<template>
  <button
    v-on="$listeners"
    data-testid="addButton"
    class="btn btn-outline-primary d-flex align-items-center"
  >
    <icon class="mr-2" :icon="icon" />
    <span>{{ text }}</span>
  </button>
</template>

<script>
import Icon from "./Icon.vue";
export default {
  components: { Icon },
  name: "AddBtn",
  inheritAttrs: true,
  props: {
    text: {
      type: String,
      default: "Add"
    },
    icon: {
      type: String,
      default: "plus"
    }
  }
};
</script>

<style lang="scss" scoped></style>
