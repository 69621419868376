<template>
  <div
    class="text-input"
    :data-testid="`${name}-select`"
    :class="{
      ' has-error': validator && validator.$model && validator && validator.$error
    }"
  >
    <label
      v-html="displayName(accessKey, label)"
      class="text-capitalize"
      :for="$attrs.name || $attrs.id"
      :id="labelId"
      v-show="label && !noLabel"
      v-tooltip="value ? hover : ''"
      @click="doClickLabel"
    />
    <div class="">
      <DxSelectBox
        :isValid="isValid"
        :placeholder="placeholder"
        :disabled="disabled"
        v-model="displaySelected"
        @focusOut="focusOut"
        @focusIn="handleFocusIn"
        :display-expr="displayExpr"
        :items="items"
        :value-expr="valueExpr"
        :input-attr="inputAttrs"
        :dataSource="dataSource"
        v-bind="$attrs"
        valueChangeEvent="input"
        :id="$attrs.id + 'box'"
        :search-expr="searchExpr"
        :searchMode="searchMode"
        :searchEnabled="searchEnabled"
        :show-clear-button="showClearButton"
        ref="selectBox"
        @initialized="initialize"
        :name="name"
        @valueChanged="valueChanged"
        :opened="openOnMount"
      >
      </DxSelectBox>
    </div>
    <div v-if="(validator && validator.$error) || (validator && validator.$invalid)">
      <div
        class="validation-error"
        v-for="(key, index) in Object.keys(validator.$params)"
        :key="index"
      >
        <span class="error" v-if="!validator[key]">{{ validatorMsgMap[key] }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { validatorMsgMapBase } from "@/modules/helpers";
import DxSelectBox from "devextreme-vue/select-box";
export default {
  name: "SelectInput",

  inheritAttrs: false,
  props: {
    noLabel: {
      type: Boolean
    },
    name: {
      required: false
    },
    accessKey: {
      type: String
    },
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    value: {
      required: false
    },
    valueExpr: {
      default() {
        return "id";
      }
    },
    dataSource: {
      required: false
    },
    items: {
      type: Array,
      default: () => []
    },
    validator: {
      type: Object
    },
    validatorMsgMap: {
      type: Object,
      default: () => {
        return validatorMsgMapBase;
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    displayExpr: {
      default() {
        return data => data && (data.displayName || data.displayText || data.name || data);
      }
    },
    template: {
      default() {
        return true;
      }
    },
    searchExpr: {
      default() {
        return this.displayExpr;
      }
    },
    searchMode: {
      default() {
        return "contains";
      }
    },
    hover: {
      default() {
        return "";
      }
    },
    clickLabel: {
      default() {
        return;
      }
    },
    showClearButton: {
      default() {
        return true;
      }
    },
    searchEnabled: {
      default() {
        return true;
      }
    },
    highlightSearchValue: {
      default: () => {
        return false;
      }
    },
    openOnMount: {
      default: false
    }
  },
  created() {
    if (this.valueExpr === "id" && typeof this.value === "string" && this.value.length) {
      this.$emit("input", Number(this.value));
    }
    window.addEventListener("wheel", this.handleScroll, {
      capture: true
    });
  },
  beforeDestroy() {
    window.removeEventListener("wheel", this.handleScroll, {
      capture: true
    });
  },
  data() {
    return {
      isFocused: false,
      selectBox: {}
    };
  },
  components: {
    DxSelectBox
  },
  computed: {
    displaySelected: {
      get() {
        if (this.valueExpr === "id" && typeof this.value === "string" && this.value.length) {
          this.$emit("input", Number(this.value));
        }
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
        return value;
      }
    },
    inputAttrs() {
      return {
        ...this.$attrs,
        "aria-labelledby": this.labelId,
        class: this.hasSearchValue ? "has-search-value" : ""
      };
    },
    labelId() {
      return (this.$attrs.id || this.name) + "label";
    },
    isValid() {
      if (this.validator) {
        return !this.validator.$invalid || !this.validator.$error;
      }
      return true;
    },
    hasSearchValue() {
      if (!this.highlightSearchValue) {
        return false;
      }
      if (this.value !== null && this.value !== undefined && this.value !== "") {
        if (Array.isArray(this.value)) {
          return this.value.length > 0;
        }
        return true;
      }
      return false;
    }
  },
  methods: {
    initialize(data) {
      this.$emit("initialized", data);
      const { component } = data;
      this.selectBox = component;
    },
    displayName(key = "", name) {
      if (key) {
        const regex = new RegExp(key, "i");
        if (regex.test(name)) {
          const { index } = name.match(regex);
          return `<span><b>${name.slice(0, index)}<u>${name[index]}</u>${name.slice(
            index + 1
          )}</b></span>`;
        }
      }
      return `<b>${name || ""}</b>`;
    },
    focus() {
      if (!this.isFocused) {
        this.selectBox.focus();
        this.selectBox.open();
        this.isFocused = true;
      }
    },
    focusOut() {
      this.selectBox.close();
      this.isFocused = false;
    },
    doClickLabel() {
      if (this.clickLabel) {
        this.clickLabel();
      }
      return;
    },
    valueChanged(data) {
      this.$emit("valueChanged", data);
    },
    handleScroll(event) {
      if (event.target.className !== "dx-item-content dx-list-item-content") {
        this.focusOut();
      }
    },
    handleFocusIn() {
      this.isFocused = true;
      this.$emit("focusIn");
    }
  }
};
</script>
<style scoped>
label {
  min-height: 16px;
}
.error {
  font-size: 0.75rem;
}
</style>
