<template>
  <div>
    <form
      @submit.prevent="handleSubmit({ ...procedure, labId: currentLab })"
      class="px-4 bg-white order-form container"
      v-shortkey="shortkeys"
      @shortkey.prevent="handleShortkey"
    >
      <h4 class="title">{{ isEditing ? "Edit" : "Add" }} Order</h4>

      <div class="row row-wrap">
        <text-input
          name="code"
          class="col code"
          label="Code"
          ref="code"
          v-model="procedure.code"
          :validator="$v.procedure.code"
        />
        <select-input
          class="col"
          label="Print Mode"
          name="printMode"
          ref="printMode  "
          :items="printModeOptions"
          v-model="procedure.printModeId"
        />
        <div class="col">
          <checkbox
            id="dor"
            name="diagnosisShowsOnPathReport"
            v-model="procedure.diagnosisShowsOnPathReport"
            label="Diagnosis on Report"
          />
          <checkbox
            id="hac"
            name="isHistologyAutoComplete"
            v-model="procedure.isHistologyAutoComplete"
            label="Histology Auto Complete"
          />
          <checkbox
            id="pac"
            name="isPathologistAutoComplete"
            label="Pathologist Auto Complete"
            v-model="procedure.isPathologistAutoComplete"
          />
        </div>
      </div>
      <div class="row date-range">
        <date-picker
          class="col"
          name="effectiveOn"
          label="Effective On"
          v-model="procedure.effectiveOn"
          :validator="$v.procedure.effectiveOn"
        />
        <date-picker
          class="col"
          label="Expires On"
          name="expiryOn"
          v-model="procedure.expiryOn"
          :validator="$v.procedure.expiryOn"
        />
        <text-input
          class="col"
          name="blockNum"
          label="Block Label"
          v-model="procedure.defaultBlockLabel"
        />
      </div>

      <div class="row">
        <div class="col">
          <div class="row">
            <text-input
              class="col blockNum"
              name="blockNum"
              label="Block Number (ID)"
              :disabled="selectedBlock || procedure.noBlocks"
              :validator="$v.procedure.defaultBlockNum"
              v-model="procedure.defaultBlockNum"
              :displayExpr="blockDisplayExpr"
              :maxLength="3"
              ref="block"
            />

            <text-input
              class="col blockNum disabled"
              name="blockNum"
              label="Block Number"
              disabled
              v-model="procedure.defaultBlockNum"
            />
            <checkbox
              class="col d-flex align-items-center"
              id="noBlocks"
              name="noBlocks"
              label="No Blocks"
              v-model="procedure.noBlocks"
            />
          </div>
        </div>
        <number-input
          class="col"
          name="numberOfSlides"
          label="Number of Slides"
          v-model="procedure.numberOfSlides"
        />
        <text-input
          class="col"
          label="Stainer Code"
          name="stainerCode"
          v-model="procedure.stainerCode"
        />
      </div>
      <div class="row">
        <SelectInput
          :dataSource="holdCodes"
          class="col holdCode"
          label="Hold Code"
          name="holdCode"
          :displayExpr="holdCodeDisplayExpr"
          v-model="procedure.holdCodeId"
        />
        <SelectInput
          :dataSource="billing"
          class="col txCode"
          name="billingTransactionCodeId"
          label="Transaction Code"
          displayExpr="code"
          v-model="procedure.billingTransactionCodeId"
        />
        <tag-input
          v-if="!isCaseOrder"
          :dataSource="diagnosisOptions"
          class="col diagnosisList"
          name="procedureDiagnosisIds"
          label="Order Diagnosis"
          v-model="procedure.orderDiagnosisIds"
        />
        <tag-input
          v-else
          :items="diagnosisOptions"
          class="col procedureDiagnosisIds"
          name="procedureDiagnosisIds"
          label="Diagnosis"
          v-model="procedure.procedureDiagnosisIds"
        />
      </div>
      <div class="row">
        <div class="col">
          <text-area-input
            class="mb-0"
            name="slideLabelDescription"
            :validator="$v.procedure.slideLabelDescription"
            label="Slide Label Description"
            v-model="procedure.slideLabelDescription"
            :resize="false"
            startHeight="3rem"
          />
          <text-area-input
            class="mb-0"
            :validator="$v.procedure.stainerDescription"
            label="Stainer Description"
            name="stainerDescription"
            v-model="procedure.stainerDescription"
            :resize="false"
            startHeight="3rem"
          />

          <text-area-input
            class="mb-0"
            :validator="$v.procedure.description"
            label="Description"
            name="description"
            v-model="procedure.description"
            :resize="false"
            startHeight="3rem"
          />
          <text-area-input
            class="diagnosis-text mb-0"
            name="diagnosisText"
            label="Diagnosis"
            v-model="procedure.diagnosisText"
            :resize="false"
          />
          <tag-input
            label="Order Tags"
            name="procedureTags"
            :items="tagOptions"
            v-model="procedure.procedureTags"
            valueExpr="id"
          />
        </div>
        <div class="col">
          <text-area-input
            class="notes-to-histology"
            name="detail"
            label="Notes to Histology"
            ref="detail"
            v-model="procedure.detail"
            :resize="false"
            :generalMacrosEnabled="true"
            startHeight="100%"
          />
        </div>
      </div>
      <div class="row mt-2 px-3 justify-content-end">
        <div>
          <button @click="handleCancel" type="button" class="btn btn-danger mx-2">Cancel</button>
          <button type="submit" class="btn btn-primary">Submit</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { maxLength, required, maxValue } from "vuelidate/lib/validators";
import TextAreaInput from "../../TextAreaInput.vue";
import { afterEffective, altKey, isValidBlockNum, oneYearAgo } from "@/modules/helpers";
import { BillingApi, DropdownApi, MacrosApi, ProceduresApi, SettingsApi } from "../../../services";
import Checkbox from "../../common/Checkbox.vue";
import { mapGetters, mapState } from "vuex";
import TagInput from "../../common/TagInput.vue";
import NumberInput from "../../common/NumberInput.vue";
import { fromLetters, toLetters } from "../../../modules/helpers";
import { cloneDeep } from "lodash";
import SelectInput from "@/components/common/SelectInput.vue";
import TextInput from "@/components/common/TextInput.vue";
import DatePicker from "@/components/common/DatePicker.vue";
import { MacroTypeEnum, SpecimenNumbersEnum, TypeCodeGroupEnum } from "@/modules/enums";
import DataSource from "devextreme/data/data_source";

export default {
  components: {
    TextAreaInput,
    Checkbox,
    TagInput,
    NumberInput,
    SelectInput,
    TextInput,
    DatePicker
  },
  name: "ProcedureForm",
  props: {
    procedureId: {
      required: false
    },
    customOrders: {
      required: false,
      default() {
        return [];
      }
    },
    selectedBlock: {
      default() {
        return null;
      }
    },
    isCaseOrder: {
      default() {
        return false;
      }
    }
  },
  mounted() {
    if (this.$refs.code) {
      this.$nextTick(() => {
        this.$refs.code.focus();
      });
    }
    if (this.isEditing) {
      this.$refs?.printMode?.focus();
    }
    if (this.selectedBlock) {
      this.procedure.defaultBlockNum = this.selectedBlock;
    }
  },
  data() {
    return {
      isEditing: false,
      diagnosisOptions: [],
      procedure: {
        code: "",
        description: "",
        holdCodeId: null,
        billingTransactionCodeId: null,
        printMode: null,
        defaultBlockLabel: "",
        numberOfSlides: 0,
        slideLabelDescription: "",
        stainerCode: "",
        stainerDescription: "",
        orderDiagnosisIds: [],
        procedureDiagnosisIds: [],
        diagnosisShowsOnPathReport: false,
        isHistologyAutoComplete: false,
        isPathologistAutoComplete: false,
        effectiveOn: oneYearAgo(),
        expiryOn: null,
        diagnosisText: "",
        defaultBlockNum: null,
        noBlocks: false,
        procedureTags: []
      },
      printModeOptions: [],
      originalProcedure: {},
      saveShortkey: altKey("s"),
      tagOptions: [],
      isMacroOpen: false,
      generalMacros: [],
      positionToPlaceCursor: 0,
      shortkeys: { b: altKey("b"), s: altKey("s") }
    };
  },
  created() {
    DropdownApi.getPrintModes().then(res => {
      this.printModeOptions = res || [];
    });
    DropdownApi.getOrderDiagnosis().then(diagnosisOptions => {
      this.diagnosisOptions = diagnosisOptions || [];
      if (this.procedureId) {
        ProceduresApi.getProcedure(this.procedureId).then(res => {
          if (this.customOrders.length) {
            const target = this.customOrders.find(e => e.procedureId === this.procedureId);
            if (this.specimenNumbering === SpecimenNumbersEnum.Numbers) {
              target.defaultBlockNum = toLetters(target.defaultBlockNum);
            }
            this.procedure = target;
            this.originalProcedure = cloneDeep(target);
            this.diagnosisOptions = diagnosisOptions.filter(e =>
              res.orderDiagnosisIds.includes(e.id)
            );
          } else {
            if (this.specimenNumbering === SpecimenNumbersEnum.Numbers) {
              res.defaultBlockNum = toLetters(res.defaultBlockNum);
            }
            res.procedureTags = res.procedureTags.map(e => e.id);
            this.procedure = res;
            this.originalProcedure = cloneDeep(res);
          }
        });
        this.isEditing = true;
      }
    });
    SettingsApi.typeCodesSearch
      .load({ filter: ["typeId", TypeCodeGroupEnum.ProcedureTag] })
      .then(res => {
        this.tagOptions = res;
      });
    MacrosApi.getMacrosByUserAndType({
      userId: this.currentUser.id,
      macroTypeId: MacroTypeEnum.General,
      loadOptions: {}
    }).then(res => {
      this.generalMacros = res.data || [];
    });
  },
  validations() {
    return {
      procedure: {
        code: {
          required,
          maxLength: maxLength(25)
        },
        description: {
          required,
          maxLength: maxLength(250)
        },
        effectiveOn: {
          required
        },
        expiryOn: {
          afterEffective
        },
        numberOfSlides: {
          maxValue: maxValue(99)
        },
        slideLabelDescription: {
          maxLength: maxLength(255),
          required
        },
        stainerCode: {
          maxLength: maxLength(255)
        },
        stainerDescription: {
          maxLength: maxLength(255)
        },
        defaultBlockNum: {
          required,
          blockMustBeNumbers: value => {
            return isValidBlockNum(value, this?.isCaseOrder, SpecimenNumbersEnum.Letters);
          },
          blockMustBeLetters: value => {
            return isValidBlockNum(value, this?.isCaseOrder, SpecimenNumbersEnum.Numbers);
          }
        }
      }
    };
  },

  computed: {
    ...mapState(["currentLab", "labSettings", "currentUser"]),
    ...mapGetters("accessionStore", ["specimenNumbering"]),
    holdCodes() {
      return new DataSource({ store: DropdownApi.searchHoldCodes, sort: ["displayName"] });
    },
    billing() {
      return new DataSource({ store: BillingApi.searchStore, sort: ["code"] });
    },
    blockDisplayExpr() {
      if (!this.procedure.defaultBlockNum || this.procedure.defaultBlockNum === "?Z") {
        return "";
      }
      return this.procedure.defaultBlockNum;
    }
  },
  methods: {
    handleCancel() {
      this.$emit("close");
    },
    async handleSubmit(procedure) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        window.notify("Invalid entry please check your input.", "warning");
        return;
      }
      if (this.specimenNumbering === SpecimenNumbersEnum.Numbers) {
        if (isNaN(procedure.defaultBlockNum)) {
          procedure.defaultBlockNum = fromLetters(procedure.defaultBlockNum.toUpperCase());
        }
      }
      procedure.procedureTags = procedure.procedureTags.map(e => {
        return { id: e };
      });
      this.$emit("refresh");
      this.$emit("submit", procedure);
    },
    handleShortkey({ srcKey }) {
      if (srcKey === "s") {
        this.handleSubmit({ ...this.procedure, labId: this.currentLab });
      } else if (srcKey === "b") {
        if (!this.selectedBlock) {
          this.$refs.block.focus();
          this.$refs.block.selectAll();
        } else {
          this.$emit("focusBlock");
        }
      }
    },
    holdCodeDisplayExpr(data) {
      if (this.labSettings.SearchByHoldCode && data?.holdCode) {
        return `${data.holdCode}  -  ${data.displayName}`;
      }
      return data?.displayName;
    }
  },
  watch: {
    "procedure.noBlocks"(nv) {
      if (nv) {
        this.procedure.defaultBlockNum = 1;
        if (this.labSettings.DefaultToZeroSlides && !this.customOrders?.length) {
          this.procedure.numberOfSlides = 0;
        }
      }
    },
    "procedure.defaultBlockNum"(nv) {
      if (!this.isCaseOrder) {
        return;
      }
      if (this.selectedBlock) {
        this.procedure.defaultBlockNum = this.selectedBlock;
      }
      if (!this.selectedBlock && (!nv || nv === "?Z")) {
        this.procedure.defaultBlockNum = "";
      }
    },
    selectedBlock(nv) {
      if (nv && !this.procedure.noBlocks) {
        this.procedure.defaultBlockNum = nv;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.order-form {
  &.case-order,
  &.edit-order {
    .date-range,
    .code,
    .title,
    .diagnosisList,
    .stainer-description {
      display: none;
    }
    .procedureDiagnosisIds {
      display: block;
    }
  }
  .blockNum.disabled {
    display: none;
  }

  &.case-order.block_selected,
  &.edit-order {
    .blockNum {
      display: none;
      &.disabled {
        display: flex;
      }
    }
  }
}
.notes-to-histology {
  height: 95%;
}
</style>
