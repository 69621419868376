<template>
  <form class="container" @submit.prevent="handleSubmit">
    <text-input
      label="Subject"
      name="subject"
      v-model="ticketPayload.subject"
      :validator="$v.ticketPayload.subject"
    />
    <text-input
      label="First Name"
      name="firstName"
      v-model="ticketPayload.firstName"
      :validator="$v.ticketPayload.firstName"
    />
    <text-input
      label="Last Name"
      v-model="ticketPayload.lastName"
      :validator="$v.ticketPayload.lastName"
    />
    <text-input
      name="lastName"
      label="Email Address"
      v-model="ticketPayload.email"
      :validator="$v.ticketPayload.email"
    />
    <phone-input label="Phone Number" v-model="ticketPayload.phoneNumber" />
    <text-area-input
      label="Comments & Questions"
      v-focus
      v-model="ticketPayload.message"
      :validator="$v.ticketPayload.message"
    />
    <div class="form-row justify-content-end">
      <button class="btn btn-success mr-auto m-1" type="button" @click="handleScreenConnect">
        Screen Support
      </button>
      <button class="btn btn-success mr-auto m-1" type="button" @click="handleStartRecording">
        Submit and Start Recording
      </button>
      <button type="submit" class="btn btn-primary m-1">Submit</button>
    </div>
  </form>
</template>

<script>
import { mapState } from "vuex";
import PhoneInput from "./common/PhoneInput.vue";
import TextInput from "./common/TextInput.vue";
import TextAreaInput from "./TextAreaInput.vue";
import { required } from "vuelidate/lib/validators";
import LogRocket from "logrocket";
import LogrocketFuzzySanitizer from "logrocket-fuzzy-search-sanitizer";
import { version } from "../../package.json";
import { detectAnyAdblocker } from "vue-adblock-detector";

export default {
  components: { TextInput, TextAreaInput, PhoneInput },
  name: "SupportPopup",
  data() {
    return {
      ticketPayload: {
        subject: "",
        message: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: ""
      },
      hasAdBlocker: false
    };
  },
  created() {
    const { firstName, lastName, phoneNumber, email } = this.currentUser;
    this.ticketPayload.firstName = firstName;
    this.ticketPayload.lastName = lastName;
    this.ticketPayload.phoneNumber = phoneNumber;
    this.ticketPayload.email = email;
  },
  validations() {
    return {
      ticketPayload: {
        message: {
          required
        },
        firstName: {
          required
        },
        lastName: {
          required
        },
        email: {
          required
        },
        subject: {
          required
        }
      }
    };
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser,
      isRecordingLogRocket: state => state.sessionDetails.isRecordingLogRocket
    })
  },
  methods: {
    getSessionUrl() {
      return new Promise(resolve => {
        LogRocket.getSessionURL(resolve);
      });
    },
    async handleSubmit() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      try {
        const fullName = `${this.ticketPayload.firstName} ${this.ticketPayload.lastName}`;
        const apiKey = process.env.VUE_APP_FRESHDESK_KEY;
        const encodedApiKey = Buffer.from(apiKey).toString("base64");
        let sessionDetails = "";
        if (this.isRecordingLogRocket) {
          const sessionUrl = await this.getSessionUrl();
          if (!sessionUrl) {
            window.alert("An error occured in recording. Please try again.");
            return;
          }
          sessionDetails = `<br> <a href="${sessionUrl}">View Session Replay</a>`;
        }
        const response = await fetch("https://netsoftusa1.freshdesk.com/api/v2/tickets", {
          method: "POST",
          headers: {
            Authorization: `Basic ${encodedApiKey}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            subject: this.ticketPayload.subject,
            description: `${fullName} <br> ${this.ticketPayload.message}${sessionDetails}`,
            status: 2,
            phone: this.ticketPayload.phoneNumber,
            type: "IPPro",
            source: 2,
            priority: 1,
            email: this.ticketPayload.email,
            custom_fields: {
              category: "IPPro"
            }
          })
        });
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        if (this.isRecordingLogRocket) {
          await window.alert(
            `<div style="font-size: 1.25rem; width: 450px; text-align: center;"><span style=" font-size: 1.5rem; font-weight: bold; margin-bottom: 0;">Thank you!</span><br>Your request has been submitted. Our customer care team will get back to you within 48 hours.  If this issue is creating a workflow stoppage, please contact us by phone at 866-463-8763.<br><br>Recording has started. Please replicate the issue you would like our team to address and remember to close your browser when finished.</div>`
          );
        } else {
          window.alert(
            `<div style="font-size: 1.25rem; width: 450px; text-align: center;"><span style=" font-size: 1.5rem; font-weight: bold; margin-bottom: 0;">Thank you!</span><br>Your request has been submitted. Our customer care team will get back to you within 48 hours.  If this issue is creating a workflow stoppage, please contact us by phone at 866-463-8763.</div>`
          );
        }
        this.$emit("close");
      } catch (error) {
        window.notify("An error ocurred. Please try again", "error");
        console.log(error);
      }
    },
    handleScreenConnect() {
      window.open("https://netsoftusa1.screenconnect.com/");
    },
    async handleStartRecording() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      await this.checkAdBlocker();
      if (this.hasAdBlocker) {
        window.alert(
          "An Ad Blocker was detected on your browser. This may prevent recording from beginning.<br><br>Please disable your Ad Blocker on this page or add https://www.intellipathpro.com to your whitelist/allowed sites before continuing."
        );
        return;
      }
      const confirmLogRocket = await window.confirm(
        `<div style="font-size: 1.25rem; width: 450px; text-align: center;">To better assist NetSoft, per your request we will be recording only your IntelliPath Pro activities.  All patient information has been redacted.  The recording will start upon selecting "Yes" and will end when you close the internet browser used for IntelliPath Pro (or after 30 minutes of inactivity).  Thank you for sending this recording. Do you wish to continue?</div>`
      );
      if (confirmLogRocket) {
        this.$store.commit("sessionDetails/setIsRecordingLogRocket", true);
        const privateFieldNames = [
          "password",
          "patient",
          "patientSSN",
          "ssn",
          "dob",
          "patientDOB",
          "patientDob",
          "patientDateOfBirth",
          "dateOfBirth",
          "birth",
          "patientMRN",
          "mrn",
          "patientName",
          "name",
          "patientFirstName",
          "patientMiddleName",
          "patientLastName",
          "patientMaidenName",
          "accountNumber",
          "acctNumber",
          "patientAccountNumber",
          "refNumber",
          "alternateId",
          "address1",
          "address2",
          "addressLine1",
          "addressLine2",
          "line1",
          "line2",
          "address",
          "phone",
          "email",
          "groupNumber",
          "guarantor",
          "policyNumber",
          "comments",
          "patientFieldContains",
          "ipAddress",
          "pdf"
        ];
        const { requestSanitizer, responseSanitizer } = LogrocketFuzzySanitizer.setup([
          ...privateFieldNames
        ]);
        const logrocketApiKey = process.env.VUE_APP_LOGROCKET_API_KEY;
        LogRocket.init(logrocketApiKey, {
          release: version,
          network: {
            requestSanitizer,
            responseSanitizer
          }
        });
        LogRocket.identify(this.currentUser.id, {
          name: this.currentUser.displayName,
          email: this.currentUser.email
        });
        await this.handleSubmit();
        await window.alert(
          `<div style="font-size: 1.25rem; width: 450px; text-align: center;"><span style=" font-size: 1.5rem; font-weight: bold; margin-bottom: 0;">Thank you!</span><br>Your request has been submitted. Our customer care team will get back to you within 48 hours.  If this issue is creating a workflow stoppage, please contact us by phone at 866-463-8763.<br><br>Recording has started. Please replicate the issue you would like our team to address and remember to close your browser when finished.</div>`
        );
      }
    },
    checkAdBlocker() {
      detectAnyAdblocker().then(detected => {
        if (detected) {
          this.hasAdBlocker = true;
        }
      });
    }
  }
};
</script>
