import store from "@/store";
import { SpecimenNumbersEnum } from "./enums";
import { toLetters } from "./helpers";

export function printFileDrop(cassettes, trayName) {
  const fileDropJSON = store.state.labSettings.FileDropPrintingConfiguration;
  // Default File Drop URL for running locally is http://localhost:18877/api/PrintMate
  const { url, printerName, defaultTray, printProcedure1, printProcedure2 } =
    JSON.parse(fileDropJSON);
  if (!cassettes.length) {
    return;
  }
  if (!url) {
    throw { message: "This lab has not set up a File Drop URL. Cassette printing failed." };
  }
  let items = [];
  for (const cassette of cassettes) {
    const {
      caseNumber,
      specimenOrder,
      blockNum,
      cassetteTrayName,
      specimenNumbering,
      patientFirstName,
      patientLastName,
      specimensPerCase,
      blocksPerSpecimen,
      procedureCodes
    } = cassette;
    // Hide specimen if lab setting is on and case only has one specimen
    const hideSpecimenOnLabel =
      store.state.labSettings.FileDropPrintingSuppressSingleSpecimen && specimensPerCase === 1;
    // Hide block if lab setting is on and specimen only has one block
    const hideBlockOnLabel =
      store.state.labSettings.FileDropPrintingSuppressSingleBlock && blocksPerSpecimen === 1;
    let procedure1 = "";
    let procedure2 = "";
    if (procedureCodes.includes(printProcedure1)) {
      procedure1 = printProcedure1;
    }
    if (procedureCodes.includes(printProcedure2)) {
      procedure2 = printProcedure2;
    }
    const caseNumberRegex =
      /(?<prefix>[A-Z]{1,6})(?<year>((19)|(20))?[0-9]{2})-?0{0,6}(?<caseNumSeq>[1-9][0-9]{0,6})/i;
    const {
      groups: { prefix, year, caseNumSeq }
    } = caseNumberRegex.exec(caseNumber);
    const shortYear = year.slice(-2);
    const blockNumDisplay =
      specimenNumbering === SpecimenNumbersEnum.Numbers ? toLetters(blockNum) : blockNum + "";
    const pipeDelimitedConcat =
      prefix + shortYear + caseNumSeq + "|" + specimenOrder + "|" + blockNumDisplay;
    items.push({
      CasePrefixAndYear: prefix + shortYear,
      CaseNumSeq: caseNumSeq,
      SpecimenOrder: hideSpecimenOnLabel ? "" : specimenOrder + "",
      CassetteId: hideBlockOnLabel ? "" : blockNumDisplay,
      PipeDelimitedConcat: pipeDelimitedConcat,
      PatientLastName: patientLastName,
      PatientFirstName: patientFirstName,
      TrayName: trayName || cassetteTrayName || defaultTray,
      procedure1,
      procedure2
    });
  }
  const payloadObject = {
    PrinterName: printerName,
    Items: items
  };
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payloadObject)
  });
}
