<template>
  <div class="bg-white container" v-shortkey="shortkeys" @shortkey="handleShortkey">
    <div class="mb-2 d-flex justify-content-between align-items-center">
      <div>
        <page-title> {{ patientId ? "Patient" : "Case" }} History</page-title>
        <h6 v-show="legacyCount">Legacy Cases: {{ legacyCount }}</h6>
      </div>
      <icon-button
        class="btn d-flex align-items-center btn-outline-primary"
        v-tooltip.left="'Full History PDF'"
        icon="history"
        @click="renderHistory"
      >
        <b class="ml-1">History</b>
      </icon-button>
    </div>

    <DxTreeList
      id="historyGrid"
      :data-source="dataSource$"
      :show-borders="true"
      :word-wrap-enabled="true"
      :hasItemsExpr="data => data.specimenCount > 1"
      data-structure="tree"
      :expand-nodes-on-filtering="true"
      :rendery-async="true"
      @initialized="initializeGrid"
      :onCellPrepared="redactLogRocket"
      :allowColumnReordering="true"
      :allowColumnResizing="true"
      :row-alternation-enabled="true"
      :columnChooser="columnChooser"
      no-data-text="No patient history found"
      :columns="columns"
      :onRowClick="handleRowClick"
      @contentReady="onContentReady"
      :page-size="5"
      ref="gridRef"
      :toolbar="toolbar"
    >
      <DxSearchPanel :visible="true" />
      <DxHeaderFilter :visible="true" />
      <template v-slot:actions="{ data: { key } }">
        <div class="text-primary">
          <icon-button
            v-tooltip.left="'View accession report.'"
            icon="file-pdf"
            class="mr-1 p-0 pointer"
            role="button"
            @click="renderPdf(key)"
          />
          <icon-button
            v-tooltip.left="'Open accession.'"
            icon="eye"
            role="link"
            class="p-1 pointer"
            @click="navigateToCase(key)"
          />
        </div>
      </template>
      <DxScrolling mode="standard" />
      <DxPaging :enabled="true" :page-size="5" />
      <DxPager :show-page-size-selector="true" :show-info="true" />
      <DxStateStoring
        :enabled="true"
        type="custom"
        :custom-save="customStateSave"
        :custom-load="customStateLoad"
      />
    </DxTreeList>
    <PDFViewer
      v-if="selectedPathReportUrl"
      :url="selectedPathReportUrl"
      @close="selectedPathReportUrl = null"
      :fileName="fileName"
      ref="historyReport"
    />
    <modal :status="isViewerOpen" @close="isViewerOpen = !isViewerOpen">
      <PDFViewer
        class="pdf-modal no-scroll"
        :url="currentPdf"
        @close="isViewerOpen = !isViewerOpen"
        @print="handlePrint"
        :fileName="fileName"
        @download="handlePdfDownload"
      />
    </modal>
  </div>
</template>

<script>
import {
  DxTreeList,
  DxHeaderFilter,
  DxSearchPanel,
  DxPager,
  DxPaging,
  DxScrolling,
  DxStateStoring
} from "devextreme-vue/tree-list";
import { map } from "rxjs/operators";
import { CaseHistoryApi, CasesApi, ReportsApi } from "@/services";
import { mapState } from "vuex";
import { createLogItem, getAltKeys, getTextFromHtml, scrollToElement } from "@/modules/helpers";
import Modal from "./common/Modal.vue";
import PDFViewer from "./common/PDFViewer.vue";
import auditLog from "@/services/AuditLog";
import pathReportMessages from "@/services/pathReportMessages";
import PageTitle from "@/components/common/PageTitle";
import IconButton from "./common/IconButton.vue";
import eventBus, { TOGGLE_HIDE_PATH_REPORT } from "@/modules/eventBus";
import { handleErrors } from "@/modules/handleErrors";

export default {
  components: {
    DxTreeList,
    DxHeaderFilter,
    DxSearchPanel,
    DxPager,
    DxPaging,
    DxScrolling,
    Modal,
    PDFViewer,
    PageTitle,
    IconButton,
    DxStateStoring
  },
  name: "CaseHistory",
  props: ["patientId", "caseId"],
  data() {
    return {
      isViewerOpen: false,
      currentPdf: "",
      legacyCount: 0,
      fileName: "",
      reportCase: null,
      columnChooser: {
        allowSearch: true,
        enabled: true,
        mode: "select"
      },
      grid: {},
      selectedCaseId: null,
      selectedPathReportUrl: null,
      shortkeys: {
        up: ["arrowup"],
        down: ["arrowdown"],
        left: ["arrowleft"],
        right: ["arrowright"],
        ...getAltKeys("pr")
      },
      hasContentReadyHappened: false,
      isScrolledToPathReport: false
    };
  },
  computed: {
    ...mapState({
      caseDetails: state => state.accessionStore.caseDetails,
      caseHistory: state => state.accessionStore.history,
      caseHistoryGridState: state => state.grids.caseHistory,
      applicationSettings: state => state.applicationSettings,
      DefaultPatientHistoryReport: state => state.labSettings.DefaultPatientHistoryReport
    }),
    columns() {
      return [
        { width: 175, dataField: "accessionNumber", caption: "Number" },
        { dataField: "specimenCount", caption: "Specimens" },
        { dataField: "reportedOn", dataType: "date", caption: "Reported" },
        {
          dataField: "receivedOn",
          sortIndex: 0,
          sortOrder: "desc",
          dataType: "date",
          caption: "Received"
        },
        { dataField: "sex" },
        { dataField: "providerName", caption: "Provider" },
        { dataField: "pathologistName", caption: "Pathologist" },
        { dataField: "patientName", caption: "Name" },
        { dataField: "patientDOB", caption: "DOB", dataType: "date" },
        {
          dataField: "diagnosis",
          caption: "Diagnosis",
          calculateDisplayValue(data) {
            if (data.diagnosis) {
              return getTextFromHtml(data.diagnosis);
            }
            return "";
          }
        },
        { dataField: "site", caption: "Site" },
        { dataField: "protocol", caption: "Protocol", visible: false },
        { type: "buttons", caption: "Actions", cellTemplate: "actions", visibleIndex: 0 }
      ];
    },
    toolbar() {
      return {
        visible: true,
        items: [
          {
            widget: "dxButton",
            options: {
              visible: this.DefaultPatientHistoryReport,
              icon: "fa fa-print",
              onClick: () => this.printHistoryReport(),
              hint: "Click to print patient history (Alt + R).",
              elementAttr: {
                class: "icon-color"
              },
              text: "Print"
            },
            locateInMenu: "auto",
            showText: "inMenu"
          },
          {
            name: "columnChooserButton",
            visible: true
          },
          {
            name: "searchPanel",
            visible: true
          }
        ]
      };
    }
  },
  created() {
    this.loadHistory();
    const caseId = this.$route.params.caseId || this.caseId;
    this.$store.dispatch("accessionStore/getHistory", caseId).then(response => {
      this.legacyCount = response?.legacyCaseQty ?? 22;
    });
    this.getCaseDetails();
    window.addEventListener("wheel", this.handleScroll, {
      capture: true
    });
  },
  beforeDestroy() {
    if (this.caseId) {
      this.$store.commit("accessionStore/clearCaseDetails");
    }
    window.removeEventListener("wheel", this.handleScroll, {
      capture: true
    });
    eventBus.$emit(TOGGLE_HIDE_PATH_REPORT, false);
  },
  watch: {
    selectedCaseId: {
      immediate: true,
      handler(nv) {
        this.getPathReportForSelectedCase(nv);
      }
    },
    selectedPathReportUrl: {
      immediate: true,
      handler(nv) {
        eventBus.$emit(TOGGLE_HIDE_PATH_REPORT, Boolean(nv));
      }
    }
  },
  domStreams: ["expanded$"],
  subscriptions() {
    const dataSource$ = this.$watchAsObservable("caseHistory", { immediate: true }).pipe(
      map(({ newValue: items }) => {
        const rootNodes = {};
        items.forEach((item, idx) => {
          if (!rootNodes[item.caseId]) {
            rootNodes[item.caseId] = {
              parentId: 0,
              id: item.caseId,
              items: [item],
              ...item
            };
            if (item.specimenCount > 1) {
              delete rootNodes[item.caseId].diagnosis;
              delete rootNodes[item.caseId].site;
              delete rootNodes[item.caseId].pathologistName;
              delete rootNodes[item.caseId].providerName;
            }
          } else {
            rootNodes[item.caseId].items = [...rootNodes[item.caseId].items, item];
          }
          item.id = idx + 1;
          delete item.specimenCount;
        });
        return Object.values(rootNodes);
      })
    );

    return { dataSource$, expand: this.expanded$ };
  },
  methods: {
    customizeTaskCompletionText(cellInfo) {
      return `${cellInfo.valueText}%`;
    },
    calculateHTML(data) {
      return getTextFromHtml(data || "");
    },
    initializeGrid({ component }) {
      this.grid = component;
      const caseId = this.$route.params.caseId || this.caseId;
      component.navigateToRow(caseId);
    },
    async renderPdf(caseId) {
      try {
        const url = await this.getPathReportUrl(caseId);
        this.fileName = `PathReport`;
        this.reportCase = caseId;
        this.currentPdf = url;
        this.isViewerOpen = !this.isViewerOpen;
      } catch (e) {
        window.notify("An error occurred", "error");
      }
    },
    async renderHistory() {
      const caseId = this.$route.params.caseId || this.caseId;
      const file = this.patientId
        ? await CasesApi.getPatientPathRepHistory(this.patientId)
        : await CasesApi.getPathReportHistory(caseId);
      const blob = new Blob([file], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      this.currentPdf = url;
      this.fileName = `${
        this.caseDetails.patientLastName + " " + this.caseDetails.patientFirstName
      }- History`;
      this.isViewerOpen = !this.isViewerOpen;
    },
    async handlePrint() {
      if (this.reportCase) {
        const caseDetails = CasesApi.getCaseById(this.reportCase);
        const logItem = createLogItem(caseDetails, 1);
        logItem.comments = `Printed path report PDF.`;
        auditLog.insertLogMessage(logItem);
      } else {
        const logItem = createLogItem({}, 1);
        logItem.comments = `Printed full patient history PDF.`;
        auditLog.insertLogMessage(logItem);
      }
    },
    async handlePdfDownload() {
      if (this.reportCase) {
        const caseDetails = CasesApi.getCaseById(this.reportCase);
        pathReportMessages.addPathReportMessage({
          caseIds: [this.reportCase ?? (this.$route.params.caseId || this.caseId)],
          method: 6
        });
        const logItem = createLogItem(caseDetails, 1);
        logItem.comments = `Manually downloaded path report pdf`;
        auditLog.insertLogMessage(logItem);
      } else {
        const logItem = createLogItem({}, 1);
        logItem.comments = `Manually downloaded full history path report pdf`;
        auditLog.insertLogMessage(logItem);
      }
    },
    navigateToCase(caseId) {
      this.$router.push({
        name: "CaseHistory",
        params: { caseId }
      });
      this.$nextTick(() => {
        this.$store.dispatch("accessionStore/getCaseHeader", caseId);
        this.$store.dispatch("accessionStore/getCaseDetails", caseId);
        this.$store.dispatch("accessionStore/getHistory", caseId);
        this.$store.dispatch("accessionStore/getTransactions", caseId);
        this.$store.dispatch("accessionStore/getInsurances", caseId);
        this.$store.dispatch("accessionStore/getCaseSpecimens", caseId);
        this.$store.dispatch("accessionStore/getDistributions", caseId);
        this.$store.dispatch("report/viewPathReport", { caseId });
      });
    },
    customStateSave(gridState) {
      const payload = {
        name: "caseHistory",
        gridState: { ...gridState, searchText: "", selectedRowKeys: [] },
        columns: this.columns
      };
      return this.$store.dispatch("grids/setGridState", payload);
    },
    customStateLoad() {
      return this.caseHistoryGridState;
    },
    loadHistory() {
      const caseId = this.$route.params?.caseId || this.caseId;
      this.$store.dispatch("accessionStore/getHistory", [caseId, this.patientId]).then(response => {
        this.legacyCount = response?.legacyCaseQty ?? 22;
      });
    },
    getCaseDetails() {
      if (this.caseId) {
        this.$store.dispatch("accessionStore/getCaseDetails", this.caseId);
      }
    },
    handleRowClick(rowData) {
      const { data } = rowData;
      this.selectedCaseId = data.caseId;
      this.grid.selectRows(rowData.key, false);
    },
    async getPathReportForSelectedCase(caseId) {
      if (caseId) {
        this.selectedPathReportUrl = await this.getPathReportUrl(caseId, true);
      } else {
        this.selectedPathReportUrl = null;
      }
    },
    async getPathReportUrl(caseId, generate) {
      const caseInformation = await CasesApi.getCaseStatus(caseId);
      const isReported = [11, 14, 8].includes(caseInformation);
      let file;
      if (isReported && !generate) {
        file = await CasesApi.getNewestPathReport(caseId);
      } else {
        file = await ReportsApi.getSSRSReport({ caseId, format: "pdf" });
      }
      const blob = new Blob([file], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      return url;
    },
    onContentReady() {
      const rows = this.grid.getVisibleRows();
      if (rows?.length && !this.hasContentReadyHappened) {
        this.handleRowClick(rows[0]);
        this.hasContentReadyHappened = true;
      }
    },
    handleShortkey({ srcKey }) {
      if (["up", "down", "left", "right"].includes(srcKey)) {
        const currentPage = this.grid.pageIndex();
        const totalCount = this.grid.pageCount();
        const selectPage = change => {
          this.grid.pageIndex(currentPage + change);
          this.$nextTick(() => {
            const newRows = this.grid.getVisibleRows();
            this.grid.selectRowsByIndexes(change > 0 ? newRows.length - 1 : 0);
          });
        };
        const rows = this.grid.getVisibleRows();
        if (rows?.length) {
          if (["up", "down"].includes(srcKey)) {
            const currentSelection = this.grid.getSelectedRowKeys();
            const currentSelectionIndex = this.grid.getRowIndexByKey(currentSelection[0]);
            let indexToSelect = currentSelectionIndex;
            switch (srcKey) {
              case "down":
                indexToSelect++;
                break;
              case "up":
                indexToSelect--;
                break;
            }
            if (indexToSelect > -1 && indexToSelect < rows.length) {
              const newRow = rows[indexToSelect];
              this.handleRowClick(newRow);
            } else {
              if (indexToSelect < 0 && currentPage > -1) {
                selectPage(-1);
              }
              if (indexToSelect > rows.length - 1 && currentPage < totalCount) {
                selectPage(1);
              }
            }
          } else {
            switch (srcKey) {
              case "left":
                if (currentPage > 0) {
                  selectPage(-1);
                }
                break;
              case "right":
                if (currentPage < totalCount) {
                  selectPage(1);
                }
                break;
            }
          }
        }
      } else {
        switch (srcKey) {
          case "p":
            if (this.isScrolledToPathReport) {
              scrollToElement(this.$refs.gridRef.$el);
            } else {
              const reportRef = this.$refs?.historyReport;
              if (reportRef?.$el) {
                scrollToElement(reportRef.$el);
                this.isScrolledToPathReport = true;
              }
            }
            break;
          case "r":
            if (this.DefaultPatientHistoryReport) {
              this.printHistoryReport();
            }
        }
      }
    },
    handleScroll() {
      this.isScrolledToPathReport = false;
    },
    printHistoryReport() {
      if (!this.applicationSettings.defaultHistoryPrinter) {
        window.alert("Please select a History Printer from the app settings popup.");
        return;
      }
      const caseId = parseInt(this.$route.params.caseId || this.caseId);
      const patientId = this.patientId || this.caseDetails?.patientId || null;
      CaseHistoryApi.printPatientHistory({
        patientId,
        caseId,
        printerId: this.applicationSettings.defaultHistoryPrinter
      })
        .then(() => {
          window.notify("Successfully printed history");
        })
        .catch(error => {
          handleErrors(error);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.pdf-modal {
  width: 50vw;
  height: 80vh;
}
.no-scroll {
  overflow-y: hidden;
}
.btn {
  color: $primary;
  &:hover {
    color: white !important;
  }
}
.icon {
  margin-right: 0.1rem;
}
</style>
