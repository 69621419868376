<template>
  <form @submit.prevent="handleSubmit" action="" class="container p-4">
    <div class="row">
      <h4>{{ isEditing ? "Edit" : "Add" }} Path Report</h4>
    </div>
    <div class="row">
      <div class="col">
        <TextInput v-model="report.name" :validator="$v.value.name" label="Name" maxLength="51" />
        <text-area-input
          v-model="report.description"
          label="Description"
          :resize="false"
          maxLength="2001"
          rows="5"
          :validator="$v.value.description"
        />
      </div>
      <div class="col">
        <date-picker
          v-model="report.lastRunOn"
          name="lastRunOn"
          :disabled="true"
          label="Last Run On"
        />
        <text-input v-model="report.lastRunBy" name="lastRunBy" disabled label="Last Run By" />
        <select-input label="Group" :dataSource="groups" v-model="report.reportGroupId" />
      </div>
    </div>
    <div class="row justify-content-end">
      <button type="submit" :disabled="$v.value.$invalid" class="btn btn-primary">Submit</button>
      <button type="button" @click="handleCancel" class="mx-2 btn btn-danger">Cancel</button>
    </div>
  </form>
</template>

<script>
import { mapState } from "vuex";
import DatePicker from "../common/DatePicker.vue";
import TextAreaInput from "../TextAreaInput.vue";
import ReportService from "../../services/Reports";
import { required, maxLength, helpers } from "vuelidate/lib/validators";
import SelectInput from "@/components/common/SelectInput.vue";
import TextInput from "@/components/common/TextInput.vue";
import { handleErrors } from "@/modules/handleErrors";
const alphaNum = helpers.regex("alphaNum", /[0-9A-Z-]/i);
export default {
  components: { DatePicker, TextAreaInput, SelectInput, TextInput },
  props: ["value"],
  name: "ReportsForm",
  created() {
    this.$store.dispatch("dropdowns/getReportGroups");
  },
  validations: {
    value: {
      name: {
        required,
        maxLength: maxLength(50),
        alphaNum
      },
      description: {
        maxLength: maxLength(2000),
        alphaNum
      }
    }
  },
  computed: {
    ...mapState(["currentUser", "currentLab"]),
    ...mapState({
      groups: state => state.dropdowns.reportGroups
    }),
    report: {
      get() {
        return this.value;
      },
      set(value) {
        return this.$emit("input", value);
      }
    },
    isEditing() {
      if (this.value.id) {
        return true;
      }
      return false;
    }
  },
  methods: {
    handleSubmit() {
      if (this.$v.$invalid) {
        return;
      }
      ReportService.updateReport(this.value)
        .then(res => {
          if (res) {
            window.notify("Updated report " + this.value.name, "success");
            this.$emit("close");
          }
        })
        .catch(error => {
          handleErrors(error);
        });
    },
    async handleCancel() {
      const confirm = await window.confirm("Are you sure you want to discard any data entered?");
      if (confirm) {
        this.$emit("close");
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
