<template>
  <div v-shortkey="shortkeys" @shortkey="handleShortkey">
    <h2>Assign Pathologist</h2>
    <div class="batch-pathologists">
      <SelectInput
        class="mb-2"
        label="<u>P</u>athologist"
        ref="pathologistId"
        :items="pathologists"
        v-model="pathologistId"
        :validator="$v.pathologistId"
        :validatorMsgMap="validationMsgMap"
      />
      <div class="row align-items-start mb-2">
        <TextInput
          label="<u>F</u>rom Case"
          name="fromCaseNumber"
          ref="fromCase"
          class="col"
          @blur="formatAccessionNumber"
          :validator="$v.fromCaseNumber"
          v-model="fromCaseNumber"
          :validatorMsgMap="validationMsgMap"
        />
        <TextInput
          label="<u>T</u>o Case"
          class="col"
          name="toCaseNumber"
          ref="toCase"
          @blur="formatAccessionNumber"
          v-model="toCaseNumber"
          :validator="$v.toCaseNumber"
          :validatorMsgMap="validationMsgMap"
        />
      </div>
    </div>
    <SubmitCancelRow
      @submit="handleSubmit"
      @cancel="handleCancel"
      :submitShortKey="null"
      :isDisabled="$v.$invalid"
      :isLoading="isLoading"
    />
  </div>
</template>

<script>
import SelectInput from "./common/SelectInput.vue";
import SubmitCancelRow from "./common/SubmitCancelRow.vue";
import TextInput from "./common/TextInput.vue";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import {
  altKey,
  calculateAccessionNumberingWithoutPrevCentury,
  validatorMsgMapBase
} from "../modules/helpers";
import { catchError, switchMap, filter, tap, distinctUntilChanged, map } from "rxjs/operators";
import { CasesApi } from "@/services";
import { scanCaseBarcode } from "@/modules/scanCaseBarcode";
import ScannerDetection from "@/modules/scanner";
import { handleErrors } from "@/modules/handleErrors";

export default {
  components: { SubmitCancelRow, TextInput, SelectInput },
  data() {
    return {
      isLoading: false,
      fromCaseNumber: "",
      toCaseNumber: "",
      fromCaseId: null,
      toCaseId: null,
      pathologistId: null,
      isFromCaseValid: false,
      isToCaseValid: false,
      shortkeys: {
        f: altKey("f"),
        p: altKey("p"),
        s: altKey("s"),
        t: altKey("t")
      },
      hasScannedBarcode: false
    };
  },
  computed: {
    ...mapState({
      pathologists: state => state.dropdowns.pathologists,
      prefixes: state => state.dropdowns.prefixes
    }),
    validationMsgMap() {
      return {
        ...validatorMsgMapBase,
        validCase: "No matching case was found.",
        greaterThanFrom: "To case must be greater than from."
      };
    },
    inSamePrefixGroup() {
      if (this.fromCaseNumber && this.toCaseNumber) {
        const prefixRegex = /^(?<prefix>[a-z]{1,6})[0-9]/i;
        const fromPrefix = prefixRegex.exec(this.fromCaseNumber)?.groups?.prefix?.toUpperCase();
        const toPrefix = prefixRegex.exec(this.toCaseNumber)?.groups?.prefix?.toUpperCase();
        const fromPrefixGroup = this.prefixes.find(e => e.code.toUpperCase() === fromPrefix)?.group;
        const toPrefixGroup = this.prefixes.find(e => e.code.toUpperCase() === toPrefix)?.group;
        return fromPrefixGroup === toPrefixGroup;
      }
      return false;
    }
  },
  subscriptions() {
    return {
      fromCaseGet: this.$watchAsObservable("fromCaseId").pipe(
        filter(({ newValue }) => newValue),
        tap(({ newValue, oldValue }) => {
          if (!this.toCaseId || this.toCaseId === oldValue) {
            this.toCaseId = newValue;
            this.$refs.toCase?.focus();
            this.$refs.toCase?.selectAll();
          }
        }),
        switchMap(({ newValue }) => this.getCase(newValue)),
        tap(caseDetails => {
          this.fromCase = caseDetails;
        }),
        catchError((e, s) => s)
      ),
      clearFromCase: this.$watchAsObservable("fromCaseId").pipe(
        filter(({ newValue, oldValue }) => oldValue && !newValue),
        tap(({ oldValue }) => {
          if (this.toCaseId === oldValue) {
            this.toCaseId = null;
            this.toCase = null;
          }
          this.fromCase = null;
        })
      ),
      formattedFromCaseNumber: this.$watchAsObservable("fromCaseNumber").pipe(
        filter(({ newValue }) => newValue && this.caseNumberWithoutPrefix(newValue).length > 11),
        map(({ newValue }) => newValue),
        tap(value => {
          if (!this.toCaseNumber) {
            this.toCaseNumber = value;
          }
        }),
        switchMap(async value => {
          const caseNumber = scanCaseBarcode(value).caseNumber || value;
          try {
            this.isSearching = true;
            const valueSplit = caseNumber.split("-");
            const isUnique = await CasesApi.searchStore.load({
              filter: [
                ["accessionNumber", "contains", caseNumber],
                "and",
                ["numberSequence", valueSplit[1]]
              ]
            });
            if (isUnique?.length === 1) {
              this.fromCaseId = isUnique[0].caseId;
              if (this.usePathologyNumbering) {
                this.fromCaseNumber = isUnique[0].accessionNumber;
                if (this.fromCaseId === this.toCaseId) {
                  this.toCaseNumber = this.fromCaseNumber;
                }
              }
              this.isFromCaseValid = true;
              if (this.hasScannedBarcode) {
                this.fromCaseId = isUnique[0].caseId;
                this.toCaseId = isUnique[0].caseId;
                setTimeout(() => {
                  this.handleSubmit();
                }, 500);
              }
              return true;
            } else {
              this.fromCaseId = null;
            }
          } catch (error) {
            console.log("error occured fetching case id.");
          } finally {
            this.isSearching = false;
          }
          this.isFromCaseValid = false;
          return false;
        })
      ),
      formattedToCaseNumber: this.$watchAsObservable("toCaseNumber").pipe(
        filter(({ newValue }) => newValue && this.caseNumberWithoutPrefix(newValue).length > 11),
        map(({ newValue }) => newValue),
        distinctUntilChanged(),
        switchMap(async value => {
          const caseNumber = scanCaseBarcode(value).caseNumber || value;
          if (caseNumber === this.fromCaseNumber) {
            this.toCaseId = this.fromCaseId;
            this.toCaseNumber = this.fromCaseNumber;
            this.isToCaseValid = true;
            return true;
          }
          try {
            this.isSearching = true;
            const valueSplit = caseNumber.split("-");
            const isUnique = await CasesApi.searchStore.load({
              filter: [
                ["accessionNumber", "contains", caseNumber],
                "and",
                ["numberSequence", valueSplit[1]]
              ],
              select: ["caseId", "accessionNumber"]
            });
            if (isUnique?.length === 1) {
              this.toCaseId = isUnique[0].caseId;
              if (this.usePathologyNumbering) {
                this.toCaseNumber = isUnique[0].accessionNumber;
              }
              this.isToCaseValid = true;
              return true;
            } else {
              this.toCaseId = null;
            }
          } catch (error) {
            console.log("error occured fetching case id.");
          } finally {
            this.isSearching = false;
          }
          this.isToCaseValid = false;
          return false;
        })
      ),
      toCaseGet: this.$watchAsObservable("toCaseId").pipe(
        filter(({ newValue }) => newValue && newValue !== this.fromCaseId),
        switchMap(({ newValue }) => this.getCase(newValue)),
        tap(caseDetails => {
          this.toCase = caseDetails;
        }),
        catchError((e, s) => s)
      )
    };
  },
  mounted() {
    this.scanner = new ScannerDetection({
      onComplete: this.handleScanBarcode,
      stopPropogation: true,
      minLength: 4
    });
    if (!this.pathologists.length) {
      this.$store.dispatch("dropdowns/getPathologists");
    }
    if (!this.prefixes?.length) {
      this.$store.dispatch("dropdowns/getPrefixes");
    }
    this.$refs.pathologistId.focus();
  },
  beforeDestroy() {
    if (this.scanner?.stopScanning) {
      this.scanner.stopScanning();
    }
  },
  watch: {
    pathologistId: {
      handler(nv) {
        if (nv) {
          this.$refs.fromCase.focus();
        }
      }
    }
  },
  validations() {
    return {
      toCaseNumber: {
        required,
        validCase: async value => (value ? this.isToCaseValid : true),
        greaterThanFrom: value => {
          value = value.replace(/[a-z]/gi, "");
          const matcher = /(\d{2})(\d{2})-(\d{7})/im;
          if (matcher.test(value) && matcher.test(this.fromCaseNumber)) {
            const [fromYear, fromNumberSequence] = this.fromCaseNumber
              .replace(/[a-z]/gi, "")
              .split("-");
            const [toYear, toNumberSequence] = value.split("-");
            return (
              parseInt(toYear) >= parseInt(fromYear) &&
              parseInt(toNumberSequence) >= parseInt(fromNumberSequence)
            );
          }
          return true;
        },
        hasPrefix: value => (value && !this.usePathologyNumbering ? this.hasPrefix(value) : true),
        inSamePrefixGroup: value =>
          value && !this.usePathologyNumbering ? this.inSamePrefixGroup : true
      },
      fromCaseNumber: {
        required,
        validCase: async value => (value ? this.isFromCaseValid : true),
        hasPrefix: value => (value && !this.usePathologyNumbering ? this.hasPrefix(value) : true)
      },
      pathologistId: {
        required
      }
    };
  },
  methods: {
    async handleSubmit() {
      if (this.isLoading) {
        return;
      }
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      const payload = {
        fromCaseId: this.fromCaseId,
        toCaseId: this.toCaseId,
        primaryPathologistId: this.pathologistId
      };
      try {
        this.isLoading = true;
        await CasesApi.batchAssignPathologists(payload);
        window.notify("Assigned pathologist to cases.");
        this.fromCaseNumber = "";
        this.toCaseNumber = "";
        this.fromCaseId = null;
        this.toCaseId = null;
        this.$nextTick(() => this.$refs.fromCase.focus());
      } catch (error) {
        handleErrors(error);
      } finally {
        this.isLoading = false;
        this.hasScannedBarcode = false;
      }
    },
    handleCancel() {
      this.$emit("close");
    },
    formatAccessionNumber(event) {
      const { value } = event.target;
      let prefix = "";
      if (value) {
        if (this.hasPrefix(value)) {
          const regex = /^(?<prefix>[a-z]{1,6})[0-9]/i.exec(value);
          prefix = regex.groups.prefix.toUpperCase();
        }
        let targetNumber = this.caseNumberWithoutPrefix(value);
        if (targetNumber.replace("-", "").length < 11) {
          const number = calculateAccessionNumberingWithoutPrevCentury(targetNumber);
          if (number?.length === 12) {
            this[event.target.name] = prefix + number;
          }
        }
      }
    },
    hasPrefix(value) {
      if (value) {
        const regex = /^(?<prefix>[a-z]{1,6})[0-9]/i.exec(value);
        return Boolean(regex?.groups?.prefix);
      }
      return false;
    },
    caseNumberWithoutPrefix(caseNumber) {
      if (!caseNumber) {
        return "";
      }
      const regex = /^(?<prefix>[a-z]{1,6})[0-9]/i.exec(caseNumber);
      return caseNumber.replace(regex?.groups?.prefix, "");
    },
    handleScanBarcode(barcode) {
      this.devlog("Scanned barcode in Print Labels Popup", barcode);
      const { caseNumber } = scanCaseBarcode(barcode);
      if (!caseNumber) {
        return;
      }
      const activeElement = document.activeElement;
      if (activeElement.nodeName === "INPUT") {
        if (activeElement.name === "fromCaseNumber") {
          this.fromCaseNumber = caseNumber;
        }
        this.toCaseNumber = caseNumber;
        this.$nextTick(() => this.$refs.toCase.selectAll());
        this.hasScannedBarcode = true;
      }
    },
    handleShortkey({ srcKey }) {
      switch (srcKey) {
        case "p":
          this.$refs.pathologistId.focus();
          break;
        case "f":
          this.$refs.fromCase.focus();
          if (this.fromCaseNumber) {
            this.$refs.fromCase.selectAll();
          }
          break;
        case "t":
          this.$refs.toCase.focus();
          if (this.toCaseNumber) {
            this.$refs.toCase.selectAll();
          }
          break;
        case "s":
          this.handleSubmit();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.batch-pathologists {
  width: 95%;
  margin-left: 0.5em;
}
</style>
