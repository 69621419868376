<template>
  <div class="printer-select">
    <label class="d-flex align-items-center" v-if="label" :for="$attrs.name || $attrs.id">
      <b> {{ label }}</b>
      <icon
        v-if="value"
        icon="circle"
        class="mx-1 p-0"
        v-tooltip="tooltip"
        :class="{ 'text-success': status, 'text-danger': !status }"
      ></icon>
    </label>
    <select-input
      v-bind="$attrs"
      v-model="printer"
      :displayExpr="displayExpr"
      :valueExpr="valueExpr"
    ></select-input>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Icon from "./common/Icon.vue";
import SelectInput from "./common/SelectInput.vue";
export default {
  inheritAttrs: false,
  props: {
    value: {
      required: true
    },
    label: {
      required: false,
      type: String
    },
    valueExpr: {
      default: "id",
      type: String,
      required: false
    }
  },
  components: { SelectInput, Icon },
  data() {
    return { status: true, computerName: "" };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        if (nv) {
          this.getPrinterStatus(nv);
        }
      }
    }
  },
  methods: {
    async getPrinterStatus(printerId) {
      const response = await fetch(`https://api.printnode.com/printers/${printerId}`, {
        headers: this.printNodeCredentials
      });
      const data = await response.json();
      if (Array.isArray(data) && data[0]?.id === printerId) {
        const { state, computer } = data[0];
        const printerIsOnline = state === "online";
        const computerIsConnected = computer?.state === "connected";
        this.computerName = computer?.name;
        this.status = printerIsOnline && computerIsConnected;
      }
    },
    displayExpr(data) {
      if (data?.hostName) {
        return data.displayName + " - " + data.hostName;
      } else {
        return data?.displayName || "";
      }
    }
  },
  computed: {
    ...mapState({ LabPrintNodeAPIKey: state => state.labSettings.LabPrintNodeAPIKey }),
    printNodeCredentials() {
      const encodedApiKey = Buffer.from(this.LabPrintNodeAPIKey).toString("base64");
      return {
        Authorization: `Basic ${encodedApiKey}`,
        "Content-Type": "application/json"
      };
    },
    printer: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
        return value;
      }
    },
    tooltip() {
      return `${this.status ? "Online" : "Offline"} ${
        this.computerName ? `(${this.computerName})` : ""
      }`;
    }
  }
};
</script>

<style lang="scss" scoped>
/* .printer-select {
  position: relative;
  .icon {
    position: absolute;
    top: 3px;
    left: 125px;
  }
} */
</style>
