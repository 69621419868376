<template>
  <div class="sidebar-section">
    <button
      @click="handleModeChange(DashboardModes.Cases)"
      v-shortkey="shortkeyAlt('1')"
      @shortkey="handleModeChange(DashboardModes.Cases)"
      v-if="permissions.CaseView"
      class="btn w-100"
      :class="{
        active: mode === DashboardModes.Cases && $route.name === 'Home',
        'dropdown-item': isMobileView
      }"
    >
      <icon icon="circle-notch" />
      <div>Recent Cases</div>
    </button>
    <button
      class="btn w-100"
      @click="handleModeChange(DashboardModes.Search)"
      v-shortkey="shortkeyAlt('2')"
      @shortkey="handleModeChange(DashboardModes.Search)"
      :class="{
        active: mode === DashboardModes.Search && $route.name === 'Home',
        'dropdown-item': isMobileView
      }"
    >
      <icon icon="search" />
      <div>Search</div>
    </button>
    <button
      v-if="permissions.CaseView && reportsCount"
      class="btn w-100"
      @click="handleModeChange(DashboardModes.Reports)"
      v-shortkey="shortkeyAlt('3')"
      @shortkey="handleModeChange(DashboardModes.Reports)"
      :class="{ active: mode === DashboardModes.Reports, 'dropdown-item': isMobileView }"
    >
      <icon icon="file-alt" />
      <div>Reports</div>
    </button>

    <button
      v-if="permissions.TaskCreate && !isMobileView"
      class="btn w-100"
      @click="handleSendMessage"
      v-shortkey="shortkeyAlt('4')"
      @shortkey="handleSendMessage"
      :class="{ active: mode === DashboardModes.Tasks, 'dropdown-item': isMobileView }"
      v-tooltip.top="mode === DashboardModes.Tasks ? 'Show all grids.' : 'Show the tasks grid.'"
    >
      <span v-show="taskCount" :data-testid="`task-count`" :class="`count_icon m-0`">{{
        taskCount
      }}</span>
      <icon icon="envelope" />
      <div>Send Message</div>
    </button>
    <modal :status="isSendingMessage" @close="handleSendMessage">
      <client-task-form @submit="handleSendMessage" @cancel="handleSendMessage" :hideCase="true" />
    </modal>
  </div>
</template>

<script>
import { ProceduresApi, ReportsApi, TaskApi } from "@/services";
import { mapState, mapGetters } from "vuex";
import Icon from "../common/Icon.vue";
import { DashboardModes } from "@/modules/enums";
import Modal from "../common/Modal.vue";
import ClientTaskForm from "../forms/ClientTaskForm.vue";
import { altKey } from "@/modules/helpers";
export default {
  components: { Icon, Modal, ClientTaskForm },
  name: "WebLinkSidebar",
  data() {
    return {
      reqLabelIsOpen: false,
      isSlidePrintingOpen: false,
      taskCount: 0,
      reportsCount: 0,
      proceduresCount: 0,
      DashboardModes,
      isSendingMessage: false,
      hasHighPriorityTask: false
    };
  },
  watch: {
    currentLab: {
      immediate: true,
      handler(nv) {
        ProceduresApi.getLabPendingProceduresCount(nv).then(res => {
          this.proceduresCount = res || 0;
        });
        this.getTaskCount();
      }
    }
  },
  mounted() {
    ReportsApi.getDailyReports(this.currentLab).then(reports => {
      this.reportsCount = reports.length || 0;
    });
  },
  computed: {
    ...mapState({
      currentLab: state => state.currentLab,
      currentUser: state => state.currentUser,
      homeGridMode: state => state.applicationSettings.homeGridMode,
      applicationSettings: state => state.applicationSettings,
      isMobileView: state => state.isMobileView
    }),
    ...mapGetters(["permissions"]),
    mode: {
      get() {
        return this.homeGridMode;
      },
      set(value) {
        this.$store.commit("applicationSettings/setGridMode", value);
        return value;
      }
    },
    accessionFilter: {
      get() {
        return this.applicationSettings.accessionFilter;
      },
      set(value) {
        this.$store.commit("applicationSettings/setAccessionFilter", value);
        return value;
      }
    }
  },
  methods: {
    handleNavigate(route) {
      this.$router.push(route);
    },
    togglePrintingModal() {
      this.isSlidePrintingOpen = !this.isSlidePrintingOpen;
    },
    toggleReqLabelModal() {
      this.reqLabelIsOpen = !this.reqLabelIsOpen;
    },
    handleSendMessage() {
      this.isSendingMessage = !this.isSendingMessage;
    },
    handleModeChange(mode) {
      if (this.$route.name !== "Home") {
        this.$router.push({ name: "Home" });
      }
      this.mode = mode;
      this.homeGridMode = mode;
    },
    handleAccessionFilter(mode) {
      this.mode = 0;
      this.$store.commit("applicationSettings/setAccessionFilter", mode);
    },
    handleAccessionNav() {
      this.$router.push({ name: "Accession" });
    },
    shortkeyAlt(key) {
      return altKey(key);
    },
    getTaskCount() {
      TaskApi.getTaskCounts().then(res => {
        if (typeof res === "number") {
          // to prevent things from breaking while transitioning payload
          this.taskCount = res;
        } else {
          this.taskCount = res?.count || 0;
          this.hasHighPriorityTask = res?.hasHighPriority || false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.sidebar-section {
  width: 100px;
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
  margin-right: 3px;
  & > button {
    position: relative;
    &:nth-child(3),
    &:nth-child(5),
    &:nth-child(6) {
      margin-top: 8px;
    }
    & > div {
      font-weight: 500;
      font-size: 0.85rem;
    }
    &.active {
      opacity: 0.5;
    }
    transition: opacity 150ms;
    background: $primary-light;
    border-radius: 3px;
    padding: 5px 0px;
    margin-bottom: 2.5px;
    text-align: center;
    font-size: 12px;
    & > svg {
      color: $primary;
      font-size: 24px;
    }
  }
}
.count_icon {
  position: absolute;
  top: -8px;
  right: -1px;
  padding: 2px 5px;
  box-sizing: border-box;
  border-radius: 50%;
  background: $primary-light;
  font-weight: 600;
  font-size: 0.75rem;
  border: 1px solid $secondary;
  &.history {
    animation: pulse-red 1s infinite;
  }
}
@media screen and (max-width: 700px) {
  .sidebar-section {
    display: flex;
    width: 100%;
    overflow-y: hidden;
    & > button {
      border-radius: initial;
      background: white;
      color: black;
      margin-bottom: 0px;
      padding: 0.75rem;
      &:not(:last-child) {
        margin-right: 2px;
      }
      opacity: 0.5;
      svg {
        display: none;
      }
      &.active {
        opacity: 1;
      }
      &:nth-child(3),
      &:nth-child(5),
      &:nth-child(6) {
        margin-top: initial;
      }
    }
  }
}
</style>
