<template>
  <div>
    <Modal :status="isPopupOpen" @close="logout">
      <div class="staging-popup">
        <h3 class="text-danger">
          <span v-if="isStaging"
            >This lab is no longer using the staging environment. Please use the URL
            <span class="link-to-navigate" @click="handleNavigate">{{ linkToCopy }}</span> to access
            the app, and update all bookmarks accordingly.
          </span>
          <span v-else-if="userInStaging">
            User is set to use the staging environment. Please use the URL
            <span class="link-to-navigate" @click="handleNavigate">{{ linkToCopy }}</span> to access
            the app. To turn this setting off, please go to the application settings and turn off
            the setting "Use Staging Environment".
          </span>
          <span v-else>
            This lab is using the staging environment during the installation. Please use the URL
            <span class="link-to-navigate" @click="handleNavigate">{{ linkToCopy }}</span> to access
            the app until instructed otherwise.
          </span>
        </h3>
        <div class="d-flex justify-content-end mt-1">
          <button class="btn btn-success mr-1" @click="copyLink">Copy Link</button>
          <button class="btn btn-primary" @click="logout">Ok</button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import eventBus, { OPEN_STAGING_POPUP } from "@/modules/eventBus";
import Modal from "./common/Modal.vue";
import { mapState } from "vuex";

export default {
  components: { Modal },
  data() {
    return {
      isPopupOpen: false,
      isStaging: false
    };
  },
  mounted() {
    eventBus.$on(OPEN_STAGING_POPUP, this.handleOpen);
  },
  beforeDestroy() {
    eventBus.$off(OPEN_STAGING_POPUP);
  },
  computed: {
    ...mapState({
      userInStaging: state => state.applicationSettings.userInStaging
    }),
    linkToCopy() {
      const isUcsf = window.location.href.toLowerCase().includes("ucsf");
      if (this.isStaging) {
        if (isUcsf) {
          return "http://ucsf.intellipathpro.com";
        }
        return "https://www.intellipathpro.com";
      }
      if (isUcsf) {
        return "https://ipathproucsfstaging.z22.web.core.windows.net";
      }
      return "http://staging.intellipathpro.com";
    }
  },
  methods: {
    handleOpen(isStaging) {
      this.isPopupOpen = true;
      this.isStaging = isStaging;
    },
    async logout() {
      await this.$store.dispatch("AUTH_LOGOUT");
      this.$router.push("/login");
      this.isPopupOpen = false;
    },
    copyLink() {
      navigator.clipboard.writeText(this.linkToCopy);
      window.notify("Link copied to clipboard!");
    },
    async handleNavigate() {
      await this.$store.dispatch("AUTH_LOGOUT");
      window.location.href = this.linkToCopy;
    }
  }
};
</script>

<style lang="scss" scoped>
.staging-popup {
  width: 500px;
}
.link-to-navigate {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}
</style>
