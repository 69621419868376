<template>
  <div class="search-wrapper">
    <div class="search-filter ml-3">
      <form @submit.prevent="handleCreateSearch" class="searchHeader">
        <div class="row search-fields">
          <text-input
            class="col-lg-4 col-xl-3"
            label="Patient Field"
            name="patientFieldContains"
            id="patientFieldContains"
            placeholder="Name, SSN, MRN, DOB, Acct#, or Order#"
            v-model="searchFields.patientFieldContains"
            v-if="patientField"
            data-private="redact"
          />
          <text-input
            label="Free Text"
            class="col-lg-4 col-xl-3"
            placeholder="Diagnosis, gross, clinical, microscopic, site, or note"
            v-if="freeTextField"
            v-model="searchFields.freeTextFieldContains"
          />

          <tag-input
            label="Pathologist Name"
            class="col-lg-4 col-xl-3"
            v-if="pathologistField"
            placeholder="Pathologist Name"
            v-model="searchFields.pathologistIds"
            :dataSource="pathologistsDataSource"
            searchExpr="displayName"
          />
          <tag-input
            v-if="providerField"
            label="Provider Name"
            class="col-lg-4 col-xl-3"
            placeholder="Provider Name"
            :dataSource="providersDataSource"
            valueExpr="id"
            :displayExpr="providerFullName"
            v-model="searchFields.providerIds"
            :searchExpr="['firstName', 'lastName']"
          />
          <tag-input
            v-if="macroField"
            placeholder="Results Macro"
            class="col-lg-2"
            label="Results Macro"
            v-model="searchFields.resultsMacroIds"
            :dataSource="resultsMacrosDataSource"
            displayExpr="macroName"
            valueExpr="macroId"
          />
          <text-input label="ICD Code" class="col-lg-2" v-model="searchFields.icdCodeContains" />

          <date-range
            :start="searchFields.accessionedOnOrAfter"
            :end="searchFields.accessionedOnOrBefore"
            v-if="accessionedOnField"
            @input="handleAccessionedRange"
            label="Accessioned Date"
            class="col-lg-2"
            placeholder="Start Date"
            name="accessionedOnOrAfter"
          />

          <date-range
            v-if="collectedOnField"
            label="Collected Date"
            placeholder="Start Date"
            class="col-lg-2"
            name="collectedOnOrAfter"
            @input="handleCollectedRange"
            :start="searchFields.collectedOnOrAfter"
            :end="searchFields.collectedOnOrBefore"
          />

          <date-range
            label="Received Date"
            v-if="receivedField"
            @input="handleReceivedRange"
            class="col-lg-2"
            name="receivedOnOrAfter"
            placeholder="Start Date"
            :start="searchFields.receivedOnOrAfter"
            :end="searchFields.receivedOnOrBefore"
          />

          <date-range
            label="Reported Date"
            v-if="signOnField"
            placeholder="Start Date"
            name="firstSignedOnOrAfter"
            class="col-lg-2"
            @input="handleSignedOnRange"
            :start="searchFields.firstSignedOnOrAfter"
            :end="searchFields.firstSignedOnOrBefore"
          />
        </div>
        <div class="ml-auto my-2 my-lg-0 d-flex">
          <loader size="small" class="mr-2 align-self-center" v-show="isLoading" />
          <button
            type="button"
            @click="clearFilters"
            icon="search"
            class="btn btn-outline-danger mr-1"
          >
            <span class="ml-1">Clear Filters</span>
          </button>
          <icon-button :disable="isLoading" type="submit" icon="search" class="btn-primary"
            ><span class="ml-1">Search</span></icon-button
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import TextInput from "../common/TextInput.vue";
import {
  CLEAR_FREE_TEXT_FILTERS,
  RENDER_FREE_TEXT_SEARCH,
  SET_FREE_TEXT_SEARCH_FILTERS
} from "@/modules/constants";
import IconButton from "../common/IconButton.vue";
import DateRange from "../common/DateRange.vue";
import eventBus from "@/modules/eventBus";
import Loader from "../common/Loader.vue";
import TagInput from "../common/TagInput.vue";
import { DropdownApi, MacrosApi, ProvidersApi } from "@/services";
import DataSource from "devextreme/data/data_source";
import { MacroTypeEnum } from "@/modules/enums";
export default {
  components: {
    TextInput,
    IconButton,
    DateRange,
    Loader,
    TagInput
  },
  name: "Search-Header",
  data() {
    return {
      isLoading: false,
      searchFields: {},
      defaultFields: {
        patientFieldContains: "",
        caseNumber: null,
        prefixIds: [],
        prefixTagIds: [],
        freeTextFieldContains: "",
        specimenSiteContains: "",
        resultsMacroIds: [],
        providerIds: [],
        providerLocationNames: [],
        providerLocationCities: [],
        providerLocationStates: [],
        providerLocationZipCodes: [],
        pathologistIds: [],
        accessioners: [], //email
        grossers: [], //id
        modifiers: [], //email
        accessionedOnOrAfter: null,
        accessionedOnOrBefore: null,
        collectedOnOrAfter: null,
        collectedOnOrBefore: null,
        receivedOnOrAfter: null,
        receivedOnOrBefore: null,
        signedOnOrBefore: null,
        signedOnOrAfter: null,
        firstSignedOnOrBefore: null,
        firstSignedOnOrAfter: null,
        modifiedOnOrAfter: null,
        modifiedOnOrBefore: null,
        cptCodeIds: [],
        icdCodeContains: "",
        holdCodeIds: [],
        showSignedCases: false,
        readyToRead: false
      },
      providers: {
        names: [],
        locations: [],
        cities: [],
        states: [],
        zips: []
      },
      users: {
        ids: [],
        emails: []
      },
      nameSearch: ["firstName", "lastName"],
      prefixTagOptions: []
    };
  },
  mounted() {
    this.searchFields = { ...this.defaultFields };
  },
  methods: {
    handleCreateSearch() {
      if (this.checkFields(this.searchFields)) {
        this.isLoading = true;
        eventBus.$once(RENDER_FREE_TEXT_SEARCH, () => {
          this.isLoading = false;
        });
        eventBus.$emit(SET_FREE_TEXT_SEARCH_FILTERS, this.searchFields);
      } else {
        window.notify("Please enter a search criteria.", "warning");
      }
    },
    checkFields(obj) {
      const keysWithValue = [];
      for (const key in obj) {
        const value = obj[key];
        if (value !== null && value !== undefined && value !== "") {
          keysWithValue.push(key);
        }
      }
      return keysWithValue.length > 0;
    },
    clearFilters() {
      this.searchFields = { ...this.defaultFields };
      eventBus.$emit(CLEAR_FREE_TEXT_FILTERS);
    },
    handleAccessionedRange(event) {
      this.searchFields.accessionedOnOrAfter = event[0];
      this.searchFields.accessionedOnOrBefore = event[1];
    },
    handleSignedOnRange(event) {
      this.searchFields.firstSignedOnOrAfter = event[0];
      this.searchFields.firstSignedOnOrBefore = event[1];
    },
    handleReceivedRange(event) {
      this.searchFields.receivedOnOrAfter = event[0];
      this.searchFields.receivedOnOrBefore = event[1];
    },
    handleCollectedRange(event) {
      this.searchFields.collectedOnOrAfter = event[0];
      this.searchFields.collectedOnOrBefore = event[1];
    },
    providerFullName({ lastName, firstName }) {
      return lastName + ", " + firstName;
    }
  },
  computed: {
    ...mapState({
      currentLab: state => state.currentLab,
      currentUser: state => state.currentUser,
      casesSearchHeader: state => state.applicationSettings.casesSearchHeader,
      ApplyPathologistSearchFilter: state => state.labSettings.ApplyPathologistSearchFilter
    }),
    ...mapGetters(["permissions"]),
    patientField() {
      const {
        CaseSearchByPatientMRN,
        CaseSearchByPatientAccountNumber,
        CaseSearchByPatientName,
        CaseSearchByPatientSSN,
        SocialSecurityNumberView,
        CaseSearchByCaseOrderNumber
      } = this.permissions;
      return (
        CaseSearchByPatientMRN ||
        CaseSearchByPatientAccountNumber ||
        CaseSearchByPatientName ||
        (CaseSearchByPatientSSN && SocialSecurityNumberView) ||
        CaseSearchByCaseOrderNumber
      );
    },
    pathologistField() {
      return this.permissions.CaseSearchByPathologistName;
    },
    macroField() {
      return this.permissions.CaseSearchBySpecimenResultMacroName;
    },
    freeTextField() {
      const {
        CaseSearchBySpecimenNote,
        CaseSearchBySpecimenMicroscopic,
        CaseSearchBySpecimenGross,
        CaseSearchBySpecimenDiagnosis,
        CaseSearchBySpecimenClinical,
        CaseSearchByCaseNotes
      } = this.permissions;
      return (
        CaseSearchBySpecimenNote ||
        CaseSearchBySpecimenMicroscopic ||
        CaseSearchBySpecimenGross ||
        CaseSearchBySpecimenDiagnosis ||
        CaseSearchBySpecimenClinical ||
        CaseSearchByCaseNotes
      );
    },
    providerField() {
      return this.permissions.CaseSearchByProviderName;
    },
    icdCodeField() {
      return this.permissions.CaseSearchBySpecimenIcdCode;
    },
    accessionedOnField() {
      return this.permissions.CaseSearchByCaseAccessionedOn;
    },
    collectedOnField() {
      return this.permissions.CaseSearchByCaseCollectedOn;
    },
    signOnField() {
      return this.permissions.CaseSearchByCaseFirstSignedOn;
    },
    receivedField() {
      return this.permissions.CaseSearchByCaseReceivedOn;
    },
    providersDataSource() {
      return new DataSource({
        store: ProvidersApi.searchProviders,
        sort: "lastName"
      });
    },
    pathologistsDataSource() {
      return new DataSource({
        store: DropdownApi.searchPathologists,
        sort: "displayName"
      });
    },
    // userDataSource() {
    //   return new DataSource({
    //     store: UsersApi.searchStore,
    //     sort: "lastName"
    //   });
    // },
    // prefixDataSource() {
    //   return new DataSource({
    //     store: PrefixApi.searchStore,
    //     sort: "code"
    //   });
    // },
    resultsMacrosDataSource() {
      return new DataSource({
        store: MacrosApi.searchStore,
        filter: ["macroType", MacroTypeEnum.Results],
        sort: ["macroName"]
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.search-filter {
  border-radius: 3px;
  color: $text-primary;
  width: 100%;
  &__icon {
    color: $primary;
  }
}
.btn-search {
  line-height: 1;
  background: $primary;
  border-color: $primary;
}
.searchHeader {
  padding: 0.25rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.search-wrapper {
  display: flex;
}
@media screen and (min-width: 1024px) {
  .search-wrapper {
    display: flex;
    background-color: $primary-light;
  }
  .searchHeader {
    flex-direction: row;
  }
}
.search-fields {
  width: 100%;
}
.alerts-section {
  background: $primary-dark;
  color: $white;
  padding: 10px 20px 10px 20px;
  &__title {
    font-size: 1.25rem;
  }
  &__body {
    font-size: 1rem;
    margin-top: 5px;
    .alert-row {
      border-bottom: 1px solid #4e8ba3;
      padding: 5px;
    }
  }
  .active {
    font-weight: 700;
    font-size: 1.2rem;
  }
}
</style>