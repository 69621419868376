import api from "./api.js";
class Printers {
  constructor() {
    this.url = "/api/Printers";
  }
  /**
   *
   * @param {Object} payload
   * @property {number} payload.locationId The id of the target location
   * @property {string} payload.apiKey The api key of the target location
   * @returns
   */
  refreshLocationPrinters(payload) {
    return api.put(this.url + "/RefreshByLocation", payload);
  }
  refreshLabPrinters(apiKey) {
    return api.put(this.url + "/RefreshByLab", { apiKey });
  }
  updatePrinter(printer) {
    return api.put(this.url, printer);
  }
  updatePrinterMappings(printerData) {
    return api.put("/api/PrinterMappings", printerData);
  }
  getPrinterMappings(printerId) {
    return api.get(this.url + "/" + printerId);
  }
  getLocationPrintersSource(locationId) {
    return api.createSearch("/api/Locations/" + locationId + "/Printers");
  }
  getLabPrinters() {
    return api.get(this.url + "/Lab");
  }
  getAllPrinters() {
    return api.get(this.url + "/AllPrinters");
  }
  getLabBinMaps() {
    return api.get(this.url + "/BinMaps");
  }
  createBinMap(data) {
    return api.post(this.url + "/BinMaps", data);
  }

  deleteBinMap(data) {
    return api.delete(this.url + "/BinMaps", data);
  }
  updateBinMap(data) {
    return api.put(this.url + "/BinMaps", data);
  }
  getBinMapById(binMapId) {
    return api.get(this.url + `/BinMaps/${binMapId}`);
  }
}
export default new Printers();
