<template>
  <div v-if="multiple" data-cy="prefix">
    <label v-html="displayName(accessKey, 'Prefix')" id="prefixLabel" for="prefixes" />
    <DxTagBox
      v-model="prefixes"
      placeholder="Select Prefix..."
      :show-selection-controls="true"
      search-expr="code"
      display-expr="code"
      search-mode="startswith"
      :data-source="dataSource"
      :maxDisplayTags="5"
      @focusOut="focusOut"
      @focusIn="isFocused = true"
      @initialized="initialize"
      ref="main"
      :showDropDownButton="true"
      :search-enabled="true"
      :isValid="isValid"
      @value-changed="onValueChanged"
      :dropdownOptions="dropdownOptions"
    />

    <div v-if="(validator && validator.$error) || (validator && validator.$invalid)">
      <div
        class="validation-error"
        v-for="(key, index) in Object.keys(validator.$params)"
        :key="index"
      >
        <span class="error" v-if="!validator[key]">
          {{ validatorMsgMap[key] }}
        </span>
      </div>
    </div>
  </div>
  <div v-else class="text-input" :data-testid="`${name}-select`">
    <label for="prefix"><b>Prefix</b></label>
    <DxSelectBox
      search-expr="code"
      display-expr="code"
      ref="main"
      v-model="prefixes"
      :showDropDownButton="true"
      :search-enabled="true"
      :valueExpr="valueExpr"
      :disabled="disabled"
      @initialized="initialize"
      @focusOut="focusOut"
      :input-attr="attr"
      @value-changed="onValueChanged"
      :items="items"
      @focusIn="isFocused = true"
      item-template="item"
      placeholder="Select Prefix..."
      searchMode="startswith"
    >
      <template v-slot:item="{ data }">
        <div>{{ data.code }} {{ data.numberingType === "Manual" ? "(Manual)" : "" }}</div>
      </template>
    </DxSelectBox>
  </div>
</template>

<script>
import Prefix from "../../../services/prefix";
import DxTagBox from "devextreme-vue/tag-box";
import { mapState } from "vuex";
import { dateRangeFilter, validatorMsgMapBase } from "../../../modules/helpers";
import DataSource from "devextreme/data/data_source";
import DxSelectBox from "devextreme-vue/select-box";

export default {
  name: "Prefix",
  inheritAttrs: false,

  components: {
    DxTagBox,
    DxSelectBox
  },
  props: {
    value: {
      required: true
    },
    validator: {
      required: false
    },
    accessKey: {
      default() {
        return "";
      }
    },
    multiple: {
      default() {
        return false;
      }
    },
    valueExpr: {
      default() {
        return "id";
      }
    },
    disabled: {
      default() {
        return false;
      }
    }
  },
  data: () => ({
    dropdownOptions: {
      position: "bottom"
    },
    inputAttr: {
      name: "prefix",
      id: "prefixInput",
      "aria-labelledby": "prefixLabel"
    },
    name: "prefix",
    main: {},
    isFocused: false,
    dataSource: new DataSource({
      store: Prefix.searchStore,
      filter: dateRangeFilter(),
      sort: ["code"]
    })
  }),
  mounted() {
    if (!this.prefixes?.length) {
      this.$store.dispatch("dropdowns/getPrefixes");
    }
  },
  methods: {
    onValueChanged(e) {
      this.main.close();
      this.prefixes = e.value;
    },
    initialize({ component }) {
      this.main = component;
    },
    focus() {
      if (!this.isFocused) {
        this.main.focus();
        if (!this.value) {
          this.main.open();
        }
      }
    },
    focusOut() {
      this.main.close();
      this.isFocused = false;
    },
    displayName(key = "", name) {
      if (key) {
        const regex = new RegExp(key, "i");
        if (regex.test(name)) {
          const { index } = name.match(regex);
          return `<b>${name.slice(0, index)}<u>${name[index]}</u>${name.slice(index + 1)}</b>`;
        }
      }
      return `<b>${name}</b>`;
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser,
      currentLab: state => state.currentLab,
      items: state => state.dropdowns.prefixes
    }),
    attr() {
      return { name: "prefix", id: "prefix" };
    },
    isValid() {
      if (this.validator) {
        return !this.validator.$invalid || !this.validator.$error;
      }
      return true;
    },
    prefixes: {
      get() {
        return this.value;
      },
      set(value) {
        return this.$emit("input", value);
      }
    },
    validatorMsgMap() {
      return { ...validatorMsgMapBase };
    }
  }
};
</script>

<style lang="scss" scoped>
.custom__tag {
  background-color: $neutral;
  color: #35495e;
}
.manual {
  background-color: $neutral;
  color: #35495e;
}
</style>
