<template>
  <Container class="p-1">
    <DxList
      ref="reportsGrid"
      @initialized="initGrid"
      :dataSource="reportsStore"
      :columns="columns"
      :onItemClick="viewReport"
      :noDataText="'No reports found.'"
    >
      <template v-slot:item="{ data }">
        <div class="card shadow">
          <div class="card-title d-flex align-items-center">
            <h5 class="m-0">{{ data.name }}</h5>
          </div>
          <div class="card-body">
            <div class="card-text">
              <p>{{ data.name }}</p>
              <p>Group: {{ getGroupName(data.reportGroupId) }}</p>
              <p v-show="data.description">
                {{ data.description }}
              </p>
            </div>
            <div>
              <div class="" v-if="isEditing && currentReport.id === data.id">
                <ReportForm @close="handleClose" v-model="currentReport" />
              </div>
              <div v-if="isViewing && currentReport.id === data.id">
                <daily-report-viewer
                  v-if="isViewing && currentReport"
                  :reportId="currentReport.id"
                  :reportName="currentReport.name"
                  @close="handleCloseReport"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </DxList>
  </Container>
</template>

<script>
import ReportService from "@/services/Reports";
import eventBus, { CHANGE_GROUP } from "@/modules/eventBus";
import DxList from "devextreme-vue/list";
import ReportForm from "@/components/forms/ReportForm";
import { mapGetters, mapState } from "vuex";
import { ReportsApi } from "@/services";
import { filterCellUTC, formatDatetimeCell } from "@/modules/helpers";
import Container from "@/components/common/Container.vue";
import DailyReportViewer from "./Reports/DailyReportViewer.vue";
export default {
  components: {
    DxList,
    ReportForm,
    Container,
    DailyReportViewer
  },
  props: ["selectedGroup"],
  name: "ReportsList",
  data() {
    return {
      view: "pdf",
      reportProps: null,
      reportsStore: [],
      group: null,
      reportUrl: null,
      isViewing: false,
      isEditing: false,
      grid: {},
      labs: [],
      isLoading: false,
      mode: null,
      currentReport: null,
      parameters: {}
    };
  },
  computed: {
    ...mapState(["currentUser", "reportViewer", "currentLab", "isMobileView"]),
    ...mapState({
      groups: state => state.dropdowns.reportGroups
    }),
    ...mapGetters("report", ["supportsPDF"]),
    columns() {
      return [
        {
          dataField: "name",
          dataType: "string"
        },
        {
          dataField: "description",
          dataType: "string"
        },
        {
          dataField: "lastRunOn",
          dataType: "datetime",
          calculateCellValue(data) {
            if (data.lastRunOn) {
              return formatDatetimeCell(data.lastRunOn);
            }
            return "";
          },
          calculateFilterExpression: filterCellUTC("lastRunOn")
        },
        {
          dataField: "lastRunBy",
          dataType: "string"
        },
        {
          dataField: "Actions",
          type: "buttons",
          cellTemplate: "actions"
        },
        {
          dataField: "reportGroupId",
          caption: "Group",
          type: "number",
          lookup: {
            valueExpr: "id",
            displayExpr: "displayName",
            dataSource: { key: "id", store: this.groups }
          }
        }
      ];
    },
    reportParams() {
      if (this.currentReport?.id && this.currentReport.reportParams) {
        return JSON.parse(this.currentReport.reportParams)?.filter(
          param => param.ParameterVisibility === "Visible" && !/labId/i.test(param.Name)
        );
      }
      return [];
    }
  },
  created() {
    eventBus.$on(CHANGE_GROUP, group => {
      this.group = group;
    });
    this.$store.dispatch("dropdowns/getReportGroups");
    ReportsApi.getDailyReports(this.currentLab).then(reports => {
      this.reportsStore = reports || [];
    });
  },
  beforeDestroy() {
    eventBus.$off(CHANGE_GROUP);
  },
  watch: {
    selectedGroup(nv) {
      this.handleGroupFilter(nv);
    }
  },
  methods: {
    initGrid({ component }) {
      this.grid = component;
    },
    handleCloseReport() {
      this.reportProps = null;
      this.currentReport = null;
    },
    async refreshReportService() {
      try {
        this.isLoading = true;
        const response = await ReportService.SSRSSYNC();
        if (response?.deletedReportsStillUsed?.length) {
          window.alert(`
          <h6>The following entities no longer have a valid path report.</h6>
          <ol>
          ${response.deletedReportsStillUsed.map(item => `<li>${item}</li>`).join("")}
          </ol>
          `);
        }
        ReportsApi.getDailyReports(this.currentLab).then(reports => {
          this.reportsStore = reports || [];
        });
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        window.notify("Error occurred", "error");
      }
    },
    getGroupName(reportGroupId) {
      const groupObject = this.groups.find(e => e.id === reportGroupId);
      return groupObject?.displayName ?? "";
    },
    async viewReport({ itemData }) {
      this.currentReport = itemData;
      this.isViewing = true;
      this.isEditing = false;
      this.reportUrl = null;
    },
    async handleEdit(data) {
      if (data.id != this.currentReport?.id && (this.isViewing || this.isEditing)) {
        const confirm = await window.confirm(
          "You may have unsaved data.<br> Do you wish to continue?"
        );
        if (!confirm) {
          return;
        }
      }
      this.currentReport = data;
      this.reportUrl = null;
      this.isEditing = true;
      this.isViewing = false;
    },
    async handleDelete(data) {
      this.isViewing = false;
      this.currentReport = null;
      this.reportUrl = null;
      this.isEditing = false;
      const confirm = await window.confirm(
        "WARNING: This action is irreversible.<br> Do you wish to continue?"
      );
      if (!confirm) {
        return;
      }
      await ReportService.deleteReport(data);
      ReportsApi.getDailyReports(this.currentLab).then(reports => {
        this.reportsStore = reports || [];
      });
    },
    handleClose() {
      this.isViewing = false;
      this.currentReport = null;
      this.reportUrl = null;
      this.isEditing = false;
      ReportsApi.getDailyReports(this.currentLab).then(reports => {
        this.reportsStore = reports || [];
      });
    },
    submitReportParameters(event) {
      const props = {
        format: !this.supportsPDF ? 5 : 0, //Report should be an image.
        outputFormat: !this.supportsPDF ? "jpeg" : "pdf",
        reportId: this.currentReport.id,
        parameters: [],
        startPage: 1
      };
      const formValues = new FormData(event.target);
      formValues.append("LabId", this.currentLab);
      const formValuesObject = Object.fromEntries(formValues.entries());
      for (const param of Object.keys(formValuesObject)) {
        props.parameters.push({
          name: param,
          value: formValuesObject[param]
        });
      }
      this.reportProps = props;
    },
    createParamField(parameter) {
      const props = {
        name: parameter.Name,
        label: parameter.Prompt,
        id: parameter.Name,
        ref: parameter.Name,
        is: "TextInput"
      };
      switch (parameter.ParameterType) {
        case "DateTime":
          props.type = "date";
          break;
        default:
          props.type = "text";
          break;
      }
      return props;
    }
  }
};
</script>
<style lang="scss" scoped>
.card {
  display: block;
  .card-title {
    margin-bottom: 2px;
  }
  .card-body {
    padding: 0px;
  }
  .avatar {
    display: flex;
    margin-right: 5px;
    & > img {
      align-self: center;
      width: 50px;
      height: 50px;
    }
  }
  p {
    margin-bottom: 1px;
  }
}
::v-deep .dx-item.dx-list-item {
  margin: 10px auto 0px auto;
  border-top: initial;
  &.dx-state-active,
  &.dx-state-focused {
    color: initial !important;
  }
}
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
</style>
