<template>
  <button
    @click="toggleOpen"
    class="menu btn nav-link"
    v-clickOutside="handleClickOutside"
    :aria-expanded="open"
  >
    <span v-html="displayName({ name, key: hotkey })"> </span>
    <icon class="ml-2" :icon="caret" />
    <ul
      aria-label="admin menu"
      class="menubar root-level"
      role="menubar"
      tabindex="-1"
      ref="dropdown"
      :class="['dropdown_menu', direction]"
      v-if="open"
    >
      <li
        :aria-haspopup="value.type === 'submenu' ? true : false"
        @click.stop
        v-for="(value, index) in calculatedItems"
        :key="index"
      >
        <router-link
          role="menuitem"
          v-if="value.type != 'submenu'"
          v-html="displayName(value)"
          active-class="router-link-active"
          v-shortkey="altKey(value.key)"
          @shortkey.native="navigate(value.route)"
          :to="{ name: value.route }"
          @keydown.native.enter.stop="navigate(value.route)"
          class="btn nav-link list-item"
          :id="handleId(value.name)"
        />
        <admin-menu
          role="menuitem"
          ref="innerMenu"
          :name="value.name"
          type="submenu"
          data-type="nav"
          tabindex="0"
          v-shortkey="altKey(value.key)"
          @shortkey.native="handleSubMenuOpen(value)"
          @close="focusFirstEl"
          @open="closeOthers"
          :hotkey="value.key"
          :direction="value.direction || 'right'"
          :id="handleId(value.name)"
          class="nav-link btn dropdown-item"
          :items="value.items"
          v-else
        />
      </li>
    </ul>
  </button>
</template>

<script>
import { fromEvent, merge } from "rxjs";
import { catchError, filter, map, tap } from "rxjs/operators";
import Icon from "../common/Icon.vue";
import { altKey } from "@/modules/helpers";
export default {
  components: { Icon },
  name: "AdminMenu",
  props: {
    name: {
      type: String
    },
    items: {
      type: Array
    },
    direction: {
      type: String,
      default() {
        return "down";
      }
    },
    depth: {
      type: Number,
      default() {
        return 0;
      }
    },
    hotkey: {
      type: String
    },
    type: {
      default() {
        return "menu";
      }
    }
  },
  data() {
    return {
      keybinds: [],
      open: false
    };
  },
  beforeDestroy() {
    if (this.open) {
      return (this.open = false);
    }
  },
  mounted() {
    if (this.type === "menu") {
      const keydown$ = fromEvent(this.$el, "keydown");
      const escape$ = keydown$.pipe(filter(event => event.keyCode === 27));
      const downArrow$ = keydown$.pipe(
        filter(event => event.keyCode === 40),
        map(() => {
          const allMenuItems = this.$el.querySelectorAll('[role="menuitem"]');
          const currentFocusIdx = Array.from(allMenuItems).findIndex(
            element => element === document.activeElement
          );
          if (allMenuItems[currentFocusIdx + 1]) {
            return allMenuItems[currentFocusIdx + 1];
          } else {
            return allMenuItems[0];
          }
        })
      );

      const rightArrow$ = keydown$.pipe(
        filter(event => {
          if (event.target.dataset.type === "nav") {
            return event.keyCode === 39;
          }
        }),
        tap(e => {
          e.target.click();
        })
      );
      const leftArrow$ = keydown$.pipe(
        map(event => {
          if (event.keyCode === 37) {
            const allSubmenus = this.$el.querySelectorAll('[data-type="nav"]');
            const targetParent = Array.from(allSubmenus).find(element =>
              element.contains(event.target)
            );
            if (targetParent.dataset.type === "nav") {
              return targetParent;
            }
          }
        }),
        filter(element => !!element),
        tap(targetParent => {
          targetParent.click();
          targetParent.focus();
        })
      );
      const upArrow$ = keydown$.pipe(
        filter(event => event.keyCode === 38),
        map(() => {
          const allMenuItems = this.$el.querySelectorAll('[role="menuitem"]');
          const currentFocusIdx = Array.from(allMenuItems).findIndex(
            element => element === document.activeElement
          );

          if (currentFocusIdx === 0) {
            return allMenuItems[Array.from(allMenuItems).length - 1];
          }
          if (allMenuItems[currentFocusIdx - 1]) {
            return allMenuItems[currentFocusIdx - 1];
          } else {
            return allMenuItems[0];
          }
        })
      );
      this.$subscribeTo(
        merge(rightArrow$, leftArrow$).pipe(
          catchError((error, source$) => {
            return source$;
          })
        )
      );
      this.$subscribeTo(
        merge(upArrow$, downArrow$).pipe(
          catchError((error, source$) => {
            return source$;
          })
        ),
        element => {
          element.focus();
        }
      );
      this.$subscribeTo(escape$, () => {
        this.toggleOpen();
      });
    }
  },

  computed: {
    calculatedItems() {
      return this.items
        .filter(navItem => {
          return navItem.permission;
        })
        .sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    currentRoute() {
      return this.$route.name;
    },
    caret() {
      switch (this.direction) {
        case "right":
          return "caret-right";
        case "left":
          return "caret-left";
        default:
          return "caret-down";
      }
    }
  },
  watch: {
    currentRoute(nv, ov) {
      if (nv != ov) {
        return this.$nextTick(() => {
          this.open = false;
        });
      }
    },
    open(nv) {
      if (nv) {
        this.focusFirstEl();
        this.currentFocus = 0;
      } else {
        this.items.forEach(menuItem => {
          if (menuItem.status) {
            menuItem.status = false;
          }
        });
      }
    }
  },
  methods: {
    keyBoardShortcut(value) {
      if (value.type === "submenu") {
        this.handleSubMenuOpen(value);
      } else {
        this.handleRoute(value.route);
      }
    },
    navigate(name) {
      if (this.$route.name !== name) {
        this.$router.push({ name });
      }
    },
    toggleOpen() {
      this.open = !this.open;
    },
    focusFirstEl() {
      this.$nextTick(() => {
        const allMenuItems = this.$el.querySelectorAll('[role="menuitem"]');
        if (allMenuItems.length) {
          allMenuItems[0].focus();
        }
      });
    },
    closeOthers(name) {
      if (this.$refs.innerMenu) {
        this.$refs.innerMenu.forEach(menu => {
          if (menu.name !== name) {
            menu.open = !menu.open;
          }
        });
      }
    },
    displayName({ key, name }) {
      if (key) {
        const regex = new RegExp(key, "i");
        if (regex.test(name)) {
          const { index } = name.match(regex);
          return `${name.slice(0, index)}<u>${name[index]}</u>${name.slice(index + 1)}`;
        }
      }
      return name;
    },
    handleId(value) {
      return (
        value
          .split(" ")
          .join("")
          .replace(/[^a-z0-9]/i, "") + "_route"
      );
    },
    handleClickOutside() {
      if (this.open) {
        return (this.open = false);
      }
    },
    handleSubMenuOpen(value) {
      if (this.$refs.innerMenu) {
        this.$refs.innerMenu.forEach(menu => {
          if (menu.name !== value.name) {
            menu.handleClickOutside();
          } else {
            if (!menu.open) {
              menu.open = !menu.open;
            }
          }
        });
      }
    },
    handleRoute(route) {
      if (this.currentRoute === route) {
        return;
      }
      this.open = false;
      this.$router.push({ name: route });
    },
    altKey(key) {
      return altKey(key);
    }
  },
  directives: {
    clickOutside: {
      bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          // here I check that click was outside the el and his childrens
          if (!(el == event.target || el.contains(event.target))) {
            // and if it did, call method provided in attribute value
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
        document.body.addEventListener("touchstart", el.clickOutsideEvent);
      },
      unbind: function (el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
        document.body.removeEventListener("touchstart", el.clickOutsideEvent);
      },
      stopProp(event) {
        event.stopPropagation();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.router-link-active {
  color: $white !important;
  background: $secondary !important;
  text-decoration: underline;
  border-top: 5px solid $secondary !important;
}
.menu {
  position: relative;
  border-radius: 0 !important;
}
.down {
  top: 0;
  left: 0;
  transform: translate3d(0px, 35px, 0px);
}
.right {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropdown_menu {
  position: absolute;
  padding: 0;
  margin: 0.55rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0 !important;
  z-index: 999;
}

.nav-dropdown {
  cursor: pointer;
  color: #1795d2;
  padding: 0.5rem 1rem;
  border-top: 5px solid white;
  background: #fff;
}
.menu_wrapper {
  width: 90vw;
  height: 90vh;
}
ul {
  &:focus {
    outline: none;
  }
}

.nav-link {
  color: $primary;
  height: 100%;
  border-top: 1px solid $gray;
  background: $white;
  border-radius: 0 !important;
  text-align: left;
  &:hover,
  &:focus {
    color: $white;
    background: $secondary !important;
    border-top: 1px solid $secondary !important;
  }
}

.nav-link.active {
  color: $white;
  background: $secondary;
  border-top: 1px solid $primary;
}

.admin-dropdown > li > a.nav-link.active {
  color: $white;
  background: $secondary;
  border-top: 5px solid $secondary;
}
</style>
