<template>
  <div data-cy="clientGrid" class="d-flex flex-column">
    <div class="utils d-flex align-items-center">
      <h4 class="col mt-3">Cases</h4>
      <div class="d-flex align-items-center">
        <loader size="small" v-show="isLoading" />
        <icon-button
          type="button"
          @click="markAll"
          v-tooltip="' Click to mark all selected cases as viewed.'"
          class="btn text-primary pointer"
          icon="eye"
        />
        <icon-button
          v-if="permissions.CaseView"
          type="button"
          @click="exportToExcel"
          v-tooltip="'Click to export as excel file'"
          class="btn text-primary pointer"
          icon="file-excel"
        />
        <icon-button
          v-if="permissions.CaseView"
          type="button"
          @click="handleDownloadCases"
          v-tooltip="
            `Click to download selected Cases. '\n Shift+Click to download and mark as viewed.' 
            `
          "
          icon="download"
          class="btn text-primary pointer"
        />
        <icon-button
          v-if="permissions.PathReportView"
          type="button"
          @click="handlePrintPathReports"
          v-tooltip="`Click to print all selected Cases `"
          icon="print"
          class="btn text-primary pointer"
        />

        <icon-button
          v-if="permissions.PathReportSendByFax"
          type="button"
          v-tooltip="'Click to fax selected Cases.'"
          class="mx-1 btn text-primary pointer"
          icon="fax"
          @click="handleDistributeCases(DistributionMethodsEnum.Fax)"
        />
        <icon-button
          v-if="permissions.PathReportSendByEmail"
          type="button"
          v-tooltip="'Click to email selected Cases.'"
          class="mx-1 btn text-primary pointer"
          @click="handleDistributeCases(DistributionMethodsEnum.Email)"
          icon="envelope"
        />
        <icon-button
          v-if="permissions.PathReportSendByHL7"
          type="button"
          v-tooltip="'Click to use hl7 on selected Cases.'"
          class="mx-1 btn text-primary pointer"
          icon="h-square"
          @click="handleDistributeCases(DistributionMethodsEnum.HL7)"
        />

        <icon-button
          v-if="permissions.PathReportSendByFileDrop"
          type="button"
          v-tooltip="'Click to send by file drop.'"
          class="mx-1 btn text-primary pointer"
          icon="file-pdf"
          @click="handleDistributeCases(DistributionMethodsEnum.FileDrop)"
        />
      </div>
    </div>
    <dx-data-grid
      class="caseGrid"
      ref="caseGrid"
      :dataSource="Cases"
      noDataText="No Cases found."
      :pageSize="10"
      :show-borders="true"
      :show-row-lines="true"
      :column-auto-width="true"
      :selection="selection"
      :scrolling="scrolling"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      columnResizingMode="widget"
      :remoteOperations="remoteOperations"
      :hoverStateEnabled="true"
      :columns="columns"
      @cellClick="handleCaseClick"
      @initialized="initializeGrid"
      :onCellPrepared="redactLogRocket"
      :cacheEnabled="false"
    >
      <DxPaging :pageSize="15" />
      <DxSelection v-bind="selection" />
      <template v-slot:actionsTemplate="{ data: { data } }">
        <div class="d-flex align-items-center justify-content-center">
          <icon-button
            v-bind="getStatusIcon(data)"
            v-tooltip.right="getStatusIcon(data).tooltip"
            class="btn pointer"
          />
          <icon-button
            v-tooltip.right="data.isViewed ? 'Case has been viewed.' : 'Mark case as viewed.'"
            v-if="canViewCase(data)"
            @click="markAsViewed(data)"
            :icon="!Boolean(data.isViewed) ? 'eye-slash' : 'eye'"
            class="btn text-primary pointer mr-2 p-0"
          />
          <icon-button
            v-if="permissions.TaskCreate"
            icon="envelope"
            @click="createRequest(data)"
            v-tooltip.right="'Send pathologist request.'"
            class="btn text-primary pointer p-0"
          />
        </div>
      </template>
    </dx-data-grid>
    <modal :status="isRequestOpen" @close="handleCloseRequest">
      <client-task-form
        :caseId="requestCaseId"
        @submit="handleCloseRequest"
        @cancel="handleCloseRequest"
      />
    </modal>
    <Modal :status="isDistributionOpen" @close="isDistributionOpen = false">
      <distribution-popup
        :method="distributionMethod"
        :caseIds="selectedCasesForDistribution"
        @close="isDistributionOpen = false"
      />
    </Modal>
  </div>
</template>

<script>
import CaseService from "../services/cases";
import { mapGetters, mapState } from "vuex";
import moment from "moment";
import { CaseStatusEnum, DistributionMethodsEnum } from "@/modules/enums";
import { DxPaging, DxSelection } from "devextreme-vue/data-grid";
import { DxDataGrid } from "devextreme-vue/data-grid";
import Reports from "../services/Reports";
import Modal from "./common/Modal.vue";
import { AuditLogApi, CasesApi, DropdownApi, PathReportMessagesApi } from "../services";
import {
  createLogItem,
  filterAccessionNumber,
  getStatusIcon,
  pastLabDelay,
  formatSSN
} from "@/modules/helpers";
import IconButton from "./common/IconButton.vue";
import printJS from "print-js";
import { format } from "date-fns";
import Loader from "./common/Loader.vue";
import DistributionPopup from "./DistributionPopup.vue";
import ClientTaskForm from "./forms/ClientTaskForm.vue";
export default {
  name: "Cases-Grid-Client-User",
  components: {
    DxPaging,
    DxSelection,
    Modal,
    DxDataGrid,
    IconButton,
    Loader,
    DistributionPopup,
    ClientTaskForm
  },
  activated() {
    if (this.grid.reload) {
      this.grid.reload();
    }
  },
  data() {
    return {
      isSelecting: false,
      selectedItems: [],
      allSelected: false,
      Cases: CasesApi.searchStore,
      isDistributionOpen: false,
      selectedCasesForDistribution: [],
      taskPriorities: [],
      grid: {},
      distributionMethod: "",
      DistributionMethodsEnum,
      isFiltered: false,
      popupVisible: false,
      isRequestOpen: false,
      requestCaseId: null,
      report: {},
      pathReport: {},
      isLoading: false,
      scrolling: {
        showScrollbar: "always",
        useNative: true,
        preloadEnabled: true
      },
      selection: {
        mode: "multiple",
        allowSelectAll: true,
        showCheckBoxesMode: "always",
        selectAllMode: "page"
      },
      remoteOperations: {
        filtering: true,
        sorting: true,
        paging: true
      },
      caseGrid: {},
      grouping: {
        allowCollapsing: true
      },
      fixedFilters: null,
      today: moment().toDate(),
      lastMonth: moment().subtract(30, "days").toDate()
    };
  },
  created() {
    this.$store.dispatch("dropdowns/getPriorities");
    DropdownApi.getTaskPriorities().then(res => {
      this.taskPriorities = res || [];
    });
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser,
      isReportOpen: state => state.report.isOpen,
      reportViewer: state => state.reportViewer,
      caseStatuses: state => state.caseStatuses,
      caseFilter: state => state.applicationSettings.accessionFilter,
      caseMode: state => state.applicationSettings.accessionMode,
      priorities: state => state.dropdowns.priorities,
      useZip: state => state.labSettings.DownloadMultiplePathReportsAsZipFile
    }),
    ...mapGetters(["permissions"]),
    isProvider() {
      const { isLabClientUser } = this.currentUser;
      if (isLabClientUser) {
        return true;
      }
      return false;
    },
    collectedOnFilter() {
      if (this.isFiltered) {
        return [this.lastMonth, this.today];
      }
      return null;
    },
    isViewedFilter() {
      if (this.isFiltered) {
        return false;
      }
      return null;
    },
    filterValues() {
      if (this.isFiltered) {
        return [
          ["collectedOn", ">=", this.lastMonth],
          "and",
          ["collectedOn", "<=", this.today],
          "and",
          ["isViewed", "=", false]
        ];
      } else {
        return [];
      }
    },
    statusFilter() {
      if (this.caseFilter === "open") {
        //! Cases whos staus is 1,2,3,4,5,6,7,9,10,12,13
        return [CaseStatusEnum.ReportedPrelim, CaseStatusEnum.Reported, CaseStatusEnum.ReReleased];
      }
      return null;
    },
    columns() {
      //Determines which columns to render depending if the user is a iPathWebLink User or a  iPathPro
      return [
        {
          type: "buttons",
          cellTemplate: "actionsTemplate",
          caption: "Action",
          width: "100px"
        },
        {
          dataField: "patientName",
          dataType: "string",
          allowReordering: true
        },
        {
          dataField: "caseStatus",
          dataType: "number",
          filterType: "include",
          lookup: {
            dataSource: this.caseStatuses.map((e, i) => ({ displayName: e, id: i })),
            displayExpr: "displayName",
            valueExpr: "id"
          }
        },
        {
          dataField: "patientSSN",
          allowReordering: true,
          caption: "SSN",
          dataType: "string",
          allowSearch: false,
          calculateDisplayValue: data => {
            if (data.patientSSN) {
              return formatSSN(data.patientSSN);
            }
            return "";
          }
        },
        {
          dataField: "patientMRN",
          allowReordering: true,
          caption: "MRN",
          dataType: "string"
        },
        {
          dataField: "accessionNumber",
          allowReordering: true,
          sortOrder: "asc",
          sortIndex: 1,
          caption: "Case #",
          dataType: "string",
          calculateFilterExpression: filterAccessionNumber(
            "accessionNumber",
            this.$store.state.labSettings.AccessionNumberingType
          )
        },
        {
          dataField: "providerName",
          allowReordering: true,
          allowSorting: true,
          caption: "Physician",
          dataType: "string"
        },
        {
          dataField: "collectedOn",
          caption: "Procedure Date",
          dataType: "date",
          sortIndex: 0,
          sortOrder: "desc",
          selectedFilterOperation: "between",
          filterType: "include",
          filterValue: this.collectedOnFilter
        },
        {
          dataField: "providerOfficeName",
          allowReordering: true,
          caption: "Location",
          dataType: "string"
        },
        {
          dataField: "isViewed",
          dataType: "boolean",
          visible: false,
          allowSearch: false,
          filterValue: this.isViewedFilter
        }
      ];
    }
  },
  watch: {
    isProvider(nv, ov) {
      if (ov != nv) {
        this.pageOffset = 0;
        this.refreshGrid(true);
      }
    },
    isDistributionOpen(nv) {
      if (!nv) {
        this.selectedCasesForDistribution = [];
        this.distributionMethod = "";
      }
    },
    caseFilter: {
      immediate: true,
      handler(nv) {
        if (nv === "all") {
          if (this.grid.clearFilters) {
            this.grid.clearFilters();
          }
        } else {
          if (this.grid.columnOptions) {
            this.grid.columnOptions("caseStatus", "filterValue", this.statusFilter);
          }
        }
      }
    },
    isFiltered: {
      immediate: true,
      handler(nv) {
        if (nv) {
          if (this.grid.refresh) {
            this.grid.refresh(true);
          }
        }
      }
    }
  },
  methods: {
    initializeGrid(event) {
      this.grid = event.component;
    },
    handleCloseRequest() {
      this.requestCaseId = null;
      this.isRequestOpen = false;
    },
    handleSelectAll() {
      if (!this.allSelected) {
        this.grid.selectAll();
        this.allSelected = true;
      } else {
        this.grid.unselectAll();
        this.allSelected = false;
      }
    },
    handleSelecting() {
      if (this.isSelecting) {
        this.grid.unselectAll();
      }
      this.isSelecting = !this.isSelecting;
    },
    isItemSelected(caseId) {
      const inSelected = this.selectedItems.find(e => e.caseId === caseId);
      return !!inSelected;
    },
    selectionChanged(event) {
      this.selectedItems = [...this.selectedItems, ...event.addedItems];
      this.selectedItems = this.selectedItems.filter(e => {
        //Check if Item has been removed
        const inRemoved = event.removedItems.find(r => r.caseId === e.caseId);
        return !inRemoved;
      });
    },
    formatDate(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    handleCaseClick(data) {
      if (data.key) {
        if ((data?.columnIndex && data.columnIndex < 2) || data?.column?.type === "selection") {
          return;
        }
        this.$router.push(`/accession/${data.key}`);
      }
    },
    getStatusIcon,
    createRequest(data) {
      this.requestCaseId = data.caseId;
      this.isRequestOpen = true;
    },
    refreshGrid() {
      if (this.grid.refresh) {
        this.grid.refresh(true);
      }
    },
    getStatus({ caseStatus }) {
      if ([8, 11, 14].includes(caseStatus)) {
        return this.caseStatuses[caseStatus];
      }
      return "Pending";
    },
    handleViewPathReport(caseId) {
      this.$store.dispatch("report/viewPathReport", { caseId });
    },
    async handleDownloadCase({ data }) {
      try {
        const response = Reports.getSSRSReport({ caseId: data.caseId, format: "PDF" });
        const url = URL.createObjectURL(response);
        var a = document.createElement("a");
        a.href = url;
        a.download = `${data.accessionNumber}_REPORT.pdf`;
        a.click();
      } catch (error) {
        window.notify("Error occured downloading case report.");
      }
    },
    exportToExcel() {
      const selectedRows = this.grid.getSelectedRowsData();
      if (selectedRows.length) {
        return this.grid.exportToExcel(true);
      }
      return this.grid.exportToExcel();
    },
    filterRecent() {
      this.isFiltered = !this.isFiltered;
    },
    markAsViewed(data) {
      const { isViewed, caseId } = data;
      const userMeta = {
        isViewed: !isViewed,
        viewedOn: new Date(),
        caseId
      };
      return CaseService.updateUserMeta(userMeta).then(() => {
        this.grid.clearSelection();
        this.grid.refresh();
      });
    },
    markAll() {
      const data = this.grid.getSelectedRowsData();
      const readyCases = data.filter(e => !e.isViewed && [8, 11, 14].includes(e.caseStatus));
      if (data.length == 1 && ![8, 11, 14].includes(data[0].caseStatus)) {
        return window.alert("Case is still pending and cannot be marked as viewed.");
      }
      if (readyCases.length !== data.length) {
        return window.alert("Pending cases cannot be marked as viewed.");
      }
      return Promise.all(readyCases.map(e => this.markAsViewed(e))).then(() => {
        this.grid.refresh(true);
        this.grid.clearSelection();
      });
    },
    getLocation(data) {
      const contactEntry = data?.contacts?.find(e => e.isPrimary);
      if (contactEntry) {
        const {
          contact: { displayName }
        } = contactEntry;
        return displayName || "N/A";
      }
      return "N/A";
    },
    getFullName(data) {
      return data.patientLastName + ", " + data.patientFirstName;
    },
    async handlePrintPathReports() {
      let cases = this.grid.getSelectedRowsData();
      if (cases.length) {
        cases = cases.filter(e => [8, 11, 14].includes(e.caseStatus));
      }
      if (cases.length === 0) {
        window.notify("No eligible cases selected.", "error");
        return;
      }
      this.isLoading = true;
      try {
        const file = await CasesApi.getMultiCasePdf(cases.map(e => e.caseId));
        const url = URL.createObjectURL(file);
        PathReportMessagesApi.addPathReportMessage({
          caseIds: cases.map(e => e.caseId),
          method: 7
        });
        const logItem = createLogItem({}, 9, "Client Cases Grid");
        logItem.comments =
          "Printed the following pdfs " + cases.map(e => e.accessionNumber).join(" \n ");
        AuditLogApi.insertLogMessage(logItem);
        printJS({
          printable: url,
          type: "pdf",
          documentTitle: `${format(new Date(), "MM/dd/yyyy")}_PATH_REPORTS.pdf`
        });
      } catch (error) {
        window.notify("Error printing cases.", "error");
      }
      this.isLoading = false;
    },
    async handleDownloadCases() {
      let cases = this.grid.getSelectedRowsData();
      const selectedCaseCount = cases.length;
      if (cases.length) {
        cases = cases.filter(e => [8, 11, 14].includes(e.caseStatus));
      }
      if (cases.length === 0) {
        if (selectedCaseCount) {
          return window.notify("Cases may only be downloaded in Reported status.", "error");
        }
        return window.notify("No Cases selected.", "error");
      }
      this.isLoading = true;
      try {
        const caseIds = cases.map(e => e.caseId);
        const extension = this.useZip && caseIds.length > 1 ? "zip" : "pdf";
        const file = await CasesApi.getMultiCasePdf(caseIds, extension);
        const url = URL.createObjectURL(file);
        const a = document.createElement("a");
        a.href = url;
        if (cases.length === 1) {
          a.download = `${format(new Date(), "MM/dd/yyyy")}_${
            cases[0]?.accessionNumber || ""
          }_PATH_REPORT.pdf`;
        } else {
          a.download = `${format(new Date(), "MM/dd/yyyy")}_PATH_REPORTS.${extension}`;
        }
        a.click();
        PathReportMessagesApi.addPathReportMessage({
          caseIds: cases.map(e => e.caseId),
          method: 6
        });
        if (selectedCaseCount > cases.length) {
          window.notify(
            "On or more cases were not able to be downloaded because they were not in Reported status.",
            "warning"
          );
        }
        const logItem = createLogItem({}, 9, "Accession Grid");
        logItem.comments =
          "Downloaded the following pdfs " + cases.map(e => e.accessionNumber).join(" \n ");
        AuditLogApi.insertLogMessage(logItem);
      } catch (error) {
        window.notify("Error loading file", "error");
      }
      this.isLoading = false;
    },
    async handleDistributeCases(method) {
      let cases = this.grid.getSelectedRowsData();
      if (cases.length === 0) {
        return window.notify("No Cases selected.", "error");
      }
      this.selectedCasesForDistribution = cases
        .filter(e => [8, 11, 14].includes(e.caseStatus))
        .map(e => e.caseId);
      if (!this.selectedCasesForDistribution.length) {
        return window.alert("Case(s) cannot be distributed, due to their status");
      } else if (cases.length !== this.selectedCasesForDistribution.length) {
        await window.alert("Some of your cases cannot be distributed, due to their status");
      }
      if (cases.length === 1) {
        const { caseStatus, patientFirstName, patientLastName } = cases[0];
        if (![8, 11, 14].includes(caseStatus)) {
          return window.alert(
            `<div class="text-center">
          Accession is still pending, and cannot be printed. <br> Patient Name : ${patientLastName}, ${patientFirstName}
          </div>`
          );
        }
      }
      this.distributionMethod = method;
      this.isDistributionOpen = true;
    },
    canViewCase(data) {
      if (
        [
          CaseStatusEnum.ReportedPrelim,
          CaseStatusEnum.Reported,
          CaseStatusEnum.ReReleased
        ].includes(data.caseStatus) &&
        this.permissions.PathReportView
      ) {
        return pastLabDelay(data.lastSignedOn);
      }
      return false;
    }
  }
};
</script>
<style lang="scss" scoped>
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #2196f3;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
.menu-btn {
  position: relative;
  span {
    display: block;
    background-color: gray;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    margin: 1px;
  }
  & > div {
    display: none;
    position: absolute;
    left: -180px;
    z-index: 900;
    background-color: #fff;
    width: 200px;
    border: 1px solid gray;
    ::v-deep button {
      display: flex;
      align-items: center;
      & > svg {
        width: 10%;
        margin: 5px;
      }
      &:not(:last-child) {
        border-bottom: 1px solid gray;
      }
      font-weight: bold;
      width: 100%;
    }
  }
  &:focus-within > div {
    display: flex;
    flex-direction: column;
  }
}
.card {
  display: block;
  .card-title {
    margin-bottom: 2px;
  }
  .card-body {
    padding: 10px;
  }
  .avatar {
    display: flex;
    margin-right: 5px;
    & > img {
      align-self: center;
      width: 50px;
      height: 50px;
    }
  }
  p {
    margin-bottom: 1px;
  }
}
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
::v-deep .dx-state-active {
  color: initial !important;
}
::v-deep
  .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)
  > td:not(.dx-focused) {
  cursor: pointer;
}
</style>
