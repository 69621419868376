<template>
  <transition name="modal" v-if="status">
    <div v-shortkey="['esc']" @shortkey="close" class="modal-mask" @keydown="checkKeyEvent">
      <div ref="modalContainer" class="rounded modal__container d-flex flex-column">
        <div class="d-flex justify-content-between close__header rounded">
          <icon-button
            v-if="showMove"
            class="close-btn btn text-white"
            icon="arrows-alt"
            ref="arrowButton"
            @mousedown="handleMouseDown"
          />
          <div v-else />
          <icon-button
            class="close-btn btn text-white"
            icon="times"
            ref="button"
            @click.prevent="close"
          />
        </div>
        <div class="modal-wrapper rounded p-1">
          <div class="modal-container p-1">
            <div class="modal__body" :style="`min-width:${minWidth};max-width:${maxWidth}`">
              <slot></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import IconButton from "./IconButton.vue";
export default {
  components: { IconButton },
  name: "Modal",
  props: {
    status: {
      required: true
    },
    minWidth: {
      default: "25vw"
    },
    maxWidth: {
      default: "100%"
    },
    showMove: {
      default: false
    }
  },
  data() {
    return {
      prevScroll: null
    };
  },
  watch: {
    status: {
      immediate: true,
      handler: function (nv) {
        if (nv) {
          this.prevScroll = window.pageYOffset;
          document.body.classList.add("modal-open");
        } else {
          document.body.classList.remove("modal-open");
          this.$forceUpdate();
          requestAnimationFrame(() => {
            this.$nextTick(() => {
              window.scrollTo(0, this.prevScroll);
            });
          });
        }
      }
    }
  },
  mounted() {
    if (this.showMove) {
      window.addEventListener("mouseup", this.handleMouseUp, true);
    }
  },
  methods: {
    close() {
      this.$emit("close", !this.status);
    },
    checkKeyEvent(event) {
      // close modal and return early if escape
      if (event.key === "Escape") {
        this.close();
        return;
      }
      const focusableList = this.$refs.modalContainer?.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );

      if (!focusableList?.length) {
        return;
      }
      // escape early if only 1 or no elements to focus
      if (focusableList.length < 2 && event.key === "Tab") {
        event.preventDefault();
        return;
      }
      const last = focusableList.length - 1;
      if (event.key === "Tab" && event.shiftKey === false && event.target === focusableList[last]) {
        event.preventDefault();
        focusableList[0].focus();
      } else if (
        event.key === "Tab" &&
        event.shiftKey === true &&
        event.target === focusableList[0]
      ) {
        event.preventDefault();
        focusableList[last].focus();
      }
    },
    handleMouseDown() {
      window.addEventListener("mousemove", this.handleMouseMove, true);
    },
    handleMouseUp() {
      window.removeEventListener("mousemove", this.handleMouseMove, true);
    },
    handleMouseMove(event) {
      event.preventDefault();
      const divOverlay = this.$refs.modalContainer;
      var deltaX = event.movementX;
      var deltaY = event.movementY;
      var rect = divOverlay.getBoundingClientRect();
      if (rect) {
        divOverlay.style.position = "absolute";
        divOverlay.style.left = rect.x + deltaX + "px";
        divOverlay.style.top = rect.y + deltaY + "px";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.modal__container {
  background-color: $primary-darker;
  margin-bottom: -5px;
  display: flex;
  flex-direction: column;
  padding: 5px 40px 40px 40px;
}
.close-btn {
  color: $white;
  width: 40px;
  border-bottom: none;
  &:hover {
    opacity: 0.5;
  }
}
.modal-mask {
  transition: opacity 0.3s ease;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-wrapper {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}
.hide_close {
  .close-btn {
    opacity: 0;
  }
}
.modal__body {
  max-height: 85vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  /* width */
  & > div {
    max-width: 60vw;
  }
  &::-webkit-scrollbar {
    width: 10px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
    display: none;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $text-primary;
    border-radius: 10px;
    height: 25px;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $primary;
  }
}
/* .modal-container {
} */
.modal-enter {
  opacity: 0;
}
.btn {
  color: rgb(153, 153, 153);
  font-weight: 900;
  font-size: 20px;
  text-align: center;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  opacity: 0.5;
}
.no-scroll {
  overflow-y: hidden;
}
</style>
