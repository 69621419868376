import { ProceduresApi } from "../services";
import { cloneDeep, sortBy, uniqBy, unset } from "lodash";
import { addTextToMacro, getTextFromHtml } from "./helpers";
import { Delta2HTML, HTML2Delta } from "./Quill/HTMLConverter";
import { getDefaultStyles } from "./getDefaultStylesByField";
import store from "@/store";

const macroEnabledFields = [
  "generalText",
  "clinical",
  "gross",
  "results",
  "caseNotes",
  "diagnosis",
  "specimenNotes",
  "notes",
  "microscopic",
  "diagnosisSummaryId",
  "interfaceDiagnosis",
  "cptCodes",
  "icdCodes",
  "procedures"
];

/**
 *
 * @param {Array} specimens //List of target specimens to apply the given macros to.
 * @param {Array} macros //List of macros found that need to me applied.
 * @param {String} phrase //Word user typed that would trigger the flow.
 * @param {String} fieldName //The name of the editor that triggered the function.
 * @param {Boolean} ignoreMultipleResults //If specimen already has results macros, don't add anything else.
 * @returns {Array} Updated Specimens with the macro data.
 */

export async function mergeSpecimensWithMacros(
  specimens,
  macros,
  phrase,
  fieldName,
  ignoreMultipleResults
) {
  let outputSpecimens = cloneDeep(specimens);
  let copyOfPrefix = "" + phrase;
  const phraseTarget = phrase
    .split(/([.|\\])/i)
    .filter(e => e)
    .map(e => {
      const macroName = e.toLowerCase().trim();
      let isNewLine = false;
      const indexOfMacroName = copyOfPrefix.toLowerCase().indexOf(macroName);
      if (indexOfMacroName > 0) {
        const newLineMatcher = new RegExp(`\\\\${RegExp.escape(macroName)}`, "i");
        const fullString = copyOfPrefix.substring(indexOfMacroName - 1, macroName.length + 1);
        isNewLine = newLineMatcher.test(fullString);
        copyOfPrefix = copyOfPrefix.replace(fullString, "");
      }
      return [macroName.replace(/;[dsmc]/i, ""), isNewLine];
    });

  const macroMap = macros.reduce((acc, curr) => {
    if (acc[curr?.macroName?.toLowerCase()]) {
      return acc;
    }
    return { ...acc, [curr.macroName.toLowerCase()]: curr };
  }, {});

  const loggedMacros = phraseTarget
    .map(([macroName]) => {
      const macro = cloneDeep(macroMap[macroName]);
      if (macro) {
        return {
          id: macro.macroId,
          displayName: macro.macroName,
          isDeleted: false,
          macroType: macro.macroType
        };
      }
      return null;
    })
    .filter(macro => macro && [0, 5].includes(macro.macroType));

  const labProcedures = await ProceduresApi.getLabProcedures(store.state.currentLab);
  outputSpecimens.forEach(specimen => {
    if (ignoreMultipleResults && specimen.resultsMacros?.length) {
      return;
    }
    phraseTarget.forEach(([targetMacro, isNewLine]) => {
      let macro = cloneDeep(macroMap[targetMacro]);
      if (!macro) {
        return;
      }
      const macroNameRegExp = new RegExp(
        `[.|\\\\]?${RegExp.escape(macro.macroName)}(;[dsmc])`,
        "i"
      );
      if (macroNameRegExp.test(phrase)) {
        const match = phrase.match(macroNameRegExp);
        const targetField = match[1].replace(";", "");
        macro = getSingleMacroHTML(targetField, macro);
      }
      macroEnabledFields.forEach(property => {
        if (macro[property]) {
          if (property === "generalText" && fieldName) {
            //Is a general Macro
            if (!macro.insertWithFormatting && getTextFromHtml(macro.generalText)) {
              macro[fieldName] = `<span style="${getDefaultStyles(fieldName)}">${getTextFromHtml(
                addTextToMacro(macro.generalText)
              )}</span>`;
            }
          } else if (property === "specimenNotes") {
            property = "notes";
            macro[property] = addTextToMacro(macro.specimenNotes);
          } else if (Array.isArray(macro[property])) {
            if (property === "cptCodes") {
              specimen[property] = uniqBy(
                [
                  ...macro[property].map(cptCode => {
                    if (typeof cptCode === "object" && cptCode) {
                      return cptCode;
                    }
                    if (typeof cptCode === "number" || typeof cptCode === "string") {
                      return { id: cptCode };
                    }
                  }),
                  ...(specimen[property] || [])
                ],
                "id"
              );
            } else if (property === "procedures" && macro?.procedures?.length) {
              ProceduresApi.applyProcedures({
                note: "Added by macro: " + macro.macroName,
                items: macro[property].map(procedureId => {
                  const procedureData = labProcedures.data.find(e => e.id === procedureId);
                  return {
                    ...procedureData,
                    blockNum: procedureData.defaultBlockNum,
                    procedureId: procedureId,
                    specimenId: specimen.id
                  };
                })
              })
                .then(res => {
                  if (res?.validationErrors?.length) {
                    window.notify(
                      "Error occurred adding order: " + res.validationErrors.join(", "),
                      "error"
                    );
                  } else {
                    window.notify("Added order to specimen.");
                  }
                  return store.dispatch("accessionStore/getCaseQuickLinks", specimen.caseId);
                })
                .catch(error => {
                  if (/duplicate/i.test(error?.response?.data)) {
                    window.notify(
                      "Error adding order: Order already exists for selected block.",
                      "error"
                    );
                  } else {
                    window.notify("Error adding order.", "error");
                  }
                });
            } else if (property === "icdCodes") {
              if (macro[property]?.length) {
                specimen.icdEngineMacros = [...(specimen.icdEngineMacros || []), macro];
              }
            } else {
              specimen[property] = uniqBy(
                [...macro[property], ...(specimen[property] ?? [])],
                "id"
              );
            }
          } else if (property === "diagnosisSummaryId") {
            specimen[property] = macro[property];
          } else if (property === "interfaceDiagnosis") {
            if (specimen?.resultsMacros?.length === 0 && !specimen.interfaceDiagnosis) {
              specimen[property] = macro[property];
            }
          } else {
            const timeUsed = macro?.timeUsed || new Date().getTime();
            const macroDelta = HTML2Delta(addTextToMacro(macro[property], timeUsed) || "");
            const dataText = macroDelta
              .filter(op => typeof op.insert === "string")
              .map(op => op.insert)
              .join("");
            if (dataText?.length) {
              const specimenDelta = HTML2Delta(specimen[property] ?? "");
              let specimenText = specimenDelta
                .filter(op => typeof op.insert === "string")
                .map(op => op.insert)
                .join("");
              if (specimenDelta.ops?.length === 1) {
                specimenDelta.ops = specimenDelta.filter(op => typeof op !== "string");
              }
              if (isNewLine && specimenText) {
                specimenDelta.insert("\n");
              } else if (specimenText?.length) {
                specimenDelta.insert(" ");
              }
              specimen[property] = Delta2HTML(specimenDelta.concat(macroDelta));
            }
          }
        }
      });
    });
  });
  if (loggedMacros.length) {
    outputSpecimens.forEach(specimen => {
      if (Array.isArray(specimen?.resultsMacros)) {
        specimen.resultsMacros = [...specimen.resultsMacros, ...loggedMacros];
      } else {
        specimen.resultsMacros = loggedMacros;
      }
    });
  }
  return sortBy(outputSpecimens, ["specimenOrder"]);
}

export function getSingleMacroHTML(query, macro) {
  query = query.toLowerCase();
  const macroClone = cloneDeep(macro);
  let fieldsToClear = [];
  switch (query) {
    case "d": {
      fieldsToClear = ["microscopic", "specimenNotes", "caseNotes"];
      break;
    }
    case "s": {
      fieldsToClear = ["diagnosis", "microscopic", "caseNotes"];
      break;
    }
    case "m": {
      fieldsToClear = ["diagnosis", "specimenNotes", "caseNotes"];
      break;
    }
    case "c": {
      fieldsToClear = ["diagnosis", "microscopic", "specimenNotes"];
      break;
    }
    default: {
      fieldsToClear = [];
    }
  }
  fieldsToClear.forEach(field => {
    unset(macroClone, field);
  });
  return macroClone;
}
