<template>
  <div class="main-section">
    <client-header class="mb-1 header" v-if="isClientUser" />
    <common-case class="header mb-1" v-else />

    <div class="main-view" v-if="isClientUser">
      <container>
        <client-case-view :signedOn="caseDetailsState.lastSignedOutOn" />
      </container>
    </div>
    <div class="main-view" v-else>
      <CaseSidebar />
      <container class="mx-1">
        <loader v-if="isLoading" class="mx-auto" />
        <template v-else>
          <router-view name="default" class="py-2" />
          <PathReportViewer v-if="isInline" class="mt-2" @close="closeReport" />
        </template>
      </container>
      <QuickLinks class="ml-1" />
      <PhysicianPopup />
    </div>
  </div>
</template>

<script>
import PathReportViewer from "@/components/Reports/PathReportViewer.vue";
import CaseSidebar from "@/components/Sidebars/CaseSidebar";
import QuickLinks from "@/components/Sidebars/QuickLinks";
import CommonCase from "@/components/Headers/CommonCase.vue";
import Loader from "@/components/common/Loader.vue";
import { mapGetters, mapState } from "vuex";
import Container from "@/components/common/Container.vue";
import physicianPopup from "@/mixins/physicianPopup.js";
import PhysicianPopup from "@/components/PhysicianPopup.vue";
import ClientHeader from "@/components/Headers/ClientHeader.vue";
import ClientCaseView from "@/components/ClientCaseView.vue";
import { CaseStatusEnum } from "@/modules/enums";
import { pastLabDelay } from "@/modules/helpers";
import { ImagesApi } from "@/services";

export default {
  components: {
    PathReportViewer,
    CaseSidebar,
    QuickLinks,
    CommonCase,
    Loader,
    Container,
    PhysicianPopup,
    ClientHeader,
    ClientCaseView
  },
  mixins: [physicianPopup],
  data() {
    return { isLoading: true };
  },
  computed: {
    ...mapGetters("report", ["isInline"]),
    ...mapGetters("accessionStore", ["primaryPathologist"]),
    ...mapState({
      currentUser: state => state.currentUser,
      isClientUser: state => state.currentUser.isLabClientUser,
      labSettings: state => state.labSettings,
      caseImages: state => state.accessionStore.caseImages,
      applicationSettings: state => state.applicationSettings
    }),
    ...mapGetters(["permissions"])
  },
  methods: {
    closeReport() {
      this.$store.commit("report/toggleReportViewer", false);
    },
    async loadAccession(caseId) {
      if (this.isClientUser) {
        this.loadPatientCases(this.$route.params.caseId);
        return;
      }
      this.isLoading = true;
      try {
        this.$store.commit("accessionStore/clearStore");
        const requiredCalls = [
          this.$store.dispatch("accessionStore/getCaseDetails", caseId),
          this.$store.dispatch("accessionStore/getCaseSpecimens", caseId)
        ];
        this.$store.dispatch("accessionStore/getCaseHeader", caseId).then(caseHeader => {
          if (
            [CaseStatusEnum.Reported, CaseStatusEnum.ReReleased].includes(caseHeader?.status) &&
            (this.permissions.CaseEditAfterReportedDiagnostic ||
              this.permissions.CaseEditAfterReportedDiagnostic)
          ) {
            this.$store.commit("accessionStore/setReReportPopup", true);
          }
          if (
            [
              CaseStatusEnum.Signed,
              CaseStatusEnum.SignedAgain,
              CaseStatusEnum.SignedOnHold
            ].includes(caseHeader?.status)
          ) {
            window.alert(
              "<p style='color: red; font-size: 1.2rem;'><b>WARNING:</b> This case has been signed. <br/> You will <b>NOT</b> be allowed to make any changes, until it is un-signed by the <b>primary pathologist</b>.</p>"
            );
          }
        });
        await Promise.all(requiredCalls);
        await this.$store.dispatch("accessionStore/getCaseQuickLinks", caseId);
        const autoOpenReqSlip =
          this.applicationSettings.autoOpenReqSlip != null
            ? this.applicationSettings.autoOpenReqSlip
            : this.labSettings.AutoOpenReqSlip;
        if (autoOpenReqSlip) {
          this.openReqImages();
        }
        if (this.permissions.PopupView) {
          await this.$store.dispatch("accessionStore/getInsurances", caseId);
          const confirmStatus = await this.getPhysicianPopup(2); //Case View
          if (!confirmStatus) {
            window.notify("User cancelled action!", "error");
            this.$router.push("/");
          }
        }
      } catch (error) {
        console.log(error);
        let message = "";
        if (error?.response) {
          const { response } = error;
          const { data } = response;
          if (data?.statusCode === 401) {
            message = "User is missing one or more permissions required to load case.";
          } else if (data?.statusCode && data?.error) {
            message = `${data?.statusCode}: "${data?.error}"`;
          }
        } else if (typeof error === "object" && Object.keys(error)?.length) {
          if (error?.message) {
            message = error.message;
          } else {
            message = JSON.stringify(error);
          }
        } else {
          message = error;
        }
        window
          .alert(`Error loading case, please check with an administrator<br><br>${message}`)
          .then(() => {
            return this.$router.push("/");
          });
      } finally {
        this.isLoading = false;
        this.$store.dispatch("report/viewPathReport", { caseId });
      }
    },
    async loadPatientCases(caseId) {
      this.$store.dispatch("accessionStore/getCaseHeader", caseId);
      const { status, lastSignedOutOn } = await this.$store.dispatch(
        "accessionStore/getCaseDetails",
        caseId
      );
      if (
        [CaseStatusEnum.Reported, CaseStatusEnum.ReReleased].includes(status) &&
        pastLabDelay(lastSignedOutOn)
      ) {
        this.$store.dispatch("report/viewPathReport", { caseId });
      } else {
        this.$store.dispatch("report/clearReport");
      }
    },
    openReqImages() {
      if (this.caseImages?.length) {
        ImagesApi.getImageTypes().then(res => {
          const reqTypeIds = res.filter(e => /req/i.test(e.displayName)).map(e => e.id);
          if (reqTypeIds.length) {
            const reqImages = this.caseImages.filter(e => reqTypeIds.includes(e.imageTypeId));
            if (reqImages.length) {
              return this.$store.dispatch(
                "sessionDetails/useImageFrame",
                reqImages.map(e => e.id)
              );
            }
          }
          this.$store.commit("sessionDetails/setImageFrameStatus", false);
        });
      } else {
        this.$store.commit("sessionDetails/setImageFrameStatus", false);
      }
    }
  },
  watch: {
    "$route.params.caseId"(nv, ov) {
      if (parseInt(nv) !== ov) {
        this.loadAccession(parseInt(nv));
      }
    }
  },
  created() {
    this.loadAccession(this.$route.params.caseId);
  },
  beforeDestroy() {
    this.$store.commit("accessionStore/clearStore");
    this.$store.commit("sessionDetails/setImageFrameData", []);
    if (this.isInline) {
      this.$store.commit("report/toggleReportViewer", false);
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  flex-shrink: 0;
}
.main-view {
  overflow: hidden;
  flex-grow: 1;
  display: flex;
}
.main-section {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
}
</style>
