import api from "./api.js";
class ProceduresApi {
  constructor() {
    this.url = "/api/Procedures";
  }
  get proceduresStore() {
    return api.createSearch("/api/cases/Procedures/search");
  }

  getLabProcedures(labId) {
    return api.get(`/api/Labs/${labId}/Procedures`);
  }
  getLabProceduresStore(labId) {
    return api.createSearch(`/api/Labs/${labId}/Procedures`);
  }
  getProcedure(procedureId) {
    return api.get(this.url + `/${procedureId}`);
  }
  getUserProcedures() {
    return api.get("/api/GetQuickLinks");
  }
  getLabPendingProcedures(labId) {
    return api.createSearch(`/api/Labs/${labId}/PendingProcedures`);
  }
  getLabPendingProceduresCount(labId) {
    return api.get(`/api/Labs/${labId}/PendingProceduresCount`);
  }
  markCompleteIncomplete(procedure) {
    return api.put(`/api/CassetteProcedures/MarkCompleteIncomplete`, procedure);
  }
  printCassetteProcedures(data) {
    return api.post(`/api/CassetteProcedures/Print`, data);
  }
  /**
   *
   * @param {number} caseId //Case id
   * @returns {Array}  // Array of procedures applied to a case
   */
  getCaseProcedures(caseId) {
    return api.get(`/api/Cases/${caseId}/Procedures`);
  }
  removeCaseProcedure(procedure) {
    return api.delete(`/api/CassetteProcedures`, [procedure]);
  }
  updateProcedure(data) {
    return api.put(this.url, data);
  }
  createProcedure(data) {
    return api.post(this.url, data);
  }
  batchPrintCaseProcedures(caseId) {
    return api.post(`/api/Cases/${caseId}/ProcedureBatchPrint`);
  }
  /**
   * Applys procedures to casssetes/blocks
   * @param {Object} data // The payload outlined for the cassette/block
   * @param {Array} data.items // Array of cassette ids & procedures.
   * @param {String} data.notes // Notes that will be attached to all blocks
   */
  applyProcedures(data) {
    return api.post("/api/CassetteProcedures", data);
  }
  /**
   * Applys procedures to casssetes/blocks
   * @param {Object} data // The payload outlined for the cassette/block
   * @param {Array} data.items // Array of cassette ids & procedures.
   * @param {String} data.notes // Notes that will be attached to all blocks
   */
  updateCaseProcedure(data) {
    return api.put("/api/CassetteProcedures", data);
  }
}
export default new ProceduresApi();
