import api from "./api.js";

class Popups {
  constructor() {
    this.url = "/api/Popups";
  }
  get searchStore() {
    return api.createSearch(this.url);
  }
  insertPopup(payload) {
    return api.post(this.url, payload);
  }
  updatePopup(payload) {
    return api.put(this.url, payload);
  }
  removePopup(id) {
    return api.delete(this.url + "/" + id);
  }
  getPopupForCase(payload) {
    return api.post(this.url + "/ForCase", payload);
  }
  getPopupForSpecimen(payload) {
    return api.post(this.url + "/ForSpecimen", payload);
  }
}
const auditLog = new Popups();
export default auditLog;
