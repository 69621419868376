<template>
  <div>
    <form
      :class="`${isQuickLinks ? '' : 'image-form '}'p-2'`"
      @submit.prevent="handleSubmit"
      v-shortkey="saveShortkey"
      @shortkey="handleSubmit"
    >
      <div :class="`${isQuickLinks ? '' : 'd-flex justify-content-between '}wrapper`">
        <div class="p-1">
          <select-input
            label="Specimen"
            :items="specimens"
            displayExpr="specimenOrder"
            name="specimenId"
            v-model="imageData.specimenId"
          />
          <select-input
            v-focus
            label="Code"
            :items="imageTypes"
            :validator="$v.imageData.imageTypeId"
            name="imageTypeId"
            v-model="imageData.imageTypeId"
          />
          <select-input
            name="printOnReport"
            label="Print On Report"
            id="printOnReport"
            :items="booleanOptions"
            v-model="imageData.printOnReport"
          />
          <tag-input
            label="Tags"
            :items="tagOptions"
            id="tagIds"
            name="tagIds"
            class="tags-input"
            v-model="imageData.tagIds"
          />
          <text-input
            label="Keywords"
            class="keywords-input"
            name="keywords"
            v-model="imageData.keywords"
          />
        </div>
        <div class="p-1">
          <select-input
            label="Device"
            :items="cameraDevices"
            v-if="isCameraOpen"
            v-model="selectedSourceId"
            name="selectedSourceId"
            valueExpr="deviceId"
          />
          <div class="d-flex my-1">
            <video
              ref="cameraOutput"
              :class="{ 'image-preview': isCameraOpen }"
              v-show="isCameraOpen"
              @loadedmetadata="playStream"
            />

            <template v-if="!isCameraOpen && imageData.imagePath">
              <img class="image-preview" v-if="!isPdf" :src="imageData.imagePath" />
              <object :data="imageData.imagePath" class="image-preview" v-else />
            </template>
            <div v-else class="image-preview"></div>
          </div>
          <div class="d-flex justify-content-center">
            <input
              accept="image/*,application/pdf"
              class="d-none"
              type="file"
              id="img"
              ref="fileInput"
              @input="onSelectFile"
            />

            <icon-button
              class="btn-outline-primary capture-btn"
              icon="camera-retro"
              v-shortkey="shortkeyTake"
              @shortkey="takeImage"
              @click="takeImage"
            >
              <span class="ml-1">Cap<u>t</u>ure</span>
            </icon-button>
            <icon-button
              class="btn-outline-primary ml-1 upload-btn"
              icon="file-upload"
              @click="openUploader"
            >
              <span>Upload</span>
            </icon-button>
          </div>
        </div>
      </div>
      <text-area-input
        class="comments-input"
        name="comment"
        ref="comment"
        label="Comments"
        v-model="imageData.comment"
        :generalMacrosEnabled="true"
        :caseId="caseId"
      />
      <div class="progress my-2" v-show="isLoading">
        <div
          class="progress-bar progress-bar-striped bg-success progress-bar-animated"
          role="progressbar"
          :style="uploadStatus$"
          :aria-valuenow="uploadStatus$.progress"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {{ uploadStatus$.progress }}
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <loader size="small" v-show="isLoading" />
        <button type="submit" :disabled="$v.$invalid" class="btn-primary btn save-btn mb-2">
          Save
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { AuditLogApi, DropdownApi, ImagesApi, MacrosApi } from "@/services";
import IconButton from "./common/IconButton.vue";
import SelectInput from "@/components/common/SelectInput.vue";
import TextInput from "./common/TextInput.vue";
import { mapGetters, mapState } from "vuex";
import TagInput from "./common/TagInput.vue";
import Loader from "./common/Loader.vue";
import required from "vuelidate/lib/validators/required";
import { altKey, booleanLookup, createLogItem } from "@/modules/helpers";
import { getBlobFromMediaStream } from "@/modules/getBlobFromMediaStream.js";
import { openCameraDevice } from "@/modules/openCameraDevice";
import { AuditLogItems, MacroTypeEnum } from "@/modules/enums";
import { handleErrors } from "@/modules/handleErrors";
import { UPLOAD_PROGRESS, fromBusEvent } from "@/modules/eventBus";
import { distinctUntilChanged, map, startWith, filter } from "rxjs/operators";

export default {
  components: {
    SelectInput,
    TextAreaInput: () => import("@/components/TextAreaInput.vue"),
    TextInput,
    IconButton,
    TagInput,
    Loader
  },
  props: ["caseId", "specimenId", "isQuickLinks"],
  data() {
    return {
      imageData: {
        specimenId: null,
        imageTypeId: null,
        imagePath: "",
        keywords: "",
        printOnReport: false,
        tagIds: [],
        comment: ""
      },
      fileType: "image/jpeg",
      isCameraOpen: false,
      wasImageCaptured: false,
      selectedSourceId: null,
      mediaStream: null,
      cameraDevices: [],
      imageTypes: [],
      isLoading: false,
      tagOptions: [],

      fileName: "",
      booleanOptions: booleanLookup.dataSource,
      shortkeyTake: altKey("t"),
      saveShortkey: this.isQuickLinks ? null : altKey("s"),
      isMacroOpen: false,
      generalMacros: [],
      positionToPlaceCursor: 0
    };
  },
  computed: {
    ...mapState({
      specimens: state => state.accessionStore.specimens,
      defaultImageType: state => state.applicationSettings.defaultImageType,
      imagePrintOnReport: state => state.applicationSettings.imagePrintOnReport,
      caseDetails: state => state.accessionStore.caseDetails,
      currentUser: state => state.currentUser
    }),
    ...mapGetters("accessionStore", ["isReported", "isCaseEditable"]),
    isPdf() {
      return /pdf/i.test(this.fileType);
    }
  },
  subscriptions() {
    const uploadStatus$ = fromBusEvent(UPLOAD_PROGRESS).pipe(
      filter(() => this.isLoading),
      startWith({ loaded: 0, total: 100 }),
      map(progressEvent => Math.round((progressEvent.loaded * 100) / progressEvent.total)),
      distinctUntilChanged(),
      map(progress => ({
        width: `${progress}%`,
        progress: progress
      }))
    );
    return {
      uploadStatus$
    };
  },
  beforeDestroy() {
    this.closeCamera();
  },
  created() {
    DropdownApi.getTags().then(res => {
      this.tagOptions = res;
    });
    ImagesApi.getImageTypes().then(imageTypes => {
      this.imageTypes = imageTypes || [];
    });
    MacrosApi.getMacrosByUserAndType({
      userId: this.currentUser.id,
      macroTypeId: MacroTypeEnum.General,
      loadOptions: {}
    }).then(res => {
      this.generalMacros = res.data || [];
    });
  },
  mounted() {
    if (this.specimenId) {
      this.imageData.specimenId = this.specimenId;
    }
    if (this.caseId) {
      this.imageData.caseId = this.caseId;
    }
    if (this.defaultImageType) {
      this.imageData.imageTypeId = this.defaultImageType;
    }
    if (this.imagePrintOnReport) {
      this.imageData.printOnReport = this.imagePrintOnReport;
    }
  },
  watch: {
    selectedSourceId(newValue, oldValue) {
      if (newValue !== oldValue) {
        const target = this.cameraDevices.find(e => e.deviceId === newValue);
        if (target) {
          this.openCamera(newValue);
        }
      }
    },
    specimenId: {
      immediate: true,
      handler(nv) {
        if (nv) {
          this.imageData.specimenId = nv;
        }
      }
    }
  },
  validations() {
    return {
      imageData: {
        imageTypeId: { required }
      }
    };
  },
  methods: {
    async handleSubmit() {
      if (!this.imageData.imagePath) {
        window.alert("Please upload or capture an image!");
        return;
      }
      this.$v.$touch();
      if (this.$v.$invalid) {
        window.notify("Please verify your input.", "warning");
        return;
      }
      try {
        this.isLoading = true;
        const { imageTypeId, tagIds, specimenId, keywords, printOnReport, comment } =
          this.imageData;
        const formData = new FormData();
        const file = await fetch(this.imageData.imagePath).then(r => r.blob());
        formData.append(`file`, file);
        formData.append(
          "jsonPayload",
          JSON.stringify({
            imageTypeId,
            tagIds,
            specimenId,
            keywords,
            printOnReport,
            comment,
            caseId: this.caseId
          })
        );
        const response = await this.$store.dispatch("accessionStore/insertImage", formData);
        const logItem = createLogItem(this.caseDetails, AuditLogItems.ChangeAccession);
        logItem.comments = `Inserted an image to the case,
      n ${JSON.stringify({ ...this.imageData, id: response }, null, 2)}`;
        AuditLogApi.insertLogMessage(logItem);
        this.imageData = {
          specimenId: null,
          imageTypeId: null,
          imagePath: "",
          keywords: "",
          printOnReport: false,
          tagIds: [],
          comment: ""
        };
        this.$emit("submit");
        window.notify("Image uploaded successfully!");
      } catch (error) {
        handleErrors(error);
      } finally {
        this.isLoading = false;
      }
    },
    async onSelectFile() {
      this.isCameraOpen = false;
      this.closeCamera();
      const input = this.$refs.fileInput;
      const files = Array.from(input.files);
      if (files?.length) {
        const file = files[0];
        this.fileName = file.name;
        this.wasImageCaptured = false;
        const header = await this.getBLOBFileHeader(file);
        this.fileType = this.mimeType(header);
        this.imageData.imagePath = URL.createObjectURL(file);
      }
      input.value = null;
    },
    openUploader() {
      this.$refs.fileInput.click();
    },
    playStream(event) {
      event.target.play();
    },
    closeCamera() {
      if (this.mediaStream?.getTracks) {
        this.mediaStream.getTracks().forEach(e => e.stop());
      }
      this.isCameraOpen = false;
    },
    async getDeviceList() {
      const cameraDevices = await navigator.mediaDevices.enumerateDevices();
      return cameraDevices
        .filter(e => e.kind === "videoinput")
        .map(e => {
          return {
            deviceId: e.deviceId,
            displayName: e.label,
            isCamera: true
          };
        });
    },
    async openCamera(deviceId) {
      try {
        const response = await openCameraDevice(deviceId);
        this.cameraDevices = await this.getDeviceList();
        if (!deviceId) {
          this.selectedSourceId = response.deviceId;
        }
        const fileInputEl = this.$refs.fileInput;
        if (fileInputEl?.value) {
          fileInputEl.value = null;
        }
        this.isCameraOpen = true;
        const videoEl = this.$refs.cameraOutput;
        this.mediaStream = response.mediaStream;
        const { height, width } = videoEl.parentElement.getBoundingClientRect();
        videoEl.srcObject = response.mediaStream;
        videoEl.height = height;
        videoEl.width = width;
      } catch (error) {
        if (error.name === "NotAllowedError" || error.name === "NotReadableError") {
          window.notify("Error initializing camera.", "warning");
        }
      }
    },
    async takeImage() {
      if (!this.isCameraOpen) {
        this.openCamera();
        return;
      }
      this.$v.$touch();
      if (this.$v.$invalid) {
        window.notify("Please verify your input.", "warning");
        return;
      }
      const blob = await getBlobFromMediaStream(this.mediaStream);
      const url = URL.createObjectURL(blob);
      this.imageData.imagePath = url;
      this.closeCamera();
      this.handleSubmit();
    },
    getBLOBFileHeader(blob) {
      return new Promise(resolve => {
        const fileReader = new FileReader();
        fileReader.onloadend = function (e) {
          const arr = new Uint8Array(e.target.result).subarray(0, 4);
          let header = "";
          for (let i = 0; i < arr.length; i++) {
            header += arr[i].toString(16);
          }
          resolve(header);
        };
        fileReader.readAsArrayBuffer(blob);
      });
    },
    // Add more from http://en.wikipedia.org/wiki/List_of_file_signatures
    mimeType(headerString) {
      let type = "unknown";
      switch (headerString) {
        case "89504e47":
          type = "image/png";
          break;
        case "47494638":
          type = "image/gif";
          break;
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
          type = "image/jpeg";
          break;
        case "25504446":
          type = "application/pdf";
          break;
        default:
          type = "unknown";
          break;
      }
      return type;
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .image-preview {
  width: 100%;
  height: 250px;
}
div.image-preview {
  background-color: gray;
}

.wrapper {
  & > div {
    width: 50%;
  }
}

.case-image-form-ql .wrapper {
  & > div {
    width: initial;
  }
}
</style>
