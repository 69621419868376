<template>
  <div class="container" v-shortkey="shortkeys" @shortkey="handleShortkey">
    <h4 class="d-flex">
      Case Orders <loader size="small" class="ml-2" v-show="isLoading"></loader>
    </h4>
    <div class="row justify-content-end">
      <div class="col p-1">
        <SelectInput
          v-model="caseOrder.specimenId"
          displayExpr="specimenOrder"
          label="Specimen"
          ref="specimens"
          class="specimen"
          :items="gridSpecimens.filter(e => e.id)"
          :validator="$v.caseOrder.specimenId"
          accessKey="i"
        />
      </div>
      <div v-for="field of fieldOrder" v-bind:key="field" class="col p-1">
        <SelectInput
          v-if="field === 'blocks'"
          v-model="caseOrder.cassetteId"
          :displayExpr="displayBlock"
          label="Block"
          class="block"
          ref="blocks"
          :disabled="!caseOrder.specimenId"
          :items="blockOptions"
          accessKey="b"
        />
        <SelectInput
          v-if="field === 'procedure'"
          :dataSource="labProcedures"
          v-model="caseOrder.procedureId"
          :displayExpr="displayOrder"
          :searchExpr="searchExpr"
          searchMode="startswith"
          ref="procedure"
          :validator="$v.caseOrder.procedureId"
          label="Order"
          :disabled="Boolean(caseOrder.panelId)"
          accessKey="o"
        />
        <SelectInput
          v-if="field === 'panel'"
          :dataSource="labPanels"
          v-model="caseOrder.panelId"
          valueExpr="macroId"
          displayExpr="macroName"
          ref="panel"
          :validator="$v.caseOrder.panelId"
          label="Panel"
          :disabled="Boolean(caseOrder.procedureId)"
          accessKey="p"
        />
      </div>
    </div>
    <div class="row border-top mt-2 p-2">
      <procedure
        v-if="caseOrder.procedureId"
        :key="caseOrder.procedureId"
        class="case-order"
        :isCaseOrder="true"
        :selectedBlock="selectedBlock"
        :procedureId="caseOrder.procedureId"
        v-stream:submit="orderSubmit$"
        @close="handleCancel"
        @focusBlock="focusBlock"
      />
      <form
        v-stream:submit="panelSubmit$"
        class="col"
        v-if="matchedMacro"
        v-shortkey="shortkeys.s"
        v-stream:shortkey="panelSubmit$"
      >
        <h4>{{ selectedMacro.macroName }}</h4>
        <orders v-model="panelOrders" />
        <text-area-input label="Details" v-model="caseOrder.detail" v-focus />
        <div class="d-flex flex-grow-1 justify-content-end mt-2">
          <button type="button" @click="handleCancel" class="mx-2 btn btn-danger">Cancel</button>
          <button type="submit" class="btn btn-primary">Save</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { catchError, filter, switchMap, tap, exhaustMap } from "rxjs/operators";
import { mapGetters, mapState } from "vuex";
import Procedure from "./CodeMaintenance/Procedures/Procedure.vue";
import TextAreaInput from "./TextAreaInput.vue";
import Orders from "./forms/Selectors/Orders.vue";
import { helpers, required } from "vuelidate/lib/validators";
import { CasesApi, MacrosApi, ProceduresApi } from "@/services";
import { dateRangeFilter, getAltKeys, toLetters } from "@/modules/helpers";
import { sortBy } from "lodash";
import SelectInput from "@/components/common/SelectInput.vue";
import { CaseOrdersFieldsEnum, SpecimenNumbersEnum } from "@/modules/enums";
import { handleErrors } from "@/modules/handleErrors";
import Loader from "@/components/common/Loader";
import DataSource from "devextreme/data/data_source";

export default {
  name: "EditProcedure-Popup",
  components: { Procedure, TextAreaInput, Orders, SelectInput, Loader },
  props: {
    procedureId: {
      required: false
    },
    panelId: {
      required: false
    },
    caseId: {
      required: false
    }
  },

  mounted() {
    if (this.caseId) {
      this.setCaseDetails(this.caseId);
    }
    if (this.procedureId) {
      this.caseOrder.procedureId = this.procedureId;
    }
    if (this.panelId) {
      this.caseOrder.panelId = this.panelId;
    }
    this.handleFocus();
  },
  validations() {
    return {
      caseOrder: {
        panelId: {
          required: value => (this.caseOrder.procedureId === null ? helpers.req(value) : true)
        },
        procedureId: {
          required: value => (this.caseOrder.panelId === null ? helpers.req(value) : true)
        },
        specimenId: {
          required
        }
      }
    };
  },
  data() {
    return {
      selectedMacro: null,
      panelOrders: [],
      isLoading: false,
      caseOrder: {
        specimenId: null,
        procedureId: null,
        panelId: null,
        cassetteId: null,
        items: [],
        details: ""
      },
      shortkeys: getAltKeys("biops")
    };
  },
  domStreams: ["panelSubmit$", "orderSubmit$"],
  subscriptions() {
    const panelProcedures$ = this.$watchAsObservable("caseOrder.panelId").pipe(
      filter(({ newValue, oldValue }) => newValue && newValue !== oldValue),
      switchMap(({ newValue }) => MacrosApi.searchStore.byKey(newValue)),
      tap(macro => {
        this.selectedMacro = macro;
        this.panelOrders = macro.procedures;
      }),
      catchError(() => panelProcedures$)
    );
    const submitPanels$ = this.panelSubmit$.pipe(
      tap(({ event }) => event.preventDefault()),
      exhaustMap(() => this.handleSubmit(this.caseOrder))
    );
    const submitOrders$ = this.orderSubmit$.pipe(
      exhaustMap(({ event }) => this.handleSubmitOrder(event.msg))
    );
    return {
      submitPanels$,
      submitOrders$,
      panelProcedures$
    };
  },
  computed: {
    ...mapState({
      currentLab: state => state.currentLab,
      currentSpecimen: state => state.accessionStore.currentSpecimen,
      specimens: state => state.accessionStore.specimens,
      caseOrders: state => state.accessionStore.caseOrders,
      currentUser: state => state.currentUser,
      labSettings: state => state.labSettings,
      caseOrdersFields: state => state.applicationSettings.caseOrdersFields,
      caseDetails: state => state.accessionStore.caseDetails
    }),
    ...mapGetters("accessionStore", ["specimenNumbering"]),
    matchedMacro() {
      return this.caseOrder.panelId === this.selectedMacro?.macroId;
    },
    gridSpecimens() {
      if (this.specimens) {
        return [{ specimenOrder: "All", id: null }, ...this.specimens];
      }
      return [];
    },

    unavailableOrders() {
      if (this.caseOrder?.cassetteId) {
        return this.caseOrders
          .filter(cp => cp.cassetteId === this.caseOrder.cassetteId)
          .map(e => e.procedureId);
      }
      return [];
    },
    labProcedures() {
      let filter = dateRangeFilter();
      if (this.unavailableOrders?.length) {
        const ordersFilter = this.unavailableOrders.reduce((acc, curr, idx) => {
          if (idx === 0) {
            return ["id", curr];
          }
          return [[...acc], "or", ["id", curr]];
        }, []);
        filter = [filter, "and", ["!", ordersFilter]];
      }
      return new DataSource({
        store: ProceduresApi.getLabProceduresStore(this.currentLab),
        filter,
        sort: ["code", "description"]
      });
    },
    labPanels() {
      return new DataSource({
        store: MacrosApi.searchStore,
        filter: [["macroType", "=", 2], "and", dateRangeFilter("effectiveOn", "expiresOn")],
        sort: "macroName"
      });
    },
    blockOptions() {
      if (this.caseOrder.specimenId) {
        return sortBy(
          this.caseBlocks.filter(e => e.specimenId === this.caseOrder.specimenId),
          "blockNum"
        );
      }
      return sortBy(this.caseBlocks, "blockNum");
    },

    selectedSpecimen() {
      return this.specimens.find(e => e.id === this.caseOrder.specimenId);
    },
    caseBlocks() {
      return this.specimens
        .map(specimen => {
          if (specimen.cassettes?.length) {
            return specimen.cassettes.map(block => {
              return { ...block, specimenOrder: specimen.specimenOrder };
            });
          } else {
            return [];
          }
        })
        .flat();
    },
    searchExpr() {
      return this.labSettings?.ProcedureSearchBy ? "description" : "code";
    },
    selectedBlock() {
      const block = this.selectedSpecimen.cassettes.find(e => e.id === this.caseOrder.cassetteId);
      if (block) {
        return this.displayBlock(block);
      }
      return null;
    },
    fieldOrder() {
      switch (this.caseOrdersFields) {
        case CaseOrdersFieldsEnum.Order:
          return ["procedure", "panel", "blocks"];
        case CaseOrdersFieldsEnum.Panel:
          return ["panel", "procedure", "blocks"];
        default:
          return ["blocks", "procedure", "panel"];
      }
    }
  },
  watch: {
    "caseOrder.specimenId"(nv, ov) {
      if (nv !== ov) {
        this.caseOrder.cassetteId = null;
      }
    },
    caseOrder: {
      deep: true,
      handler(nv, ov) {
        if (nv.panelId && ov.procedureId) {
          return (nv.procedureId = null);
        }
        if (nv.procedureId && ov.panelId) {
          return (nv.panelId = null);
        }
      }
    }
  },
  methods: {
    async handleCancel() {
      const confirm = await window.confirm("Any unsaved data will be lost. <br> Are you sure?");
      if (!confirm) {
        return;
      }
      this.handleReset();
      this.$emit("close");
    },
    handleReset() {
      this.$emit("input", {
        specimenId: null,
        procedureId: null,
        panelId: null,
        cassetteId: null,
        items: [],
        details: ""
      });
    },
    displayBlock(block) {
      if (block) {
        if (this.specimenNumbering === SpecimenNumbersEnum.Numbers) {
          return block.blockNum ? toLetters(block.blockNum) : "";
        }
        return block.blockNum;
      }
      return "";
    },
    displayOrder(data) {
      return data && `${data.code} - ${data.description} `;
    },
    async handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        window.notify("Missing data, Please verify your input and try again.", "warning");
        return;
      }
      let blockNum;
      if (this.caseOrder.cassetteId) {
        blockNum = this.selectedSpecimen.cassettes.find(
          e => e.id === this.caseOrder.cassetteId
        ).blockNum;
      }
      try {
        this.isLoading = true;
        const data = {
          ...this.selectedMacro,
          panelId: this.panelId,
          procedures: this.panelOrders.map(item => ({
            ...item,
            description: item.description,
            code: item.code,
            diagnosisText: "",
            detail: this.caseOrder.detail,
            cassetteId: this.caseOrder.cassetteId,
            panelId: this.caseOrder.panelId,
            procedureId: item.id,
            blockNum: blockNum || item.defaultBlockNum || item.blockNum,
            specimenId: this.caseOrder.specimenId,
            holdCodeId: item.holdCode
          }))
        };

        //Do macro conversion
        const payload = {};
        payload.items = data.procedures.filter(order => {
          payload.note = order.detail;
          if (order.blockNum < 1) {
            window.alert(
              `<b>${order.code}-${order.description}: </b>No blocks and slides will be queued up to print for this order`
            );
            return false;
          } else {
            return true;
          }
        });
        await this.$store.dispatch("accessionStore/addCaseOrders", payload);
        this.$emit("close");
      } catch (error) {
        handleErrors(error);
      } finally {
        this.isLoading = false;
      }
    },
    async handleSubmitOrder(order) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        window.notify("Missing data, please verify your input and try again.", "warning");
        return;
      }

      try {
        this.isLoading = true;
        let blockNum;
        if (this.caseOrder.cassetteId) {
          blockNum = this.selectedSpecimen.cassettes.find(
            e => e.id === this.caseOrder.cassetteId
          )?.blockNum;
        }
        const data = {
          ...order,
          procedureId: order.id,
          specimenId: this.caseOrder.specimenId,
          blockNum: blockNum || order.defaultBlockNum
        };
        await this.$store.dispatch("accessionStore/addCaseOrders", {
          items: [data]
        });
        this.$emit("addOrder");
      } catch (error) {
        handleErrors(error);
      } finally {
        this.isLoading = false;
      }
    },
    async setCaseDetails(caseId) {
      const caseDetails = await CasesApi.getCaseById(caseId);
      await this.$store.commit("accessionStore/setCaseDetails", caseDetails);
      if (
        caseDetails?.specimens &&
        (!this.specimens.length || this.specimens[0].caseId !== caseId)
      ) {
        await this.$store.commit("accessionStore/setCaseSpecimens", caseDetails?.specimens);
        this.handleFocus();
      }
    },
    handleShortkey(event) {
      const { srcKey } = event;
      let fieldToFocus = "";
      switch (srcKey) {
        case "i":
          fieldToFocus = "specimens";
          break;
        case "p":
          fieldToFocus = "panel";
          break;
        case "o":
          fieldToFocus = "procedure";
          break;
        case "b":
          fieldToFocus = "blocks";
          break;
      }
      let focusRef = this.$refs[fieldToFocus];
      if (focusRef) {
        if (Array.isArray(focusRef)) {
          focusRef = focusRef[0];
        }
        focusRef.focus();
      }
    },
    focusBlock() {
      this.$refs.blocks[0].focus();
    },
    handleFocus() {
      const specimens = this.specimens || this.caseDetails.specimens;
      const fieldToFocus = this.fieldOrder[0];
      if (specimens?.length === 1) {
        this.caseOrder.specimenId = specimens[0].id;
        this.$nextTick(() => {
          this.$refs[fieldToFocus][0].focus();
        });
      } else if (this.currentSpecimen?.id) {
        this.caseOrder.specimenId = this.currentSpecimen.id;
      }
      if (specimens?.length > 1) {
        this.$nextTick(() => {
          this.$refs.specimens.focus();
        });
      } else {
        this.$nextTick(() => {});
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.procedure-modal {
  height: 65vh;

  .grid {
    height: 250px;
  }
  .summary {
    height: 200px;
  }
}
.value {
  margin: 5px 2px;
}
.summary {
  overflow: auto;
}
.target_title {
  color: $primary-dark;
}
.order-list {
  .specimen,
  .block {
    display: none;
  }
}
</style>
