import api from "./api.js";
class PathReportMessages {
  constructor() {
    this.url = "/api/PathReportMessages/";
  }
  get searchStore() {
    return api.createSearch(this.url);
  }
  get batchStore() {
    return api.createSearch("/api/BatchPathReportMessages", "caseId");
  }
  createSearch(url, key, params) {
    return api.createSearch(url, key, params);
  }
  syncPrintNodeJobs(labId) {
    return api.post("/api/SyncPrintNodeMessages/" + labId);
  }
  getPathReportMessages() {
    return api.get(this.url);
  }
  getCasePathReportMessages(caseId) {
    return api.get(`/api/Cases/${caseId}/PathReportMessages`);
  }
  resubmitMessage(id) {
    return api.post(this.url + `${id}/Resubmit`);
  }
  cancelMessage(id) {
    return api.delete(this.url + `${id}/Cancel`);
  }
  /**
   *
   * @param {object} data /Data for adding the manual message
   * @param {array} data.caseIds // Array of integers.
   * @param {number} data.method // Either LocalDownload or LocalPrint
   * @returns
   */
  addPathReportMessage(data) {
    return api.post(this.url, data);
  }
}
export default new PathReportMessages();
