<template>
  <div>
    <label><b>Orders</b></label>
    <DxTagBox
      placeholder="Select orders..."
      :show-selection-controls="true"
      search-expr="description"
      :display-expr="calculateDisplay"
      :data-source="dataSource"
      :maxDisplayTags="5"
      :showDropDownButton="true"
      :search-enabled="true"
      v-model="procedures"
      :dropdownOptions="dropdownOptions"
    >
    </DxTagBox>
  </div>
</template>

<script>
import DxTagBox from "devextreme-vue/tag-box";
import { mapState } from "vuex";
import api from "@/services/api.js";
import { dateRangeFilter } from "../../../modules/helpers";
import DataSource from "devextreme/data/data_source";

export default {
  name: "Orders",
  inheritAttrs: false,
  components: {
    DxTagBox
  },
  props: {
    value: {
      required: true
    }
  },
  data: () => ({
    dropdownOptions: {
      position: "bottom"
    }
  }),
  methods: {
    onValueChanged(e) {
      this.procedures = e.value;
    },
    calculateDisplay(data) {
      return data && data.description
        ? `${data.code}-${data.description}`
        : data.displayName || data.name;
    }
  },
  computed: {
    ...mapState(["currentUser", "currentLab"]),
    dataSource() {
      return new DataSource({
        store: api.createSearch(`/api/Labs/${this.currentLab}/Procedures`),
        filter: dateRangeFilter(),
        sort: { selector: "code", desc: false }
      });
    },
    procedures: {
      get() {
        return this.value;
      },
      set(value) {
        return this.$emit("input", value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.custom__tag {
  background-color: $neutral;
  color: #35495e;
}
</style>
