import { calculateAccessionNumbering } from "./helpers";
import store from "@/store";

export function scanCaseBarcode(barcode) {
  function getBlockRegex(specimen, delimiter = "") {
    const blockLettersOrNumbers = /[a-z]/i.test(specimen) ? "[0-9]" : "[a-z]";
    const specimenLettersOrNumbers = /[a-z]/i.test(specimen) ? "[a-z]" : "[0-9]";
    const value =
      "(?<specimen>" +
      specimenLettersOrNumbers +
      "{1,3})(" +
      delimiter +
      "(?<block>" +
      blockLettersOrNumbers +
      "{1,3}))?";
    return value;
  }
  const caseNumberRegex = "(?<caseNumber>[a-z]{1,6}[0-9]{2,4}-?[0-9]{1,7})";
  const isFullCaseNumber = new RegExp("^" + caseNumberRegex + "$", "i").test(barcode);
  if (isFullCaseNumber) {
    const fullCaseNumber = calculateAccessionNumbering(barcode);
    return { caseNumber: fullCaseNumber };
  }
  let delimiter = store.state.labSettings.BarcodeDelimiter;
  const specialCharacters = "|.+*?^$\\/()[".split("");
  if (delimiter && specialCharacters.includes(delimiter)) {
    delimiter = "\\" + delimiter;
  }
  const specimenRegex = "(?<specimen>[a-z0-9]{1,3})";
  const specimenWithoutDelimiterRegex = new RegExp(
    caseNumberRegex + "(?<specimen>[a-z]{1,3})",
    "i"
  );
  const caseAndSpecimenWithDelimiterRegex = new RegExp(
    caseNumberRegex + delimiter + specimenRegex,
    "i"
  );
  if (delimiter && caseAndSpecimenWithDelimiterRegex.test(barcode)) {
    const {
      groups: { caseNumber, specimen }
    } = caseAndSpecimenWithDelimiterRegex.exec(barcode);
    const fullCaseNumber = calculateAccessionNumbering(caseNumber);
    if (specimen) {
      const blockRegex = getBlockRegex(specimen, delimiter);
      const { groups } = new RegExp(caseNumberRegex + delimiter + blockRegex, "i").exec(barcode);
      return {
        caseNumber: fullCaseNumber,
        specimenOrder: specimen,
        blockNum: groups?.block
      };
    }
  } else if (specimenWithoutDelimiterRegex.test(barcode)) {
    const {
      groups: { caseNumber, specimen }
    } = specimenWithoutDelimiterRegex.exec(barcode);
    const fullCaseNumber = calculateAccessionNumbering(caseNumber);
    const blockRegex = getBlockRegex(specimen);
    const { groups } = new RegExp(caseNumberRegex + blockRegex, "i").exec(barcode);
    return {
      caseNumber: fullCaseNumber,
      specimenOrder: specimen,
      blockNum: groups?.block
    };
  } else {
    const fullCaseRegex = new RegExp(caseNumberRegex, "i");
    if (fullCaseRegex.test(barcode)) {
      const {
        groups: { caseNumber }
      } = fullCaseRegex.exec(barcode);
      const fullCaseNumber = calculateAccessionNumbering(caseNumber);
      if (fullCaseNumber) {
        return { caseNumber: fullCaseNumber };
      }
    }
    return false;
  }
}
