<template>
  <div>
    <div class="d-flex justify-content-between my-2">
      <h3>HL7 Configuration</h3>
      <AddBtn
        type="button"
        @click="triggerIsAdding"
        :text="isAdding ? 'Close' : 'Add'"
        :icon="isAdding ? 'times' : 'plus'"
      />
    </div>
    <div v-if="value && value.length">
      <PropTable
        v-model="value"
        :columns="columns"
        @edit="editHL7"
        @input="$emit('input', $event)"
      />
    </div>
    <form v-if="isAdding || isEditing" @submit.prevent="addHL7" class="col-8">
      <div class="row d-flex justify-content-center align-items-center">
        <div class="d-flex flex-column justify-content-start">
          <Checkbox label="Final" id="hl7Final" v-model="HL7.final" />
          <Checkbox label="Prelim" class="" id="hl7Prelim" v-model="HL7.prelim" />
          <Checkbox
            label="Hide in Distribution"
            id="hideInDist"
            v-model="HL7.hideInDistributionHistory"
          />
        </div>
        <div class="d-flex flex-column align-items-center ml-4">
          <DatePicker label="Start On" class="w-100" id="startOn" v-model="HL7.startOn" />
          <DatePicker
            class="w-100"
            label="End On"
            v-model="HL7.endOn"
            :validator="$v.HL7.endOn"
            :validatorMsgMap="validatorMsgMap"
          />
        </div>
      </div>
      <div class="row">
        <TextInput
          name="Sending Application"
          class="col"
          label="Sending Application"
          maxLength="21"
          v-model="HL7.sendingApp"
          :validator="$v.HL7.sendingApp"
        />
        <TextInput
          name="Sending Facility"
          class="col"
          maxLength="21"
          label="Sending Facility"
          v-model="HL7.sendingFac"
          :validator="$v.HL7.sendingFac"
        />
      </div>
      <div class="row">
        <TextInput
          name="Receiving App"
          class="col"
          label="Receiving Application"
          maxLength="21"
          v-model="HL7.receivingApp"
          :validator="$v.HL7.receivingApp"
        />
        <TextInput
          name="Receiving Facility"
          class="col"
          maxLength="21"
          label="Receiving Facility"
          v-model="HL7.receivingFac"
          :validator="$v.HL7.receivingFac"
        />
      </div>

      <div class="row justify-content-end">
        <button @click="cancelEdit" type="button" class="my-2 mr-1 btn btn-danger">Cancel</button>
        <button type="submit" class="my-2 btn btn-primary">
          {{ isEditing ? "Save" : "Submit" }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import { validatorMsgMapBase } from "@/modules/helpers";
import { isAfter, format, parseISO, isValid } from "date-fns";
import PropTable from "@/components/common/PropTable.vue";
import AddBtn from "@/components/common/AddButton.vue";
import TextInput from "@/components/common/TextInput.vue";
import Checkbox from "@/components/common/Checkbox.vue";
import DatePicker from "@/components/common/DatePicker.vue";

export default {
  name: "HL7-Config",
  components: {
    PropTable,
    AddBtn,
    TextInput,
    Checkbox,
    DatePicker
  },
  props: ["value"],
  data() {
    return {
      columns: [
        {
          dataField: "prelim",
          dataType: "boolean"
        },
        {
          dataField: "final",
          dataType: "boolean"
        },
        {
          dataField: "sendingApp",
          caption: "Sending Application"
        },
        {
          dataField: "receivingApp",
          caption: "Receiving Application",
          dataType: "string"
        },
        {
          dataField: "sendingFac",
          caption: "Sending Facility",
          dataType: "string"
        },
        {
          dataField: "receivingFac",
          caption: "Receiving Facility"
        },
        {
          dataField: "startOn",
          dataType: "date"
        },
        {
          dataField: "endOn",
          dataType: "date"
        },
        {
          dataField: "hideInDistributionHistory",
          dataType: "boolean"
        }
      ],
      isEditing: false,
      isAdding: false,
      defaultHL7: {
        prelim: false,
        final: false,
        sendingApp: "",
        sendingFac: "",
        receivingApp: "",
        receivingFac: "",
        startOn: new Date(),
        endOn: null,
        hideInDistributionHistory: false
      },
      HL7: {
        prelim: false,
        final: false,
        sendingApp: "",
        sendingFac: "",
        receivingApp: "",
        receivingFac: "",
        startOn: new Date(),
        endOn: null,
        hideInDistributionHistory: false
      }
    };
  },
  mounted() {
    if (this.value === null) {
      this.$emit("input", []);
    }
  },

  computed: {
    validatorMsgMap() {
      return {
        ...validatorMsgMapBase,
        afterStartOn: "Must be after start on date."
      };
    }
  },
  validations: {
    HL7: {
      sendingApp: {
        required,
        maxLength: maxLength(20)
      },
      sendingFac: {
        required,
        maxLength: maxLength(20)
      },
      receivingApp: {
        required,
        maxLength: maxLength(20)
      },
      receivingFac: {
        required,
        maxLength: maxLength(20)
      },
      startOn: {
        required
      },
      endOn: {
        afterStartOn: function (value, vm) {
          if (value != undefined && value != null) {
            return isAfter(value, vm.startOn);
          }
          return true;
        }
      }
    }
  },
  methods: {
    async triggerIsAdding() {
      if (this.isEditing) {
        const confirm = await window.confirm(
          "You may have unsaved data, are you sure you want to continue?"
        );
        if (!confirm) {
          return;
        }
      }
      this.HL7 = { ...this.defaultHL7 };
      if (this.isAdding) {
        this.isAdding = false;
        this.isEditing = false;
        return;
      }
      this.isAdding = true;
      this.isEditing = false;
    },
    displayDate(date) {
      if (!(date instanceof Date)) {
        date = parseISO(date);
      }
      if (isValid(date)) {
        return format(date, "MM/dd/yyyy");
      }
      return "N/A";
    },
    addHL7() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        window.notify("Please verify your input and try again.", "warning");

        return;
      }
      if (!this.isEditing) {
        if (this.value) {
          const values = [...this.value, this.HL7];
          this.$emit("input", values);
        } else {
          this.$emit("input", [this.HL7]);
        }
      }
      this.isEditing = false;
      this.$v.$reset();
      return (this.HL7 = { ...this.defaultHL7 });
    },
    editHL7({ data }) {
      this.isEditing = true;
      this.HL7 = data;
    },
    cancelEdit() {
      if (this.isEditing) {
        this.isEditing = false;
      }
      this.isAdding = false;
      this.HL7 = { ...this.defaultHL7 };
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-cell {
  width: 10%;
}

table td {
  text-align: center;
}
</style>
