/**
 * Simple API class service for making Axios calls
 * and returning a promise for each call
 */
import Axios from "axios";
import _ from "lodash";
import { createStore } from "devextreme-aspnet-data-nojquery";
const axios = Axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL
});
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Access-Control-Max-Age"] = "600";

import Store from "../store";
import eventBus, { UPLOAD_PROGRESS } from "@/modules/eventBus";

axios.defaults.withCredentials = true;

class Api {
  constructor() {
    this.base_url = this.getBaseUrl();
  }
  get state() {
    const state = Store.state;
    if (state) {
      return state;
    }
    return {};
  }
  get headers() {
    const token = sessionStorage.getItem("token");
    if (token) {
      return {
        Authorization: `Bearer ${encodeURIComponent(token)}`
      };
    }
    return null;
  }
  get currentLab() {
    return this.state.currentLab;
  }
  get currentUser() {
    const currentUser = this.state?.currentUser;
    return currentUser ?? {};
  }
  getBaseUrl() {
    let baseUrl = `${process.env.VUE_APP_API_BASE_URL}`;
    return baseUrl;
  }
  onBeforeSend(_, settings) {
    settings.xhrFields = {
      withCredentials: true
    };
    if (this.currentUser?.labId === null) {
      const { labId } = settings.data;
      if (labId !== this.currentLab) {
        settings.data.labId = this.currentLab;
      }
    }
    settings.headers = this.headers;

    return true;
  }
  /**
   *
   * @param {string} url
   * @param {string} key // Primary key of the data
   * @param {*} loadParams
   * @param {function} onLoading // Function that takes loadOptions as arg
   * @returns
   */
  createSearch(url, key = "id", loadParams = {}, onLoading = undefined, onBeforeSend) {
    return createStore({
      key,
      loadParams,
      onLoading,
      loadUrl: this.base_url + url,
      onBeforeSend: (operation, settings) => {
        settings.xhrFields = {
          withCredentials: true
        };
        if (this.currentUser?.labId === null) {
          const { labId } = settings.data;
          if (labId !== this.currentLab) {
            settings.data.labId = this.currentLab;
          }
        }
        settings.headers = this.headers;
        if (onBeforeSend) {
          onBeforeSend(settings);
        }
        return true;
      }
    });
  }
  handleError(error) {
    if (error?.response?.status === 401) {
      const response = error.response.data;
      if (typeof response === "string" && !response.includes("Username or password")) {
        window.notify("User does not have permission for this api.", "error");
      }
    }
    if (error?.response?.status === 413) {
      window.alert("Your data is too large to process, please check your input and try again.");
    } else if (error?.response?.status === 500) {
      if (typeof error.response.data?.message === "string") {
        window.alert(error.response.data.message);
      } else if (error?.request?.responseType === "blob") {
        error.response.data.text().then(errorText => {
          const jsonResponse = JSON.parse(errorText || "{}");
          if (jsonResponse?.message) {
            window.alert(jsonResponse.message);
          }
        });
      }
    }
    return Promise.reject(error);
  }
  /**
   * Get
   * @param {string} endpoint
   * @param {*} params
   */
  async get(endpoint, params = {}, headers = this.headers, responseType = "json") {
    if (this.currentUser?.labId === null) {
      params.labId = this.currentLab;
    }
    let config = {
      url: endpoint,
      params: params,
      method: "GET",
      responseType: responseType
    };
    if (!_.isEmpty(headers)) {
      config["headers"] = headers;
    }
    return axios(config)
      .then(res => res.data)
      .catch(this.handleError);
  }
  getFile(endpoint, params = {}, responseType = "blob") {
    if (this.currentUser?.labId === null) {
      params.labId = this.currentLab;
    }
    let config = {
      url: endpoint,
      params: params,
      method: "GET",
      responseType: responseType
    };
    config["headers"] = this.headers;
    return axios(config)
      .then(res => res.data)
      .catch(this.handleError);
  }
  getFileWithPost(endpoint, data = {}, responseType = "arraybuffer", params = {}) {
    if (this.currentUser?.labId === null) {
      params.labId = this.currentLab;
    }
    let config = {
      url: endpoint,
      data: data,
      params: params,
      method: "POST",
      responseType: responseType
    };
    config["headers"] = this.headers;
    return axios(config)
      .then(res => res.data)
      .catch(this.handleError);
  }

  /**
   * Get
   * @param {string} endpoint
   * @param {*} params
   */
  getFullUrl(endpoint, params = {}, headers = this.headers) {
    let config = {
      url: `${endpoint}`,
      params: params,
      method: "GET"
    };

    if (!_.isEmpty(headers)) {
      config["headers"] = headers;
    }
    let axiosInstance = axios.create(); // why create an instance you might ask?? bc it lets me delete the Auth Bearer header without affecting all the other requests (was throwing error when accessing azure blob storage)
    delete axiosInstance.defaults.headers.common["Authorization"];

    return axiosInstance(config)
      .then(res => res.data)
      .catch(this.handleError);
  }
  /**
   * Post
   * @param {string} endpoint
   * @param {*} data
   */
  post(endpoint, data = {}, headers = this.headers, params = {}) {
    params.labId = this.currentLab;
    let config = {
      url: endpoint,
      data: data,
      method: "POST",
      params,
      onUploadProgress: progressEvent => {
        eventBus.$emit(UPLOAD_PROGRESS, progressEvent);
      }
    };
    if (!_.isEmpty(headers)) {
      config["headers"] = headers;
    }
    return axios(config)
      .then(res => res.data)
      .catch(this.handleError);
  }
  /**
   * PUT
   * @param {string} endpoint
   * @param {*} data
   */
  put(endpoint, data = {}, headers = this.headers, params = {}) {
    params.labId = this.currentLab;
    let config = {
      url: endpoint,
      data: data,
      method: "PUT",
      params
    };
    if (!_.isEmpty(headers)) {
      config["headers"] = headers;
    }
    return axios(config)
      .then(res => res.data)
      .catch(this.handleError);
  }
  /**
   * Delete
   * @param {string} endpoint
   * @param {*} params
   */
  delete(endpoint, data = {}, headers = this.headers, params = {}) {
    params.labId = this.currentLab;
    let config = {
      url: endpoint,
      data,
      method: "DELETE",
      params
    };
    if (!_.isEmpty(headers)) {
      config["headers"] = headers;
    }
    return axios(config)
      .then(res => res.data)
      .catch(this.handleError);
  }
}

export default new Api();
