<template>
  <div class="text-center w-50%">
    <h1 class="">Your session will time out in:</h1>
    <br />
    <h2 class="display-4">{{ timer }}</h2>
  </div>
</template>

<script>
import { interval } from "rxjs";
import { tap } from "rxjs/operators";
import { minutesCalculator } from "@/modules/helpers";
export default {
  data() {
    return {
      futureTime: Date.now() + minutesCalculator(2),
      timer: "2:00"
    };
  },
  subscriptions() {
    return {
      timer$: interval(1000).pipe(tap(() => this.calculateDistance(this.futureTime)))
    };
  },
  methods: {
    async calculateDistance(future) {
      const distance = future - Date.now();
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (distance < 2) {
        this.$emit("logout");
      }
      this.timer = `${minutes}:${seconds.toString().padStart(2, 0)}`;
    }
  }
};
</script>

<style lang="scss" scoped></style>
