var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper p-1"},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('button',{staticClass:"btn btn-outline-primary",on:{"click":_vm.handleClearGridFilters}},[_vm._v("Clear Filters")])]),_c('dx-grid-with-search',{directives:[{name:"stream",rawName:"v-stream:content-ready",value:(_vm.contentReady$),expression:"contentReady$",arg:"content-ready"}],ref:"orderGrid",staticClass:"orderGrid",attrs:{"title":"Orders","refkey":"Order Grid","data-source":_vm.getPendingProcedures,"column-resizing-mode":"widget","gridName":'ordersGrid',"noDataText":"No orders found.","selection":_vm.selection,"onCellPrepared":_vm.redactLogRocket,"allow-column-resizing":true,"columns":_vm.columns,"pager":_vm.pager,"paging":_vm.paging,"toolbar":_vm.toolbar},on:{"initialized":_vm.initializeGrid},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('loader',{attrs:{"size":"small"}})]},proxy:true},{key:"actions",fn:function(ref){
var data = ref.data;
return [_c('icon-button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:(
          ("Click mark order " + (_vm.isProcedureComplete(data.data) ? 'incomplete' : 'complete') + ".")
        ),expression:"\n          `Click mark order ${isProcedureComplete(data.data) ? 'incomplete' : 'complete'}.`\n        ",modifiers:{"left":true}}],staticClass:"pointer p-0 mx-2",class:{
          'text-success': !_vm.isProcedureComplete(data.data),
          'text-danger': _vm.isProcedureComplete(data.data)
        },attrs:{"icon":_vm.isProcedureComplete(data.data) ? 'times' : 'check'},on:{"click":function($event){return _vm.toggleProcedure(data.data)}}})]}},{key:"accessionNumber",fn:function(ref){
        var data = ref.data;
return [(_vm.permissions.CassetteProcedureView)?_c('router-link',{attrs:{"to":{
          name: 'CaseView',
          params: {
            caseId: data.data.caseId
          }
        }}},[_vm._v(_vm._s(_vm.accessionNumber(data)))]):_c('span',[_vm._v(_vm._s(_vm.accessionNumber(data)))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }