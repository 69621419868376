<template>
  <div class="prostate-form" v-shortkey="shortkeys" @shortkey="handleShortkey">
    <div v-if="!siteMap.length">
      <h3>
        Lab settting "Prostate Site Map" is not set up. Please configure it in Lab Settings and try
        again.
      </h3>
      <div class="d-flex justify-content-end">
        <button class="btn btn-primary" @click="handleCancel">Close</button>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-2 px-2"></div>
        <div class="col-8">
          <div class="border">
            <div class="row px-2" v-if="hasMalignant">
              <TextInput
                class="col-3"
                label="<u>C</u>linical"
                v-model="prostateClinicalScore"
                ref="prostateClinicalScore"
              />
              <TextInput
                class="col-3"
                label="Gleaso<u>n</u>"
                v-model="caseGleasonScore"
                ref="caseGleasonScore"
              />
              <NumberInput
                class="col-6"
                label="Likelihood of <u>O</u>rgan Confinement"
                v-model="likelihoodOfOrganConfinement"
                ref="likelihoodOfOrganConfinement"
              />
              <NumberInput
                class="col-6"
                label="<u>R</u>isk of Extraprostatic Extension"
                v-model="riskOfExtraprostaticExtension"
                ref="riskOfExtraprostaticExtension"
              />
              <NumberInput
                class="col-6"
                label="<u>E</u>xt. Risk of Seminal Vesicle Involvement"
                v-model="extensionRiskOfSeminalVesicleInvolvement"
                ref="extensionRiskOfSeminalVesicleInvolvement"
              />
            </div>
          </div>
        </div>
        <div class="col-2 px-2"></div>
        <div
          v-for="site of siteMap"
          v-bind:key="site"
          class="my-1 px-2"
          :class="colWidth"
          @click="changeSelectedSpecimen(site)"
        >
          <ProstateSpecimen
            v-if="site"
            :ref="getSpecimenRef(site)"
            :specimen="getSpecimen(site)"
            @updateSpecimen="handleUpdateSpecimen"
            @setMalignance="setMalignance"
            :usedMacros="usedMacros"
            :windowWidth="windowWidth"
            :selectedSpecimenOrder="selectedSpecimenOrder"
          />
          <div v-else />
        </div>
      </div>
      <SubmitCancelRow
        @submit="handleSubmit"
        @cancel="handleCancel"
        submitText="Save"
        :submitShortKey="saveShortkey"
        :isLoading="isLoading"
        :disabled="isLoading"
      />
    </div>
  </div>
</template>

<script>
import ProstateSpecimen from "@/components/ProstateSpecimen.vue";

import { mapState } from "vuex";
import TextInput from "./common/TextInput.vue";
import NumberInput from "./common/NumberInput.vue";
import SubmitCancelRow from "./common/SubmitCancelRow.vue";
import { altKey, getAltKeys } from "@/modules/helpers";
import { uniqBy } from "lodash";
import { MacrosApi } from "@/services";
import { orderBy } from "lodash";
import { handleErrors } from "@/modules/handleErrors";

export default {
  components: {
    ProstateSpecimen,
    TextInput,
    NumberInput,
    SubmitCancelRow
  },
  data() {
    return {
      defaultSpecimen: {
        id: null,
        resultsMacros: [],
        specimenOrder: ""
      },
      specimenToUpdate: {},
      caseGleasonScore: null,
      prostateClinicalScore: null,
      likelihoodOfOrganConfinement: null,
      riskOfExtraprostaticExtension: null,
      extensionRiskOfSeminalVesicleInvolvement: null,
      malignances: [],
      hasMalignant: false,
      saveShortkey: altKey("s"),
      siteMap: [],
      usedMacros: [],
      windowWidth: window.innerWidth,
      selectedSpecimenOrder: null,
      outputSpecimens: [],
      isLoading: false,
      shortkeys: getAltKeys("ceglnoprt")
    };
  },
  computed: {
    ...mapState({
      specimens: state => state.accessionStore.specimens,
      currentSpecimen: state => state.accessionStore.currentSpecimen,
      diagnosisSummaries: state => state.dropdowns.diagnosisSummaries,
      ProstateSiteMap: state => state.labSettings.ProstateSiteMap
    }),
    colWidth() {
      if (this.siteMap.length < 13) {
        return "col-3";
      }
      return "col-2";
    }
  },
  mounted() {
    if (!this.diagnosisSummaries.length) {
      this.$store.dispatch("dropdowns/getDiagnosisSummaries");
    }
    if (this.currentSpecimen?.id) {
      this.selectedSpecimenOrder = this.currentSpecimen.specimenOrder;
    }
    this.getCaseScores();
    this.malignances = this.specimens.map(() => false);
    this.getSiteMap();
    this.getResultsMacros();
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    getSpecimen(site) {
      if (site) {
        const specimen = this.specimens.find(e => e.site === site);
        if (specimen) {
          const index = this.specimens.map(e => e.specimenOrder).indexOf(specimen.specimenOrder);
          return { ...specimen, index };
        } else {
          return this.defaultSpecimen;
        }
      }
    },
    handleCancel() {
      this.$emit("close");
    },
    async handleSubmit() {
      let specimensToUpdate = [];
      for (const specimen of this.outputSpecimens) {
        if (specimen.tumorSize && specimen.tumorSize > specimen.prostateLength) {
          window.alert("Tumor size must be less than prostate length.");
          this.selectedSpecimenOrder = specimen.specimenOrder;
          return;
        }
        if (specimen.id === this.currentSpecimen?.id) {
          specimensToUpdate.push(specimen);
        } else {
          const originalSpecimen = this.specimens.find(e => e.id === specimen.id);
          for (const field of ["gleasonScore", "tumorSize", "prostateLength", "prostatePieces"]) {
            if (originalSpecimen[field] !== specimen[field]) {
              specimensToUpdate.push(specimen);
              break;
            }
          }
        }
      }
      if (!specimensToUpdate.length) {
        specimensToUpdate = [this.specimens[0]];
      }
      this.isLoading = true;
      try {
        await this.$store.dispatch(
          "accessionStore/updateProstateSpecimens",
          specimensToUpdate.map(e => {
            return {
              ...e,
              caseGleasonScore: this.caseGleasonScore,
              prostateClinicalScore: this.prostateClinicalScore,
              riskOfExtraprostaticExtension: this.riskOfExtraprostaticExtension,
              extensionRiskOfSeminalVesicleInvolvement:
                this.extensionRiskOfSeminalVesicleInvolvement,
              likelihoodOfOrganConfinement: this.likelihoodOfOrganConfinement
            };
          })
        );
      } catch (error) {
        handleErrors(error);
      } finally {
        this.isLoading = false;
        window.notify("Specimens updated.");
      }
      this.$emit("close");
    },
    handleUpdateSpecimen(specimen) {
      this.outputSpecimens = orderBy(
        [...this.outputSpecimens.filter(e => e.id && e.id !== specimen.id), specimen],
        "specimenOrder"
      );
    },
    getCaseScores() {
      const specimen = this.currentSpecimen?.id ? this.currentSpecimen : this.specimens[0];
      this.caseGleasonScore = specimen.caseGleasonScore;
      this.prostateClinicalScore = specimen.prostateClinicalScore;
      this.extensionRiskOfSeminalVesicleInvolvement =
        specimen.extensionRiskOfSeminalVesicleInvolvement;
      this.riskOfExtraprostaticExtension = specimen.riskOfExtraprostaticExtension;
      this.likelihoodOfOrganConfinement = specimen.likelihoodOfOrganConfinement;
    },
    setMalignance({ index, value }) {
      if (index != null) {
        this.malignances[index] = value;
        this.hasMalignant = this.malignances.includes(true);
      }
    },
    handleShortkey({ srcKey }) {
      if ("glpt".includes(srcKey) && this.selectedSpecimenOrder) {
        let specimenRef = this.$refs["specimen" + this.selectedSpecimenOrder];
        if (specimenRef) {
          if (Array.isArray(specimenRef)) {
            specimenRef = specimenRef[0];
          }
        }
        specimenRef.handleShortKey(srcKey);
      } else if (this.hasMalignant) {
        let ref = "";
        switch (srcKey) {
          case "c":
            ref = "prostateClinicalScore";
            break;
          case "n":
            ref = "caseGleasonScore";
            break;
          case "o":
            ref = "likelihoodOfOrganConfinement";
            break;
          case "r":
            ref = "riskOfExtraprostaticExtension";
            break;
          case "e":
            ref = "extensionRiskOfSeminalVesicleInvolvement";
            break;
        }
        if (ref) {
          this.$refs[ref].focus();
        }
      }
    },
    getResultsMacros() {
      const specimenResultsMacros = [...this.specimens, this.currentSpecimen].map(
        e => e.resultsMacros
      );
      const uniqueMacros = uniqBy(specimenResultsMacros.flat(), "id");
      for (const macro of uniqueMacros) {
        MacrosApi.getMacroDetails(macro.id).then(res => {
          this.usedMacros.push(res);
        });
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    getSiteMap() {
      let siteMap = JSON.parse(this.ProstateSiteMap);
      if (this.specimens.length < 13) {
        siteMap = siteMap.filter((e, idx) => ![0, 5].includes(idx % 6));
      }
      this.siteMap = siteMap;
    },
    changeSelectedSpecimen(site) {
      const { specimenOrder } = this.getSpecimen(site);
      if (specimenOrder) {
        this.selectedSpecimenOrder = specimenOrder;
      }
    },
    getSpecimenRef(site) {
      const { specimenOrder } = this.getSpecimen(site);
      if (specimenOrder) {
        return "specimen" + specimenOrder;
      }
      return "noSpecimen" + site;
    }
  }
};
</script>

<style lang="scss" scoped>
.prostate-form {
  width: 95%;
  margin: auto;
}
</style>
