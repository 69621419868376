import api from "./api.js";
class Roles {
  constructor() {
    this.url = "/api/Roles";
  }

  //** DEPRECATED */
  // getLabRoles(labId) {
  //   return api.get(this.url + `/lab/${labId}`);
  // }
  // searchRolePermissions(options, roleId) {
  //   return api.post(this.url + `/${roleId}/permissions/search`, options);
  // }
  searchAllPermissions(options) {
    return api.post(this.url + `/permissions/search`, options);
  }
  getRolePermissions(roleId) {
    return api.get(this.url + `/${roleId}/permissions`);
  }
  getAllPermissions() {
    return api.get(this.url + "/Permissions");
  }
  updateRole(role) {
    return api.put(this.url, role);
  }
  addRole(role) {
    return api.post(this.url, role);
  }
  deleteRole(role) {
    return api.delete(this.url, role);
  }
}
export default new Roles();
