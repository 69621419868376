import api from "./api.js";
class IcdRules {
  constructor() {
    this.url = "/api/IcdRules/";
  }
  getIcdRules() {
    return api.get(this.url);
  }
  getIcdRuleById(id) {
    return api.get(this.url + `${id}`);
  }
  getIcdFormulaById(id) {
    return api.get("/api/icdFormulas/" + id);
  }
  updateIcdRule(data) {
    return api.put(this.url, data);
  }
  insertIcdRule(data) {
    return api.post(this.url, data);
  }
  removeIcdRule(id) {
    return api.delete(this.url + id);
  }
  findBillableCode(data) {
    return api.post(this.url + "FindBillableIcdCode", data);
  }
}
export default new IcdRules();
