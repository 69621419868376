<template>
  <div class="p-1">
    <div class="d-flex align-items-center justify-content-between">
      <h2 class="text-capitalize mr-2">{{ pageTitle }}</h2>
      <div class="d-flex align-items-center">
        <DxSwitch
          hint="Changes search mode."
          switchedOnText="All"
          v-model="searchType"
          switchedOffText="Case #"
        />
        <button class="ml-2 btn btn-outline-primary" @click="handleClearGridFilters">
          Clear Filters
        </button>
      </div>
    </div>
    <DxDataGrid
      :columnChooser="columnChooser"
      :data-source="accessionDataStore"
      @cell-click="handleCaseClick"
      noDataText="No cases found."
      :show-borders="true"
      :show-row-lines="true"
      :column-auto-width="true"
      :scrolling="scrolling"
      :allow-column-reordering="true"
      :remoteOperations="remoteOperations"
      :allow-column-resizing="true"
      columnResizingMode="widget"
      :hoverStateEnabled="true"
      @content-ready="onContentReady"
      @option-changed="onOptionChanged"
      @initialized="onInitialized"
      :onCellPrepared="redactLogRocket"
      :toolbar="toolbar"
      :pager="pager"
      :paging="paging"
      :sorting="sorting"
      :searchPanel="searchPanel"
      :headerFilter="headerFilter"
      :filterRow="filterRow"
      :selection="selection"
      :stateStoring="stateStoring"
      :cacheEnabled="false"
    >
      <DxColumn v-for="(column, i) of columns" :key="i" v-bind="column" />
      <template v-slot:actionsTemplate="{ data: { data, key, rowIndex } }">
        <div
          class="justify-content-start d-flex align-items-center"
          :data-testid="'actions-col-' + rowIndex"
        >
          <router-link
            v-if="permissions.CaseView"
            v-tooltip.right="'View case details.'"
            :to="getModeRoute(key) || '/'"
            class="btn-link btn p-0"
          >
            <icon class="text-primary" icon="eye" />
          </router-link>
          <icon-button
            v-if="permissions.PathReportView"
            v-tooltip.right="'View path report.'"
            class="btn text-primary pointer p-0 px-1 mr-1"
            @click="handleViewPathReport(key)"
            icon="window-restore"
          />
          <icon-button
            v-if="isCaseReadyForSignout(data)"
            @click="handleSignCase(data)"
            v-tooltip.right="signButton(data).message"
            :icon="signButton(data).icon"
            class="btn text-primary pointer p-0 px-1 mr-1"
            :disabled="isLoading"
          />
        </div>
      </template>
      <template v-slot:booleanValue="{ data }">
        <icon v-if="data.value" class="text-success" icon="check" />
      </template>
    </DxDataGrid>
    <modal :status="isDistributionOpen" @close="isDistributionOpen = false">
      <distribution-popup
        @close="isDistributionOpen = false"
        @submitted="clearSelection"
        :method="distributionMethod"
        :caseIds="selectedCasesForDistribution"
      />
    </modal>
    <modal :status="isLocationPopupOpen" @close="isLocationPopupOpen = false">
      <lab-location-popup
        :isLoading="isLoading"
        @cancel="isLocationPopupOpen = false"
        @sign="handleSelectLabLocation"
        :payloadToSign="payloadToSign"
      />
    </modal>
    <modal :status="isCollectedPopupOpen" @close="isCollectedPopupOpen = false">
      <collected-popup
        @submit="handleSetCollected"
        @cancel="isCollectedPopupOpen = false"
        :caseId="collectedPopupCaseId"
      />
    </modal>
    <PathReportViewer v-if="isInline && isActivated" class="mt-2" @close="closeReport" />
  </div>
</template>

<script>
import CaseService from "../services/cases";
import { mapGetters, mapState } from "vuex";
import { parseISO, format } from "date-fns";
import eventBus, {
  SIGNED_CASE_FROM_PDF,
  fromBusEvent,
  FILTER_ACCESSION_GRID,
  SIGN_CASE_ON_GRID,
  UPDATE_CUSTOM_RESULTS_STATUS
} from "@/modules/eventBus";
import { DxDataGrid, DxColumn } from "devextreme-vue/data-grid";
import DxSwitch from "devextreme-vue/switch";
import PathReportViewer from "./Reports/PathReportViewer.vue";
import Reports from "../services/Reports";
import { combineLatest, fromEvent } from "rxjs";
import {
  calculateAccessionNumbering,
  createLogItem,
  caseCreationMethodEnum,
  createQueryArray,
  formatDatetimeCell,
  createLocalTzDateFilter,
  addCommasToCount,
  checkIfProxy,
  calcultateNameFilter,
  caseGridAccessionNumberFilter,
  formatSSN
  // batchRedirect
} from "../modules/helpers";
import pathReportMessages from "../services/pathReportMessages";
import {
  take,
  tap,
  map,
  filter,
  switchMap,
  withLatestFrom,
  mergeMap,
  throttleTime,
  distinctUntilChanged
} from "rxjs/operators";
import AuditLog from "../services/AuditLog";
import { CasesApi, DropdownApi } from "../services/index";
import DistributionPopup from "./DistributionPopup.vue";
import Modal from "./common/Modal.vue";
import IconButton from "./common/IconButton.vue";
import Icon from "./common/Icon.vue";
import {
  AuditLogItems,
  CaseStatusEnum,
  DashboardModes,
  DistributionMethodsEnum
} from "@/modules/enums";
import CustomStore from "devextreme/data/custom_store";
import api from "@/services/api";
import DataSource from "devextreme/data/data_source";
import { handleErrors } from "@/modules/handleErrors";
import { scanCaseBarcode } from "@/modules/scanCaseBarcode";
import LabLocationPopup from "./LabLocationPopup.vue";
import ScannerDetection from "@/modules/scanner";
import CollectedPopup from "./CollectedPopup.vue";

export default {
  name: "Open-Cases-Grid",
  components: {
    DxDataGrid,
    PathReportViewer,
    DistributionPopup,
    DxSwitch,
    Modal,
    IconButton,
    DxColumn,
    Icon,
    LabLocationPopup,
    CollectedPopup
  },
  data() {
    return {
      isLoading: false,
      isDistributionOpen: false,
      selectedCasesForDistribution: [],
      priorities: [],
      distributionMethod: "",
      directQueryProperties: ["accessionNumber", "orderNumber"],
      isFiltered: false,
      popupVisible: false,
      report: {},
      grid: {},
      pathReport: {},
      filterRow: {
        visible: true,
        applyFilter: "auto"
      },
      searchPanel: {
        width: 300,
        visible: true
      },
      headerFilter: {
        visible: true
      },
      scrolling: {
        showScrollbar: "always",
        useNative: true
      },
      selection: {
        mode: "multiple",
        allowSelectAll: true,
        showCheckBoxesMode: "always",
        selectAllMode: "page"
      },
      sorting: {
        mode: "multiple"
      },
      remoteOperations: {
        filtering: true,
        sorting: true,
        paging: true
      },
      accessionGrid: {},
      grouping: {
        allowCollapsing: true
      },
      pager: {
        allowedPageSizes: [10, 15, 20, 25, 50, 100],
        showInfo: true,
        infoText: "Page {0} of {1} ({2} Cases)",
        showPageSizeSelector: true,
        visible: true,
        displayMode: "full"
      },
      paging: {
        enabled: true
      },
      searchType: false,
      isLocationPopupOpen: false,
      payloadToSign: null,
      scanner: {},
      billingCycles: [],
      isCollectedPopupOpen: false,
      collectedPopupCaseId: null,
      barcodeScanned: false,
      isActivated: true
    };
  },
  domStreams: [
    "contentReady$",
    "optionChanged$",
    "activated$",
    "deactivate$",
    "initialized$",
    "saveState$"
  ],
  subscriptions() {
    const currentRow$ = this.contentReady$.pipe(map(({ component }) => component.getVisibleRows()));
    const firstContentReady$ = this.contentReady$.pipe(take(1));
    const showFirstRowPathReport$ = this.contentReady$.pipe(
      throttleTime(5000),
      map(({ component }) => {
        return component.getKeyByRowIndex(0);
      }),
      filter(caseId => {
        const paramCaseId = this.$route.params.caseId;
        if (paramCaseId) {
          return paramCaseId === caseId;
        }
        return caseId && caseId !== this.reportCaseId;
      }),
      tap(caseId => {
        this.handleViewPathReport(caseId);
      })
    );

    const gridState$ = this.$watchAsObservable("currentUser", { immediate: true }).pipe(
      filter(({ newValue }) => newValue.isPathologist && newValue.id),
      mergeMap(({ newValue }) =>
        DropdownApi.searchPathologists.load({
          filter: ["userId", newValue.id]
        })
      ),
      switchMap(pathologistRecords =>
        this.$watchAsObservable("accessionMode", { immediate: true }).pipe(
          tap(({ newValue }) => {
            if (
              newValue === "signout" ||
              (this.labSettings.ResultModePathologistFilter && newValue === "result")
            ) {
              if (pathologistRecords[0] && pathologistRecords[0]?.id) {
                if (this.doctorProxies.length) {
                  DropdownApi.searchPathologists.load().then(res => {
                    let pathologistIds = [pathologistRecords[0].id];
                    for (const proxy of this.doctorProxies) {
                      const pathologist = res.find(e => e.userId === proxy);
                      if (pathologist) {
                        pathologistIds.push(pathologist.id);
                      }
                    }
                    this.$store.commit("applicationSettings/setCaseSearchHeader", {
                      ...this.casesSearchHeader,
                      pathologists: pathologistIds
                    });
                  });
                } else {
                  this.$store.commit("applicationSettings/setCaseSearchHeader", {
                    ...this.casesSearchHeader,
                    pathologists: [pathologistRecords[0].id]
                  });
                }
              } else {
                if (Array.isArray(this.casesSearchHeader?.pathologists)) {
                  const currentPathologist = this.casesSearchHeader.pathologists.findIndex(
                    e => e === pathologistRecords[0].id
                  );
                  if (currentPathologist > -1) {
                    this.$store.commit("applicationSettings/setCaseSearchHeader", {
                      ...this.casesSearchHeader,
                      pathologists: this.casesSearchHeader.pathologists?.filter(
                        e => e !== pathologistRecords[0].id
                      )
                    });
                  }
                } else {
                  this.$store.commit("applicationSettings/setCaseSearchHeader", {
                    ...this.casesSearchHeader,
                    pathologists: []
                  });
                }
              }
            }
          })
        )
      )
    );

    //Returns the searchPanel input element.
    const searchPanel$ = firstContentReady$.pipe(
      filter(({ element }) => element.querySelector('input[type="text"]')),
      map(({ element }) => {
        const searchPanel = element.querySelector('input[type="text"]');
        if (searchPanel) {
          searchPanel.setAttribute("id", "searchPanel");
          searchPanel.focus();
        }
        return searchPanel;
      })
    );
    const quickSearch$ = searchPanel$.pipe(
      switchMap(searchPanel =>
        /**
         * 1...
         * ...2
         * ......3
         */

        {
          return combineLatest([
            //This event will only dispatch when there is 1 or 0 row visible.
            currentRow$.pipe(
              filter(rows => rows.length < 2),
              distinctUntilChanged(
                (prev, curr) => prev[0]?.data.accessionNumber === curr[0]?.data.accessionNumber
              )
            ),
            //This event is being fired when enter is pressed.
            fromEvent(searchPanel, "keydown").pipe(
              filter(event => event.keyCode === 13),
              map(event => event.target.value),
              tap(() => (this.barcodeScanned = false))
            )
          ]).pipe(
            filter(() => !this.barcodeScanned),
            tap(([data, searchValue]) => {
              if (data?.length === 1) {
                this.handleDirectQuery(data[0].data, searchValue);
                console.log("Case matched with %s", searchValue);
              } else if (data?.length == 0) {
                this.searchAnyCase(searchValue);
              }
            })
          );
        }
      )
    );

    const listenForFocusSearch$ = searchPanel$.pipe(
      switchMap(searchPanel => {
        return fromBusEvent(SIGNED_CASE_FROM_PDF).pipe(
          filter(() => this.isActivated),
          tap(() => {
            this.refreshGrid();
            searchPanel.focus();
            searchPanel.setSelectionRange(0, searchPanel.value.length);
          })
        );
      })
    );
    const focusSearch$ = this.activated$.pipe(
      withLatestFrom(searchPanel$),
      tap(data => {
        const [value, searchPanel] = data;
        searchPanel.focus();
        if (value) {
          searchPanel.setSelectionRange(0, searchPanel.value.length);
          this.refreshGrid();
        }
      })
    );
    const showSignoutQueue$ = fromBusEvent(FILTER_ACCESSION_GRID).pipe(
      withLatestFrom(firstContentReady$),
      tap(([filters, grid]) => {
        grid.clearFilter();
        filters.forEach(values => {
          grid.columnOption(...values);
        });
      })
    );

    return {
      showFirstRowPathReport$,
      searchPanel$,
      gridState$,
      quickSearch$,
      listenForFocusSearch$,
      focusSearch$,
      showSignoutQueue$
    };
  },
  activated() {
    this.handleOnActivated();
  },
  deactivated() {
    this.isActivated = false;
    if (this.scanner?.stopScanning) {
      this.scanner.stopScanning();
    }
  },
  watch: {
    accessionMode: {
      handler(nv, ov) {
        if (nv !== ov) {
          this.grid.searchByText("");
        }
      }
    },
    currentCaseId: {
      immediate: true,
      handler(nv) {
        this.getNextCase(nv);
      }
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser,
      reportViewer: state => state.report,
      pathReportLocation: state => state.applicationSettings.pathReportLocation,
      currentLab: state => state.currentLab,
      caseStatuses: state => state.caseStatuses,
      labSettings: state => state.labSettings,
      systemInformation: state => state.systemInformation,
      accessionFilter: state => state.applicationSettings.accessionFilter,
      accessionMode: state => state.applicationSettings.accessionMode,
      casesSearchHeader: state => state.applicationSettings.casesSearchHeader,
      isMobileView: state => state.isMobileView,
      AccessionNumberingType: state => state.labSettings.AccessionNumberingType,
      casesGridState: state => state.grids.openCases,
      reportCaseId: state => state.report.caseId,
      currentLabLocation: state => state.currentLabLocation,
      availableLabLocations: state => state.availableLabLocations,
      homeGridMode: state => state.applicationSettings.homeGridMode,
      doctorProxies: state => state.doctorProxies,
      customResultStatuses: state => state.applicationSettings.customResultStatuses,
      currentCaseId: state => state.accessionStore.caseDetails.caseId,
      nextCaseDashboardFilter: state => state.applicationSettings.nextCaseDashboardFilter
    }),
    ...mapGetters(["permissions"]),
    ...mapGetters("report", ["isInline"]),
    stateStoring() {
      let customSave = this.customStateSave;
      let customLoad = this.customStateLoad;
      return {
        enabled: true,
        type: "custom",
        customSave,
        customLoad,
        ignoreColumnOptionNames: ["filterValue", "filterValues", "headerFilter", "filterType"]
      };
    },
    toolbar() {
      return {
        visible: true,
        items: [
          {
            widget: "dxButton",
            visible: this.permissions.CaseView,
            options: {
              icon: "fa fa-file-excel",
              onClick: this.exportToExcel,
              hint: "Click to export as excel file",
              elementAttr: {
                "data-testId": "toolbar-action-excel",
                class: "icon-color"
              },
              text: "Export to Excel"
            },
            locateInMenu: "auto",
            showText: "inMenu"
          },
          // IP-1413: Removed dowload button from Open Cases Grid
          // {
          //   widget: "dxButton",
          //   visible: this.permissions.CaseView,
          //   options: {
          //     icon: "fa fa-download",
          //     onClick: this.handleDownloadCases,
          //     hint: "Click to download selected cases.",
          //     elementAttr: {
          //       "data-testId": "toolbar-action-download",
          //       class: "icon-color"
          //     },
          //     text: "Download"
          //   },
          //   locateInMenu: "auto",
          //   showText: "inMenu"
          // },
          {
            widget: "dxButton",
            visible: this.permissions.CaseSignout || this.permissions.CaseSignoutByProxy,
            options: {
              icon: "fa fa-pen",
              onClick: this.handleSignMultipleCase,
              hint: "Click Sign Out selected cases.",
              elementAttr: {
                "data-testId": "toolbar-action-signOut",
                class: "icon-color"
                // disabled: this.isLoading
              },
              text: "Sign Out"
            },
            locateInMenu: "auto",
            showText: "inMenu"
          },
          {
            widget: "dxButton",
            visible: this.permissions.PathReportSendByPrint,
            options: {
              icon: "fa fa-print",
              onClick: () => this.handleDistributeCases(DistributionMethodsEnum.Print),
              hint: "Click to print selected cases.",
              elementAttr: {
                "data-testId": "toolbar-action-print",
                class: "icon-color"
              },
              text: "Print"
            },
            locateInMenu: "auto",
            showText: "inMenu"
          },
          {
            widget: "dxButton",
            visible: this.permissions.PathReportSendByFax,
            options: {
              icon: "fa fa-fax",
              onClick: () => this.handleDistributeCases(DistributionMethodsEnum.Fax),
              hint: "Click to fax selected cases.",
              elementAttr: {
                "data-testId": "toolbar-action-fax",
                class: "icon-color"
              },
              text: "Fax"
            },
            locateInMenu: "auto",
            showText: "inMenu"
          },
          {
            widget: "dxButton",
            visible: this.permissions.PathReportSendByEmail,
            options: {
              icon: "fa fa-envelope",
              onClick: () => this.handleDistributeCases(DistributionMethodsEnum.Email),
              hint: "Click to email selected cases.",
              elementAttr: {
                "data-testId": "toolbar-action-email",
                class: "icon-color"
              },
              text: "Email"
            },
            locateInMenu: "auto",
            showText: "inMenu"
          },
          {
            widget: "dxButton",
            visible: this.permissions.PathReportSendByFileDrop,
            options: {
              icon: "fa fa-file-pdf",
              onClick: () => this.handleDistributeCases(DistributionMethodsEnum.FileDrop),
              hint: "Click to file drop selected cases.",
              elementAttr: {
                "data-testId": "toolbar-action-fileDrop",
                class: "icon-color"
              },
              text: "File Drop"
            },
            locateInMenu: "auto",
            showText: "inMenu"
          },
          {
            widget: "dxButton",
            visible: this.permissions.PathReportSendByHL7,
            options: {
              icon: "fa fa-share-alt",
              onClick: () => this.handleDistributeCases(DistributionMethodsEnum.HL7),
              hint: "Click to use hl7 on selected cases.",
              elementAttr: {
                "data-testId": "toolbar-action-HL7",
                class: "icon-color"
              },
              text: "Use HL7"
            },
            locateInMenu: "auto",
            showText: "inMenu"
          },
          {
            name: "columnChooserButton",
            visible: true
          },
          {
            name: "searchPanel",
            visible: true
          }
        ]
      };
    },
    columnChooser() {
      return {
        enabled: true,
        allowSearch: true,
        mode: "select"
      };
    },
    pageTitle() {
      return `${this.accessionFilter || ""} Cases`;
    },
    isReportOpen() {
      return this.reportViewer.isOpen;
    },
    pathologistFilter() {
      if (this.accessionMode === "signout" && this.currentUser.isPathologist) {
        return `${this.currentUser.firstName} ${this.currentUser.lastName}`;
      }
      return null;
    },
    statusFilter() {
      const {
        Acc,
        Demo,
        Spec,
        Gross,
        Orders,
        ResultedOnHold,
        SignedOnHold,
        ReportedPrelim,
        Resulted,
        Signed,
        ResultedAgain,
        SignedAgain
      } = CaseStatusEnum;
      const defaultFilter = [
        Acc,
        Demo,
        Spec,
        Gross,
        Orders,
        ResultedOnHold,
        SignedOnHold,
        ReportedPrelim,
        Resulted,
        Signed,
        ResultedAgain,
        SignedAgain
      ];
      if (this.casesSearchHeader.alertFilter) {
        return defaultFilter;
      }
      switch (this.accessionMode) {
        case "specimen":
          return [Acc, Demo, Spec, Orders];
        case "result":
          // if (this.customResultStatuses?.length) {
          //   return this.customResultStatuses;
          // }
          if (this.labSettings.ResultModeShowOnHold && this.casesSearchHeader.holdCodes.length) {
            return [Spec, Gross, Orders, ResultedOnHold];
          }
          return [Spec, Gross, Orders];
        case "signout":
          return [ResultedOnHold, Resulted, ResultedAgain];
        default:
          return defaultFilter;
      }
    },
    isProvider() {
      const { isLabClientUser } = this.currentUser;
      if (isLabClientUser) {
        return true;
      }
      return false;
    },
    accessionDataStore() {
      const { procedures, casePrefixTags, holdCodes, pathologists, alertFilter, tags } =
        this.casesSearchHeader;
      const url = `/api/Cases/search?${
        pathologists?.length ? "&" + createQueryArray(pathologists, "pathologists") : ""
      }${casePrefixTags?.length ? "&" + createQueryArray(casePrefixTags, "casePrefixTags") : ""}${
        holdCodes?.length ? "&" + createQueryArray(holdCodes, "holdCodes") : ""
      }${procedures?.length ? "&" + createQueryArray(procedures, "procedures") : ""}${
        tags?.length ? "&" + createQueryArray(tags, "tags") : ""
      }
${alertFilter ? "&alertFilter=" + alertFilter : ""}`;
      const store = api.createSearch(url, "caseId");
      return new DataSource({
        store
      });
    },
    columns() {
      const priorityStore = new CustomStore({
        key: ["id", "displayName"],
        load() {
          return DropdownApi.getPriorities();
        }
      });
      return [
        {
          type: "buttons",
          cellTemplate: "actionsTemplate",
          caption: "Action",
          width: "100px"
        },
        {
          dataField: "caseCreationMethodId",
          caption: "Method",
          lookup: {
            dataSource: caseCreationMethodEnum,
            displayExpr: "displayName",
            valueExpr: "id"
          },
          dataType: "number",
          allowSearch: this.searchType
        },
        {
          dataField: "priority",
          dataType: "string",
          allowSearch: this.searchType,
          allowSorting: true,
          calculateSortValue: "prioritySort",
          lookup: {
            dataSource: priorityStore,
            displayExpr: "displayName",
            valueExpr: "displayName"
          }
        },
        {
          dataField: "caseStatus",
          dataType: "string",
          allowFiltering: true,
          filterValues: this.statusFilter,
          showInColumnChooser: false,
          defaultFilterValues: [],
          lookup: {
            dataSource: this.caseStatuses.map((e, i) => ({ displayName: e, id: i })),
            displayExpr: "displayName",
            valueExpr: "id"
          },
          caption: "Status",
          allowSearch: false
        },
        {
          dataField: "numberYear",
          caption: "Year",
          alowSearch: false
        },
        {
          dataField: "numberSequence",
          dataType: "number",
          caption: "Number",
          allowSearch: this.searchType
        },
        {
          dataField: "accessionNumber",
          allowReordering: true,
          caption: "Case #",
          allowHeaderFiltering: false,
          dataType: "string",
          sortOrder: "asc",
          sortIndex: 0,
          allowSearch: true,
          calculateFilterExpression: caseGridAccessionNumberFilter(this.AccessionNumberingType),
          selectedFilterValue: "="
        },
        {
          dataField: "patientName",
          allowReordering: true,
          dataType: "string",
          caption: "Name",
          allowHeaderFiltering: false,
          allowSearch: this.searchType,
          filterOperations: ["startswith"],
          calculateFilterExpression: value => calcultateNameFilter(value)
        },
        {
          dataField: "patientDOB",
          allowReordering: true,
          dataType: "date",
          caption: "DOB",
          allowHeaderFiltering: false,
          allowSearch: this.searchType
        },
        {
          dataField: "patientAgeAtReceiptDisplay",
          allowReordering: true,
          caption: "Age",
          dataType: "string",
          allowSearch: false,
          allowSorting: false,
          allowHeaderFiltering: false,
          allowFiltering: false
        },
        {
          dataField: "patientSex",
          caption: "Gender",
          allowReordering: true,
          dataType: "number",
          width: "50px",
          lookup: {
            dataSource: [
              {
                text: "M",
                id: 0
              },
              {
                text: "F",
                id: 1
              },
              {
                text: "O",
                id: 2
              },
              {
                text: "U",
                id: 3
              }
            ],
            displayExpr: "text",
            valueExpr: "id"
          },
          allowHeaderFiltering: false,
          allowSearch: this.searchType
        },

        {
          dataField: "collectedOn",
          caption: "Collected",
          allowReordering: true,
          dataType: "date",
          allowHeaderFiltering: false,
          allowSearch: this.searchType
        },

        {
          dataField: "numberOfSpecimens",
          allowReordering: true,
          caption: "Quantity",
          dataType: "number",
          width: "50px",
          allowHeaderFiltering: false,
          allowSearch: this.searchType
        },
        {
          dataField: "patientMRN",
          allowReordering: true,
          caption: "MRN",
          dataType: "string",
          allowHeaderFiltering: false,
          allowFiltering: true,
          allowSearch: this.searchType
        },

        {
          dataField: "patientSSN",
          allowReordering: true,
          caption: "SSN",
          dataType: "string",
          visible: this.permissions.SocialSecurityNumberView,
          allowSorting: false,
          allowHeaderFiltering: false,
          allowSearch: false,
          allowFiltering: false,
          calculateDisplayValue: data => {
            if (data.patientSSN) {
              return formatSSN(data.patientSSN);
            }
            return "";
          }
        },
        {
          dataField: "providerName",
          allowReordering: true,
          caption: "Provider",
          dataType: "string",
          allowSearch: this.searchType
        },
        {
          dataField: "providerOfficeName",
          caption: "Location",
          dataType: "string",
          allowReordering: true,
          allowSearch: this.searchType
        },
        {
          dataField: "receivedOn",
          allowReordering: true,
          caption: "Received",
          dataType: "date",
          allowHeaderFiltering: false,
          allowSearch: this.searchType
        },
        {
          dataField: "reportedOn",
          caption: "Reported",
          allowReordering: true,
          dataType: "date",
          allowSearch: this.searchType,
          calculateDisplayValue: data => {
            if (data.reportedOn) {
              return this.calculateReportedOn(data.reportedOn);
            }
            return "";
          },
          calculateFilterExpression: (data, filterExpr) => {
            return createLocalTzDateFilter(data, filterExpr, "reportedOn");
          },
          calculateSortValue: "reportedOn"
        },
        {
          dataField: "orderNumber",
          filterField: "OrderNumber",
          allowReordering: true,
          dataType: "string",
          caption: "Order #",
          allowSearch: this.searchType
        },
        {
          dataField: "accountNumber",
          filterField: "AcctNumber",
          dataType: "string",
          allowReordering: true,
          caption: "Account Number",
          allowSearch: this.searchType
        },
        {
          dataField: "pathologistName",
          caption: "Pathologist",
          dataType: "string",
          allowSearch: this.searchType
        },
        {
          dataField: "billingCycle",
          caption: "Billing Cycle",
          dataType: "string",
          lookup: {
            dataSource: this.billingCycles,
            displayExpr: "displayName",
            valueExpr: "id"
          }
        },
        {
          dataField: "hx",
          filterField: "hx",
          allowReordering: true,
          dataType: "boolean",
          cellTemplate: "booleanValue",
          allowHeaderFiltering: true,
          allowFiltering: false,
          caption: "HX",
          headerFilter: {
            dataSource: [
              {
                text: "True",
                value: ["hx", "=", true]
              },
              {
                text: "False",
                value: ["hx", "=", false]
              }
            ]
          },
          allowSearch: this.searchType
        },
        {
          dataField: "ins",
          allowHeaderFiltering: true,
          filterField: "Ins",
          caption: "INS",
          allowReordering: true,
          dataType: "boolean",
          cellTemplate: "booleanValue",
          allowFiltering: false,
          headerFilter: {
            dataSource: [
              {
                text: "True",
                value: ["ins", "=", true]
              },
              {
                text: "False",
                value: ["ins", "=", false]
              }
            ]
          },
          allowSearch: this.searchType
        },
        {
          dataField: "hasSlideImages",
          allowHeaderFiltering: true,
          filterField: "Slides",
          caption: "Slides",
          allowReordering: true,
          dataType: "boolean",
          cellTemplate: "booleanValue",
          allowFiltering: false,
          headerFilter: {
            dataSource: [
              {
                text: "True",
                value: ["hasSlideImages", "=", true]
              },
              {
                text: "False",
                value: ["hasSlideImages", "=", false]
              }
            ]
          },
          allowSearch: false
        }
      ];
    }
  },
  mounted() {
    DropdownApi.getBillingCycles().then(res => {
      this.billingCycles = res || [];
    });
    eventBus.$on(UPDATE_CUSTOM_RESULTS_STATUS, () => this.refreshGrid());
  },
  beforeDestroy() {
    eventBus.$off(UPDATE_CUSTOM_RESULTS_STATUS);
  },
  methods: {
    clearSelection() {
      if (this.grid.clearSelection) {
        this.grid.clearSelection();
      }
    },
    handleDirectQuery(data, searchValue) {
      ["accessionNumber", "orderNumber"].forEach(property => {
        if (data[property]) {
          let rowValue = data[property].replace(/[a-z]/gi, "");
          if (property === "accessionNumber") {
            searchValue = calculateAccessionNumbering(searchValue, this.AccessionNumberingType);
            searchValue = searchValue.replace(/[a-z]/gi, "");
          }
          rowValue = RegExp.escape(rowValue || "");
          const targetRegex = new RegExp(rowValue, "i");
          if (targetRegex.test(searchValue) && !this.barcodeScanned) {
            return this.handleNavigateToCase(data.caseId);
          }
        }
      });
    },
    focusElement(element) {
      this.$nextTick(() => element.focus({ preventScroll: true }));
    },
    onContentReady(event) {
      addCommasToCount();
      this.contentReady$?.next(event);
    },
    onInitialized(event) {
      this.grid = event.component;
      this.initialized$.next(event);
    },
    refreshGrid() {
      if (this.grid.refresh) {
        this.grid.refresh(true);
        this.grid.clearSelection();
      }
    },
    repaintGrid() {
      if (this.grid.repaint) {
        this.grid.repaint(true);
      }
    },
    onOptionChanged(event) {
      this.optionChanged$?.next(event);
    },
    isCaseReadyForSignout(data) {
      if (!data?.pathologistGUID) {
        return false;
      }
      if (this.currentUser.id !== data.pathologistGUID && !checkIfProxy(data.pathologistGUID)) {
        return false;
      }
      return (
        (this.permissions.CaseSignout || this.permissions.CaseSignoutByProxy) &&
        [6, 9, 12].includes(data.caseStatus)
      );
    },
    closeReport() {
      this.$store.commit("report/toggleReportViewer", false);
    },
    customStateSave(gridState) {
      const payload = {
        name: "openCases",
        gridState,
        columns: this.columns
      };

      this.$store.dispatch("grids/setGridState", payload);
    },
    async customStateLoad() {
      return this.casesGridState;
    },
    handleViewPathReport(caseId) {
      this.$store.dispatch("report/viewPathReport", { caseId });
    },
    async handleSignMultipleCase() {
      if (this.isLoading) {
        return;
      }
      this.payloadToSign = null;
      const selectedRows = this.grid.getSelectedRowsData();
      if (selectedRows.length === 0) {
        return window.notify("No case selected.", "error");
      }
      if (
        selectedRows.find(
          e => e.pathologistGUID !== this.currentUser.id && !checkIfProxy(e.pathologistGUID)
        )
      ) {
        window.alert(
          "User does not have permission to sign out one or more selected cases as primary pathologist or as proxy. Please check your selection and try again."
        );
        return;
      }
      if (this.availableLabLocations.length > 1 && !this.currentLabLocation) {
        this.isLocationPopupOpen = true;
        return;
      }
      const caseIds = selectedRows
        .filter(
          e =>
            [
              CaseStatusEnum.ResultedOnHold,
              CaseStatusEnum.Resulted,
              CaseStatusEnum.ResultedAgain,
              CaseStatusEnum.Signed
            ].includes(e.caseStatus) && e.collectedOn
        )
        .map(v => {
          return v.caseId;
        });
      if (
        this.labSettings.ConfirmPrelimSignOuts &&
        selectedRows.find(e => e.caseStatus === CaseStatusEnum.ResultedOnHold)
      ) {
        const confirm = await window.confirm(
          "One or more cases you are signing out has a hold code attached. Are you sure you want to sign these cases as prelim?"
        );
        if (!confirm) {
          return;
        }
      }
      const unsignableCases = selectedRows.filter(e => !caseIds.includes(e.caseId));
      try {
        this.isLoading = true;
        if (caseIds.length > 0) {
          document.body.style.cursor = "wait";
          const SignResponse = await CaseService.multiSignCase(caseIds, this.currentLabLocation);
          if (SignResponse && unsignableCases.length) {
            window.alert(
              `${
                caseIds.length
              } cases successfully signed<br><br>The following cases could not be signed:<br>${unsignableCases
                .map(e => e.accessionNumber)
                .join("<br>")}`
            );
            const logItem = createLogItem(
              SignResponse.length === 1 ? SignResponse[0] : {},
              AuditLogItems.ChangeAccession
            );
            logItem.comments = `Signed out case${
              SignResponse.length > 1 ? "s" : ""
            } ${SignResponse.map(e => e.caseNumber).join(", ")}.`;
            AuditLog.insertLogMessage(logItem);
          } else if (SignResponse) {
            window.notify(`${caseIds.length} cases successfully signed`);
            const logItem = createLogItem(
              SignResponse.length === 1 ? SignResponse[0] : {},
              AuditLogItems.ChangeAccession
            );
            logItem.comments = `Signed out case${
              SignResponse.length > 1 ? "s" : ""
            } ${SignResponse.map(e => e.caseNumber).join(", ")}.`;
            AuditLog.insertLogMessage(logItem);
          }
          document.body.style.cursor = "";
          this.refreshGrid();
          this.$nextTick(() => eventBus.$emit(SIGN_CASE_ON_GRID));
        } else {
          throw "Cases cannot be signed because they are not in the right status or do not have Collected On dates.";
        }
      } catch (error) {
        handleErrors(error);
      } finally {
        this.isLoading = false;
        document.body.style.cursor = "";
      }
    },
    async handleSignCase(caseDetails) {
      if (this.isLoading) {
        return;
      }
      this.payloadToSign = null;
      if (
        this.labSettings.ConfirmPrelimSignOuts &&
        caseDetails.caseStatus === CaseStatusEnum.ResultedOnHold
      ) {
        const confirm = await window.confirm(
          "This case has a hold code attached. Are you sure you want to sign it as prelim?"
        );
        if (!confirm) {
          return;
        }
      }
      try {
        this.isLoading = true;
        const { caseStatus, collectedOn, caseId } = caseDetails;
        if (this.canSignOut(caseDetails)) {
          if (this.availableLabLocations.length > 1 && !this.currentLabLocation) {
            this.isLocationPopupOpen = true;
            this.payloadToSign = caseDetails;
            return;
          }
          const isSigned = [7, 8, 10, 11, 13, 14].includes(caseStatus);
          if (!isSigned && !collectedOn) {
            this.isCollectedPopupOpen = true;
            this.collectedPopupCaseId = caseId;
            return;
          } else {
            const caseId = caseDetails.caseId;
            document.body.style.cursor = "wait";
            const SignResponse = await this.$store.dispatch("accessionStore/handleSignCase", {
              caseId,
              labLocationId: this.currentLabLocation,
              caseStatus
            });
            if (SignResponse) {
              window.notify(
                `Successfully ${caseStatus === 10 ? "unsigned" : "signed"} accession ${
                  caseDetails.accessionNumber
                }. `
              );
              // if (SignResponse?.batchRedirect) {
              //   batchRedirect();
              // }
            }
            document.body.style.cursor = "";
            if (this.searchPanel$?.focus) {
              this.focusElement(this.searchPanel$);
            }
            this.refreshGrid();
          }
        } else {
          throw "Case cannot be signed with the current status.";
        }
      } catch (error) {
        handleErrors(error);
      } finally {
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
        document.body.style.cursor = "";
      }
    },
    async handleDownloadCase({ data }) {
      const response = Reports.getSSRSReport({ caseId: data.caseId, format: "PDF" });
      const url = URL.createObjectURL(response);
      var a = document.createElement("a");
      a.href = url;
      a.download = `${data.accessionNumber}_REPORT`;
      a.click();
    },
    exportToExcel() {
      let selectedRows = this.grid.getSelectedRowsData();
      if (selectedRows.length) {
        this.grid.exportToExcel(true);
      } else {
        selectedRows = this.grid.getVisibleRows().map(e => e.data);
        this.grid.exportToExcel();
      }
      const logItem = createLogItem({}, 8, "Accession Grid");
      logItem.comments =
        "Exported the following cases to excel " +
        selectedRows.map(e => e.accessionNumber).join(" \n ");
      AuditLog.insertLogMessage(logItem);
      return;
    },
    markAsViewed({ data }) {
      const { isViewed, caseId } = data;
      const userMeta = {
        isViewed: !isViewed,
        caseId
      };
      return CaseService.updateUserMeta(userMeta).then(this.grid.refresh);
    },
    markAll() {
      const data = this.grid.getSelectedRowsData();
      return Promise.all(data.map(e => this.markAsViewed({ data: e }))).then(this.grid.refresh);
    },
    getLocation(data) {
      const contactEntry = data?.contacts?.find(e => e.isPrimary);
      if (contactEntry) {
        const {
          contact: { displayName }
        } = contactEntry;
        return displayName || "N/A";
      }
      return "N/A";
    },
    getFullName(data) {
      return data.patientLastName + ", " + data.patientFirstName;
    },
    getStatus({ caseStatus }) {
      return this.caseStatuses[caseStatus] || "Other";
    },
    canSignOut(data) {
      const { caseStatus } = data;
      if (caseStatus === 6 || caseStatus === 9 || caseStatus === 12 || caseStatus === 10) {
        return true;
      }
      return false;
    },
    signButton(data) {
      const { caseStatus } = data;
      if (caseStatus === 6 || caseStatus === 9 || caseStatus === 12) {
        return { icon: "edit", message: "Sign accession." };
      }
      if (caseStatus === 10) {
        return { icon: "unlink", message: "Unsign accession" };
      }
      return { icon: "edit", message: "Sign accession." };
    },
    getDOB(data) {
      return data.patientDOB ? format(parseISO(data.patientDOB), "MM/dd/yyyy") : "";
    },
    getSex(data) {
      switch (data.patientSex) {
        case 0:
          return "M";
        case 1:
          return "F";
        default:
          return "";
      }
    },
    async handleDownloadCases() {
      const cases = this.grid.getSelectedRowsData();
      if (cases.length === 0) {
        return window.notify("No accessions selected.", "error");
      }
      this.isLoading = true;

      try {
        const file = await CasesApi.getMultiCasePdf(cases.map(e => e.caseId));
        const url = URL.createObjectURL(file);
        const a = document.createElement("a");
        a.href = url;
        if (cases.length === 1) {
          a.download = `${format(new Date(), "MM/dd/yyyy")}_${
            cases[0]?.accessionNumber || ""
          }_PATH_REPORT.pdf`;
        } else {
          a.download = `${format(new Date(), "MM/dd/yyyy")}_PATH_REPORTS.pdf`;
        }
        a.click();
        pathReportMessages.addPathReportMessage({ caseIds: cases.map(e => e.caseId), method: 6 });
        const logItem = createLogItem({}, 9, "Accession Grid");
        logItem.comments =
          "Downloaded the following pdfs " + cases.map(e => e.accessionNumber).join(" \n ");
        AuditLog.insertLogMessage(logItem);
      } catch (error) {
        window.notify("Error loading file.", "error");
      }
      this.isLoading = false;
    },

    async handleDistributeCases(method) {
      const cases = this.grid.getSelectedRowsData();
      if (cases.length === 0) {
        return window.notify("No accessions selected.", "error");
      }
      const allStatuses = cases.map(e => e.caseStatus);
      let signedCases = cases.filter(e => [7, 8, 10, 11, 13, 14].includes(e.caseStatus));
      const allCasesSigned = allStatuses.every(e =>
        [
          CaseStatusEnum.SignedOnHold,
          CaseStatusEnum.ReportedPrelim,
          CaseStatusEnum.SignedAgain,
          CaseStatusEnum.Reported,
          CaseStatusEnum.SignedAgain,
          CaseStatusEnum.Reported,
          CaseStatusEnum.Signed
        ].includes(e)
      );
      if (!allCasesSigned) {
        if (method === DistributionMethodsEnum.HL7) {
          if (!signedCases?.length) {
            window.alert("Cases that have not been signed cannot be distributed through HL7.");
            return;
          }
          const confirm = await window.confirm(
            `<div class="text-center">
              One or more of the selected accessions has not been signed out and cannot be distributed through HL7.<br>Do you wish to continue?
          </div>`
          );
          if (!confirm) {
            return;
          }
        } else {
          const confirm = await window.confirm(
            `<div class="text-center">
              One or more of the selected accessions has not been signed out. <br> Are you sure you want to distribute this report?
          </div>`
          );
          if (!confirm) {
            return;
          }
        }
      }
      this.selectedCasesForDistribution =
        method === DistributionMethodsEnum.HL7
          ? signedCases.map(e => e.caseId)
          : cases.map(e => e.caseId);
      this.distributionMethod = method;
      this.isDistributionOpen = true;
    },
    getCollectedOn(data) {
      return data.collectedOn ? format(parseISO(data.collectedOn), "MM/dd/yyyy") : "";
    },
    getReceiveOn(data) {
      return data.receivedOn ? format(parseISO(data.receivedOn), "MM/dd/yyyy") : "";
    },
    getReportedOn(data) {
      return data.reportedOn ? format(parseISO(data.reportedOn), "MM/dd/yyyy") : "";
    },
    getOrderNumber(data) {
      return data.orderNumber ? data.orderNumber : "";
    },
    getModeRoute(caseId) {
      if (this.$route.fullPath.includes("accession")) {
        return;
      }
      let route = "/";
      if (this.isMobileView) {
        route = {
          name: "CaseView",
          params: { caseId }
        };
        return;
      }
      switch (this.accessionMode) {
        case "specimen":
          route = {
            name: "Specimen",
            params: { caseId }
          };
          break;
        case "result":
          route = {
            name: "SpecimenResults",
            params: { caseId }
          };
          break;
        case "signout":
          route = {
            name: "SpecimenResults",
            params: { caseId }
          };
          break;
        case "transactions":
          route = {
            name: "accesionTransactions",
            params: { caseId }
          };
          break;
        case "insurance":
          route = {
            name: "accessionInsurance",
            params: { caseId }
          };
          break;
        case "distribution":
          route = {
            name: "Distribution",
            params: { caseId }
          };
          break;
        case "history":
          route = {
            name: "CaseHistory",
            params: { caseId }
          };
          break;
        default:
          route = {
            name: "CaseView",
            params: { caseId }
          };
          break;
      }
      return route;
    },
    handleNavigateToCase(caseId) {
      if (this.$route.fullPath.includes("accession")) {
        return;
      }
      const route = this.getModeRoute(caseId);
      this.$router.push(route);
    },
    handleCaseClick(event) {
      //Stops the rerouting if you click inside the action or selection cell
      if (event?.column?.name === "buttons" || !event?.column?.name) {
        return;
      }
      if (event.data) {
        return this.handleNavigateToCase(event.data.caseId);
      }
    },
    handleClearGridFilters() {
      this.grid.clearFilter("row");
      this.grid.clearFilter("header");
      this.grid.clearFilter("filterValue");
      this.grid.columnOption("caseStatus", "filterValues", this.statusFilter);
      this.grid.clearFilter("search");
      this.grid.clearFilter("dataSource");
    },
    calculateReportedOn(reportedOn) {
      if (reportedOn) {
        const formattedDate = formatDatetimeCell(reportedOn);
        return format(formattedDate, "M/dd/yyyy");
      }
      return "";
    },
    async searchAnyCase(searchValue) {
      searchValue = searchValue.trim();
      this.isLoading = true;
      let prefix = "";
      if (/[A-Z]{1,6}/i.test(searchValue)) {
        prefix = searchValue.match(/[A-Z]{1,6}/i)[0].toUpperCase();
      }
      const numberToSearch = calculateAccessionNumbering(searchValue, this.AccessionNumberingType);
      const caseMatches = await CasesApi.searchStore.load({
        filter: ["accessionNumber", prefix ? "=" : "contains", numberToSearch]
      });
      if (caseMatches.length === 1 && !this.barcodeScanned) {
        this.handleNavigateToCase(caseMatches[0].caseId);
      }
      this.isLoading = false;
    },
    handleSelectLabLocation(data) {
      this.payloadToSign = null;
      this.isLocationPopupOpen = false;
      if (data?.caseId) {
        this.handleSignCase(data);
      } else {
        this.handleSignMultipleCase();
      }
    },
    async handleScanBarcode(barcode) {
      this.barcodeScanned = true;
      this.devlog("Scanned barcode in Open Cases Grid", barcode);
      if (this.$route.name !== "Home" || this.homeGridMode !== DashboardModes.Cases) {
        return;
      }
      const modalOpen = await document.getElementsByClassName("modal-mask");
      if (modalOpen.length) {
        return;
      }
      const { caseNumber, specimenOrder } = scanCaseBarcode(barcode);
      if (!caseNumber) {
        return;
      }
      if (specimenOrder && ["specimen", "result"].includes(this.accessionMode)) {
        this.$store.commit("accessionStore/setSpecimenToLoad", specimenOrder);
      }
      const matchingCases = await CasesApi.searchStore.load({
        filter: ["accessionNumber", caseNumber]
      });
      if (matchingCases.length === 1) {
        this.handleNavigateToCase(matchingCases[0].caseId);
      } else {
        this.barcodeScanned = false;
      }
    },
    async handleSetCollected(collectedOnDate) {
      this.isCollectedPopupOpen = false;
      if (!this.collectedPopupCaseId) {
        window.alert("An error ocurred signing out this case. Please try again.");
      }
      const caseDetails = await CasesApi.getCaseById(this.collectedPopupCaseId);
      const updatedCase = await this.$store.dispatch("accessionStore/updateCaseDetails", {
        ...caseDetails,
        collectedOn: collectedOnDate
      });
      this.handleSignCase({
        ...updatedCase,
        caseStatus: updatedCase?.caseStatus || updatedCase?.status
      });
      this.collectedPopupCaseId = null;
    },
    getNextCase(caseId) {
      if (this.nextCaseDashboardFilter && caseId && this.grid?.getRowIndexByKey) {
        const index = this.grid.getRowIndexByKey(caseId);
        const rows = this.grid.getVisibleRows();
        const { data } = rows[index + 1];
        if (data) {
          this.$store.commit("accessionStore/setNextCaseToLoad", {
            caseId: data.caseId,
            caseNumber: data.accessionNumber
          });
          return;
        }
      }
      this.$store.commit("accessionStore/setNextCaseToLoad", null);
      if (this.grid?.refresh) {
        this.grid.refresh(true);
      }
    },
    handleOnActivated() {
      console.log("on activated called");
      this.scanner = new ScannerDetection({
        onComplete: this.handleScanBarcode,
        stopPropogation: true,
        minLength: 4,
        timeBeforeScanTest: 250
      });
      this.isActivated = true;
      this.activated$.next(true);
    }
  }
};
</script>

<style lang="scss" scoped>
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

::v-deep .dx-pager {
  justify-content: flex-start;
  color: $primary-dark;
  font-weight: 600;
  font-size: 1rem;
  .dx-pages {
    margin: auto;
  }
}
.slider.round:before {
  border-radius: 50%;
}
::v-deep
  .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)
  > td:not(.dx-focused) {
  cursor: pointer;
}
</style>
