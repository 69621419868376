import api from "./api.js";
class Insurance {
  constructor() {
    this.url = "/api/Insurances";
  }
  get searchStore() {
    return api.createSearch(this.url + "/search");
  }
  get headers() {
    const token = sessionStorage.getItem("token");
    return {
      Authorization: `Bearer ${encodeURIComponent(token)}`
    };
  }
  createSearch(url, labId) {
    return api.createSearch(this.url + url, undefined, { labId });
  }
  addInsurance(insurance) {
    return api.post(this.url, insurance);
  }
  updateInsurance(insurance) {
    return api.put(this.url, insurance);
  }
  getPolicies(caseId) {
    return api.get(this.url + "/Policies", { caseId });
  }
  updatePolicy(policy) {
    return api.put(this.url + "/Policies", policy);
  }
  addPolicy(policy) {
    return api.post(this.url + "/Policies", policy);
  }
  getEligibilities(id) {
    return api.get(this.url + "/Eligibility/" + id);
  }
  createEligbility(caseInsuranceId) {
    return api.post(this.url + "/Eligibility/" + caseInsuranceId);
  }
}
export default new Insurance();
