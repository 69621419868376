<template>
  <dx-data-grid
    :show-row-lines="bool"
    :show-borders="bool"
    ref="grid"
    class="prop__grid"
    :data-source="value"
    :columns="computedColumns"
    :scrolling="scrolling"
    :editing="editing"
    :selection="selection"
    :allowColumnResizing="true"
    :noDataText="noDataText"
    :wordWrapEnabled="wordWrapEnabled"
    :rowDragging="rowDragging"
    @initialized="initializeGrid"
    :summary="gridSummary"
    @update:selectedRowKeys="forwardEvent('selectedRowKeys', $event)"
    :width="width"
    :cacheEnabled="false"
  >
    <template v-slot:actions="{ data }">
      <div class="d-flex align-items-center justify-content-center">
        <icon-button
          type="button"
          v-if="canEdit"
          :data-testid="`edit-${data.rowIndex}`"
          v-tooltip.left.start="'Edit item.'"
          @click="editItem(data)"
          class="btn text-primary mx-2 p-0"
          icon="pen-alt"
        />
        <icon-button
          type="button"
          v-if="canDelete"
          v-tooltip.right.start="'Delete item.'"
          @click="removeItem(data)"
          class="mx-1 text-danger btn p-0"
          icon="trash-alt"
        />
      </div>
    </template>
    <template v-slot:expirationCell="{ data }">
      <div class="align-items-center">
        <ExpiryTemplate :expiry-date="data.value" />
      </div>
    </template>
  </dx-data-grid>
</template>

<script>
import { DxDataGrid } from "devextreme-vue/data-grid";
import ExpiryTemplate from "@/components/common/ExpiryTemplate.vue";
import IconButton from "./IconButton.vue";
export default {
  name: "DataTable",
  props: {
    value: {
      required: true,
      default() {
        return [];
      }
    },
    selection: {
      type: Object,
      default() {
        return null;
      }
    },
    wordWrapEnabled: {
      type: Boolean,
      default: true
    },
    columns: {
      required: true
    },
    rowDragging: {
      required: false
    },
    noDataText: {
      type: String,
      default() {
        return "No Data";
      }
    },
    apiRemove: {
      type: Boolean,
      default() {
        return false;
      }
    },
    gridSummary: {
      type: Object,
      default() {
        return null;
      }
    },
    canDelete: {
      default() {
        return true;
      }
    },
    canEdit: {
      default() {
        return true;
      }
    },
    scrolling: {
      default() {
        return {
          showScrollbar: "always",
          useNative: true
        };
      }
    },
    hasActions: {
      default() {
        return true;
      }
    },
    width: {
      default() {
        return "auto";
      }
    }
  },
  components: {
    DxDataGrid,
    ExpiryTemplate,
    IconButton
  },
  data: () => ({
    editing: {
      confirmDelete: false
    },
    bool: true
  }),
  computed: {
    computedColumns() {
      this.columns.forEach(item => {
        if (item.dataField === "expiryOn" || item.dataField === "expiresOn") {
          item.cellTemplate = "expirationCell";
        }
      });
      if (!this.hasActions) {
        return this.columns;
      }
      return [
        ...this.columns,
        {
          type: "buttons",
          caption: "Actions",
          cellTemplate: "actions",
          visible: this.canEdit || this.canDelete
        }
      ];
    }
  },
  methods: {
    initializeGrid(event) {
      this.$emit("initialized", event);
    },
    editItem(data) {
      this.$emit("edit", data);
    },
    removeItem(data) {
      this.$emit("remove", {
        ...data,
        deleteMethod: this.$refs.grid.instance.deleteRow
      });
      if (!this.apiRemove) {
        this.$refs.grid.instance.deleteRow(data.rowIndex);
      }
    },
    forwardEvent(name, event) {
      this.$emit(name, event);
    }
  }
};
</script>

<style lang="scss" scoped>
.prop__grid {
  width: 75%;
}
</style>
