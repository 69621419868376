<template>
  <div class="text-input">
    <label v-if="!noLabel" :for="label">
      <b v-html="label" />
    </label>
    <DxDateBox
      :type="type"
      :value="time"
      @value-changed="onChange"
      :isValid="isValid"
      :dateSerializationFormat="dateSerializationFormat"
      v-bind="$attrs"
      :min="minTime"
      ref="dateBox"
      :focusStateEnabled="focusStateEnabled"
    />

    <div v-if="(validator && validator.$error) || (validator && validator.$invalid)">
      <div
        class="validation-error"
        v-for="(key, index) in Object.keys(validator.$params)"
        :key="index"
      >
        <span class="error" v-if="!validator[key]">
          {{ validatorMsgMap[key] }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { validatorMsgMapBase } from "@/modules/helpers";
import DxDateBox from "devextreme-vue/date-box";
import moment from "moment-timezone";
export default {
  name: "Timepicker",
  components: { DxDateBox },
  props: {
    noLabel: {
      type: Boolean
    },
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    dateSerializationFormat: {
      type: String,
      default: "yyyy-MM-ddTHH:mm:ss"
    },
    // focus: {
    //   type: Boolean,
    //   default: false
    // },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      required: false
    },
    type: {
      type: String,
      default: "time"
    },
    customStyles: {
      type: Object
    },
    validatorMsgMap: {
      type: Object,
      default: () => {
        return validatorMsgMapBase;
      }
    },
    validator: {
      type: Object,
      default: null
    },
    required: {
      type: Boolean
    },
    min: {
      required: false
    },
    focusStateEnabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isFocused: false,
      isOpen: false,
      blurred: false,
      time: null
    };
  },
  mounted() {
    this.formatTime(this.value);
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        this.formatTime(nv);
      }
    }
  },
  computed: {
    isValid() {
      if (this.validator) {
        return !this.validator.$invalid || !this.validator.$error;
      }
      return true;
    },
    minTime() {
      if (this.min) {
        return this.utcformat(this.min);
      }
      return null;
    }
  },
  methods: {
    onChange(data) {
      if (data.previousValue || !this.value) {
        return this.$emit("input", this.utcformat(data.value));
      }
    },
    utcformat(d) {
      if (d) {
        return moment.tz(d, "UTC").format("YYYY-MM-DDTHH:mm:ss");
      }
      return "";
    },
    focus() {
      this.$refs.dateBox.focus();
    },
    formatTime(value) {
      if (value) {
        this.time = moment(value).format("YYYY-MM-DDTHH:mm:ss");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.time-picker {
  box-sizing: border-box;
  .picker {
    z-index: 10;
    position: absolute;
    //margin-top: 1px;
    height: 11em;
    border: 1px solid silver;
    background: white;
    width: 182px;
    .selected {
      background: #44bbdd;
    }
    .hours-header,
    .minutes-header {
      background: black;
      color: white;
      font-weight: bold;
    }
    .hours,
    .minutes,
    .ampm {
      border: none;
      height: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      vertical-align: top;
      display: inline-block;
      width: 60px;
      text-align: center;
      cursor: pointer;
      .minute:hover,
      .hour:hover {
        border: none;
        background: #d9d9d9;
      }
    }
    .minutes {
      width: 58px;
      margin-left: -4px;
      border-left: 1px solid #d9d9d9;
    }
  }
}
.vue__time-picker input.display-time.is--valid {
  border-color: #28a745 !important;
}
input {
  width: 28%;
  border: none;
  &:focus {
    outline: none;
  }
}
/* ::v-deep text-input {
  min-height: 40px;
} */
.focused {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  &.is-valid {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  }
  &.is-invalid {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }
}
</style>
