<template>
  <div class="border prostate-specimen-form p-1" :class="isSelectedSpecimen ? '' : 'disabled'">
    <div class="d-flex justify-content-between align-items-center">
      <h3>
        <span v-if="specimen.specimenOrder">
          <b>{{ specimen.specimenOrder }}:</b> {{ outputSpecimen.site }}</span
        >
      </h3>
      <h4>{{ diagnosisSummary.displayText }}</h4>
    </div>
    <div class="row my-0">
      <NumberInput
        class="col-6 pr-1"
        label="<u>L</u>ength"
        ref="prostateLength"
        v-model="outputSpecimen.prostateLength"
        :disabled="!isSelectedSpecimen"
      />
      <NumberInput
        class="col-6 pl-1"
        label="<u>P</u>ieces"
        ref="prostatePieces"
        v-model="outputSpecimen.prostatePieces"
        :disabled="!isSelectedSpecimen"
      />
    </div>
    <div class="row my-0">
      <TextInput
        class="col-6 pr-1"
        :label="windowWidth < 1500 ? '<u>G</u>leason' : '<u>G</u>leason Score'"
        ref="gleasonScore"
        v-model="outputSpecimen.gleasonScore"
        :disabled="!isSelectedSpecimen || !isMalignant"
      />
      <NumberInput
        class="col-6 pl-1"
        :label="windowWidth < 1200 ? '<u>T</u>umor' : '<u>T</u>umor Size'"
        ref="tumorSize"
        v-model="outputSpecimen.tumorSize"
        :disabled="!isSelectedSpecimen || !isMalignant"
      />
    </div>
    <div class="d-flex justify-content-end">
      <b :class="percentageClass">{{ tumorPercentage }}</b>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import NumberInput from "./common/NumberInput.vue";
import TextInput from "./common/TextInput.vue";
import { roundDecimals } from "../modules/helpers";

export default {
  components: {
    NumberInput,
    TextInput
  },
  props: ["specimen", "usedMacros", "windowWidth", "selectedSpecimenOrder"],
  data() {
    return {
      outputSpecimen: {}
    };
  },
  watch: {
    outputSpecimen: {
      immediate: true,
      deep: true,
      handler(nv) {
        this.handleUpdateOutputSpecimen(nv);
      }
    },
    isMalignant: {
      immediate: true,
      handler(nv) {
        this.$emit("setMalignance", { index: this.specimen.index, value: nv });
      }
    },
    selectedSpecimenOrder: {
      immediate: true,
      handler(nv) {
        if (nv === this.specimen.specimenOrder) {
          this.$nextTick(() => {
            if (this.tumorPercentage && !/\d/.test(this.tumorPercentage)) {
              this.$refs.tumorSize.focus();
            } else {
              this.$refs.prostateLength.focus();
            }
          });
        }
      }
    }
  },
  computed: {
    ...mapState({
      currentSpecimen: state => state.accessionStore.currentSpecimen,
      diagnosisSummaries: state => state.dropdowns.diagnosisSummaries
    }),
    isSelectedSpecimen() {
      return this.specimen.specimenOrder === this.selectedSpecimenOrder;
    },
    resultsMacroText() {
      if (this.specimen.resultsMacros.length) {
        return this.specimen.resultsMacros[0].displayName.toUpperCase();
      }
      return "";
    },
    diagnosisSummary() {
      for (const macro of this.macroDetails) {
        if (macro?.diagnosisSummaryId) {
          const diagnosisSummary = this.diagnosisSummaries.find(
            e => e.id === macro.diagnosisSummaryId
          );
          return diagnosisSummary;
        }
      }
      return {};
    },
    isMalignant() {
      if (this.diagnosisSummary?.displayText?.toLowerCase() === "malignant") {
        return true;
      }
      return false;
    },
    tumorPercentage() {
      if (this.outputSpecimen.prostateLength && this.outputSpecimen.tumorSize) {
        const fullPercentage =
          (this.outputSpecimen.tumorSize / this.outputSpecimen.prostateLength) * 100;
        if (fullPercentage > 100) {
          return "Tumor Size must be less than Length.";
        }
        const returnPercentage = roundDecimals(fullPercentage, 3);
        return returnPercentage + "%";
      }
      return "";
    },
    percentageClass() {
      if (this.tumorPercentage && !/\d/.test(this.tumorPercentage)) {
        return "text-danger";
      }
      return "";
    },
    macroDetails() {
      const specimen =
        this.currentSpecimen?.id === this.specimen.id ? this.currentSpecimen : this.specimen;
      return specimen.resultsMacros.map(e => this.usedMacros.find(f => e.id === f.macroId));
    }
  },
  mounted() {
    if (this.isSelectedSpecimen) {
      this.$refs.prostateLength.focus();
    }
    const { id, specimenOrder, prostateLength, prostatePieces, gleasonScore, tumorSize, site } =
      this.currentSpecimen.id === this.specimen.id ? this.currentSpecimen : this.specimen;
    this.outputSpecimen = {
      id,
      specimenOrder,
      prostateLength,
      prostatePieces,
      gleasonScore,
      tumorSize,
      site
    };
  },
  methods: {
    handleUpdateOutputSpecimen(specimen) {
      if (specimen?.id) {
        this.$emit("updateSpecimen", specimen);
      }
    },
    // This is called from parent component
    handleShortKey(key) {
      let ref = "";
      switch (key) {
        case "l":
          ref = "prostateLength";
          break;
        case "p":
          ref = "prostatePieces";
          break;
        case "g":
          if (this.isMalignant) {
            ref = "gleasonScore";
          }
          break;
        case "t":
          if (this.isMalignant) {
            ref = "tumorSize";
          }
          break;
      }
      if (ref) {
        this.$refs[ref].focus();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.prostate-specimen-form {
  height: 100%;
}
.disabled {
  background-color: $gray;
}
</style>
