<template>
  <div class="custom-control custom-checkbox">
    <!-- @keydown.space="$emit('input', !value)" -->
    <input
      type="checkbox"
      class="custom-control-input"
      :id="id"
      :name="name"
      @input="$emit('input', $event.target.checked)"
      :checked="value"
      :disabled="disabled"
    />
    <label class="custom-control-label" :for="id">
      <slot name="label">
        <b v-html="label"></b>
      </slot>
    </label>
  </div>
</template>

<script>
export default {
  name: "Checkbox",
  props: ["label", "value", "id", "name", "disabled", "nolabel"]
};
</script>
