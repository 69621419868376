<template>
  <div>
    <h2>Please enter Collected On date for the case:</h2>
    <date-picker
      ref="collectedOn"
      label="Collected"
      :min="minCollected"
      :max="AllowReceivedBeforeCollected ? maxFutureDays : selectedCaseDetails.receivedOn"
      id="collectedOn"
      dateSerializationFormat="yyyy-MM-dd"
      v-model="collectedOnDate"
      :validator="$v.collectedOnDate"
      name="collectedOn"
    />
    <div class="d-flex justify-content-end">
      <button class="btn btn-danger mr-1" @click.prevent="handleCancel">Cancel</button
      ><button
        @click="handleSubmit"
        class="btn btn-primary"
        v-shortkey="['enter']"
        @shortkey.prevent="handleSubmit"
        :disabled="$v.$invalid"
      >
        Go
      </button>
    </div>
  </div>
</template>

<script>
import { MaxDiffCollectedAndReceived } from "@/modules/helpers";
import { mapState } from "vuex";
import DatePicker from "./common/DatePicker.vue";
import moment from "moment";
import { DateFormats } from "@/modules/enums";
import { required } from "vuelidate/lib/validators";
import { CasesApi } from "@/services";
export default {
  components: { DatePicker },
  props: ["caseId", "caseDetailsProp"],
  data() {
    const today = moment(new Date()).format("yyyy-MM-DD");
    return {
      collectedOnDate: null,
      today,
      caseFromProps: {}
    };
  },
  validations() {
    return {
      collectedOnDate: {
        required,
        MaxDiffCollectedAndReceived: value =>
          value
            ? MaxDiffCollectedAndReceived(
                value,
                this.MaxDiffCollectedAndReceivedDates,
                this.selectedCaseDetails.receivedOn
              )
            : true,
        CollectedAfterDob: value => {
          if (value && this.selectedCaseDetails.patientDOB) {
            return moment(value, DateFormats).isSameOrAfter(
              moment(this.selectedCaseDetails.patientDOB)
            );
          }
          return true;
        }
      }
    };
  },
  computed: {
    ...mapState({
      caseDetails: state => state.accessionStore.caseDetails,
      AllowReceivedBeforeCollected: state => state.labSettings.AllowReceivedBeforeCollected,
      MaxDiffCollectedAndReceivedDates: state => state.labSettings.MaxDiffCollectedAndReceivedDates,
      DefaultDateCollected: state => state.labSettings.DefaultDateCollected,
      MaxFutureDaysForReceivedDate: state => state.labSettings.MaxFutureDaysForReceivedDate
    }),
    minCollected() {
      return moment(this.receivedOn, DateFormats)
        .subtract(this.MaxDiffCollectedAndReceivedDates + 1, "days")
        .format("yyyy/MM/DD");
    },
    receivedOn() {
      if (this.selectedCaseDetails?.caseId) {
        return this.selectedCaseDetails.receivedOn;
      } else if (this.caseFromProps?.receivedOn) {
        return this.caseFromProps.receivedOn;
      } else {
        return null;
      }
    },
    maxFutureDays() {
      const today = new Date();
      if (this.MaxFutureDaysForReceivedDate) {
        const maxDay = new Date();
        maxDay.setDate(today.getDate() + this.MaxFutureDaysForReceivedDate + 1);
        return maxDay;
      }
      return today;
    },
    selectedCaseDetails() {
      return this.caseDetailsProp || this.caseDetails;
    }
  },
  mounted() {
    if (this.caseId) {
      CasesApi.getCaseById(this.caseId).then(res => (this.caseFromProps = res));
      this.setDefaultDate();
    } else {
      this.setDefaultDate();
    }
  },
  methods: {
    handleSubmit() {
      this.$emit("submit", this.collectedOnDate);
    },
    handleCancel() {
      this.$emit("cancel");
    },
    setDefaultDate() {
      if (this.DefaultDateCollected != undefined) {
        if (this.DefaultDateCollected === 0) {
          this.collectedOnDate = this.today;
        } else if (this.DefaultDateCollected === 1) {
          this.collectedOnDate = this.receivedOn;
        } else if (this.DefaultDateCollected === 2) {
          this.collectedOnDate = null;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
