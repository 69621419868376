<template>
  <div class="card client-header px-1" v-show="caseDetails.caseId">
    <div class="d-flex">
      <div class="avatar d-flex flex-column">
        <router-link
          :to="{ name: 'Home', params: { keepGrid: true } }"
          class="btn btn-link font-weight-bold d-flex"
          >&larr;Back
        </router-link>
        <img src="@/assets/placeholder_avatar.svg" alt="Patient avatar" class="align-self-center" />
      </div>
      <div class="card-body ml-3 p-1">
        <h4 class="card-title mb-0">
          {{ shortenAccessionNumber(caseDetails.caseNumber) }}
          |
          <span data-private="redact">{{
            caseDetails.patientFirstName + " " + caseDetails.patientLastName
          }}</span>
        </h4>
        <div class="card-text d-flex justify-content-between">
          <div class="d-flex flex-wrap">
            <p class="mr-4">
              <icon icon="phone" />
              <a v-show="caseHeader.phone" :href="'tel:' + caseHeader.phone">{{
                patientPhoneNumber
              }}</a>
            </p>
            <p v-show="caseDetails.patientAccountNumber" class="mr-4">
              <icon icon="id-badge" /> {{ caseDetails.patientAccountNumber }}
            </p>
            <p v-show="caseHeader.mrn" class="mr-4">
              <icon icon="laptop-medical" /> {{ caseHeader.mrn }}
            </p>
          </div>
          <ClientSlideButton v-if="specimensWithSlideImages.length" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { shortenAccessionNumber } from "@/modules/helpers";
import Icon from "../common/Icon.vue";
import ClientSlideButton from "../common/ClientSlideButton.vue";
export default {
  components: { Icon, ClientSlideButton },
  name: "ClientHeader",
  methods: { shortenAccessionNumber },
  computed: {
    ...mapState({
      caseHeader: state => state.accessionStore.caseHeader,
      caseDetails: state => state.accessionStore.caseDetails,
      slideImages: state => state.accessionStore.slideImages
    }),
    patientDOB() {
      return moment(this.caseHeader.dob).format("DD/MM/YYYY");
    },
    patientPhoneNumber() {
      if (this.caseHeader.phone) {
        const rawPhone = this.caseHeader.phone
          .replace(/[^\d]/g, "")
          .replace(/(\d{3})(\d{3})(\d+)/, "($1)-$2-$3");
        return rawPhone;
      }
      return "";
    },
    specimensWithSlideImages() {
      return this.slideImages.filter(
        e => e.slideImageUrls?.length || e.specimenSlideImageUrl?.length
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.avatar {
  img {
    border-radius: 50%;
    height: 50px;
    width: 50px;
  }
}
p {
  margin-bottom: 2px;
}
.client-header {
  background: $primary-light;
}
@media screen and (min-width: 1024px) {
  .card-text {
    font-size: 1.5rem;
    color: $text-primary;
  }
}
</style>
