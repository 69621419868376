<template>
  <div>
    <!-- <button @click="isShowingResults = !isShowingResults">toggle</button> -->
    <div v-show="isShowingResults">
      <case-free-text-search-grid :showBackButton="true" @back="isShowingResults = false" />
    </div>
    <div v-show="!isShowingResults">
      <search-fields @submitSearch="isShowingResults = true" />
    </div>
  </div>
</template>

<script>
import CaseFreeTextSearchGrid from "@/components/CaseFreeTextSearchGrid.vue";
import SearchFields from "@/components/SearchFields.vue";

export default {
  components: {
    CaseFreeTextSearchGrid,
    SearchFields
  },
  data() {
    return {
      isShowingResults: false
    };
  }
};
</script>

<style lang="scss" scoped></style>
