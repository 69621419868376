import api from "./api.js";
class Specimen {
  constructor() {
    this.url = "/api/Specimens";
  }
  getSpecimen(caseId) {
    return api.get(this.url, { caseId: caseId });
  }
  createSpecimen(specimen) {
    return api.post(this.url, specimen);
  }
  updateSpecimen(specimen) {
    return api.put(this.url, specimen);
  }
  deleteSpecimen(specId) {
    return api.delete(this.url + `/RemoveSpecimen?specId=${specId}`);
  }
  recalculateAutoBilling(specId) {
    return api.post(this.url + `/${specId}/AutoBilling`);
  }
  increaseCassetteQty(specId, qty) {
    return api.put(this.url + `/${specId}/CassetteQuantity`, { qty });
  }
  getSlideImages(specId) {
    return api.get(this.url + `/${specId}/SlideImages`);
  }
}
export default new Specimen();
