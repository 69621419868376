<template>
  <div>
    <form
      class="image-form p-2"
      @submit.prevent="handleSubmit"
      v-shortkey="saveShortkey"
      @shortkey="handleSubmit"
    >
      <div class="d-flex justify-content-between wrapper">
        <div class="col">
          <select-input
            label="Specimen"
            :items="specimens"
            displayExpr="specimenOrder"
            name="specimenId"
            v-model="imageData.specimenId"
          />
          <select-input
            v-focus
            label="Code"
            :items="imageTypes"
            :validator="$v.imageData.imageTypeId"
            name="imageTypeId"
            v-model="imageData.imageTypeId"
          />
          <select-input
            name="printOnReport"
            label="Print On Report"
            id="printOnReport"
            :items="booleanOptions"
            v-model="imageData.printOnReport"
          />
          <tag-input
            label="Tags"
            :items="tagOptions"
            id="tagIds"
            name="tagIds"
            class="tags-input"
            v-model="imageData.tagIds"
          />
          <text-input
            label="Keywords"
            class="keywords-input"
            name="keywords"
            v-model="imageData.keywords"
          />
        </div>
        <div class="col align-self-center d-flex flex-column">
          <modal :status="isImageOpen" @close="handleCloseImage">
            <div class="image-modal">
              <img class="center" v-if="!isPdf" :src="imageData.imagePath" />
              <object class="center" :data="imageData.imagePath" v-else />
            </div>
          </modal>
          <loader v-if="isLoading" class="mx-auto" />
          <div v-else class="h-100">
            <img
              @click="isImageOpen = !isImageOpen"
              class="image-preview"
              v-if="!isPdf"
              :src="imageData.imagePath"
            />
            <object
              @click="isImageOpen = !isImageOpen"
              :data="imageData.imagePath"
              class="image-preview"
              v-else
            />
          </div>

          <icon-button
            class="btn btn-primary mx-auto mt-2"
            @click="isImageOpen = !isImageOpen"
            icon="eye"
            >Preview</icon-button
          >
        </div>
      </div>
      <text-area-input
        class="comments-input"
        ref="comments"
        name="comment"
        label="Comments"
        v-model="imageData.comment"
        :generalMacrosEnabled="true"
        :caseId="caseId"
      />
      <div class="d-flex justify-content-end">
        <loader size="small" v-show="isLoading || isUploading" />
        <button
          type="submit"
          :disabled="$v.$invalid || isUploading"
          class="btn-primary btn save-btn ml-2"
          v-if="permissions.CaseImageCreateEdit"
        >
          Submit
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { AuditLogApi, DropdownApi, ImagesApi, MacrosApi } from "@/services";
import SelectInput from "@/components/common/SelectInput.vue";
import TextInput from "./common/TextInput.vue";
import { mapGetters, mapState } from "vuex";
import TagInput from "./common/TagInput.vue";
import Loader from "./common/Loader.vue";
import required from "vuelidate/lib/validators/required";
import { altKey, booleanLookup, createLogComment, createLogItem } from "@/modules/helpers";
import Modal from "./common/Modal.vue";
import IconButton from "./common/IconButton.vue";
import { cloneDeep } from "lodash";
import { AuditLogItems, MacroTypeEnum } from "@/modules/enums";
import { handleErrors } from "@/modules/handleErrors";

export default {
  name: "CaseEditImageform",
  components: {
    SelectInput,
    TextAreaInput: () => import("@/components/TextAreaInput.vue"),
    TextInput,
    TagInput,
    Loader,
    Modal,
    IconButton
  },
  props: {
    caseId: Number,
    specimenId: Number,
    imageId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      imageData: {
        specimenId: null,
        imageTypeId: null,
        imagePath: "",
        keywords: "",
        printOnReport: false,
        tagIds: [],
        comment: ""
      },
      originalImage: {},
      isImageOpen: false,
      fileType: "image/jpeg",
      imageTypes: [],
      isLoading: false,
      isUploading: false,
      tagOptions: [],
      booleanOptions: booleanLookup.dataSource,
      saveShortkey: altKey("s"),
      isMacroOpen: false,
      generalMacros: [],
      positionToPlaceCursor: 0
    };
  },
  computed: {
    ...mapState({
      specimens: state => state.accessionStore.specimens,
      defaultImageType: state => state.applicationSettings.defaultImageType,
      imagePrintOnReport: state => state.applicationSettings.imagePrintOnReport,
      currentUser: state => state.currentUser
    }),
    ...mapGetters("accessionStore", ["isReported", "isCaseEditable"]),
    ...mapGetters(["permissions"]),
    isPdf() {
      return /pdf/i.test(this.fileType);
    }
  },
  created() {
    if (this.imageId) {
      this.loadImageData(this.imageId);
    }
    DropdownApi.getTags().then(res => {
      this.tagOptions = res;
    });
    ImagesApi.getImageTypes().then(imageTypes => {
      this.imageTypes = imageTypes || [];
    });
    MacrosApi.getMacrosByUserAndType({
      userId: this.currentUser.id,
      macroTypeId: MacroTypeEnum.General,
      loadOptions: {}
    }).then(res => {
      this.generalMacros = res.data || [];
    });
  },
  watch: {
    specimenId: {
      immediate: true,
      handler(nv) {
        if (nv) {
          this.imageData.specimenId = nv;
        }
      }
    }
  },
  validations() {
    return {
      imageData: {
        imageTypeId: { required }
      }
    };
  },
  methods: {
    closeOnOutsideClick(e) {
      return e.target === document.getElementById("someElement");
    },
    async loadImageData(imageId) {
      try {
        this.isLoading = true;
        this.isCameraOpen = false;
        const res = await ImagesApi.getImages(imageId);
        this.imageData = res || {};
        this.originalImage = cloneDeep(res || {});
        const imageContent = await ImagesApi.getImageContent(imageId);
        this.fileType = imageContent.type;
        const url = URL.createObjectURL(imageContent);
        this.imageData.imagePath = url;
      } catch (error) {
        handleErrors(error);
      } finally {
        this.isLoading = false;
      }
    },
    handleCloseImage() {
      this.isImageOpen = false;
    },
    async handleSubmit() {
      if (!this.imageData.imagePath) {
        await window.alert("Please upload or capture an image!");
        return;
      }
      this.$v.$touch();
      if (this.$v.$invalid) {
        await window.notify("Please verify your input.", "warning");
        return;
      }
      this.isUploading = true;
      try {
        const { imageTypeId, tagIds, specimenId, keywords, printOnReport, comment } =
          this.imageData;
        const formData = new FormData();
        const file = await fetch(this.imageData.imagePath).then(r => r.blob());
        formData.append(`file`, file);

        formData.append(
          "jsonPayload",
          JSON.stringify({
            imageTypeId,
            tagIds,
            specimenId,
            keywords,
            printOnReport,
            comment,
            caseId: this.caseId,
            id: this.imageId
          })
        );
        await this.$store.dispatch("accessionStore/updateImage", formData);
        const logItem = createLogItem(this.caseDetails, AuditLogItems.ChangeAccession);
        logItem.comments = createLogComment(this.originalImage, this.imageData);
        AuditLogApi.insertLogMessage(logItem);
        await window.notify("Image updated successfully!");
        this.$emit("submit");
      } catch (error) {
        handleErrors(error);
      } finally {
        this.isUploading = false;
      }
    },

    getBLOBFileHeader(blob) {
      return new Promise(resolve => {
        const fileReader = new FileReader();
        fileReader.onloadend = function (e) {
          const arr = new Uint8Array(e.target.result).subarray(0, 4);
          let header = "";
          for (let i = 0; i < arr.length; i++) {
            header += arr[i].toString(16);
          }
          resolve(header);
        };
        fileReader.readAsArrayBuffer(blob);
      });
    },
    // Add more from http://en.wikipedia.org/wiki/List_of_file_signatures
    mimeType(headerString) {
      let type = "unknown";
      switch (headerString) {
        case "89504e47":
          type = "image/png";
          break;
        case "47494638":
          type = "image/gif";
          break;
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
          type = "image/jpeg";
          break;
        case "25504446":
          type = "application/pdf";
          break;
        default:
          type = "unknown";
          break;
      }
      return type;
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .image-preview {
  width: 100%;
  height: 100%;
  img {
    height: auto;
  }
  border: 1px solid #ddd; /* Gray border */
  border-radius: 4px; /* Rounded border */
  padding: 5px; /* Some padding */
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
    opacity: 0.7;
  }
}
::v-deep .image-modal {
  height: fit-content;
  width: 50vw;
  img {
    width: 100%;
    height: auto;
  }
  object {
    height: 75vh;
    width: 100%;
  }
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  height: auto;
}

.wrapper {
  & > div {
    width: 50%;
  }
}

.case-image-form-ql .wrapper {
  & > div {
    width: initial;
  }
}
</style>
