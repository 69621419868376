<template>
  <div class="wrapper p-1">
    <div class="d-flex align-items-center justify-content-end">
      <button class="btn btn-outline-primary" @click="handleClearGridFilters">Clear Filters</button>
    </div>
    <dx-grid-with-search
      class="orderGrid"
      title="Orders"
      refkey="Order Grid"
      ref="orderGrid"
      :data-source="getPendingProcedures"
      column-resizing-mode="widget"
      :gridName="'ordersGrid'"
      noDataText="No orders found."
      :selection="selection"
      @initialized="initializeGrid"
      :onCellPrepared="redactLogRocket"
      v-stream:content-ready="contentReady$"
      :allow-column-resizing="true"
      :columns="columns"
      :pager="pager"
      :paging="paging"
      :toolbar="toolbar"
    >
      <template v-slot:loader>
        <loader size="small"> </loader>
      </template>
      <template v-slot:actions="{ data }">
        <icon-button
          class="pointer p-0 mx-2"
          :class="{
            'text-success': !isProcedureComplete(data.data),
            'text-danger': isProcedureComplete(data.data)
          }"
          v-tooltip.left="
            `Click mark order ${isProcedureComplete(data.data) ? 'incomplete' : 'complete'}.`
          "
          :icon="isProcedureComplete(data.data) ? 'times' : 'check'"
          @click="toggleProcedure(data.data)"
        />
      </template>

      <template class="icon-button-group d-flex" v-slot:accessionNumber="{ data }">
        <router-link
          v-if="permissions.CassetteProcedureView"
          :to="{
            name: 'CaseView',
            params: {
              caseId: data.data.caseId
            }
          }"
          >{{ accessionNumber(data) }}</router-link
        >
        <span v-else>{{ accessionNumber(data) }}</span>
      </template>
    </dx-grid-with-search>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import DxGridWithSearch from "./common/DxGridWithSearch.vue";
import { AuditLogApi, CasesApi, DropdownApi, ProceduresApi } from "@/services/index";
import IconButton from "@/components/common/IconButton.vue";
import {
  createLogItem,
  createQueryArray,
  filterAccessionNumber,
  filterCellUTC,
  formatDatetimeCell,
  fromLetters,
  toLetters
} from "@/modules/helpers";
import { map, take, tap } from "rxjs/operators";
import handleErrors from "@/mixins/handleErrors";
import Loader from "./common/Loader.vue";
import api from "@/services/api";
import { scanCaseBarcode } from "@/modules/scanCaseBarcode";
import ScannerDetection from "@/modules/scanner";
import CustomStore from "devextreme/data/custom_store";
import { format } from "date-fns";

export default {
  name: "Orders",
  components: {
    DxGridWithSearch,
    IconButton,
    Loader
  },
  mixins: [handleErrors],
  data() {
    return {
      grid: {},
      isLoading: false,
      dataSource: [],
      scrolling: {
        showScrollbar: "always",
        useNative: true,
        rowRenderingMode: "virtual",
        columnRenderingMode: "virtual"
      },
      selection: {
        mode: "multiple",
        allowSelectAll: true,
        showCheckBoxesMode: "always",
        selectAllMode: "page"
      },
      remoteOperations: {
        filtering: true,
        sorting: true,
        paging: true
      },
      grouping: {
        allowCollapsing: true
      },
      pager: {
        allowedPageSizes: [10, 15, 20, 25, 50],
        showInfo: true,
        infoText: "Page {0} of {1} ({2} Orders)",
        showPageSizeSelector: true,
        visible: true,
        displayMode: "full"
      },
      paging: {
        enabled: true
      },
      labPathologists: [],
      searchType: false,
      billingCycles: [],
      priorityStore: new CustomStore({
        key: ["id", "displayName"],
        load() {
          return DropdownApi.getPriorities();
        }
      })
    };
  },
  created() {
    DropdownApi.searchPathologists.load().then(res => {
      this.labPathologists = res;
    });
    DropdownApi.getBillingCycles().then(res => {
      this.billingCycles = res || [];
    });
  },
  activated() {
    if (this.grid.refresh) {
      this.grid.refresh(true);
    }
  },
  domStreams: ["contentReady$"],
  subscriptions() {
    return {
      applyFilters$: this.contentReady$.pipe(
        map(({ event }) => event.msg),
        take(1),
        tap(({ component }) => {
          if (this.isPathologist) {
            component.columnOption("isPathologistComplete", "filterValue", false);
            component.columnOption("isHistoComplete", "filterValue", true);
            component.columnOption("pathologistName", "filterValue", this.currentUser.id);
          } else {
            component.columnOption("isHistoComplete", "filterValue", false);
            component.columnOption("isPathologistComplete", "filterValue", null);
            component.columnOption("pathologistName", "filterValue", null);
          }
        })
      )
    };
  },
  mounted() {
    this.scanner = new ScannerDetection({
      onComplete: this.handleScanBarcode,
      stopPropogation: true,
      minLength: 4
    });
  },
  beforeDestroy() {
    if (this.scanner?.stopScanning) {
      this.scanner.stopScanning();
    }
  },
  computed: {
    ...mapState(["currentUser", "currentLab"]),
    ...mapState({
      casesSearchHeader: state => state.applicationSettings.casesSearchHeader,
      caseStatuses: state => state.caseStatuses,
      defaultOrdersReportPrinter: state => state.applicationSettings.defaultOrdersReportPrinter
    }),
    ...mapGetters(["permissions"]),

    isPathologist() {
      return this.currentUser.isPathologist;
    },
    getPendingProcedures() {
      const { procedures, casePrefixGroups, holdCodes, pathologists, alertFilter, tags } =
        this.casesSearchHeader;
      const url = `/api/Labs/PendingProcedures/search?${
        pathologists?.length ? "&" + createQueryArray(pathologists, "pathologists") : ""
      }${
        casePrefixGroups?.length ? "&" + createQueryArray(casePrefixGroups, "casePrefixGroup") : ""
      }${holdCodes?.length ? "&" + createQueryArray(holdCodes, "holdCodes") : ""}${
        procedures?.length ? "&" + createQueryArray(procedures, "procedures") : ""
      }${tags?.length ? "&" + createQueryArray(tags, "tags") : ""}${
        alertFilter ? "&alertFilter=" + alertFilter : ""
      }`;
      return api.createSearch(url, "id");
    },
    toolbar() {
      return {
        items: [
          {
            template: "loader",
            visible: this.isLoading
          },
          {
            widget: "dxButton",
            visible: this.permissions.CaseView,
            options: {
              icon: "fa fa-file-excel",
              onClick: this.exportToExcel,
              hint: "Click to export as excel file",
              elementAttr: {
                class: "icon-color"
              },
              text: "Export to Excel"
            },
            locateInMenu: "auto",
            showText: "inMenu"
          },

          {
            widget: "dxButton",
            visible: this.permissions.CaseView,
            options: {
              icon: "fa fa-print",
              onClick: this.printProcedures,
              hint: "Click to print selected rows",
              elementAttr: {
                class: "icon-color"
              },
              text: "Print"
            },
            locateInMenu: "auto",
            showText: "inMenu"
          },
          {
            widget: "dxButton",
            visible: this.permissions.CaseView,
            options: {
              icon: "fa fa-check",
              onClick: () => this.markCompleteIncomplete("complete"),
              hint: "Click to complete order.",
              elementAttr: {
                class: "icon-color"
              },
              text: "Complete"
            },
            locateInMenu: "auto",
            showText: "inMenu"
          },
          {
            widget: "dxButton",
            visible: this.permissions.CaseView,
            options: {
              icon: "fa fa-times-circle",
              onClick: () => this.markCompleteIncomplete("incomplete"),
              hint: "Click to Incomplete order.",
              elementAttr: {
                class: "icon-color"
              },
              text: "Incomplete"
            },
            locateInMenu: "auto",
            showText: "inMenu"
          }
        ]
      };
    },
    columns() {
      return [
        {
          dataField: "specimenNum",
          caption: "Specimen",
          dataType: "string"
        },
        {
          dataField: "caseNumber",
          caption: "Accession Number",
          cellTemplate: "accessionNumber",
          dataType: "string",
          calculateFilterExpression: filterAccessionNumber(
            "caseNumber",
            this.$store.state.labSettings.AccessionNumberingType
          )
        },
        {
          dataField: "caseStatusId",
          dataType: "string",
          allowFiltering: true,
          showInColumnChooser: false,
          defaultFilterValues: [],
          lookup: {
            dataSource: this.caseStatuses.map((e, i) => ({ displayName: e, id: i })),
            displayExpr: "displayName",
            valueExpr: "id"
          },
          caption: "Status",
          allowSearch: false
        },
        {
          dataField: "priority",
          dataType: "string",
          allowSearch: false,
          allowSorting: true,
          calculateSortValue: "prioritySort",
          lookup: {
            dataSource: this.priorityStore,
            displayExpr: "displayName",
            valueExpr: "displayName"
          }
        },
        {
          dataField: "protocol",
          allowFiltering: true,
          dataType: "string"
        },
        {
          dataField: "procedureName",
          caption: "Order Name",
          dataType: "string"
        },
        {
          dataField: "patientName",
          caption: "Patient Name",
          dataType: "string"
        },
        {
          dataField: "patientDateOfBirth",
          caption: "DOB",
          dataType: "date"
        },
        {
          dataField: "cassetteNum",
          caption: "Block",
          dataType: "string",
          calculateCellValue: data => this.calculateBlockNum(data),
          calculateFilterExpression: searchText => {
            return ["cassetteNum", "=", fromLetters(searchText.toUpperCase())];
          }
        },

        {
          dataField: "isCaseHold",
          caption: "On Hold",
          dataType: "boolean"
        },
        {
          dataField: "caseReceivedOn",
          dataType: "date",
          caption: "Received"
        },
        {
          dataField: "providerName",
          caption: "Provider Name",
          dataType: "string"
        },
        {
          dataField: "detail",
          caption: "Notes to Histology",
          dataType: "string",
          width: "170px"
        },
        {
          dataField: "pathologistName",
          caption: "Pathologist",
          filterValue: this.isPathologist ? this.currentUser.id : undefined,
          allowHeaderFiltering: true,
          allowFiltering: true,
          dataType: "string",
          lookup: {
            dataSource: this.labPathologists,
            valueExpr: "userId",
            displayExpr: "displayName"
          }
        },
        {
          dataField: "orderedOn",
          dataType: "datetime",
          caption: "Ordered On",
          sortIndex: 0,
          sortOrder: "desc",
          calculateCellValue(data) {
            if (data.orderedOn) {
              const date = formatDatetimeCell(data.orderedOn);
              return date;
            }
            return "";
          },
          calculateFilterExpression: filterCellUTC("orderedOn")
        },
        {
          dataField: "orderedBy",
          dataType: "string"
        },
        {
          dataField: "isHistoComplete",
          caption: "Histo Completed",
          dataType: "boolean",
          width: "100px",
          lookup: {
            dataSource: [
              { id: true, displayName: "Yes" },
              { id: false, displayName: "No" }
            ],
            valueExpr: "id",
            displayExpr: "displayName"
          },
          filterValue: this.isPathologist
        },
        { dataField: "histologyCompletedBy", caption: "Histo Complete By", dataType: "string" },
        {
          dataField: "histologyCompletedOn",
          caption: "Histo Complete On",
          dataType: "datetime",
          calculateDisplayValue: data => {
            if (data.histologyCompletedOn) {
              const formattedDate = formatDatetimeCell(data.histologyCompletedOn);
              return format(formattedDate, "M/d/yyyy h:mm a");
            }
            return "";
          },
          calculateFilterExpression: filterCellUTC("histologyCompletedOn")
        },
        {
          dataField: "isPathologistComplete",
          caption: "Pathologist Completed",
          lookup: {
            dataSource: [
              { id: true, displayName: "Yes" },
              { id: false, displayName: "No" }
            ],
            valueExpr: "id",
            displayExpr: "displayName"
          },
          dataType: "boolean",
          filterValue: this.isPathologist ? !this.isPathologist : null
        },
        { dataField: "pathologistCompletedBy", caption: "Path Complete By", dataType: "string" },
        {
          dataField: "pathologistCompletedOn",
          caption: "Path Complete On",
          dataType: "datetime",
          calculateDisplayValue: data => {
            if (data.pathologistCompletedOn) {
              const formattedDate = formatDatetimeCell(data.pathologistCompletedOn);
              return format(formattedDate, "M/d/yyyy h:mm a");
            }
            return "";
          },
          calculateFilterExpression: filterCellUTC("pathologistCompletedOn")
        },
        {
          dataField: "printedOn",
          dataType: "datetime",
          calculateCellValue(data) {
            if (data.printedOn) {
              const date = formatDatetimeCell(data.printedOn);
              return date;
            }
            return "";
          },
          calculateFilterExpression: filterCellUTC("printedOn")
        },
        {
          dataField: "qtySlides",
          caption: "No of Slides",
          dataType: "number",
          width: "100px"
        },
        {
          type: "icon-buttons",
          caption: "Actions",
          cellTemplate: "actions",
          visibleIndex: 0
        },
        {
          dataField: "billingCycle",
          caption: "Billing Cycle",
          dataType: "string",
          lookup: {
            dataSource: this.billingCycles,
            displayExpr: "displayName",
            valueExpr: "id"
          }
        }
      ];
    }
  },
  methods: {
    initializeGrid({ component }) {
      this.grid = component;
    },
    accessionNumber({ data }) {
      return data?.caseNumber;
    },
    async printProcedures() {
      const procedureIds = this.grid.getSelectedRowKeys();
      if (procedureIds.length === 0) {
        return window.notify("No procedures selected to print.", "error");
      }
      if (!this.defaultOrdersReportPrinter) {
        window.notify(
          "No printer selected. Please select an Orders Report Printer in User Settings.",
          "error"
        );
        return;
      }
      try {
        this.isLoading = true;
        if (procedureIds.length > 0) {
          await ProceduresApi.printCassetteProcedures({
            cassetteProcedureIds: procedureIds,
            printerId: this.defaultOrdersReportPrinter || 0
          });
          window.notify("Print successful");
          this.grid.selectRows([], false);
        }
      } catch (error) {
        this.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    isProcedureComplete(procedure) {
      if (this.isPathologist) {
        return procedure.isPathologistComplete;
      }
      return procedure.isHistoComplete;
    },
    async toggleProcedure(procedure) {
      try {
        await ProceduresApi.markCompleteIncomplete({
          cassetteProcedureIds: [procedure.id],
          markComplete: !this.isProcedureComplete(procedure)
        });
        this.createAuditLogItem(
          procedure,
          this.isProcedureComplete(procedure) ? "incomplete" : "complete"
        );
        this.grid.refresh();
      } catch (error) {
        window.notify(`Server error completing procedure.`, "error");
      }
    },
    async markCompleteIncomplete(method, procedures = []) {
      if (procedures.length === 0) {
        procedures = this.grid.getSelectedRowsData();
      }
      if (procedures.length === 0) {
        window.notify("No procedures selected.", "error");
        return;
      }

      const procedureIds = procedures.map(v => {
        return v.id;
      });
      let objProcedure = {
        cassetteProcedureIds: procedureIds,
        markComplete: false
      };
      if (method === "complete") {
        objProcedure.markComplete = true;
      }
      if (method === "incomplete") {
        objProcedure.markComplete = false;
      }
      try {
        if (procedureIds.length > 0) {
          await ProceduresApi.markCompleteIncomplete(objProcedure);
          for (const procedure of procedures) {
            this.createAuditLogItem(procedure, method);
          }
          this.grid.refresh(true);
          this.grid.clearSelection();
        }
      } catch (error) {
        window.notify(`Server Error: Could not mark completed order.`, "error");
      }
    },
    exportToExcel() {
      const selectedRows = this.grid.getSelectedRowsData();
      if (selectedRows.length) {
        return this.grid.exportToExcel(true);
      }
      return this.grid.exportToExcel();
    },
    handleCaseClick(event) {
      if (event.columnIndex === 1) {
        if (event.data) {
          const caseId = event.data.caseId;
          this.$router.push({ name: "CaseView", params: { caseId } });
        }
      }
    },
    setFocus({ element }) {
      const searchPanel = element.querySelector('input[type="text"]');
      if (searchPanel) {
        searchPanel.focus();
      }
    },
    async createAuditLogItem(procedure, method) {
      const caseDetails = await CasesApi.getCaseById(procedure.caseId);
      const logItem = createLogItem(
        { ...caseDetails, patientAccountNumber: caseDetails.acctNumber },
        16,
        "Orders Grid"
      );
      logItem.comments = "Marked order as " + method + ": " + JSON.stringify(procedure);
      await AuditLogApi.insertLogMessage(logItem);
    },
    async handleScanBarcode(barcode) {
      this.devlog("Scanned barcode in Orders Grid", barcode);
      const modalOpen = await document.getElementsByClassName("modal-mask");
      if (modalOpen.length) {
        return;
      }
      const { caseNumber, specimenOrder, blockNum } = scanCaseBarcode(barcode);
      if (!caseNumber) {
        return;
      }
      this.grid.clearFilter();
      this.grid.searchByText(null);
      this.grid.columnOption("caseNumber", "filterValue", caseNumber);
      if (specimenOrder) {
        this.grid.columnOption("specimenNum", "filterValue", specimenOrder);
        if (blockNum) {
          this.grid.columnOption("cassetteNum", "filterValue", blockNum);
        }
      }
    },
    handleClearGridFilters() {
      this.grid.clearFilter("row");
      this.grid.clearFilter("header");
      this.grid.clearFilter("filterValue");
      this.grid.clearFilter("caseStatus");
      this.grid.clearFilter("search");
      this.grid.clearFilter("dataSource");
    },
    calculateBlockNum(data) {
      if (/[a-z]/i.test(data.specimenNum)) {
        return data.cassetteNum;
      }
      return toLetters(data.cassetteNum);
    }
  }
};
</script>

<style lang="scss" scoped>
.task-header {
  font-weight: 600;
  font-size: 0.8rem;
  color: black;
  text-align: left;
}
.dx-datagrid-search-panel {
  width: 300px !important;
}
.dx-header-filter {
  color: $primary !important;
}
.filled_circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0.2rem 0.5rem;
  &.yellow {
    color: yellow;
  }
  &.blue {
    color: blue;
  }
  &.red {
    color: red;
  }
  &.green {
    color: green;
  }
}
.dx-row {
  cursor: pointer !important;
}
.pointer {
  width: 25px;
}
::v-deep .dx-pager {
  justify-content: flex-start;
  color: $primary-dark;
  font-weight: 600;
  font-size: 1rem;
  .dx-pages {
    margin: auto;
  }
}
</style>
