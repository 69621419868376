import api from "./api.js";
class Cassette {
  constructor() {
    this.url = "/api/Cassettes";
  }
  markCassettesAsPrinted(cassetteIds) {
    // Takes in array of cassette IDs to mark as printed.
    return api.put(this.url + `/IsPrinted`, { CassetteIds: cassetteIds });
  }
}
export default new Cassette();
