import api from "./api.js";
class PowerBi {
  constructor() {
    this.url = "/api/PowerBI";
  }
  /**
   *
   * @param {object} uniqueKey	REPORT DTO
   */
  getReport(report) {
    return api.post(this.url + "/GetReport", report);
  }
  getAccessToken() {
    return api.get(this.url + "/AccessToken");
  }
  getPathReport(caseId) {
    return api.get(this.url + "/getpathreport?caseId=" + caseId);
  }
}
export default new PowerBi();
