<template>
  <form @submit.prevent="handleSubmit" v-shortkey="saveShortkey" @shortkey="handleSubmit">
    <div class="mb-2 d-flex align-items-end text-capitalize justify-content-between">
      <h3>{{ header }}</h3>
    </div>
    <div class="container my-2 p-2 bg-white popup">
      <SelectInput
        label="Provider"
        v-model="form.selectedContactId"
        :dataSource="availableProviders"
        :validator="$v.form.selectedContactId"
        searchExpr="displayName"
        valueExpr="contactId"
      />
      <SelectInput
        v-if="DistributionMethodsEnum.Print === method"
        :items="availablePrinters"
        v-model="form.targetId"
        :valueExpr="form.selectedContactId ? 'id' : 'printerId'"
        label="Available Printers"
        ref="availablePrinters"
      />
      <SelectInput
        v-else-if="form.selectedContactId"
        v-bind="targets"
        :validator="$v.form.targetId"
        v-model="form.targetId"
      />
      <!-- v-model="" -->
      <div v-if="DistributionMethodsEnum.Fax === method">
        <TextInput label="Recipient" :validator="$v.form.recipient" v-model="form.recipient" />
        <phone-input
          label="Fax Number"
          :validator="$v.form.phoneNumber"
          v-model="form.phoneNumber"
        />
        <checkbox class="mt-4" id="faxPriority" label="Prioritize" v-model="form.isHighPriority" />
      </div>
      <div v-if="DistributionMethodsEnum.Email === method">
        <TextInput label="Subject" v-model="form.subject" />
        <EmailsInput :validator="$v.form.to" v-model="form.to" name="to" label="To" />
        <EmailsInput :validator="$v.form.cc" v-model="form.cc" name="cc" label="CC" />

        <text-area-input label="Body" :resize="false" rows="5" v-model="form.body" />
      </div>
      <div v-if="DistributionMethodsEnum.HL7 === method && form.targetId">
        <TextInput label="Sending Application" v-model="form.hl7.sendingApp" :disabled="true" />
        <TextInput label="Sending Facility" v-model="form.hl7.sendingFac" :disabled="true" />
        <TextInput label="Receiving Application" v-model="form.hl7.receivingApp" :disabled="true" />
        <TextInput label="Receiving Facility" v-model="form.hl7.receivingFac" :disabled="true" />
      </div>
      <div v-if="DistributionMethodsEnum.FileDrop === method && form.targetId">
        <TextInput label="Network Path" v-model="form.networkPath" :disabled="true" />
        <TextInput
          label="File Naming Convention"
          v-model="form.fileNamingConvention"
          :disabled="true"
        />
      </div>
    </div>
    <div class="my-2 d-flex justify-content-end">
      <loader v-if="isLoading" size="small" class="mx-1" />
      <button @click="closeModal" type="button" class="btn btn-danger">Cancel</button>
      <button :disabled="isLoading" type="submit" class="btn btn-primary mx-2">Submit</button>
    </div>
  </form>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { CasesApi, PrintersApi, ProvidersApi } from "../services";
import PhoneInput from "./common/PhoneInput.vue";
import { catchError, filter, switchMap, tap } from "rxjs/operators";
import { helpers } from "vuelidate/lib/validators";
import { altKey, createLogItem, formatPhoneNumber, validatorMsgMapBase } from "../modules/helpers";
import TextAreaInput from "./TextAreaInput.vue";
import auditLog from "../services/AuditLog";
import EmailsInput from "./common/EmailsInput.vue";
import DataSource from "devextreme/data/data_source";
import Loader from "./common/Loader.vue";
import { DistributionMethodsEnum } from "@/modules/enums";
import TextInput from "@/components/common/TextInput.vue";
import SelectInput from "@/components/common/SelectInput.vue";
import Checkbox from "./common/Checkbox.vue";
import { sortBy } from "lodash";

export default {
  name: "DistributionPopup",
  components: { PhoneInput, TextAreaInput, EmailsInput, Loader, TextInput, SelectInput, Checkbox },
  props: {
    caseIds: {
      type: Array,
      default() {
        if (this.$route.params?.caseId) {
          return [this.$route.params.caseId];
        }
      }
    },
    method: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      DistributionMethodsEnum,
      isLoading: false,
      labPrinters: [],
      caseProviders: [],
      providers: new DataSource({
        store: ProvidersApi.searchContacts,
        sort: { selector: "displayName", desc: true }
      }),
      form: {
        searchValue: "",
        selectedContactId: null,
        selectedContact: null,
        inputAttr: {
          required: true
        },
        shouldTakePriority: false,
        recipient: "",
        cc: [],
        to: [],
        fileNamingConvention: "",
        networkPath: "",
        phoneNumber: "",
        hl7: {
          sendingApp: "",
          receivingApp: "",
          sendingFac: "",
          receivingFac: ""
        },
        targetId: null,
        dropdownOptions: { disabled: true },
        isHighPriority: false
      },
      saveShortkey: altKey("s")
    };
  },
  mounted() {
    if (this.caseIds?.length === 1) {
      this.isLoading = true;
      CasesApi.getCaseById(this.caseIds[0])
        .then(caseDetails => {
          if (caseDetails.contacts?.length) {
            const caseProviders = caseDetails.contacts.map(e => e.contact);
            this.caseProviders = caseProviders;
            if (!this.labSettings.PrintPopupFocusPrinter) {
              const primaryContact = caseDetails?.contacts?.find(contact => contact.isPrimary);
              if (primaryContact) {
                this.form.selectedContactId = primaryContact?.contactId;
                ProvidersApi.searchContacts.byKey(primaryContact.contactId).then(contact => {
                  if (DistributionMethodsEnum.Fax === this.method) {
                    if (contact.properties?.faxMachines?.phoneNumber) {
                      this.form.targetId = contact.properties.faxMachines.id;
                    }
                  }
                });
              }
            }
          }
          this.isLoading = false;
        })
        .catch(error => {
          console.error("Error loading case contacts.", error);
          this.isLoading = false;
          this.caseProviders = [];
        });
    }
    PrintersApi.refreshLabPrinters(this.LabPrintNodeAPIKey).then(() => {
      PrintersApi.getLabPrinters().then(res => {
        this.labPrinters = res || [];
      });
    });
    PrintersApi.getLabPrinters().then(res => {
      this.labPrinters = res || [];
    });
    if (this.labSettings.PrintPopupFocusPrinter) {
      this.$refs.availablePrinters.focus();
    }
  },
  created() {},
  validations() {
    return {
      form: {
        targetId: {
          required: vm => {
            return this.isTargetRequired ? helpers.req(vm) : true;
          }
        },
        selectedContactId: {
          required: vm => {
            return this.isTargetRequired ? helpers.req(vm) : true;
          }
        },
        to: {
          required: value => {
            return DistributionMethodsEnum.Email === this.method ? helpers.req(value) : true;
          }
        },
        recipient: {
          required: value => {
            return DistributionMethodsEnum.Fax === this.method ? helpers.req(value) : true;
          }
        },
        phoneNumber: {
          required: value => {
            return DistributionMethodsEnum.Fax === this.method ? helpers.req(value) : true;
          }
        }
      }
    };
  },
  computed: {
    ...mapState({
      macro: state => state.macro,
      currentLab: state => state.currentLab,
      distributionPopup: state => state.distributionPopup,
      currentUser: state => state.currentUser,
      labSettings: state => state.labSettings,
      caseDetails: state => state.accessionStore.caseDetails,
      LabPrintNodeAPIKey: state => state.labSettings.LabPrintNodeAPIKey
    }),
    ...mapGetters(["permissions"]),

    availablePrinters() {
      if (this.form.selectedContactId) {
        return [
          { displayName: "Contact Printers", id: null },
          ...(sortBy(this.form.selectedContact?.properties?.printers, "displayName") ?? [])
        ];
      }
      return [
        { displayName: "Contact Printers", id: null },
        ...(sortBy(this.labPrinters, "displayName") ?? [])
      ];
    },
    availableProviders() {
      const showOnlyCaseProviders =
        this.caseIds.length === 1 &&
        this.caseProviders?.length &&
        !this.labSettings.PrintPopupFocusPrinter;
      const methodsForAll = [DistributionMethodsEnum.Email, DistributionMethodsEnum.Fax];
      if (showOnlyCaseProviders && !methodsForAll.includes(this.method)) {
        return new DataSource({
          store: this.caseProviders,
          sort: "displayName"
        });
      }
      return new DataSource({
        store: ProvidersApi.searchContacts,
        select: ["contactId", "displayName"],
        sort: "displayName"
      });
    },
    caseStore() {
      return CasesApi.searchStore;
    },
    header() {
      if (DistributionMethodsEnum.Fax === this.method) {
        return "Fax Distribution";
      }
      if (DistributionMethodsEnum.FileDrop === this.method) {
        return "PDF File Drop";
      }
      if (DistributionMethodsEnum.Email === this.method) {
        return "Email Distribution";
      }
      if (DistributionMethodsEnum.Print === this.method) {
        return "Print Distribution";
      }
      if (DistributionMethodsEnum.HL7 === this.method) {
        return "HL7 Distribution";
      }
      return "";
    },
    targets() {
      if (this.form.selectedContact) {
        if (DistributionMethodsEnum.Fax === this.method) {
          const items = this.form.selectedContact.properties.faxMachines || [];
          return {
            items,
            displayExpr: data => this.formatFax(data),
            label: "Available Faxes"
          };
        }
        if (DistributionMethodsEnum.Email === this.method) {
          return {
            items: this.form.selectedContact.properties.emails || [],
            displayExpr: data => data && `${data.recipient} (${data.address})`,
            label: "Available Emails"
          };
        }
        if (DistributionMethodsEnum.HL7 === this.method) {
          return {
            items: this.form.selectedContact.properties.hL7Configs || [],
            displayExpr: data =>
              data &&
              `${data.sendingApp} - ${data.sendingFac} - ${data.receivingApp} - ${data.receivingFac}`,
            label: "Available HL7s"
          };
        }
        if (DistributionMethodsEnum.Print === this.method) {
          return {
            dataSource: this.form.selectedContact.properties.printers || [],
            displayExpr: "name",
            label: "Available Printers"
          };
        }
        if (DistributionMethodsEnum.FileDrop === this.method) {
          return {
            dataSource: this.form.selectedContact.properties.pdfFileDrops || [],
            displayExpr: data => data && `${data.fileNamingConvention} -- ${data.networkPath}`,
            label: "Available Locations"
          };
        }
      }
      return {};
    },
    recipientTypes() {
      return {
        to: 1,
        cc: 2,
        bcc: 3
      };
    },
    isTargetRequired() {
      return [DistributionMethodsEnum.HL7, DistributionMethodsEnum.FileDrop].includes(this.method);
    },
    validatorMsgMap() {
      return validatorMsgMapBase;
    }
  },
  domStreams: ["submit$"],
  subscriptions() {
    const availableTargets$ = this.$watchAsObservable("form.selectedContactId", {
      immediate: true,
      deep: true
    }).pipe(
      filter(({ newValue }) => newValue),
      switchMap(async ({ newValue }) => {
        if (DistributionMethodsEnum.Print === this.method) {
          return ProvidersApi.searchContacts.byKey(newValue);
        }
        const contacts = await ProvidersApi.getContactsById([newValue]);
        return contacts[0];
      }),
      tap(contact => {
        this.form.selectedContact = contact;
        this.form.recipient = contact?.displayName;
        this.initializeTargetInput();
      }),
      catchError((e, s) => {
        return s;
      })
    );

    return {
      contact: availableTargets$
    };
  },
  watch: {
    "form.targetId"(nv) {
      if (nv === null) {
        this.form.recipient = "";
        this.form.phoneNumber = "";
      } else {
        this.handleLoadTarget(nv);
      }
    },
    "form.selectedContactId"(nv) {
      if (nv === null) {
        this.form.targetId = nv;
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    contactDisplayExpr(contact) {
      if (!contact) {
        return;
      }
      if (this.caseIds.length > 1) {
        return contact && contact?.provider?.id
          ? `${contact.provider.lastName}, ${contact.provider.firstName} (${contact.location.name})`
          : `Contact with id ${contact?.contactId ?? ""}`;
      }
      return contact && contact?.contact?.displayName;
    },
    async handleSubmit() {
      this.isLoading = true;
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.isLoading = false;
        throw "Please verify your input and try again.";
      }
      try {
        if (!this.form.targetId && !this.method === DistributionMethodsEnum.Email) {
          const confirm = await window.confirm(
            "You are potentially distributing to many targets. <br> Do you wish to continue?"
          );
          if (!confirm) {
            throw new Error("User cancelled distribution.");
          }
        }
        let payload = {
          caseIds: this.caseIds,
          method: this.method,
          copyNameMode: 8 // ForceBaseCopy
        };
        if (DistributionMethodsEnum.Fax === this.method) {
          payload.isHighPriority = this.form.priority;
          if (this.form.targetId) {
            payload.targetId = this.form.targetId;
          } else {
            payload.recipients = [
              {
                type: 1,
                address: this.form.phoneNumber,
                displayName: this.form.recipient
              }
            ];
            payload.phoneNumber = this.form.phoneNumber;
            payload.recipient = this.form.recipient;
          }
        }
        if (DistributionMethodsEnum.Email === this.method) {
          payload.recipients = [
            ...this.form.to.map(e => ({ ...e, type: 1 })),
            ...this.form.cc.map(e => ({ ...e, type: 2 }))
          ];
          payload.body = this.form.body;
          payload.subject = this.form.subject;
          if (payload.recipients.length < 1) {
            throw "No recipients found, please add emails.";
          }
        }
        if (DistributionMethodsEnum.Print === this.method) {
          payload.targetId = this.form.targetId;
        }
        if (DistributionMethodsEnum.HL7 === this.method) {
          payload.targetId = this.form.targetId;
        }
        if (DistributionMethodsEnum.FileDrop === this.method) {
          payload.targetId = this.form.targetId;
        }
        await CasesApi.distributeReports(payload);
        await this.handleSaveActivityLog(this.caseIds);
        window.notify("Success");
        this.$emit("submitted");
        this.closeModal();
      } catch (error) {
        if (error.response?.data) {
          if (error.response.data?.message) {
            return window.alert(error.response.data.message, "error");
          }
          const { errors } = error.response.data;
          if (errors) {
            const errorHTML = `<ul>
                <li>${Object.keys(errors).map(e => {
                  return `${e}: ${errors[e].join("<br>")}`;
                })}</li>
              </ul>`;
            return window.alert(errorHTML);
          }
          if (typeof error.response.data === "string") {
            return window.alert(error.response.data, "error");
          }
          return window.notify("An error occurred.", "error");
        }
        if (error.message) {
          return window.notify(error.message, "error");
        }
        window.notify("An error occurred", "error");
        console.log("An error occured cases", error);
      } finally {
        this.isLoading = false;
      }
    },
    handleSaveActivityLog(caseIds) {
      const targetPrinter = this.contact
        ? this.contact.properties?.printers?.find(e => {
            return e.id === this.form.targetId;
          })
        : this.availablePrinter$?.find(e => e.id === this.form.targetId);
      let itemTypes = {
        3: {
          item: 10,
          comments: `Printed using provider -- ${this.form.recipient} and printer ${
            targetPrinter?.name || "Contact Printers"
          }`
        },
        1: {
          item: 11,
          comments: `Sent to ${this.form.recipient} at ${this.form.phoneNumber}`
        },
        2: {
          item: 12,
          comments: `Sent to ${[...(this.form.to || []), ...(this.form.cc || [])]
            .map(e => e.address)
            .join(", ")}`
        },
        5: {
          item: 13,
          comments: `Sent to ${this.form.recipient} at ${this.form.networkPath}`
        },
        4: {
          item: 14,
          comments: `Sent to ${this.form.recipient} with Sending Facility ${this.form.hl7.sendingFac} & Sending Application ${this.form.hl7.sendingApp}.`
        }
      };

      return Promise.all(
        caseIds.map(caseId =>
          CasesApi.getCaseById(caseId).then(caseDetails => {
            const logItem = createLogItem(caseDetails, itemTypes[this.method]?.item);
            logItem.comments = itemTypes[this.method]?.comments;
            return auditLog.insertLogMessage(logItem);
          })
        )
      );
    },
    initializeTargetInput() {
      const items = this.targets?.items || [];
      if (!items?.length) return;
      if (DistributionMethodsEnum.Fax === this.method) {
        let primaryIdx = items.findIndex(e => e.isPrimary);
        if (primaryIdx < 0) {
          primaryIdx = 0;
        }
        if (this.form.targetId) return;
        const targetItem = items[primaryIdx];
        if (targetItem?.id) {
          this.form.targetId = targetItem.id;
        }
      }
      if (DistributionMethodsEnum.Email === this.method) {
        const emails = items.map(e => {
          return {
            address: e.address,
            Type: this.recipientTypes.to,
            displayName: e.recipient || ""
          };
        });
        if (emails.length) {
          this.form.to = emails;
        }
      }
    },
    handleLoadTarget(id) {
      const contact = this.form.selectedContact;
      if (contact && contact.properties) {
        if (DistributionMethodsEnum.Fax === this.method) {
          const { faxMachines } = contact.properties;
          const targetFaxMachine = faxMachines.find(e => e.id === id);
          if (targetFaxMachine?.phoneNumber) {
            this.form.phoneNumber = targetFaxMachine.phoneNumber;
          }
          if (targetFaxMachine?.recipient) {
            this.form.recipient = targetFaxMachine.recipient;
          }
        }
        if (DistributionMethodsEnum.Email === this.method) {
          const { emails } = contact.properties;
          const email = emails.find(e => e.id === id);
          if (email?.recipient && email?.address) {
            this.form.recipient = email.recipient;
            this.form.to.push({
              address: email.address,
              Type: this.recipientTypes.to,
              displayName: email.recipient || ""
            });
          }
        }
        if (DistributionMethodsEnum.FileDrop === this.method) {
          const { pdfFileDrops } = contact.properties;
          const fileDrop = pdfFileDrops.find(e => e.id === id);
          this.form.networkPath = fileDrop.networkPath;
          this.form.fileNamingConvention = fileDrop.fileNamingConvention;
        }
        if (DistributionMethodsEnum.HL7 === this.method) {
          const { hL7Configs } = contact.properties;
          const hl7 = hL7Configs.find(e => e.id === id);
          if (hl7) {
            this.form.hl7 = hl7;
          }
        }
        if (DistributionMethodsEnum.Print === this.method) {
          const { printers } = contact.properties;
          return {
            dataSource: printers || [],
            displayExpr: "name",
            label: "Available Printers"
          };
        }
      }
    },
    formatFax(data) {
      if (data?.phoneNumber) {
        return formatPhoneNumber(data.phoneNumber);
      }
      return "";
    }
  },
  directives: {
    focusItem: {
      inserted(el) {
        return el.focus();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.list-group {
  width: 600px;
}
.popup {
  height: "fit-content";
}
</style>
