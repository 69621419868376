<template>
  <div>
    <label
      ><b>{{ $attrs.label }}</b></label
    >
    <DxTagBox
      v-if="multiple"
      :value="value"
      placeholder="Select Users..."
      :show-selection-controls="true"
      :search-expr="columns"
      :display-expr="displayUserName"
      :dataSource="dataSource"
      :maxDisplayTags="5"
      :showDropDownButton="true"
      :search-enabled="true"
      :hideSelectedItems="true"
      @value-changed="onValueChanged"
      :dropdownOptions="dropdownOptions"
    />

    <DxSelectBox
      v-else
      :value="value"
      :search-expr="columns"
      placeholder="Select Users..."
      :show-selection-controls="true"
      :display-expr="displayUserName"
      :dataSource="dataSource"
      :show-clear-button="true"
      v-bind="$attrs"
      label=""
      :maxDisplayTags="5"
      :showDropDownButton="true"
      :search-enabled="true"
      :hideSelectedItems="true"
      @value-changed="onValueChanged"
      :dropdownOptions="dropdownOptions"
    />
  </div>
</template>

<script>
import UserService from "../../../services/users";
import DxTagBox from "devextreme-vue/tag-box";
import DxSelectBox from "devextreme-vue/select-box";
import { mapState } from "vuex";
import DataSource from "devextreme/data/data_source";
export default {
  name: "User",
  props: {
    multiple: {
      type: Boolean,
      default() {
        return false;
      }
    },
    value: {
      required: true
    }
  },
  inheritAttrs: false,
  components: {
    DxTagBox,
    DxSelectBox
  },
  data: () => ({
    columns: ["username", "firstName", "lastName"],
    dropdownOptions: {
      position: "bottom"
    }
  }),
  methods: {
    onValueChanged(e) {
      this.users = e.value;
    },
    displayUserName(user) {
      if (user) {
        if (user.firstName && user.lastName) {
          return `${user?.lastName}, ${user?.firstName}`;
        }
        return user.username;
      }
      return "";
    }
  },
  computed: {
    ...mapState(["currentUser", "currentLab"]),
    dataSource() {
      return new DataSource({
        store: UserService.searchStore,
        sort: ["lastName"]
      });
    },
    users: {
      get() {
        return this.value;
      },
      set(value) {
        return this.$emit("input", value);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
