import Vue from "vue";
import VToolTip from "v-tooltip";
import Vuelidate from "vuelidate";
import VueRx from "vue-rx";
import { Observable } from "rxjs";
import VueMeta from "vue-meta";
import { alert, confirm } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import registerWorkbox from "./registerServiceWorker";
import ShortKey from "vue-shortkey";
import * as overlay from "devextreme/ui/overlay";
import "devextreme/dist/css/dx.light.css";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "jquery";
import "popper.js";
import "bootstrap";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import autoFocus from "@/directives/autoFocus";
import logrocketRedact from "./mixins/logrocketRedact";

import config from "devextreme/core/config";
import devLog from "./mixins/devLog";

config({
  forceIsoDateParsing: true
});
window.nativeAlert = window.alert;
window.alert = message => alert(`<div style="max-width: 50vw">${message}</div>`, "IntelliPath Pro");
window.confirm = message => confirm(message, "IntelliPath Pro");
window.notify = (
  message,
  type = "success",
  time = 5000,
  position = { at: "bottom center", of: "#navbar" }
) => {
  if (type === "error" && time === 5000) {
    time = 7500;
  }
  return notify(
    {
      message,
      width: 300,
      position,
      closeOnClick: true
    },
    type,
    time
  );
};
RegExp.escape = function (string) {
  return string.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
};

const isDev = process.env.NODE_ENV !== "production";
registerWorkbox();
Vue.config.performance = isDev;
Vue.config.productionTip = false;
Vue.config.debug = isDev;
Vue.config.devtools = isDev;
Vue.use(ShortKey);
Vue.directive("focus", autoFocus);

Vue.use(VToolTip);
overlay.baseZIndex(5000);
Vue.use(VueMeta);

Vue.use(VueRx, { Observable });
Vue.use(Vuelidate);
Vue.mixin(logrocketRedact);
Vue.mixin(devLog);
export default new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

if (window.Cypress) {
  // Add `store` to the window object only when testing with Cypress
  window.store = store;
}
