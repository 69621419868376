import api from "./api.js";

/**
 * Represents a service for handling cytology-related operations.
 */

export const CytologyEntities = {
  CytDiagnosticCodes: "CytDiagnosticCodes",
  CytDiagnosticMacros: "CytDiagnosticMacros",
  CytQuestions: "CytQuestions",
  CytMolecResults: "CytMolecResults",
  CytMolecTests: "CytMolecTests",
  CytMolecPanels: "CytMolecPanels",
  CytTriggers: "CytTriggers"
};
class CytologyService {
  constructor() {
    this.url = "/api/";
  }

  createSearch(type) {
    if (type in CytologyEntities) {
      return api.createSearch(this.url + type);
    }
    throw new Error("Invalid Cytology Entity Type");
  }

  updateEntity(type, data) {
    if (type in CytologyEntities) {
      return api.put(this.url + type, data);
    }
    throw new Error("Invalid Cytology Entity Type");
  }

  createEntity(type, data) {
    if (type in CytologyEntities) {
      return api.post(this.url + type, data);
    }
    throw new Error("Invalid Cytology Entity Type");
  }

  getSingleEntity(type, id) {
    if (type in CytologyEntities) {
      return api.get(this.url + type + `/${id}`);
    }
    throw new Error("Invalid Cytology Entity Type");
  }
}

const cytologyService = new CytologyService();
export default cytologyService;
