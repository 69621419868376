<template>
  <div>
    <h2>Please select lab location:</h2>
    <select-input
      v-focus
      v-model="labLocationId"
      :items="availableLabLocations"
      :showClearButton="false"
    />
    <div class="d-flex justify-content-end">
      <loader v-if="isLoading" size="small" class="mr-1" />
      <button class="btn btn-danger mr-1" @click="handleCancel">Cancel</button
      ><button
        v-shortkey="['enter']"
        @shortkey="handleSelectLabLocation"
        class="btn btn-primary"
        @click="handleSelectLabLocation"
        :disabled="!currentLabLocation"
      >
        Sign
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Loader from "./common/Loader.vue";
import SelectInput from "./common/SelectInput.vue";
export default {
  components: { SelectInput, Loader },
  props: ["isLoading", "payloadToSign"],
  computed: {
    ...mapState({
      currentLabLocation: state => state.currentLabLocation,
      availableLabLocations: state => state.availableLabLocations
    }),
    labLocationId: {
      get() {
        return this.currentLabLocation;
      },
      set(value) {
        this.$store.dispatch("setCurrentLabLocation", value);
        return value;
      }
    }
  },
  methods: {
    handleCancel() {
      this.$emit("cancel");
    },
    handleSelectLabLocation() {
      this.$emit("sign", this.payloadToSign);
    }
  }
};
</script>

<style lang="scss" scoped></style>
