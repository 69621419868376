import eventBus, { POPUP_ACTION, POPUP_HIDE, POPUP_SHOW } from "@/modules/eventBus";
import { PopupApi, ProvidersApi } from "@/services";
import { uniq } from "lodash";
import { mapGetters, mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      caseTags: state => state.accessionStore.caseTags,
      insurances: state => state.accessionStore.insurances,
      caseDetailsState: state => state.accessionStore.caseDetails,
      currentSpecimenState: state => state.accessionStore.currentSpecimen,
      viewedPopups: state => state.accessionStore.viewedPopups
    }),
    ...mapGetters("accessionStore", ["primaryProvider"]),
    ...mapGetters(["permissions"])
  },
  methods: {
    async getPhysicianPopup(triggerEventId, specimensPayload = []) {
      if (!this.permissions.PopupView) {
        return true;
      }
      let providerId = null;
      let locationId = null;
      if (this.primaryProvider?.contact) {
        providerId = this.primaryProvider?.contact?.providerId;
        locationId = this.primaryProvider?.contact?.providerAddressId;
      } else if (this.primaryProvider?.contactId) {
        const primaryContact = await ProvidersApi.getContactsById([this.primaryProvider.contactId]);
        if (primaryContact?.length) {
          providerId = primaryContact[0]?.providerId;
          locationId = primaryContact[0]?.providerAddressId;
        }
      }
      const payload = {
        caseId: this.caseDetailsState.caseId,
        primaryProviderId: providerId,
        primaryLocationId: locationId,
        billingCycleId: this.caseDetailsState.billingCycle,
        casePriorityId: this.caseDetailsState.priority,
        tagIds: this.caseTags.map(e => e.tagId),
        insuranceIds: this.insurances.map(e => e.insuranceId),
        imageTypeIds: [],
        patientId: this.caseDetailsState.patientId,

        triggerEventId
      };
      let physicianPopupResponse;
      if (triggerEventId === 5) {
        const allMacroIds = specimensPayload.reduce(
          (acc, specimen) => {
            if (specimen.resultsMacros) {
              acc.resultsMacroIds = uniq(
                [
                  ...acc.resultsMacroIds,
                  specimen.resultsMacros.map(macro => macro.id).filter(e => e)
                ].flat()
              );
            }
            acc.protocolMacroIds = uniq(
              [...acc.protocolMacroIds, specimen.protocolId].filter(e => e)
            );
            return acc;
          },
          { protocolMacroIds: [], resultsMacroIds: [] }
        );

        payload.resultsMacroIds = allMacroIds.resultsMacroIds;
        payload.protocolMacroIds = allMacroIds.protocolMacroIds;
        physicianPopupResponse = await PopupApi.getPopupForSpecimen(payload);
      } else {
        physicianPopupResponse = await PopupApi.getPopupForCase(payload);
      }
      if (physicianPopupResponse?.length) {
        let unviewedPopups = physicianPopupResponse.filter(e => {
          if (triggerEventId === 5 || triggerEventId === 1) {
            return true;
          } else {
            return !this.viewedPopups.includes(e.id);
          }
        });
        const infoPopups = unviewedPopups.filter(
          e =>
            e.hasCancelButton === false && e.requireInitials === false && !e?.imageTypeIds?.length
        );
        unviewedPopups = unviewedPopups.filter(
          e => e.hasCancelButton || e.requireInitials || e?.imageTypeIds?.length
        );
        if (infoPopups?.length) {
          const joinedInfoPopup = {
            name: infoPopups.map(e => e.name).join(" | "),
            displayText: infoPopups.map(e => e.displayText).join("<br><br>")
          };
          unviewedPopups = [joinedInfoPopup, ...unviewedPopups];
        }
        let responses = [];
        for (const popup of unviewedPopups) {
          await this.showPopup(popup);
        }
        this.$store.commit(
          "accessionStore/setViewedPopups",
          uniq([...this.viewedPopups, infoPopups.map(e => e.id)])
        );
        const allConfirmed = responses.filter(e => e);
        return allConfirmed.length === responses.length;
      } else {
        return true;
      }
    },
    showPopup(config) {
      eventBus.$emit(POPUP_SHOW, config);
      this.$store.commit("accessionStore/setViewedPopups", uniq([...this.viewedPopups, config.id]));
      return new Promise(resolve => {
        eventBus.$once(POPUP_ACTION, value => {
          eventBus.$emit(POPUP_HIDE);
          resolve(value);
        });
      });
    },
    showImagePopup(config) {
      eventBus.$emit(POPUP_SHOW, config);
      this.$store.commit("accessionStore/setViewedPopups", uniq([...this.viewedPopups, config.id]));
      return new Promise(resolve => {
        eventBus.$once(POPUP_ACTION, value => {
          eventBus.$emit(POPUP_HIDE);
          resolve(value);
        });
      });
    }
  }
};
