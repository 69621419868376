<template>
  <div class="p-2">
    <div class="m-2">
      <h2>Patient Cases</h2>
      <path-report-viewer
        v-if="isInline && canViewResults && permissions.PathReportView"
        class="mb-2"
      />
      <DxDataGrid
        v-if="!isMobileView"
        class="caseGrid"
        ref="caseGrid"
        noDataText="No Cases found."
        :pageSize="10"
        :show-borders="true"
        :show-row-lines="true"
        :column-auto-width="true"
        :rowAlternationEnabled="true"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        columnResizingMode="widget"
        :hoverStateEnabled="true"
        @cellClick="handleCaseClick"
        :columns="columns"
        :dataSource="clientHistoryStore"
        @initialized="initGrid"
        :cacheEnabled="false"
      >
        <template v-slot:actionsTemplate="{ data: { data } }">
          <div class="d-inline-flex">
            <icon-button v-bind="getStatusIcon(data)" v-tooltip="getStatusIcon(data).tooltip" />
            <icon-button
              v-tooltip.right="data.isViewed ? 'Case has been viewed.' : 'Mark case as viewed.'"
              v-if="reportedStatuses.includes(data.status) && permissions.PathReportView"
              @click="markAsViewed(data)"
              :icon="!Boolean(data.isViewed) ? 'eye-slash' : 'eye'"
              class="text-primary mr-2 p-0 pointer"
            />
            <icon-button
              icon="envelope"
              @click="createRequest(data)"
              v-tooltip="'Send pathologist request.'"
              class="text-primary pointer p-0"
            />
          </div>
        </template>
      </DxDataGrid>

      <dx-list
        @itemClick="handleCaseClick"
        v-else
        :dataSource="clientHistoryStore"
        :pullRefreshEnabled="true"
        selectionMode="all"
      >
        <template v-slot:item="{ data }">
          <div class="card">
            <div class="card-title d-flex align-items-center">
              <h6 class="m-0">{{ data.caseNumber }}</h6>
              <icon-button v-bind="getStatusIcon(data)" class="ml-auto" />
            </div>
            <div class="">
              <p>Collected: {{ formatDate(data.dateReceived) }}</p>
              <p v-show="data.providerLastName">
                Provider: {{ data.providerLastName + ", " + data.providerFirstName }}
              </p>
              <p v-show="data.providerOfficeName">Location: {{ data.providerOfficeName }}</p>
            </div>
          </div>
        </template>
      </dx-list>
      <modal :status="isRequestOpen" @close="handleCloseRequest">
        <client-task-form
          :caseId="requestCaseId"
          :caseNumber="requestCaseNumber"
          @submit="handleCloseRequest"
          @cancel="handleCloseRequest"
        />
      </modal>
    </div>
  </div>
</template>

<script>
import { CasesApi } from "@/services";
import { DxDataGrid } from "devextreme-vue/data-grid";
import PathReportViewer from "./Reports/PathReportViewer.vue";
import { mapGetters, mapState } from "vuex";
import { filterAccessionNumber, getStatusIcon, pastLabDelay } from "@/modules/helpers";
import IconButton from "./common/IconButton.vue";
import DataSource from "devextreme/data/data_source";
import { DxList } from "devextreme-vue/list";
import moment from "moment";
import Modal from "./common/Modal.vue";
import ClientTaskForm from "./forms/ClientTaskForm.vue";
import { CaseStatusEnum } from "@/modules/enums";
export default {
  name: "ClientCaseView",
  components: {
    PathReportViewer,
    DxDataGrid,
    IconButton,
    DxList,
    Modal,
    ClientTaskForm
  },
  props: ["signedOn"],
  data() {
    return {
      caseDetails: {},
      patientDetails: {},
      selectedCase: {},
      requestCaseId: "",
      requestCaseNumber: "",
      isRequestOpen: false,
      reportedStatuses: [
        CaseStatusEnum.ReportedPrelim,
        CaseStatusEnum.Reported,
        CaseStatusEnum.ReReleased
      ]
    };
  },
  created() {
    this.setSelectedCase(this.$route.params.caseId);
  },

  methods: {
    initGrid({ component }) {
      this.grid = component;
    },
    getPhysician(data) {
      const { providerLastName, providerFirstName } = data;
      if (providerLastName && providerFirstName) {
        return providerLastName + ", " + providerFirstName;
      }
      return "N/A";
    },
    formatDate(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    markAsViewed(data) {
      const { isViewed, caseId } = data;
      const userMeta = {
        isViewed: !isViewed,
        viewedOn: new Date(),
        caseId
      };
      return CasesApi.updateUserMeta(userMeta).then(() => {
        this.grid.clearSelection();
        this.grid.refresh();
      });
    },
    async handleCaseClick(data) {
      let key = data.key;
      if (this.isMobileView) {
        key = data.itemData.caseId;
      }
      if (data.columnIndex < 1) {
        return;
      }
      const status = data.data.status;
      this.selectedCase = data.data;
      await this.$store.dispatch("accessionStore/getCaseHeader", key);
      this.$router.push(`/accession/${key}`);
      if (status !== 14 || status !== 11) {
        this.$store.dispatch("report/clearReport");
      } else {
        this.$store.dispatch("report/viewPathReport", { caseId: key });
      }
    },
    getStatusIcon,
    createRequest(data) {
      this.requestCaseId = data.caseId;
      this.requestCaseNumber = data.caseNumber;
      this.isRequestOpen = true;
    },
    handleCloseRequest() {
      this.requestCaseId = null;
      this.isRequestOpen = false;
    },
    async setSelectedCase(id) {
      this.selectedCase = await CasesApi.getCaseById(id);
      if (this.permissions.SlideImageViewClient) {
        const slideImages = await CasesApi.getSlideImages(id);
        if (slideImages?.length) {
          this.$store.commit("accessionStore/setSlideImages", slideImages);
        }
      }
    }
  },
  computed: {
    ...mapState({
      caseStatuses: state => state.caseStatuses,
      isMobileView: state => state.isMobileView,
      labSettings: state => state.labSettings
    }),
    ...mapGetters("report", ["isInline"]),
    ...mapGetters(["permissions"]),
    clientHistoryStore() {
      const { caseId } = this.$route.params;
      return new DataSource({
        filter: ["!", ["caseId", caseId]],
        store: CasesApi.getPatientHistoryStore(caseId)
      });
    },
    columns() {
      //Determines which columns to render depending if the user is a iPathWebLink User or a  iPathPro
      return [
        {
          type: "buttons",
          cellTemplate: "actionsTemplate",
          caption: "Action",
          width: "100px"
        },
        {
          dataField: "status",
          dataType: "number",
          filterType: "include",
          lookup: {
            dataSource: this.caseStatuses.map((e, i) => ({ displayName: e, id: i })),
            displayExpr: "displayName",
            valueExpr: "id"
          }
        },
        {
          dataField: "caseNumber",
          allowReordering: true,
          sortOrder: "asc",
          sortIndex: 1,
          caption: "Case #",
          dataType: "string",
          calculateFilterExpression: filterAccessionNumber(
            "caseNumber",
            this.$store.state.labSettings.AccessionNumberingType
          )
        },
        {
          dataField: "provider",
          allowReordering: true,
          allowSorting: true,
          caption: "Physician"
        },
        {
          dataField: "dateReceived",
          caption: "Procedure Date",
          dataType: "date",
          sortIndex: 0,
          sortOrder: "desc",
          selectedFilterOperation: "between",
          filterType: "include"
        },
        {
          dataField: "location",
          allowReordering: true,
          caption: "Location",
          dataType: "string"
        }
      ];
    },
    canViewResults() {
      if (this.reportedStatuses.includes(this.selectedCase.status)) {
        if (this.labSettings.ShowCasesToLabClientsDuringDistributionDelay) {
          return true;
        }
        return pastLabDelay(this.signedOn);
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep
  .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)
  > td:not(.dx-focused) {
  cursor: pointer;
}
.card {
  display: block;
  .card-title {
    margin-bottom: 2px;
  }
  .card-body {
    padding: 10px;
  }
  .avatar {
    display: flex;
    margin-right: 5px;
    & > img {
      align-self: center;
      width: 50px;
      height: 50px;
    }
  }

  p {
    margin-bottom: 1px;
  }
}
::v-deep .dx-item.dx-list-item {
  margin: 10px auto 0px auto;
  border-top: initial;
}
</style>
