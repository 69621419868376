<template>
  <div class="container">
    <div v-if="!isLoading">
      <dx-grid-with-search
        :pageSize="5"
        title="Patient Records"
        :dataSource="dataSource"
        :columns="patientColumns"
        :selection="selection"
        @row-click="handleRowClick"
        :scrolling="scrolling"
        v-stream:content-ready="initializeGrid$"
        v-stream:selection-changed="selectionChange$"
        :columnChooser="true"
        :toolbar="toolbar"
        @initialized="initGrid"
      >
        <template v-slot:actions="{ data: { data, rowIndex } }">
          <icon-button
            type="button"
            :data-testid="`set-patient-${rowIndex}`"
            @click="setPatient(data.id)"
            v-tooltip.left="'Select Patient'"
            class="btn p-0 text-primary"
            icon="link"
          />
        </template>
      </dx-grid-with-search>
    </div>
    <div v-else class="d-flex h-100 flex-column justify-content-center align-items-center">
      <loader />
      <p>Applying patient information.</p>
    </div>
  </div>
</template>

<script>
import { filter, map, switchMap, take, tap } from "rxjs/operators";
import DxGridWithSearch from "./common/DxGridWithSearch.vue";
import { CasesApi } from "@/services";
import { fromEvent, merge } from "rxjs";
import DataSource from "devextreme/data/data_source";
import Loader from "./common/Loader.vue";
import IconButton from "./common/IconButton.vue";
import ArrayStore from "devextreme/data/array_store";
import { formatSSN } from "../modules/helpers";
import { mapState } from "vuex";
export default {
  components: { DxGridWithSearch, Loader, IconButton },
  name: "PatientMatchingPopup",
  props: {
    matchedPatients: {
      default: () => []
    }
  },
  domStreams: ["initializeGrid$", "selectionChange$"],
  created() {
    this.$store.dispatch("dropdowns/getSexes");
    this.$store.dispatch("dropdowns/getRaces");
  },
  data() {
    return {
      selection: {
        mode: "single",
        showCheckBoxesMode: "always",
        enabled: true
      },
      scrolling: {
        showScrollbar: "always",
        useNative: true
      },
      grid: {},
      isLoading: false,
      toolbar: {
        items: [
          {
            name: "exportButton",
            visible: true
          },
          {
            widget: "dxButton",
            options: {
              icon: "xlsfile",
              onClick: this.exportToExcel,
              hint: "Click to export as excel file"
            }
          }
        ]
      }
    };
  },
  methods: {
    async setPatient(patientId) {
      this.isLoading = true;
      try {
        const patientRecord = await CasesApi.getPatientById(patientId);
        this.$emit("setPatient", patientRecord);
      } catch (error) {
        console.error("Error fetching patient record", error);
      }
      this.isLoading = false;
    },
    initGrid({ component }) {
      this.grid = component;
    },
    exportToExcel() {
      this.grid.exportToExcel(false);
    },
    handleRowClick(data) {
      this.setPatient(data.key);
    }
  },
  computed: {
    ...mapState({
      sexes: state => state.dropdowns.sexes,
      races: state => state.dropdowns.races
    }),
    patientColumns() {
      return [
        { dataField: "firstName", dataType: "string" },
        { dataField: "lastName", dataType: "string" },
        { dataField: "maidenName", dataType: "string" },
        {
          dataField: "ssn",
          dataType: "string",
          calculateDisplayValue: data => {
            if (data.ssn) {
              return formatSSN(data.ssn);
            }
            return "";
          }
        },
        { dataField: "mrn", dataType: "string" },
        {
          dataField: "sex",
          lookup: {
            dataSource: this.sexes,
            valueExpr: "id",
            displayExpr: "displayName"
          },
          dataType: "number",
          allowSearch: false
        },
        { dataField: "dateOfBirth", dataType: "date" },
        { dataField: "accountNumber", dataType: "string" },
        {
          dataField: "race",
          dataType: "number",
          lookup: {
            dataSource: this.races,
            valueExpr: "id",
            displayExpr: "displayName"
          },
          allowSearch: false
        },
        { dataField: "lastProvider", dataType: "string", caption: "Provider" },
        { dataField: "createdOn", dataType: "date", allowSearch: false },
        { dataField: "updatedOn", dataType: "date", allowSearch: false },
        {
          type: "buttons",
          caption: "Actions",
          cellTemplate: "actions"
        }
      ];
    },
    dataSource() {
      let store;
      if (this.matchedPatients && this.matchedPatients?.length) {
        store = new ArrayStore({ data: this.matchedPatients, key: "id" });
      } else {
        store = CasesApi.patientMatch;
      }
      return new DataSource({
        store,
        sort: { selector: "createdOn", desc: true },
        pageSize: 10,
        paginate: true,
        key: "id"
      });
    }
  },
  subscriptions() {
    const grid$ = this.initializeGrid$.pipe(map(({ event }) => event.msg));
    const selectFirstRow$ = grid$.pipe(
      take(1),
      tap(({ component, element }) => {
        const searchPanelElement = element.querySelector('input[type="text"]');
        if (searchPanelElement) {
          searchPanelElement.focus();
        }
        const selectedKeys = component.getSelectedRowKeys();
        if (!selectedKeys?.lenght) {
          component.selectRowsByIndexes([0]);
        }
      })
    );
    const focusPanel$ = grid$.pipe(
      filter(({ element }) => {
        const searchPanelElement = element.querySelector('input[type="text"]');
        return searchPanelElement;
      }),
      switchMap(({ component: grid, element }) => {
        const panel = element.querySelector('input[type="text"]');

        function selectNextRow(change) {
          const totalCount = grid.totalCount();
          const currentSelection = grid.getSelectedRowKeys();
          const currentSelectionIndex = grid.getRowIndexByKey(currentSelection[0]);
          const newRowIndex = currentSelectionIndex + change;
          if (newRowIndex > -1) {
            if (newRowIndex < totalCount) {
              const rowElements = grid.getRowElement(newRowIndex);
              rowElements.forEach(element => {
                element.scrollIntoView(false);
              });
              const newRowKey = grid.getKeyByRowIndex(newRowIndex);
              grid.selectRows([newRowKey]);
            }
            const rowElements = grid.getRowElement(newRowIndex);
            if (rowElements) {
              rowElements.forEach(element => {
                element.scrollIntoView(false);
              });
            }
          }
        }
        const keyDown$ = fromEvent(this.$el, "keydown");
        const arrowUp$ = keyDown$.pipe(
          filter(e => e.keyCode === 38),
          map(() => -1),
          tap(selectNextRow)
        );
        const arrowDown$ = keyDown$.pipe(
          filter(e => e.keyCode === 40),
          map(() => 1),
          tap(selectNextRow)
        );
        const enter$ = keyDown$.pipe(
          filter(e => e.keyCode === 13),
          tap(event => {
            event.preventDefault();
            event.stopPropagation();
            const data = grid.getSelectedRowsData();
            if (data?.length) {
              this.setPatient(data[0]?.id);
            }
          })
        );
        const selectionChanged$ = this.selectionChange$.pipe(
          tap(data => {
            const { selectedRowsData } = data.event.msg;
            this.selectedRows = selectedRowsData;
            if (document.activeElement !== panel) {
              panel.focus();
            }
          })
        );
        return merge(arrowDown$, arrowUp$, selectionChanged$, enter$);
      })
    );
    return {
      focusPanel$,
      selectFirstRow$
    };
  }
};
</script>

<style lang="scss" scoped></style>
