import api from "./api.js";
class Billing {
  constructor() {
    this.url = "/api/Maintenance/";
  }
  uploadAddendaFile(data) {
    const token = sessionStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${encodeURIComponent(token)}`,
      "Content-Type": "form-data"
    };
    return api.post(this.url + "icd10", data, headers);
  }
}
export default new Billing();
