import api from "./api.js";
class Auth {
  async login(data) {
    const response = await api.post("/api/Users/login", data, {
      "Content-Type": "application/json-patch+json"
    });
    sessionStorage.setItem("token", response.token);
    return response;
  }
  logout() {
    return api.delete("/api/Users/logout");
  }

  activateUser(data) {
    return api.post("/users/activate", data);
  }
  checkAuthStatus() {
    return api.get("/api/SystemInfo");
  }
}
export default new Auth();
