<template>
  <form @submit.prevent="handleSubmit" class="change-case-prefix">
    <div class="d-flex">
      <prefix class="col-4" v-model="prefixId" :validator="$v.prefixId" />
      <text-input
        label="Case #"
        class="col"
        v-focus
        v-model="caseNumber"
        @blur="formatAccessionNumber"
        :validator="$v.caseNumber"
        :validatorMsgMap="validatorMsgMap"
        maxLength="13"
        name="caseNumber"
      />
    </div>
    <div class="d-flex col py-2">
      <button class="btn btn-primary ml-auto" :disabled="formDisabled" type="submit">Submit</button>
    </div>
  </form>
</template>

<script>
import TextInput from "./common/TextInput.vue";
import Prefix from "./forms/Selectors/Prefix.vue";
import { helpers, required } from "vuelidate/lib/validators";
import { calculateAccessionNumbering, createLogItem, validatorMsgMapBase } from "@/modules/helpers";
import { mapGetters, mapState } from "vuex";
import { AuditLogApi, CasesApi } from "@/services";
import { AuditLogItems } from "@/modules/enums";
export default {
  components: { Prefix, TextInput },
  name: "ChangeCasePrefix",
  data() {
    return { prefixId: null, caseNumber: null };
  },
  created() {
    if (this.caseDetails) {
      this.prefixId = this.caseDetails.labPrefix;
      this.caseNumber = this.caseDetails.caseNumber.substring(
        this.caseDetails.caseNumber.length - 12
      );
    }
  },
  validations() {
    return {
      prefixId: {
        required
      },
      caseNumber: {
        validCaseNumber: helpers.regex("CaseNumber", /^[0-9-]+$/),
        caseNumberValidator: this.validateCaseNumber,
        required
      }
    };
  },
  methods: {
    async handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return window.notify("Please check your input & try again.", "error");
      }
      const caseId = this.$route?.params?.caseId;
      const [year, number] = this.caseNumber.split("-");
      const payload = {
        caseId,
        prefixId: this.prefixId,
        numberYear: year,
        numberSequence: number,
        caseNumber: this.formattedCaseNumber
      };
      try {
        await CasesApi.changeCaseNumber(payload);
        const logItem = createLogItem(this.caseDetails, AuditLogItems.ChangeAccession);
        logItem.commons = `Case number has been updated from ${this.caseDetails.caseNumber} to ${this.formattedCaseNumber}`;
        await AuditLogApi.insertLogMessage(logItem);
        await this.$store.dispatch("accessionStore/getCaseDetails", caseId);
        await this.$store.dispatch("accessionStore/getCaseHeader", caseId);
        await this.$store.dispatch("report/viewPathReport", { caseId });
        window.notify("Case number has been updated.");
        this.$emit("close");
      } catch (error) {
        console.log("Error while changing case number", { error });
        window.alert("Error occurred changing the case number.");
      }
      //! TODO make api call here.
    },
    async validateCaseNumber(value, vm) {
      if (value?.length === 12 && vm.prefixId) {
        const caseId = this.$route?.params?.caseId;
        const [year, number] = value.split("-");
        const payload = {
          caseId,
          prefixId: vm.prefixId,
          numberYear: year,
          numberSequence: number,
          caseNumber: this.formattedCaseNumber
        };
        const isUsed = await CasesApi.validateCaseNumber(payload);
        if (isUsed) {
          window.notify(`The number ${value} has already been used.`, "warning", 7500);
        }
        return !isUsed;
      }
      return null;
    },
    formatAccessionNumber(event) {
      const { value } = event.target;
      let targetNumber = value.replace(/[^\d]/g, "");
      if (value && value.length < 11) {
        const number = calculateAccessionNumbering(targetNumber);
        if (number?.length === 12) {
          this.caseNumber = number;
        }
        if (value.length === 11 && !value.includes("-")) {
          const caseNumWithOutDash = /([\d]{4})([\d]{7})/;
          if (caseNumWithOutDash.test(value)) {
            this.caseDetails.caseNumber = value.replace(
              caseNumWithOutDash,
              (match, g1, g2) => `${g1}-${g2}`
            );
          }
        }
      }
    }
  },
  computed: {
    ...mapState({
      caseDetails: state => state.accessionStore.caseDetails,
      prefixes: state => state.dropdowns.prefixes
    }),
    ...mapGetters("accessionStore", ["isCaseEditable", "isReported"]),
    ...mapGetters(["permissions"]),
    formattedCaseNumber() {
      if (this.caseNumber) {
        const targetPrefix = this.prefixes.find(e => e.id === this.prefixId);
        if (targetPrefix) return targetPrefix.code + this.caseNumber;
      }
      return null;
    },
    currentCaseNumber() {
      return this.caseDetails.caseNumber;
    },
    formDisabled() {
      if (this.isReported) {
        return true;
      } else if (this.isCaseEditable && this.formattedCaseNumber) {
        return this.formattedCaseNumber === this.currentCaseNumber;
      }
      return true;
    },
    validatorMsgMap() {
      return {
        ...validatorMsgMapBase,
        cannotBeCurrentNumber: validatorMsgMapBase.caseNumberValidator
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.change-case-prefix {
  max-width: 30vw;
}
::v-deep .validation-error .error {
  color: red;
  font-weight: bold;
}
</style>
