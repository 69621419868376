<script>
import DxPopup, { DxToolbarItem } from "devextreme-vue/popup";
import { CLOSE_INSTALL_UI, SHOW_INSTALL_UI } from "@/modules/constants";
import eventBus from "@/modules/eventBus";
export default {
  components: {
    DxPopup,
    DxToolbarItem
  },
  name: "DWT-Install",
  data() {
    return {
      installerUrl: "",
      productName: "",
      isVisible: false,
      downloadBtn: {
        text: "Download",
        onClick: () => {
          const a = document.createElement("a");
          a.href = this.installerUrl;
          a.download = this.installerUrl.replace("dwt-resources/dist/", "");
          a.click();
        }
      }
    };
  },
  beforeDestroy() {
    eventBus.$off(SHOW_INSTALL_UI);
    eventBus.$off(CLOSE_INSTALL_UI);
  },
  mounted() {
    eventBus.$on(SHOW_INSTALL_UI, ({ installerUrl, productName }) => {
      this.isVisible = true;
      this.installerUrl = installerUrl;
      this.productName = productName;
    });
    eventBus.$on(CLOSE_INSTALL_UI, () => {
      this.handleHide();
    });
  },
  methods: {
    handleHide() {
      this.isVisible = false;
      this.installerUrl = null;
      this.producName = null;
    }
  }
};
</script>

<template>
  <DxPopup
    @hidden="handleHide"
    maxHeight="250px"
    maxWidth="325px"
    title="IntelliPath Pro"
    :visible="isVisible"
  >
    <template v-slot:content>
      <div>
        <p>
          In order to use your TWAIN device with IntelliPath Pro, please follow this one time set
          up.
        </p>
        <p class="text-danger">After the installation, please <b>REFRESH</b> your browser.</p>
      </div>
    </template>
    <DxToolbarItem widget="dxButton" toolbar="bottom" location="center" :options="downloadBtn" />
  </DxPopup>
</template>

<style lang="scss" scoped></style>
