<template>
  <Multiselect
    :items="items"
    @search-change="fetchItems"
    :loading="isMultiSelectLoading"
    :internal-search="false"
    v-model="forwardValue"
    v-bind="$attrs"
    v-on="$listeners"
    :blockKeys="['Delete']"
    ref="multiSelect"
    @open="isFocused = true"
    @close="isFocused = false"
  >
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </Multiselect>
</template>

<script>
import { debounce } from "lodash";
import Multiselect from "@/components/common/MultiSelect";
import { mapState } from "vuex";
export default {
  inheritAttrs: false,
  name: "MultiSelectWithSearch",
  components: {
    Multiselect
  },
  props: {
    dataSource: {
      required: true
    },
    value: {
      required: true
    },
    newSearch: {
      type: Boolean,
      default: () => false
    },
    apiUrl: {
      type: String
    },
    sort: {
      required: false
    },
    columns: {
      type: Array
    },
    select: {
      type: Array
    },
    fixedFilter: {
      type: Array
    },
    filterType: {
      default() {
        return "contains";
      }
    }
  },
  data() {
    return {
      isMultiSelectLoading: false,
      items: [],
      isFocused: false
    };
  },
  computed: {
    ...mapState(["currentLab"]),
    forwardValue: {
      get() {
        return this.value;
      },
      set(value) {
        return this.$emit("input", value);
      }
    },
    multiSelectRef() {
      return this.$refs.multiSelect;
    }
  },
  watch: {
    currentLab(nv, ov) {
      if (nv != ov && this.dataSource?.load) {
        const select = this.select;
        this.dataSource.load({ filter: this.fixedFilter, select }).then(data => {
          this.items = data;
        });
      }
    }
  },
  created() {
    if (this.dataSource?.filter) {
      this.dataSource.filter({
        filter: this.fixedFilter,
        select: this.select
      });
    }
    if (this.dataSource?.load) {
      this.dataSource
        .load({ filter: this.fixedFilter, select: this.select, sort: this.sort })
        .then(data => {
          this.items = data;
        });
    }
  },
  methods: {
    focus() {
      this.$refs.multiSelect.focus();
    },
    fetchItems: debounce(function (query) {
      this.isMultiSelectLoading = true;
      let filter = this.calculateFilters(query);
      const select = this.select;
      if (this.fixedFilter) {
        filter = [filter, "and", this.fixedFilter];
      }

      return this.dataSource
        .load({
          filter,
          select,
          sort: this.sort
        })
        .then(data => {
          this.items = data;
          this.isMultiSelectLoading = false;
        })
        .catch(() => {
          this.isMultiSelectLoading = false;
        });
    }, 300),
    calculateFilters(query) {
      if (this.columns.length > 1) {
        return this.columns.flatMap((column, i, arr) => {
          let filterExpr = [column, this.filterType, query];
          if (i + 1 < arr.length) {
            return [filterExpr, "or"];
          } else {
            return [filterExpr];
          }
        });
      } else {
        return this.columns.flatMap(column => {
          return [column, this.filterType, query];
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
