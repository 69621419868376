import api from "./api.js";
class CaseHistory {
  constructor() {
    this.url = "/api/CaseHistory";
  }
  moveCases(payload) {
    return api.post(this.url + "/PatientMove", payload);
  }
  removeCases(payload) {
    return api.post(this.url + "/PatientRemoval", payload);
  }
  updatePatient(payload) {
    return api.post(this.url + "/PatientUpdate", payload);
  }
  mergePatients(payload) {
    return api.post(this.url + "/PatientMerge", payload);
  }
  printPatientHistory(payload) {
    return api.post(this.url + "/HistoryReport/Print", payload);
  }
}
export default new CaseHistory();
