<template>
  <div data-testid="loader" :class="{ small: size === 'small' }" class="loader"></div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    size: {
      default: "large",
      type: String
    }
  }
};
</script>

<style lang="scss">
.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid $primary;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  &.small {
    height: 25px;
    width: 25px;
    border-width: 4px;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
