<template>
  <div>
    <div v-if="updatePrompt" class="update-dialog">
      <div class="update-dialog__content">A new version is available. Please update!</div>
      <div class="update-dialog__actions">
        <button
          class="update-dialog__button update-dialog__button--confirm btn btn-success"
          @click="refreshApp"
        >
          Update now!
        </button>
        <button
          class="update-dialog__button update-dialog__button--cancel btn btn-danger"
          @click="togglePopup"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { fromBusEvent } from "@/modules/eventBus";
import { fromEvent } from "rxjs";
import { take, tap } from "rxjs/operators";
import { mapState } from "vuex";

export default {
  data() {
    return {
      refreshing: false,
      registration: null
    };
  },
  computed: {
    ...mapState({
      updatePrompt: state => state.updatePrompt,
      token: state => state.token
    })
  },
  subscriptions() {
    const swReady$ = fromBusEvent("swReady").pipe(
      tap(e => {
        this.registration = e.detail;
        if (this.token) {
          this.registration.active.postMessage({ type: "SAVE_TOKEN", token: this.token });
        }
      })
    );
    const swUpdated$ = fromBusEvent("swUpdated").pipe(
      tap(e => {
        //! Show refresh ui
        this.registration = e.detail;
        if (this.$route.name !== "Login") {
          this.togglePopup();
        }
      })
    );

    return {
      swReady$,
      swUpdated$
    };
  },
  watch: {
    token: {
      immediate: true,
      handler(nv) {
        if (this.registration && this.registration?.active) {
          this.registration.active.postMessage({ type: "SAVE_TOKEN", token: nv });
        }
      }
    }
  },
  methods: {
    async refreshApp() {
      const controllerChange = fromEvent(navigator.serviceWorker, "controllerchange")
        .pipe(take(1))
        .toPromise();
      const serviceWorkers = await navigator.serviceWorker.getRegistrations();
      for (const sw of serviceWorkers) {
        if (sw.waiting) {
          sw.waiting.postMessage({ type: "SAVE_TOKEN", token: this.token });
          sw.waiting.postMessage({
            type: "SKIP_WAITING"
          });
        }
      }
      await controllerChange;
      window.location.reload();
    },
    togglePopup() {
      this.$store.commit("toggleUpdatePrompt");
    }
  }
};
</script>

<style lang="scss" scoped>
.update-dialog {
  position: fixed;
  left: 50%;
  z-index: 1111;
  bottom: 64px;
  transform: translateX(-50%);
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 12px;
  max-width: 576px;
  color: white;
  background-color: $primary;
  text-align: left;
  &__actions {
    display: flex;
    margin-top: 8px;
  }
  &__button {
    margin-right: 8px;
    &--confirm {
      margin-left: auto;
    }
  }
}
</style>
