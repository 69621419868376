<template>
  <form
    ref="procedureModal"
    @submit.prevent="handleSubmit"
    v-shortkey="shortkeys"
    @shortkey.prevent.stop="triggerShortkeys"
    class="p-1 procedure-modal p-2"
  >
    <div class="d-flex justify-content-between">
      <h4>Orders List</h4>
      <div class="d-flex justify-content-end align-items-center">
        <loader size="small" class="mx-2" v-show="isListLoading" />
        <div id="searchInput" class="search-input flex-grow-1 w-full position-relative">
          <input
            ref="searchInput"
            v-model="searchValue"
            class="form-control"
            placeholder="Search"
          />
          <icon-button
            v-if="searchValue"
            @click="clearSearchInput"
            id="clearIcon"
            class="text-primary"
            role="button"
            icon="times"
          />
        </div>
      </div>
    </div>
    <div class="d-flex">
      <div class="d-flex col-3 flex-column">
        <div class="">
          <span class="font-weight-bold"><u>B</u>locks</span>
          <div ref="blocks" id="blocks" class="border blocks p-2">
            <!-- Blocks -->
            <checkbox
              id="selectAllBlocksPopup"
              @input="selectAllBlocks(caseBlocks)"
              ref="selectAllBlocks"
              label="All"
            />
            <checkbox
              :name="displayBlockLabel(block)"
              :key="block.specimenOrder + i"
              v-for="(block, i) in caseBlocks"
              :id="block.specimenOrder + i + 1"
              :value="selectedIds.includes(block.id)"
              @input="addBlock(block)"
              :label="displayBlockLabel(block)"
              :disabled="block.blockNum > 0 && noBlocksSpecimens.includes(block.specimenOrder)"
            />
          </div>
        </div>
        <div class="flex-grow-1 d-flex flex-column">
          <span class="font-weight-bold">Orders <u>f</u>or Selected Block(s)</span>
          <div
            ref="selectedOrders"
            id="selectedOrders"
            class="border flex-grow-1 list-goup list-group-flush blocks-selected"
          >
            <template v-for="(order, index) in ordersForSelectedBlocks">
              <!-- Renders a different list item for group panel orders. -->
              <div data-type="macro" :key="index" class="panels-list" v-if="order.macroId">
                <button
                  role="button"
                  @click="addPanel(order, $event)"
                  type="button"
                  class="list-group-item list-group-item-action pl-1 d-flex text-black justify-content-between"
                >
                  <span class="text-xs font-weight-bold">
                    {{ order.macroName }}
                  </span>
                  <span v-show="order.procedures.length" class="badge badge-primary badge-pill">
                    {{ order.procedures.length }}
                  </span>
                </button>
                <div v-if="order.procedures.length" class="d-flex panel-items flex-column">
                  <span
                    data-type="macroOrder"
                    @keydown.space.stop="removeProcFromPanel(order, proc, $event)"
                    @click="removeProcFromPanel(order, proc, $event)"
                    role="button"
                    tabindex="1"
                    class="text-xs p-1 panel-item list-group-item list-group-item-action mt-1 font-weight-medium"
                    :key="index"
                    v-for="(proc, index) in order.procedures"
                  >
                    {{ proc.description }}
                  </span>
                </div>
              </div>
              <button
                role="button"
                @click="addProc(order, $event)"
                v-else
                :key="index"
                type="button"
                class="list-group-item list-group-item-action pl-1"
              >
                <span class="text-xs font-weight-bold">
                  {{ order.description }}
                </span>
              </button>
            </template>
            <!-- Selected Order -->
          </div>
        </div>
      </div>
      <div class="col-9 d-flex flex-column">
        <div class="">
          <span class="font-weight-bold"><u>O</u>rders</span>
          <div
            id="availableOrders"
            ref="availableOrders"
            class="procedures grid no-gutters p-1 border"
          >
            <!-- <div class="procedure d-flex align-items-center"> -->
            <checkbox
              type="button"
              v-for="(procedure, index) in !isFiltered ? labProcedures : filteredLabProcedures"
              :key="procedure.id"
              :data-index="index"
              :label="procedureLabel(procedure)"
              :id="procedure.id + 'order'"
              :value="selectedProcIds.includes(procedure.id)"
              @input="addProc(procedure)"
              class="text-primary text-left my-1 align-items-center"
            />
          </div>
          <!-- Individual Orders -->
        </div>
        <div>
          <span class="font-weight-bold"><u>P</u>anels</span>
          <div ref="availablePanels" id="panels" class="panels p-1 border grid">
            <checkbox
              v-for="(panel, index) in !isFiltered ? panels : filteredLabPanels"
              :key="panel.macroId"
              type="button"
              :data-index="index"
              :label="panel.macroName"
              :id="panel.macroId + 'panel'"
              :value="selectedPanelIds.includes(panel.macroId)"
              @input="addPanel(panel)"
              class="text-primary text-left my-1 align-items-center"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex p-2">
      <div class="col-5">
        <label for="note"
          ><b>Notes to <u>H</u>istology</b></label
        >
        <text-area-input
          ref="noteModal"
          name="note"
          :validator="$v.note"
          :noLabel="true"
          v-model="note"
          startHeight="2.5rem"
          :generalMacrosEnabled="true"
        />
      </div>
      <div class="ml-auto align-self-center d-flex align-items-center">
        <loader size="small" v-show="isSubmitting" class="mr-1" />
        <button
          :disabled="$v.$invalid || isSubmitting"
          type="submit"
          class="btn btn-primary ml-auto"
        >
          Send
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { maxLength, required } from "vuelidate/lib/validators";
import { MacrosApi } from "@/services/index";
import { addLoadFilter, dateRangeFilter, getAltKeys, toLetters } from "@/modules/helpers";
import { fromEvent, merge } from "rxjs";
import api from "@/services/api";
import { catchError, debounceTime, filter, scan, switchMap, tap } from "rxjs/operators";
import Checkbox from "./common/Checkbox.vue";
import Loader from "./common/Loader.vue";
import IconButton from "./common/IconButton.vue";
import TextAreaInput from "./TextAreaInput.vue";
import { SpecimenNumbersEnum } from "@/modules/enums";
import { handleErrors } from "@/modules/handleErrors";
import { sortBy } from "lodash";

export default {
  components: { Checkbox, Loader, IconButton, TextAreaInput },
  name: "QuickProcedurePopup",
  data() {
    return {
      searchValue: "",
      filteredLabPanels: [],
      labProcedures: [],
      filteredLabProcedures: [],
      selectedBlocks: [],
      note: "",
      panels: [],
      isFiltered: false,
      isListLoading: false,
      isSubmitting: false,
      selectedProcedures: [],
      selectedPanels: [],

      shortkeys: getAltKeys("bfhikops")
    };
  },
  validations() {
    return {
      selectedBlocks: {
        required
      },
      ordersForSelectedBlocks: {
        required
      },
      note: {
        maxLength: maxLength(255)
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.blocks.firstChild) {
        const firstBlock = this.$refs.blocks.firstChild.querySelector("input");
        firstBlock.focus();
      }
    });
  },
  watch: {
    currentSpecimen: {
      immediate: true,
      handler(nv) {
        if (nv?.id) {
          this.selectedBlocks = this.caseBlocks.filter(
            block => block.specimenId === nv.id && !/^NB/i.test(block.id)
          );
        }
      }
    }
  },
  created() {
    const { caseId } = this.$route.params;
    this.procedureStore.load({ sort: [{ selector: "description", desc: false }] }).then(res => {
      this.labProcedures = res || [];
    });

    this.$store.dispatch("accessionStore/getCaseOrders", caseId);

    MacrosApi.searchStore
      .load({
        filter: [["macroType", "=", 2], dateRangeFilter("effectiveOn", "expiresOn")],
        sort: [{ selector: "macroName", desc: false }]
      })
      .then(res => {
        this.panels = res || [];
      });
  },
  domStreams: ["search$"],
  subscriptions() {
    const keydown$ = fromEvent(document, "keydown");
    const block$ = keydown$.pipe(
      filter(() => this.$refs.blocks.contains(document.activeElement)),
      tap(event => {
        let nextTarget;
        switch (event.keyCode) {
          case 40:
            event.preventDefault();
            event.stopPropagation();
            nextTarget = event.target?.offsetParent?.nextElementSibling;
            if (nextTarget) {
              nextTarget.querySelector("input")?.focus();
            }
            return;
          case 38:
            event.preventDefault();
            event.stopPropagation();
            nextTarget = event.target?.offsetParent?.previousElementSibling;
            if (nextTarget) {
              nextTarget.querySelector("input")?.focus();
            }
            return;
        }
      })
    );
    const selectedOrders$ = keydown$.pipe(
      filter(() => this.$refs.selectedOrders.contains(document.activeElement)),
      tap(event => {
        const targets = this.$refs.selectedOrders.querySelectorAll('[role="button"]');
        const currentIdx = Array.from(targets).findIndex(e => e === event.target);
        let nextTarget;
        switch (event.keyCode) {
          case 40:
            event.preventDefault();
            event.stopPropagation();
            nextTarget = targets[currentIdx + 1];
            break;
          case 38:
            event.preventDefault();
            event.stopPropagation();
            nextTarget = targets[currentIdx - 1];
            break;
        }
        return nextTarget && nextTarget?.focus();
      })
    );
    const availableOrder$ = keydown$.pipe(
      filter(() => this.$refs.availableOrders.contains(document.activeElement)),
      tap(event => {
        let nextTarget;
        let nextIndex;
        const grid = this.proceduresGrid;
        const container = this.$refs.availableOrders;
        const currentElement = event.target.offsetParent;
        if (currentElement?.dataset) {
          switch (event.keyCode) {
            case 40:
              event.preventDefault();
              event.stopPropagation();
              nextTarget = currentElement.nextElementSibling;
              break;
            case 38:
              event.preventDefault();
              event.stopPropagation();
              nextTarget = currentElement.previousElementSibling;
              break;
            case 39:
              event.preventDefault();
              event.stopPropagation();
              for (let x = 0; x < grid.length; x++) {
                const col = grid[x];
                for (let y = 0; y < col.length; y++) {
                  const orderIndex = col[y];
                  if (orderIndex === parseInt(currentElement.dataset.index)) {
                    nextIndex = grid[x + 1][y];
                    break;
                  }
                }
              }
              if (container.childNodes[nextIndex]) {
                nextTarget = container.childNodes[nextIndex];
              }
              break;
            case 37:
              event.preventDefault();
              event.stopPropagation();
              for (let x = 0; x < grid.length; x++) {
                const col = grid[x];
                for (let y = 0; y < col.length; y++) {
                  const orderIndex = col[y];
                  if (orderIndex === parseInt(currentElement.dataset.index)) {
                    nextIndex = grid[x - 1][y];
                    break;
                  }
                }
              }

              if (container.childNodes[nextIndex]) {
                nextTarget = container.childNodes[nextIndex];
              }
              break;
          }
          if (nextTarget) {
            nextTarget.querySelector("input")?.focus();
          }
        }
      }),
      catchError((e, s) => {
        //Silently fail if no checkbox is found :)
        return s;
      })
    );
    const availablePanel$ = keydown$.pipe(
      filter(() => this.$refs.availablePanels.contains(document.activeElement)),
      tap(event => {
        let nextTarget;
        let nextIndex;
        const grid = this.panelsGrid;
        const container = this.$refs.availablePanels;
        const currentElement = event.target.offsetParent;
        if (currentElement?.dataset) {
          switch (event.keyCode) {
            case 40:
              event.preventDefault();
              event.stopPropagation();
              nextTarget = currentElement.nextElementSibling;
              break;
            case 38:
              event.preventDefault();
              event.stopPropagation();
              nextTarget = currentElement.previousElementSibling;
              break;
            case 39:
              event.preventDefault();
              event.stopPropagation();
              for (let x = 0; x < grid.length; x++) {
                const col = grid[x];
                for (let y = 0; y < col.length; y++) {
                  const orderIndex = col[y];
                  if (orderIndex === parseInt(currentElement.dataset.index)) {
                    nextIndex = grid[x + 1][y];
                    break;
                  }
                }
              }

              if (container.childNodes[nextIndex]) {
                nextTarget = container.childNodes[nextIndex];
              }
              break;
            case 37:
              event.preventDefault();
              event.stopPropagation();
              for (let x = 0; x < grid.length; x++) {
                const col = grid[x];
                for (let y = 0; y < col.length; y++) {
                  const orderIndex = col[y];
                  if (orderIndex === parseInt(currentElement.dataset.index)) {
                    nextIndex = grid[x - 1][y];
                    break;
                  }
                }
              }

              if (container.childNodes[nextIndex]) {
                nextTarget = container.childNodes[nextIndex];
              }
              break;
          }

          if (nextTarget) {
            nextTarget.querySelector("input")?.focus();
          }
        }
      }),
      catchError((e, s) => {
        //Silently fail if no checkbox is found :)
        return s;
      })
    );
    const searchOrder$ = this.$watchAsObservable("searchValue").pipe(
      debounceTime(500),
      switchMap(async ({ newValue }) => {
        if (newValue) {
          this.isListLoading = true;
          const orders = await this.procedureStore.load({
            filter: [
              [this.labSettings?.ProcedureSearchBy ? "description" : "code", "contains", newValue],
              "and",
              dateRangeFilter()
            ],
            sort: [
              {
                selector: this.labSettings?.ProcedureSearchBy ? "description" : "code",
                desc: false
              }
            ]
          });
          return orders;
        }
        return null;
      }),
      tap(orders => {
        if (orders) {
          this.isFiltered = true;
          this.filteredLabProcedures = orders;
        } else {
          this.isFiltered = false;
        }
        this.isListLoading = false;
      })
    );
    const searchPanels$ = this.$watchAsObservable("searchValue").pipe(
      debounceTime(500),
      switchMap(async ({ newValue }) => {
        if (newValue) {
          this.isListLoading = true;
          const panels = await MacrosApi.searchStore.load({
            filter: [
              ["macroType", 2],
              "and",
              ["macroName", "contains", newValue],
              "and",
              dateRangeFilter("effectiveOn", "expiresOn")
            ],
            sort: [{ selector: "macroName", desc: false }]
          });
          return panels;
        }
        return null;
      }),
      tap(panels => {
        if (panels) {
          this.isFiltered = true;
          this.filteredLabPanels = panels;
        } else {
          this.isFiltered = false;
        }
        this.isListLoading = false;
      })
    );

    return {
      display$: this.$watchAsObservable("selectedIds", { immediate: false }).pipe(
        tap(this.updateDisplay)
      ),
      availablePanel$,
      searchLists$: merge(searchOrder$, searchPanels$).pipe(
        scan(total => total + 1, 0),
        filter(total => total % 2 === 0),
        tap(() => {
          this.isListLoading = false;
        })
      ),
      selectedOrders$,
      block$,
      availableOrder$
    };
  },
  computed: {
    ...mapState({
      currentLab: state => state.currentLab,
      currentUser: state => state.currentUser,
      labSettings: state => state.labSettings,
      caseProcedures: state => state.accessionStore.caseOrders,
      specimens: state => state.accessionStore.specimens,
      currentSpecimen: state => state.accessionStore.currentSpecimen,
      quickLinksNoBlocks: state => state.applicationSettings.quickLinksNoBlocks
    }),
    ...mapGetters("accessionStore", ["specimenNumbering", "isReported", "isCaseEditable"]),
    ordersForSelectedBlocks() {
      return [...this.selectedProcedures, ...this.selectedPanels];
    },
    selectedIds() {
      return this.selectedBlocks.map(e => e.id);
    },
    panelsGrid() {
      return this.panels.reduce((acc, curr, index) => {
        if (acc.length === 0) {
          return [[index]];
        }
        const x = acc.length - 1;
        if (acc[x].length < 5) {
          acc[x].push(index);
        } else {
          acc.push([index]);
        }
        return acc;
      }, []);
    },
    proceduresGrid() {
      return this.labProcedures.reduce((acc, curr, index) => {
        if (acc.length === 0) {
          return [[index]];
        }
        const x = acc.length - 1;
        if (acc[x].length < 10) {
          acc[x].push(index);
        } else {
          acc.push([index]);
        }
        return acc;
      }, []);
    },
    selectedProcIds() {
      return this.selectedProcedures.map(e => e.id);
    },
    selectedPanelIds() {
      return this.selectedPanels.map(e => e.macroId);
    },
    procedureStore() {
      const lab = this.currentLab;
      return api.createSearch(`/api/Labs/${lab}/Procedures`, "id", undefined, addLoadFilter);
    },
    caseBlocks() {
      return sortBy(
        this.specimens
          .map(specimen => {
            let specimenBlocks = [];
            if (specimen.cassettes?.length) {
              specimenBlocks = specimen.cassettes.map(block => {
                return { ...block, specimenOrder: specimen.specimenOrder };
              });
            }
            if (this.quickLinksNoBlocks) {
              specimenBlocks.push({
                blockNum: 0,
                specimenOrder: specimen.specimenOrder,
                id: "NB-" + specimen.specimenOrder,
                blockLabel: "NoBlocks" + specimen.specimenOrder,
                specimenId: specimen.id
              });
            }
            return specimenBlocks;
          })
          .flat() || [],
        ["specimenOrder", "blockNum"]
      );
    },
    noBlocksSpecimens() {
      return this.selectedBlocks.filter(e => e.blockNum === 0).map(e => e.specimenOrder);
    }
  },
  methods: {
    removeProcFromPanel(panel, proc, event) {
      const procedures = panel.procedures.filter(item => item.id !== proc.id);
      event && this.shiftFocusSelectedPanel(event);
      if (procedures.length) {
        this.selectedPanels = [
          ...this.selectedPanels.filter(e => e.macroId !== panel.macroId),
          { ...panel, procedures }
        ];
        return;
      }
      this.selectedPanels = [...this.selectedPanels.filter(e => e.macroId !== panel.macroId)];
    },
    clearSearchInput() {
      this.searchValue = "";
    },
    selectAllBlocks(blocks) {
      const noBlocksSelected = this.selectedBlocks.filter(e => e.blockNum === 0);
      const blocksWithoutNoBlocks = blocks.filter(e => e.blockNum > 0);
      const selectedIdsWithBlocks = this.selectedIds.filter(e => !/NB-/.test(e));
      if (selectedIdsWithBlocks.length < blocksWithoutNoBlocks.length) {
        return (this.selectedBlocks = blocksWithoutNoBlocks);
      }
      if (selectedIdsWithBlocks.length === blocksWithoutNoBlocks.length) {
        return (this.selectedBlocks = noBlocksSelected);
      }
      return blocks.forEach(block => this.addBlock(block));
    },
    addBlock(block) {
      if (this.selectedIds.includes(block.id)) {
        return (this.selectedBlocks = this.selectedBlocks.filter(e => e.id !== block.id));
      }
      if (block.blockNum === 0) {
        return (this.selectedBlocks = [...this.selectedBlocks, block].filter(
          e => e.specimenOrder !== block.specimenOrder || e.blockNum === 0
        ));
      }
      return (this.selectedBlocks = [...this.selectedBlocks, block]);
    },
    shiftFocusSelectedPanel(event) {
      let elements = this.$refs.selectedOrders.querySelectorAll('[role="button"]');
      let currentIndex = Array.from(elements).findIndex(e => e === event.target);
      return this.$nextTick(() => {
        if (
          event.target.dataset?.type === "macroOrder" &&
          !event.target.previousElementSibling &&
          !event.target.nextElementSibling
        ) {
          currentIndex = Array.from(elements).findIndex(
            e => e === event.target.parentNode.previousElementSibling
          );
        }
        let target = elements[currentIndex - 1];
        if (target) {
          target.focus();
        }
      });
    },
    addPanel(panel, event) {
      if (this.selectedPanelIds.includes(panel.macroId)) {
        event && this.shiftFocusSelectedPanel(event);
        return (this.selectedPanels = this.selectedPanels.filter(e => e.macroId !== panel.macroId));
      }
      return (this.selectedPanels = [...this.selectedPanels, panel]);
    },
    addProc(procedure, event) {
      if (this.selectedProcIds.includes(procedure.id)) {
        event && this.shiftFocusSelectedPanel(event);
        return (this.selectedProcedures = this.selectedProcedures.filter(
          e => e.id !== procedure.id
        ));
      }
      return (this.selectedProcedures = [...this.selectedProcedures, procedure]);
    },
    displayBlockLabel(block) {
      if (this.specimenNumbering === SpecimenNumbersEnum.Numbers) {
        if (block.blockNum === 0) {
          return block.specimenOrder + " 0";
        }
        return `${block.specimenOrder} ${block.blockNum ? toLetters(block.blockNum) : ""}`;
      }
      return `${block.specimenOrder} ${block.blockNum}`;
    },
    triggerShortkeys(event) {
      switch (event.srcKey) {
        case "k":
          if (this.$refs.searchInput) {
            this.$refs.searchInput?.focus();
          }

          break;
        case "p":
          if (this.$refs.availablePanels) {
            const checkbox = this.$refs.availablePanels.firstChild;
            if (checkbox) {
              checkbox.querySelector("input")?.focus();
            }
          }
          break;
        case "b":
          if (this.$refs.blocks) {
            const checkbox = this.$refs.blocks.firstChild;
            if (checkbox) {
              checkbox.querySelector("input")?.focus();
            }
          }
          break;
        case "o":
          if (this.$refs.availableOrders) {
            const checkbox = this.$refs.availableOrders.firstChild;
            if (checkbox) {
              checkbox.querySelector("input")?.focus();
            }
          }
          break;
        case "f":
          if (this.$refs.selectedOrders) {
            const button = this.$refs.selectedOrders.firstChild;
            if (button?.dataset?.type === "macro") {
              if (button.childNodes?.length) {
                return button.childNodes[0].focus();
              }
            }
            if (button) {
              button?.focus();
            }
          }
          break;
        case "h":
          this.$refs.noteModal.focus();
          break;
        case "s":
          this.handleSubmit();
          break;
        default:
          break;
      }
    },
    updateDisplay() {
      const $el = this.$refs.selectAllBlocks.$el;
      if ($el) {
        const overall = $el.querySelector('input[type="checkbox"]');
        var checkedCount = this.selectedIds.length;
        if (checkedCount === 0) {
          overall.checked = false;
          overall.indeterminate = false;
        } else if (checkedCount === this.caseBlocks.length) {
          overall.checked = true;
          overall.indeterminate = false;
        } else {
          overall.checked = false;
          overall.indeterminate = true;
        }
      }
    },
    async handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        window.notify("Please verify your input and try again.", "warning");
        return;
      }
      const noProcedurePanel = this.selectedPanels.find(panel => !panel.procedures?.length);
      if (noProcedurePanel) {
        window.alert(
          `The following panel doesn't have any orders please verify your input and try again. <br> <h3>${noProcedurePanel?.macroName}</h3>`
        );
        return;
      }
      const currentOrders = this.caseProcedures.reduce((acc, curr) => {
        if (!acc[curr.cassetteId]) {
          acc[curr.cassetteId] = [curr.procedureId];
        } else {
          acc[curr.cassetteId] = [...acc[curr.cassetteId], curr.procedureId];
        }
        return acc;
      }, {});
      const blockMessages = {};
      const payload = { note: this.note, items: [] };
      const selectedOrders = [
        ...this.selectedProcedures,
        ...this.selectedPanels
          .map(panel => {
            return panel.procedures.map(order => {
              return {
                ...order,
                panelId: panel.macroId,
                detail: this.note || panel.detail
              };
            });
          })
          .flat()
      ];
      for (let block of this.selectedBlocks) {
        let message = [];
        if (block.blockNum === 0) {
          block = { ...block, blockNum: 1, noBlocks: true };
        }
        for (const order of selectedOrders) {
          if (currentOrders[block.id] && currentOrders[block.id].includes(order.id)) {
            message.push(`<li>${order.code} - ${order.description}</li>`);
          } else {
            if (this.labSettings.DefaultToZeroSlides && order?.noBlocks) {
              order.numberOfSlides = 0;
            }
            payload.items.push({
              ...order,
              specimenId: block.specimenId,
              procedureId: order.id,
              panelId: order.macroId,
              procedureDescription: order.description,
              billingTransactionCodeId: order.billingTransactionCodeId,
              holdCodeId: order?.holdCodeId || order?.holdCodes || order?.holdCode,
              blockNum: block.blockNum || order.defaultBlockNum,
              defaultBlockNum: block.blockNum || order.defaultBlockNum,
              detail: this.note || order.detail,
              noBlocks: block?.noBlocks || false
            });
          }
        }
        if (message.length) {
          const targetBlock = this.caseBlocks.find(e => e.id === block.id);
          blockMessages[block.id] = {
            message,
            label: this.displayBlockLabel(targetBlock)
          };
        }
      }
      if (Object.keys(blockMessages).length) {
        await window.alert(`
        <p>The following orders will not be added because they already exist.</p>
          ${Object.keys(blockMessages)
            .map(block => {
              return `
                  <p>${blockMessages[block].label}</p>
                  <ul>${blockMessages[block].message.join("")}</ul>
              `;
            })
            .join("")}
      `);
      }
      if (payload.items.length) {
        try {
          this.isSubmitting = true;
          await this.$store.dispatch("accessionStore/addCaseOrders", payload);
          window.notify(
            `Successfully applied ${this.ordersForSelectedBlocks.length} orders(s) to ${this.selectedBlocks.length} block(s).`
          );
          this.$emit("appliedOrders");
        } catch (error) {
          handleErrors(error);
        } finally {
          this.isSubmitting = false;
        }
      }
    },
    procedureLabel(data) {
      if (this.labSettings.ProcedureSearchBy || data.description === data.code) {
        return data.description;
      }
      return `${data.code} - ${data.description}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.holds-text.text-danger {
  font-weight: 800;
  position: relative;
  .circle {
    display: block;
  }
}
.circle {
  display: none;
  height: 10px;
  width: 10px;
  background-color: $red;
  position: absolute;
  top: 5px;
  left: -12px;
  padding: 2px;
  box-sizing: border-box;
  border-radius: 50%;
  font-weight: 600;
  font-size: 0.75rem;
  // border: 1px solid $secondary;
  animation: pulse-red 2s infinite;
}
#clearIcon {
  position: absolute;
  right: -2px;
  top: -4%;
  cursor: pointer;
}
.header {
  background-color: $gray;
}
.quick-links-header {
  margin-bottom: -5px;
  border-bottom: 0;
}
.text-black {
  color: black;
}
.tags-notes-header {
  font-size: 1rem;
  margin-bottom: 0;
}
.font-weight-medium {
  font-weight: 500;
}
.panel-procedure {
  &::before {
    width: 4px;
    color: black;
  }
}
.list-group-item {
  &:focus {
    border: 2px solid black;
  }
}
.text-xss {
  font-size: 0.5rem;
}
.text-sm {
  font-size: 0.75rem;
}

.btn:focus,
.btn.focus {
  box-shadow: none;
}

.blocks {
  height: 20vh;
  width: 60%;
  overflow-y: auto;
  &-selected {
    max-height: 40vh;
    overflow-y: auto;
  }
}

.order-header {
  margin-bottom: 0;
  &:hover {
    text-decoration: underline;
  }
}

#searchInput {
  width: 300px;
  &::after {
    top: 12%;
    right: 10%;
    position: absolute;
    content: "ALT+K";
    font-weight: 500;
    border: 0.5px solid gray;
    padding: 0.25rem 0.5rem;
    border-radius: 5px;
    color: gray;
  }
  &:focus-within {
    &::after {
      display: none;
    }
  }
}

.grid {
  padding: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, 250px);
  grid-template-rows: repeat(10, auto);
  grid-auto-flow: column;
  grid-auto-columns: 300px;
  column-gap: 1rem;
  row-gap: 0;
  &.panels {
    grid-template-rows: repeat(5, 1.5rem);
  }
}

.procedure-modal {
  width: 70vw;
  font-size: 0.75rem;
  .row {
    height: 100%;
  }
  .blocks {
    width: 100%;
    max-height: 15vh;
    font-size: 1rem;
    overflow-y: auto;
    &-selected {
      max-height: 40vh;
      overflow-y: auto;
    }
  }

  .procedures {
    max-width: 100%;
    padding: 1rem;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .panels {
    overflow-x: auto;
    height: 20vh;
  }
}
.side_info {
  font-size: 1rem;
  // max-height: 25vh;
  h6 {
    color: $primary;
  }
  .side_info_data {
    max-height: 22vh;
    text-transform: uppercase;
    .boxes {
      overflow-y: auto;
    }
    button:hover {
      text-decoration: underline;
    }
  }
}
.panels-list {
  .panels-list-group {
    display: none;
  }
  &:focus-within {
    .panels-list-group {
      display: block;
    }
  }
}
.panel-items {
  padding-left: 1rem;
  .panel-item {
    position: relative;
    margin-right: 1px;
    &::before {
      display: inline-flex;
      align-items: center;
      content: "\25CF";
      padding: 10px 0.5rem 1rem 0;
      padding-bottom: 0;
      color: black;
      position: absolute;
      top: 8px;
      left: -10%;
      // border-left: 1px solid gray;
      margin: -0.875rem 0 1.5rem 5px;
      &:first-of-type {
        margin-top: 0;
      }
      // margin-right: 1px;
    }
  }
}
.selected-panel .specimen_select {
  ::v-deep .custom-control {
    font-size: 0.7rem;
    padding: 0;
    min-height: 0.75rem;
  }
}
</style>
