<template>
  <div>
    <div class="d-flex justify-content-between my-2">
      <h3>Phones</h3>
      <AddBtn
        type="button"
        @click="triggerIsAdding"
        :icon="isAdding ? 'times' : 'plus'"
        :text="isAdding ? 'Close' : 'Add'"
      />
    </div>
    <div v-if="value && value.length">
      <PropTable
        v-model="phones"
        :columns="columns"
        @edit="editPhone"
        @input="$emit('input', $event)"
      />
    </div>
    <form v-if="isAdding || isEditing" @submit.prevent="addPhone" class="col-8 aling-items-center">
      <div class="row">
        <div class="col-1 my-auto">
          <button @click="setPrimary" type="button" class="btn actions icon">
            <template v-if="phone.isPrimary">
              <icon class="text-primary" icon="parking" />
            </template>
            <template v-else>
              <span class="secondary-icon">S</span>
            </template>
          </button>
        </div>
        <SelectInput
          name="phone type"
          class="col-3"
          label="Type"
          v-model="phone.phoneType"
          :items="phoneTypes"
          :validator="$v.phone.phoneType"
        />
        <PhoneInput
          name="phone"
          class="col"
          label="Phone Number"
          maxLength="16"
          v-model="phone.phoneNumber"
          :validator="$v.phone.phoneNumber"
        />
        <TextInput
          name="name"
          type="number"
          class="col-3 extension"
          maxLength="11"
          label="Ext"
          v-model="phone.extension"
          :validator="$v.phone.extension"
        />
      </div>
      <div class="row">
        <TextInput
          name="name"
          class="offset-1 col-4"
          maxLength="101"
          label="Contact Name"
          v-model="phone.contactName"
          :validator="$v.phone.contactName"
        />
      </div>
      <div class="row justify-content-end">
        <button @click="cancelEdit" type="button" class="my-2 mr-1 btn btn-danger">Cancel</button>
        <button type="submit" class="my-2 btn btn-primary">
          {{ isEditing ? "Save" : "Submit" }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import PropTable from "@/components/common/PropTable.vue";
import { required, maxLength, minValue, minLength } from "vuelidate/lib/validators";
import PhoneInput from "@/components/common/PhoneInput";
import AddBtn from "@/components/common/AddButton.vue";
import TextInput from "@/components/common/TextInput.vue";
import Icon from "@/components/common/Icon.vue";
import SelectInput from "@/components/common/SelectInput.vue";
import { mapState } from 'vuex';

export default {
  name: "Phones",
  components: {
    PhoneInput,
    PropTable,
    Icon,
    AddBtn,
    TextInput,
    SelectInput
  },
  props: {
    value: {
      required: true
    },
    types: {
      type: String
    }
  },
  data() {
    return {
      columns: [
        {
          dataField: "isPrimary",
          caption: "Primary"
        },
        { dataField: "contactName" },
        { dataField: "phoneNumber" },
        {
          dataField: "phoneType",
          calculateCellValue: this.displayPhoneType
        },
        { dataField: "extension" }
      ],
      isEditing: false,
      isAdding: false,
      defaultPhone: {
        isPrimary: false,
        contactName: "",
        phoneNumber: "",
        phoneType: "",
        extension: ""
      },
      phone: {
        isPrimary: false,
        contactName: "",
        phoneNumber: "",
        phoneType: "",
        extension: ""
      }
    };
  },
  computed: {
    ...mapState({
      phoneTypes: state => state.dropdowns.locationPhoneTypes
    }),
    phones: {
      get() {
        return this.value;
      },
      set(newVal) {
        return this.$emit("input", newVal);
      }
    }
  },
  validations: {
    phone: {
      phoneType: {
        required
      },
      phoneNumber: {
        required,
        maxLength: maxLength(10),
        minLength: minLength(10)
      },
      contactName: {
        maxLength: maxLength(100)
      },
      extension: {
        maxLength: maxLength(10),
        minValue: minValue(0)
      }
    }
  },
  watch: {
    phone: {
      deep: true,
      handler(value) {
        const primary = this.value.find(e => e.isPrimary);
        if (!primary || !this.value?.length) {
          value.isPrimary = true;
        }
      }
    }
  },
  mounted() {
    this.$store.dispatch("dropdowns/getLocationPhoneTypes");
    if (!this.value?.length) {
      const anyPrimary = this.value?.find(e => e.isPrimary);
      if (!anyPrimary) {
        this.phone.isPrimary = true;
      }
    }
  },
  methods: {
    async triggerIsAdding() {
      if (this.isEditing) {
        const confirm = await window.confirm(
          "You may have unsaved data, are you sure you want to continue?"
        );
        if (!confirm) {
          return;
        }
      }
      this.phone = { ...this.defaultPhone };
      if (this.isAdding) {
        this.isAdding = false;
        this.isEditing = false;
        return;
      }
      this.isAdding = true;
      this.isEditing = false;
    },
    displayPhoneType(data) {
      const phoneType = this.phoneTypes.find(e => e.id === data.phoneType);
      if (phoneType) {
        return phoneType.displayName;
      }
      return "N/A";
    },
    formatPhone(phone) {
      return `${phone.phoneNumber} ${phone.extension ? `Ext: ${phone.extension}` : ``} `;
    },
    addPhone() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        window.notify("Please verify your input and try again.", "warning");

        return;
      }
      if (!this.value?.length) {
        this.phone.isPrimary = true;
      }
      if (!this.isEditing) {
        if (this.value) {
          this.phones = [...this.phones, this.phone];
        } else {
          this.phones = [this.phone];
        }
      }
      this.isEditing = false;
      this.$v.$reset();
      return (this.phone = { ...this.defaultPhone });
    },
    editPhone({ data }) {
      this.isEditing = true;
      this.phone = data;
    },
    async setPrimary() {
      if (this.phone.isPrimary) {
        this.phone.isPrimary = !this.phone.isPrimary;
        return;
      }
      if (this.value?.length) {
        const anyPrimary = this.value.find(e => e?.isPrimary);
        if (anyPrimary) {
          const confirm = await window.confirm(
            "There is already a primary phone number.\n Are you sure you want to continue?"
          );
          if (confirm) {
            anyPrimary.isPrimary = false;
            this.phone.isPrimary = !this.phone.isPrimary;
          }
        } else {
          this.phone.isPrimary = !this.phone.isPrimary;
        }
      } else {
        this.phone.isPrimary = !this.phone.isPrimary;
      }
    },
    cancelEdit() {
      if (this.isEditing) {
        this.isEditing = false;
      }
      this.isAdding = false;
      this.phone = { ...this.defaultPhone };
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-cell {
  width: 10%;
}
table td {
  text-align: center;
  padding: 0;
}

.extension {
  /* Chrome, Safari, Edge, Opera */
  ::v-deep & > .input-group {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
}
</style>
