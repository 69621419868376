<template>
  <div>
    <h2>Results Mode Statuses</h2>
    <Checkbox
      v-for="option of statusOptions"
      v-bind:key="option.id"
      :label="option.displayName"
      :id="option.id"
      :name="option.displayName"
      :value="selectedStatuses.includes(option.id)"
      @input="handleValueChanged(option)"
    />
    <SubmitCancelRow @submit="handleSubmit" @cancel="handleCancel" />
  </div>
</template>

<script>
import { CaseStatusEnum } from "@/modules/enums";
import Checkbox from "./common/Checkbox.vue";
import SubmitCancelRow from "./common/SubmitCancelRow.vue";

export default {
  components: { Checkbox, SubmitCancelRow },
  props: ["statusProp"],
  data() {
    return {
      selectedStatuses: [CaseStatusEnum.Spec, CaseStatusEnum.Gross, CaseStatusEnum.Orders]
    };
  },
  computed: {
    statusOptions() {
      return Object.keys(CaseStatusEnum).map(e => {
        return { id: CaseStatusEnum[e], displayName: e };
      });
    }
  },
  mounted() {
    if (this.statusProp?.length) {
      this.selectedStatuses = this.statusProp;
    }
  },
  methods: {
    handleValueChanged(data) {
      if (this.selectedStatuses.includes(data.id)) {
        this.selectedStatuses = this.selectedStatuses.filter(e => e !== data.id);
      } else {
        this.selectedStatuses.push(data.id);
      }
    },
    handleCancel() {
      this.$emit("cancel");
    },
    handleSubmit() {
      this.$emit("submit", this.selectedStatuses);
    }
  }
};
</script>

<style lang="scss" scoped></style>
