<template>
  <div class="text-input">
    <label v-if="!noLabel" :id="$attrs.id && $attrs.id + 'label'">
      <b>{{ label }}</b>
    </label>
    <div class="input-group">
      <input
        type="text"
        :class="{
          'is-invalid':
            (validator && validator.$model && validator.$invalid) ||
            (validator && validator.$error),
          'is-valid': validator && validator.$model && !validator.$invalid,
          'has-error': validator && validator.$model && validator && validator.$error
        }"
        class="form-control"
        :disabled="disabled"
        :placeholder="placeholder"
        v-model="displayValue"
        @blur="focusOut"
        :aria-label="label"
        v-bind="{ ...$props, ...$attrs }"
        max-length="10"
      />
    </div>
    <div v-if="(validator && validator.$error) || (validator && validator.$invalid)">
      <div
        :class="{ 'd-block': validator.$invalid }"
        class="validation-errors"
        v-for="(key, index) in Object.keys(validator.$params)"
        :key="index"
      >
        <span class="error mb-2" v-if="!validator[key]">
          {{ validatorMsgMap[key] }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { validatorMsgMapBase } from "@/modules/helpers";
export default {
  name: "ZipCodeInput",
  inheritAttrs: false,
  props: {
    label: {
      required: false
    },
    placeholder: {
      required: false
    },
    value: {
      required: false
    },
    disabled: {
      required: false
    },
    noLabel: {
      required: false
    },
    validator: {
      required: false
    },
    validatorMsgMap: {
      type: Object,
      default: () => {
        return validatorMsgMapBase;
      }
    },
  },
  // [
  //   "label",
  //   "placeholder",
  //   "value",
  //   "disabled",
  //   "noLabel",
  //   "validator",
  //   "validatorMsgMap",
  //   "required"
  // ],
  data() {
    return {
      displayValue: this.value
    };
  },
  methods: {
    focusOut(event) {
      this.$emit("blur", event);
    }
  },
  watch: {
    displayValue: function () {
      return this.$emit("input", this.displayValue);
    },
    value: function () {
      this.displayValue = this.value;
    }
  }
};
</script>

<style lang="scss" scoped>
label {
  min-height: 16px;
}
.error {
  font-size: 0.75rem;
}
</style>
