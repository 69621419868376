<template>
  <div class="p-1">
    <DxGridWithSearch
      ref="taskGrid"
      title="Tasks"
      gridName="labTasks"
      :dataSource="dataSource"
      noDataText="No tasks available"
      :columns="columns"
      :selection="selection"
      :toolbar="toolbar"
      @initialized="initGrid"
      :onCellPrepared="redactLogRocket"
    >
      <template v-slot:caseTemplate="{ data: { data } }">
        <router-link v-if="data.caseNumber" :to="`/accession/${data.caseId}`">{{
          data.caseNumber
        }}</router-link>
      </template>
      <template v-slot:extraActions>
        <div class="d-flex">
          <AddBtn v-if="permissions.TaskCreate" @click="createTask" text="Add" />
        </div>
      </template>
      <template v-slot:actions="{ data: { data } }">
        <div class="d-flex">
          <icon-button
            v-if="permissions.TaskChangeStatus && data.assignedTo === currentUser.id"
            v-tooltip.right="data.isCompleted ? 'Complete.' : 'Incomplete.'"
            @click.stop="markComplete(data)"
            :class="[data.isCompleted ? 'text-success' : 'text-danger', 'pointer', '']"
            class="mr-2 p-0"
            :icon="data.isCompleted ? 'check' : 'times'"
          />
          <icon-button
            v-if="
              permissions.TaskEdit &&
              (data.assignedTo === currentUser.id ||
                data.createdBy === currentUser.id ||
                permissions.TaskEditAllUsers)
            "
            class="text-primary pointer mr-2 p-0"
            v-tooltip.right="'Edit task.'"
            @click.stop="editTask(data)"
            icon="pen-alt"
          />
          <icon-button
            v-if="permissions.TaskEdit && data.assignedTo !== currentUser.id"
            class="text-primary pointer mr-2 p-0"
            v-tooltip.right="'Assign to me'"
            @click.stop="assignToCurrentUser(data)"
            icon="user"
          />
          <icon-button
            v-if="
              permissions.TaskDelete &&
              (data.assignedTo === currentUser.id || data.createdBy === currentUser.id)
            "
            v-tooltip.right="'Delete task.'"
            @click.stop="deleteTask(data.taskId)"
            class="text-danger pointer p-0"
            icon="trash-alt"
          />
        </div>
      </template>
      <template v-slot:dateCell="{ data }">
        <div class="align-items-center">
          <icon icon="circle" class="filled_circle" :class="dueDateCell(data)" />
          <b>{{ data.text }}</b>
        </div>
      </template>
    </DxGridWithSearch>
    <modal :status="isFormOpen" @close="isFormOpen = false">
      <TaskForm
        ref="taskForm"
        :caseId="caseId"
        :taskId="currentTask"
        @submit="handleSubmit"
        @cancel="clearTask"
      />
    </modal>
  </div>
</template>

<script>
import TaskForm from "@/components/forms/TaskForm";
import { mapState, mapGetters } from "vuex";
import moment from "moment";
import DxGridWithSearch from "@/components/common/DxGridWithSearch";
import { format } from "date-fns";
import { AuditLogApi, DropdownApi, SettingsApi, TaskApi } from "../services";
import {
  createLogItem,
  createQueryArray,
  filterAccessionNumber,
  filterCellUTC,
  formatDatetimeCell
} from "@/modules/helpers";
import AddBtn from "@/components/common/AddButton.vue";
import DataSource from "devextreme/data/data_source";
import Modal from "./common/Modal.vue";
import IconButton from "./common/IconButton.vue";
import Icon from "./common/Icon.vue";
import { AuditLogItems, TypeCodeGroupEnum } from "@/modules/enums";
import api from "@/services/api";
import eventBus, { UPDATE_TASK_COUNT } from "@/modules/eventBus";
export default {
  name: "TaskManagement",
  components: {
    DxGridWithSearch,
    TaskForm,
    AddBtn,
    Modal,
    IconButton,
    Icon
  },
  props: ["caseId"],
  data() {
    return {
      isFormOpen: false,
      isEditing: false,
      taskGrid: {},
      currentTask: null,
      remoteOperations: {
        filtering: true,
        sorting: true,
        pagination: true
      },
      selection: {
        allowSelectAll: true,
        mode: "multiple",
        selectAllMode: "page",
        showCheckBoxesMode: "always"
      },
      settingsSearchSource: new DataSource({
        store: SettingsApi.typeCodesSearch,
        filter: ["typeId", TypeCodeGroupEnum.TaskPriority]
      }),
      priorities: [],
      tasksList: []
    };
  },
  mounted() {
    if (this.caseId) {
      TaskApi.searchStore.load({ filter: ["caseId", this.caseId] }).then(data => {
        if (!data.length) {
          this.isFormOpen = true;
        }
      });
    }
    TaskApi.markTasksAsViewed();
  },
  created() {
    DropdownApi.getTaskPriorities(this.currentLab).then(res => {
      this.priorities = res || [];
    });
  },
  computed: {
    ...mapState(["currentUser", "currentLab"]),
    ...mapState({
      casesSearchHeader: state => state.applicationSettings.casesSearchHeader,
      taskGridViewAllUsers: state => state.applicationSettings.taskGridViewAllUsers
    }),
    ...mapGetters(["permissions", "isClientUser"]),
    dataSource() {
      const { procedures, casePrefixGroups, holdCodes, pathologists, alertFilter, tags } =
        this.casesSearchHeader;
      const url = `/api/Tasks/search?${
        pathologists?.length ? "&" + createQueryArray(pathologists, "pathologists") : ""
      }${
        casePrefixGroups?.length ? "&" + createQueryArray(casePrefixGroups, "casePrefixGroup") : ""
      }${holdCodes?.length ? "&" + createQueryArray(holdCodes, "holdCodes") : ""}${
        procedures?.length ? "&" + createQueryArray(procedures, "procedures") : ""
      }${tags?.length ? "&" + createQueryArray(tags, "tags") : ""}${
        alertFilter ? "&alertFilter=" + alertFilter : ""
      }`;
      let filter = this.caseId
        ? ["caseId", this.caseId]
        : this.isClientUser
        ? ["createdByEmail", this.currentUser.email]
        : null;
      if (!this.permissions.TaskViewAllUsers && !this.caseId) {
        if (filter) {
          filter = [filter, "and", ["assignedToEmail", this.currentUser.email]];
        } else {
          filter = ["assignedToEmail", this.currentUser.email];
        }
      }
      return new DataSource({
        store: this.caseId ? TaskApi.searchStore : api.createSearch(url, "taskId"),
        filter: filter
      });
    },
    prioritiesHeaderFilter() {
      if (this.priorities.length) {
        return this.priorities?.map(e => {
          return {
            text: e.displayName,
            value: e.id
          };
        });
      }
      return [];
    },
    toolbar() {
      return {
        items: [
          {
            template: "extraActions"
          },
          {
            widget: "dxButton",
            visible: this.permissions.CaseView,
            options: {
              icon: "fa fa-file-excel",
              onClick: this.exportToExcel,
              hint: "Click to export as excel file",
              elementAttr: {
                class: "icon-color"
              },
              text: "Export to Excel"
            },
            locateInMenu: "auto",
            showText: "inMenu"
          },
          {
            widget: "dxButton",
            visible: this.permissions.CaseView,
            options: {
              icon: "fa fa-user",
              onClick: this.assignSelectedToCurrentUser,
              hint: "Click to assign to me",
              elementAttr: {
                class: "icon-color"
              },
              text: "Assign to Me"
            },
            locateInMenu: "auto",
            showText: "inMenu"
          },
          {
            widget: "dxButton",
            visible: this.permissions.CaseView,
            options: {
              icon: "fa fa-check",
              onClick: this.completeSelectedTasks,
              hint: "Click to complete task.",
              elementAttr: {
                class: "icon-color"
              },
              text: "Complete"
            },
            locateInMenu: "auto",
            showText: "inMenu"
          }
        ]
      };
    },
    columns() {
      return [
        {
          caption: "Actions",
          dataField: "isCompleted",
          visibleIndex: 0,
          cellTemplate: "actions",
          dataType: "boolean",
          width: "100px",
          filterType: "includes",
          filterValue: false,
          lookup: {
            displayExpr: "text",
            valueExpr: "value",
            dataSource: [
              {
                value: true,
                text: "Completed"
              },
              {
                value: false,
                text: "Incomplete"
              }
            ]
          }
        },
        {
          dataField: "priority.id",
          dataType: "number",
          caption: "Priority",
          sortOrder: "desc",
          sortIndex: 1,
          lookup: {
            dataSource: this.priorities,
            displayExpr: "displayName",
            valueExpr: "id"
          }
        },
        {
          dataField: "description",
          filterField: "Description",
          caption: "Description",
          dataType: "string"
        },
        {
          dataField: "dueDate",
          caption: "Due Date",
          cellTemplate: "dateCell",
          width: "125px",
          dataType: "date"
        },
        {
          dataField: "pathologistName",
          caption: "Pathologist",
          dataType: "string"
        },
        {
          dataField: "patientName",
          caption: "Patient Name",
          dataType: "string"
        },
        {
          dataField: "caseId",
          caption: "Case #",
          dataType: "string",
          cellTemplate: "caseTemplate",
          calculateFilterExpression: filterAccessionNumber(
            "caseNumber",
            this.$store.state.labSettings.AccessionNumberingType
          )
        },
        {
          dataField: "providerName",
          caption: "Provider",
          dataType: "string"
        },
        {
          dataField: "location",
          caption: "Location",
          dataType: "string"
        },
        {
          dataField: "assignedToEmail",
          caption: "Assigned To",
          dataType: "string",
          allowHeaderFiltering: true,
          filterValue:
            !this.caseId && this.permissions.TaskViewAllUsers && !this.taskGridViewAllUsers
              ? this.currentUser.email
              : null
        },
        {
          dataField: "createdByEmail",
          dataType: "string",
          visible: false,
          caption: "Creator"
        },
        {
          dataField: "createdOn",
          visible: false,
          dataType: "datetime",
          calculateCellValue(data) {
            return formatDatetimeCell(data.createdOn);
          },
          calculateFilterExpression: filterCellUTC("createdOn")
        }
      ];
    }
  },
  methods: {
    initGrid({ component }) {
      this.taskGrid = component;
    },
    handleCellClick({ columnIndex, data }) {
      if (columnIndex > 0 && data && data.caseId) {
        window.scrollTo(0, 0);
        this.$router.push({
          name: "CaseView",
          params: { caseId: data.caseId }
        });
      }
    },
    assignSelectedToCurrentUser() {
      const selectedTasks = this.taskGrid.getSelectedRowsData();
      if (!selectedTasks.length) {
        window.notify("No selected tasks found!", "warning");
        return;
      }
      const taskNotAssignedToMe = selectedTasks.filter(e => e.assignedTo !== this.currentUser.id);
      taskNotAssignedToMe.forEach(task => this.assignToCurrentUser(task));
      eventBus.$emit(UPDATE_TASK_COUNT);
    },
    completeSelectedTasks() {
      const selectedTasks = this.taskGrid.getSelectedRowsData();
      if (!selectedTasks.length) {
        window.notify("No selected tasks found!", "warning");
        return;
      }
      const incompleteTasksAssignedToCurrentUser = selectedTasks.filter(
        e => e.assignedTo === this.currentUser.id && !e.isCompleted
      );
      incompleteTasksAssignedToCurrentUser.forEach(task => this.markComplete(task));
    },
    async assignToCurrentUser(data) {
      const task = await TaskApi.getTaskById(data.taskId);
      await TaskApi.updateTask({
        ...task,
        assignedToUserId: this.currentUser.id
      });
      window.notify(`Success, updating task.`);
      const logMessage = createLogItem({}, AuditLogItems.Other);
      logMessage.comments = `Assigned task ${task.id} to ${this.currentUser.displayName}(${this.currentUser.email})\n Description - ${task.description}`;
      AuditLogApi.insertLogMessage(logMessage);
      this.taskGrid.refresh();
    },
    exportToExcel() {
      this.taskGrid.beginUpdate();
      this.taskGrid.columnOption("createdByEmail", "visible", true);
      this.taskGrid.columnOption("createdOn", "visible", true);
      this.taskGrid.on({
        exporting(data) {
          data.fileName = `${format(new Date(), "MM/dd/yyy")}_Tasks_Grid`;
          return data;
        }
      });
      this.taskGrid.exportToExcel(false);
      this.taskGrid.columnOption("createdByEmail", "visible", false);
      this.taskGrid.columnOption("createdOn", "visible", false);
    },
    markComplete(data) {
      data.isCompleted = !data.isCompleted;
      const task = {
        id: data.taskId,
        isCompleted: data.isCompleted,
        isDeleted: false,
        priority: data.priority.id,
        description: data.description,
        caseId: data.caseId,
        dueDate: data.dueDate,
        assignedToUserId: data.assignedTo
      };
      return TaskApi.updateTask(task).then(res => {
        if (res.isCompleted === data.isCompleted) {
          this.taskGrid.refresh(true);
          eventBus.$emit(UPDATE_TASK_COUNT);
        }
      });
    },
    clearTask() {
      this.currentTask = null;
      this.isEditing = false;
      this.isFormOpen = false;
    },
    async editTask(data) {
      this.currentTask = data.taskId;
      this.isEditing = true;
      this.isFormOpen = true;
    },
    createTask() {
      this.currentTask = null;
      this.isFormOpen = true;
    },
    handleSubmit() {
      this.clearTask();
      eventBus.$emit(UPDATE_TASK_COUNT);
      return this.taskGrid.refresh();
    },
    async deleteTask(id) {
      const confirm = await window.confirm(`Are you sure you want to delete this task?`);
      if (confirm) {
        return TaskApi.deleteTask(id).then(() => {
          this.taskGrid.refresh(true);
          eventBus.$emit(UPDATE_TASK_COUNT);
        });
      }
      return;
    },
    dueDateCell({ data, text }) {
      const classObj = { red: false, yellow: false, blue: false, green: false };
      if (data.isCompleted) {
        classObj.blue = true;
        return classObj;
      }
      let cellDate = "";
      if (data.dueDate instanceof Date) {
        cellDate = data.dueDate.setHours(0, 0, 0, 0);
      } else {
        cellDate = new Date(text).setHours(0, 0, 0, 0);
      }
      const today = new Date().setHours(0, 0, 0, 0);
      const difference = moment(cellDate).diff(today);
      if (difference < 0) {
        classObj.red = true;
      } else if (difference === 0) {
        classObj.yellow = true;
      } else if (difference > 0) {
        classObj.green = true;
      }
      return classObj;
    }
  }
};
</script>

<style lang="scss" scoped>
.task-header {
  font-weight: 600;
  font-size: 0.8rem;
  color: black;
  text-align: left;
}
.dx-datagrid-search-panel {
  width: 300px !important;
}
.dx-header-filter {
  color: $primary !important;
}
.filled_circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0.2rem 0.5rem;
  &.yellow {
    color: yellow;
  }
  &.blue {
    color: blue;
  }
  &.red {
    color: red;
  }
  &.green {
    color: green;
  }
}
.dx-row {
  cursor: pointer !important;
}
.pointer {
  width: 25px;
}
</style>
