import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { dom } from "@fortawesome/fontawesome-svg-core";
dom.watch();

import {
  faFileAlt,
  faSearchPlus,
  faSearchMinus,
  faChevronRight,
  faMinus,
  faCalendar,
  faHammer,
  faCopy,
  faBolt,
  faInfoCircle,
  faCogs,
  faVial,
  faExchangeAlt,
  faUserPlus,
  faFileUpload,
  faClipboardCheck,
  faUsers,
  faSort,
  faCameraRetro,
  faFileCsv,
  faUserSecret,
  faTrashAlt,
  faChevronDown,
  faPenAlt,
  faSearchLocation,
  faBan,
  faParking,
  faHandPaper,
  faPlus,
  faChevronUp,
  faCheck,
  faTimes,
  faHome,
  faCog,
  faCaretDown,
  faSearch,
  faExclamationTriangle,
  faCircleNotch,
  faBriefcase,
  faServer,
  faItalic,
  faBold,
  faUnderline,
  faStrikethrough,
  faListUl,
  faListOl,
  faUndo,
  faRedo,
  faParagraph,
  faFlask,
  faSlash,
  faExpandAlt,
  faFilePdf,
  faFilter,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faUmbrella,
  faHistory,
  faClock,
  faCircle,
  faUser,
  faHospitalAlt,
  faEdit,
  faWindowRestore,
  faEye,
  faPrint,
  faFax,
  faDownload,
  faFileExcel,
  faEyeSlash,
  faUsersSlash,
  faSave,
  faExclamationCircle,
  faCaretRight,
  faCaretLeft,
  faArrowUp,
  faCaretUp,
  faSync,
  faArrowDown,
  faArrowRight,
  faArrowLeft,
  faSignOutAlt,
  faExpandArrowsAlt,
  faUnlink,
  faLink,
  faEnvelope,
  faHSquare,
  faFileImage,
  faProjectDiagram,
  faCheckCircle,
  faTimesCircle,
  faIdBadge,
  faUserMd,
  faMapPin,
  faLaptopMedical,
  faBirthdayCake,
  faFemale,
  faMale,
  faEject,
  faClinicMedical,
  faPhone,
  faPen,
  faShareAlt,
  faArrowsAlt,
  faLock,
  faLockOpen,
  faExternalLinkAlt,
  faCalendarCheck,
  faBook,
  faUserCheck,
  faBarcode
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faMale,
  faEject,
  faMinus,
  faFileCsv,
  faFemale,
  faClinicMedical,
  faBirthdayCake,
  faLaptopMedical,
  faMapPin,
  faProjectDiagram,
  faSignOutAlt,
  faChevronRight,
  faInfoCircle,
  faBan,
  faSearchMinus,
  faIdBadge,
  faUserMd,
  faSearchPlus,
  faBolt,
  faCaretUp,
  faArrowRight,
  faArrowLeft,
  faCaretRight,
  faCaretLeft,
  faExclamationCircle,
  faCogs,
  faUsersSlash,
  faSave,
  faCopy,
  faHandPaper,
  faEyeSlash,
  faFileExcel,
  faCameraRetro,
  faDownload,
  faFax,
  faEnvelope,
  faPrint,
  faHospitalAlt,
  faUndo,
  faRedo,
  faUser,
  faHistory,
  faUmbrella,
  faExchangeAlt,
  faClipboardCheck,
  faVial,
  faUserPlus,
  faUsers,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faSort,
  faFilter,
  faFilePdf,
  faFileImage,
  faCircle,
  faFileAlt,
  faSort,
  faClock,
  faSync,
  faSlash,
  faFlask,
  faFileUpload,
  faParagraph,
  faBold,
  faUnderline,
  faStrikethrough,
  faFileAlt,
  faListUl,
  faListOl,
  faChevronUp,
  faUserSecret,
  faPenAlt,
  faChevronDown,
  faTrashAlt,
  faParking,
  faPlus,
  faCheck,
  faTimes,
  faCalendar,
  faHome,
  faHSquare,
  faCog,
  faCaretDown,
  faSearch,
  faExclamationTriangle,
  faCircleNotch,
  faPhone,
  faCircle,
  faBriefcase,
  faFileAlt,
  faServer,
  faHammer,
  faItalic,
  faExpandAlt,
  faEdit,
  faWindowRestore,
  faEye,
  faArrowUp,
  faArrowDown,
  faSearchLocation,
  faExpandArrowsAlt,
  faUnlink,
  faLink,
  faCheckCircle,
  faTimesCircle,
  faPen,
  faShareAlt,
  faArrowsAlt,
  faLock,
  faLockOpen,
  faExternalLinkAlt,
  faCalendarCheck,
  faBook,
  faUserCheck,
  faBarcode
);

export default FontAwesomeIcon;
