<template>
  <div>
    <h2>Reset Grids</h2>
    <div v-for="gridName of savedGrids" v-bind:key="gridName">
      <Checkbox
        :value="gridsToReset.includes(gridName)"
        :label="generateDisplayName(gridName)"
        :id="gridName"
        :name="gridName"
        @input="handleCheckbox(gridName)"
      />
    </div>
    <SubmitCancelRow submitText="Reset" @submit="handleSubmit" @cancel="handleCancel" />
  </div>
</template>

<script>
import SubmitCancelRow from "@/components/common/SubmitCancelRow.vue";
import { mapState } from "vuex";
import Checkbox from "./common/Checkbox.vue";

export default {
  components: { SubmitCancelRow, Checkbox },
  data() {
    return {
      gridsToReset: [],
      savedGrids: []
    };
  },
  mounted() {
    this.savedGrids = Object.keys(this.grids).sort();
  },
  computed: {
    ...mapState({
      grids: state => state.grids
    })
  },
  methods: {
    handleCancel() {
      this.$emit("close");
    },
    handleSubmit() {
      for (const gridName of this.gridsToReset) {
        this.$store.dispatch("grids/resetGrid", gridName);
      }
      window.notify("Reset grids.");
      this.$emit("close");
    },
    handleCheckbox(gridName) {
      if (this.gridsToReset.includes(gridName)) {
        this.gridsToReset = this.gridsToReset.filter(e => e !== gridName);
      } else {
        this.gridsToReset.push(gridName);
      }
    },
    generateDisplayName(gridName) {
      const result = gridName.replace(/([A-Z])/g, " $1");
      const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      return finalResult;
    }
  }
};
</script>

<style lang="scss" scoped></style>
