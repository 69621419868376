<template>
  <div class="d-flex flex-column align-items-center p-2">
    <div>
      <h2>{{ reportName }}</h2>
    </div>
    <form class="container m-1" ref="reportParamsForm" @submit.prevent="submitReportParameters">
      <div class="row mx-auto flex-wrap">
        <div class="mx-2 row row-wrap" :key="param.Name" v-for="param in reportParams">
          <component
            v-bind="createParamField(param)"
            :is="createParamField(param).is"
            :ref="param.Name"
          />
          <div v-if="param && param.Nullable" class="form-group align-self-start">
            <Checkbox
              v-tooltip="'Set to null'"
              type="checkbox"
              class="form-control-sm mx-2 mt-2"
              :data-param="param.Name"
              :id="param.Name + 'nullable'"
              :name="param.Name + 'nullable'"
              v-model="nullableParams[param.Name]"
            />
          </div>
          <div class="w-100" v-else>
            <small>This param is required.</small>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end align-items-center px-3">
        <loader size="small" class="mr-2" v-show="isLoading" />
        <button class="btn btn-primary" type="submit">Submit</button>
      </div>
    </form>
    <div class="rounded w-100 viewer">
      <pdf-viewer
        v-if="!error && reportUrl"
        :url="reportUrl"
        @close="handleClose"
        @changePage="handleChangePage"
        @print="handlePrint"
        @download="handlePdfDownload"
        :fileName="reportName"
      >
        <template v-slot:customButtons>
          <icon-button class="text-white" icon="file-excel" @click="exportReport('EXCELOPENXML')" />
        </template>
      </pdf-viewer>
    </div>
  </div>
</template>

<script>
import { camelCase } from "lodash";
import { mapGetters, mapState } from "vuex";
import PdfViewer from "@/components/common/PDFViewer.vue";
import { AuditLogApi, DropdownApi, MacrosApi, ReportsApi } from "@/services/index";
import { createLogItem } from "@/modules/helpers";
import DatePicker from "@/components/common/DatePicker.vue";
import TextInput from "@/components/common/TextInput.vue";
import Checkbox from "@/components/common/Checkbox.vue";
import Timepicker from "@/components/common/TimePicker.vue";
import Loader from "@/components/common/Loader.vue";
import IconButton from "../common/IconButton.vue";
import moment from "moment";
import { handleErrors } from "@/modules/handleErrors";

//Multiple components are rendererd dynamically in the template.
// createParamField is used to create the component for each parameter.
import SelectInput from "@/components/common/SelectInput.vue";
import MultiSelectInput from "@/components/common/TagInput.vue";
import { FileFormatEnum, MacroTypeEnum } from "@/modules/enums";
import DataSource from "devextreme/data/data_source";

export default {
  components: {
    DatePicker,
    TextInput,
    Loader,
    PdfViewer,
    IconButton,
    SelectInput,
    Timepicker,
    Checkbox,
    MultiSelectInput
  },
  name: "DailyReportViewer",
  props: {
    reportId: Number,
    reportName: String
  },
  data() {
    return {
      isLoading: false,
      reportParams: [],
      imgUrl: "",
      loadedRatio: 0,
      page: 1,
      numPages: null,
      rotate: 0,
      error: "",
      reportUrl: "",
      reportProperties: {},
      items: [],
      nullableParams: {}
    };
  },
  computed: {
    ...mapState({
      reportViewer: state => state.report,
      pdfZoom: state => state.applicationSettings.pdfZoom,
      accessionMode: state => state.accessionMode,
      reportCaseId: state => state.report.caseId,
      currentCase: state => state.accessionStore.caseHeader,
      pathReportLocation: state => state.applicationSettings.pathReportLocation,
      token: state => state.token,
      isMobileView: state => state.isMobileView,
      currentLab: state => state.currentLab,
      currentUser: state => state.currentUser
    }),
    ...mapGetters("accessionStore", ["caseStatus"]),
    ...mapGetters("report", ["supportsPDF"]),
    zoom: {
      get() {
        return this.pdfZoom;
      },
      set(value) {
        this.$store.commit("applicationSettings/setPdfZoom", value);
        return value;
      }
    },
    customReportParams() {
      //New list of custom parameters for special report fields
      return {
        protocolId: {
          is: "SelectInput",
          displayExpr: "macroName",
          value: [],
          valueExpr: "macroId",
          dataSource: new DataSource({
            store: MacrosApi.searchStore,
            filter: ["macroTypeId", "=", MacroTypeEnum.Protocol]
          })
        },
        tagIds: {
          is: "MultiSelectInput",
          dataSource: new DataSource({
            store: DropdownApi.searchTags,
            filter: ["settingType", "T"]
          }),
          displayExpr: "displayName",
          valueExpr: "id",
          value: [],
          style: {
            minWidth: "200px"
          }
        },
        noteIds: {
          is: "MultiSelectInput",
          dataSource: new DataSource({
            store: DropdownApi.searchTags,
            filter: ["settingType", "N"]
          }),
          displayExpr: "displayName",
          valueExpr: "id",
          value: []
        }
      };
    }
  },
  watch: {
    reportId: {
      immediate: true,
      handler(nv) {
        if (nv) {
          this.getReportParams(nv);
        }
      }
    }
  },
  methods: {
    async handleChangePage(page) {
      if (page + this.page > -1) {
        if (this.numPages && this.page + page > this.numPages) {
          return;
        }
        this.page += page;
        const report = await ReportsApi.getLabReport({ ...this.reportProps, startPage: this.page });
        const blob = new Blob([report], {
          type: !this.supportsPDF ? "image/jpeg" : "application/pdf"
        });
        const url = URL.createObjectURL(blob);
        const doesImageExist = await this.doesImageExist(url);
        if (doesImageExist) {
          this.reportUrl = url;
        } else {
          //Capture the last page & revert the amount of pages increased
          this.numPages = this.page;
          this.page -= page;
        }
      }
    },
    async getReportParams(reportId) {
      try {
        this.isLoading = true;
        this.reportParams = [];
        const response = await ReportsApi.getReportParameters(reportId);
        if (typeof response === "string") {
          const reportParams = JSON.parse(response);
          if (Array.isArray(reportParams)) {
            this.reportParams = reportParams.filter(
              e => e.Name !== "LabId" && e.ParameterVisibility === "Visible"
            );
          }
        }
      } catch (error) {
        handleErrors(error);
        this.$emit("close");
      } finally {
        this.isLoading = false;
      }
    },
    createParamField(parameter) {
      const props = {
        name: parameter.Name,
        label: parameter.Prompt,
        id: parameter.Name,
        ref: parameter.Name
      };
      if (parameter.Nullable) {
        props.value = null;
        this.nullableParams[parameter.Name] = parameter.DefaultValuesIsNull;
        if (parameter.DefaultValuesIsNull) {
          props.disabled = true;
        }
      } else {
        props.required = true;
      }
      switch (parameter.ParameterType) {
        case "DateTime":
          props.is = "DatePicker";
          if (/time/i.test(parameter.Name)) {
            props.is = "Timepicker";
            props.type = "datetime";
            props.dateSerializationFormat = "yyyy-MM-ddTHH:mm:ssZ";
          }
          break;
        case "Integer":
          props.is = "TextInput";
          props.type = "number";
          props.class = "text-input-width";
          break;
        default:
          props.is = "TextInput";
          props.type = "text";
          props.class = "text-input-width";
          break;
      }

      if (parameter.MultiValue) {
        props.is = "MultiSelectInput";
        props.value = [];
        props.class = "text-input text-input-width";
        props.dropDownOptions = { resizeEnabled: true };
      }
      //If defaultValues is not null, then set the value to the default value.
      if (!parameter.DefaultValuesIsNull && parameter.ParameterState === "HasValidValue") {
        if (parameter.DefaultValues && !parameter.MultiValue) {
          props.value = parameter.DefaultValues[0];
        } else {
          props.value = parameter.DefaultValues;
        }
      }

      if (parameter.ValidValuesIsNull === false) {
        props.valueExpr = "Value";
        props.displayExpr = "Label";
        props.dataSource = parameter.ValidValues;
        if (props.is === "TextInput" && parameter.ValidValues?.length > 0) {
          props.is = "SelectInput";
          props.value = parameter.ValidValues[0].Value;
          if (this.nullableParams[parameter.Name]) {
            this.nullableParams[parameter.Name] = false;
          }
        }
      }

      //If the parameter is a custom parameter that valid values are based on the current lab.
      let camelCaseParamName = camelCase(parameter.Name);
      //Absolute special case for the noteIDs which is a specific data source from the tags typecode.
      if (parameter.Prompt === "Note Ids") {
        camelCaseParamName = "noteIds";
      }
      if (this.customReportParams[camelCaseParamName]) {
        return { ...props, ...this.customReportParams[camelCaseParamName] };
      }
      return props;
    },
    async getReport(props) {
      try {
        this.isLoading = true;

        const report = await ReportsApi.getLabReport(props);

        await AuditLogApi.insertLogMessage({
          ...createLogItem({}, 16),
          comments: JSON.stringify({ name: `Viewed ${this.reportName} report`, ...props })
        });

        const blob = new Blob([report], {
          type: this.supportsPDF ? "application/pdf" : "image/jpeg"
        });
        const url = URL.createObjectURL(blob);
        this.reportUrl = url;
      } catch (error) {
        handleErrors(error);
      } finally {
        this.isLoading = false;
      }
    },
    async exportReport(format) {
      try {
        const formatFileTypes = {
          PDF: "pdf",
          XML: "xml",
          MHTML: "mhtml",
          EXCEL: "xls",
          EXCELOPENXML: "xlsx",
          IMAGE: "jpeg",
          WORD: "docx",
          HTML: "html"
        };
        this.isLoading = true;
        const payload = { ...this.reportProps, format, outputFormat: formatFileTypes[format] };
        const report = await ReportsApi.getLabReport(payload);
        await this.handlePdfDownload();

        const blob = new Blob([report]);
        const url = URL.createObjectURL(blob);

        const anchor = document.createElement("a");
        anchor.href = url;
        const today = moment(new Date()).format("MM-DD-YY hh:mm A");
        anchor.download = `${today}-${this.reportName}.${formatFileTypes[format]}`;
        anchor.click();
      } catch (error) {
        window.notify("An error occured.", "error");
      } finally {
        this.isLoading = false;
      }
    },
    doesImageExist(url) {
      return new Promise(resolve => {
        const img = new Image();
        img.src = url;
        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
      });
    },
    handleClose() {
      this.$emit("close");
    },
    handlePdfDownload() {
      return AuditLogApi.insertLogMessage({
        ...createLogItem({}, 16),
        comments: `Downloaded ${this.reportName} report pdf`
      });
    },
    handlePrint() {
      AuditLogApi.insertLogMessage({
        ...createLogItem({}, 16),
        comments: `Printed ${this.reportName} report pdf`
      });
    },
    submitReportParameters(event) {
      const props = {
        format: this.supportsPDF ? FileFormatEnum.PDF : FileFormatEnum.IMAGE, //Report should be an image.
        outputFormat: this.supportsPDF ? "pdf" : "jpeg",
        reportId: this.reportId,
        startPage: this.supportsPDF ? 0 : 1
      };
      props.parameters = this.getParametersFromForm(event.target);
      for (const param of props.parameters) {
        const reportParam = this.reportParams.find(p => p.Name === param.Name);
        if (param.Value === null || param.Value === undefined || param.Value === "NaN") {
          return window.alert(`Please select a value for ${reportParam.Prompt}.`);
        }
      }

      this.reportProps = props;
      return this.getReport(props);
    },
    getParametersFromForm(formEl) {
      const formValues = new FormData(formEl);
      const formValuesObject = Object.fromEntries(formValues.entries());
      const parameters = [];
      for (const reportParam of this.reportParams) {
        const param = {
          Name: reportParam.Name,
          Value: formValuesObject[reportParam.Name]
        };
        if (reportParam.Nullable || reportParam.DefaultValuesIsNull) {
          const isDisabled = this.nullableParams[reportParam.Name];
          if (isDisabled) {
            param.Value = null;
            continue;
          }
        }

        // If it is a multi value parameter, wn convert the selected options to an array.
        if (reportParam.MultiValue) {
          const selectElement = formEl.querySelector(`select[name="${reportParam.Name}"]`);
          if (selectElement != null && selectElement.length > 0) {
            param.Value = Array.from(selectElement.selectedOptions)
              .reduce((acc, curr, idx) => {
                if (idx === 0) {
                  return [curr.value];
                } else {
                  return [...acc, `&${param.Name}=${curr.value}`];
                }
              }, [])
              .join("");
          } else {
            param.Value = null;
          }
        }

        parameters.push(param);
      }
      return parameters;
    }
  }
};
</script>

<style lang="scss" scoped>
.loading-mode {
  height: 100%;
  &.windowed {
    height: 100vh;
    background-color: $primary-darker;
  }
}
.text-input-width {
  width: 100%;
}
</style>
