<template>
  <div class="main-view">
    <ReportGroups
      class="mx-1 group-sidebar"
      @CHANGE_GROUP="handleGroupFilter"
      v-if="!isLabClientUser"
    />
    <ReportsList v-if="isMobileView" />
    <ReportsGrid v-else :selectedGroup="selectedGroup" />
  </div>
</template>

<script>
import ReportsGrid from "@/components/ReportsGrid";
import ReportGroups from "@/components/Sidebars/ReportGroups.vue";
import ReportsList from "@/components/ReportsList.vue";
import { mapState } from "vuex";
export default {
  name: "Reports",
  components: {
    ReportsGrid,
    ReportGroups,
    ReportsList
  },
  metaInfo: {
    title: "Reports",
    titleTemplate: "IntelliPath - %s"
  },
  data() {
    return {
      selectedGroup: null
    };
  },
  computed: {
    ...mapState({
      isMobileView: state => state.isMobileView,
      isLabClientUser: state => state.currentUser.isLabClientUser
    })
  },
  methods: {
    handleGroupFilter(selectedGroup) {
      this.selectedGroup = selectedGroup;
    }
  }
};
</script>

<style lang="scss" scoped>
.main-view {
  overflow: hidden;
  flex-grow: 1;
  display: flex;
}
.group-sidebar {
  display: none;
}
@media screen and (min-width: 1025px) {
  .group-sidebar {
    display: block;
  }
}
</style>
