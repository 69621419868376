<template>
  <div>
    <label><b>Hold Codes</b></label>
    <DxTagBox
      :value="value"
      placeholder="Select hold codes..."
      :show-selection-controls="true"
      search-expr="displayName"
      display-expr="displayName"
      :data-source="dataSource"
      :maxDisplayTags="5"
      :showDropDownButton="true"
      :search-enabled="true"
      :hideSelectedItems="true"
      @value-changed="onValueChanged"
      :dropdownOptions="dropdownOptions"
      v-bind="$attrs"
    >
    </DxTagBox>
  </div>
</template>

<script>
import DropDownApis from "../../../services/dropdown.js";
import DxTagBox from "devextreme-vue/tag-box";
import { mapState } from "vuex";
import DataSource from "devextreme/data/data_source";

export default {
  name: "Tag",
  inheritAttrs: false,
  components: {
    DxTagBox
  },
  props: {
    value: {
      required: true
    }
  },
  data: () => ({
    dropdownOptions: {
      position: "bottom"
    }
  }),
  methods: {
    onValueChanged(e) {
      this.holdCodes = e.value;
    },
    calculateDisplay(data) {
      return data.displayName || data.name;
    }
  },
  computed: {
    ...mapState(["currentUser", "currentLab"]),
    dataSource() {
      const { labId } = this.currentUser;
      let holdCodeSource = [];
      if (labId === null) {
        holdCodeSource = DropDownApis.createSearch("/holdcodes/search", this.currentLab);
      } else {
        holdCodeSource = DropDownApis.searchHoldCodes;
      }
      return new DataSource({
        store: holdCodeSource,
        sort: ["displayName"]
      });
    },
    holdCodes: {
      get() {
        return this.value;
      },
      set(value) {
        return this.$emit("input", value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.custom__tag {
  background-color: $neutral;
  color: #35495e;
}
</style>
