<template>
  <h2>
    {{ title }}
    <slot></slot>
  </h2>
</template>

<script>
export default {
  props: ["title"]
};
</script>

<style lang="scss" scoped></style>
