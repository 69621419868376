export default function launchImage(
  url,
  applicationHandler = '{"target": "_self", "protocol": "https", "width": 800, "height": 600}',
  idx = null
) {
  if (url) {
    let launchUrl = new URL(url);
    let target = "_blank";
    let protocol = "https";
    let features = "";
    if (typeof applicationHandler === "string") {
      try {
        const handlerDetails = JSON.parse(applicationHandler);
        if (handlerDetails?.target) {
          target = handlerDetails.target;
          if (target === "slideImageWindow") {
            const { width, height } = handlerDetails;
            if (width && height) {
              features = `width=${width},height=${height},scrollbars=yes,resizable=yes,toolbar=no,location=no,menubar=no,status=no`;
            }
          }
        }
        if (handlerDetails?.protocol) {
          protocol = handlerDetails.protocol;
        }
      } catch (e) {
        console.error("Error parsing SlideImagesApplicationHandler", e);
      }
    }
    // ! This is a url constructor for launching the 3D Slide Center Case Viewer
    if (protocol === "mview") {
      const params = launchUrl.searchParams;
      const slideId = params.get("SlideID");
      //Remove the http protocol from url
      const hub = launchUrl.hostname;
      window.open(`mview:OfflTelSlide?HUBIP=${hub}&HUBSecure=false&SldID=${slideId}`, target);
    } else {
      if (idx) {
        target += idx;
        features = "";
      }
      if (features) {
        window.open(launchUrl, target, features);
      } else {
        window.open(launchUrl, target);
      }
    }
  }
}
