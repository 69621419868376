<template>
  <dx-data-grid
    :show-borders="true"
    :show-row-lines="true"
    :loadPanel="loadPanel"
    :filterRow="filterRow"
    :paging="paging"
    :sorting="sorting"
    :headerFilter="headerFilter"
    :scrolling="scrolling"
    :grouping="grouping"
    width="100%"
    class="p-2"
    :selection="selection"
    :hoverStateEnabled="true"
    :height="$attrs.height"
    @row-click="forwardEvent('row-click', $event)"
    @initialized="initializeGrid"
    @update:focusedRowIndex="forwardEvent('focusedRowIndex', $event)"
    @key-down="forwardEvent('keyDown', $event)"
    ref="grid"
    @content-ready="handleContentReady"
    :filter-value="filterValue"
    :row-alternation-enabled="rowAlternate"
    :data-source="dataSource"
    :remoteOperations="remoteOperations"
    :column-auto-width="true"
    :selection-filter="selectionFilter"
    :allow-column-resizing="true"
    columnResizingMode="widget"
    :allow-column-reordering="allowColumnReordering"
    :editing="editing"
    @selection-changed="forwardEvent('selection-changed', $event)"
    @cell-click="forwardEvent('cell-click', $event)"
    @cell-prepared="onCellPrepared"
    @exporting="forwardEvent('exporting', $event)"
    :noDataText="noDataText"
    :selected-row-keys="selectedRowKeys"
    :stateStoring="stateStoring"
    :pager="pager"
    :filter-sync-enabled="filterValueSync"
    :searchPanel="searchPanel"
    :customizeColumns="customizeColumns"
    :columnChooser="columnChooserObject"
    :export="exportConfig"
    :onEditorPreparing="onEditorPreparing"
    :onCellClick="onCellClick"
    :onCellDblClick="onCellDblClick"
    @option-changed="onOptionChanged"
    @focusedCellChanged="onFocusedCellChanged"
    :cacheEnabled="false"
  >
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
    <template v-slot:gridTitle>
      <page-title v-show="title">{{ title }}</page-title>
    </template>
    <DxToolbar>
      <DxItem v-for="(item, idx) of toolbarProp.items" v-bind="item" :key="'toolbar' + idx" />
    </DxToolbar>
    <DxColumn
      v-for="(column, i) of columns"
      :key="column.dataField || 'column' + i"
      v-bind="column"
    />
  </dx-data-grid>
</template>

<script>
import { DxDataGrid, DxToolbar, DxItem, DxColumn } from "devextreme-vue/data-grid";
import { mapState } from "vuex";
import PageTitle from "./PageTitle.vue";
import { map, take, filter } from "rxjs/operators";
import logrocketRedact from "@/mixins/logrocketRedact";
import { addCommasToCount } from "@/modules/helpers";

export default {
  name: "DataGrid",
  mixins: [logrocketRedact],
  props: {
    columnChooser: {
      type: Boolean,
      default() {
        return true;
      }
    },
    customizeColumns: {
      type: Function
    },
    selectedRowKeys: {
      type: Array
    },
    title: String,
    columns: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    },
    toolbar: {
      default() {
        return {};
      }
    },
    filterValue: {
      default() {
        return [];
      }
    },
    filterValueSync: {
      default: false
    },
    rowAlternate: {
      default() {
        return false;
      }
    },
    gridName: {
      default() {
        return null;
      }
    },
    columnResize: {
      type: Boolean,
      default: () => false
    },
    persistState: {
      type: Boolean,
      default() {
        return false;
      }
    },
    dataSource: {
      required: true
    },
    pageSize: {
      type: Number,
      default() {
        return 10;
      }
    },
    keyExpr: {
      type: String
    },
    refkey: {
      type: String
    },
    selection: {
      type: Object,
      default() {
        return {
          mode: "multiple",
          allowSelectAll: true,
          showCheckBoxesMode: "always",
          selectAllMode: "page"
        };
      }
    },
    noDataText: {
      type: String
    },
    editing: {
      type: Object,
      default() {
        return {
          allowUpdating: false,
          allowAdding: false,
          allowDeleting: false,
          mode: "row"
        };
      }
    },
    selectionFilter: {
      default: () => []
    },
    grouping: {
      default: () => null
    },
    fixedFilters: {
      type: Array,
      default() {
        return [];
      }
    },
    searchPanel: {
      default() {
        return {
          width: 300,
          visible: true
        };
      }
    },
    searchInput: {
      default() {
        return true;
      }
    },
    scrolling: {
      default() {
        return {
          showScrollbar: "always",
          useNative: true
        };
      }
    },
    remoteOperations: {
      default() {
        return {
          filtering: true,
          sorting: true,
          paging: true,
          grouping: false
        };
      }
    },
    exportConfig: {
      default() {
        return {
          enabled: false
        };
      }
    },
    cellPrepared: {
      default() {
        return;
      }
    },
    pager: {
      default() {
        return {
          allowedPageSizes: [5, 10, 15, 25, 50],
          showInfo: true,
          infoText: "Page {0} of {1} ({2} Total)"
        };
      }
    },
    onEditorPreparing: {
      default() {
        return;
      }
    },
    onCellClick: {
      default() {
        return;
      }
    },
    onCellDblClick: {
      default() {
        return;
      }
    },
    onOptionChanged: {
      default() {
        return () => {
          return;
        };
      }
    },
    onFocusedCellChanged: {
      default() {
        return;
      }
    },
    allowColumnReordering: {
      default: true
    },
    showFilterRow: {
      default: true
    },
    isLoading: {
      default: false
    }
  },
  components: {
    DxDataGrid,
    PageTitle,
    DxToolbar,
    DxColumn,
    DxItem
  },

  data() {
    return {
      searchValue: "",
      pageOffset: 0,
      grid: {},

      sorting: {
        mode: "multiple"
      },
      headerFilter: {
        visible: true
      },
      filterRow: {
        visible: this.showFilterRow
      },
      loadPanel: {
        enabled: true
      }
    };
  },
  domStreams: ["contentReady$"],
  subscriptions() {
    const searchPanel$ = this.contentReady$.pipe(
      take(1),
      filter(() => this.searchPanel?.visible),
      map(({ element }) => {
        const searchPanel = element.querySelector('input[type="text"]');
        if (searchPanel) {
          searchPanel.setAttribute("id", "searchPanel");

          searchPanel.focus();
        }
        return searchPanel;
      })
    );
    return { searchPanel$ };
  },
  computed: {
    ...mapState(["currentLab", "currentUser"]),
    paging() {
      return {
        enabled: true,
        pageSize: this.pageSize
      };
    },
    columnChooserObject() {
      return {
        enabled: this.columnChooser,
        allowSearch: true,
        mode: "select"
      };
    },
    toolbarProp() {
      if (this.toolbar) {
        return {
          ...this.toolbar,
          items: [
            ...(this.toolbar?.items || []),

            { template: "gridTitle", location: "before" },
            {
              name: "columnChooserButton",
              visible: true
            },
            {
              name: "searchPanel",
              visible: true
            }
          ]
        };
      }
      return {
        items: [
          { template: "gridTitle", location: "before" },
          {
            name: "columnChooserButton",
            visible: true
          },
          {
            name: "searchPanel",
            visible: true
          }
        ]
      };
    },
    stateStoring() {
      let customSave = this.customStateSave;
      let customLoad = this.customStateLoad;
      return {
        enabled: Boolean(this.gridName),
        type: "custom",
        customSave,
        customLoad,
        ignoreColumnOptionNames: ["filterValue", "filterValues", "headerFilter", "filterType"]
      };
    }
  },
  created() {
    if (this.columns) {
      this.loadColumns();
    }
  },
  watch: {
    columns() {
      this.loadColumns();
    },
    currentLab(nv, ov) {
      if (nv !== ov) {
        this.grid.refresh();
      }
    }
  },
  methods: {
    customStateSave(gridState) {
      return this.$store.dispatch("grids/setGridState", {
        name: this.gridName,
        gridState,
        columns: this.columns
      });
    },
    handleContentReady(event) {
      addCommasToCount();
      this.forwardEvent("content-ready", event);
      this.contentReady$.next(event);
    },
    customStateLoad() {
      return this.$store.state.grids[this.gridName];
    },
    forwardEvent(event, $event) {
      return this.$emit(event, $event);
    },
    initializeGrid(event) {
      this.grid = event.component;
      this.$emit("initialized", event);
    },

    loadColumns() {
      return this.columns.forEach(column => {
        const { headerFilter, type } = column;
        //We do not run any of these actions on columns of type buttons.
        if (/buttons/i.test(type) || /actions/i.test(type)) {
          column.allowSearch = false;
          return;
        }
        if (column.allowHeaderFiltering) {
          return;
        }
        if (headerFilter != undefined) {
          column.allowHeaderFiltering = true;
          column.allowFiltering = false;
          column.allowSearch = false;
        } else if (column.allowFiltering === undefined) {
          column.allowHeaderFiltering = false;
          column.allowFiltering = true;
        }
      });
    },
    onCellPrepared(data) {
      this.redactLogRocket(data);
      if (typeof this.cellPrepared === "function") {
        this.cellPrepared(data);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .dx-datagrid-headers {
  font-weight: bold;
}
</style>
