<template>
  <div class="container">
    <dx-grid-with-search
      :dataSource="caseEditReasons"
      title="Case Edits"
      :columns="columns"
      :selection="selection"
      @cell-click="handleCellClick"
      :isLoading="isLoading"
    >
      <template v-slot:actions="{ data: { data } }">
        <div class="d-flex align-items-center justify-content-center">
          <icon-button
            v-if="permissions.CaseEditReasonEdit"
            v-tooltip.left.start="'Edit item.'"
            @click.stop="handleEdit(data)"
            class="text-primary p-0 pointer"
            icon="pen-alt"
          />
          <icon-button
            v-if="permissions.CaseEditReasonDelete"
            v-tooltip.right.start="'Delete item.'"
            @click.stop="handleDelete(data)"
            class="mx-1 p-0 text-danger pointer"
            icon="trash-alt"
          />
        </div>
      </template>
    </dx-grid-with-search>
    <DxPopup
      :visible="true"
      v-if="popupVisible"
      :width="500"
      :height="400"
      :showCloseButton="true"
      @hidden="handleClose"
    >
      <template v-slot:content>
        <div class="viewEditReasonPopup">
          <dx-scroll-view>
            <div>
              <strong>Date:</strong>
              {{ parseEditDate(popupData.createdOn) }}
            </div>
            <div><strong>Type:</strong> {{ parseEditType(popupData.editType) }}</div>
            <div><strong>Author:</strong> {{ popupData.createdBy }}</div>
            <div><strong>Reason:</strong></div>
            <div class="ml-3">{{ popupData.reasonForChange }}</div>
          </dx-scroll-view>
        </div>
      </template>
    </DxPopup>
    <modal :status="isEditOpen" @close="closeEdit">
      <ReReportPopup :propReason="reasonToEdit" @cancel="closeEdit" @edit="closeEdit" />
    </modal>
  </div>
</template>

<script>
import { CasesApi } from "@/services";
import ArrayStore from "devextreme/data/array_store";
import DxGridWithSearch from "./common/DxGridWithSearch.vue";
import { DxPopup } from "devextreme-vue/popup";
import { DxScrollView } from "devextreme-vue/scroll-view";
import moment from "moment";
import IconButton from "./common/IconButton.vue";
import { mapGetters } from "vuex";
import { handleErrors } from "@/modules/handleErrors";
import Modal from "@/components/common/Modal";
import ReReportPopup from "./ReReportPopup.vue";
import { createLocalTzDateFilter, formatDatetimeCell, getEditTypes } from "@/modules/helpers";
import { format } from "date-fns";

export default {
  name: "EditReasonPopup",
  components: { DxGridWithSearch, DxPopup, DxScrollView, IconButton, Modal, ReReportPopup },
  data() {
    return {
      caseEditReasons: [],
      selection: {
        mode: "none"
      },
      popupVisible: false,
      popupData: {},
      editTypes: getEditTypes(),
      isLoading: false,
      isEditOpen: false,
      reasonToEdit: {}
    };
  },
  created() {
    this.getCaseEdits();
  },
  computed: {
    ...mapGetters(["permissions"]),
    columns() {
      return [
        {
          type: "buttons",
          caption: "Actions",
          cellTemplate: "actions"
        },
        {
          dataField: "createdBy",
          caption: "Author",
          dataType: "string",
          allowHeaderFiltering: true,
          width: 200
        },
        {
          dataField: "reasonForChange",
          caption: "Reason",
          dataType: "string",
          wordWrapEnabled: false,
          width: 400
        },
        {
          dataField: "editType",
          caption: "Type",
          dataType: "number",
          allowHeaderFiltering: true,
          lookup: {
            dataSource: this.editTypes,
            valueExpr: "id",
            displayExpr: "displayName"
          }
        },
        {
          dataField: "createdOn",
          caption: "Date",
          dataType: "date",
          sortIndex: 0,
          sortOrder: "desc",
          calculateDisplayValue: data => {
            if (data.createdOn) {
              return this.calculateLocalDate(data.createdOn);
            }
            return "";
          },
          calculateFilterExpression: (data, filterExpr) => {
            return createLocalTzDateFilter(data, filterExpr, "date");
          }
        }
      ];
    }
  },
  methods: {
    handleCellClick(event) {
      this.popupData = event.data;
      this.popupVisible = true;
    },
    handleClose() {
      this.popupVisible = false;
    },
    parseEditDate(date) {
      return moment(date).format("MMMM D, YYYY");
    },
    parseEditType(editType) {
      const targetEditType = this.editTypes.find(e => e.id === editType);
      if (targetEditType) {
        return targetEditType.displayName;
      }
      return "";
    },
    handleEdit(data) {
      this.isEditOpen = true;
      this.reasonToEdit = data;
    },
    async handleDelete(data) {
      try {
        this.isLoading = true;
        await CasesApi.deleteEditReason({ caseId: data.caseId, id: data.id });
        window.notify("Edit reason deleted.");
        this.getCaseEdits();
      } catch (error) {
        handleErrors(error);
      } finally {
        this.isLoading = false;
      }
    },
    getCaseEdits() {
      const { caseId } = this.$route.params;
      CasesApi.getCaseEdits(caseId).then(res => {
        this.caseEditReasons = new ArrayStore({
          data: res || [],
          key: "id"
        });
      });
    },
    closeEdit() {
      this.isEditOpen = false;
      this.reasonToEdit = {};
      this.getCaseEdits();
    },
    calculateLocalDate(date) {
      if (date) {
        const formattedDate = formatDatetimeCell(date);
        return format(formattedDate, "M/d/yyyy");
      }
      return "";
    }
  }
};
</script>

<style lang="scss" scoped>
.viewEditReasonPopup {
  font-size: 1.25rem;
}

::v-deep
  .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)
  > td:not(.dx-focused) {
  cursor: pointer;
}
</style>
