import Store from "../store/index";

export default {
  methods: {
    devlog(...args) {
      if (process.env?.VUE_APP_STAGE || Store.state.isRecordingLogRocket) {
        console.log(...args);
      }
    }
  }
};
