<template>
  <MultiSelectWithSearch
    data-cy="pathologistSelector"
    :multiple="true"
    ref="selectBox"
    v-bind="$attrs"
    v-model="pathologists"
    v-on="$listeners"
    trackBy="id"
    placeholder="Select Pathologists"
    :dataSource="dataSource"
    :columns="columns"
    :tabIndex="tabProp"
    :fixedFilter="activeFilter"
  >
    <template v-slot:option="{ option }">
      <span class="option__title">{{ displayName(option) }}</span>
    </template>
    <template v-slot:tag="{ option, remove }">
      <span class="custom__tag">
        <span>{{ displayName(option) }}</span>
        <div class="custom__remove d-flex">
          <icon-button
            v-tooltip="'Remove option'"
            @click="remove(option)"
            type="button"
            icon="trash-alt"
            class="btn text-danger"
            :tabindex="tabProp"
          />
          <button
            v-if="!noPrimary"
            v-tooltip.top="option.isPrimary ? 'Primary' : 'Alternate'"
            type="button"
            class="btn"
            @click="setPrimary(option)"
            :tabindex="tabProp"
          >
            <template v-if="option.isPrimary">
              <icon class="text-primary" icon="parking" />
            </template>
            <template v-else>
              <span class="secondary-icon">S</span>
            </template>
          </button>
        </div>
      </span>
    </template>
  </MultiSelectWithSearch>
</template>
<script>
import MultiSelectWithSearch from "@/components/common/MultiSelectWithSearch.vue";
import { mapState } from "vuex";

import { DropdownApi } from "@/services";
import IconButton from "@/components/common/IconButton.vue";
import Icon from "@/components/common/Icon.vue";
export default {
  name: "Pathologist",
  inheritAttrs: false,
  components: {
    MultiSelectWithSearch,
    IconButton,
    Icon
  },
  props: {
    noPrimary: {
      type: Boolean,
      default: false
    },
    value: {
      required: true
    },
    tabProp: {
      default: 0
    }
  },
  data: () => ({
    columns: ["displayName"],
    activeFilter: ["isDisabled", false]
  }),
  computed: {
    ...mapState(["currentUser", "currentLab"]),
    pathologists: {
      get() {
        return this.value;
      },
      set(value) {
        value.forEach(item => {
          if (!item.isPrimary) {
            item.isPrimary = false;
          }
        });
        return this.$emit("input", value);
      }
    },
    dataSource() {
      return DropdownApi.searchPathologists;
    }
  },
  methods: {
    displayName(option) {
      if (option.displayName) {
        return option.displayName;
      } else if (option?.firstName && option?.lastName) {
        if (option.officeName) {
          return `${option.lastName}, ${option.firstName} (${option.officeName})`;
        }
        return `${option.lastName}, ${option.firstName}`;
      }
      return `Pathologist with id ${option.id} `;
    },
    focus() {
      const input = this.$refs.selectBox;
      input.focus();
    },
    setPrimary(option) {
      this.pathologists = this.value.map(e => {
        if (e.id === option.id) {
          e.isPrimary = true;
        } else {
          e.isPrimary = false;
        }
        return e;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.custom__tag {
  background-color: $neutral;
  color: #35495e;
}
</style>
