<template>
  <div :id="componentId" class="range-wrapper">
    <label v-if="!noLabel"
      ><b>{{ label || "Range" }}</b></label
    >
    <text-input
      :onFocus="openDatePickers"
      :noLabel="true"
      :value="inputText"
      :highlightSearchValue="highlightSearchValue && startDate && endDate"
      :highlightInvalid="isInvalidDateRange"
    />
    <dx-popup
      :visible="isPopupVisible"
      :drag-enabled="false"
      :showTitle="false"
      :close-on-outside-click="true"
      @hidden="closeDatePickers"
      :shading="false"
      :height="120"
      :width="250"
    >
      <DxPosition at="center" my="top" :of="$el" />
      <div class="range-pickers">
        <DxDateBox
          ref="startDate"
          :focusStateEnabled="true"
          id="startDate"
          type="date"
          class="mb-2"
          placeholder="Start Date"
          v-model="startDate"
          :value="startDate"
          @value-changed="formatInput"
          valueChangeEvent="input"
          :use-mask-behavior="true"
          :min="minStart"
          :showClearButton="true"
        />
        <DxDateBox
          class=""
          ref="endDate"
          placeholder="End Date"
          v-model="endDate"
          :value="endDate"
          @value-changed="formatInput"
          valueChangeEvent="input"
          :use-mask-behavior="true"
          :min="startDate"
          :max="maxEnd"
          :showClearButton="true"
        />
      </div>
    </dx-popup>
  </div>
</template>

<script>
import TextInput from "./TextInput.vue";
import { DxPopup, DxPosition } from "devextreme-vue/popup";
import DxDateBox from "devextreme-vue/date-box";
import moment from "moment";
import { mapState } from "vuex";
import { daysAfter, daysBefore, checkLeapDay } from "@/modules/helpers";
export default {
  components: { TextInput, DxPopup, DxPosition, DxDateBox },
  props: ["start", "end", "label", "name", "highlightSearchValue", "noLabel"],
  data() {
    return {
      isPopupVisible: false,
      positionOf: ""
    };
  },
  methods: {
    openDatePickers() {
      this.isPopupVisible = true;
    },
    closeDatePickers() {
      this.isPopupVisible = false;
    },
    formatInput({ previousValue, value, event, component, element }) {
      if (previousValue === value) {
        return;
      }
      value = moment.utc(new Date(value)).format("YYYY-MM-DD");
      if (previousValue) {
        previousValue = moment(new Date(previousValue).toLocaleDateString()).format("YYYY-MM-DD");
      }
      const field = element.id;
      //The event object is undefined if the value change programatically.
      if (event && value && previousValue) {
        value = checkLeapDay(value, previousValue);
        let [prevYear] = previousValue?.split("-") || [null];
        let [year, month, day] = value.split("-");
        const currentYear = parseInt(moment(new Date()).format("YYYY"));
        let inputEl = component.field();
        //Only run this logic when the year has changed.
        if (event.target === inputEl && prevYear !== year) {
          year = parseInt(year);
          //If the year is greater than today's year
          //We set it the previous century.
          if (year > currentYear) {
            year =
              (parseInt(currentYear.toString().slice(0, 2)) - 1).toString() +
              year.toString().slice(2);
          }
          inputEl = `${month}/${day}/${year}`;
          this[field] = `${year}-${month}-${day}`;
        }
      }
    }
  },
  computed: {
    ...mapState({
      maxSearchDateRange: state => state.labSettings.MaxSearchDateRange
    }),
    startDate: {
      get() {
        return this.start;
      },
      set(value) {
        this.$emit("input", [value, this.end]);
        return value;
      }
    },
    endDate: {
      get() {
        return this.end;
      },
      set(value) {
        this.$emit("input", [this.start, value]);
        return value;
      }
    },
    startString() {
      if (this.start) {
        const startDate = moment(this.start);
        if (startDate.isValid()) {
          return startDate.format("MM/DD/YYYY");
        }
      }
      return "";
    },
    componentId() {
      return `${this.name}RangePicker`;
    },
    endString() {
      if (this.end) {
        const endDate = moment(this.end);
        if (endDate.isValid()) {
          return endDate.format("MM/DD/YYYY");
        }
      }
      return "";
    },
    inputText() {
      if (!this.endString) {
        return `${this.startString}`;
      }
      return `${this.startString}-${this.endString}`;
    },
    minStart() {
      if (this.end) {
        return daysBefore(this.end, this.maxSearchDateRange || 365);
      }
      return null;
    },
    maxEnd() {
      if (this.start) {
        return daysAfter(this.start, this.maxSearchDateRange || 365);
      }
      return null;
    },
    isInvalidDateRange() {
      if (!this.startDate || !this.endDate) {
        return false;
      }
      return this.endDate > daysAfter(this.startDate, this.maxSearchDateRange || 365);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .text-input {
  min-height: initial;
}
.range-wrapper {
  cursor: pointer;
  /* position: relative; */
}
.range-pickers {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
::v-deep .dx-popup-content {
  padding: 10px;
}
::v-deep :disabled {
  background-color: #ffffff;
}
@media screen and(max-width: 700px) {
  .range-pickers {
  }
}
</style>
