// eslint-disable-next-line no-unused-vars
import { fromEventPattern, Observable } from "rxjs";

/**
 *
 * **/

/**
 *
 * @param {Element} element
 * @param {MutationObserverInit} options
 * @returns {Observable} Mutation events
 */
function elementObserver(element, options = { attributes: true, childList: true }) {
  return fromEventPattern(
    handler => {
      const elObserver = new MutationObserver(handler);
      elObserver.observe(element, options);
      return elObserver;
    },
    (_, mutationObserver) => {
      mutationObserver.disconnect();
    }
  );
}

export default elementObserver;
