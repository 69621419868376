<template>
  <div class="macro_popup">
    <form
      @submit.prevent="handleSubmit"
      class="container"
      v-shortkey="saveShortkey"
      @shortkey="handleSubmit"
    >
      <h3>General Macro</h3>
      <dx-grid-with-search
        class="grid"
        :gridName="gridName"
        :dataSource="dataSource"
        :columns="columns"
        v-stream:content-ready="contentReady$"
        @initialized="initGrid"
        :selection="selection"
        :scrolling="scrolling"
        v-stream:selection-changed="selectionChange$"
        :searchPanel="searchPanel"
        :toolbar="toolbar"
      >
        <template v-slot:searchType>
          <DxSwitch
            slot="searchType"
            v-tooltip="searchType ? 'Macro search' : 'Free text search'"
            switched-on-text="Macro"
            switched-off-text="Text"
            v-model="searchType"
            width="4rem"
          /> </template
        ><template v-slot:startsWith>
          <DxSwitch
            v-if="searchType"
            slot="startsWith"
            v-tooltip="startsWith ? 'Macro Starts With' : 'Contains'"
            switched-on-text="Starts With"
            switched-off-text="Contains"
            v-model="startsWith"
            width="5rem"
          />
        </template>
      </dx-grid-with-search>
      <div v-if="selectedMacroId" :key="selectedMacroId" class="summary col mx-0 mt-2 p-0">
        <DxScrollView>
          <h4>Name: {{ selectedMacro.macroName }}</h4>
          <checkbox
            name="all"
            id="all"
            label="All"
            :value="allFieldsSelected"
            @input="handleSelectAllFields"
          />
          <div class="border-top pt-1" v-for="(field, index) in fieldMap" :key="field + index">
            <checkbox
              :name="field"
              :id="field + 'box'"
              :label="startCase(field)"
              v-model="targetFields[field]"
            />
            <div v-html="selectedMacro[field]" v-if="selectedMacro[field]"></div>
            <div class="text-muted" v-else>No data found.</div>
          </div>
        </DxScrollView>
      </div>

      <div class="d-flex align-self-end">
        <button type="submit" class="btn btn-primary ml-auto">Submit</button>
      </div>
    </form>
  </div>
</template>

<script>
import { format } from "date-fns";
import { mapGetters, mapState } from "vuex";
import { fromEventPattern } from "rxjs";
import { filter, map, mergeMap, switchMap, take, tap } from "rxjs/operators";
import moment from "moment";
import { startCase } from "lodash";
import { MacrosApi } from "@/services";
import { DxSwitch } from "devextreme-vue";
import DataSource from "devextreme/data/data_source";
import eventBus, { RESTORE_GENERAL_EDITOR_POSITION } from "@/modules/eventBus";
import DxGridWithSearch from "./common/DxGridWithSearch.vue";
import Checkbox from "@/components/common/Checkbox.vue";
import { DxScrollView } from "devextreme-vue/scroll-view";
import CustomStore from "devextreme/data/custom_store";
import { gridKeybindings } from "@/modules/gridKeybindings";
import { altKey, getTextFromHtml, shortenAccessionNumber } from "@/modules/helpers";
import { MacroTypeEnum, MacroWindowClinicalEnum } from "@/modules/enums";

export default {
  name: "GeneralMacroPopup",
  components: {
    DxSwitch,
    DxGridWithSearch,
    Checkbox,
    DxScrollView
  },
  data: () => ({
    isLoading: true,
    grid: {},
    toolbar: {
      items: [
        {
          location: "after",
          template: "searchType"
        },
        {
          location: "after",
          template: "startsWith"
        }
      ]
    },
    searchOutput: [],
    isManagementOpen: false,
    searchValue: "",
    diagnosisSummaries: [],
    macroTypes: [],
    targetFields: {
      caseNotes: true,
      clinical: true,
      diagnosis: true,
      microscopic: true,
      specimenNotes: true,
      gross: true
    },
    selection: {
      mode: "single"
    },
    scrolling: {
      showScrollbarAlways: true,
      mode: "infinite",
      preloadEnabled: true,
      rowRenderingMode: "virtual"
    },
    remoteOperations: {
      filtering: true,
      sorting: true,
      paging: true,
      grouping: false
    },
    searchType: false,
    startsWith: false,
    selectedMacro: {},
    selectedIndex: [],
    protocolOptions: [],
    bodyPartOptions: [],
    procedureOptions: [],
    typeMap: {
      Results: [
        "users",
        "diagnosisSummaryId",
        "diagnosis",
        "interfaceDiagnosis",
        "microscopic",
        "specimenNotes",
        "caseNotes",
        "cptCodes",
        "holdCodes",
        "icdCodes"
      ],
      General: [
        "users",
        "generalText",
        "cptCodes",
        "holdCodes",
        "icdCodes",
        "procedures",
        "images",
        "tags"
      ],
      saveShortkey: altKey("s")
    },
    procedurePopupTextToReplace: ""
  }),
  props: {
    dialogFromWysiwyg: Boolean,
    procedureGeneralMacroInput: Object
  },
  created() {
    this.searchType = this.$store.state.applicationSettings.macroSearchMode;
    this.startsWith = this.$store.state.applicationSettings.macroStartsWith;
  },
  mounted() {
    this.targetFields = this.fieldMap.reduce((acc, curr) => {
      acc[curr] = true;
      return acc;
    }, {});
    this.handlePreloadFields(MacroTypeEnum.General);
    if (this.procedureGeneralMacroInput && Object.keys(this.procedureGeneralMacroInput).length) {
      this.procedurePopupTextToReplace = this.procedureGeneralMacroInput.input;
    }
  },
  domStreams: ["selectionChange$", "contentReady$"],
  subscriptions() {
    // Event from the devExtreme grid.
    const grid$ = this.contentReady$.pipe(map(data => data.event.msg));
    const focusFirstRow$ = grid$.pipe(
      tap(async ({ component }) => {
        const currentSelected = component.getSelectedRowKeys();
        if (!currentSelected?.length) {
          await this.$nextTick();
          component.selectRowsByIndexes([0]);
        }
      })
    );
    // Listen for search events only once after the grid is rendered.
    const searchChange$ = grid$.pipe(
      take(1),
      mergeMap(({ component }) =>
        fromEventPattern(
          handler => component.on("optionChanged", handler),
          handler => component.off("optionChanged", handler)
        ).pipe(
          filter(({ name }) => name === "searchPanel"),
          switchMap(data => {
            return grid$.pipe(
              tap(() => {
                const { component } = data;
                const rows = component.getVisibleRows();
                if (rows?.length) {
                  component.selectRowsByIndexes(0);
                } else {
                  component.clearSelection();
                }
              })
            );
          })
        )
      )
    );
    return {
      focusFirstRow$,
      searchChange$,
      startUp$: gridKeybindings.call(this, grid$),
      //Load the data for the selected macro when the selectedRowsData comes in the devExtreme component.
      selectedMacro$: this.selectionChange$.pipe(
        tap(data => {
          const { selectedRowsData } = data.event.msg;
          this.selectedMacro = selectedRowsData[0];
        })
      )
    };
  },
  computed: {
    ...mapState({
      currentLab: state => state.currentLab,
      caseDetails: state => state.accessionStore.caseDetails,
      specimens: state => state.accessionStore.specimens,
      MacroWindowClinical: state => state.labSettings.MacroWindowClinical,
      patientAge: state => state.accessionStore.caseHeader.patientAgeAtReceiptDisplay
    }),
    currentSpecimenClinical() {
      let targetSpecimen = this.target;
      // IP-174 -- a lab flag will determine if the first specimen's clinical is displayed by default.'
      if (
        this.MacroWindowClinical === MacroWindowClinicalEnum.FirstSpecimen &&
        this.specimens?.length
      ) {
        targetSpecimen = this.specimens[0];
      }

      if (targetSpecimen?.clinical) {
        const textValue = getTextFromHtml(targetSpecimen.clinical);
        if (typeof textValue === "string") {
          return textValue;
        }
        return null;
      }
      return "";
    },
    ...mapGetters(["permissions"]),
    ...mapGetters("accessionStore", ["primaryPathologist"]),
    gridName() {
      return "generalMacros";
    },
    hasEditPermission() {
      return this.permissions.MacroGeneralCreateEdit;
    },
    selectedMacroId() {
      return this.selectedMacro?.macroId;
    },
    searchPanel() {
      return {
        searchVisibleColumnsOnly: this.searchType,
        searchOperation: this.startsWith ? "startswith" : "contains",
        visible: true,
        width: "300px"
      };
    },
    dataSource() {
      const permissions = {
        diagnosis: this.permissions.CaseFieldEditDiagnosis,
        gross: this.permissions.CaseFieldEditGross,
        clinical: this.permissions.CaseFieldEditClinical,
        caseNotes: this.permissions.CaseFieldEditCaseNotes,
        microscopic: this.permissions.CaseFieldEditMicroscopic,
        notes: this.permissions.CaseFieldEditSpecimenNotes
      };
      const isAllowedToResult = permissions.diagnosis;
      let userId = this.currentUser?.id;
      if (this.primaryPathologist?.guid && isAllowedToResult) {
        userId = this.primaryPathologist?.guid;
      }
      const startsWith = this.startsWith;
      const customStore = new CustomStore({
        load(loadOptions) {
          return MacrosApi.getMacrosByUserAndType({
            userId,
            macroTypeId: MacroTypeEnum.General,
            loadOptions
          });
        }
      });

      return new DataSource({
        store: customStore,
        key: "macroId",
        searchOperation: startsWith ? "startswith" : "contains"
      });
    },
    patientDOB() {
      if (this.caseDetails.patientDOB) {
        return moment(this.caseDetails.patientDOB).format("MM/DD/YYYY");
      }
      return "N/A";
    },
    hasSelected() {
      return this.selectedMacro?.macroId;
    },
    icdCodes() {
      return this.selectedMacro.icdCodes.flat();
    },
    holdCodes() {
      return this.selectedMacro.holdCodes.flat();
    },
    fieldMap() {
      return ["generalText"];
    },
    target() {
      return this.targetSpecimen;
    },
    microscopicHTML() {
      return this.selectedMacro.microscopic;
    },
    notesHTML() {
      return this.selectedMacro.specimenNotes;
    },
    caseNotesHTML() {
      return this.selectedMacro.caseNotes;
    },
    grossHTML() {
      return this.selectedMacro.gross;
    },
    clinicalHTML() {
      return this.selectedMacro.clinical;
    },
    columns() {
      return [
        {
          dataField: "macroName",
          caption: "Macro Name",
          sortOrder: "asc",
          visibleIndex: 1,
          sortIndex: 0,
          dataType: "string",
          selectedFilterOperation: "startsWith"
        },
        {
          dataField: "generalText",
          visibleIndex: 2,
          dataType: "string",
          allowSearch: !this.searchType,
          calculateDisplayValue(data) {
            if (data.generalText) {
              return getTextFromHtml(data.generalText);
            }
            return "";
          },
          calculateFilterExpression: (selector, comparisonOperator) => {
            return [
              "generalText",
              comparisonOperator ? comparisonOperator : this.startsWith ? "startswith" : "contains",
              selector
            ];
          }
        }
      ];
    },
    macroFields() {
      let macroMap = this.typeMap[MacroTypeEnum.General];
      if (macroMap) {
        macroMap = macroMap.filter(e => {
          return e != "users";
        });
        return macroMap;
      }
      return [];
    },
    mappedProtocol() {
      return this.protocolOptions.reduce(
        (obj, item) =>
          Object.assign(obj, {
            [item.id]: item.displayName
          }),
        {}
      );
    },
    allFieldsSelected() {
      return Object.values(this.targetFields).every(field => field);
    },
    mappedBodyPart() {
      return this.bodyPartOptions.reduce(
        (obj, item) =>
          Object.assign(obj, {
            [item.id]: item.displayName
          }),
        {}
      );
    },
    map() {
      return {
        Results: [
          "diagnosisSummaryId",
          "diagnosis",
          "interfaceDiagnosis",
          "microscopic",
          "specimenNotes",
          "caseNotes",
          "cptCodes",
          "holdCodes",
          "icdCodes"
        ],
        General: [
          "generalText",
          "cptCodes",
          "holdCodes",
          "icdCodes",
          "procedures",
          "images",
          "tags"
        ]
      };
    },
    shortenedCaseNumber() {
      return shortenAccessionNumber(this.caseDetails.caseNumber);
    }
  },
  watch: {
    mappedProtocol() {
      return this.protocolOptions.reduce(
        (obj, item) =>
          Object.assign(obj, {
            [item.id]: item.displayName
          }),
        {}
      );
    },
    mappedBodyPart() {
      return this.bodyPartOptions.reduce(
        (obj, item) =>
          Object.assign(obj, {
            [item.id]: item.displayName
          }),
        {}
      );
    }
  },
  methods: {
    handlePreloadFields(targetType) {
      switch (targetType) {
        case MacroTypeEnum.General:
          {
            this.targetFields = {
              caseNotes: false,
              clinical: false,
              diagnosis: false,
              microscopic: false,
              specimenNotes: false,
              gross: false,
              generalText: true
            };
          }
          break;
      }
    },
    initGrid({ component }) {
      this.grid = component;
    },
    startCase,
    async handleSubmit() {
      if (!this.selectedMacro?.macroId) {
        window.notify("Please select a macro to apply.", "warning");
        return;
      }
      const targetMacro = await MacrosApi.getMacroDetails(this.selectedMacroId);
      if (Object.keys(this.procedurePopupTextToReplace).length) {
        this.$emit("macroSelected", targetMacro);
        return;
      }

      Object.keys(this.targetFields).forEach(field => {
        if (!this.targetFields[field]) {
          targetMacro[field] = null;
        }
      });

      if (this.dialogFromWysiwyg) {
        eventBus.$emit(RESTORE_GENERAL_EDITOR_POSITION, [targetMacro]);
      } else {
        this.$emit("macroSelected", [targetMacro]);
      }
    },
    handleSelectAllFields() {
      if (this.allFieldsSelected) {
        this.targetFields = this.fieldMap.reduce((acc, curr) => {
          acc[curr] = false;
          return acc;
        }, {});
      } else {
        this.targetFields = this.fieldMap.reduce((acc, curr) => {
          acc[curr] = true;
          return acc;
        }, {});
      }
    },
    toggleMacro() {
      this.$emit("close");
    },
    displayDate(date) {
      if (date instanceof Date) {
        return format(date, "MM/dd/yyy");
      } else {
        date = new Date(date);
      }
      if (!Number.isNaN(Number(date))) {
        return format(date, "MM/dd/yyyy");
      }
      return "N/A";
    },
    handleSelect(macro) {
      this.macro.callback(macro);
      this.$store.commit("clearMacro");
    },
    displayField(field) {
      if (field === "diagnosisSummaryId") {
        return "Diagnosis Summary";
      }
      return field.replace(/([a-z])([A-Z])/g, "$1 $2");
    },
    displayProcedure(id) {
      const procedure = this.procedureOptions.find(e => e.id === id);
      if (procedure) {
        return procedure.displayName;
      }
      return "N/A";
    },
    displayDiagnosisSummary(diagnosisSummaryId) {
      if (diagnosisSummaryId) {
        const diagnosis = this.diagnosisSummaries.find(e => e.id === diagnosisSummaryId);
        if (diagnosis) {
          const classes = {
            "bg-danger": diagnosis.backgroundColor === 2,
            "bg-success": diagnosis.backgroundColor === 1,
            "bg-warning": diagnosis.backgroundColor === 3
          };
          return { displayText: diagnosis.displayText, classes };
        }
      }
      return { displayText: "N/A", classes: {} };
    },
    arrayProperty(property) {
      return Array.isArray(property);
    },
    lastNumberInRow(property) {
      const { patientMRN, acctNumber, orderNumber } = this.caseDetails;
      const lastNumber = () => {
        if (orderNumber) {
          return "orderNumber";
        }
        if (acctNumber) {
          return "acctNumber";
        }
        if (patientMRN) {
          return "patientMRN";
        }
      };
      if (lastNumber() !== property) {
        return "border-right mx-1 pr-2";
      }
      return "ml-1";
    }
  },
  directives: {
    focusItem: {
      inserted(el) {
        return el.focus();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.macro_popup {
  .grid {
    height: 40vh;
  }
  .summary {
    height: 25vh;
  }
}
.value {
  margin: 5px 2px;
}
.summary {
  overflow: auto;
}
.target_title {
  color: $primary-dark;
}
.clinical-text {
  font-size: 1.2rem;
  color: $primary-dark;
  width: 650px;
  max-width: 100%;
}
</style>
