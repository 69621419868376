// import { isIE } from '@/modules/helpers.js';

/**
 *
 * @param {Object} Options // Add your keyCode and a string to represent
 * @param {String} callback //String representing the name of the method you want the shortcut key to trigger.
 */

export default function ({ keyCode, modifier }, callback) {
  const event = "keyup";
  return {
    mounted() {
      this.$el.accesskey = modifier;
      this._keyListeners = function (event) {
        event.preventDefault();
        const activeEl = document.activeElement;
        if (
          (event[`${modifier}Key`] && event.keyCode === keyCode) ||
          (!modifier && event.keyCode === keyCode)
        ) {
          if (this.$el.contains(activeEl)) {
            event.stopPropagation();
            event.preventDefault();
            this[callback](activeEl);
          }
        }
      };
      this.$el.addEventListener(event, this._keyListeners.bind(this));
    },
    beforeDestroy() {
      this.$el.removeEventListener(event, this._keyListeners);
    }
  };
}
