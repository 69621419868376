import api from "./api.js";
class QuickLinks {
  constructor() {
    this.url = "/api/QuickLinks";
  }
  get search() {
    return api.createSearch(this.url);
  }
  quickLinksByRole() {
    return api.get(this.url + "/QuickLinksByRole");
  }
  getCaseQuickLinks(caseId) {
    return api.get(this.url + `/ForCase/${caseId}`);
  }
  addNoteTags(tag) {
    return api.post(this.url, tag);
  }
  editNoteTags(tag) {
    return api.put(this.url, tag);
  }
  deleteQuickLinks(payload) {
    return api.delete(this.url, payload);
  }
}
export default new QuickLinks();
