import api from "./api.js";
class Dropdown {
  constructor() {
    this.url = "/api/DropDown";
  }
  get searchPathologists() {
    return api.createSearch(this.url + "/pathologists/search");
  }
  get SearchProviderLocations() {
    return api.createSearch(this.url + "ProviderLocations/search");
  }
  get searchProviders() {
    return api.createSearch(this.url + "/providers/search");
  }
  get searchInsurancePayers() {
    return api.createSearch(this.url + "/payers/search");
  }
  get searchIcdCodes() {
    return api.createSearch(this.url + "/icdcodes/search");
  }
  get searchCptCodes() {
    return api.createSearch(this.url + "/cptcodes/search");
  }
  get searchProcedures() {
    return api.createSearch(this.url + "/procedures/search");
  }
  get searchHoldCodes() {
    return api.createSearch(this.url + "/holdcodes/search");
  }
  get searchRoles() {
    return api.createSearch(this.url + "/roles/search");
  }
  get searchTags() {
    return api.createSearch(this.url + "/tags/search");
  }
  get searchLocations() {
    return api.createSearch(this.url + "/Locations");
  }
  get searchContacts() {
    return api.createSearch(this.url + "/Contacts");
  }
  get searchInterfaceDiagnoses() {
    return api.createSearch(this.url + "/InterfaceDiagnoses");
  }
  get proxyUserOptionsStore() {
    return api.createSearch(this.url + "/ProxyUsers");
  }

  getPrefixTags() {
    return api.get(this.url + "/PrefixTags");
  }
  createSearch(url, labId) {
    return api.createSearch(this.url + url, undefined, { labId });
  }
  /**
   *
   * @param {Object} payload
   * @param {Array} payload.billingTransactionCodeIds - Id's of the transaction codes
   * @param {Array} payload.icdCodeIds - Ids of the icdCodes
   * @returns
   */
  getMatchingGCodes(payload) {
    return api.post(this.url + "/MatchingGCodes", payload);
  }
  getUserMacroList(userId) {
    return api.get(this.url + "/MacrosByUser/" + userId);
  }
  getProtocol(prefixId) {
    return api.get(this.url + "/Protocols", { prefixId });
  }
  getBodyParts() {
    return api.get(this.url + "/BodyParts");
  }
  getSubDescription() {
    return api.get(this.url + "/SubDescription");
  }
  getTaskPriorities(labId) {
    return api.get(this.url + "/taskPriorities", { labId });
  }
  getICDCodes(search) {
    return api.get(this.url + "/ICDCodes", { search: search });
  }
  getHopper() {
    return api.get(this.url + `/Hopper`);
  }
  getSites() {
    return api.get(this.url + "/Sites");
  }
  getBillingCycles() {
    return api.get(this.url + `/billingCodes`);
  }
  getTimeZones() {
    return api.get(this.url + "/TimeZones");
  }
  getBillingTypes() {
    return api.get(this.url + `/billingTypes`);
  }
  getLabPrefix(labId) {
    return api.get(this.url + `/labPrefix/${labId}`);
  }
  getPathologists(labId) {
    return api.get(this.url + `/pathologists/${labId}`);
  }
  getTitles() {
    return api.get(this.url + `/titles`);
  }
  getSuffix() {
    return api.get(this.url + `/suffix`);
  }
  getStates() {
    return api.get(this.url + `/states`);
  }
  getRace() {
    return api.get(this.url + `/race`);
  }
  getSex() {
    return api.get(this.url + `/sex`);
  }
  getPriorities() {
    return api.get(this.url + `/Priorities`);
  }
  getProviders() {
    return api.get(this.url + `/ProviderLocations`);
  }
  getMacroTypes() {
    return api.get(this.url + `/MacroTypes`);
  }
  getCPTCodes() {
    return api.get(this.url + `/CPTCodes`);
  }
  getHoldCodes() {
    return api.get(this.url + `/HoldCodes`);
  }
  getTypeCodeGroups() {
    return api.get(this.url + `/TypeCodeGroups`);
  }
  getProcedures() {
    return api.get(this.url + `/Procedures`);
  }
  getImages() {
    return api.get(this.url + `/Images`);
  }
  getTags() {
    return api.get(this.url + `/Tags`);
  }
  getLocationPhoneTypes() {
    return api.get(this.url + "/LocationPhoneTypes");
  }
  getCasePhoneTypes() {
    return api.get(this.url + `/CasePhoneTypes`);
  }
  getBatches() {
    return api.get(this.url + "/PathReportMessageBatches");
  }
  getDiagnosisSummaries(labId) {
    return api.get(this.url + `/DiagnosisSummaries/${labId}`);
  }
  getRateTypes() {
    return api.get(this.url + "/RateTypes");
  }
  getStatusEnum() {
    return api.get(this.url + "/StatusEnums");
  }
  getReportGroups() {
    return api.get(this.url + "/ReportGroups");
  }
  getBillingRates() {
    return api.get(this.url + "/BillingRates");
  }
  getTransactionCodeTypes() {
    return api.get(this.url + "/BillingTransactionCodeTypes");
  }
  getAvailableLabs() {
    return api.get(this.url + "/AvailableLabs");
  }
  getClientLabs(providerList) {
    return api.post(this.url + "/LabClientAvailableLabs", providerList);
  }
  getPathReportPreviewLocations() {
    return api.get(this.url + "/PathReportPreviewLocations");
  }
  getResultsDiagnosisOnFocusBehaviors() {
    return api.get(this.url + "/ResultsDiagnosisBehavior");
  }
  getPrintModes() {
    return api.get(this.url + "/PrintModes");
  }
  getOrderDiagnosis() {
    return api.get(this.url + "/OrderDiagnosis");
  }
  getLabLocations() {
    return api.get(this.url + "/LabLocations");
  }
  getInterfaceDiagnoses() {
    return api.get(this.url + "/InterfaceDiagnoses");
  }
  getSpecimenTypes() {
    return api.get(this.url + "/SpecimenTypes");
  }
}
export default new Dropdown();
