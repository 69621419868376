<template>
  <div
    class="text-input d-flex flex-column date_picker_wrapper"
    :data-testid="`${name}-datepicker`"
    :id="($attrs.id ? $attrs.id : name) + 'wrapper'"
    :class="{
      ' has-error': validator && validator.$model && validator && validator.$error
    }"
  >
    <label
      v-html="displayName(accessKey, label)"
      :for="$attrs.id || $attrs.name"
      v-if="!noLabel && label"
      :id="$attrs.id && $attrs.id + 'label'"
    />
    <DxDateBox
      type="date"
      v-model="date"
      :value="date"
      @valueChanged="formatInput"
      :input-attr="inputAttrs"
      valueChangeEvent="input"
      :max="max"
      :min="min"
      :show-clear-button="true"
      :use-mask-behavior="true"
      display-format="shortdate"
      :dateSerializationFormat="dateSerializationFormat"
      :isValid="isValid"
      @focus-out="focusOut"
      @focusIn="isFocused = true"
      @initialized="initialize"
      :disabled="disabled"
    />
    <div v-if="(validator && validator.$error) || (validator && validator.$invalid)">
      <div
        class="validation-error"
        v-for="(key, index) in Object.keys(validator.$params)"
        :key="index"
      >
        <span class="error" v-if="!validator[key]">
          {{ validatorMsgMaps[key] }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import DxDateBox from "devextreme-vue/date-box";
import { validatorMsgMapBase, checkLeapDay } from "@/modules/helpers";
import moment from "moment";
export default {
  inheritAttrs: false,
  name: "DatePicker",
  components: {
    DxDateBox
  },
  props: {
    dateSerializationFormat: {
      type: String,
      default() {
        return "yyyy-MM-dd";
      }
    },
    noLabel: {
      type: Boolean,
      default: false
    },
    accessKey: {
      type: String,
      default: ""
    },
    value: {
      required: false
    },
    name: String,
    max: {
      require: false
    },
    min: {
      require: false
    },
    twoDigitYear: {
      type: Boolean
    },
    label: {
      type: String
    },
    disabledDates: {
      type: Object
    },
    required: {
      type: Boolean
    },
    disabled: {
      default() {
        return false;
      }
    },
    validatorMsgMap: {
      type: Object,
      default: () => {
        return validatorMsgMapBase;
      }
    },
    validator: {
      type: Object,
      default: null
    },
    highlightSearchValue: {
      default: () => {
        return false;
      }
    }
  },

  data() {
    return {
      isCalendarOpen: false,
      isFocused: false,
      dateBox: {},
      isLeapDay: false
    };
  },
  computed: {
    date: {
      get() {
        return this.value;
      },
      set(value) {
        return this.$emit("input", value);
      }
    },
    hasSearchValue() {
      if (!this.highlightSearchValue) {
        return false;
      }
      if (this.value !== null && this.value !== undefined && this.value !== "") {
        if (Array.isArray(this.value)) {
          return this.value.length > 0;
        }
        return true;
      }
      return false;
    },
    inputAttrs() {
      return {
        ...this.$attrs,
        "arial-label": this.name,
        name: this.name,
        id: this.name,
        "data-testid": `${this.name}-datepicker-input`,
        class: this.hasSearchValue ? "has-search-value" : ""
      };
    },
    isValid() {
      if (this.validator && this.value) {
        return !this.validator?.$invalid;
      }
      return true;
    },
    validatorMsgMaps() {
      return { ...validatorMsgMapBase, ...this.validatorMsgMap };
    }
  },
  methods: {
    formatInput({ previousValue, value, event, component }) {
      //The event object is undefined if the value change programatically.
      if (value && previousValue) {
        value = moment(value).format("YYYY-MM-DD");
        previousValue = moment(previousValue).format("YYYY-MM-DD");
        value = checkLeapDay(value, previousValue);
        let [prevYear] = previousValue.split("-");
        let [year, month, day] = value.split("-");
        if (month === "02" && day === "29") {
          this.isLeapDay = true;
        }
        if (event && this.twoDigitYear) {
          const today = new Date();
          const field = component.field();
          //Only run this logic when the year has changed.
          if (event.target === field && prevYear !== year) {
            year = parseInt(year);
            //If the year is greater than today's year
            //We set it the previous century.
            if (year > today.getFullYear()) {
              year =
                (parseInt(today.getFullYear().toString().slice(0, 2)) - 1).toString() +
                year.toString().slice(2);
            }
            field.value = `${month}/${day}/${year}`;
            return this.$emit("input", `${year}-${month}-${day}`);
          }
        }
      }
      this.$emit("input", value);
    },
    initialize({ component }) {
      this.dateBox = component;
    },
    focus() {
      if (!this.isFocused) {
        this.dateBox.focus();
      }
    },
    focusOut(event) {
      this.isFocused = false;
      this.$emit("blur", event);
    },
    displayName(key = "", name) {
      if (key) {
        const regex = new RegExp(key, "i");
        if (regex.test(name)) {
          const { index } = name.match(regex);
          return `<b>${name.slice(0, index)}<u>${name[index]}</u>${name.slice(index + 1)}</b>`;
        }
      }
      return `<b>${name}</b>`;
    }
  }
};
</script>

<style lang="scss">
.FormDate {
  $spacing: 0.75em;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  border: 1px solid #888;
  border-radius: 0.25em;
  &__input {
    border: none;
    text-align: center;
    -moz-appearance: textfield; // 1
    &::-webkit-inner-spin-button {
      display: none; // 1
    }
    &:focus {
      outline: none;
    }
    &::placeholder {
      font-size: 0.7rem;
      font-weight: bold;
      color: black;
    }
    &--day,
    &--month {
      width: 15%;
      &::after {
        content: "/";
      }
    }
    &--year {
      text-align: left;
      width: 50%;
    }
  }
  &__divider {
    pointer-events: none;
  }
  &.disabled {
    background-color: #e9ecef;
    opacity: 1;
  }
}
input:focus {
  outline: none;
}
.focused {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  &.is-valid {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  }
  &.is-invalid {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }
}
.error {
  font-size: 0.75rem;
}
.vdp-datepicker__calendar {
  z-index: 999;
  position: absolute !important;
  top: 40px;
  right: -30px;
  &.labeled {
    top: 70px;
    right: -5px;
  }
}
.vdp-datepicker {
  position: relative !important;
}
input {
  &::-webkit-calendar-picker-indicator {
    color: $primary;
  }
}
</style>
