import { syncMacroDb } from "@/modules/Quill/Macro.js";
import api from "./api.js";
class Macros {
  constructor() {
    this.url = "/api/Macros";
  }
  get searchStore() {
    return api.createSearch(this.url + "/search", "macroId");
  }
  createSearch(url, labId) {
    return api.createSearch(this.url + url, undefined, { labId });
  }
  getMacrosByUser(userId) {
    return api.createSearch(this.url + `/ByUser/${userId}`, "macroId");
  }
  getMacroDetails(id) {
    return api.get(this.url + `/${id}`);
  }
  getMacrosByUserAndType(payload) {
    return api.post(this.url + "/ByUserAndType", payload);
  }
  getMacroForTyping(id) {
    return api.get(this.url + `/ForTyping/${id}`);
  }
  getMacroListByType(id) {
    return api.get(this.url + `/search?filter=["macroType","=",${id}]`);
  }
  getMacroByPhrase(phrase) {
    return api.get(this.url + `/MacrosByPhrase?macroPhrase=${phrase}`);
  }
  async addMacro(macro) {
    const response = api.post(this.url, macro);
    await syncMacroDb();
    return response;
  }
  async editMacro(macro) {
    const response = api.put(this.url, macro);
    await syncMacroDb();
    return response;
  }
  getMacrosForCurrentUser(userId) {
    return api.get(this.url + "/ForCurrentUser", { userId });
  }
  requestUserMacroExport(payload) {
    return api.post(this.url + "/Exports", payload);
  }
  extractMacroWords(userIds, labId) {
    return api.post(this.url + "/ExtractResultMacroWords", { userIds, labId });
  }
  deleteMacros(payload) {
    return api.delete(this.url, payload);
  }
}
export default new Macros();
