<template>
  <div>
    <div class="d-flex justify-content-between my-2">
      <h3>Emails</h3>
      <AddBtn
        type="button"
        @click="triggerIsAdding"
        :icon="isAdding ? 'times' : 'plus'"
        :text="isAdding ? 'Close' : 'Add'"
      />
    </div>
    <div v-if="value && value.length">
      <PropTable
        v-model="value"
        :columns="columns"
        @edit="editEmail"
        @input="$emit('input', $event)"
      />
    </div>
    <form
      v-if="isAdding || isEditing"
      @submit.prevent.stop="addEmail"
      class="col-8 aling-items-center"
    >
      <div class="row">
        <div class="col-2 my-auto">
          <Checkbox label="Prelim" id="prelim" v-model="email.prelim" />
          <Checkbox label="Final" id="final" v-model="email.final" />
        </div>
        <TextInput
          name="email"
          class="col"
          label="Address"
          maxLength="255"
          v-model="email.address"
          :validator="$v.email.address"
        />
        <TextInput
          name="name"
          class="col"
          maxLength="101"
          label="Recipient"
          v-model="email.recipient"
          :validator="$v.email.recipient"
        />
      </div>
      <div class="row justify-content-end">
        <button @click="cancelEdit" type="button" class="my-2 mr-1 btn btn-danger">Cancel</button>
        <button type="submit" class="my-2 btn btn-primary">
          {{ isEditing ? "Save" : "Submit" }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { required, maxLength, email } from "vuelidate/lib/validators";
import PropTable from "@/components/common/PropTable.vue";
import AddBtn from "@/components/common/AddButton.vue";
import TextInput from "@/components/common/TextInput.vue";
import Checkbox from "@/components/common/Checkbox.vue";

export default {
  name: "Emails",
  props: {
    value: {
      required: true
    }
  },
  components: {
    PropTable,
    AddBtn,
    TextInput,
    Checkbox
  },
  data() {
    return {
      columns: [
        {
          dataField: "prelim",
          dataType: "boolean"
        },
        {
          dataField: "final",
          dataType: "boolean"
        },
        { dataField: "address" },
        {
          dataField: "recipient"
        }
      ],
      isEditing: false,
      isAdding: false,
      defaultEmail: {
        prelim: true,
        final: true,
        address: "",
        recipient: ""
      },
      email: {
        prelim: true,
        final: true,
        address: "",
        recipient: ""
      }
    };
  },
  validations: {
    email: {
      address: {
        required,
        maxLength: maxLength(254),
        email
      },
      recipient: {
        required,
        maxLength: maxLength(100)
      }
    }
  },
  methods: {
    async triggerIsAdding() {
      if (this.isEditing) {
        const confirm = await window.confirm(
          "You may have unsaved data, are you sure you want to continue?"
        );
        if (!confirm) {
          return;
        }
      }
      this.email = { ...this.defaultEmail };
      if (this.isAdding) {
        this.isAdding = false;
        this.isEditing = false;
        return;
      }
      this.isAdding = true;
      this.isEditing = false;
    },
    addEmail() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        window.notify("Please verify your input and try again.", "warning");

        return;
      }
      if (!this.isEditing) {
        if (this.value) {
          const values = [...this.value, this.email];
          this.$emit("input", values);
        } else {
          this.$emit("input", [this.email]);
        }
      }
      this.isEditing = false;
      this.$v.$reset();
      return (this.email = { ...this.defaultEmail });
    },
    editEmail({ data }) {
      this.isEditing = true;
      this.email = data;
    },
    cancelEdit() {
      if (this.isEditing) {
        this.isEditing = false;
      }
      this.isAdding = false;
      this.email = { ...this.defaultEmail };
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-cell {
  width: 10%;
}
table td {
  text-align: center;
  padding: 0;
}
</style>
