<template>
  <div>
    <div class="d-flex justify-content-between my-2">
      <h3>PDF Configuration</h3>
      <AddBtn
        type="button"
        @click="triggerIsAdding"
        :text="isAdding ? 'Close' : 'Add'"
        :icon="isAdding ? 'times' : 'plus'"
      />
    </div>
    <div v-if="value && value.length">
      <PropTable
        v-model="value"
        :columns="columns"
        @edit="editPDF"
        @input="$emit('input', $event)"
      />
    </div>
    <form v-if="isAdding || isEditing" @submit.prevent="addPDF" class="col-8">
      <div class="row d-flex justify-content-center align-items-center">
        <div class="d-flex flex-column justify-content-start">
          <Checkbox label="Final" id="PDFFinal" v-model="PDF.final" />
          <Checkbox label="Prelim" class="" id="PDFPrelim" v-model="PDF.prelim" />
        </div>
      </div>
      <div class="row">
        <TextInput
          name="Network Path"
          class="col"
          label="Network Path"
          maxLength="261"
          v-model="PDF.networkPath"
          :validator="$v.PDF.networkPath"
        />
        <TextInput
          name="fileName"
          class="col"
          maxLength="201"
          label="File Naming Convention"
          v-model="PDF.fileNamingConvention"
          :validator="$v.PDF.fileNamingConvention"
        />
      </div>
      <div>
        <span class="text-danger">Available Keywords: </span>
        <br />
        <ul class="text-danger">
          <li>{ShortAccessionNum}</li>
          <li>{AccessionNum}</li>
          <li>{LastName}</li>
          <li>{FirstName}</li>
          <li>{DOB}</li>
          <li>{MRN}</li>
          <li>{DateCollected}</li>
          <li>{DateReported}</li>
        </ul>
      </div>
      <div class="row justify-content-end">
        <button @click="cancelEdit" type="button" class="my-2 mr-1 btn btn-danger">Cancel</button>
        <button type="submit" class="my-2 btn btn-primary">
          {{ isEditing ? "Save" : "Submit" }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import PropTable from "@/components/common/PropTable.vue";
import TextInput from "@/components/common/TextInput.vue";
import AddBtn from "@/components/common/AddButton.vue";
import Checkbox from "@/components/common/Checkbox.vue";

export default {
  name: "PDF-Config",
  components: {
    PropTable,
    AddBtn,
    TextInput,
    Checkbox
  },
  props: {
    value: {
      required: true
    }
  },
  data() {
    return {
      columns: [
        {
          dataField: "prelim"
        },
        {
          dataField: "final"
        },
        { dataField: "networkPath" },
        { dataField: "fileNamingConvention" }
      ],
      isEditing: false,
      isAdding: false,
      defaultPDF: {
        prelim: false,
        final: false,
        networkPath: "",
        fileNamingConvention: ""
      },
      PDF: {
        prelim: false,
        final: false,
        networkPath: "",
        fileNamingConvention: ""
      }
    };
  },
  mounted() {
    if (this.value === null) {
      this.$emit("input", []);
    }
  },
  computed: {
    timeSlots() {
      const times = [];
      var hours, minutes;
      for (var i = 0; i <= 1380; i += 60) {
        hours = Math.floor(i / 60);
        minutes = i % 60;
        if (minutes < 10) {
          minutes = "0" + minutes; // adding leading zero to minutes portion
        }
        //add the value to dropdownlist

        times.push({
          id: hours,
          displayName: hours + ":" + minutes
        });
      }

      return times;
    }
  },
  validations: {
    PDF: {
      networkPath: {
        required,
        maxLength: maxLength(260)
      },
      fileNamingConvention: {
        required,
        maxLength: maxLength(200)
      }
    }
  },
  methods: {
    async triggerIsAdding() {
      if (this.isEditing) {
        const confirm = await window.confirm(
          "You may have unsaved data, are you sure you want to continue?"
        );
        if (!confirm) {
          return;
        }
      }
      this.PDF = { ...this.defaultPDF };
      if (this.isAdding) {
        this.isAdding = false;
        this.isEditing = false;
        return;
      }
      this.isAdding = true;
      this.isEditing = false;
    },
    addPDF() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        window.notify("Please verify your input and try again.", "warning");

        return;
      }
      if (!this.isEditing) {
        if (this.value) {
          const values = [...this.value, this.PDF];
          this.$emit("input", values);
        } else {
          this.$emit("input", [this.PDF]);
        }
      }
      this.isEditing = false;
      this.$v.$reset();
      return (this.PDF = { ...this.defaultPDF });
    },
    editPDF({ data }) {
      this.isEditing = true;
      this.PDF = data;
    },
    cancelEdit() {
      if (this.isEditing) {
        this.isEditing = false;
      }
      this.isAdding = false;
      this.PDF = { ...this.defaultPDF };
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-cell {
  width: 10%;
}

table td {
  text-align: center;
  padding: 0;
}
</style>
