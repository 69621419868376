import { register } from "register-service-worker";
import eventBus from "./modules/eventBus";

const updateMinutesInterval = 15;
function getMinsToMs(hr) {
  return hr * 60 * 1000;
}

function registerWorkbox() {
  if ("serviceWorker" in navigator) {
    register(`${process.env.BASE_URL}service-worker.js`, {
      ready(registration) {
        console.log("Service worker is active.");
        eventBus.$emit("swReady", { detail: registration });
      },
      registered(registration) {
        console.log(
          "Service Worker Registered: The system will check for updates every " +
            updateMinutesInterval +
            " minutes(s)."
        );
        setInterval(async () => {
          console.log("Checking for update....");
          try {
            await registration.update();
          } catch (error) {
            console.log("Error updating SW, check network.");
          }
        }, getMinsToMs(updateMinutesInterval)); // e.g. minute interval checks
      },
      cached() {
        console.log("Content has been cached for offline use.");
      },
      updatefound() {
        eventBus.$emit("swUpdateFound");
      },
      updated(registration) {
        console.log("New content is available; please refresh.", registration);
        eventBus.$emit("swUpdated", { detai: registration });
      },
      offline() {
        console.log("No internet connection found. App is running in offline mode.");
      },
      error(error) {
        console.error("Error during service worker registration:", error);
      }
    });
  }
}
export async function unregisterServiceWorker() {
  // unregister all registrations of servive worker
  const registrations = await navigator.serviceWorker.getRegistrations();
  for (const registration of registrations) {
    await registration.unregister();
  }
}
export async function installWaitingServiceWorker() {
  // unregister all registrations of servive worker
  const registrations = await navigator.serviceWorker.getRegistrations();
  for (const registration of registrations) {
    if (registration.waiting?.postMessage) {
      console.log("Found waiting SW");
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
    }
  }
}

export default registerWorkbox;
