<template>
  <div data-cy="clientGrid" class="main-view">
    <div class="utils d-flex align-items-center justify-content-end p-1">
      <IconButton
        @click="handleSelectAll"
        v-show="isSelecting"
        class="btn btn-outline-secondary mr-2 ml-auto"
        iconClass="mr-1"
        icon="check"
        >Select All</IconButton
      >
      <IconButton
        icon="list-ul"
        @click="handleSelecting"
        class="font-weight-bold mr-2 btn btn-outline-primary text-whie"
      >
        Select
      </IconButton>
      <button @click.stop class="menu-btn btn">
        <span></span>
        <span></span>
        <span></span>
        <div>
          <loader size="small" v-show="isLoading" />
          <icon-button type="button" @click="markAll" class="btn pointer" icon="eye">
            Mark as viewed
          </icon-button>
          <icon-button
            v-if="permissions.PathReportView"
            type="button"
            @click="handleDownloadCases"
            icon="print"
          >
            Print
          </icon-button>
          <icon-button
            v-if="permissions.PathReportSendByFax"
            type="button"
            icon="fax"
            @click="handleDistributeCases(DistributionMethodsEnum.Fax)"
          >
            Fax</icon-button
          >
          <icon-button
            v-if="permissions.PathReportSendByEmail"
            type="button"
            @click="handleDistributeCases(DistributionMethodsEnum.Email)"
            icon="envelope"
          >
            Email
          </icon-button>
          <icon-button
            v-if="permissions.PathReportSendByHL7"
            type="button"
            icon="h-square"
            @click="handleDistributeCases(DistributionMethodsEnum.HL7)"
          >
            Send by HL7
          </icon-button>
        </div>
      </button>
    </div>
    <dx-list
      @itemClick="handleCaseClick"
      :dataSource="Cases"
      :pullRefreshEnabled="true"
      selectionMode="all"
      @initialized="initializeGrid"
      @selectionChanged="selectionChanged"
    >
      <template v-slot:item="{ data }">
        <div class="card shadow">
          <div class="card-title d-flex align-items-center">
            <input
              class="mr-1"
              v-show="isSelecting"
              type="checkbox"
              :checked="isItemSelected(data.caseId)"
            />
            <h5 class="m-0">{{ shortenAccessionNumber(data.accessionNumber) }}</h5>
            <div class="d-flex ml-auto justify-content-center">
              <icon-button
                icon="envelope"
                iconClass="text-primary"
                @click.stop="createRequest(data)"
              />
              <icon-button v-bind="getStatusIcon(data)" />
            </div>
          </div>
          <div class="card-body">
            <div class="d-flex">
              <div class="avatar">
                <!-- <icon :icon="data.patientSex ? 'female' : 'male'" class="mr-1" /> -->
              </div>
              <div class="ml-2">
                <p>{{ data.patientName }}</p>
                <p v-show="data.patientDOB">
                  <icon icon="birthday-cake" class="mr-1" /> {{ formatDate(data.patientDOB) }}
                </p>
                <p v-show="data.accountNumber">
                  <icon icon="id-badge" class="mr-1" /> {{ data.accountNumber }}
                </p>
                <p v-show="data.patientMRN">
                  <icon icon="laptop-medical" class="mr-1" /> {{ data.patientMRN }}
                </p>
                <p><icon icon="calendar" class="mr-1" /> {{ formatDate(data.collectedOn) }}</p>

                <p v-show="data.providerName">
                  <icon icon="user-md" class="mr-1" />
                  {{ providerName }}
                </p>
                <p v-show="data.providerOfficeName">
                  <icon icon="clinic-medical" class="mr-1" /> {{ data.providerOfficeName }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </dx-list>
    <modal :status="isRequestOpen" @close="handleCloseRequest">
      <client-task-form
        :caseId="requestCaseId"
        @submit="handleCloseRequest"
        @cancel="handleCloseRequest"
      />
    </modal>
    <Modal :status="isDistributionOpen" @close="isDistributionOpen = false">
      <distribution-popup
        :method="distributionMethod"
        :caseIds="selectedCasesForDistribution"
        @close="isDistributionOpen = false"
      />
    </Modal>
  </div>
</template>

<script>
import CaseService from "../services/cases";
import { mapGetters, mapState } from "vuex";
import moment from "moment";
import { CaseStatusEnum, DistributionMethodsEnum } from "@/modules/enums";
import { DxList } from "devextreme-vue/list";
import Reports from "../services/Reports";
import Modal from "./common/Modal.vue";
import { AuditLogApi, CasesApi, DropdownApi, PathReportMessagesApi } from "../services";
import { createLogItem, filterAccessionNumber, getStatusIcon, formatSSN } from "@/modules/helpers";
import IconButton from "./common/IconButton.vue";
import { format } from "date-fns";
import Loader from "./common/Loader.vue";
import DistributionPopup from "./DistributionPopup.vue";
import { shortenAccessionNumber } from "@/modules/helpers";
import Icon from "./common/Icon.vue";
import ClientTaskForm from "./forms/ClientTaskForm.vue";
export default {
  name: "Cases-Grid-Client-User",
  components: {
    Modal,
    IconButton,
    DxList,
    Loader,
    DistributionPopup,
    Icon,
    ClientTaskForm
  },
  activated() {
    if (this.grid.reload) {
      this.grid.reload();
    }
  },
  data() {
    return {
      isSelecting: false,
      selectedItems: [],
      allSelected: false,
      Cases: CasesApi.searchStore,
      isDistributionOpen: false,
      selectedCasesForDistribution: [],
      taskPriorities: [],
      DistributionMethodsEnum,
      grid: {},
      distributionMethod: "",
      isFiltered: false,
      popupVisible: false,
      isRequestOpen: false,
      requestCaseId: null,
      report: {},
      pathReport: {},
      isLoading: false,
      scrolling: {
        showScrollbar: "always",
        useNative: true,
        preloadEnabled: true
      },
      selection: {
        mode: "multiple",
        allowSelectAll: true,
        showCheckBoxesMode: "always",
        selectAllMode: "page"
      },
      remoteOperations: {
        filtering: true,
        sorting: true,
        paging: true
      },
      caseGrid: {},
      grouping: {
        allowCollapsing: true
      },
      fixedFilters: null,
      today: moment().toDate(),
      lastMonth: moment().subtract(30, "days").toDate()
    };
  },
  created() {
    this.$store.dispatch("dropdowns/getPriorities");
    DropdownApi.getTaskPriorities().then(res => {
      this.taskPriorities = res || [];
    });
  },
  mounted() {},
  computed: {
    ...mapState({
      currentUser: state => state.currentUser,
      isReportOpen: state => state.report.isOpen,
      reportViewer: state => state.reportViewer,
      caseStatuses: state => state.caseStatuses,
      caseFilter: state => state.applicationSettings.accessionFilter,
      caseMode: state => state.applicationSettings.accessionMode,
      priorities: state => state.dropdowns.priorities,
      useZip: state => state.labSettings.DownloadMultiplePathReportsAsZipFile
    }),
    ...mapGetters(["permissions"]),
    isProvider() {
      const { isLabClientUser } = this.currentUser;
      if (isLabClientUser) {
        return true;
      }
      return false;
    },
    collectedOnFilter() {
      if (this.isFiltered) {
        return [this.lastMonth, this.today];
      }
      return null;
    },
    isViewedFilter() {
      if (this.isFiltered) {
        return false;
      }
      return null;
    },
    filterValues() {
      if (this.isFiltered) {
        return [
          ["collectedOn", ">=", this.lastMonth],
          "and",
          ["collectedOn", "<=", this.today],
          "and",
          ["isViewed", "=", false]
        ];
      } else {
        return [];
      }
    },
    statusFilter() {
      if (this.caseFilter === "open") {
        //! Cases whos staus is 1,2,3,4,5,6,7,9,10,12,13
        return [CaseStatusEnum.ReportedPrelim, CaseStatusEnum.Reported, CaseStatusEnum.ReReleased];
      }
      return null;
    },
    columns() {
      //Determines which columns to render depending if the user is a iPathWebLink User or a  iPathPro
      return [
        {
          type: "buttons",
          cellTemplate: "actionsTemplate",
          caption: "Action",
          width: "100px"
        },
        {
          dataField: "patientName",
          dataType: "string",
          allowReordering: true
        },
        {
          dataField: "caseStatus",
          dataType: "number",
          filterType: "include",
          lookup: {
            dataSource: this.caseStatuses.map((e, i) => ({ displayName: e, id: i })),
            displayExpr: "displayName",
            valueExpr: "id"
          }
        },
        {
          dataField: "patientSSN",
          allowReordering: true,
          caption: "SSN",
          dataType: "string",
          allowSearch: false,
          calculateDisplayValue: data => {
            if (data.patientSSN) {
              return formatSSN(data.patientSSN);
            }
            return "";
          }
        },
        {
          dataField: "patientMRN",
          allowReordering: true,
          caption: "MRN",
          dataType: "string"
        },
        {
          dataField: "accessionNumber",
          allowReordering: true,
          sortOrder: "asc",
          sortIndex: 1,
          caption: "Case #",
          dataType: "string",
          calculateFilterExpression: filterAccessionNumber(
            "accessionNumber",
            this.$store.state.labSettings.AccessionNumberingType
          )
        },
        {
          dataField: "providerName",
          allowReordering: true,
          allowSorting: true,
          caption: "Physician",
          dataType: "string"
        },
        {
          dataField: "collectedOn",
          caption: "Procedure Date",
          dataType: "date",
          sortIndex: 0,
          sortOrder: "desc",
          selectedFilterOperation: "between",
          filterType: "include",
          filterValue: this.collectedOnFilter
        },
        {
          dataField: "providerOfficeName",
          allowReordering: true,
          caption: "Location",
          dataType: "string"
        },
        {
          dataField: "isViewed",
          dataType: "boolean",
          visible: false,
          allowSearch: false,
          filterValue: this.isViewedFilter
        }
      ];
    }
  },
  watch: {
    isProvider(nv, ov) {
      if (ov != nv) {
        this.pageOffset = 0;
        this.refreshGrid(true);
      }
    },
    isDistributionOpen(nv) {
      if (!nv) {
        this.selectedCasesForDistribution = [];
        this.distributionMethod = "";
      }
    },
    caseFilter: {
      immediate: true,
      handler(nv) {
        if (nv === "all") {
          if (this.grid.clearFilters) {
            this.grid.clearFilters();
          }
        } else {
          if (this.grid.columnOptions) {
            this.grid.columnOptions("caseStatus", "filterValue", this.statusFilter);
          }
        }
      }
    },
    isFiltered: {
      immediate: true,
      handler(nv) {
        if (nv) {
          if (this.grid.refresh) {
            this.grid.refresh(true);
          }
        }
      }
    }
  },
  methods: {
    handleCloseRequest() {
      this.requestCaseId = null;
      this.isRequestOpen = false;
    },
    initializeGrid(event) {
      this.grid = event.component;
      this.grid.refresh = this.grid.reload;
      this.grid.clearSelection = this.grid.unselectAll;
    },
    shortenAccessionNumber,
    handleSelectAll() {
      if (!this.allSelected) {
        this.grid.selectAll();
        this.allSelected = true;
      } else {
        this.grid.unselectAll();
        this.allSelected = false;
      }
    },
    handleSelecting() {
      if (this.isSelecting) {
        this.grid.unselectAll();
        this.allSelected = false;
      }
      this.isSelecting = !this.isSelecting;
    },
    isItemSelected(caseId) {
      const inSelected = this.selectedItems.find(e => e.caseId === caseId);
      return !!inSelected;
    },
    selectionChanged(event) {
      this.selectedItems = [...this.selectedItems, ...event.addedItems];
      this.selectedItems = this.selectedItems.filter(e => {
        //Check if Item has been removed
        const inRemoved = event.removedItems.find(r => r.caseId === e.caseId);
        return !inRemoved;
      });
    },
    formatDate(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    handleCaseClick(data) {
      if (this.isSelecting) {
        const isSelected = data.component.isItemSelected(data.itemIndex);
        if (isSelected) {
          data.component.selectItem(data.itemIndex);
        } else {
          data.component.unselectItem(data.itemIndex);
        }
      } else {
        this.$router.push(`/accession/${data.itemData.caseId}`);
      }
      if (data.key) {
        if (data?.columnIndex && data.columnIndex < 2) {
          return;
        }
        this.$router.push(`/accession/${data.key}`);
      }
    },
    getStatusIcon,
    createRequest(data) {
      this.requestCaseId = data.caseId;
      this.isRequestOpen = true;
    },
    refreshGrid() {
      if (this.grid.refresh) {
        this.grid.refresh(true);
      }
    },
    getStatus({ caseStatus }) {
      if ([8, 11, 14].includes(caseStatus)) {
        return this.caseStatuses[caseStatus];
      }
      return "Pending";
    },
    handleViewPathReport(caseId) {
      this.$store.dispatch("report/viewPathReport", { caseId });
    },
    async handleDownloadCase({ data }) {
      try {
        const response = Reports.getSSRSReport({ caseId: data.caseId, format: "PDF" });
        const url = URL.createObjectURL(response);
        var a = document.createElement("a");
        a.href = url;
        a.download = `${data.accessionNumber}_REPORT.pdf`;
        a.click();
      } catch (error) {
        window.notify("Error occured downloading case report.");
      }
    },
    filterRecent() {
      this.isFiltered = !this.isFiltered;
    },
    markAsViewed({ data }) {
      const { isViewed, caseId } = data;
      const userMeta = {
        isViewed: !isViewed,
        viewedOn: new Date(),
        caseId
      };
      return CaseService.updateUserMeta(userMeta).then(() => {
        this.grid.clearSelection();
        this.grid.refresh();
      });
    },
    markAll() {
      const data = this.selectedItems;
      const readyCases = data.filter(e => !e.isViewed && [8, 11, 14].includes(e.caseStatus));
      if (data.length == 1 && ![8, 11, 14].includes(data[0].caseStatus)) {
        return window.alert("Case is still pending and cannot be marked as viewed.");
      }
      if (readyCases.length !== data.length) {
        return window.alert("Pending cases cannot be marked as viewed.");
      }
      return Promise.all(readyCases.map(e => this.markAsViewed({ data: e }))).then(() => {
        this.grid.refresh(true);
        this.grid.clearSelection();
      });
    },

    getLocation(data) {
      const contactEntry = data?.contacts?.find(e => e.isPrimary);
      if (contactEntry) {
        const {
          contact: { displayName }
        } = contactEntry;
        return displayName || "N/A";
      }
      return "N/A";
    },
    getFullName(data) {
      return data.patientLastName + ", " + data.patientFirstName;
    },
    async handleDownloadCases() {
      let cases = this.selectedItems;
      if (cases.length === 0) {
        return window.notify("No Cases selected.", "error");
      }
      cases = cases.filter(e => [8, 11, 14].includes(e.caseStatus));
      if (cases.length === 0) {
        return window.notify("Selected cases are still pending", "error");
      }
      this.isLoading = true;
      try {
        const caseIds = cases.map(e => e.caseId);
        const extension = this.useZip && caseIds.length > 1 ? "zip" : "pdf";
        const file = await CasesApi.getMultiCasePdf(caseIds, extension);
        const url = URL.createObjectURL(file);
        const a = document.createElement("a");
        a.href = url;
        if (cases.length === 1) {
          a.download = `${format(new Date(), "MM/dd/yyyy")}_${
            cases[0]?.accessionNumber || ""
          }_PATH_REPORT.pdf`;
        } else {
          a.download = `${format(new Date(), "MM/dd/yyyy")}_PATH_REPORTS.${extension}`;
        }
        a.click();
        PathReportMessagesApi.addPathReportMessage({
          caseIds: cases.map(e => e.caseId),
          method: 6
        });
        const logItem = createLogItem({}, 9, "Accession Grid");
        logItem.comments =
          "Downloaded the following pdfs " + cases.map(e => e.accessionNumber).join(" \n ");
        AuditLogApi.insertLogMessage(logItem);
      } catch (error) {
        window.notify("Error loading file", "error");
      }
      this.isLoading = false;
    },
    async handleDistributeCases(method) {
      const cases = this.selectedItems;
      if (cases.length === 0) {
        return window.notify("No Cases selected.", "error");
      }
      this.selectedCasesForDistribution = cases
        .filter(e => [8, 11, 14].includes(e.caseStatus))
        .map(e => e.caseId);
      if (!this.selectedCasesForDistribution.length) {
        return window.alert("Case(s) cannot be distributed, due to their status");
      } else if (cases.length !== this.selectedCasesForDistribution.length) {
        await window.alert("Some of your cases cannot be distributed, due to their status");
      }
      if (cases.length === 1) {
        const { caseStatus, patientFirstName, patientLastName } = cases[0];
        if (![8, 11, 14].includes(caseStatus)) {
          return window.alert(
            `<div class="text-center">
          Accession is still pending, and cannot be printed. <br> Patient Name : ${patientLastName}, ${patientFirstName}
          </div>`
          );
        }
      }
      this.distributionMethod = method;
      this.isDistributionOpen = true;
    }
  }
};
</script>
<style lang="scss" scoped>
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #2196f3;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
.menu-btn {
  position: relative;
  span {
    display: block;
    background-color: gray;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    margin: 1px;
  }
  & > div {
    display: none;
    position: absolute;
    left: -180px;
    z-index: 900;
    background-color: #fff;
    width: 200px;
    border: 1px solid gray;
    ::v-deep button {
      display: flex;
      align-items: center;
      & > svg {
        width: 10%;
        margin: 5px;
      }
      &:not(:last-child) {
        border-bottom: 1px solid gray;
      }
      font-weight: bold;
      width: 100%;
    }
  }
  &:focus-within > div {
    display: flex;
    flex-direction: column;
  }
}
.card {
  display: block;
  .card-title {
    margin-bottom: 2px;
  }
  .card-body {
    padding: 0px;
  }
  .avatar {
    display: flex;
    margin-right: 5px;
    & > svg {
      align-self: center;
      width: 50px;
      height: 50px;
    }
  }
  p {
    margin-bottom: 1px;
  }
}
::v-deep .dx-item.dx-list-item {
  margin: 10px auto 0px auto;
  border-top: initial;
}
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary {
  color: #6c757d;
  background-color: #fff;
  border-color: #6c757d;
}
::v-deep .dx-state-active {
  color: initial !important;
}
::v-deep
  .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)
  > td:not(.dx-focused) {
  cursor: pointer;
}
.main-view {
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}
</style>
