<template>
  <main v-on="$listeners">
    <div v-bind="$attrs">
      <slot></slot>
    </div>
  </main>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
main {
  flex: 1;
  margin: 0 auto;
  overflow: auto;
  & > div {
    background-color: $white;
  }
  @media screen and (max-width: 700px) {
    margin: 0;
    max-height: 100%;
  }
}
</style>
