<template>
  <span
    v-if="isExpired"
    :class="{ expire: isExpired }"
    class="text-center align-items-center d-flex h-100"
  >
    {{ formatDate(expiryDate) }} <icon class="mx-2" icon="exclamation-circle" />
  </span>
  <span v-else>
    {{ formatDate(expiryDate) }}
  </span>
</template>

<script>
import moment from "moment";
import Icon from "./Icon.vue";
export default {
  components: { Icon },
  name: "ExpiryTemplate",
  props: ["expiryDate"],
  computed: {
    isExpired() {
      if (this.expiryDate) {
        return moment(this.expiryDate).isBefore(moment(new Date()));
      }
      return false;
    }
  },
  methods: {
    formatDate(value) {
      if (value) {
        return moment(value).format("MM/DD/YYYY");
      }
    }
  }
};
</script>

<style>
.expire {
  color: #ff0000;
  font-weight: 700;
  font-size: 1rem;
}
</style>
