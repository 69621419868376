import api from "./api.js";

class AuditLog {
  constructor() {
    this.url = "/api/AuditLog";
  }
  get searchStore() {
    return api.createSearch(this.url + "/search");
  }
  get superUserLog() {
    return api.createSearch("/api/AuditLog/SuperUserSearch");
  }
  createSearch(url, labId) {
    return api.createSearch(this.url + url, undefined, { labId });
  }
  /**
   *
   * @param {object} message
   * @returns
   */
  insertLogMessage(message) {
    return api.put(this.url, message);
  }
  getLogPdf(logId, responseType = "blob") {
    return api.getFile(this.url + `/getPDF`, { logId }, responseType);
  }
}
const auditLog = new AuditLog();
export default auditLog;
