import api from "./api.js";
class Settings {
  constructor() {
    this.url = "/api/Settings";
  }
  get typeCodesSearch() {
    return api.createSearch(this.url + "/TypeCodes/search");
  }
  get labSettingsSearch() {
    return api.createSearch(this.url + "/LabSettings/Search");
  }
  get LabSettingGroupsSearch() {
    return api.createSearch(this.url + "/LabSettingGroups/search");
  }
  createSearch(url, labId) {
    return api.createSearch(this.url + url, undefined, { labId });
  }
  getLabSettings(labId) {
    return api.get(this.url + `/Lab/${labId}`);
  }

  editLabSettingsGrid(labSetting) {
    return api.put(this.url + "/LabSettingGrid", labSetting);
  }
  addLabSettingsGrod(labSetting) {
    return api.post(this.url + "/LabSettingGrid", labSetting);
  }
  editTypeCode(typeCode) {
    return api.put(this.url + "/TypeCodes", typeCode);
  }
  addTypeCode(typeCode) {
    return api.post(this.url + "/TypeCodes", typeCode);
  }

  getLabSettingsGrid(labId) {
    return api.get(this.url + `/LabSettingGrid/${labId}`);
  }
  getLabSettingsFilter() {
    return api.get(this.url + `/search/labsettingsfilterpaths`);
  }

  deleteTypeCode(data) {
    return api.delete(this.url + `/TypeCodes`, data);
  }
  editAdminSetting(data) {
    return api.put(this.url, data);
  }
}
export default new Settings();
