<template>
  <form @submit.prevent="handleSubmit" class="select-contact-popup container">
    <dx-grid-with-search
      @initialized="initGrid"
      :columns="columns"
      :dataSource="dataSource"
      :toolbar="toolbar"
    >
      <template v-slot:extraActions>
        <div class="d-flex">
          <icon-button
            :disabled="!permissions.ProviderCreateEdit"
            @click.stop="openModal = 'provider'"
            type="button"
            class="btn btn-primary mr-2"
            icon="user"
          >
            <span class="mx-1"> Add Provider </span>
          </icon-button>
          <icon-button
            :disabled="!permissions.LocationCreateEdit"
            @click.stop="openModal = 'location'"
            type="button"
            class="btn btn-primary mr-2"
            iconClass="text-danger"
            icon="hospital-alt"
          >
            <span class="mx-1"> Add Location </span>
          </icon-button>
        </div>
      </template>
      <template v-slot:actions-cell="{ data: { data } }">
        <button
          v-tooltip.top="
            data.isPrimary ? 'Primary' : !isSecondaryOnly(data) ? 'Alternate' : 'Secondary Only'
          "
          type="button"
          class="btn"
          tabindex="2"
          @click="() => !isSecondaryOnly(data) && setPrimary(data)"
        >
          <template v-if="data.isPrimary && !isSecondaryOnly(data)">
            <icon class="text-primary" icon="parking" />
          </template>
          <template v-else>
            <span class="secondary-icon font-weight-bold">S</span>
          </template>
        </button>
      </template>
      <template class="d-flex" v-slot:providerName="{ data }">
        <span
          class="contactLink"
          v-if="permissions.ProviderCreateEdit"
          @click="editProvider(data.data.provider.id)"
          >{{ providerName(data) }}</span
        >
        <span v-else>{{ providerName(data) }}</span>
      </template>
      <template class="d-flex" v-slot:locationName="{ data }">
        <span
          class="contactLink"
          v-if="permissions.LocationCreateEdit"
          @click="editLocation(data.data.location.id)"
          >{{ locationName(data) }}</span
        >
        <span v-else>{{ locationName(data) }}</span>
      </template>
    </dx-grid-with-search>
    <div class="d-flex justify-content-end">
      <button type="submit" class="btn btn-primary">Save</button>
    </div>
    <modal :status="openModal === 'provider'" @close="closeModal">
      <Provider
        @close="closeModal"
        @cancel="closeModal"
        :providerId="modalProviderId"
        :isModal="true"
      />
    </modal>
    <modal :status="openModal === 'location'" @close="closeModal">
      <Location
        @close="closeModal"
        @cancel="closeModal"
        :locationId="modalLocationId"
        :isModal="true"
      />
    </modal>
  </form>
</template>

<script>
import { dateRangeFilter, formatPhoneNumber } from "@/modules/helpers";
import DxGridWithSearch from "./common/DxGridWithSearch.vue";
import DataSource from "devextreme/data/data_source";
import { ProvidersApi } from "@/services";
import Icon from "./common/Icon.vue";
import { mapGetters, mapState } from "vuex";
import Provider from "./forms/CodeMaintenance/Providers/Provider.vue";
import Modal from "./common/Modal.vue";
import Location from "./forms/CodeMaintenance/Providers/Location.vue";
import IconButton from "./common/IconButton.vue";
export default {
  components: { DxGridWithSearch, Icon, Provider, Modal, Location, IconButton },
  props: {
    selectedContacts: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      dataSource: new DataSource({
        store: ProvidersApi.searchContacts,
        filter: [dateRangeFilter("properties.effectiveOn", "properties.expiryOn")]
      }),
      grid: {},
      selection: {
        mode: "multiple",
        allowSelectAll: true,
        showCheckBoxesMode: "always"
      },
      toolbar: {
        items: [
          {
            template: "extraActions",
            location: "after"
          }
        ]
      },
      openModal: null,
      modalProviderId: null,
      modalLocationId: null
    };
  },
  mounted() {
    this.$store.dispatch("dropdowns/getStates");
  },
  computed: {
    ...mapState({
      states: state => state.dropdowns.states
    }),
    ...mapGetters(["permissions"]),
    columns() {
      return [
        {
          sortIndex: 0,
          sortOrder: "asc",
          dataField: "provider.providerFullName",
          caption: "Provider",
          dataType: "string",
          cellTemplate: "providerName"
        },
        {
          dataField: "location.name",
          caption: "Location Name",
          dataType: "string",
          cellTemplate: "locationName"
        },
        {
          dataField: "location.address1",
          caption: "Address",
          dataType: "string",
          calculateCellValue(data) {
            return data?.location?.address1 || "N/A";
          }
        },
        {
          dataField: "location.address2",
          caption: "Address 2",
          dataType: "string",
          calculateCellValue(data) {
            return data?.location?.address2 || "N/A";
          }
        },
        {
          dataField: "location.city",
          caption: "City",
          dataType: "string",
          calculateCellValue(data) {
            return data?.location?.city || "N/A";
          }
        },
        {
          dataField: "location.state",
          lookup: {
            dataSource: this.states,
            valueExpr: "id",
            displayExpr: "displayName"
          },
          caption: "State",
          dataType: "string"
          // calculateCellValue(data) {
          //   return data?.location?.state || "N/A";
          // }
        },
        {
          dataField: "location.zipCode",
          caption: "Zip Code",
          dataType: "string",
          calculateCellValue(data) {
            return data?.location?.zipCode || "N/A";
          }
        },
        {
          dataField: "primaryPhone",
          dataType: "string",
          caption: "Contact Phone Number",
          calculateCellValue(data) {
            if (data.primaryPhone) {
              return formatPhoneNumber(data.primaryPhone);
            }
          }
        },
        {
          dataField: "primaryFax",
          allowSearch: false,
          caption: "Contact Primary Fax",
          dataType: "string",
          calculateCellValue(data) {
            if (data.primaryFax) {
              return formatPhoneNumber(data.primaryFax);
            }
          }
        },
        {
          type: "buttons",
          cellTemplate: "actions-cell",
          caption: "Actions",
          visibleIndex: 0,
          allowSearch: false
        }
      ];
    }
  },
  methods: {
    async initGrid({ component }) {
      this.grid = component;
    },
    handleSubmit() {
      const selectedContactData = this.grid.getSelectedRowsData();
      if (!selectedContactData?.length) {
        return;
      }
      this.$emit("submit", selectedContactData);
      //! Todo: All of the data below to emit an event and set the value.
    },
    isSecondaryOnly(option) {
      if (option.provider?.secondaryOnly) {
        return option.provider.secondaryOnly;
      } else if (option.contact?.provider?.secondaryOnly) {
        return option.contact.provider.secondaryOnly;
      } else {
        return false;
      }
    },
    setPrimary(option) {
      const selectedContactRows = this.grid.getSelectedRowsData();
      selectedContactRows.forEach(row => {
        row.isPrimary = false;
      });

      option.isPrimary = !option.isPrimary;
      this.grid.selectRows([option.contactId], true);
    },
    closeModal() {
      this.openModal = null;
      this.modalProviderId = null;
      this.modalLocationId = null;
      this.grid.refresh();
    },
    providerName({ data }) {
      return data?.provider?.providerFullName;
    },
    locationName({ data }) {
      return data?.location?.name;
    },
    editProvider(providerID) {
      this.openModal = "provider";
      this.modalProviderId = providerID;
    },
    editLocation(locationId) {
      this.openModal = "location";
      this.modalLocationId = locationId;
    }
  },
  name: "SelectContactPopup"
};
</script>

<style lang="scss" scoped>
.select-contact-popup {
  max-width: 100%;
}

.contactLink {
  color: #337ab7;
  cursor: pointer;
}
</style>
