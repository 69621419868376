import api from "./api.js";

class Task {
  constructor() {
    this.url = "/api/Tasks";
  }
  get searchStore() {
    return api.createSearch(this.url + "/search", "taskId");
  }
  searchTasks(search) {
    return api.get(this.url + "/search", search).then(response => {
      return response;
    });
  }
  insertTask(task) {
    return api.post(this.url, task);
  }
  updateTask(task) {
    return api.put(this.url, task);
  }
  deleteTask(id) {
    return api.delete(this.url + `?id=${id}`);
  }
  getAllTasks(labId) {
    return api.get(this.url + `?labId=${labId}`);
  }
  getTaskById(id) {
    return api.get(this.url + `/${id}`);
  }
  getTaskCounts() {
    return api.get(this.url + "/count");
  }
  markTasksAsViewed() {
    return api.put(this.url + "/MarkAllAsViewed");
  }
  checkHighPriorityTasks() {
    return api.put(this.url + "/MarkAllHighPriorityAsUnviewed");
  }
}
export default new Task();
