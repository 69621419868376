export function handleMissingCptPairs(data) {
  let missingCpts = [];
  if (Array.isArray(data)) {
    for (const specimen of data) {
      if (specimen?.cptCodesWithoutCptPair.length) {
        for (const cptCode of specimen.cptCodesWithoutCptPair) {
          if (!missingCpts.includes(cptCode)) {
            missingCpts.push(cptCode);
          }
        }
      }
    }
  } else {
    missingCpts = data?.cptCodesWithoutCptPair;
  }
  if (missingCpts?.length) {
    window.alert(
      `The following CPT Code Pairs are missing:<br><br>${missingCpts.join(
        "<br>"
      )}<br><br>Please review the setup of the Type Codes, "CPT Code Pair" in Lab Settings.`
    );
  }
  return;
}
