var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.noPrimary)?_c('div',{class:_vm.$attrs.class + ' contact-wrapper'},[(_vm.$attrs.label)?_c('label',{staticClass:"provider-label"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.labelName)}}),_c('icon-button',{staticClass:"text-primary align-self-start provider-search",attrs:{"icon":"search-location"},on:{"click":function($event){_vm.isSearchingContact = !_vm.isSearchingContact}}})],1):_vm._e(),_c('MultiSelectWithSearch',_vm._g(_vm._b({ref:"selector",attrs:{"data-testid":"contactSelector","multiple":true,"trackBy":"id","placeholder":"Select Providers","dataSource":_vm.dataSource,"label":"","columns":_vm.columns,"select":_vm.select,"sort":_vm.sort,"fixedFilter":_vm.rangeFilter,"filterType":"startsWith","tabIndex":_vm.tabProp},on:{"search-change":_vm.searchChange},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
return [(!_vm.isSecondaryOnly(option))?_c('span',{staticClass:"option__title",class:_vm.getClass(option)},[_vm._v(_vm._s(_vm.displayName(option)))]):_c('span',{staticClass:"option__title secondary_option"},[_c('span',{staticClass:"secondary-icon mr-2"},[_vm._v("S")]),_vm._v(" "+_vm._s(_vm.displayName(option))+" ")])]}},{key:"tag",fn:function(ref){
var option = ref.option;
return [_c('span',{staticClass:"custom__tag",class:{ isSecondary: _vm.isSecondaryOnly(option) }},[_c('span',[_vm._v(_vm._s(_vm.displayName(option)))]),_c('div',{staticClass:"custom__remove d-flex"},[_c('icon-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Remove option'),expression:"'Remove option'"}],staticClass:"btn text-danger",attrs:{"type":"button","icon":"trash-alt","tabindex":_vm.tabProp},on:{"click":function($event){$event.preventDefault();return _vm.removeOption(option)}}}),_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(
              option.isPrimary
                ? 'Primary'
                : !_vm.isSecondaryOnly(option)
                ? 'Alternate'
                : 'Secondary Only'
            ),expression:"\n              option.isPrimary\n                ? 'Primary'\n                : !isSecondaryOnly(option)\n                ? 'Alternate'\n                : 'Secondary Only'\n            ",modifiers:{"top":true}}],staticClass:"btn",attrs:{"type":"button","tabindex":_vm.tabProp},on:{"click":function () { return !_vm.isSecondaryOnly(option) && _vm.setPrimary(option); }}},[(option.isPrimary && !_vm.isSecondaryOnly(option))?[_c('icon',{staticClass:"text-primary",attrs:{"icon":"parking"}})]:[_c('span',{staticClass:"secondary-icon font-weight-bold"},[_vm._v("S")])]],2)],1)])]}}],null,false,3713067089),model:{value:(_vm.providers),callback:function ($$v) {_vm.providers=$$v},expression:"providers"}},'MultiSelectWithSearch',_vm.$attrs,false),_vm.$listeners)),_c('modal',{attrs:{"status":_vm.isSearchingContact},on:{"close":function($event){_vm.isSearchingContact = !_vm.isSearchingContact}}},[_c('select-contact-popup',{attrs:{"selectedContacts":_vm.value},on:{"submit":_vm.handleContactSelected}})],1)],1):_c('tag-input',_vm._g(_vm._b({attrs:{"dataSource":_vm.tagsSource,"valueExpr":"contactId","maxDisplayedTags":1,"displayExpr":_vm.displayName,"searchMode":"startswith","searchExpr":_vm.tagSearchExpr,"grayOutExpired":true,"selectAllMode":"page","enableSelectPopup":true,"selectedItems":_vm.selectedItems},on:{"tagClick":_vm.handleTagClick,"closePopup":_vm.handleClosePopup},model:{value:(_vm.providers),callback:function ($$v) {_vm.providers=$$v},expression:"providers"}},'tag-input',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }