import api from "./api.js";
class Reports {
  constructor() {
    this.url = "/api/Reports/";
  }
  get searchStore() {
    return api.createSearch(this.url);
  }
  createSearch(labId) {
    return api.createSearch(this.url, labId);
  }
  createSearchStore(groupId) {
    return api.createSearch("/api/ReportGroups/" + groupId + "/Reports");
  }
  PbiReportStore() {
    return api.createSearch(`/api/PowerBIReports`, "id");
  }
  getDailyReports(labId) {
    return api.get("/api/DailyReports", { labId });
  }
  getLabReport(data) {
    return api.getFileWithPost("/api/LabReport", data);
  }
  getReportParameters(reportId) {
    return api.get("/api/GetReportParams/" + reportId);
  }
  get cassetteListStore() {
    return api.createSearch("/api/GetCassetteList", "cassetteId");
  }
  get slideListStore() {
    return api.createSearch("/api/GetSlidesList", "slideId");
  }
  cassetteListWithHolds(query = {}) {
    return api.createSearch("/api/GetCassetteList/", "cassetteId", query);
  }
  slideListWithHolds(query = {}) {
    return api.createSearch("/api/GetSlidesList/", "slideId", query);
  }
  /**
   *
   * @param {number} id Id of the report to get information from.
   * @returns {Object} Returns an object of the report in the databse.
   */
  getReport(id) {
    return api.get(this.url + `/${id}`);
  }
  /**
   *
   * @param {Object} data // Report entity with new values
   * @returns {Object} //Returns the newly updated report
   */
  updateReport(data) {
    return api.put(this.url, data);
  }

  /**
   *
   * @param {Object} report Report entity to receive a file of.
   * @param {string} responseType The type of response, default "arrayBuffer"
   * @returns
   */
  getSSRSReport(report) {
    return api.getFile(`/api/GetSSRSReport`, report);
  }
  /**
   *
   * @param {Object} data Report object to delete
   * @returns
   */
  deleteReport(data) {
    return api.delete(this.url, data);
  }
  /**
   *
   * @param {number} reportId
   * @param {string} type
   * @returns {blob} returns a file of the request report.
   */
  getBlankReport(reportId, type) {
    return api.getFile("/api/GetBlankReport", { reportId }, type);
  }
  /**
   *
   * @param {number} id //Id of the path report
   * @returns {blob} Returns a blob of the report file.
   */
  getPathReportById(id) {
    return api.getFile(`/api/PathReports/${id}`, undefined, "blob");
  }
  /**
   *
   * @returns {string} Refreshes the SSRS server.
   */
  SSRSSYNC() {
    return api.get("/api/SSRSSync");
  }
  /**
   *
   * @param {Object} params
   * @param {number} params.caseId //Id of case to print.Reports
   * @param {number} params.specLabelCopies //Number of specimen labels to print.
   * @param {number} params.reqLabelCopies //Number of req labels to print.
   * @param {number} params.printerId //Id of the target printer.
   * @returns {string}
   */

  getLabels(params) {
    return api.get("/api/GetLabels", params);
  }
  /**
   *
   * @param {object} body
   * @param {number} body.fromCaseId
   * @param {number} body.toCaseId
   * @param {number} body.printerId
   * @param {number} body.specLabelCopies
   * @param {number} body.reqLabelCopies
   */
  getLabelsInRange(body) {
    return api.post("/api/GetLabelsInRange", body);
  }
  /**
   *
   * @param {Object} params
   * @param {number} params.printerId
   * @param {number} params.labId
   * @param {string} params.caseNumber
   * @param {number} params.specimenId
   * @param {number} params.numberOfCopies
   * @returns
   */
  printBlockLabels(params) {
    return api.get(`/api/PrintBlockLabels`, params);
  }
  /**
   *
   * @param {Object} params
   * @param {number} params.printerId
   * @param {number} params.labId
   * @param {string} params.caseNumber
   * @param {array} params.slideId
   * @param {number} params.numberOfCopies
   * @returns
   */
  printSlidesWorksheet(params) {
    return api.post(`/api/PrintWorksheet`, params);
  }
  /**
   *
   * @param {Object} params
   * @param {number} params.printerId
   * @param {number} params.labId
   * @param {string} params.caseNumber
   * @param {number} params.specimenId
   * @param {number} params.numberOfCopies
   * @param {Array} params.slideIds
   * @returns
   */
  printSlideLabels(labId, body) {
    return api.post(`/api/PrintSlideLabels`, body, undefined, { labId });
  }
  /**
   *
   * @param {Object} params
   * @param {number} params.printerId
   * @param {number} params.labId
   * @param {string} params.caseNumber
   * @param {number} params.specimenId
   * @param {number} params.numberOfCopies
   * @returns
   */
  printGlassSlideLabels(params) {
    return api.get(`/api/PrintGlassSlides`, params);
  }

  /**
   * @param {Object} params
   * @param {number} params.printerId
   * @param {number} params.LabId
   * @param {number} params.caseNumber
   * @param {number} params.numberOfcopies
   * @returns {string}
   */
  printTagNoteTemplate(params) {
    return api.post("/api/PrintTagNoteTemplate", params);
  }

  printBlockLabelsBatch(payload) {
    return api.post("api/Reports/PrintBlockLabelsInBatch", payload);
  }

  printGlassSlidesBatch(payload) {
    return api.post("api/Reports/PrintGlassSlidesInBatch", payload);
  }

  getLabelCount(payload) {
    return api.post("api/LabelsCounts", payload);
  }
}
export default new Reports();
