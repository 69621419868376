<template>
  <fieldset class="form-row justify-content-between align-items-center my-3">
    <div class="col d-flex align-items-end">
      <zip-code-input
        label="Zip Code"
        v-model="address.zipCode"
        v-stream:blur="zipCodeBlur$"
        name="zipCode"
        :tabindex="tabProp || 0"
        :validator="$v.zipCode"
      />
      <icon-button
        :tabIndex="tabProp || 5"
        type="button"
        class="btn text-primary"
        v-tooltip="'Open zip search'"
        @click="toggleModal"
        icon="map-pin"
      />
    </div>
    <div class="col">
      <text-input
        ref="city"
        name="city"
        id="city"
        label="City"
        max-length="21"
        v-model="address.city"
        :tabindex="tabProp || 0"
        :validator="$v.city"
      />
    </div>
    <div class="col">
      <select-input
        label="State"
        id="state"
        name="state"
        :items="states"
        v-model="address.state"
        :tabindex="tabProp || 0"
        :validator="$v.state"
      />
    </div>
    <modal :status="isModalOpen" @close="toggleModal">
      <div class="p-3">
        <input
          label="Zip Code"
          class="col form-control mb-2"
          placeholder="Search"
          v-model="query"
          ref="search"
          name="zipCode"
          v-focus
          :tabindex="tabProp || 0"
        />
        <DxDataGrid
          class="address_matcher"
          :data-source="zipCodeMatches$"
          :height="300"
          :width="'25vw'"
          :selection="selection"
          :onRowClick="itemSelected"
          @content-ready="pushToObserver"
          :searchEnabled="true"
          noDataText="Type a zip code to begin your search"
          :cacheEnabled="false"
        />
      </div>
    </modal>
  </fieldset>
</template>

<script>
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  tap
} from "rxjs/operators";
import ZipCodeInput from "../ZipCodeInput.vue";
import TextInput from "./TextInput.vue";
import DxDataGrid from "devextreme-vue/data-grid";
import Modal from "./Modal.vue";
import { ZipCodesApi } from "@/services";
import { fromEvent, merge } from "rxjs";
import { mapState } from "vuex";
import SelectInput from "./SelectInput.vue";
import IconButton from "./IconButton.vue";
import { TzDbCodesEnum } from "@/modules/enums";
export default {
  components: { ZipCodeInput, TextInput, Modal, DxDataGrid, SelectInput, IconButton },
  props: {
    value: {
      default() {
        return {};
      }
    },
    tabProp: {
      type: Number
    },
    validatorProp: {
      required: false
    }
  },
  name: "AddressLookup",
  data() {
    return {
      isModalOpen: false,
      query: null,
      selection: {
        mode: "single",
        showCheckBoxesMode: true
      },
      zipCodeTzId: null
    };
  },
  mounted() {
    if (!this.states?.length) {
      this.$store.dispatch("dropdowns/getStates");
    }
  },
  validations() {
    return this.validatorProp || {};
  },
  computed: {
    ...mapState({ states: state => state.dropdowns.states }),
    address: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
        return value;
      }
    },
    columns() {
      return [
        { dataField: "City", dataType: "string" },
        {
          dataField: "zipCode",
          dataType: "string"
        },
        {
          dataField: "state"
        }
      ];
    }
  },
  methods: {
    pushToObserver(data) {
      this.contentReady$.next(data);
    },
    toggleModal() {
      this.isModalOpen = !this.isModalOpen;
      if (!this.isModalOpen) {
        this.$nextTick(() => {
          this.$refs.city.focus();
        });
      }
    },
    itemSelected({ data }) {
      const { state, zip_code, city, timezone } = data;
      const targetState = this.states.find(e => e.displayName === state);
      this.address.state = targetState?.id;
      this.address.city = city;
      this.address.zipCode = zip_code;
      this.zipCodeTzId = TzDbCodesEnum[timezone];
      this.$emit("zipCodeSelected", this.zipCodeTzId);
      if (this.isModalOpen) {
        this.isModalOpen = false;
        this.$nextTick(() => {
          this.$refs.city.focus();
        });
      }
    }
  },
  domStreams: ["zipCodeBlur$", "cityClick$", "search$", "contentReady$"],
  subscriptions() {
    const zipCodeBlur = this.zipCodeBlur$.pipe(
      filter(data => {
        const { event } = data;
        const { value } = event.msg.target;
        return value?.length > 4 && /^[0-9]+$/.test(value);
      }),
      map(({ event }) => {
        const { value } = event.msg.target;
        this.mode = "info";
        this.query = value;
        return value;
      })
    );
    const searchApi$ = this.$watchAsObservable("query").pipe(
      map(({ newValue }) => newValue),
      filter(value => value?.length > 4 && /^[0-9]+$/.test(value)),
      distinctUntilChanged()
    );
    const zipCodeMatches$ = searchApi$.pipe(
      switchMap(async zipCode => {
        const data = await ZipCodesApi.getZipInfo(zipCode);
        if (data?.error_code) {
          throw new Error(data.error_msg);
        }
        if (data?.acceptable_city_names?.length) {
          if (!data.acceptable_city_names.find(e => e.city === data.city)) {
            data.acceptable_city_names = [
              { city: data.city, state: data.state },
              ...data.acceptable_city_names
            ];
          }
          return data.acceptable_city_names.map(match => {
            return {
              ...match,
              zip_code: zipCode,
              timezone: data.timezone.timezone_identifier
            };
          });
        }
        return [{ ...data, timezone: data.timezone.timezone_identifier }];
      }),
      tap(payload => {
        if (payload.length === 1 && !this.isModalOpen) {
          return this.itemSelected({ data: payload[0] });
        }
        if (payload?.length) {
          return (this.isModalOpen = true);
        }
      }),
      catchError((e, s) => {
        window.notify(e.message, "error");
        return s;
      })
    );
    const keyboardNavigation$ = this.contentReady$.pipe(
      map(data => {
        const { component } = data;
        const searchPanel = this.$refs.search;
        searchPanel.focus();
        if (!component.getSelectedRowKeys()?.length) {
          component.selectRowsByIndexes([0]);
        }
        return component;
      }),
      switchMap(grid => {
        function selectNextRow(change) {
          const totalCount = grid.totalCount();
          const currentSelection = grid.getSelectedRowKeys();
          const currentSelectionIndex = grid.getRowIndexByKey(currentSelection[0]);
          if (currentSelectionIndex + change > -1 && currentSelectionIndex + change < totalCount) {
            const rowElements = grid.getRowElement(currentSelectionIndex + change);
            rowElements.forEach(element => {
              element.scrollIntoView(false);
            });
            const newRowKey = grid.getKeyByRowIndex(currentSelectionIndex + change);
            return grid.selectRows([newRowKey]);
          }
          return;
        }
        const keyDown$ = fromEvent(this.$el, "keydown");
        const arrowUp$ = keyDown$.pipe(
          filter(event => event.keyCode === 38),
          tap(event => event.preventDefault()),
          map(() => -1),
          tap(selectNextRow)
        );
        const arrowDown$ = keyDown$.pipe(
          filter(event => event.keyCode === 40),
          tap(event => event.preventDefault()),
          map(() => 1),
          tap(selectNextRow)
        );
        const enter$ = keyDown$.pipe(
          filter(e => e.keyCode === 13),
          tap(e => {
            e.preventDefault();
            e.stopPropagation();
          }),
          tap(() => {
            const data = grid.getSelectedRowsData();
            this.itemSelected({ data: data[0] });
          }),
          take(1)
        );
        return merge(arrowDown$, arrowUp$).pipe(switchMap(() => enter$));
      })
    );
    return {
      zipCodeMatches$,
      zipCodeBlur,
      keyboardNavigation$
    };
  }
};
</script>

<style lang="scss" scoped></style>
