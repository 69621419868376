<template>
  <div>
    <form @submit.prevent="handleReReport" v-shortkey="saveShortkey" @shortkey="handleReReport">
      <h2>{{ propReason ? "Edit Reason for Change" : "Re-Report" }}</h2>
      <SelectInput
        :validator="$v.type"
        :items="types"
        v-model="type"
        label="Edit Type"
        :disabled="!permissions.CaseEditAfterReportedDiagnostic"
      />
      <text-area-input
        :validator="$v.reasonForChange"
        :resize="false"
        ref="reasonForChange"
        rows="5"
        label="Reason"
        v-model="reasonForChange"
        :generalMacrosEnabled="true"
        :startHeight="propReason ? '25vw' : 'auto'"
        :width="propReason ? '80vw' : '100%'"
      />
      <div class="d-flex justify-content-end align-items-end">
        <button type="button" @click="cancel" class="btn btn-danger">Cancel</button>
        <button type="submit" class="btn mx-1 btn-primary">Submit</button>
      </div>
    </form>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import TextAreaInput from "./TextAreaInput.vue";
import { mapGetters, mapState } from "vuex";
import SelectInput from "@/components/common/SelectInput.vue";
import { altKey } from "@/modules/helpers";
import { CasesApi, MacrosApi } from "../services";
import { MacroTypeEnum } from "@/modules/enums";
import { handleErrors } from "@/modules/handleErrors";

export default {
  components: { TextAreaInput, SelectInput },
  props: ["propReason"],
  data() {
    return {
      type: "",
      reasonForChange: "",
      types: [
        {
          id: 1,
          displayName: "Amended"
        },
        {
          id: 2,
          displayName: "Addendum"
        },
        {
          id: 3,
          displayName: "Corrected"
        },
        { id: 4, displayName: "NonDiagnostic" }
      ],
      saveShortkey: altKey("s"),
      isMacroOpen: false,
      positionToPlaceCursor: 0,
      generalMacros: []
    };
  },
  mounted() {
    this.type = this.$store.state.accessionStore.editType;
    if (this.propReason?.id) {
      this.reasonForChange = this.propReason.reasonForChange;
      this.type = this.propReason.editType;
    } else {
      this.reasonForChange = this.$store.state.accessionStore.reasonForChange;
      if (
        !this.permissions.CaseEditAfterReportedDiagnostic &&
        !this.labSettings.AllowCaseEditTypeBilling
      ) {
        this.type = 4;
      }
    }
    if (this.labSettings.AllowCaseEditTypeBilling) {
      this.types.push({ id: 6, displayName: "Billing" });
    }
    if (this.labSettings.AllowCaseEditReasonOther) {
      this.types.push({ id: 5, displayName: "Other" });
    }
    MacrosApi.getMacrosByUserAndType({
      userId: this.currentUser.id,
      macroTypeId: MacroTypeEnum.General,
      loadOptions: {}
    }).then(res => {
      this.generalMacros = res.data || [];
    });
  },
  computed: {
    ...mapState({
      reReportPopup: state => state.accessionStore.reReportPopup,
      labSettings: state => state.labSettings,
      currentUser: state => state.currentUser
    }),
    ...mapGetters(["permissions"])
  },
  validations() {
    return {
      type: {
        required
      },
      reasonForChange: {
        required
      }
    };
  },
  methods: {
    async handleReReport() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        window.notify("Missing required fields, please try again.", "warning");
        return;
      }
      if (this.propReason?.id) {
        try {
          await CasesApi.editEditReason({
            caseId: this.propReason.caseId,
            id: this.propReason.id,
            editTypeId: this.type,
            reasonForChange: this.reasonForChange
          });
          window.notify("Reason for Change updated.");
          this.$emit("edit");
        } catch (error) {
          handleErrors(error);
        }
      } else {
        if ([1, 2, 3].includes(this.type)) {
          if (this.$route.name !== "SpecimenResults") {
            this.$router.push({ ...this.$route, name: "SpecimenResults" });
          }
        } else if (this.$route.name !== "CaseView") {
          this.$router.push({ ...this.$route, name: "CaseView" });
        }
        this.$store.dispatch("accessionStore/ammendCase", {
          editType: this.type,
          reasonForChange: this.reasonForChange
        });
      }
    },
    cancel() {
      if (this.propReason?.id) {
        this.$emit("cancel");
      } else this.$store.commit("accessionStore/setReReportPopup", false);
    }
  }
};
</script>

<style lang="scss" scoped></style>
