import { labSettingsEnum } from "@/modules/labSettingsEnum";
import { SettingsApi } from "../services/index";
import eventBus, { OPEN_STAGING_POPUP } from "@/modules/eventBus";

const initialState = labSettingsEnum;

export const labSettings = {
  namespaced: true,
  state: () => ({ ...initialState }),
  getters: {
    isNumeric(state) {
      return Boolean(state.SpecimenNumberingTypes);
    }
  },
  mutations: {
    setLabSettings(state, payload) {
      for (const property in initialState) {
        if (property in payload) {
          state[property] = payload[property];
        } else {
          state[property] = initialState[property];
        }
      }
    }
  },

  actions: {
    getLabSettings({ commit, rootState, dispatch }) {
      const labId = rootState.currentLab;
      const { userInStaging } = rootState.applicationSettings;
      SettingsApi.getLabSettings(labId).then(res => {
        const stagingSetting = res.find(e => e.displayName === "Use Staging Environment");
        // If environment is staging and lab is not set to staging
        if (
          (!stagingSetting || [0, null, "0"].includes(stagingSetting?.value)) &&
          process.env.VUE_APP_STAGE === "Staging" &&
          !userInStaging
        ) {
          eventBus.$emit(OPEN_STAGING_POPUP, true);
        }
        // If environment is production and lab is set for staging.
        if ([1, "1"].includes(stagingSetting?.value) && !process.env.VUE_APP_STAGE) {
          eventBus.$emit(OPEN_STAGING_POPUP, false);
        }
        var labSettings = {};
        res.forEach(e => {
          let name = e.displayName ? e.displayName.replace(/\W+/gi, "") : "";
          const settingsNames = Object.keys(labSettingsEnum);
          if (!settingsNames.includes(name)) {
            // Some settings were renamed, which was causing issues
            if (settingsNames.includes("Lab" + name)) {
              name = "Lab" + name;
            } else {
              console.log(`Lab setting "${name}" does not exist in the front end.`);
              labSettings[name] = null;
            }
          }
          // Incorrectly formatted default font sizes can cause formatting issues
          if (name === "DefaultEditorFontSize" && !/^\d+pt$/.test(e.value)) {
            const number = /\d+/g.exec(e.value)?.join("");
            if (number) {
              e.value = number + "pt";
            } else {
              window.notify(
                `Value "${e.value}" for lab setting Default Editor Font Size is not valid.`,
                "error"
              );
              e.value = "11pt";
            }
          }
          // Incorrectly formatted default font sizes can cause formatting issues
          if (name === "DefaultEditorFontSize" && !/^\d+pt$/.test(e.value)) {
            const number = /\d+/g.exec(e.value)?.join("");
            if (number) {
              e.value = number + "pt";
            } else {
              window.notify(
                `Value "${e.value}" for lab setting Default Editor Font Size is not valid.`,
                "error"
              );
              e.value = "11pt";
            }
          }
          // Incorrectly formatted default font sizes can cause formatting issues
          if (name === "DefaultEditorFontSize" && !/^\d+pt$/.test(e.value)) {
            const number = /\d+/g.exec(e.value)?.join("");
            if (number) {
              e.value = number + "pt";
            } else {
              window.notify(
                `Value "${e.value}" for lab setting Default Editor Font Size is not valid.`,
                "error"
              );
              e.value = "11pt";
            }
          }
          if (/^[0-9]+$/i.test(e.value)) {
            e.value = Number(e.value);
          }
          if (name === "SpecimenContainerLabelsPerSpecimen") {
            commit("sessionDetails/setLabelPrintAmount", e.value, { root: true });
          }
          labSettings[name] = e.value;
        });
        dispatch("dropdowns/getStates", null, { root: true });
        commit("setLabSettings", labSettings);
        return labSettings;
      });
    }
  }
};
