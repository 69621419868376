import api from "./api.js";
class Exports {
  constructor() {
    this.url = "/api/Exports";
  }
  get searchExports() {
    return api.createSearch(this.url + "/search");
  }
  downloadExport(id) {
    return api.getFile(this.url + `/${id}`);
  }
}
export default new Exports();
