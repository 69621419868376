import api from "./api.js";
class Prefix {
  constructor() {
    this.url = "/api/Prefix";
  }
  get searchStore() {
    return api.createSearch(this.url + "/search");
  }
  createSearch(url, labId) {
    return api.createSearch(this.url + url, "id", { labId });
  }
  addPrefix(prefix) {
    return api.post(this.url, prefix);
  }
  updatePrefix(prefix) {
    return api.put(this.url, prefix);
  }
  getPrefixById(id) {
    return api.get(this.url + `/${id}`);
  }
}
export default new Prefix();
