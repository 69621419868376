<template>
  <div class="text-input" data-private="redact">
    <label v-if="!noLabel" :for="$attrs.name" :id="$attrs.id || $attrs.name">
      <b>{{ label }}</b>
    </label>
    <DxTextBox
      ref="phoneNumber"
      v-bind="$attrs"
      :value="displayValue"
      :inputAttrs="{ id: $attrs.id || $attrs.name, name: $attrs.name }"
      @input="handleInput"
      @value-changed="updateValue"
      :tabIndex="tabProp"
    />
    <div v-if="(validator && validator.$error) || (validator && validator.$invalid)">
      <div
        class="validation-error"
        v-for="(key, index) in Object.keys(validator.$params)"
        :key="index"
      >
        <span class="error" v-if="!validator[key]">
          {{ validatorMsgMap[key] }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { formatPhoneNumber, validatorMsgMapBase } from "@/modules/helpers";
import DxTextBox from "devextreme-vue/text-box";
export default {
  name: "PhoneInput",
  inheritAttrs: false,
  components: {
    DxTextBox
  },
  props: {
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    value: {
      type: String
    },
    validatorMsgMap: {
      type: Object,
      default: () => {
        return validatorMsgMapBase;
      }
    },
    required: {
      type: Boolean
    },
    validator: {
      type: Object,
      default: null
    },
    mask: {
      default() {
        return "+1(999)000-0000";
      }
    },
    noLabel: {
      type: Boolean
    },
    pattern: {
      type: String,
      default: "[0-9]{3}-[0-9]{3}-[0-9]{4}"
    },
    tabProp: {
      type: Number
    }
  },
  data() {
    return {
      displayValue: ""
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        if (nv) {
          this.displayValue = formatPhoneNumber(nv);
        } else {
          this.displayValue = "";
        }
        this.updateValue({ value: nv });
      }
    }
  },
  computed: {
    phoneNumber: {
      get() {
        return this.value;
      },
      set(value) {
        return this.$emit("input", value);
      }
    }
  },
  methods: {
    updateValue(data) {
      const { value } = data;
      if (/\D/.test(value)) {
        return this.$emit("input", value.replaceAll(/\D/g, ""));
      }
      return value;
    },
    handleInput({ event }) {
      if (event?.target?.value) {
        this.displayValue = formatPhoneNumber(event.target.value) || event.target.value;
      } else {
        this.displayValue = "";
        this.$emit("input", "");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
label {
  min-height: 16px;
}
.error {
  font-size: 0.75rem;
}
</style>
