<template>
  <div>
    <label for="img"><b>Images</b></label>
    <div class="images d-flex flex-wrap justify-content-center">
      <slot name="images" :images="images" :remove="removeFile"></slot>
    </div>
    <input class="d-none" type="file" id="img" ref="fileInput" multiple @input="onSelectFile" />
    <div class="button-group d-flex">
      <AddBtn type="button" @click="openUploader" />
      <button
        v-if="Boolean(images.length)"
        type="button"
        @click="clear"
        class="btn btn-outline-danger mx-2"
      >
        Clear
      </button>
    </div>
  </div>
</template>

<script>
import AddBtn from "@/components/common/AddButton.vue";

export default {
  name: "ImageUpload",
  components: {
    AddBtn
  },
  props: ["value"],
  data() {
    return {
      files: []
    };
  },
  watch: {},
  computed: {
    images: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    clear() {
      const images = this.images.filter(file => {
        if (typeof file.image === "string") {
          file.isDeleted = true;
          return true;
        }
        return false;
      });
      this.$emit("input", images);
    },
    openUploader() {
      this.$refs.fileInput.click();
    },
    change(event) {
      this.files = event.target.files;
    },
    onSelectFile() {
      const input = this.$refs.fileInput;
      const files = input.files;
      if (files && files.length) {
        const values = Array.from(files, file => {
          return {
            image: file,
            type: "",
            isDeleted: false
          };
        });
        files.forEach((file, i) => {
          const reader = new FileReader();
          reader.onload = e => {
            this.images.push({ ...values[i], data: e.target.result });
          };
          reader.readAsDataURL(file);
        });
      }
      input.value = null;
    },
    removeFile(index) {
      const file = this.images[index];
      if (typeof file.image === "string") {
        file.isDeleted = true;
        return this.$emit("input", this.images);
      }
      return this.$emit("input", [...this.images.slice(0, index), ...this.images.slice(index + 1)]);
    }
  }
};
</script>

<style lang="scss" scoped></style>
