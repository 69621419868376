import { from } from "rxjs";
import { concatMap, last, retry, scan } from "rxjs/operators";
import IcdRules from "../services/IcdRules";

export default {
  caseNumber: null,
  protocolId: 0,
  labId: 0,
  specimenOrder: null,
  icdCodes: [], // In results
  bodyPartId: null,
  siteId: 0,
  subDescriptionId: 0,
  hopperId: 0,
  gross: "", // In results
  hold: "",
  grosser: "",
  cutter: "",
  clinical: "", // In results
  providers: [],
  pathologists: [],
  resultsMacros: [],
  cptCodes: [],
  interfaceDiagnosis: "",
  diagnosis: "",
  microscopic: "",
  notes: "",
  caseNotes: "",
  macroIsModified: false,
  isICDModified: false,
  prostateLength: null,
  prostatePieces: null,
  gleasonScore: "",
  tumorSize: null,
  prostateSpecificAntigen: 0,
  tables: []
};

export function icdRulesEngine$(icdCodes, specimen, caseDetails) {
  return from(icdCodes).pipe(
    concatMap(async code => {
      const response = await IcdRules.findBillableCode({
        caseReceivedOn: caseDetails.receivedOn,
        searchFor: code.displayCode.replace(/\.([0-9]+)/gi, ""),
        specimenDiagnosis: specimen.diagnosis,
        specimenSite: specimen.site ?? ""
      });
      return [response, code];
    }),
    scan((acc, curr) => {
      acc.push(curr);
      return acc;
    }, []),
    retry(2),
    last(),
    concatMap(async engineOutput => {
      const nonBillableCodes = [];
      let newCodes = icdCodes;
      for (const [response, code] of engineOutput) {
        if ([3, 4, 6, 3, 5, 7, 8].includes(response.detail) && !code.isBillable) {
          nonBillableCodes.push(code.displayCode);
        } else if ([2].includes(response.detail)) {
          const { replacementIcdCode } = response;
          newCodes = icdCodes.map(icd => {
            if (icd.id === code.id && replacementIcdCode !== icd.id) {
              return replacementIcdCode;
            }
            return icd;
          });
        }
      }
      if (nonBillableCodes.length) {
        const message = `Non billable icd code found on specimen ${
          specimen.specimenOrder
        }.<br> Code(s): ${nonBillableCodes.join(", ")}`;
        window.alert(message);
      }
      return { codes: newCodes, target: specimen };
    })
  );
}

export function removeDuplicates(myArr, prop = "id") {
  return myArr.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
}
