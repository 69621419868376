import api from "./api.js";
class Proxy {
  constructor() {
    this.url = "/api/Proxies";
  }
  get searchProxies() {
    return api.createSearch(this.url, "id");
  }
  addProxy(payload) {
    return api.post(this.url, payload);
  }
  editProxy(payload) {
    return api.put(this.url, payload);
  }
  getProxiesForUser() {
    return api.get(this.url + "/ForCurrentUser");
  }
}
export default new Proxy();
