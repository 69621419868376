export const SET_G_CODE = "SET-G-CODE";
export const OPEN_G_CODE = "OPEN-G-CODE";
export const CLOSE = "close";
export const SET_FREE_TEXT_SEARCH_FILTERS = "SET-FREE-TEXT-FILTERS";
export const RENDER_FREE_TEXT_SEARCH = "RENDER-FREE-TEXT-SEARCH";
export const RUN_DWT_SCANNER = "RUN-DWT-SCANNER";
export const CLOSE_DWT_SCANNER = "CLOSE-DWT-SCANNER";
export const CLEAR_FREE_TEXT_FILTERS = "CLEAR-FREE-TEXT-FILTERS";

/** TWAIN EVENTS */
export const SHOW_INSTALL_UI = "SHOW_INSTALL_UI";
export const CLOSE_INSTALL_UI = "CLOSE_INSTALL_UI";
export const SCANNER_READY = "SCANNER-READY";
export const SCANNED_IMAGE = "SCANNED_IMAGE";

export const WEB_TWAIN_ID = "IP-PRO-TWAIN";
