<template>
  <div id="app">
    <layout>
      <keep-alive include="Dashboard,QuickSignout">
        <router-view />
      </keep-alive>
    </layout>
    <StagingPopup />
    <update-popup />
  </div>
</template>
<script>
import UpdatePopup from "./components/UpdatePopup.vue";
import { mapState } from "vuex";
import Layout from "./components/common/Layout.vue";
import { exhaustMap, map, take } from "rxjs/operators";
import { fromBusEvent, START_SPELLCHECK } from "./modules/eventBus";
import { UserTypesEnum } from "./modules/enums";
import StagingPopup from "./components/StagingPopup.vue";

export default {
  name: "IntelliPath-Pro",
  components: { UpdatePopup, Layout, StagingPopup },
  subscriptions() {
    const getUserDictionary$ = fromBusEvent(START_SPELLCHECK).pipe(
      map(wscInstance => {
        return {
          wscInstance,
          dictionaryName: this.generatedDictionaryName,
          existingDictionary: wscInstance.getUserDictionaryName(),
          oldName: this.oldGeneratedDictionaryName
        };
      }),
      take(1),
      exhaustMap(connectToDictionary)
    );
    return {
      getUserDictionary$
    };
  },
  computed: {
    ...mapState({
      userDictionaryName: state => state.applicationSettings.userDictionaryName,
      currentLab: state => state.currentLab,
      currentUser: state => state.currentUser,

      updatePrompt: state => state.updatePrompt
    }),
    oldGeneratedDictionaryName() {
      const { firstName, lastName, userTypeId, labLocation } = this.currentUser;
      if (userTypeId !== UserTypesEnum.SuperUser) {
        return `${firstName}${lastName}${labLocation || ""}Dictionary`.replace(/\s+/i, "");
      }
      return `${firstName}${lastName}Dictionary`.replace(/\s+/i, "");
    },
    generatedDictionaryName() {
      if (this.userDictionaryName) {
        return this.userDictionaryName;
      }
      const { id } = this.currentUser;
      const newDictionaryName = `${id.replace(/\W/g, "")}Dictionary`;
      this.$store.commit("applicationSettings/setUserDictionaryName", newDictionaryName);
      return newDictionaryName;
    }
  },
  created() {
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    mediaQuery.addEventListener("change", this.handleMediaResize);
    this.handleMediaResize(mediaQuery);
    window.addEventListener("keydown", this.handleKeydown);
  },
  beforeDestroy() {
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    mediaQuery.removeEventListener("change", this.handleMediaResize);
    window.removeEventListener("keydown", this.handleKeydown);
  },
  methods: {
    handleMediaResize(event) {
      this.$store.commit("setMobileView", event.matches);
    },
    handleKeydown({ key }) {
      key = key?.toLowerCase();
      if (["y", "n"].includes(key)) {
        const buttons = document.getElementsByClassName("dx-dialog-buttons");
        if (buttons?.length) {
          const buttonToClick = document.querySelector(
            `[aria-label="${key === "y" ? "Yes" : "No"}"]`
          );
          if (Object.keys(buttonToClick)) {
            buttonToClick.focus();
            setTimeout(() => buttonToClick.click(), 100);
          }
        }
      }
    }
  }
};

async function connectToDictionary({ wscInstance, dictionaryName, existingDictionary, oldName }) {
  let wordsToAdd = [];
  async function migrateWords(wordlist) {
    for (const word of wordlist) {
      const wordToAdd = word;
      await wscInstance.addWordToUserDictionary(wordToAdd, function (word) {
        console.log("Added '" + word.wordlist[0] + "' to dictionary.");
      });
    }
  }
  if (existingDictionary) {
    if (existingDictionary === dictionaryName) {
      return;
    }
    if (existingDictionary !== dictionaryName && oldName.length < 51) {
      await wscInstance.getUserDictionary(oldName, function (userDictionary) {
        if (userDictionary.wordlist.length) {
          wordsToAdd = userDictionary.wordlist;
        }
      });
    }
  }
  try {
    await new Promise((res, rej) => wscInstance.createUserDictionary(dictionaryName, res, rej));
    console.log("Created the user dictionary for %s", dictionaryName);
  } catch (error) {
    if (!error.message.toLowerCase().includes(`already exists`)) {
      window.alert("Error creating user dictionary: " + error.message);
    }
  }
  await wscInstance.setUserDictionaryName(dictionaryName);
  const currentDictionaryName = await wscInstance.getUserDictionaryName();
  if (dictionaryName === currentDictionaryName) {
    console.log(`Connected to dictionary ${dictionaryName}`);
  } else {
    window.alert(`Error connecting to dictionary ${dictionaryName}`);
  }
  if (wordsToAdd.length) {
    migrateWords(wordsToAdd);
  }
  return { wscInstance, dictionaryName, exists: wscInstance.getUserDictionaryName() };
}
</script>
<style lang="scss">
#app {
  background: $background;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  position: relative;
}
@media screen and (min-width: 1024px) {
  #app {
    padding: 0 2.5%;
  }
  #nav {
    padding: 30px;
  }
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
