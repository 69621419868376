<template>
  <button v-bind="$attrs" v-on="$listeners" type="button" class="btn">
    <Icon :class="iconClass" :icon="icon" />
    <slot></slot>
  </button>
</template>

<script>
import Icon from "./Icon.vue";
export default { components: { Icon }, props: ["icon", "iconClass"], name: "Icon-Button" };
</script>

<style lang="scss" scoped>
.btn:hover {
  color: initial !important;
}
</style>
