export const labSettingsEnum = {
  AccessioningMaxAgeFromHL7WhenMatchingByOrderNumber: null, // - int
  SpecimenContainerLabelsPerSpecimen: null, // - int
  RequisitionLabelsPerAccession: null, // - int
  DefaultProviderIsLastUsed: null, // - bool
  MaxFutureDaysForReceivedDate: null, // - int
  DefaultDateCollected: null, // - enum: CurrentDate, ReceivedDate
  MaxDiffCollectedAndReceivedDates: null, // - int
  DuplicateCheckMaxDays: null, // - int
  DuplicateCheckMaxDaysIncludesWeekends: null, // - bool
  AccessionNumberingType: null, // - enum: Prefix, Pathology
  AccessionNumberingMethod: null, // - enum: Manual, Automatic
  AccessionNumberingNewYearSeed: null, // the first number of a new year
  ForceUpperCaseAddress: null, // - bool
  ForceUpperCaseCaseNote: null, // - bool
  ForceUpperCaseClinical: null, // - bool
  ForceUpperCaseDiagnosis: null, // - bool
  ForceUpperCaseGross: null, // - bool
  ForceUpperCaseInsurance: null, // - bool
  ForceUpperCaseMicroscopic: null, // - bool
  ForceUpperCasePatientName: null, // - bool
  ForceUpperCaseSite: null, // - bool
  SlideImagesApplicationHandler: null, //String
  ForceUpperCaseSpecimenNote: null, // - bool
  SpecimenNumberingTypes: null, //enum Numbers, Letters
  DefaultPriority: null, // enum
  DefaultBillingType: null, // enum
  UsesHoppers: null, // bool
  LabDistributionDelay: null, //int
  LabWorkspaceId: null, //string
  DefaultPathReport: null, // int
  DefaultBillingRate: null, //int
  RequisitionLabelTemplate: null, //int
  SpecimenContainerLabelTemplate: null, //int
  DefaultPurgeNumberOfDays: null, //int
  LabPrintNodeAPIKey: null, //string
  DefaultProcedureReport: null, // string
  DefaultProcedurePrinter: null, // int
  OrderNumberMatcher: null, //string
  DefaultSlideLabelBin: null, //string
  BillingCPTWarning: null,
  BillingLinkedCPTRequired: null,
  BillingUsesCPTCodePairs: null,
  BillingTypeReplaceCPT: null,
  LabDefaultBin: null, //string
  LabBinPrintOrder: null, //string
  BillingEnsureSpecimenHasProcedureCpt: null,
  PurgeInactiveHL7: null, //Int How long to keep HL7 before purging
  DefaultCassetteProcedureReport: null,
  DefaultCassetteProcedurePrinter: null,
  DefaultWorksheetReport: null, // Int
  DefaultQuickLinksTagNoteReport: null, //Int
  SlidePrintWarning: null, //int
  SlidePrintMax: null, //Int
  CassettePrintWarning: null, //int
  CassettePrintMax: null, //int
  MarkProceduresAfterSlidePrint: null, // Bool
  DefaultBatchMessageType: null, // enum: PathReportMessageTypeEnum
  BatchPrintMinCaseQtyCausesError: null, // int
  BatchPrintMinCaseQtyCausesWarning: null, // int
  DefaultCopyNamesMode: null, // enum: BatchCopyNameModesEnum
  ApplyPathologistSearchFilter: null, //bool
  DefaultSlideLabelReport: null, //int
  UnsignedMinAgeForAlerts: null, // int
  UserActivityTimeout: null, //int
  DefaultLastUsedSite: null, //Bool
  AdditionalFields: null, //Bool
  DefaultLastProtocolUsed: null, //Bool
  ClinicalICD10Browse: null, //Bool
  DefaultPathologistId: null, // int (67)
  DefaultEditorFontSize: null, //string
  DefaultEditorFontFamily: null, //string
  DefaultSlidePrintMode: null, // enum: SlidePrintMode
  DefaultGlassSlideReport: null, // int
  SRFaxEmail: null,
  SRFaxPassword: null,
  SRFaxCallerId10digits: null,
  SRFaxAccountNumber: null,
  IncludeSiteOnSpecimenLabel: null, //bool
  DefaultOrdersReport: null, //int
  MacroSearchWithPeriod: null, //bool
  DefaultPrinters: null, // string
  AutoCompleteOrdersForHistology: null, // bool
  AutoCompleteOrdersForPathology: null, // bool
  ShowLegacyCaseCount: null, // bool
  DefaultFontClinical: null,
  DefaultFontDiagnosis: null,
  DefaultFontGross: null,
  DefaultFontMicroscopic: null,
  DefaultFontSpecimenNotes: null,
  DefaultFontCaseNotes: null,
  LabelPrintingUsesBatching: null, //bool
  /* IP-66 */
  AutoAddSpecimens: null, // bool
  /* IP-133 */
  DefaultOrdersPrinter: null, //Int
  /* IP-173 */
  DefaultFaxFinal: null, //bool
  DefaultFaxPrelim: null, //bool
  DefaultFaxWeekend: null, //bool
  DefaultFaxStartTime: null, //string
  DefaultFaxEndTime: null, //string
  /* IP-172 */
  DistributionErrorEmails: null, // string
  AutoMacroPopup: null,
  DobIsRequired: null,
  MacroWindowClinical: null,
  MaxDiffDobCollected: null,
  MinDiffDobCollected: null,
  SegregateResultsMacros: null,
  SignOutTildeCheck: null,
  AccessioningArrangement: null,
  PrintNodeNotificationEmails: null,
  DefaultSearchStartDiff: null,
  MaxSearchDateRange: null,
  SpellCheckOnSave: null,
  SpellCheckSubscriptionKey: null,
  SpellCheckDictionaryId: null,
  ShowCreationMethod: null,
  EditorToolbarsDisabled: null,
  ProcedureSearchBy: null,
  AllowReceivedBeforeCollected: null,
  FileDropPrintingConfiguration: null,
  PrintPopupFocusPrinter: null,
  SuspendWelcomeEmailsToNewUsers: null,
  BarcodeDelimiter: null,
  InsuranceNameWhenNoInterfaceMatch: null,
  DefaultBillCycle: null,
  CassetteSlidePrintingShowHoldCodeFilter: null,
  EditorToolbarDisableBulletPoints: null,
  ConfirmPrelimSignOuts: null,
  CassettePrintingUsesBatching: null,
  SlidePrintingUsesBatching: null,
  AllowCaseEditReasonOther: null,
  ShowCasesToLabClientsDuringDistributionDelay: null,
  EnableInsuranceEligibilityCheck: null,
  OrdersReportUsesLargeParameters: null,
  SpecimenICDOptional: null,
  DefaultProcedureReportSupportsPrintModes: null,
  FaxCoverPageComments: null,
  SpellCheckOptions: null,
  GetSiteFromProtocolDescription: null,
  SkipDemographicsatAccessioning: null,
  FileDropPrintingSuppressSingleSpecimen: null,
  FileDropPrintingSuppressSingleBlock: null,
  UseADTOrders: null,
  SpecimenProtocolPopup: null,
  MaximumLabelQuantity: null,
  UseStagingEnvironment: null,
  PriorityOptional: null,
  PriorityFocusAfterPrefix: null,
  PatientMatchingonMRNAloneOption: null,
  SpecimenNonDiagnosticChange: null,
  ProviderNPIOptional: null,
  PathologistsResetBetweenAccessions: null,
  DefaultQtyPiecesPerCassette: null,
  PrintSlidesOnSpecimenSave: null,
  SpecimenFocus: null,
  AllowCaseEditTypeBilling: null,
  MolecularTestRunsEnabled: null,
  SearchByHoldCode: null,
  DefaultToZeroSlides: null,
  SpecimenHasMultipleICD10SWarning: null,
  ResultModePathologistFilter: null,
  ResultModeShowOnHold: null,
  AutoOpenReqSlip: null,
  UseSynopticTextField: null,
  AccessionPatientHistoryFields: null,
  HL7ContactFilterType: null,
  // IP-271 - replace with real setting
  DefaultClientTaskPriority: 0,
  DefaultPatientHistoryReport: null,
  DownloadMultiplePathReportsAsZipFile: null,
  UsesSpecimenTypes: null,
  ShowBillingOnCaseInsuranceForm: null,
  TagNoteSearchByCode: null,
  // Cytology Related Settings
  CytologyForceAsQc: null, // Bool
  CytologyFaxOnlyAbnormal: null, // Bool
  CytologyShowICD: null, // Bool
  CytologyAbnormalSeverityLevelStarts: null, // Int
  CytologyWNLDiagnosisCode: null, // Int
  CytologyModuleEnabled: null, // Bool
  CytologyPrinting: null, // Bool
  CytologyPathReport: null, // Int
  CytologyTermAdequacy: null, // Int
  CytologyTermComment: null, // Int
  CytologyTermDiagnosis: null, // Int
  CytologyTermFinalDiagnosis: null, // Int
  CytologyTermHormonal: null, // Int
  CytologyTermRecommendation: null, // Int,
  ProstateSiteMap: null,
  HighNumberOfSpecThreshold: null,
  UpdateDemoFromSelfInsurance: null,
  RegistrationNoteTagId: null
};
