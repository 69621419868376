import api from "./api.js";
class TransactionsApi {
  constructor() {
    this.url = "/api/Transactions";
  }
  get transactionsStore() {
    return api.createSearch("/api/cases/Transactions/search");
  }
  searchTransactionsByCase(data, caseId) {
    return api.post(`/api/Cases/${caseId}/Transactions/search`, data);
  }
  insertTx(data) {
    return api.post(this.url, data);
  }
  updateTx(data) {
    return api.put(this.url, data);
  }
  deleteTx(id) {
    return api.delete(this.url + `/${id}`);
  }
  async getTransactionById(id) {
    return api.get(this.url + `/${id}`);
  }
}
export default new TransactionsApi();
