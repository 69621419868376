<template>
  <div>
    <div class="d-flex justify-content-between my-2">
      <h3>Custom Fields</h3>
      <AddBtn
        type="button"
        @click="triggerIsAdding"
        :text="isAdding ? 'Close' : 'Add'"
        :icon="isAdding ? 'times' : 'plus'"
      />
    </div>
    <PropTable
      class="w-full"
      v-if="value && value.length"
      v-model="rows"
      :columns="columns"
      @edit="editField"
      @input="$emit('input', $event)"
    />

    <form v-if="isAdding || isEditing" @submit.prevent="addField">
      <div class="d-flex justify-content-between align-items-end">
        <label><b>Field Name</b></label>
        <add-btn
          v-if="permissions.TypeCodeCreateEditCustomFieldNames"
          class="mt-3 mb-2 ml-2"
          text="Create"
          @click.prevent="toggleCreateCustomFieldName"
        />
      </div>
      <select-input
        name="field"
        :noLabel="true"
        :items="fieldNameOptions"
        displayExpr="displayName"
        valueExpr="id"
        v-model="field.fieldNameId"
        :validator="$v.field.fieldNameId"
      />
      <TextAreaInput
        name="value"
        :resize="false"
        rows="4"
        maxLength="4001"
        label="Field Value"
        v-model="field.value"
        :validator="$v.field.value"
      />
      <div class="row justify-content-end">
        <button type="submit" class="my-2 btn btn-primary">Save</button>
      </div>
    </form>
    <modal :status="isCreatingCustomFieldName" @close="toggleCreateCustomFieldName">
      <h3>Create New Custom Field Name</h3>
      <text-input v-focus v-model="newCustomFieldName" />
      <div class="d-flex justify-content-end my-2">
        <loader size="small" v-show="isLoading" />
        <button
          @click.prevent="toggleCreateCustomFieldName"
          :disabled="isLoading"
          type="button"
          class="mx-2 btn btn-danger"
        >
          Cancel
        </button>
        <button
          :disabled="isLoading || !newCustomFieldName.length"
          class="btn btn-primary"
          @click.prevent="saveNewCustomFieldName"
          v-shortkey="['alt', 's']"
          @shortkey="saveNewCustomFieldName"
        >
          Save
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import PropTable from "@/components/common/PropTable.vue";
import AddBtn from "@/components/common/AddButton.vue";
import { SettingsApi } from "@/services";
import SelectInput from "@/components/common/SelectInput.vue";
import DataSource from "devextreme/data/data_source";
import Modal from "@/components/common/Modal.vue";
import TextInput from "@/components/common/TextInput.vue";
import Loader from "@/components/common/Loader.vue";
import { handleErrors } from "@/modules/handleErrors";
import { capitalize } from "@/modules/helpers";
import { mapGetters, mapState } from "vuex";
import { TypeCodeGroupEnum } from "@/modules/enums";

export default {
  name: "CustomFields",
  components: {
    TextAreaInput: () => import("@/components/TextAreaInput.vue"),
    PropTable,
    AddBtn,
    SelectInput,
    Modal,
    TextInput,
    Loader
  },
  props: {
    value: {
      required: true
    },
    customFieldType: {
      type: String
    }
  },
  data() {
    return {
      isEditing: false,
      isAdding: false,
      isCreatingCustomFieldName: false,
      defaultField: {
        fieldNameId: null,
        value: ""
      },
      field: {
        fieldNameId: null,
        value: ""
      },
      fieldNameOptions: [],
      newCustomFieldName: "",
      isLoading: false
    };
  },
  validations: {
    field: {
      fieldNameId: {
        required
      },
      value: {
        maxLength: maxLength(4000)
      }
    }
  },
  mounted() {
    SettingsApi.typeCodesSearch.load({ filter: this.filterValue }).then(res => {
      this.fieldNameOptions = res;
    });
  },
  computed: {
    ...mapState({
      currentLab: state => state.currentLab
    }),
    ...mapGetters(["permissions"]),
    rows: {
      get() {
        return this.value;
      },
      set(value) {
        return this.$emit("input", value);
      }
    },
    customFieldNameDataSource() {
      return new DataSource({
        store: SettingsApi.typeCodesSearch,
        filter: this.filterValue
      });
    },
    fieldTypeCodeId() {
      switch (this.customFieldType) {
        case "contact":
          return TypeCodeGroupEnum.ContactCustomFieldName;
        case "provider":
          return TypeCodeGroupEnum.ProviderCustomFieldName;
        case "location":
          return TypeCodeGroupEnum.LocationCustomFieldName;
        case "insurance":
          return TypeCodeGroupEnum.InsuranceCustomFieldName;
        case "prefix":
          return TypeCodeGroupEnum.PrefixCustomFieldName;
        default:
          return null;
      }
    },
    filterValue() {
      return this.fieldTypeCodeId ? ["typeId", this.fieldTypeCodeId] : null;
    },
    columns() {
      return [
        {
          dataField: "fieldNameId",
          caption: "Field Name",
          lookup: {
            dataSource: this.fieldNameOptions,
            displayExpr: "displayName",
            valueExpr: "id"
          }
        },
        { dataField: "value" }
      ];
    }
  },
  methods: {
    async triggerIsAdding() {
      if (this.isEditing) {
        const confirm = await window.confirm(
          "You may have unsaved data, are you sure you want to continue?"
        );
        if (!confirm) {
          return;
        }
      }
      this.field = { ...this.defaultField };
      if (this.isAdding) {
        this.isAdding = false;
        this.isEditing = false;
        return;
      }
      this.isAdding = true;
      this.isEditing = false;
    },
    addField() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        window.notify("Please verify your input and try again.", "warning");

        return;
      }
      if (!this.isEditing) {
        const dupeCheck = this.value?.find(e => {
          return e.fieldNameId === this.field.fieldNameId;
        });
        if (dupeCheck) {
          return alert("Duplicate name found.");
        }
        let values = [this.field];
        if (this.value) {
          values = [...this.value, this.field];
        }
        this.$emit("input", values);
      }
      this.isEditing = false;
      this.isAdding = false;
      this.$v.$reset();
      return (this.field = { ...this.defaultField });
    },
    editField({ data }) {
      this.isEditing = true;
      this.field = data;
    },
    cancelEdit() {
      this.field = { ...this.defaultField };
      if (this.isEditing) {
        this.isEditing = false;
      }
      this.isAdding = false;
    },
    toggleCreateCustomFieldName() {
      this.isCreatingCustomFieldName = !this.isCreatingCustomFieldName;
      this.newCustomFieldName = "";
    },
    async saveNewCustomFieldName() {
      if (!this.newCustomFieldName.length) {
        return;
      }
      if (this.fieldNameOptions.find(e => e.displayName === this.newCustomFieldName)) {
        window.notify("Custom Field Name Already Exists.", "error");
        return;
      }
      this.isLoading = true;
      const customFieldNameTypeCode = {
        labId: this.currentLab,
        typeId: this.fieldTypeCodeId,
        code: "",
        displayName: this.newCustomFieldName,
        flag1: "",
        flag2: "",
        flag3: "",
        flag4: "",
        flag5: "",
        flag6: "",
        type: {
          id: this.fieldTypeCodeId,
          userId: null,
          displayName: capitalize(this.customFieldType) + "CustomFieldName",
          settingType: null,
          officeName: null,
          isDeleted: false,
          isPrimary: null,
          defaultBlockNumber: 0,
          expiryOn: null,
          effectiveOn: null
        }
      };
      try {
        await SettingsApi.addTypeCode(customFieldNameTypeCode);
        window.notify(`Created new custom field "${this.newCustomFieldName}".`);
        SettingsApi.typeCodesSearch.load({ filter: this.filterValue }).then(res => {
          this.fieldNameOptions = res;
          const newFieldName = res.find(e => e.displayName === customFieldNameTypeCode.displayName);
          if (newFieldName) {
            this.field.fieldNameId = newFieldName.id;
          }
        });
        this.toggleCreateCustomFieldName();
      } catch (error) {
        handleErrors(error);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-cell {
  width: 10%;
}
table td {
  text-align: center;
  padding: 0;
}
</style>
