<template>
  <nav id="navbar" class="header">
    <div class="header__top">
      <router-link to="/">
        <img class="header__logo" src="@/assets/logo.svg" />
      </router-link>
      <div class="envName">{{ stage }}</div>
      <div class="header__user">
        <div class="d-none d-md-flex mr-2 align-items-center" v-if="!isLabClientUser">
          <label for="accessionMode" class="mr-2"><b>Mode: </b></label>
          <select
            v-model="mode"
            class="form-control text-capitalize"
            name="accessionMode"
            id="accessionMode"
          >
            <option value="" disabled></option>
            <option
              class="text-capitalize"
              v-for="(item, index) in accesionModeItems"
              :key="item + index"
              :value="item"
            >
              {{ item }}
            </option>
          </select>
        </div>
        <div
          v-if="availableLabs.length > 1"
          class="d-none d-md-flex mr-2 align-items-center"
          @mouseover="handleLabSelectorHover"
          @mouseleave="handleLabSelectorHover"
        >
          <label for="labSelector" class="mr-2"><b>Lab: </b></label>
          <DxSelectBox
            class="mx-2"
            data-cy="labSelector"
            v-model="labId"
            valueExpr="id"
            name="labSelector"
            id="labSelector"
            ref="labSelector"
            :items="availableLabs"
            displayExpr="displayName"
            searchExpr="longDisplayName"
            :showClearButton="isHoveringLabSelector"
            :searchEnabled="true"
            :onFocusOut="handleBlurLabSelector"
          >
          </DxSelectBox>
        </div>
        <div
          v-if="
            availableLabLocations.length > 1 && (currentUser.isPathologist || doctorProxies.length)
          "
          class="d-none d-md-flex mx-2 align-items-center"
        >
          <label for="labLocationSelector" class="mr-2"><b>Location: </b></label>
          <select
            data-cy="labLocationSelector"
            v-model="labLocationId"
            name="labLocationSelector"
            id="labLocationSelector"
            class="mx-2 form-control"
          >
            <option
              v-for="labLocation in availableLabLocations"
              :key="labLocation.id"
              :value="labLocation.id"
            >
              {{ labLocation.displayName }}
            </option>
          </select>
        </div>
        <div class="header__user_info d-none d-md-block">
          <div class="user__name">
            <b>{{ currentUser.firstName }} {{ currentUser.lastName }}</b>
          </div>
          <div
            v-if="!isLabClientUser && availableLabs.length === 1"
            class="user__subtext text-center text-capitalize"
          >
            <span> {{ labName }} </span>
          </div>
        </div>

        <a
          v-if="isLabClientUser && permissions.TaskCreate"
          @click="handleSendMessage"
          class="header__icon text-center"
          role="button"
        >
          <icon icon="envelope" />
        </a>
        <a
          class="header__icon text-center"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
          id="dropdownMenuLink"
        >
          <icon icon="cog" />
        </a>

        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <button id="logout" class="btn btn-link dropdown-item" @click="confirmLogout">
            Logout
          </button>
          <button
            v-if="!isLabClientUser"
            id="settings"
            class="btn btn-link dropdown-item"
            @click="isSettingsOpen = true"
          >
            Settings
          </button>
          <button
            v-if="currentUser.userTypeId === 40"
            id="documentation"
            class="btn btn-link dropdown-item"
            @click="goToDocs"
          >
            Documentation
          </button>
          <button id="support" class="btn btn-link dropdown-item" @click="isSupportOpen = true">
            Support
          </button>
        </div>
      </div>
    </div>
    <div class="bg-white" v-show="!isMobileView">
      <nav class="nav">
        <template v-for="(navButton, idx) in navButtons">
          <admin-menu
            v-if="navButton.type === 'submenu'"
            ref="admin"
            :key="idx"
            v-shortkey="shortkey(idx)"
            @shortkey.native.prevent="openMenu"
            aria-level="2"
            v-model="isAdminOpen"
            class="nav-link"
            id="adminDropdown"
            :name="navButton.title"
            direction="down"
            :items="navButton.items"
          />
          <router-link
            v-else
            :key="idx"
            :to="{ name: navButton.route }"
            v-shortkey="shortkey(idx)"
            @shortkey.native="navigate({ name: navButton.route })"
            class="nav-link"
            exact-active-class="active"
            >{{ navButton.title }}</router-link
          >
        </template>
        <div v-if="isLabClientUser" class="d-flex justify-content-start">
          <label for="clientDefaultMode" class="client-mode-label"><b>Default Mode:</b></label>
          <SelectInput
            class="client-mode-input"
            name="clientDefaultMode"
            :noLabel="true"
            :items="clientModeOptions"
            :value="defaultDashboardMode"
            @input="setClientDefaultMode"
            :showClearButton="false"
          />
        </div>
        <div
          v-if="recentCases.length"
          class="mr-2 prev-cases my-0 py-0 d-flex align-items-center ml-auto"
        >
          <DxDropDownButton
            :onButtonClick="handlePrevCaseClick"
            ref="lastCase"
            :splitButton="true"
            :items="recentCases"
            displayExpr="caseNumber"
            :showDataBeforeSearch="true"
            :noLabel="true"
            valueExpr="caseNumber"
            :acceptCustomValue="false"
            type="normal"
            @item-click="handleNavigateToCaseByCaseNum"
            :showArrowIcon="true"
            :text="'Last Case: ' + lastCase.caseNumber"
            :split-button="false"
            stylingMode="text"
            class="dropdown-button prev-cases"
            :wrapItemText="false"
            :drop-down-options="dropDownOptions"
            item-template="dropdownItem"
            hint="Last viewed case"
          >
            <template v-slot:dropdownItem="{ data }"
              ><div v-tooltip="activityDate(data.activityDate)">
                {{ data.caseNumber }}
              </div></template
            ></DxDropDownButton
          >
        </div>
      </nav>
    </div>
    <DWTInstall />
    <Modal :status="isSettingsOpen" @close="toggleSettings">
      <app-settings-popup @close="toggleSettings" />
    </Modal>
    <Modal class="idle-view" :status="isIdle">
      <idle-popup @logout="handleLogout" />
    </Modal>
    <modal :status="isSendingMessage" @close="handleSendMessage">
      <task-form @submit="handleSendMessage" @cancel="handleSendMessage" />
    </modal>
    <modal :status="isSupportOpen" @close="submitSupport">
      <SupportPopup @close="submitSupport" />
    </modal>
    <PopupReportViewerFrame />
    <ImagePreviewFrame />
  </nav>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import AdminMenu from "./AdminMenu.vue";
import { filter, mergeMap, tap, throttleTime } from "rxjs/operators";
import Modal from "../common/Modal.vue";
import AppSettingsPopup from "../AppSettingsPopup.vue";
import { from, fromEvent } from "rxjs";
import IdlePopup from "../IdlePopup.vue";
import { SESSION_KEY } from "@/store";
import { fromBusEvent, OPEN_APPLICATION_SETTINGS } from "@/modules/eventBus";
import { createLogItem, isSuperUser, utcToLocal } from "@/modules/helpers";
import { AuditLogApi, CasesApi } from "@/services";
import Icon from "../common/Icon.vue";
import TaskForm from "../forms/TaskForm.vue";
import DWTInstall from "../DWTInstall.vue";
import TWAINDriver from "@/modules/TWAINDriver";
import SupportPopup from "../SupportPopup.vue";
import DxDropDownButton from "devextreme-vue/drop-down-button";
import moment from "moment";
import getOS from "@/modules/getOs";
import { CytologyEntities } from "@/services/cytology";
import DxSelectBox from "devextreme-vue/select-box";

//All window owning viewers are always loaded in the navigation
import PopupReportViewerFrame from "../Reports/PathReportViewerFrame.vue";
import ImagePreviewFrame from "../ImagePreviewFrame.vue";
import websocket from "@/mixins/websocket";
import SelectInput from "../common/SelectInput.vue";
export default {
  components: {
    AdminMenu,
    Modal,
    AppSettingsPopup,
    IdlePopup,
    PopupReportViewerFrame,
    Icon,
    TaskForm,
    DWTInstall,
    SupportPopup,
    ImagePreviewFrame,
    DxDropDownButton,
    DxSelectBox,
    SelectInput
  },
  name: "Navbar",
  data() {
    return {
      labInformation: null,
      isAdminOpen: false,
      isSettingsOpen: false,
      isSupportOpen: false,
      isIdle: false,
      isSendingMessage: false,
      scanner: null,
      recentCases: [],
      lastCase: {},
      dropDownOptions: {
        class: "prev-cases"
      },
      isHoveringLabSelector: false,
      clientModeOptions: [
        { id: 0, displayName: "Cases" },
        { id: 1, displayName: "Search" }
      ]
    };
  },
  mixins: [websocket],
  beforeDestroy() {
    clearTimeout(this.userActivityTimeout);
    if (this.enableTWAINDriver) {
      TWAINDriver.disconnect();
    }
  },

  created() {
    if (this.currentLab) {
      this.loadRecentCases();
    }
    if (this.enableTWAINDriver) {
      TWAINDriver.load();
    }
  },
  subscriptions() {
    const shareCredentials$ = fromEvent(window, "storage").pipe(
      tap(({ key }) => {
        if (key === "REQUESTING_SHARED_CREDENTIALS") {
          const token = window.sessionStorage.getItem("token");
          const state = window.sessionStorage.getItem(SESSION_KEY);
          if (token) {
            localStorage.setItem("CREDENTIAL_SHARING", JSON.stringify({ token, state }));
            localStorage.removeItem("CREDENTIAL_SHARING");
          }
        }
      })
    );
    const openSettings$ = fromBusEvent(OPEN_APPLICATION_SETTINGS).pipe(
      tap(() => {
        this.isSettingsOpen = true;
      })
    );

    return {
      shareCredentials$,
      openSettings$,
      activityTracker$: from(["mousemove", "scroll", "keydown", "resize"]).pipe(
        mergeMap(event => fromEvent(window, event)),
        throttleTime(500),
        tap(this.resetUserActivityTimeout)
      ),
      flushCredentials$: fromEvent(window, "storage").pipe(
        filter(({ key }) => key === "FLUSH_CREDENTIALS"),
        tap(this.handleLogout)
      )
    };
  },

  methods: {
    async handleLogout() {
      this.isIdle = false;
      AuditLogApi.insertLogMessage({ ...createLogItem({}, 16), comments: "Logout" }).catch(() => {
        console.log("Error in audit log.");
      });
      await this.$store.dispatch("AUTH_LOGOUT");
      this.$router.push("/login");
    },
    handleSendMessage() {
      this.isSendingMessage = !this.isSendingMessage;
    },
    async handleChangeLab(labId) {
      const selectorEl = this.$refs.labSelector;
      if (selectorEl?.blur) {
        selectorEl.blur();
      }
      await this.$store.dispatch("setCurrentLab", labId);
      if (this.$route.path !== "/") {
        this.$router.push("/");
      }
    },
    async confirmLogout() {
      const answer = await window.confirm(
        "Are you sure you would like to logout? Any unsaved changes will be lost."
      );
      if (answer) {
        localStorage.setItem("FLUSH_CREDENTIALS", Date.now());
        localStorage.removeItem("FLUSH_CREDENTIALS");
        this.handleLogout();
      }
    },
    handleNavigateToCase(caseId) {
      if (this.$route.params?.caseId === caseId) {
        return;
      }

      switch (this.$store.state.applicationSettings.accessionMode) {
        case "specimen":
          this.$router.push({
            name: "Specimen",
            params: { caseId }
          });
          break;
        case "result":
          this.$router.push({
            name: "SpecimenResults",
            params: { caseId }
          });
          break;
        case "signout":
          this.$router.push({
            name: "SpecimenResults",
            params: { caseId }
          });
          break;
        case "transactions":
          this.$router.push({
            name: "accesionTransactions",
            params: { caseId }
          });
          break;
        case "insurance":
          this.$router.push({
            name: "accessionInsurance",
            params: { caseId }
          });
          break;
        case "distribution":
          this.$router.push({
            name: "Distribution",
            params: { caseId }
          });
          break;
        case "history":
          this.$router.push({
            name: "CaseHistory",
            params: { caseId }
          });
          break;
        default:
          this.$router.push({
            name: "CaseView",
            params: { caseId }
          });
          break;
      }
    },
    toggleSettings() {
      this.isSettingsOpen = !this.isSettingsOpen;
    },
    navigate(route) {
      if (this.$route.name !== route) {
        this.$router.push(route);
      }
    },
    openMenu() {
      this.$refs.admin[0].open = !this.$refs.admin[0].open;
    },
    resetUserActivityTimeout() {
      clearTimeout(this.userActivityTimeout);
      this.isIdle = false;
      this.userActivityTimeout = setTimeout(() => {
        this.inactiveUserAction();
      }, this.INACTIVE_USER_TIME_THRESHOLD);
    },
    inactiveUserAction() {
      // logout logic
      if (this.token) {
        this.isIdle = true;
      }
    },
    minutesCalculator(minutes = 15) {
      return minutes * 1000 * 60;
    },
    submitSupport() {
      this.isSupportOpen = false;
    },
    handlePrevCaseClick() {
      this.handleNavigateToCaseByCaseNum(this.lastCase.caseNumber);
    },
    handleNavigateToCaseByCaseNum(caseNumber) {
      if (typeof caseNumber === "object") {
        caseNumber = caseNumber.itemData.caseNumber;
      }
      if (caseNumber === this.currentCaseNumber) {
        return;
      }
      CasesApi.searchStore
        .load({
          filter: ["accessionNumber", "=", caseNumber]
        })
        .then(res => {
          this.handleNavigateToCase(res[0].caseId);
          this.loadRecentCases();
        });
    },
    loadRecentCases() {
      CasesApi.getPreviousCases(this.currentLab).then(res => {
        this.recentCases = res;
        if (Array.isArray(res)) {
          const mostRecentCase = this.recentCases[0];
          if (mostRecentCase?.caseNumber === this.currentCaseNumber) {
            this.lastCase = this.recentCases[1];
          } else {
            this.lastCase = this.recentCases[0];
          }
        }
      });
    },
    activityDate(date) {
      return moment(utcToLocal(date)).format("MMMM D, YYYY h:mm a");
    },
    shortkey(idx) {
      if (getOS().includes("Mac")) {
        return ["meta", idx + 1];
      }
      return ["ctrl", idx + 1];
    },
    goToDocs() {
      window.location.href = "/documentation";
    },
    handleBlurLabSelector({ component }) {
      const value = component?.option("value");
      if (!value) {
        component.option("value", this.labId);
      }
    },
    handleLabSelectorHover({ type }) {
      this.isHoveringLabSelector = type === "mouseover";
    },
    setClientDefaultMode(value) {
      this.$store.dispatch("applicationSettings/setUserSettings", { defaultDashboardMode: value });
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser,
      currentLab: state => state.currentLab,
      currentLabLocation: state => state.currentLabLocation,
      availableLabs: state => state.availableLabs,
      availableLabLocations: state => state.availableLabLocations,
      token: state => state.token,
      accessionMode: state => state.applicationSettings.accessionMode,
      isLabClientUser: state => state.currentUser.isLabClientUser,
      isMobileView: state => state.isMobileView,
      enableTWAINDriver: state => state.applicationSettings.enableTWAINDriver,
      UserActivityTimeout: state => state.labSettings.UserActivityTimeout,
      currentCaseNumber: state => state.accessionStore.caseDetails.caseNumber,
      MolecularTestRunsEnabled: state => state.labSettings.MolecularTestRunsEnabled,
      CytologyModuleEnabled: state => state.labSettings.CytologyModuleEnabled,
      doctorProxies: state => state.doctorProxies,
      defaultDashboardMode: state => state.applicationSettings.defaultDashboardMode
    }),
    ...mapGetters(["permissions"]),
    labName() {
      if (this.availableLabs.length === 1) {
        return `(${this.availableLabs[0].displayName})`;
      }
      return "";
    },
    labId: {
      get() {
        return this.currentLab;
      },
      set(value) {
        if (value == null) {
          return this.labId;
        }
        this.handleChangeLab(value);
        return value;
      }
    },
    labLocationId: {
      get() {
        return this.currentLabLocation;
      },
      set(value) {
        this.$store.dispatch("setCurrentLabLocation", value);
        return value;
      }
    },
    INACTIVE_USER_TIME_THRESHOLD() {
      const UserActivityTimeoutValue = this.UserActivityTimeout ?? 15;
      return this.minutesCalculator(UserActivityTimeoutValue);
    },
    navButtons() {
      return [
        {
          title: "Dashboard",
          route: "Home",
          permission: true
        },
        {
          title: "Reports",
          route: "Reports",
          permission: !this.isLabClientUser && this.permissions.DailyReportView
        },
        {
          // IP-1548 - rename to Batch Printing
          title: "Batch Printing",
          route: "Batch Printing",
          permission: !this.isLabClientUser
        },
        {
          title: "Distribution History",
          route: "Distribution History",
          permission: !this.isLabClientUser
        },
        {
          title: "Test Runs",
          route: "Molecular Test Runs",
          permission:
            !this.isLabClientUser && this.MolecularTestRunsEnabled && this.permissions.TestRunView
        },
        {
          title: "Quick Signout",
          route: "Quick Signout",
          permission: !this.isLabClientUser && this.canSignOut
        },
        {
          type: "submenu",
          title: "Admin",
          permission: !this.isLabClientUser,
          items: [
            {
              name: "Code Maintenance",
              type: "submenu",
              permission: true,
              key: "c",
              direction: "right",
              items: [
                {
                  name: "Provider/Location",
                  route: "ContactMaintenance",
                  key: "v",
                  permission: this.permissions.ContactView
                },
                {
                  name: "Cytology",
                  type: "submenu",
                  route: "Cytology",
                  permission:
                    this.permissions.CytDiagnosticCodeCreateEdit && this.CytologyModuleEnabled,
                  items: [
                    {
                      name: "Diagnostic Codes",
                      route: CytologyEntities.CytDiagnosticCodes,
                      permission: true
                    },
                    {
                      name: "Macros",
                      route: CytologyEntities.CytDiagnosticMacros,
                      permission: true
                    },
                    {
                      name: "Tests",
                      route: CytologyEntities.CytMolecTests,
                      permission: true
                    },
                    {
                      name: "Questions",
                      route: CytologyEntities.CytQuestions,
                      permission: true
                    },
                    {
                      name: "Molecular Results",
                      route: CytologyEntities.CytMolecResults,
                      permission: true
                    },
                    {
                      name: "Molecular Panels",
                      route: CytologyEntities.CytMolecPanels,
                      permission: true
                    },
                    {
                      name: "Triggers",
                      route: CytologyEntities.CytTriggers,
                      permission: true
                    }
                  ]
                },
                {
                  name: "Printers",
                  route: "PrinterMaintenance",
                  key: "p",
                  permission: this.permissions.PrinterCreateEdit
                },

                {
                  name: "Orders",
                  route: "ProcedureMaintenace",
                  key: "o",
                  permission: true
                },
                {
                  name: "Billing",
                  route: "TransactionCodes",
                  key: "b",
                  permission: this.permissions.TransactionCodeView
                },
                {
                  name: "Insurance",
                  route: "InsuranceMaintenance",
                  key: "n",
                  permission: true
                },
                {
                  name: "Prefix",
                  route: "PrefixMaintenance",
                  key: "p",
                  permission: true
                },
                {
                  name: "Macros",
                  route: "MacroConfiguration",
                  key: "m",
                  permission: this.permissions.MacroView
                },
                {
                  name: "Popups",
                  route: "Popups",
                  permission: this.permissions.PopupCreateEdit
                },
                {
                  name: "Proxy",
                  route: "ProxyMaintenance",
                  permission: this.permissions.ProxyView
                }
              ]
            },
            {
              name: "User & Role Management",
              type: "submenu",
              key: "u",
              direction: "right",
              permission: true,
              items: [
                {
                  name: "User",
                  key: "s",
                  route: "UserAdmin",
                  permission: this.permissions.UserView
                },
                {
                  name: "Role",
                  route: "Role_Management",
                  key: "e",
                  permission: this.permissions.RoleCreateEdit
                }
              ]
            },
            {
              name: "Lab Settings",
              route: "LabSettings",
              key: "l",
              permission: this.permissions.SettingView
            },
            {
              name: "Patient History",
              route: "PatientHistoryMaintenance",
              key: "h",
              permission: this.permissions.CaseHistoryChange
            },
            {
              name: "ICD Master File",
              route: "IcdAdenda",
              key: "i",
              permission: isSuperUser()
            },
            {
              name: "Activity Log",
              route: "AuditLog",
              key: "g",
              permission: this.permissions.AuditLogView
            },
            {
              name: "ICD Rules",
              route: "IcdRules",
              key: "d",
              permission: isSuperUser()
            }
          ]
        }
      ].filter(item => item.permission);
    },

    mode: {
      get() {
        return this.accessionMode;
      },
      set(value) {
        this.$store.commit("applicationSettings/setAccessionMode", value);
        return value;
      }
    },
    currentPage() {
      return this.$route.path;
    },
    accesionModeItems() {
      const { distinctPermissions } = this.currentUser;
      const items = {
        demographics: distinctPermissions.includes(1180),
        specimen: distinctPermissions.includes(1150),
        result: distinctPermissions.includes(1120),
        transactions: distinctPermissions.includes(1170),
        insurance: distinctPermissions.includes(1100),
        history: distinctPermissions.includes(1080),
        distribution: distinctPermissions.includes(1080),
        signout: distinctPermissions.includes(1130)
      };
      return Object.keys(items).filter(e => {
        return items[e];
      });
    },
    stage() {
      const envStage = process.env.VUE_APP_STAGE;
      if (envStage && !["Prod", "UCSF", "Staging"].includes(envStage)) {
        return envStage;
      }
      return "";
    },
    canSignOut() {
      const hasPermission = this.permissions.CaseSignout || this.permissions.CaseSignoutByProxy;
      const canSign = this.currentUser.isPathologist || this.doctorProxies.length;
      return hasPermission && canSign;
    }
  },
  watch: {
    $route(nv, ov) {
      const caseViewNames = [
        "CaseView",
        "Specimen",
        "SpecimenResults",
        "accesionTransactions",
        "accessionInsurance",
        "CaseHistory",
        "Distribution",
        "CaseActivityLog"
      ];
      if (
        (ov.name === "Login" && !this.recentCases.length) ||
        (caseViewNames.includes(ov.name) && !caseViewNames.includes(nv.name)) ||
        nv?.params?.caseId !== ov?.params?.caseId
      ) {
        this.loadRecentCases();
      }
    },
    enableTWAINDriver(nv) {
      if (nv) {
        TWAINDriver.load();
      } else {
        TWAINDriver.disconnect();
      }
    },
    "currentUser.settings": {
      immediate: true,
      handler(nv) {
        if (nv) {
          this.$store.commit("applicationSettings/setAppSettings", JSON.parse(nv));
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.idle-view {
  z-index: 9999;
}

.header {
  background: $background;

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $white;
  }

  &__bottom {
    background: $white;
  }

  &__logo {
    height: 46px;
    width: 46px;
  }

  &__user {
    display: flex;
    align-items: center;
  }

  &__user_info {
    margin-right: 5px;
  }

  &__icon {
    background: $primary;
    color: $white;
    width: 30px;
    height: 30px;
    margin-left: 10px;
    border-radius: 3px;
    font-size: 20px;
  }
}

.nav {
  height: 100%;
}

.nav-dropdown {
  color: $primary;
  padding: 0.5rem 1rem;
  border-top: 5px solid white;
  background: $white;
}

.admin-dropdown {
  min-width: 12rem;
}
.nav-link {
  display: inline-flex;
  align-items: center;
}
.nav-link.active {
  color: $white;
  background: $secondary;
  border-top: 1px solid $secondary;
  margin-top: 2.5px;
}

.envName {
  color: $warning;
  font-size: 2em;
  font-weight: bold;
  animation: blinker 3s linear infinite;
}

.prev-cases {
  color: $secondary !important;
}

.dropdown-button {
  border: none;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

#labSelector {
  width: 26em;
  font-size: 1rem;
}
.client-mode-input {
  min-height: 0px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0.5em;
  width: 7em;
}
.client-mode-label {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0.5em;
}
</style>
