import store from "@/store";

function logToNewRelic(error) {
  if (window.newRelic) {
    window.newRelic.noticeError(error, { labId: store.state.labId, ...store.state.currentUser });
  }
}

export function handleErrors(error) {
  logToNewRelic(error);
  if (error.response?.data) {
    if (error.response.data?.message) {
      const { message } = error.response.data;
      if (/prelim signouts/.test(message)) {
        return window.alert(
          "Cases in this prefix can not be signed as prelim. Hold must be removed before signing."
        );
      }
      if (/reporting services error/i.test(message)) {
        return window.alert(
          "An error occured generating the reporting service. Please contact customer care."
        );
      }
      return window.alert(`${message}`, "error");
    }
    const { errors } = error.response.data;
    if (errors) {
      const errorHTML = `<ul>
                <li>${Object.keys(errors).map(e => {
                  return `${e}: ${errors[e].join("<br>")}`;
                })}</li>
              </ul>`;
      return window.alert(errorHTML);
    }
    if (typeof error.response.data === "string") {
      return window.alert(error.response.data, "error");
    }
    if (
      error.response.request.responseType === "arraybuffer" &&
      error.response.data.toString() === "[object ArrayBuffer]"
    ) {
      /** error response */

      const res = JSON.parse(Buffer.from(error.response.data).toString("utf8"));
      if (
        (typeof res?.message === "string" && res.message.includes("Reporting Service")) ||
        (res?.includes && res?.includes("Reporting Service"))
      ) {
        return window.alert(
          "There was an error running the report.  Please contact customer care."
        );
      }
      if (typeof res?.message === "string") {
        return window.alert(res.message);
      }
      return window.alert(res);
    }
    return window.notify("An error occured.", "error");
  }
  if (error.message) {
    const { message } = error;
    if (message?.includes("Reporting Services Error")) {
      return window.alert("There was an error running the report.  Please contact customer care.");
    }
    if (/network error/i.test(message)) {
      return window.alert("Network error. Please confirm you are connected to the internet.");
    }
    return window.alert(message, "error");
  }
}
