<template>
  <div>
    <form @submit.prevent="handleSubmit">
      <h1>Next Case</h1>
      <text-input v-model="nextCaseNumber" v-focus :disabled="isLoading" ref="nextCaseNumber" />
      <div class="d-flex justify-content-end">
        <loader v-if="isLoading" size="small" class="mr-1" />
        <button class="btn btn-danger mr-1" @click.prevent="handleCancel">Cancel</button
        ><button
          type="submit"
          class="btn btn-primary"
          v-shortkey="shortkeys"
          @shortkey.prevent="handleShortKey"
          :disabled="isDisabled"
        >
          Go
        </button>
      </div>
    </form>
    <modal :status="multipleCases" @close="multipleCases = false">
      <h3>The case number entered has multiple matches. Please select the case to navigate to.</h3>
      <button
        :class="`next-case-button btn btn-${buttonClass(caseDetails.caseId)} mx-auto my-1`"
        v-for="caseDetails of matchingCases"
        :key="caseDetails.caseId"
        :ref="caseDetails.caseId"
        @click="goToCase(caseDetails.caseId)"
      >
        {{ caseDetails.accessionNumber }}
      </button>
    </modal>
  </div>
</template>

<script>
import { calculateAccessionNumbering } from "@/modules/helpers";
import { mapState } from "vuex";
import TextInput from "./common/TextInput.vue";
import { CasesApi } from "@/services";
import Modal from "./common/Modal.vue";
import Loader from "./common/Loader.vue";
import { AccessionNumberingTypeEnum } from "@/modules/enums";
import ScannerDetection from "@/modules/scanner";
import { scanCaseBarcode } from "@/modules/scanCaseBarcode";
import { handleErrors } from "@/modules/handleErrors";
export default {
  components: { TextInput, Modal, Loader },
  props: ["previousCaseId", "previousCaseNumber"],
  data() {
    return {
      nextCaseNumber: "",
      nextCaseObject: {},
      multipleCases: false,
      matchingCases: [],
      selectedCaseId: null,
      NumberingTypeEnum: {
        Pathology: 1,
        Prefix: 2
      },
      isLoading: false,
      alertOpen: false
    };
  },
  mounted() {
    if (this.autoFillNextCase) {
      this.loadDefaultCase();
    }
    this.scanner = new ScannerDetection({
      onComplete: this.handleScanBarcode,
      stopPropogation: true,
      minLength: 4
    });
  },
  beforeDestroy() {
    if (this.scanner?.stopScanning) {
      this.scanner.stopScanning();
    }
  },
  watch: {
    nextCaseNumber(nv) {
      this.nextCaseNumber = nv.toUpperCase();
    }
  },
  computed: {
    ...mapState({
      currentCase: state => state.accessionStore.caseDetails,
      autoFillNextCase: state => state.applicationSettings.autoFillNextCase,
      accessionNumberingType: state => state.labSettings.AccessionNumberingType,
      nextCaseUseDashboardMode: state => state.applicationSettings.nextCaseUseDashboardMode,
      dashboardMode: state => state.applicationSettings.accessionMode,
      nextCaseToLoad: state => state.accessionStore.nextCaseToLoad,
      nextCaseDashboardFilter: state => state.applicationSettings.nextCaseDashboardFilter
    }),
    shortkeys() {
      if (this.alertOpen) {
        return {};
      }
      return {
        enter: ["enter"],
        up: ["arrowup"],
        down: ["arrowdown"]
      };
    },
    isDisabled() {
      if (!this.nextCaseNumber || this.isLoading) {
        return true;
      }
      if (!this.isValidCaseNumber) {
        return true;
      }
      return false;
    },
    isValidCaseNumber() {
      const validationRegex =
        this.accessionNumberingType === AccessionNumberingTypeEnum.Prefix
          ? /^(?<prefix>[a-z]{1,6})(?<year>([0-9]{2})?[0-9]{2})-?(?<zeros>0{0,6})(?<number>[1-9][0-9]{0,6})$/i
          : /^(?<prefix>[a-z]{1,6})?(?<year>([0-9]{2})?[0-9]{2})-?(?<zeros>0{0,6})(?<number>[1-9][0-9]{0,6})$/i;
      return validationRegex.test(this.nextCaseNumber);
    }
  },
  methods: {
    handleCancel() {
      this.$emit("close");
    },
    async handleSubmit() {
      if (this.nextCaseNumber === this.nextCaseObject?.caseNumber) {
        this.goToCase(this.nextCaseObject.id);
      } else {
        try {
          this.isLoading = true;
          if (!this.isValidCaseNumber) {
            const { caseNumber } = scanCaseBarcode(this.nextCaseNumber);
            if (caseNumber) {
              this.nextCaseNumber = caseNumber;
            }
          }
          const fullCaseNumber = calculateAccessionNumbering(this.nextCaseNumber);
          const hasPrefix = /^[A-Z]{1,6}/.test(fullCaseNumber);
          const matchingCases = await CasesApi.searchStore.load({
            filter: ["accessionNumber", hasPrefix ? "=" : "contains", fullCaseNumber]
          });
          if (!matchingCases.length) {
            this.alertOpen = true;
            await window.alert("No matching cases found.");
            this.alertOpen = false;
            this.isLoading = false;
            this.$nextTick(() => {
              this.$refs.nextCaseNumber.focus();
              this.$refs.nextCaseNumber.selectAll();
            });
            return;
          }
          if (matchingCases.length === 1) {
            this.goToCase(matchingCases[0].caseId);
          } else {
            this.multipleCases = true;
            this.matchingCases = matchingCases;
            this.selectedCaseId = matchingCases[0].caseId;
          }
        } catch (error) {
          handleErrors(error);
        } finally {
          this.isLoading = false;
        }
      }
    },
    goToCase(caseId) {
      let destinationTab = this.$route.name;
      if (this.nextCaseUseDashboardMode) {
        switch (this.dashboardMode) {
          case "specimen":
            destinationTab = "Specimen";
            break;
          case "result":
            destinationTab = "SpecimenResults";
            break;
          case "signout":
            destinationTab = "SpecimenResults";
            break;
          case "transactions":
            destinationTab = "accesionTransactions";
            break;
          case "insurance":
            destinationTab = "accessionInsurance";
            break;
          case "distribution":
            destinationTab = "Distribution";
            break;
          case "history":
            destinationTab = "CaseHistory";
            break;
          default:
            destinationTab = "CaseView";
            break;
        }
      }
      this.$router.push({ name: destinationTab, params: { caseId: caseId } });
      this.$emit("close");
    },
    handleShortKey(event) {
      switch (event.srcKey) {
        case "enter": {
          if (this.multipleCases) {
            this.goToCase(this.selectedCaseId);
          } else {
            this.handleSubmit();
          }
          break;
        }
        case "down": {
          const matchingIds = this.matchingCases.map(e => e.caseId);
          const idx = matchingIds.indexOf(this.selectedCaseId);
          if (idx === matchingIds.length - 1) {
            this.selectedCaseId = matchingIds[0];
          } else {
            this.selectedCaseId = matchingIds[idx + 1];
          }
          break;
        }
        case "up": {
          const matchingIds = this.matchingCases.map(e => e.caseId);
          const idx = matchingIds.indexOf(this.selectedCaseId);
          if (idx === 0) {
            this.selectedCaseId = matchingIds[matchingIds.length - 1];
          } else {
            this.selectedCaseId = matchingIds[idx - 1];
          }
          break;
        }
      }
    },
    buttonClass(caseId) {
      return this.selectedCaseId === caseId ? "primary" : "light";
    },
    async loadDefaultCase() {
      if (this.nextCaseDashboardFilter && this.nextCaseToLoad) {
        this.nextCaseNumber = this.nextCaseToLoad.caseNumber;
      } else if (this.previousCaseId) {
        try {
          this.isLoading = true;
          const nextCaseObject = await CasesApi.getNextCaseNumber(this.previousCaseId);
          if (!nextCaseObject?.caseId) {
            window.notify("No subsequent cases available.", "error");
          } else if (nextCaseObject?.caseNumber) {
            this.nextCaseNumber = nextCaseObject.caseNumber;
          }
        } catch (error) {
          handleErrors(error);
        } finally {
          this.isLoading = false;
        }
      }
      this.$nextTick(() => {
        this.$refs.nextCaseNumber.focus();
        this.$refs.nextCaseNumber.selectAll();
      });
    },
    handleScanBarcode(barcode) {
      this.devlog("Scanned barcode in Next Case Popup", barcode);
      const { caseNumber } = scanCaseBarcode(barcode);
      if (caseNumber) {
        this.nextCaseNumber = caseNumber;
        this.$emit("close");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.next-case-button {
  width: 12rem;
  text-align: left;
}
</style>
