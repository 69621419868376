import { custom } from "devextreme/ui/dialog";

export default function (
  messageHtml,
  title,
  confirmName = "Ok",
  cancelName = "Cancel",
  focusCancel = true
) {
  return custom({
    title,
    messageHtml: `<div style="font-size: 1.25rem; font-weight: 450; text-align:center">${messageHtml}</div>`,
    dragEnabled: false,
    buttons: [
      {
        text: confirmName,
        onClick: () => true,
        stylingMode: "contained",
        elementAttr: {
          id: "accept",
          tabindex: "1"
        }
      },
      {
        text: cancelName,
        onClick: () => false,
        elementAttr: {
          id: "cancel",
          tabindex: "2"
        }
      }
    ],
    popupOptions: {
      onShown: function () {
        const parent = document.querySelector('.dx-overlay-content[tabindex="0"]');
        if (parent) {
          parent.setAttribute("tabindex", "-1");
        }
        if (focusCancel) {
          const cancel = document.querySelector("#cancel");
          cancel.focus();
        } else {
          const accept = document.querySelector("#accept");
          accept.focus();
        }
      }
    }
  });
}
