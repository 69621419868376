import store from "@/store";

/**
 *
 * @param {String} fieldName //Name of the HTML field.
 * @returns
 */
export function getDefaultStyles(fieldName, isObject) {
  fieldName = fieldName.replaceAll(" ", "");
  let settingToCheck = "";
  const {
    DefaultFontDiagnosis,
    DefaultFontClinical,
    DefaultFontGross,
    DefaultFontMicroscopic,
    DefaultFontSpecimenNotes,
    DefaultFontCaseNotes,
    DefaultEditorFontFamily,
    DefaultEditorFontSize
  } = store.state.labSettings;
  if (/diagnosis/i.test(fieldName) && DefaultFontDiagnosis) {
    settingToCheck = DefaultFontDiagnosis;
  }
  if (/clinical/i.test(fieldName) && DefaultFontClinical) {
    settingToCheck = DefaultFontClinical;
  }
  if (/gross/i.test(fieldName) && DefaultFontGross) {
    settingToCheck = DefaultFontGross;
  }
  if (/microscopic/i.test(fieldName) && DefaultFontMicroscopic) {
    settingToCheck = DefaultFontMicroscopic;
  }
  if (/specimenNotes|^notes$/i.test(fieldName) && DefaultFontSpecimenNotes) {
    settingToCheck = DefaultFontSpecimenNotes;
  }
  if (/caseNotes/i.test(fieldName) && DefaultFontCaseNotes) {
    settingToCheck = DefaultFontCaseNotes;
  }
  let styleObject = {
    font: DefaultEditorFontFamily ?? "Arial",
    size: DefaultEditorFontSize ?? "11pt",
    bold: /diagnosis/i.test(fieldName)
  };
  // harcoding for now - may need to be a lab setting in the future
  if (/frozen/i.test(fieldName)) {
    styleObject = {
      font: DefaultEditorFontFamily ?? "Arial",
      size: DefaultEditorFontSize ?? "11pt",
      bold: true
    };
  }
  if (settingToCheck) {
    styleObject = JSON.parse(settingToCheck);
  }
  if (isObject) {
    return styleObject;
  }
  return getStyles(styleObject);
}

export function getStyles(inputObject = {}) {
  if (!inputObject) {
    return "";
  }
  const { font, size, bold, italic } = inputObject;
  const fontFamily = font ? `font-family:${font};` : "";
  const fontSize = size ? `font-size: ${size};` : "";
  const fontWeight = bold ? `font-weight: bold;` : "";
  const fontStyle = italic ? `font-style: italic;` : "";
  return fontFamily + fontSize + fontWeight + fontStyle;
}
