<template>
  <div class="container-fluid bg-white py-1">
    <div class="mb-2">
      <full-search></full-search>
    </div>
    <div class="d-flex justify-content-end">
      <IconButton
        @click="handleSelectAll"
        v-show="isSelecting"
        class="btn btn-outline-secondary mr-1"
        iconClass="mr-1"
        icon="check"
        >Select All</IconButton
      >
      <IconButton
        icon="list-ul"
        @click="handleSelecting"
        class="font-weight-bold btn btn-outline-primary text-whie"
      >
        Select
      </IconButton>
      <button @click.stop class="menu-btn btn">
        <span></span>
        <span></span>
        <span></span>
        <div>
          <icon-button
            v-if="permissions.CaseView"
            @click="exportToExcel"
            v-tooltip="'Click to export as excel file'"
            class="btn ml-2 text-primary pointer"
            icon="file-excel"
          />
          <icon-button
            v-if="permissions.CaseView"
            type="button"
            @click="handleDownloadCases"
            v-tooltip="`Click to download selected accessions.`"
            class="btn text-primary pointer"
            icon="download"
          />
          <icon-button
            v-if="permissions.CaseSignout"
            type="button"
            @click="handleSignMultipleCase"
            v-tooltip="'Click to multiple sign.'"
            class="btn text-primary pointer"
            icon="edit"
          />
          <icon-button
            v-if="permissions.PathReportSendByPrint"
            type="button"
            v-tooltip="'Click to print selected accessions.'"
            class="btn text-primary pointer"
            @click="handleDistributeCases('print')"
            icon="print"
          />
          <icon-button
            v-if="permissions.PathReportSendByFax"
            type="button"
            v-tooltip="'Click to fax selected accessions.'"
            class="mx-1 btn text-primary pointer"
            @click="handleDistributeCases('fax')"
            icon="fax"
          />
          <icon-button
            v-if="permissions.PathReportSendByEmail"
            type="button"
            v-tooltip="'Click to email selected accessions.'"
            class="mx-1 btn text-primary pointer"
            @click="handleDistributeCases('email')"
            icon="envelope"
          />
          <icon-button
            v-if="permissions.PathReportSendByFileDrop"
            type="button"
            v-tooltip="'Click to send by file drop.'"
            class="mx-1 btn text-primary pointer"
            icon="file-pdf"
            @click="handleDistributeCases('pdf')"
          />
          <icon-button
            v-if="permissions.PathReportSendByHL7"
            type="button"
            v-tooltip="'Click to use hl7 on selected accessions.'"
            class="mx-1 btn text-primary pointer"
            icon="h-square"
            @click="handleDistributeCases('hl7')"
          />
        </div>
      </button>
    </div>
    <dx-list
      @itemClick="handleCaseClick"
      :dataSource="dataSource"
      :pullRefreshEnabled="true"
      selectionMode="all"
      @initialized="initializeGrid"
      :onContentReady="onContentReady"
      @selectionChanged="selectionChanged"
    >
      <template v-slot:item="{ data }">
        <div class="card shadow">
          <div class="card-title d-flex align-items-center">
            <input
              class="mr-1"
              v-show="isSelecting"
              type="checkbox"
              :checked="isItemSelected(data.caseId)"
            />
            <h5 class="m-0">{{ data.caseNumber }}</h5>
            <div class="d-flex ml-auto justify-content-center">
              <icon-button
                icon="envelope"
                iconClass="text-primary"
                @click.stop="createRequest(data)"
              />
              <icon-button v-bind="getStatusIcon(data)" />
            </div>
          </div>
          <div class="card-body">
            <div class="d-flex">
              <div class="avatar">
                <icon
                  :icon="data.patientGender ? data.patientGender.toLowerCase() : 'male'"
                  class="mr-1"
                />
              </div>
              <div class="ml-2">
                <p>{{ data.patientName }}</p>
                <p v-show="data.patientDateOfBirth">
                  <icon icon="birthday-cake" class="mr-1" />
                  {{ formatDate(data.patientDateOfBirth) }}
                </p>
                <p v-show="data.accountNumber">
                  <icon icon="id-badge" class="mr-1" /> {{ data.accountNumber }}
                </p>
                <p v-show="data.patientMRN">
                  <icon icon="laptop-medical" class="mr-1" /> {{ data.patientMRN }}
                </p>
                <p><icon icon="calendar" class="mr-1" /> {{ formatDate(data.collectedOn) }}</p>
                <p v-show="data.providerName">
                  <icon icon="user-md" class="mr-1" />{{ data.providerName }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </dx-list>
    <modal :status="isDistributionOpen" @close="isDistributionOpen = false">
      <distribution-popup
        @close="isDistributionOpen = false"
        @submitted="clearSelection"
        :method="distributionMethod"
        :caseIds="selectedCasesForDistribution"
      />
    </modal>
    <modal :status="isLocationPopupOpen" @close="isLocationPopupOpen = false">
      <lab-location-popup
        :isLoading="isLoading"
        @cancel="isLocationPopupOpen = false"
        @sign="handleSelectLabLocation"
      />
    </modal>
  </div>
</template>

<script>
import { DxList } from "devextreme-vue/list";
import { AuditLogApi, CasesApi, MacrosApi, ReportsApi } from "@/services";
import { mapGetters, mapState } from "vuex";
import { createLogItem, getTextFromHtml, getStatusIcon } from "@/modules/helpers";
import Modal from "./common/Modal.vue";
import pathReportMessages from "@/services/pathReportMessages";
import IconButton from "./common/IconButton.vue";
import {
  CLEAR_FREE_TEXT_FILTERS,
  RENDER_FREE_TEXT_SEARCH,
  SET_FREE_TEXT_SEARCH_FILTERS
} from "@/modules/constants";
import { format } from "date-fns";
import Icon from "./common/Icon.vue";
import FullSearch from "./Headers/FullSearch.vue";
import { shortenAccessionNumber } from "@/modules/helpers";
import moment from "moment";
import DataSource from "devextreme/data/data_source";
import eventBus, { fromBusEvent } from "@/modules/eventBus";
import { exhaustMap, switchMap, take, tap } from "rxjs/operators";
import LabLocationPopup from "./LabLocationPopup.vue";
import { AuditLogItems } from "@/modules/enums";
export default {
  components: {
    Modal,
    IconButton,
    DxList,
    Icon,
    FullSearch,
    LabLocationPopup
  },
  name: "CaseFreeTextSearch",
  data() {
    return {
      isLoading: false,
      grid: {},
      isSelecting: false,
      isViewerOpen: false,
      selectedItems: [],
      currentPdf: "",
      fileName: "",
      selectedCasesForDistribution: [],
      distributionMethod: null,
      reportCase: null,
      isDistributionOpen: false,
      selection: {
        mode: "multiple",
        allowSelectAll: true,
        showCheckBoxesMode: "always",
        selectAllMode: "page"
      },
      columnChooser: {
        allowSearch: true,
        enabled: true
      },
      grouping: {
        autoExpandAll: true
      },
      protocolLookup: {
        dataSource: { store: MacrosApi.searchStore },
        displayExpr: "macroName",
        valueExpr: "macroId"
      },
      dataSource: [],
      remoteOperations: {
        filtering: true,
        sorting: true,
        paging: true,
        grouping: false
      },
      isLocationPopupOpen: false
    };
  },
  domStreams: ["contentReady$"],
  subscriptions() {
    return {
      SET_FREE_TEXT_SEARCH_FILTERS: fromBusEvent(SET_FREE_TEXT_SEARCH_FILTERS).pipe(
        exhaustMap(async payload => {
          const Store = CasesApi.createFreeTextStore(payload);
          const groupData = data =>
            data.reduce((acc, curr) => {
              if (acc[curr.caseNumber]) {
                acc[curr.caseNumber].items = [...acc[curr.caseNumber].items, curr];
              } else {
                acc[curr.caseNumber] = {
                  key: curr.caseNumber,
                  items: [curr],
                  ...curr
                };
              }
              return acc;
            }, {});
          return new DataSource({
            key: "caseId",
            store: Store,
            postProcess: data => Object.values(groupData(data))
          });
        }),
        switchMap(dataSource => {
          this.dataSource = dataSource;
          return this.contentReady$.pipe(
            take(1),
            tap(() => {
              eventBus.$emit(RENDER_FREE_TEXT_SEARCH);
            })
          );
        })
      ),
      CLEAR_FREE_TEXT_FILTERS: fromBusEvent(CLEAR_FREE_TEXT_FILTERS).pipe(
        tap(() => {
          this.dataSource = [];
        })
      )
    };
  },

  computed: {
    ...mapState({
      caseDetails: state => state.accessionStore.caseDetails,
      caseHistory: state => state.accessionStore.history,
      caseStatuses: state => state.caseStatuses,
      isMobileView: state => state.isMobileView,
      accessionMode: state => state.applicationSettings.accessionMode,
      isLabClientUser: state => state.currentUser.isLabClientUser,
      currentLabLocation: state => state.currentLabLocation,
      availableLabLocations: state => state.availableLabLocations,
      useZip: state => state.labSettings.DownloadMultiplePathReportsAsZipFile
    }),
    ...mapGetters("report", ["isInline"]),
    ...mapGetters(["permissions"]),
    columns() {
      return [
        { type: "command", caption: "Actions", cellTemplate: "actions", allowExporting: false },
        { dataField: "caseNumber", dataType: "string", caption: "Case #", groupIndex: 1 },
        {
          dataField: "caseStatus",
          dataType: "string",
          allowFiltering: true,
          lookup: {
            dataSource: this.caseStatuses.map((e, i) => ({ displayName: e, id: i })),
            displayExpr: "displayName",
            valueExpr: "id"
          },
          caption: "Status",
          allowSearch: false
        },
        { dataField: "receivedOn", caption: "Received", dataType: "date" },
        { dataField: "collectedOn", caption: "Collected", dataType: "date" },
        { dataField: "reportedOn", caption: "Reported", dataType: "date" },
        { dataField: "providerName", dataType: "string", caption: "Provider" },
        { dataField: "pathologistName", dataType: "string", caption: "Pathologist" },
        { dataField: "patientName", dataType: "string", caption: "Patient" },
        { dataField: "patientDateOfBirth", caption: "DOB", dataType: "date" },
        { dataField: "site", dataType: "string" },
        {
          dataField: "diagnosis",
          dataType: "string",
          calculateCellValue: data => this.calculateHTML(data.diagnosis)
        },
        {
          visible: false,
          dataType: "string",
          dataField: "microscopic",
          calculateCellValue: data => this.calculateHTML(data.microscopic)
        },
        // {
        //   dataType: "string",
        //   visible: false,
        //   dataField: "notes",
        //   calculateCellValue: data => this.calculateHTML(data.notes)
        // },
        {
          visible: false,
          dataType: "string",
          dataField: "gross",
          calculateCellValue: data => this.calculateHTML(data.gross)
        },
        {
          visible: false,
          dataType: "string",
          dataField: "caseNote",
          calculateCellValue: data => this.calculateHTML(data.caseNote)
        },
        {
          visible: false,
          dataType: "string",
          dataField: "clinical",
          calculateCellValue: data => this.calculateHTML(data.clinical)
        }
      ];
    }
  },
  methods: {
    handleViewPathReport(caseId) {
      this.$store.dispatch("report/viewPathReport", { caseId });
    },
    handleSelectAll() {
      if (!this.allSelected) {
        this.grid.selectAll();
        this.allSelected = true;
      } else {
        this.grid.unselectAll();
        this.allSelected = false;
      }
    },
    handleSelecting() {
      if (this.isSelecting) {
        this.grid.unselectAll();
        this.allSelected = false;
      }
      this.isSelecting = !this.isSelecting;
    },
    formatDate(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    shortenAccessionNumber,
    selectionChanged(event) {
      this.selectedItems = [...this.selectedItems, ...event.addedItems];
      this.selectedItems = this.selectedItems.filter(e => {
        //Check if Item has been removed
        const inRemoved = event.removedItems.find(r => r.caseId === e.caseId);
        return !inRemoved;
      });
    },
    isItemSelected(caseId) {
      const inSelected = this.selectedItems.find(e => e.caseId === caseId);
      return !!inSelected;
    },
    getStatusIcon,
    closeReport() {
      this.$store.commit("report/toggleReportViewer", false);
    },
    clearSelection() {
      if (this.grid.clearSelection) {
        this.grid.clearSelection();
      }
    },
    async handleDistributeCases(method) {
      const cases = this.grid.getSelectedRowKeys();
      if (cases.length === 0) {
        return window.notify("No accessions selected.", "error");
      }
      for (const e of this.grid.getSelectedRowsData()) {
        if (![7, 8, 10, 11, 13, 14].includes(e.caseStatus)) {
          const confirm = await window.confirm(
            `<div class="text-center">
          One or more of the selected accessions has not been signed out. <br> Are you sure you want to distribute this report?
          </div>`
          );
          if (!confirm) {
            return;
          }
          break;
        }
      }
      this.selectedCasesForDistribution = cases;
      this.distributionMethod = method;
      this.isDistributionOpen = true;
    },
    async handleSignMultipleCase() {
      let isSignOut = false;
      const selectedRows = this.grid.getSelectedRowsData();
      if (selectedRows.length === 0) {
        return window.notify("No case selected.", "error");
      }
      if (this.availableLabLocations.length > 1 && !this.currentLabLocation) {
        this.isLocationPopupOpen = true;
        return;
      }
      const caseIds = selectedRows.map(v => {
        return v.caseId;
      });
      for (const e of selectedRows) {
        if ([6, 9, 12, 10].includes(e.caseStatus)) {
          isSignOut = true;
        } else {
          isSignOut = false;
        }
      }
      if (isSignOut && caseIds.length > 0) {
        document.body.style.cursor = "wait";
        const SignResponse = await CasesApi.multiSignCase(caseIds, this.currentLabLocation);
        if (SignResponse) {
          window.notify(`${caseIds.length} cases successfully signed`);
          const logItem = createLogItem(
            SignResponse.length === 1 ? SignResponse[0] : {},
            AuditLogItems.ChangeAccession
          );
          logItem.comments = `Signed out case${
            SignResponse.length > 1 ? "s" : ""
          } ${SignResponse.map(e => e.caseNumber).join(", ")}.`;
          AuditLogApi.insertLogMessage(logItem);
        }
        document.body.style.cursor = "";
        this.refreshGrid();
      } else {
        throw "Case cannot be signed with the current status.";
      }
      document.body.style.cursor = "";
    },
    exportToExcel() {
      let selectedRows = this.grid.getSelectedRowsData();
      if (selectedRows.length) {
        this.grid.exportToExcel(true);
      } else {
        selectedRows = this.grid.getVisibleRows().map(e => e.data);
        this.grid.exportToExcel();
      }
      const logItem = createLogItem({}, 8, "Case Search Grid");
      logItem.comments =
        "Exported the following cases to excel " +
        selectedRows.map(e => e.accessionNumber).join(" \n ");
      AuditLogApi.insertLogMessage(logItem);
      return;
    },
    async handleDownloadCases() {
      const cases = this.grid.getSelectedRowsData();
      if (cases.length === 0) {
        return window.notify("No accessions selected.", "error");
      }
      this.isLoading = true;
      try {
        const caseIds = cases.map(e => e.caseId);
        const extension = this.useZip && caseIds.length > 1 ? "zip" : "pdf";
        const file = await CasesApi.getMultiCasePdf(caseIds, extension);
        const url = URL.createObjectURL(file);
        const a = document.createElement("a");
        a.href = url;
        if (cases.length === 1) {
          a.download = `${format(new Date(), "MM/dd/yyyy")}_${
            cases[0]?.accessionNumber || ""
          }_PATH_REPORT.pdf`;
        } else {
          a.download = `${format(new Date(), "MM/dd/yyyy")}_PATH_REPORTS.${extension}`;
        }
        a.click();
        pathReportMessages.addPathReportMessage({ caseIds: cases.map(e => e.caseId), method: 6 });
        const logItem = createLogItem({}, 9, "Case Search Grid");
        logItem.comments =
          "Downloaded the following pdfs " + cases.map(e => e.accessionNumber).join(" \n ");
        AuditLogApi.insertLogMessage(logItem);
      } catch (error) {
        window.notify("Error loading file.", "error");
      }
      this.isLoading = false;
    },
    hasItemsExpr(data) {
      return data.specimenCount > 1;
    },
    calculateHTML(data) {
      return getTextFromHtml(data || "");
    },
    onContentReady({ component }) {
      this.contentReady$.next(component);
    },
    initializeGrid({ component }) {
      this.grid = component;
      this.grid.refresh = this.grid.reload;
      this.grid.clearSelection = this.grid.unselectAll;
      this.grid.on("exporting", data => {
        data.fileName = "Cases Search Grid " + format(new Date(), "MM/dd/yyyy");
        ["microscopic", "notes", "gross", "caseNote", "clinical"].forEach(column => {
          data.component.columnOption(column, "visible", true);
        });
      });
      this.grid.on("exported", data => {
        ["microscopic", "notes", "gross", "caseNote", "clinical"].forEach(column => {
          data.component.columnOption(column, "visible", false);
        });
      });
    },
    async renderPdf(caseId) {
      try {
        const caseInformation = await CasesApi.getCaseHeader(caseId);
        const isReported = [11, 14, 8].includes(caseInformation.status);
        let file;
        if (isReported) {
          file = await CasesApi.getNewestPathReport(caseId);
        } else {
          file = await ReportsApi.getSSRSReport({ caseId, format: "pdf" });
        }
        const blob = new Blob([file], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        this.fileName = `${caseInformation.caseNumber}-PathReport`;
        this.reportCase = caseId;
        this.currentPdf = url;
        this.isViewerOpen = !this.isViewerOpen;
      } catch (e) {
        window.notify("An error occurred", "error");
      }
    },
    async handlePrint() {
      if (this.reportCase) {
        const caseDetails = CasesApi.getCaseById(this.reportCase);
        const logItem = createLogItem(caseDetails, 1);
        logItem.comments = `Printed path report PDF.`;
        AuditLogApi.insertLogMessage(logItem);
      } else {
        const logItem = createLogItem({}, 1);
        logItem.comments = `Printed full patient history PDF.`;
        AuditLogApi.insertLogMessage(logItem);
      }
    },
    async handlePdfDownload() {
      if (this.reportCase) {
        const caseDetails = CasesApi.getCaseById(this.reportCase);
        pathReportMessages.addPathReportMessage({
          caseIds: [this.reportCase ?? this.$route.params.caseId],
          method: 6
        });
        const logItem = createLogItem(caseDetails, 1);
        logItem.comments = `Manually downloaded path report pdf`;
        AuditLogApi.insertLogMessage(logItem);
      } else {
        const logItem = createLogItem({}, 1);
        logItem.comments = `Manually downloaded full history path report pdf`;
        AuditLogApi.insertLogMessage(logItem);
      }
    },
    isCaseReadyForSignout(data) {
      return this.permissions.CaseSignout && [6, 9, 12].includes(data.caseStatus);
    },
    handleNavigateToCase(caseId) {
      if (this.$route.fullPath.includes("accession")) {
        return;
      }
      switch (this.accessionMode) {
        case "specimen":
          this.$router.push({
            name: "Specimen",
            params: { caseId }
          });
          break;
        case "result":
          this.$router.push({
            name: "SpecimenResults",
            params: { caseId }
          });
          break;
        case "signout":
          this.$router.push({
            name: "SpecimenResults",
            params: { caseId }
          });
          break;
        case "transactions":
          this.$router.push({
            name: "accesionTransactions",
            params: { caseId }
          });
          break;
        case "insurance":
          this.$router.push({
            name: "accessionInsurance",
            params: { caseId }
          });
          break;
        case "distribution":
          this.$router.push({
            name: "Distribution",
            params: { caseId }
          });
          break;
        case "history":
          this.$router.push({
            name: "CaseHistory",
            params: { caseId }
          });
          break;
        default:
          this.$router.push({
            name: "CaseView",
            params: { caseId }
          });
          break;
      }
    },
    handleClientCaseClick(data) {
      if (this.isSelecting) {
        const isSelected = data.component.isItemSelected(data.itemIndex);
        if (isSelected) {
          data.component.selectItem(data.itemIndex);
        } else {
          data.component.unselectItem(data.itemIndex);
        }
      } else {
        this.$router.push(`/accession/${data.itemData.caseId}`);
      }
      if (data.key) {
        if (data?.columnIndex && data.columnIndex < 2) {
          return;
        }
        this.$router.push(`/accession/${data.key}`);
      }
    },
    handleCaseClick(event) {
      //Stops the rerouting if you click inside the action cell which is located at index 0
      if (this.isLabClientUser) {
        this.handleClientCaseClick(event);
      } else {
        this.handleNavigateToCase(event);
      }
    },
    handleSelectLabLocation() {
      this.isLocationPopupOpen = false;
      this.handleSignMultipleCase();
    }
  }
};
</script>
<style lang="scss" scoped>
.pdf-modal {
  width: 50vw;
  height: 80vh;
}
.no-scroll {
  overflow-y: hidden;
}
.btn {
  color: $primary;
  &:hover {
    color: white !important;
  }
}

.icon {
  margin-right: 0.1rem;
}
::v-deep
  .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)
  > td:not(.dx-focused) {
  cursor: pointer;
}
.card {
  display: block;
  .card-title {
    margin-bottom: 2px;
  }
  .card-body {
    padding: 0px;
  }
  .avatar {
    display: flex;
    margin-right: 5px;
    & > svg {
      align-self: center;
      width: 50px;
      height: 50px;
    }
  }
  p {
    margin-bottom: 1px;
  }
}
::v-deep .dx-item.dx-list-item {
  margin: 10px auto 0px auto;
  border-top: initial;
}
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary {
  color: #6c757d;
  background-color: #fff;
  border-color: #6c757d;
}
::v-deep .dx-state-active {
  color: initial !important;
}
::v-deep
  .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)
  > td:not(.dx-focused) {
  cursor: pointer;
}
.main-view {
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}
.menu-btn {
  position: relative;
  span {
    display: block;
    background-color: gray;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    margin: 1px;
  }
  & > div {
    display: none;
    position: absolute;
    left: -180px;
    z-index: 900;
    background-color: #fff;
    width: 200px;
    border: 1px solid gray;
    ::v-deep button {
      display: flex;
      align-items: center;
      & > svg {
        width: 10%;
        margin: 5px;
      }
      &:not(:last-child) {
        border-bottom: 1px solid gray;
      }
      font-weight: bold;
      width: 100%;
    }
  }
  &:focus-within > div {
    display: flex;
    flex-direction: column;
  }
}
</style>
