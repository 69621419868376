import { fromEvent, merge } from "rxjs";
import { filter, map, switchMap, tap, throttleTime } from "rxjs/operators";

export function gridKeybindings(observable$, handleEnter) {
  return observable$.pipe(
    filter(({ element }) => {
      const searchPanel = element.querySelector('input[type="text"]');
      if (searchPanel) {
        return true;
      }
      return false;
    }),
    map(({ element, component }) => {
      const searchPanel = element.querySelector('input[type="text"]');
      return [component, searchPanel];
    }),
    switchMap(([grid]) => {
      function selectNextRow(change) {
        const totalCount = grid.totalCount();
        const currentSelection = grid.getSelectedRowKeys();
        const currentSelectionIndex = grid.getRowIndexByKey(currentSelection[0]);
        if (currentSelectionIndex + change > -1 && currentSelectionIndex + change < totalCount) {
          const rowElements = grid.getRowElement(currentSelectionIndex + change);
          if (rowElements?.length) {
            rowElements.forEach(element => {
              element.scrollIntoView(false);
            });
            const newRowKey = grid.getKeyByRowIndex(currentSelectionIndex + change);
            return grid.selectRows([newRowKey]);
          }
        }
        return;
      }
      function selectNextPage(change) {
        const totalCount = grid.pageCount();
        const currentPage = grid.pageIndex();
        if (currentPage + change > -1 && currentPage + change < totalCount) {
          return grid.pageIndex(currentPage + change);
        }
        return;
      }
      const keyDown$ = fromEvent(this.$el, "keydown");
      const arrowUp$ = keyDown$.pipe(
        filter(e => e.keyCode === 38),
        map(() => -1),
        tap(selectNextRow)
      );
      const arrowDown$ = keyDown$.pipe(
        filter(e => e.keyCode === 40),
        map(() => 1),
        tap(selectNextRow)
      );
      const arrowRight$ = keyDown$.pipe(
        filter(e => e.keyCode === 39),
        map(() => 1),
        tap(selectNextPage)
      );
      const arrowLeft$ = keyDown$.pipe(
        filter(e => e.keyCode === 37),
        map(() => -1),
        tap(selectNextPage)
      );
      const enterKey$ = keyDown$.pipe(
        filter(e => e.keyCode === 13),
        throttleTime(5000),
        tap(() => {
          const rows = grid.getSelectedRowsData();
          if (handleEnter) {
            handleEnter(rows);
          }
        })
      );

      return merge(arrowDown$, arrowUp$, arrowRight$, arrowLeft$, enterKey$);
    })
  );
}
