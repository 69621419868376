<template>
  <div class="footer">
    <div class="footer__content">
      <span>Version {{ version }} / {{ uiVersion }} {{ stage ? `(${stage})` : "" }}</span>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import SystemInfoService from "../services/systemInfo";
import { version } from "../../package.json";
export default {
  data() {
    return {
      version: "0",
      ieVersion: "",
      deployedOn: new Date().toString(),
      halfScroll: false,
      prompt: false,
      uiVersion: version
    };
  },
  mounted() {
    SystemInfoService.getData()
      .then(response => {
        this.setSystemInfo(response);
        this.version = response.version;
        this.deployedOn = new Date(response.deployedOn).toString();
      })
      .catch(err => {
        console.error(err);
      });

    if (typeof window !== "undefined") {
      window.addEventListener("scroll", this.onScroll, { passive: true });
    }
    if (window.newrelic) {
      window.newrelic.addRelease("Ip Pro", this.uiVersion);
    }
    localStorage.setItem("version", this.uiVersion);
  },
  computed: {
    stage() {
      return process.env.VUE_APP_STAGE;
    }
  },
  methods: {
    ...mapMutations(["setSystemInfo"]),
    onScroll(e) {
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.halfScroll = top > 350;
    },
    scrollTop() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }
};
</script>

<style lang="scss" scoped>
.footer {
  /* position: relative; */
  width: 100%;
  background: $background;
  color: white;
  &__content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
#scrollTop {
  position: absolute;
  bottom: 0px;
  width: 50px;
  height: 25px;
  font-size: 1.5rem;
  background-color: #f4f4f4;
}

.update-dialog {
  position: fixed;
  left: 50%;
  bottom: 64px;
  transform: translateX(-50%);
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 12px;
  max-width: 576px;
  color: white;
  background-color: #2c3e50;
  text-align: left;
  &__actions {
    display: flex;
    margin-top: 8px;
  }
  &__button {
    margin-right: 8px;
    &--confirm {
      margin-left: auto;
    }
  }
}
</style>
