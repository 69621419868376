<template>
  <div
    class="p-1 procedure-modal container"
    v-shortkey="previewShortkey"
    @shortkey="previewSelectedImages"
  >
    <dx-grid-with-search
      noDataText="No images found."
      ref="imagesGrid"
      title="Images"
      :toolbar="toolbar"
      :pageSize="10"
      :columns="columns"
      :dataSource="listData"
      @initialized="initializeGrid"
      :keyExpr="'id'"
      @content-ready="onContentReady"
    >
      <template v-slot:extraActions>
        <add-button type="button" @click="handleAddImage" v-if="permissions.CaseImageCreateEdit" />
      </template>
      <template v-slot:actions="{ data: { key } }">
        <div class="d-flex justify-content-between align-items-center">
          <icon-button
            icon="eye"
            class="text-primary pointer p-0 mr-1"
            role="button"
            v-tooltip.left="'Preview Image'"
            @click="previewImage(key)"
            v-if="permissions.CaseImageView"
          />
          <icon-button
            icon="print"
            class="text-primary pointer p-0 mr-1"
            role="button"
            v-tooltip.left="'Print image.'"
            v-if="permissions.CaseImageView"
            @click="printImage(key)"
          />
          <icon-button
            icon="pen-alt"
            class="text-primary pointer p-0 mr-1"
            role="button"
            v-tooltip.left="'Edit image.'"
            @click="editImage(key)"
            v-if="permissions.CaseImageView"
          />
          <icon-button
            icon="trash-alt"
            class="text-danger pointer p-0 mr-1"
            role="button"
            v-tooltip.left="'Remove image.'"
            @click="removeImage(key)"
            v-if="permissions.CaseImageCreateEdit"
          />
        </div>
      </template>
    </dx-grid-with-search>
    <modal :status="isAdding" @close="handleCancel">
      <case-edit-image-form
        v-if="imageId"
        :caseId="caseDetails.caseId"
        :specimenId="currentSpecimen.specimenId"
        :imageId="imageId"
        @submit="handleCancel"
      />
      <case-image-form
        v-else
        :caseId="caseDetails.caseId"
        :specimenId="currentSpecimen.specimenId"
        @submit="handleCancel"
      />
    </modal>
  </div>
</template>

<script>
import DxGridWithSearch from "./common/DxGridWithSearch.vue";
import AddButton from "./common/AddButton.vue";
import Modal from "./common/Modal.vue";
import { DropdownApi, ImagesApi } from "../services";
import { mapGetters, mapState } from "vuex";
import IconButton from "./common/IconButton.vue";
import CaseImageForm from "./CaseImageForm.vue";
import { altKey, booleanLookup, filterCellUTC, formatDatetimeCell } from "@/modules/helpers";
import printJS from "print-js";
import ArrayStore from "devextreme/data/array_store";
import CaseEditImageForm from "./CaseEditImageForm.vue";
import Hermes from "hermes-channel";

export default {
  name: "QuickLink-Image-Popup",
  components: { DxGridWithSearch, AddButton, Modal, IconButton, CaseImageForm, CaseEditImageForm },
  props: ["caseId"],
  data() {
    return {
      isAdding: false,
      reqLabels: 1,
      imageTypes: [],
      tags: [],
      imageId: null,
      grid: {},
      hasContentReadyHappened: false,
      previewShortkey: altKey("p")
    };
  },
  mounted() {
    this.loadImages();
    DropdownApi.getTags().then(res => {
      this.tags = res || [];
    });
    ImagesApi.getImageTypes().then(res => {
      this.imageTypes = res || [];
    });
    if (!Object.keys(this.caseDetails).length && this.caseId) {
      this.loadCaseFromProps();
    }
  },
  beforeDestroy() {
    if (this.caseId) {
      this.$store.commit("accessionStore/clearCaseDetails");
    }
  },
  methods: {
    initializeGrid({ component }) {
      this.grid = component;
    },
    async previewImage(imageId) {
      if (this.$route.query?.isPreview) {
        Hermes.send("SET_DATA", [imageId]);
      } else {
        this.$store.dispatch("sessionDetails/useImageFrame", [imageId]);
      }
    },
    handleAddImage() {
      this.isAdding = true;
      this.imageId = null;
    },
    async editImage(imageId) {
      this.imageId = imageId;
      this.isAdding = true;
    },
    async printImage(imageId) {
      const file = await ImagesApi.getImageContent(imageId);
      const url = URL.createObjectURL(file);
      return printJS(url, "image");
    },
    async removeImage(imageId) {
      const confirm = await window.confirm(`Are you sure you want to delete this image?`);
      if (!confirm) {
        return;
      }
      await this.$store.dispatch("accessionStore/removeCaseImage", imageId);
      this.grid.refresh(true);
    },
    handleCancel() {
      this.imageId = null;
      this.isAdding = false;
      this.grid.refresh(true);
    },
    onContentReady() {
      if (
        !this.disableAutoAddQuickLinks &&
        !this.hasContentReadyHappened &&
        !this.caseImages.length &&
        this.permissions.CaseImageCreateEdit
      ) {
        this.isAdding = true;
      }
      this.hasContentReadyHappened = true;
    },
    loadImages() {
      const caseId = this.$route.params.caseId || this.caseId;
      this.$store.dispatch("accessionStore/getCaseImages", caseId);
    },
    loadCaseFromProps() {
      this.$store.dispatch("accessionStore/getCaseDetails", this.caseId);
      this.$store.dispatch("accessionStore/getCaseSpecimens", this.caseId);
    },
    previewSelectedImages() {
      let selectedRows = this.grid.getSelectedRowsData();
      if (!selectedRows.length) {
        const visibleRows = this.grid.getVisibleRows();
        selectedRows = visibleRows.map(e => e.data);
      }
      this.$store.dispatch(
        "sessionDetails/useImageFrame",
        selectedRows.map(e => e.id)
      );
    },
    previewTooltip() {
      let selection = "all";
      if (this.grid?.getSelectedRowsData) {
        const rows = this.grid.getSelectedRowsData();
        if (rows.length) {
          selection = "selected";
        }
      }
      return `Click to preview ${selection} images (Alt + P).`;
    }
  },
  computed: {
    ...mapState({
      currentLab: state => state.currentLab,
      caseDetails: state => state.accessionStore.caseDetails,
      currentSpecimen: state => state.accessionStore.currentSpecimen,
      caseImages: state => state.accessionStore.caseImages,
      disableAutoAddQuickLinks: state => state.applicationSettings.disableAutoAddQuickLinks
    }),
    ...mapGetters(["permissions"]),
    listData() {
      return new ArrayStore({
        data: [...this.caseImages],
        key: "id"
      });
    },
    gridSpecimens() {
      if (this.caseDetails?.specimens) {
        return [{ specimenOrder: "All", id: null }, ...this.caseDetails.specimens];
      }
      return [];
    },

    columns() {
      return [
        {
          dataField: "addedBy",
          dataType: "string"
        },
        {
          dataField: "addedOn",
          dataType: "datetime",
          calculateDisplayValue: data => data.addedOn && formatDatetimeCell(data.addedOn),
          calculateFilterExpression: filterCellUTC("addedOn")
        },
        {
          dataField: "keywords",
          dataType: "string"
        },
        {
          dataField: "imageTypeId",
          dataType: "number",
          caption: "Type",
          lookup: {
            dataSource: { store: this.imageTypes },
            displayExpr: "displayName",
            valueExpr: "id"
          }
        },
        {
          dataField: "specimenId",
          dataType: "number",
          caption: "Specimen",
          lookup: {
            dataSource: { store: this.caseDetails?.specimens ?? [] },
            valueExpr: "id",
            displayExpr: "specimenOrder"
          }
        },
        {
          dataField: "comment",
          caption: "Comments",
          dataType: "string"
        },
        {
          dataField: "printOnReport",
          dataType: "boolean",
          lookup: booleanLookup
        },
        {
          type: "buttons",
          caption: "Actions",
          cellTemplate: "actions"
        }
      ];
    },
    toolbar() {
      return {
        items: [
          {
            location: "after",
            template: "extraActions",
            visible: this.permissions.CaseImageCreateEdit
          },
          {
            widget: "dxButton",
            options: {
              icon: "fa fa-eye",
              onClick: this.previewSelectedImages,
              hint: this.previewTooltip(),
              elementAttr: {
                class: "icon-color"
              },
              text: "Preview images"
            },
            locateInMenu: "auto",
            showText: "inMenu"
          }
        ]
      };
    }
  }
};
</script>

<style></style>
