<template>
  <div>
    <label for=""><b>Locations</b></label>
    <DxTagBox
      :noLabel="true"
      :value="value"
      valueExpr="id"
      placeholder="Select Locations..."
      :onInitialized="initInput"
      :isValid="isValid"
      display-expr="displayName"
      :items="items"
      value-expr="id"
      v-bind="$attrs"
      :maxDisplayedTags="10"
      :showSelectionControls="true"
      search-expr="displayName"
      :searchEnabled="true"
      :show-clear-button="true"
      :onValueChanged="handleValueChange"
      ref="selectBox"
      tagTemplate="tag"
    >
      <template v-slot:tag="{ data }">
        <div
          :class="`dx-tag-content${isExpired(data) ? ' expired' : ''}`"
          v-tooltip="value.length > 10 ? 'Click to view or remove selected locations' : ''"
        >
          <span @click.stop="handleClickMulti">{{ locationDisplayName(data) }}</span>
          <div v-if="!isExpired(data)" class="dx-tag-remove-button"></div>
        </div>
      </template>
    </DxTagBox>
    <modal :status="isPopupOpen" @close="closePopup">
      <h2>Selected Locations</h2>
      <checkbox
        v-for="location of selectedLocations"
        v-bind:key="location.id"
        :id="location.displayName + 'Checkbox'"
        :label="location.displayName"
        class="ml-2"
        :value="value.includes(location.id)"
        @input="handleClickCheckbox(location)"
      />
      <div class="d-flex justify-content-end mx-2">
        <button @click="closePopup" type="button" class="mx-2 btn btn-primary">Done</button>
      </div>
    </modal>
  </div>
</template>

<script>
import DxTagBox from "devextreme-vue/tag-box";
import { validatorMsgMapBase } from "@/modules/helpers";
import Modal from "@/components/common/Modal.vue";
import { DropdownApi } from "@/services";
import { sortBy } from "lodash";
import Checkbox from "@/components/common/Checkbox.vue";

export default {
  props: {
    value: {
      required: false
    },
    items: {
      type: Array,
      default: () => []
    },
    validator: {
      type: Object
    },
    validatorMsgMap: {
      type: Object,
      default: () => {
        return validatorMsgMapBase;
      }
    },
    providerContacts: {
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      isPopupOpen: false,
      selectedLocations: []
    };
  },
  components: {
    DxTagBox,
    Modal,
    Checkbox
  },
  created() {
    window.addEventListener("wheel", this.handleScroll, {
      capture: true
    });
  },
  beforeDestroy() {
    window.removeEventListener("wheel", this.handleScroll, {
      capture: true
    });
  },
  computed: {
    displaySelected: {
      get() {
        return this.value;
      },
      set(value) {
        return this.$emit("input", value);
      }
    },
    isValid() {
      if (this.validator) {
        return !this.validator.$invalid || !this.validator.$error;
      }
      return true;
    }
  },
  methods: {
    initInput({ component }) {
      this.tagBox = component;
    },
    isExpired(data) {
      if (this.providerContacts.length) {
        const { isExpired } = this.providerContacts.find(e => e.locationId === data.id) || false;
        return isExpired;
      }
      return false;
    },
    locationDisplayName(data) {
      if (typeof data === "string") {
        return data;
      }
      return data.displayName;
    },
    handleScroll(event) {
      if (event.target.className !== "dx-item-content dx-list-item-content") {
        this.tagBox.close();
      }
    },
    handleValueChange(data) {
      this.$emit("input", data.value);
    },
    handleClickMulti() {
      if (this.value?.length > 5) {
        this.isPopupOpen = true;
        this.getLocationData();
      } else {
        this.tagBox.open();
      }
    },
    closePopup() {
      this.isPopupOpen = false;
      this.selectedLocations = [];
    },
    async getLocationData() {
      const locations = await DropdownApi.searchLocations.load();
      for (const locationId of this.value) {
        const locationData = locations.find(e => e.id === locationId);
        this.selectedLocations.push(locationData);
      }
      this.selectedLocations = sortBy(this.selectedLocations, "displayName");
    },
    handleClickCheckbox(data) {
      if (this.value.includes(data.id)) {
        this.$emit(
          "input",
          this.value.filter(e => e !== data.id)
        );
      } else {
        this.$emit("input", [...this.value, data.id]);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.expired {
  text-decoration: line-through;
}
</style>
