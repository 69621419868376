import { HubConnectionBuilder } from "@microsoft/signalr";
import { fromEventPattern, BehaviorSubject, fromEvent, combineLatest } from "rxjs";
import {
  switchMap,
  tap,
  filter,
  switchMapTo,
  retry,
  map,
  startWith,
  takeUntil,
  repeat
} from "rxjs/operators";
import { mapState } from "vuex";
import store from "@/store";

function fromWebsocketEvent(connection, event) {
  return fromEventPattern(
    handler => connection.on(event, handler),
    handler => connection.off(event, handler)
  );
}

const BASE_URL = process.env.VUE_APP_API_BASE_URL;
const connection = new HubConnectionBuilder()
  .withUrl(`${BASE_URL}/casehub`, {
    accessTokenFactory: () => {
      // Get and return the access token.
      // This function can return a JavaScript Promise if asynchronous
      // logic is required to retrieve the access token.
      return store.state.token;
    }
  })
  .withAutomaticReconnect()
  .build();
const wsConnectionStatu$ = new BehaviorSubject({ connected: false });
connection.onclose(() => {
  console.log("Connection closed 🚫");
  wsConnectionStatu$.next({ connected: false });
});
connection.onreconnected(() => wsConnectionStatu$.next({ connected: true }));
connection.onreconnecting(() => wsConnectionStatu$.next({ connected: false }));
const relativeTimeFormmater = new Intl.RelativeTimeFormat("en", {
  style: "short",
  numeric: "auto"
});
const dateFormatter = new Intl.DateTimeFormat("en", {
  dateStyle: "full",
  timeStyle: "short"
});

const documentVisibility$ = fromEvent(document, "visibilitychange").pipe(
  map(() => document.visibilityState === "visible"),
  startWith(true)
);

export default {
  data() {
    return {
      enterTime: new Date().getTime()
    };
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser,
      token: state => state.token
    }),
    displayName() {
      return this.currentUser.displayName;
    },
    groupName() {
      const caseId = this.$route.params.caseId;
      if (caseId) {
        return caseId.toString();
      }
      return null;
    },
    pageName() {
      return this.$route.name;
    }
  },
  subscriptions() {
    const closeConnection$ = documentVisibility$.pipe(
      filter(isVisible => !isVisible),
      tap(() => console.log("closing connection")),
      switchMap(() => connection.stop())
    );

    const startConnection$ = this.$watchAsObservable("token", { immediate: true }).pipe(
      filter(({ newValue }) => !!newValue),
      switchMapTo(combineLatest([wsConnectionStatu$, documentVisibility$])),
      filter(([{ connected }, isVisible]) => !connected && isVisible),
      switchMap(async () => {
        await connection.start();
        return wsConnectionStatu$.next({ connected: true });
      }),
      retry(5)
    );

    const joinCase$ = wsConnectionStatu$.pipe(
      // Only subscribe if the connection is open
      filter(({ connected }) => connected),
      tap(() => console.log("Connected to CaseHub 🚀")),
      switchMap(() => this.$watchAsObservable("groupName", { immediate: true })),
      filter(({ newValue, oldValue }) => {
        if (newValue != oldValue && newValue) {
          return true;
        }
        return false;
      }),
      switchMap(async ({ newValue }) => {
        return this.joinCase(newValue);
      })
    );
    const leaveCase$ = wsConnectionStatu$.pipe(
      filter(({ connected }) => connected),
      switchMap(() => this.$watchAsObservable("groupName")),
      filter(({ newValue, oldValue }) => {
        if (newValue != oldValue && oldValue) {
          return true;
        }
        return false;
      }),
      switchMap(async ({ oldValue }) => {
        return this.leaveCase(oldValue);
      })
    );
    const receiveMessage$ = wsConnectionStatu$.pipe(
      filter(({ connected }) => connected),
      switchMapTo(fromWebsocketEvent(connection, "ReceiveMessage")),
      tap(() => console.log("Regular message received.")),
      filter(data => {
        if (Array.isArray(data)) {
          const [user] = data;
          return this.groupName && user !== this.displayName;
        }
        return false;
      }),
      tap(data => {
        if (Array.isArray(data)) {
          const [user, message] = data;
          console.log("Message from %s\n%s", user, data);
          window.alert(message);
        }
      }),
      takeUntil(combineLatest([leaveCase$, closeConnection$])),
      repeat()
    );

    const joinMessage$ = wsConnectionStatu$.pipe(
      filter(({ connected }) => connected),
      switchMapTo(fromWebsocketEvent(connection, "UserJoin")),
      tap(() => console.log("Join message received.")),
      filter(user => this.groupName && user !== this.displayName),
      tap(user => {
        const enterDate = this.getEnterDate();
        window.notify(`${user} has opened this case.`, "info", 5000);
        connection.invoke(
          "SendMessageToGroup",
          this.groupName,
          "User",
          `This case was opened by ${this.displayName} on ${enterDate}.  Proceeding with any edits may result in data being lost because two users are editing this case at same time. We recommend you exit case and return later.`
        );
      }),
      takeUntil(combineLatest([leaveCase$, closeConnection$])),
      repeat()
    );

    return {
      joinCase$,
      leaveCase$,
      startConnection$,
      receiveMessage$,
      joinMessage$,
      closeConnection$
    };
  },
  methods: {
    joinCase(caseId) {
      if (caseId) {
        this.enterTime = new Date().getTime();
        return connection.invoke("JoinGroup", caseId, this.currentUser.displayName);
      }
    },
    getEnterTime() {
      const timeinms = new Date().getTime() - this.enterTime;
      const seconds = Math.floor(timeinms / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const unit = minutes > 1 ? (hours > 1 ? "hours" : "minutes") : "seconds";
      const value = minutes > 1 ? (hours > 1 ? hours : minutes) : seconds;
      return relativeTimeFormmater.format(0 - value, unit);
    },

    getEnterDate() {
      const enterDate = new Date(this.enterTime);
      return dateFormatter.format(enterDate);
    },
    async leaveCase(caseId) {
      try {
        await connection.invoke("LeaveGroup", caseId);
      } catch (error) {
        console.error("Error leaving case:", error);
      }
    },
    handleBeforeUnload() {
      connection.stop();
    }
  },
  beforeDestroy() {
    this.handleBeforeUnload();
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
  created() {
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  }
};
