import api from "./api.js";
class HL7Service {
  constructor() {
    this.url = "/api/HL7Orders";
  }
  get searchStore() {
    return api.createSearch(this.url + "/search2", ["id", "contactId"]);
  }
  createSearch(url, labId) {
    return api.createSearch(this.url + url, undefined, { labId });
  }
  getOrderById(orderId) {
    return api.get(this.url + "/" + orderId);
  }
  /**
   *
   * @param {number} id
   * @param {boolean} activate // Boolean to toggle the order.
   * @returns
   */
  activateOrder(id, activate) {
    return api.put(`${this.url}/${id}/activate/${activate}`);
  }
}
export default new HL7Service();
