<template>
  <DxPopup
    :visible.sync="popupVisible"
    :drag-enabled="false"
    :hide-on-outside-click="true"
    :show-close-button="false"
    :show-title="true"
    :onHidden="toggleVisibility"
    :width="400"
    :height="380"
    container="#app"
  >
    <template v-slot:title>
      <div v-html="title"></div>
    </template>
    <DxPosition at="center" my="center" :of.sync="positionOf" collision="fit" />
    <DxToolbarItem
      widget="dxButton"
      toolbar="bottom"
      location="center"
      :options="launchButtonOptions"
    />
    <DxToolbarItem
      widget="dxButton"
      toolbar="bottom"
      location="center"
      :options="closeButtonOptions"
    />
    <div class="h3" v-shortkey="launchAllShortkey" @shortkey="launchAllSlides">
      <ul>
        <li v-for="(item, idx) of data" :key="item.url">
          <button class="btn btn-link" @click="handleOpenSlide(item.url)">
            {{ buildSlideTitle(item, idx) }}
          </button>
        </li>
      </ul>
    </div>
  </DxPopup>
</template>

<script>
import launchImage from "@/modules/launchImage";
import { DxPopup, DxPosition, DxToolbarItem } from "devextreme-vue/popup";
import { mapState, mapGetters } from "vuex";
import { SpecimenNumbersEnum } from "@/modules/enums";
import { toLetters } from "@/modules/helpers";
import { altKey } from "@/modules/helpers";

export default {
  components: {
    DxPopup,
    DxPosition,
    DxToolbarItem
  },
  props: {
    positionOf: String,
    title: String,
    specimenId: Number,
    data: Array,
    popupVisible: Boolean
  },
  data() {
    return {
      closeButtonOptions: {
        text: "Close",
        onClick: () => {
          this.$emit("update:popupVisible", false);
        }
      },
      launchButtonOptions: {
        visible: this.showLaunchAllButton,
        text: "Launch All",
        onClick: () => {
          this.launchAllSlides();
        }
      },
      launchAllShortkey: altKey("a")
    };
  },
  watch: {
    popupVisible: {
      immediate: true,
      handler(nv) {
        if (nv) {
          setTimeout(() => {
            const launchEl = document.querySelector('div[aria-label="Launch All"]');
            if (launchEl) {
              launchEl.innerHTML = launchEl.innerHTML.replace("All", "<u>A</u>ll");
            }
          }, 500);
        }
      }
    }
  },
  computed: {
    ...mapState("sessionDetails", ["viewedSlideImages"]),
    ...mapState({
      currentLab: state => state.currentLab,
      SlideImagesApplicationHandler: state => state.labSettings.SlideImagesApplicationHandler
    }),
    ...mapGetters("accessionStore", ["specimenNumbering"]),

    showLaunchAllButton() {
      const { protocol } = JSON.parse(this.SlideImagesApplicationHandler);
      return protocol !== "mview";
    }
  },
  methods: {
    buildSlideTitle(item, idx) {
      const url = new URL(item.url);
      const params = url.searchParams;
      const slideId = params.get("SlideID");
      if (slideId === null) {
        const { blockNum, slideLabel, slideProcedure, type } = item;
        if (type && type.toLowerCase() === "specimen") {
          return "Specimen Slide";
        }
        let items = [];
        if (blockNum) {
          const blockDisplay =
            this.specimenNumbering === SpecimenNumbersEnum.Numbers ? toLetters(blockNum) : blockNum;
          items.push("Block " + blockDisplay);
        }
        if (slideLabel) {
          items.push("Slide " + slideLabel);
        }
        if (slideProcedure) {
          items.push("Order " + slideProcedure);
        }
        if (items.length) {
          return items.join(", ");
        }
        return `Slide ${idx + 1}`;
      }
      return `${slideId} (${item?.type ? item.type : ""})`;
    },
    toggleVisibility() {
      this.$emit("update:popupVisible", false);
    },
    handleOpenSlide(link, idx) {
      launchImage(link, this.SlideImagesApplicationHandler, idx);
    },
    launchAllSlides() {
      for (const idx in this.data) {
        const slide = this.data[idx];
        this.handleOpenSlide(slide.url, idx + 1);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
