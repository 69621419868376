import api from "./api.js";
class Providers {
  constructor() {
    this.url = "/api/Provider";
  }
  get searchContacts() {
    return api.createSearch(this.url + "/contacts/search", "contactId");
  }
  get searchProviders() {
    return api.createSearch(this.url + "/provider/search");
  }
  get searchLocations() {
    return api.createSearch(this.url + "/locations/search");
  }
  get providersForLocation() {
    return api.createSearch(this.url);
  }
  createSearch(url, key, labId) {
    return api.createSearch(this.url + url, key, { labId });
  }
  addLocation(location) {
    return api.post(this.url + "/Locations", location);
  }
  addProvider(provider, labId) {
    return api.post(this.url + `?labId=${labId}`, provider);
  }
  getProviders(labId) {
    return api.get(this.url + `?labId=${labId}`);
  }
  getProviderById(id) {
    return api.get(this.url + `/${id}`);
  }
  getLocations() {
    return api.get(this.url + `/locations/search`);
  }
  createPrinterSearch(id) {
    return api.createSearch(`/api/Locations/${id}/Printers`);
  }
  refreshPrinters() {
    return api.put("/api/Printers/Refresh");
  }
  getLocationById(locationId) {
    return api.get(this.url + `/LocationsById?id=${locationId}`);
  }
  getContacts(labId) {
    return api.get(this.url + `/Contacts/?labId=${labId}`);
  }
  getContactsById(contactIds) {
    return api.post(this.url + `/contactById`, contactIds);
  }
  updateProvider(provider) {
    return api.put(this.url, provider);
  }
  updateContact(contact) {
    return api.put(this.url + "/Contact", contact);
  }
  updateLocation(location) {
    return api.put(this.url + "/Locations", location);
  }
  getContactsByLocation(location) {
    return api.post(this.url + "/ProvidersByLocation", [location]);
  }
  getContactsByProvider(providerId) {
    return api.post(this.url + "/LocationsForProvider?providerId=" + providerId);
  }
}
export default new Providers();
