<template>
  <div>
    <label><b>Tags</b></label>
    <DxTagBox
      :value="value"
      placeholder="Select Tags..."
      :show-selection-controls="true"
      search-expr="displayName"
      display-expr="displayName"
      :data-source="dataSource"
      :maxDisplayTags="5"
      :showDropDownButton="true"
      :search-enabled="true"
      :hideSelectedItems="true"
      @value-changed="onValueChanged"
      :dropdownOptions="dropdownOptions"
    >
    </DxTagBox>
  </div>
</template>

<script>
import DropDownApis from "../../../services/dropdown.js";
import DxTagBox from "devextreme-vue/tag-box";
import { mapState } from "vuex";

export default {
  name: "Tag",
  inheritAttrs: false,
  components: {
    DxTagBox
  },
  props: {
    value: {
      required: true
    }
  },
  data: () => ({
    dropdownOptions: {
      position: "bottom"
    }
  }),
  methods: {
    onValueChanged(e) {
      this.tags = e.value;
    },
    calculateDisplay(data) {
      return data.displayName || data.name;
    }
  },
  computed: {
    ...mapState(["currentUser", "currentLab"]),
    dataSource() {
      return DropDownApis.searchTags;
    },
    tags: {
      get() {
        return this.value;
      },
      set(value) {
        return this.$emit("input", value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.custom__tag {
  background-color: $neutral;
  color: #35495e;
}
</style>
