<template>
  <div class="">
    <label
      class="text-capitalize"
      :for="$attrs.name || $attrs.id"
      :id="$attrs.id && $attrs.id + 'label'"
      v-if="!!label"
    >
      <b>{{ label }}</b>
    </label>
    <div :class="hasSearchValue ? 'has-search-value' : ''">
      <DxTagBox
        :isValid="isValid"
        :placeholder="placeholder"
        :disabled="disabled"
        v-model="displaySelected"
        :display-expr="displayExpr"
        :items="items"
        :value-expr="valueExpr"
        v-bind="$attrs"
        :maxDisplayedTags="maxDisplayedTags"
        :showSelectionControls="true"
        :search-expr="searchExpr"
        :searchEnabled="true"
        :show-clear-button="true"
        :selectAllMode="selectAllMode"
        ref="selectBox"
        :element-attr="inputAttrs"
        itemTemplate="itemTemplate"
        :drop-down-options="dropDownOptions"
        :noDataText="noDataText"
        :opened="openOnMount"
        :onInitialized="initInput"
      >
        <template #itemTemplate="{ data }">
          <span :class="!isDataEffective(data) && grayOutExpired ? 'disabled-data' : ''">{{
            tagDisplayText(data)
          }}</span>
        </template>
        <template v-slot:tag="{ data }">
          <div
            class="dx-tag-content"
            v-tooltip="
              value.length > maxDisplayedTags ? 'Click to view or remove selected items' : ''
            "
          >
            <span @click.stop="handleClickMulti">{{ tagDisplayText(data) }}</span>
            <div class="dx-tag-remove-button"></div>
          </div>
        </template>
      </DxTagBox>
    </div>
    <div v-if="(validator && validator.$error) || (validator && validator.$invalid)">
      <div
        class="validation-error"
        v-for="(key, index) in Object.keys(validator.$params)"
        :key="index"
      >
        <span class="error" v-if="!validator[key]">{{ validatorMsgMap[key] }}</span>
      </div>
    </div>
    <modal :status="isSelectPopupOpen" @close="closeSelectPopup">
      <h2>Selected Items</h2>
      <checkbox
        v-for="item of selectedItems"
        v-bind:key="item[valueExpr]"
        :id="item[valueExpr] + 'Checkbox'"
        :label="tagDisplayText(item)"
        class="ml-2"
        :value="value.includes(item[valueExpr])"
        @input="handleClickCheckbox(item)"
      />
      <div class="d-flex justify-content-end mx-2">
        <button @click="closeSelectPopup" type="button" class="mx-2 btn btn-primary">Done</button>
      </div>
    </modal>
  </div>
</template>

<script>
import { isEffective, validatorMsgMapBase } from "@/modules/helpers";
import DxTagBox from "devextreme-vue/tag-box";
import Modal from "@/components/common/Modal.vue";
import Checkbox from "@/components/common/Checkbox.vue";

export default {
  name: "TagInput",
  inheritAttrs: false,
  props: {
    noLabel: {
      type: Boolean
    },
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    value: {
      required: false
    },
    maxDisplayedTags: {
      required: false,
      default() {
        return 3;
      }
    },
    valueExpr: {
      default() {
        return "id";
      }
    },
    items: {
      type: Array,
      default: () => []
    },
    validator: {
      type: Object
    },
    validatorMsgMap: {
      type: Object,
      default: () => {
        return validatorMsgMapBase;
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    displayExpr: {
      default() {
        return data => data && (data?.displayName || data?.displayText || data?.name || data);
      }
    },
    searchExpr: {
      default() {
        this.displayExpr;
      }
    },
    highlightSearchValue: {
      default: () => {
        return false;
      }
    },
    grayOutExpired: {
      default: () => {
        return false;
      }
    },
    dropDownOptions: {
      default() {}
    },
    noDataText: {
      default: "No data to display"
    },
    selectAllMode: {
      default: "allPages"
    },
    openOnMount: {
      default: false
    },
    enableSelectPopup: {
      default: false
    },
    selectedItems: {
      default() {
        return [];
      }
    }
  },
  components: {
    DxTagBox,
    Modal,
    Checkbox
  },
  data() {
    return {
      tagBox: {},
      isSelectPopupOpen: false
    };
  },
  computed: {
    displaySelected: {
      get() {
        return this.value;
      },
      set(value) {
        return this.$emit("input", value);
      }
    },
    isValid() {
      if (this.validator) {
        return !this.validator.$invalid || !this.validator.$error;
      }
      return true;
    },
    hasSearchValue() {
      if (!this.highlightSearchValue) {
        return false;
      }
      if (this.value !== null && this.value !== undefined && this.value !== "") {
        if (Array.isArray(this.value)) {
          return this.value.length > 0;
        }
        return true;
      }
      return false;
    },
    inputAttrs() {
      return {
        ...this.$attrs,
        class: this.hasSearchValue ? "has-search-value" : ""
      };
    }
  },
  methods: {
    initInput({ component }) {
      this.tagBox = component;
    },
    focus() {
      if (this.$refs.selectBox) {
        const box = this.$refs.selectBox.$el.querySelector("input.dx-texteditor-input");
        if (box) {
          box.focus();
        }
      }
    },
    tagDisplayText(data) {
      if (typeof data === "string") {
        return data;
      }
      if (typeof this.displayExpr === "function") {
        return this.displayExpr(data);
      }
      const displayText = data[this.displayExpr] || data.displayName;
      return displayText;
    },
    isDataEffective(data) {
      if (data?.isEnabled === false) {
        return false;
      }
      return isEffective({
        expiryOn: data?.expiryOn || data?.expiresOn,
        effectiveOn: data?.effectiveOn
      });
    },
    handleClickMulti() {
      if (this.enableSelectPopup && this.value?.length > this.maxDisplayedTags) {
        this.isSelectPopupOpen = true;
        this.$emit("tagClick");
      } else {
        this.tagBox.open();
      }
    },
    closeSelectPopup() {
      this.isSelectPopupOpen = false;
      this.$emit("closePopup");
    },
    handleClickCheckbox(data) {
      if (this.value.includes(data[this.valueExpr])) {
        this.$emit(
          "input",
          this.value.filter(e => e !== data[this.valueExpr])
        );
      } else {
        this.$emit("input", [...this.value, data[this.valueExpr]]);
      }
    }
  }
};
</script>
<style scoped>
label {
  min-height: 16px;
}
.error {
  font-size: 0.75rem;
}
.disabled-data {
  color: gray;
  font-style: italic;
}
</style>
