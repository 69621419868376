<template>
  <div class="layout-wrapper d-flex flex-column">
    <Header v-if="currentUserId" class="layout-header" />
    <div class="layout-main">
      <slot> </slot>
    </div>
    <Footer v-if="currentUserId" />
  </div>
</template>

<script>
import Header from "../../components/Headers/Navigation";
import Footer from "../../components/Footer";
import { mapGetters, mapState } from "vuex";

export default {
  name: "Layout",
  components: {
    Header,
    Footer
  },
  computed: {
    ...mapState({
      currentLab: state => state.currentLab,
      isOpen: state => state.report.isOpen,
      currentUserId: state => state.currentUser?.id
    }),
    ...mapGetters("report", ["isInline"])
  },

  watch: {
    $route(to, from) {
      const toDepth = to.path.split("/").length;
      const fromDepth = from.path.split("/").length;
      this.transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
    }
  }
};
</script>

<style lang="scss">
@import "../../assets/custom";
.layout-wrapper {
  background: $secondary;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  .layout-header {
    flex-shrink: 0;
    margin-bottom: 0.2rem;
  }
  .layout-main {
    background: $secondary;
    display: flex;
    overflow: hidden;
    flex-grow: 1;
    height: 100%;
  }
}

@keyframes pulse-red {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 250ms ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
