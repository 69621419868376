import api from "./api.js";
class SystemInfo {
  constructor() {
    this.url = "/api/SystemInfo";
  }
  getData() {
    return api.get(this.url);
  }
}
export default new SystemInfo();
