<template>
  <div class="p-1 procedure-modal container">
    <dx-grid-with-search
      gridName="tags"
      ref="holdsGrid"
      title="Holds"
      :pageSize="10"
      @initialized="initGrid"
      :columns="columns"
      :dataSource="caseHolds"
      noDataText="No hold codes found."
      :toolbar="toolbar"
      @content-ready="onContentReady"
    >
      <template v-slot:actions="{ data: { data: hold } }">
        <div class="d-flex justify-content-center align-items-center">
          <icon-button
            icon="pen-alt"
            class="text-primary pointer mr-2 p-0"
            role="button"
            v-tooltip.left="'Edit hold.'"
            v-if="!isDisabled"
            @click="editHold(hold)"
          />
          <icon-button
            icon="trash-alt"
            class="text-danger pointer p-0"
            role="button"
            v-tooltip.left="'Remove hold.'"
            @click="removeHold(hold)"
          />
        </div>
      </template>
      <template v-slot:extraActions>
        <div class="d-flex">
          <icon-button
            type="button"
            class="btn btn-outline-primary mx-1"
            icon="print"
            @click="printHolds"
          >
          </icon-button>
          <add-button
            type="button"
            @click="handleAddHolds"
            v-if="permissions.CaseHoldCreateEdit && !isDisabled"
          />
        </div>
      </template>
    </dx-grid-with-search>
    <modal :status="isAdding" @close="handleCancel">
      <div>
        <h3>{{ hold.id === undefined ? "Add" : "Edit" }} Hold</h3>
        <form
          @submit.prevent="handleSubmit"
          class="px-4 bg-white order-form container"
          v-shortkey="saveShortkey"
          @shortkey="handleSubmit"
        >
          <div class="row">
            <select-input
              v-focus
              :disabled="!!hold.id"
              v-model="hold.tagId"
              :displayExpr="displayExpr"
              :searchExpr="searchExpr"
              label="Hold"
              class="col"
              :dataSource="holdCodesDataSource"
              :validator="$v.hold.tagId"
              searchMode="startswith"
            />
          </div>
          <div class="row">
            <text-area-input
              class="col"
              label="Text"
              maxLength="2001"
              ref="holdText"
              id="desc"
              rows="5"
              cols="10"
              :resize="false"
              v-model="hold.text"
              :generalMacrosEnabled="true"
              :caseId="caseId"
            />
          </div>

          <div class="row mt-2 px-3 justify-content-end">
            <div>
              <button @click="handleCancel" type="button" class="btn btn-danger mx-2">
                Cancel
              </button>
              <button :disabled="$v.$invalid" type="submit" class="btn btn-primary">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
import DxGridWithSearch from "./common/DxGridWithSearch.vue";
import AddButton from "./common/AddButton.vue";
import Modal from "./common/Modal.vue";
import { ReportsApi, AuditLogApi, DropdownApi, MacrosApi } from "../services";
import TextAreaInput from "./TextAreaInput.vue";
import { mapGetters, mapState } from "vuex";
import IconButton from "./common/IconButton.vue";
import { cloneDeep } from "lodash";
import { required } from "vuelidate/lib/validators";
import SelectInput from "@/components/common/SelectInput.vue";
import { altKey, createLogItem } from "@/modules/helpers";
import DataSource from "devextreme/data/data_source";
import { handleErrors } from "@/modules/handleErrors";
import { CaseEditTypeEnum, CaseStatusEnum, MacroTypeEnum } from "@/modules/enums";
import moment from "moment";

export default {
  name: "QuickLink-Hold-Popup",
  components: {
    DxGridWithSearch,
    AddButton,
    Modal,
    TextAreaInput,
    IconButton,
    SelectInput
  },
  props: ["caseId"],
  data() {
    return {
      isAdding: false,
      reqLabels: 1,
      listData: [],
      holdList: [],
      holdsGrid: {},
      hold: {
        caseId: null,
        specimenId: null,
        text: "",
        type: "H",
        tagId: null
      },
      saveShortkey: altKey("s"),
      hasContentReadyHappened: false,
      isMacroOpen: false,
      generalMacros: [],
      positionToPlaceCursor: 0
    };
  },
  validations() {
    return {
      hold: {
        tagId: {
          required
        }
      }
    };
  },
  created() {
    MacrosApi.getMacrosByUserAndType({
      userId: this.currentUser.id,
      macroTypeId: MacroTypeEnum.General,
      loadOptions: {}
    }).then(res => {
      this.generalMacros = res.data || [];
    });
    if (!Object.keys(this.caseDetails).length && this.caseId) {
      this.loadCaseFromProps();
    }
  },
  beforeDestroy() {
    if (this.caseId) {
      this.$store.commit("accessionStore/clearCaseDetails");
    }
  },
  methods: {
    handleAddHolds() {
      this.isAdding = true;
    },
    initGrid({ component }) {
      this.holdsGrid = component;
    },
    async printHolds() {
      const caseId = this.$route.params.caseId || this.caseId;
      return await ReportsApi.printTagNoteTemplate({
        printerId: this.tagNotePrinter,
        LabId: this.currentLab,
        caseNumber: caseId,
        numberOfcopies: this.reqLabels
      });
    },
    editHold(hold) {
      if (!this.permissions.CaseHoldCreateEdit) {
        window.notify("You do not have permission to perform this action", "warning");
        return;
      }
      this.hold = cloneDeep(hold);
      this.hold.tagId = hold.tagId;
      this.hold.type = "H";
      this.isAdding = true;
    },
    async removeHold(item) {
      if (!this.permissions.CaseHoldCreateEdit) {
        window.notify("You do not have permission to perform this action", "warning");
        return;
      }
      const confirm = this.confirmRemoveHold
        ? await window.confirm(`Are you sure you want to delete this item?`)
        : true;
      if (confirm) {
        const caseId = this.caseDetails?.caseId || this.caseId;
        try {
          await this.$store.dispatch("accessionStore/removeQuickLink", {
            ids: [item.id],
            type: "H",
            caseId
          });
          if (!this.caseHolds.length) {
            this.$emit("close");
          }
          await this.$store.dispatch("accessionStore/getCaseHeader", caseId);
        } catch (error) {
          handleErrors(error);
        }
      }
    },
    handleCancel() {
      this.hold = {
        caseId: null,
        specimenId: null,
        text: "",
        type: "H",
        tagId: null
      };
      this.holdsGrid.refresh(true);
      this.isAdding = false;
    },
    async handleSubmit() {
      const caseId = this.$route.params.caseId || this.caseId;
      try {
        await this.$store.dispatch("accessionStore/upsertCaseQuickLink", {
          ...this.hold,
          caseId,
          type: "H"
        });
        const logItem = createLogItem(this.caseDetails, 1);
        logItem.comments = `Added a hold code, \n${JSON.stringify(this.hold, null, 2)}`;
        AuditLogApi.insertLogMessage(logItem);
        await this.$store.dispatch("accessionStore/getCaseQuickLinks", caseId);
        this.handleCancel();
      } catch (error) {
        handleErrors(error);
      }
    },
    onContentReady() {
      if (
        !this.disableAutoAddQuickLinks &&
        !this.hasContentReadyHappened &&
        !this.caseHolds.length &&
        this.permissions.CaseHoldCreateEdit &&
        !this.isDisabled
      ) {
        this.isAdding = true;
      }
      this.hasContentReadyHappened = true;
    },
    displayExpr(data) {
      if (this.SearchByHoldCode && data?.holdCode) {
        return `${data.holdCode}  -  ${data.displayName}`;
      }
      return data?.displayName;
    }
  },
  computed: {
    ...mapState({
      tagNotePrinter: state => state.applicationSettings.tagNotePrinter,
      currentLab: state => state.currentLab,
      caseHolds: state => state.accessionStore.caseHolds,
      caseDetails: state => state.accessionStore.caseDetails,
      caseHeader: state => state.accessionStore.caseHeader,
      confirmRemoveHold: state => state.applicationSettings.confirmRemoveHold,
      currentUser: state => state.currentUser,
      disableAutoAddQuickLinks: state => state.applicationSettings.disableAutoAddQuickLinks,
      SearchByHoldCode: state => state.labSettings.SearchByHoldCode
    }),
    ...mapGetters("accessionStore", ["isReported", "isCaseEditable"]),
    ...mapGetters(["permissions"]),
    formDisabled() {
      if (this.isReported) {
        if (this.isCaseEditable) {
          return [CaseEditTypeEnum.NonDiagnostic, CaseEditTypeEnum.Billing].includes(this.editType);
        }
        return true;
      } else {
        return !this.isCaseEditable;
      }
    },
    gridSpecimens() {
      if (this.caseDetails?.specimens) {
        return [{ specimenOrder: "All", id: null }, ...this.caseDetails.specimens];
      }
      return [];
    },
    gridHolds() {
      if (this.holdList !== null && this.holdList?.length) {
        return [{ displayName: "All", id: null }, ...this.holdList];
      }
      return [];
    },
    gridFilter() {
      return "H";
    },
    isDisabled() {
      return [
        CaseStatusEnum.SignedOnHold,
        CaseStatusEnum.ReportedPrelim,
        CaseStatusEnum.Signed,
        CaseStatusEnum.Reported,
        CaseStatusEnum.SignedAgain,
        CaseStatusEnum.ReReleased
      ].includes(this.caseHeader.status);
    },
    columns() {
      return [
        {
          dataField: "addedBy",
          dataType: "string"
        },
        {
          dataField: "type",
          dataType: "string",
          visible: false
        },
        {
          dataField: "addedOn",
          dataType: "date"
        },
        {
          dataField: "tagId",
          caption: "Hold",
          lookup: {
            dataSource: { store: DropdownApi.searchHoldCodes, sort: ["displayName"] },
            valueExpr: "id",
            displayExpr: "displayName"
          }
        },
        {
          dataField: "text",
          caption: "Message",
          dataType: "string"
        },
        {
          type: "buttons",
          caption: "Actions",
          cellTemplate: "actions",
          visible: this.permissions.CaseHoldCreateEdit
        }
      ];
    },
    toolbar() {
      return {
        items: [
          {
            location: "after",
            template: "extraActions"
          }
        ]
      };
    },
    holdCodesDataSource() {
      const now = moment().format("yyyy-MM-DDTHH:mm:ss");
      const effectiveFilter = [
        [["effectiveOn", "<", now], "or", ["effectiveOn", "=", null]],
        "and",
        [["expiryOn", ">", now], "or", ["expiryOn", "=", null]]
      ];
      return new DataSource({
        store: DropdownApi.searchHoldCodes,
        filter: effectiveFilter,
        sort: this.searchExpr
      });
    },
    searchExpr() {
      return this.SearchByHoldCode ? "holdCode" : "displayName";
    }
  }
};
</script>
<style></style>
