<template>
  <form v-if="!isLoading" @submit.prevent="handleSubmit" class="position-relative main_form p-4">
    <div class="row">
      <h2>Print Labels</h2>
      <loader size="small" class="ml-auto" v-show="isSearching" />
    </div>
    <div class="row">
      <printer-select-with-status
        label="Req Printer"
        :dataSource="printerOptions"
        name="recPrinterId"
        class="col"
        v-model="reqPrinterId"
      />
      <printer-select-with-status
        label="Spec Printer"
        :dataSource="printerOptions"
        name="specPrinterId"
        class="col"
        v-model="specPrinterId"
      />
    </div>
    <fieldset class="row">
      <template>
        <text-input
          v-focus
          label="From Case"
          name="fromCaseNumber"
          ref="fromCase"
          class="col"
          @blur="formatAccessionNumber"
          :validator="$v.fromCaseNumber"
          v-model="fromCaseNumber"
          :validatorMsgMap="validationMsgMap"
        />
        <div class="col">
          <text-input
            label="To Case"
            name="toCaseNumber"
            ref="toCase"
            @blur="formatAccessionNumber"
            v-model="toCaseNumber"
            :validator="$v.toCaseNumber"
            :validatorMsgMap="validationMsgMap"
          />
        </div>
      </template>
    </fieldset>
    <div>
      <div class="row align-items-start">
        <number-input
          tabindex="0"
          class="col-4"
          v-model="reqLabels"
          name="reqLabels"
          label="Req. Slip"
          :disabled="!fromCaseId"
        />
      </div>
      <div class="row align-items-start">
        <div class="col-4">
          <number-input
            tabindex="0"
            :disabled="
              multipleCases && fromCaseId
                ? false
                : useAccessioningNumber && !availableSpecimens.length
            "
            label="Spec. Container"
            class=""
            name="specLabels"
            v-model="specLabels"
          />
          <select-input
            label="Specimen Target"
            displayExpr="specimenOrder"
            v-if="specimenTarget === 'specific' && fromCase.caseId"
            :items="availableSpecimens"
            v-model="specimenId"
            name="specimenId"
            valueExpr="specimenOrder"
          />
        </div>
        <div v-if="!availableSpecimens.length && !multipleCases" class="align-self-end">
          <input
            v-model="useAccessioningNumber"
            type="checkbox"
            id="useAccessioningNumber"
            name="useAccessioningNumber"
            :disabled="!fromCaseId"
          />
          <label class="font-weight-bold" for="accession">Number From Accession</label>
        </div>
        <fieldset v-else-if="!multipleCases">
          <div cla>
            <input
              type="radio"
              id="All"
              v-model="specimenTarget"
              name="specimenTarget"
              :disabled="!fromCaseId"
              value="all"
              tabindex="0"
            />
            <label class="font-weight-bold mx-1" for="All">All</label>
          </div>
          <div>
            <input
              v-model="specimenTarget"
              type="radio"
              tabindex="0"
              id="specific"
              name="specimenTarget"
              value="specific"
            />
            <label class="font-weight-bold mx-1" for="specific">Specific Specimen</label>
          </div>
        </fieldset>
        <div v-else-if="multipleCases">
          <div class="d-flex align-items-start">
            <checkbox v-model="printReq" id="reqLabelPrint" label="Req. Slip" />
          </div>
          <div class="d-flex align-items-end">
            <checkbox
              :disabled="useAccessioningNumber"
              label="Spec. Container"
              class="mr-2"
              name="specLabelPrint"
              id="specLabelPrint"
              v-model="printSpec"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="buttons">
      <button type="submit" class="btn-primary btn m-2">Submit</button>
    </div>
  </form>
  <div v-else class="col d-flex align-items-center flex-column justify-content-center">
    <loader class="mx-auto" />
    <p>Sending print command....</p>
  </div>
</template>

<script>
import { catchError, switchMap, filter, tap, distinctUntilChanged, map } from "rxjs/operators";
import { mapState } from "vuex";
import {
  calculateAccessionNumbering,
  calculateAccessionNumberingWithoutPrevCentury,
  validatorMsgMapBase
} from "../modules/helpers";
import Checkbox from "./common/Checkbox.vue";
import { required } from "vuelidate/lib/validators";
import NumberInput from "@/components/common/NumberInput.vue";
import { CasesApi, PrintersApi, ReportsApi, SpecimensApi } from "../services";
import SelectInput from "./common/SelectInput.vue";
import Loader from "./common/Loader.vue";
import DataSource from "devextreme/data/data_source";
import api from "@/services/api";
import { LabNumberingMethodsEnum } from "@/modules/enums";
import TextInput from "./common/TextInput.vue";
import PrinterSelectWithStatus from "@/components/PrinterSelectWithStatus.vue";
import ScannerDetection from "@/modules/scanner";
import { scanCaseBarcode } from "@/modules/scanCaseBarcode";
import { handleErrors } from "@/modules/handleErrors";

export default {
  components: { NumberInput, Checkbox, SelectInput, Loader, TextInput, PrinterSelectWithStatus },
  name: "PrintLabelsModal",
  props: {
    caseIdFilter: {
      required: false
    }
  },
  data() {
    return {
      //Departamental Numbering Lab
      fromCaseId: null,
      toCaseId: null,

      //Pathology Numbering Lab
      fromCaseNumber: "",
      toCaseNumber: "",
      isFromCaseValid: false,
      isToCaseValid: false,

      reqLabels: 1,
      specLabels: 1,
      printReq: false,
      printSpec: false,
      specimenTarget: "all",
      useAccessioningNumber: true,
      from: "",
      to: "",
      fromCase: null,
      toCase: null,
      availableSpecimens: [],
      specimenId: null,
      isLoading: false,
      isSearching: false,
      accessions: CasesApi.searchStore,
      reqPrinterId: null,
      specPrinterId: null,
      printerOptions: []
    };
  },
  subscriptions() {
    return {
      fromCaseGet: this.$watchAsObservable("fromCaseId").pipe(
        filter(({ newValue }) => newValue),
        tap(({ newValue, oldValue }) => {
          if (!this.toCaseId || this.toCaseId === oldValue) {
            this.toCaseId = newValue;
            this.$refs.toCase?.focus();
          }
        }),
        switchMap(({ newValue }) => this.getCase(newValue)),
        tap(caseDetails => {
          this.fromCase = caseDetails;
        }),
        catchError((e, s) => s)
      ),
      clearFromCase: this.$watchAsObservable("fromCaseId").pipe(
        filter(({ newValue, oldValue }) => oldValue && !newValue),
        tap(({ oldValue }) => {
          if (this.toCaseId === oldValue) {
            this.toCaseId = null;
            this.toCase = null;
          }
          this.fromCase = null;
        })
      ),
      formattedFromCaseNumber: this.$watchAsObservable("fromCaseNumber").pipe(
        filter(({ newValue }) => newValue && this.caseNumberWithoutPrefix(newValue).length > 11),
        map(({ newValue }) => newValue),
        tap(value => {
          if (!this.toCaseNumber) {
            this.toCaseNumber = value;
          }
        }),
        distinctUntilChanged(),
        switchMap(async value => {
          const caseNumber = scanCaseBarcode(value).caseNumber || value;
          try {
            this.isSearching = true;
            const valueSplit = caseNumber.split("-");
            const matchingCases = await CasesApi.searchStore.load({
              filter: [
                ["accessionNumber", "contains", caseNumber],
                "and",
                ["numberSequence", valueSplit[1]]
              ]
            });
            const exactMatch = matchingCases.find(e => e.accessionNumber === caseNumber);
            if (exactMatch || matchingCases?.length === 1) {
              const caseDetails = exactMatch || matchingCases[0];
              this.fromCaseId = caseDetails.caseId;
              if (this.usePathologyNumbering) {
                this.fromCaseNumber = caseDetails.accessionNumber;
                if (this.fromCaseId === this.toCaseId) {
                  this.toCaseNumber = this.fromCaseNumber;
                }
              }
              this.isFromCaseValid = true;
              return true;
            } else {
              this.fromCaseId = null;
            }
          } catch (error) {
            console.log("error occured fetching case id.");
          } finally {
            this.isSearching = false;
          }
          this.isFromCaseValid = false;
          return false;
        })
      ),
      formattedToCaseNumber: this.$watchAsObservable("toCaseNumber").pipe(
        filter(({ newValue }) => newValue && this.caseNumberWithoutPrefix(newValue).length > 11),
        map(({ newValue }) => newValue),
        distinctUntilChanged(),
        switchMap(async value => {
          const caseNumber = scanCaseBarcode(value).caseNumber || value;
          if (caseNumber === this.fromCaseNumber) {
            this.toCaseId = this.fromCaseId;
            this.toCaseNumber = this.fromCaseNumber;
            this.isToCaseValid = true;
            return true;
          }
          try {
            this.isSearching = true;
            const valueSplit = caseNumber.split("-");
            const matchingCases = await CasesApi.searchStore.load({
              filter: [
                ["accessionNumber", "contains", caseNumber],
                "and",
                ["numberSequence", valueSplit[1]]
              ],
              select: ["caseId", "accessionNumber"]
            });
            const exactMatch = matchingCases.find(e => e.accessionNumber === caseNumber);
            if (exactMatch || matchingCases?.length === 1) {
              const caseDetails = exactMatch || matchingCases[0];
              this.toCaseId = caseDetails.caseId;
              if (this.usePathologyNumbering) {
                this.toCaseNumber = caseDetails.accessionNumber;
              }
              this.isToCaseValid = true;
              return true;
            } else {
              this.toCaseId = null;
            }
          } catch (error) {
            console.log("error occured fetching case id.");
          } finally {
            this.isSearching = false;
          }
          this.isToCaseValid = false;
          return false;
        })
      ),
      toCaseGet: this.$watchAsObservable("toCaseId").pipe(
        filter(({ newValue }) => newValue && newValue !== this.fromCaseId),
        switchMap(({ newValue }) => this.getCase(newValue)),
        tap(caseDetails => {
          this.toCase = caseDetails;
        }),
        catchError((e, s) => s)
      ),
      specimenValues: this.$watchAsObservable("fromCase").pipe(
        switchMap(data => {
          const { newValue } = data;
          if (newValue.caseId) {
            this.specimenTarget = "";
            this.specimenId = "";
            return SpecimensApi.getSpecimen(newValue.caseId);
          }
          return null;
        }),
        tap(data => {
          this.availableSpecimens = data || [];
        })
      )
    };
  },
  created() {
    PrintersApi.getLabPrinters().then(res => {
      this.printerOptions = new DataSource({
        store: res,
        sort: ["displayName"]
      });
    });
    if (!this.prefixes?.length) {
      this.$store.dispatch("dropdowns/getPrefixes");
    }
  },
  mounted() {
    this.specLabels = parseInt(this.SpecimenContainerLabelsPerSpecimen) ?? 1;
    this.reqLabels = parseInt(this.RequisitionLabelsPerAccession) ?? 1;

    this.reqPrinterId = this.defaultReqLabelPrinter;
    this.specPrinterId = this.defaultSpecLabelPrinter;
    if (!this.reqPrinterId) {
      this.reqPrinterId = this.defaultLabelPrinter;
    }
    if (!this.specPrinterId) {
      this.specPrinterId = this.defaultLabelPrinter;
    }
    if (this.caseIdFilter) {
      this.fromCaseId = this.caseIdFilter;
      this.toCaseId = this.caseIdFilter;
      this.fromCaseNumber = this.caseDetails.caseNumber;
      this.toCaseNumber = this.caseDetails.caseNumber;
    }
    this.scanner = new ScannerDetection({
      onComplete: this.handleScanBarcode,
      stopPropogation: true,
      minLength: 4
    });
  },
  beforeDestroy() {
    if (this.scanner?.stopScanning) {
      this.scanner.stopScanning();
    }
  },
  methods: {
    async getCase(caseId) {
      this.isSearching = true;
      const caseDetails = await CasesApi.getCaseById(caseId);
      this.isSearching = false;
      return caseDetails;
    },
    async handleSubmit() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }

      this.isLoading = true;
      try {
        if (this.fromCaseId !== this.toCaseId || this.LabelPrintingUsesBatching) {
          await ReportsApi.getLabelsInRange({
            fromCaseId: this.fromCaseId,
            toCaseId: this.toCaseId,
            specPrinterId: this.specPrinterId,
            reqPrinterId: this.reqPrinterId,
            printerId: this.defaultLabelPrinter,
            specLabelCopies: this.specLabels,
            reqLabelCopies: this.reqLabels,
            specId: this.specimenTarget === "specific" ? this.specimenId : null
          });
        } else {
          const checkCount = await ReportsApi.getLabelCount({
            fromCaseId: this.fromCaseId,
            toCaseId: this.toCaseId,
            specLabelCopies: this.specLabels,
            reqLabelCopies: this.reqLabels
          });
          if (checkCount?.errorMessage) {
            window.alert(checkCount.errorMessage);
            return;
          }
          await ReportsApi.getLabels({
            caseId: this.fromCaseId,
            specPrinterId: this.specPrinterId,
            reqPrinterId: this.reqPrinterId,
            printerId: this.defaultLabelPrinter,
            specLabelCopies: this.specLabels,
            reqLabelCopies: this.reqLabels,
            specId: this.specimenTarget === "specific" ? this.specimenId : null
          });
        }
        window.notify("Success");
      } catch (error) {
        handleErrors(error);
      } finally {
        this.isLoading = false;
      }
    },
    formatAccessionNumber(event) {
      const { value } = event.target;
      let prefix = "";
      if (value) {
        if (this.hasPrefix(value)) {
          const regex = /^(?<prefix>[a-z]{1,6})[0-9]/i.exec(value);
          prefix = regex.groups.prefix.toUpperCase();
        }
        let targetNumber = this.caseNumberWithoutPrefix(value);
        if (targetNumber.replace("-", "").length < 11) {
          const number = calculateAccessionNumberingWithoutPrevCentury(targetNumber);
          if (number?.length === 12) {
            this[event.target.name] = prefix + number;
          }
        }
      }
    },
    handleScanBarcode(barcode) {
      this.devlog("Scanned barcode in Print Labels Popup", barcode);
      const { caseNumber } = scanCaseBarcode(barcode);
      if (!caseNumber) {
        return;
      }
      const activeElement = document.activeElement;
      if (activeElement.nodeName === "INPUT") {
        if (activeElement.name === "fromCaseNumber") {
          this.fromCaseNumber = caseNumber;
        }
        this.toCaseNumber = caseNumber;
        this.$nextTick(() => this.$refs.toCase.selectAll());
      }
    },
    hasPrefix(value) {
      if (value) {
        const regex = /^(?<prefix>[a-z]{1,6})[0-9]/i.exec(value);
        return Boolean(regex?.groups?.prefix);
      }
      return false;
    },
    caseNumberWithoutPrefix(caseNumber) {
      if (!caseNumber) {
        return "";
      }
      const regex = /^(?<prefix>[a-z]{1,6})[0-9]/i.exec(caseNumber);
      return caseNumber.replace(regex?.groups?.prefix, "");
    }
  },
  validations() {
    return {
      toCaseNumber: {
        required,
        validCase: async value => (value ? this.isToCaseValid : true),
        greaterThanFrom: value => {
          value = value.replace(/[a-z]/gi, "");
          const matcher = /(\d{2})(\d{2})-(\d{7})/im;
          if (matcher.test(value) && matcher.test(this.fromCaseNumber)) {
            const [fromYear, fromNumberSequence] = this.fromCaseNumber
              .replace(/[a-z]/gi, "")
              .split("-");
            const [toYear, toNumberSequence] = value.split("-");
            return (
              parseInt(toYear) >= parseInt(fromYear) &&
              parseInt(toNumberSequence) >= parseInt(fromNumberSequence)
            );
          }
          return true;
        },
        hasPrefix: value => (value && !this.usePathologyNumbering ? this.hasPrefix(value) : true),
        inSamePrefixGroup: value =>
          value && !this.usePathologyNumbering ? this.inSamePrefixGroup : true
      },
      fromCaseNumber: {
        required,
        validCase: async value => (value ? this.isFromCaseValid : true),
        hasPrefix: value => (value && !this.usePathologyNumbering ? this.hasPrefix(value) : true)
      }
    };
  },
  computed: {
    ...mapState({
      labelPrintAmount: state => state.sessionDetails.labelPrintAmount,
      requisitionLabels: state => state.requisitionLabels,
      labSettings: state => state.labSettings,
      defaultSpecLabelPrinter: state => state.applicationSettings.defaultSpecLabelPrinter,
      defaultLabelPrinter: state => state.applicationSettings.defaultLabelPrinter,
      defaultReqLabelPrinter: state => state.applicationSettings.defaultReqLabelPrinter,
      SpecimenContainerLabelsPerSpecimen: state =>
        state.labSettings.SpecimenContainerLabelsPerSpecimen,
      RequisitionLabelsPerAccession: state => state.labSettings.RequisitionLabelsPerAccession,
      AccessionNumberingType: state => state.labSettings.AccessionNumberingType,
      LabelPrintingUsesBatching: state => state.labSettings.LabelPrintingUsesBatching,
      caseDetails: state => state.accessionStore.caseDetails,
      prefixes: state => state.dropdowns.prefixes
    }),
    specContainer: {
      get() {
        if (this.useAccessioningNumber) {
          return this.labelPrintAmount;
        }
        return this.specContainerNumber;
      },
      set(value) {
        if (this.useAccessioningNumber) {
          return;
        }
        this.specContainerNumber = value;
        return value;
      }
    },
    validationMsgMap() {
      return {
        ...validatorMsgMapBase,
        validCase: "No matching case was found.",
        greaterThanFrom: "To case must be greater than from."
      };
    },
    usePathologyNumbering() {
      return this.AccessionNumberingType === LabNumberingMethodsEnum.Pathology;
    },
    casesStore() {
      return new DataSource({
        store: api.createSearch(`/api/Cases/search`, "caseId", {}, loadOptions => {
          if (loadOptions.filter?.length) {
            const originalFilter = loadOptions.filter[0];
            loadOptions.filter = [
              "accessionNumber",
              "contains",
              calculateAccessionNumbering(originalFilter[2])
            ];
          }
        }),
        key: "caseId",
        select: ["accessionNumber", "caseId"]
      });
    },
    multipleCases() {
      return this.toCaseId !== this.fromCaseId;
    },
    inSamePrefixGroup() {
      if (this.fromCaseNumber && this.toCaseNumber) {
        const prefixRegex = /^(?<prefix>[a-z]{1,6})[0-9]/i;
        const fromPrefix = prefixRegex.exec(this.fromCaseNumber)?.groups?.prefix?.toUpperCase();
        const toPrefix = prefixRegex.exec(this.toCaseNumber)?.groups?.prefix?.toUpperCase();
        const fromPrefixGroup = this.prefixes.find(e => e.code.toUpperCase() === fromPrefix)?.group;
        const toPrefixGroup = this.prefixes.find(e => e.code.toUpperCase() === toPrefix)?.group;
        return fromPrefixGroup === toPrefixGroup;
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  justify-content: flex-end;
}
.main_form {
  min-height: 200px;
  width: 40vw;
}
</style>
