<template>
  <container class="container">
    <form
      class="p-2"
      @submit.prevent="handleSubmit"
      v-shortkey="saveShortkey"
      @shortkey="handleSubmit"
    >
      <h3>Providers</h3>
      <div class="d-flex">
        <TextInput
          v-focus
          class="col"
          v-model="provider.firstName"
          label="First Name"
          name="firstName"
          :validator="$v.provider.firstName"
          maxLength="51"
          :validatorMsgMap="validatorMsgMap"
        />
        <TextInput
          class="col"
          v-model="provider.middleName"
          label="Middle Name"
          name="middleName"
          maxLength="51"
          :validator="$v.provider.middleName"
          :validatorMsgMap="validatorMsgMap"
        />
        <TextInput
          class="col"
          v-model="provider.lastName"
          label="Last Name"
          name="lastName"
          maxLength="51"
          :validator="$v.provider.lastName"
          :validatorMsgMap="validatorMsgMap"
        />
      </div>
      <div class="d-flex my-2">
        <TextInput
          class="col"
          v-model="provider.emailAddress"
          label="Email Address"
          name="emailAddress"
          :validator="$v.provider.emailAddress"
          :validatorMsgMap="validatorMsgMap"
        />
        <PhoneInput
          class="col"
          v-model="provider.phoneNumber"
          label="Phone Number"
          name="phoneNumber"
        />

        <TextInput
          class="col hide_ard-flexs"
          v-model="provider.npi"
          type="number"
          label="NPI"
          name="npi"
          maxLength="11"
          :validator="$v.provider.npi"
          :validatorMsgMap="validatorMsgMap"
        />
      </div>
      <div class="d-flex my-1">
        <SelectInput
          class="col-4"
          v-model="provider.secondaryOnly"
          :items="boolOptions"
          label="Secondary Only"
          name="secondaryOnly"
          id="secondaryOnly"
        />

        <TextInput
          class="col-4"
          v-model="provider.degrees"
          label="Degrees"
          maxLength="51"
          name="degrees"
          :validator="$v.provider.degrees"
          :validatorMsgMap="validatorMsgMap"
        />
        <PhoneInput
          class="col-4"
          v-model="provider.altPhoneNumber"
          label="Alternate Phone Number"
          name="phoneNumber"
        />
      </div>
      <div class="d-flex my-1">
        <TextInput
          class="col-4"
          v-model="provider.legacyId"
          label="Legacy Provider ID"
          name="legacyId"
        />
        <select-input
          :multiple="false"
          class="col-4"
          name="billingType"
          label="Default Billing Type"
          v-model="selectedBillingType"
          :items="billingTypes"
          :customLabel="option => option.displayName"
          :validator="$v.provider.billingType"
        >
        </select-input>
      </div>
      <div class="d-flex my-1">
        <embedded-location
          class="col-8"
          v-model="selectedLocations"
          :dataSource="locationSearch"
          placeholder="Select Locations..."
          :show-selection-controls="true"
          valueExpr="id"
          :maxDisplayTags="5"
          :showDropDownButton="true"
          :search-enabled="true"
          searchExpr="displayName"
          :validator="$v.provider.locations"
          :providerContacts="providerContacts"
        >
        </embedded-location>
        <SelectInput
          class="col-4"
          label="Primary Location"
          :items="provider.locations"
          v-model="provider.primaryLocationId"
        />
      </div>
      <div class="d-flex my-1">
        <TagInput
          v-if="cytologyModuleEnabled"
          label="Cytology Disallowed Tests"
          class="col"
          v-model="provider.cytDisallowedTests"
          :dataSource="cytologyTests"
          displayExpr="displayText"
          placeholder="Select Cytology tests..."
        />
      </div>
      <div class="d-flex pb-4 mb-3 border-bottom">
        <TextAreaInput
          v-model="provider.comments"
          label="Comments"
          name="comments"
          maxLength="4001"
          class="col"
          rows="5"
          cols="20"
          :resize="false"
          :validator="$v.provider.comments"
          :validatorMsgMap="validatorMsgMap"
        />
      </div>
      <div class="border p-4 mb-4">
        <CustomFields
          class="col"
          v-model="provider.providerCustomFields"
          @input="$emit('input', $event)"
          customFieldType="provider"
        />
      </div>

      <div class="d-flex justify-content-end mx-2">
        <button @click="cancel" type="button" class="mx-2 btn btn-danger">Cancel</button>
        <button type="submit" class="btn btn-primary" :disabled="isSubmitting">Save</button>
      </div>
    </form>
  </container>
</template>

<script>
import PhoneInput from "@/components/common/PhoneInput.vue";
import CustomFields from "@/components/forms/CodeMaintenance/CustomFieldsTable";
import TextAreaInput from "@/components/TextAreaInput";
import { altKey, validatorMsgMapBase } from "@/modules/helpers";
import { maxLength, required, email, minLength, helpers } from "vuelidate/lib/validators";
import shorcutKeys from "@/mixins/shortcutKeys";
import { mapState } from "vuex";
import ReportsService from "../../../../services/Reports";
import { tap } from "rxjs/operators";
import SelectInput from "../../../common/SelectInput.vue";
import { createLogComment, createLogItem } from "../../../../modules/helpers";
import { cloneDeep } from "lodash";
import { AuditLogApi, DropdownApi, ProvidersApi } from "@/services/index";
import TextInput from "@/components/common/TextInput.vue";
import EmbeddedLocation from "@/components/forms/CodeMaintenance/Providers/EmbeddedLocation.vue";
import Container from "@/components/common/Container.vue";
import DataSource from "devextreme/data/data_source";
import CytologyService, { CytologyEntities } from "@/services/cytology";
import TagInput from "@/components/common/TagInput.vue";

export default {
  name: "Provider",
  components: {
    TextAreaInput,
    CustomFields,
    PhoneInput,
    SelectInput,
    Container,
    TextInput,
    EmbeddedLocation,
    TagInput
  },
  props: {
    providerId: {
      default: null
    },
    isModal: {
      default: false
    }
  },
  mixins: [shorcutKeys({ keyCode: 65, modifier: "alt" }, "addCustomField")],
  created() {
    if (this.providerId != null || this.providerId != undefined) {
      this.isEditing = true;
      ProvidersApi.getProviderById(this.providerId).then(response => {
        response.locations = response.locations.sort((a, b) => (a.name - b.name > 1 ? -1 : 1));
        this.provider = {
          ...this.defaultProvider,
          ...response
        };
        this.originalProvider = cloneDeep({
          ...this.defaultProvider,
          ...response
        });

        this.selectedLocations = response.locations.map(e => e.id);
        if (response.report) {
          this.selectedPathReport = response.report;
        }
      });
      ProvidersApi.getContactsByProvider(this.providerId).then(
        res => (this.providerContacts = res)
      );
    }
  },
  data() {
    return {
      isEditing: false,
      isLoading: false,
      originalProvider: {},
      cytologyTests: CytologyService.createSearch(CytologyEntities.CytMolecTests),
      locations: [],
      boolOptions: [
        { id: true, displayName: "Yes" },
        { id: false, displayName: "No" }
      ],
      selectedPathReport: null,
      billingTypes: [
        {
          id: 0,
          displayName: "Patient/Insurance"
        },
        {
          id: 1,
          displayName: "Doctor/Client"
        }
      ],
      //test
      selectedLocations: [],
      defaultProvider: {
        firstName: "",
        lastName: "",
        middleName: "",
        degrees: "",
        npi: "",
        secondaryOnly: false,
        comments: "",
        locations: [],
        providerCustomFields: [],
        billingType: {},
        phoneNumber: "",
        altPhoneNumber: "",
        emailAddress: "",
        pathReportTemplateId: null,
        legacyId: null,
        primaryLocationId: null
      },
      provider: {
        firstName: "",
        lastName: "",
        middleName: "",
        degrees: "",
        npi: "",
        secondaryOnly: false,
        comments: "",
        locations: [],
        providerCustomFields: [],
        billingType: {},
        phoneNumber: "",
        altPhoneNumber: "",
        emailAddress: "",
        pathReportTemplateId: null,
        legacyId: null,
        primaryLocationId: null
      },
      isSubmitting: false,
      providerContacts: [],
      saveShortkey: altKey("s")
    };
  },
  computed: {
    ...mapState(["currentUser", "currentLab", "labSettings"]),
    cytologyModuleEnabled() {
      return this.labSettings.CytologyModuleEnabled;
    },
    validatorMsgMap() {
      return { ...validatorMsgMapBase, unique: "This NPI is already registered." };
    },
    fixedFilters() {
      return ["labId", "=", this.currentLab];
    },
    reportsSearch() {
      return ReportsService.searchStore;
    },
    locationSearch() {
      return new DataSource({
        store: DropdownApi.searchLocations,
        paginate: true,
        pageSize: 10,
        searchOperation: "startswith",
        sort: "displayName"
      });
    },
    selectedBillingType: {
      get() {
        return this.provider.billingType?.id;
      },
      set(value) {
        this.provider.billingType = this.billingTypes.find(e => e.id === value);
        return value;
      }
    }
  },
  subscriptions() {
    const selectLocation$ = this.$watchAsObservable("selectedLocations").pipe(
      tap(({ newValue, oldValue }) => {
        newValue.forEach(id => {
          const location = this.provider.locations.find(prov => prov.id === id);
          if (!location) {
            this.provider.locations = [...this.provider.locations, { id, isNew: true }];
          } else if (location && location.delete) {
            location.delete = false;
          }
        });
        const removedLocation = oldValue.find(id => !newValue.includes(id));
        if (removedLocation) {
          const location = this.provider.locations.find(e => e.id === removedLocation);
          this.removeLocation(location);
        }
      })
    );
    return { selectLocation$ };
  },
  validations() {
    const provider = {
      firstName: {
        required,
        maxLength: maxLength(50)
      },
      lastName: {
        required,
        maxLength: maxLength(50)
      },

      middleName: {
        maxLength: maxLength(50)
      },
      billingType: {
        required
      },
      degrees: {
        maxLength: maxLength(50)
      },
      emailAddress: {
        email
      },
      locations: {
        minLength: minLength(1),
        required
      },
      npi: {
        required: value => (this.labSettings.ProviderNPIOptional ? true : helpers.req(value)),
        maxLength: maxLength(10),
        unique: async value => {
          if (value) {
            const isUnique = await ProvidersApi.searchProviders.totalCount({
              filter: this.providerId
                ? [["npi", value], "and", ["!", ["id", this.providerId]]]
                : ["npi", value]
            });
            return !isUnique;
          }
          return true;
        }
      },

      comments: {
        maxLength: maxLength(4000)
      },
      customFields: {
        $each: {
          field: {
            required,
            maxLength: maxLength(50)
          },
          value: {
            required,
            maxLength: maxLength(4000)
          }
        }
      }
    };
    return { provider };
  },
  watch: {
    selectedLocations: {
      deep: true,
      handler(nv, ov) {
        this.provider.locations = nv.map(e => {
          let existingLocation = this.provider.locations.find(location => location.id === e);
          if (existingLocation) {
            return existingLocation;
          }
          return {
            id: e
          };
        });
        if (nv.length > ov.length && ov.length > 0) {
          this.provider.locations.forEach(location => {
            const oldLocation = ov.find(e => e.id === location.id);
            if (!oldLocation) {
              location.isNew = true;
              location.delete = false;
            }
          });
        }
      }
    },
    selectedPathReport(nv) {
      if (nv) {
        if (nv?.id !== undefined && nv.id !== this.provider.pathReportTemplateId) {
          this.provider.pathReportTemplateId = nv.id;
        }
      }
    }
  },
  methods: {
    fetchLocations(searchValue) {
      let options = {
        sortFields: [
          {
            field: "OfficeName",
            ascending: true
          }
        ],
        searchPaths: ["OfficeName", "Address_1", "City"],
        searchPathsInclusive: true,
        searchValue,
        pageSize: 20,
        paginate: true,
        pageOffset: 0
      };
      return ProvidersApi.searchLocations(options);
    },
    removeLocation(location) {
      if (!location.hasCases) {
        this.provider.locations = this.provider.locations.filter(e => e.id != location.id);
      } else {
        window.notify(
          "This provider is linked to one or more Accessions and can not be removed.",
          "error"
        );
      }
      this.selectedLocations = this.provider.locations.map(e => e.id);
    },
    cancel() {
      this.isEditing = false;
      this.provider = { ...this.defaultProvider };
      if (this.isModal) {
        this.$emit("cancel");
      } else {
        this.$router.push("/admin/contacts");
      }
    },
    async handleSubmit() {
      //IMPLEMENT CALLS FOR INSERT OR UPDATE
      this.$v.$touch();
      if (this.$v.$invalid) {
        window.notify("Please verify your input and try again.", "warning");

        return;
      }
      //This will happen when a user is adding a provider from within the location form
      try {
        this.isSubmitting = true;
        if (this.isEditing) {
          await ProvidersApi.updateProvider(this.provider);
          const logItem = createLogItem({}, 4);
          logItem.comments = `${this.provider.lastName}, ${
            this.provider.firstName
          }:${createLogComment(this.originalProvider, this.provider)}`;
          await AuditLogApi.insertLogMessage(logItem);
        } else if (!this.providerId) {
          await ProvidersApi.addProvider(this.provider, this.currentLab);
          const logItem = createLogItem({}, 5);
          logItem.comments = `Created provider ${this.provider.firstName} ${this.provider.lastName}`;
          await AuditLogApi.insertLogMessage(logItem);
        }
        if (this.isModal) {
          this.$emit("close");
        } else {
          this.$router.push("/admin/contacts");
        }
      } catch (error) {
        if (error.response?.data) {
          window.alert(error.response.data);
        }
      } finally {
        this.isSubmitting = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
/* Chrome, Safari, Edge, Opera */

/* Firefox */
</style>
