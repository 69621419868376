<template>
  <div>
    <label v-if="label"
      ><b>{{ label }}</b></label
    >
    <DxTagBox
      :name="$attrs.name"
      v-model="emails"
      :valid="validator && validator.$dirty && !validator.$invalid"
      :id="$attrs.id || $attrs.name"
      displayExpr="address"
      :placeholder="$attrs.placeholder"
      :accept-custom-value="true"
      :openOnFieldClick="false"
      @keyDown="handleTabKey"
      :showDropDownButton="false"
      @customItemCreating="onCustomItemCreating"
      valueChangeEvent="blur change focusout"
    >
    </DxTagBox>
    <div v-if="(validator && validator.$error) || (validator && validator.$invalid)">
      <div
        class="validation-error"
        v-for="(key, index) in Object.keys(validator.$params)"
        :key="index"
      >
        <span class="error" v-if="!validator[key]">{{ validatorMsgMap[key] }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { validatorMsgMapBase } from "@/modules/helpers";
import DxTagBox from "devextreme-vue/tag-box";
export default {
  components: { DxTagBox },
  inheritAttrs: false,
  props: ["validator", "label", "value"],
  name: "EmailInput",
  computed: {
    validatorMsgMap() {
      return validatorMsgMapBase;
    },
    emails: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
        return value;
      }
    }
  },
  methods: {
    onCustomItemCreating(data) {
      const newValue = data.text;
      if (!data.text) return;
      const instance = { address: newValue, displayName: "" };
      data.customItem = instance;
      this.$emit("input", [...(this.value || []), instance]);
    },
    handleTabKey(e) {
      if (e.event.code === "Tab") {
        const event = new KeyboardEvent("keydown", {
          key: "Enter",
          keyCode: 13,
          which: 13
        });
        const target = e.event.target;
        target.dispatchEvent(event);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
