<template>
  <div class="sec-tags d-flex pt-3 flex-column justify-content-start">
    <fieldset class="tags p-1">
      <checkbox
        :key="i"
        v-for="(tag, i) in quickLinks"
        ref="tags"
        :id="tag.displayName + i"
        :value="selectedIds.includes(tag.id)"
        @input="addTags(tag)"
        :label="tag.displayName"
      />
    </fieldset>
    <div class="d-flex border-bottom px-2">
      <text-area-input
        class="col px-0"
        name="detail"
        label="Notes to Tag/Notes"
        v-model="tagNoteText"
        :resize="false"
        :generalMacrosEnabled="true"
      />
    </div>
    <div class="d-flex justify-content-end my-1">
      <button
        type="button"
        :disabled="$v.$invalid || isSubmitting"
        @click="saveTags"
        class="btn btn-primary"
      >
        Send
      </button>
    </div>
  </div>
</template>

<script>
import Checkbox from "../common/Checkbox.vue";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import TextAreaInput from "../TextAreaInput.vue";

export default {
  components: { Checkbox, TextAreaInput },
  name: "TagQuickLinks",
  data() {
    return {
      selectedTags: [],
      tagNoteText: "",
      isSubmitting: false
    };
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser,
      quickLinks: state =>
        state.sessionDetails.quickLinks.filter(t => t.linkType === 3 || t.linkType === 4)
    }),
    selectedIds() {
      return this.selectedTags.map(e => e.id);
    }
  },
  validations() {
    return {
      selectedTags: {
        required
      }
    };
  },
  methods: {
    addTags(tag) {
      if (this.selectedIds.includes(tag.id)) {
        return (this.selectedTags = this.selectedTags.filter(e => e.id !== tag.id));
      }
      return (this.selectedTags = [...this.selectedTags, tag]);
    },
    async saveTags() {
      if (this.selectedTags !== null && this.selectedTags.length > 0) {
        this.isSubmitting = true;
        const { caseId } = this.$route.params;
        const caseNoteTags = this.selectedTags.map(noteTag => {
          return {
            tagId: noteTag.id,
            caseId,
            text: this.tagNoteText,
            type: (noteTag.linkType === 3 ? "T" : null) || (noteTag.linkType === 4 ? "N" : null),
            typeCode: {
              id: noteTag.id
            }
          };
        }, []);
        try {
          await this.$store.dispatch("accessionStore/upsertCaseQuickLink", caseNoteTags);
          this.selectedTags = [];
          this.tagNoteText = "";
        } catch (error) {
          if (error?.response?.data) {
            window.notify(error?.response?.data, "error");
          } else {
            window.notify("Error occurred with your submission.", "error");
          }
        } finally {
          this.isSubmitting = false;
        }
      }
    }
  }
};
</script>

<style>
.sec-tags .tags {
  height: 21vh;
  overflow-y: auto;
}
</style>
