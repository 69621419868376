<template>
  <!-- SIDEBAR -->
  <div class="sidebar-section">
    <button
      v-shortkey="shortkeyAlt(index)"
      @shortkey="handleTabClick(tab)"
      v-for="(tab, index) of groups"
      :key="tab + index"
      class="btn w-100 sidebar-section__item"
      :class="{ active: group && tab.id === group.id }"
      :id="tab.displayName + 'tab'"
      @click="handleTabClick(tab)"
    >
      <icon class="sidebar-section__icon" icon="file-alt" />
      <div class="d-flex justify-content-center align-items-center text-capitalize">
        {{ tab.displayName }}
      </div>
    </button>
  </div>
</template>

<script>
import DropdownService from "../../services/dropdown";
import { mapState, mapGetters } from "vuex";
import Icon from "../common/Icon.vue";
import { altKey } from "@/modules/helpers";
export default {
  name: "ReportsSidebar",
  components: {
    Icon
  },
  created() {
    DropdownService.getReportGroups().then(groups => {
      if (groups.length) {
        this.groups = [this.group, ...groups] || [];
      }
    });
  },
  data() {
    return {
      groups: [],
      group: { id: null, displayName: "All" }
    };
  },

  computed: {
    ...mapState(["currentUser", "currentLab"]),
    ...mapGetters(["permissions"])
  },
  methods: {
    handleTabClick(tab) {
      const { id } = tab;
      if (this.group?.id === id) {
        this.group = null;
        this.$emit("CHANGE_GROUP", null);
      } else {
        this.group = tab;
        this.$emit("CHANGE_GROUP", id);
      }
    },
    shortkeyAlt(index) {
      return altKey(index + 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.active {
  opacity: 0.65;
}

.sidebar-section {
  width: 100px;
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
  & > button {
    position: relative;
    & > div {
      font-weight: 500;
      font-size: 0.85rem;
    }
    &.active {
      opacity: 0.5;
    }
    transition: opacity 150ms;
    background: $primary-light;
    border-radius: 3px;
    padding: 5px 0px;
    margin-bottom: 2.5px;
    text-align: center;
    font-size: 12px;
    & > svg {
      color: $primary;
      font-size: 24px;
    }
  }
}
.count_icon {
  position: absolute;
  top: -8px;
  right: -1px;
  padding: 2px 5px;
  box-sizing: border-box;
  border-radius: 50%;
  background: $primary-light;
  font-weight: 600;
  font-size: 0.75rem;
  border: 1px solid $secondary;
  &.history {
    animation: pulse-red 1s infinite;
  }
}
</style>
