<template>
  <div class="d-flex">
    <div class="search-filter col pl-0">
      <icon-button
        :icon="isSearchOpen ? 'caret-up' : 'caret-down'"
        iconClass="mx-1 search-filter__icon"
      >
        <b> Case Search Filters </b>
      </icon-button>
      <height-transition>
        <div class="searchHeader" v-show="isSearchOpen">
          <div class="row row-wrap pl-2">
            <div class="col-xl col-lg-3">
              <tag-input
                label="Prefix Tags"
                :searchEnabled="true"
                placeholder="Select Prefix tags"
                :dataSource="prefixTags"
                v-model="casePrefixTags"
                :disabled="isOnSearchScreen"
              />
            </div>
            <div class="col-xl col-lg-3">
              <tag-input
                label="Orders"
                placeholder="Select Order"
                :searchEnabled="true"
                :dataSource="procedureDataSource"
                display-expr="description"
                v-model="procedures"
                :disabled="isOnOrdersScreen || isOnSearchScreen"
              />
            </div>
            <div class="col-xl col-lg-3">
              <tag-input
                label="HoldCodes"
                placeholder="Select HoldCodes"
                :dataSource="holdCodesDataSource"
                :searchEnabled="true"
                :searchExpr="SearchByHoldCode ? 'holdCode' : 'displayName'"
                :display-expr="holdCodeDisplayExpr"
                v-model="holdCodes"
                :multiple="true"
                :disabled="isOnOrdersScreen || isOnSearchScreen"
              />
            </div>
            <div class="col-xl col-lg-3">
              <tag-input
                label="Pathologists"
                placeholder="Select Pathologists"
                :dataSource="pathologistsDataSource"
                display-expr="displayName"
                :searchEnabled="true"
                v-model="pathologists"
                :multiple="true"
                :disabled="isOnSearchScreen"
              />
            </div>
            <div class="col-xl col-lg-3">
              <tag-input
                label="Tags"
                placeholder="Select Tags"
                :dataSource="tagDataSource"
                display-expr="displayName"
                :searchEnabled="true"
                v-model="tags"
                :multiple="true"
              />
            </div>
          </div>
        </div>
      </height-transition>
    </div>

    <div class="col-lg-3 col-5 ml-2 rounded alerts-section rounded">
      <div class="alerts-section__title">
        <icon icon="exclamation-triangle" class="mr-2" />
        Alerts
      </div>
      <div class="alerts-section__body">
        <div
          @click="handleFilterSignoutQueue"
          class="d-flex justify-content-between alert-row pointer"
          :class="{ active: alertFilter === 1 }"
        >
          <div>Sign Out Queue</div>
          <div>
            <b>{{ alerts.signOutQty }}</b>
          </div>
        </div>
        <div
          @click="handleFilterBacklog"
          class="d-flex justify-content-between alert-row pointer"
          :class="{ active: alertFilter === 2 }"
        >
          <div>Backlog</div>
          <div>
            <b>{{ alerts.backlogQty }}</b>
          </div>
        </div>
        <div
          @click="handleFilterUnsigned"
          class="d-flex justify-content-between alert-row pointer border-bottom-0"
          :class="{ active: alertFilter === 3 }"
        >
          <div>Unsigned over {{ alerts.oldUnsignedMinAge }} days</div>
          <div>
            <b>{{ alerts.oldUnsignedQty }}</b>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { filter, exhaustMap, tap } from "rxjs/operators";
import { mapState } from "vuex";
import casesService from "../../services/cases";
import HeightTransition from "../common/HeightTransition.vue";
import DataSource from "devextreme/data/data_source";
import { merge } from "rxjs";
import { DropdownApi } from "@/services/index";
import { dateRangeFilter } from "@/modules/helpers";
import IconButton from "../common/IconButton.vue";
import Icon from "../common/Icon.vue";
import TagInput from "../common/TagInput.vue";
import { DashboardModes } from "@/modules/enums";
import eventBus, { SIGNED_CASE_FROM_PDF, SIGN_CASE_ON_GRID } from "@/modules/eventBus";

export default {
  components: { HeightTransition, IconButton, Icon, TagInput },
  name: "Home-Header",
  data() {
    return {
      isSearchOpen: true,
      alerts: {
        backlogQty: 0,
        oldUnsignedMinAge: 5,
        oldUnsignedQty: 0,
        signOutQty: 0
      },
      lastRefresh: 0
    };
  },
  subscriptions() {
    return {
      alertsRefresh$: merge(
        this.$watchAsObservable("currentLab", { immediate: true }).pipe(
          filter(({ newValue }) => newValue),
          filter(() => {
            const difference = this.getTimeSinceLastRefresh();
            this.lastRefresh = new Date().getTime();
            return difference > 5000;
          })
        )
      ).pipe(
        exhaustMap(() => casesService.getAlerts(this.currentLab)),
        tap(alerts => {
          this.devlog("Alerts refreshed on lab change.");
          this.refreshAlerts(alerts);
        })
      )
    };
  },
  mounted() {
    if (!this.isPathologistSearchFilter) {
      this.$store.commit("applicationSettings/setCaseSearchHeader", {
        pathologists: [],
        holdCodes: [],
        casePrefixTags: [],
        procedures: [],
        tags: [],
        alertFilter: null
      });
    }
    eventBus.$on(SIGN_CASE_ON_GRID, () => this.getAndRefreshAlerts());
    eventBus.$on(SIGNED_CASE_FROM_PDF, () => this.getAndRefreshAlerts());
  },
  beforeDestroy() {
    eventBus.$off(SIGN_CASE_ON_GRID);
    eventBus.$off(SIGNED_CASE_FROM_PDF);
  },
  activated() {
    if (this.getTimeSinceLastRefresh() > 5000) {
      this.lastRefresh = new Date().getTime();
      this.devlog("Alerts refreshed on activated.");
      this.getAndRefreshAlerts();
    }
  },
  created() {
    this.$store.dispatch("dropdowns/getPrefixTags");
  },
  watch: {
    homeGridMode(nv, ov) {
      if (
        this.getTimeSinceLastRefresh() > 5000 &&
        nv !== ov &&
        [DashboardModes.Cases, DashboardModes.Orders, DashboardModes.Tasks].includes(nv)
      ) {
        this.devlog("Alerts refreshed on home grid mode change.");
        this.getAndRefreshAlerts();
      }
    },
    accessionFilter(nv, ov) {
      if (
        this.getTimeSinceLastRefresh() > 5000 &&
        this.homeGridMode === DashboardModes.Cases &&
        nv !== ov
      ) {
        this.devlog("Alerts refreshed on accession filter change.");
        this.getAndRefreshAlerts();
      }
    }
  },
  methods: {
    handleFilterSignoutQueue() {
      if (this.alertFilter === 1) {
        this.alertFilter = null;
      } else {
        this.alertFilter = 1;
      }
    },
    handleFilterBacklog() {
      if (this.alertFilter === 2) {
        this.alertFilter = null;
      } else {
        this.alertFilter = 2;
      }
    },
    handleFilterUnsigned() {
      if (this.alertFilter === 3) {
        this.alertFilter = null;
      } else {
        this.alertFilter = 3;
      }
    },
    getAndRefreshAlerts() {
      this.lastRefresh = new Date().getTime();
      this.devlog("Called API to refresh alerts.", new Date().getTime());
      casesService.getAlerts(this.currentLab).then(res => {
        this.refreshAlerts(res);
      });
    },
    refreshAlerts(alerts) {
      this.alerts = { ...alerts };
      this.devlog("Alerts refreshed.", new Date().getTime());
      this.lastRefresh = new Date().getTime();
    },
    getTimeSinceLastRefresh() {
      const now = new Date().getTime();
      const difference = now - this.lastRefresh;
      return difference;
    },
    holdCodeDisplayExpr(data) {
      if (this.SearchByHoldCode && data?.holdCode) {
        return `${data.holdCode}  -  ${data.displayName}`;
      }
      return data?.displayName;
    }
  },
  computed: {
    ...mapState({
      currentLab: state => state.currentLab,
      currentUser: state => state.currentUser,
      casesSearchHeader: state => state.applicationSettings.casesSearchHeader,
      ApplyPathologistSearchFilter: state => state.labSettings.ApplyPathologistSearchFilter,
      homeGridMode: state => state.applicationSettings.homeGridMode,
      accessionFilter: state => state.applicationSettings.accessionFilter,
      prefixTags: state => state.dropdowns.prefixTags,
      SearchByHoldCode: state => state.labSettings.SearchByHoldCode
    }),
    isOnOrdersScreen() {
      return this.homeGridMode === DashboardModes.Orders;
    },
    alertFilter: {
      get() {
        return this.casesSearchHeader.alertFilter;
      },
      set(value) {
        this.$store.commit("applicationSettings/setCaseSearchHeader", {
          ...this.casesSearchHeader,
          alertFilter: value
        });
        this.$store.commit("applicationSettings/setAccessionFilter", "open");

        return value;
      }
    },
    procedures: {
      get() {
        if (Array.isArray(this.casesSearchHeader.procedures)) {
          return this.casesSearchHeader.procedures;
        }
        return [];
      },
      set(value) {
        this.$store.commit("applicationSettings/setCaseSearchHeader", {
          ...this.casesSearchHeader,
          procedures: value
        });
        return value;
      }
    },
    pathologists: {
      get() {
        if (Array.isArray(this.casesSearchHeader.pathologists)) {
          return this.casesSearchHeader.pathologists;
        }
        return [];
      },
      set(value) {
        this.$store.commit("applicationSettings/setCaseSearchHeader", {
          ...this.casesSearchHeader,
          pathologists: value
        });

        return value;
      }
    },
    holdCodes: {
      get() {
        if (Array.isArray(this.casesSearchHeader.holdCodes)) {
          return this.casesSearchHeader.holdCodes;
        }
        return [];
      },
      set(value) {
        this.$store.commit("applicationSettings/setCaseSearchHeader", {
          ...this.casesSearchHeader,
          holdCodes: value
        });

        return value;
      }
    },
    casePrefixTags: {
      get() {
        if (Array.isArray(this.casesSearchHeader.casePrefixTags)) {
          return this.casesSearchHeader.casePrefixTags;
        }
        return [];
      },
      set(value) {
        this.$store.commit("applicationSettings/setCaseSearchHeader", {
          ...this.casesSearchHeader,
          casePrefixTags: value
        });

        return value;
      }
    },
    tags: {
      get() {
        if (Array.isArray(this.casesSearchHeader.tags)) {
          return this.casesSearchHeader.tags;
        }
        return [];
      },
      set(value) {
        this.$store.commit("applicationSettings/setCaseSearchHeader", {
          ...this.casesSearchHeader,
          tags: value
        });

        return value;
      }
    },
    isPathologistSearchFilter() {
      return this.ApplyPathologistSearchFilter ?? 0;
    },
    pathologistsDataSource() {
      return DropdownApi.searchPathologists;
    },
    holdCodesDataSource() {
      return new DataSource({
        store: DropdownApi.searchHoldCodes,
        sort: this.SearchByHoldCode ? ["holdCode", "displayName"] : ["displayName"]
      });
    },
    procedureDataSource() {
      return new DataSource({
        store: DropdownApi.searchProcedures,
        filter: dateRangeFilter("effectiveOn", "expiresOn"),
        sort: ["description"]
      });
    },
    prefixGroupDataSource() {
      return DropdownApi.searchPrefixGroups;
    },
    tagDataSource() {
      return new DataSource({
        store: DropdownApi.searchTags,
        filter: ["settingType", "T"],
        sort: ["displayName"]
      });
    },
    isOnSearchScreen() {
      return this.homeGridMode === DashboardModes.Search;
    }
  }
};
</script>

<style lang="scss" scoped>
.search-filter {
  background: $primary-light;
  border-radius: 3px;
  color: $text-primary;
  padding: 5px 10px 5px 10px;
  /* height: 100%; */
  &__icon {
    color: $primary;
  }
}

.btn-search {
  line-height: 1;
  background: $primary;
  border-color: $primary;
}

.alerts-section {
  background: $primary-dark;
  color: $white;
  padding: 10px 20px 10px 20px;
  &__title {
    font-size: 1.25rem;
  }
  &__body {
    font-size: 1rem;
    margin-top: 5px;
    .alert-row {
      border-bottom: 1px solid #4e8ba3;
      padding: 5px;
    }
  }
  .active {
    font-weight: 700;
    font-size: 1.2rem;
  }
}
.searchHeader {
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
