import api from "./api.js";
class ImagesAPI {
  constructor() {
    this.url = "/api/images";
  }
  get headers() {
    const token = sessionStorage.getItem("token");
    return {
      Authorization: `Bearer ${encodeURIComponent(token)}`
    };
  }
  addImage(data) {
    return api.post(this.url, data, {
      ...this.headers,
      "Content-Type": "application/x-www-form-urlencoded"
    });
  }
  addImageType(data) {
    return api.post(this.url + "/types", data);
  }
  updateImage(data) {
    return api.put(this.url, data, {
      ...this.headers,
      "Content-Type": "application/x-www-form-urlencoded"
    });
  }
  getImages(id) {
    return api.get(this.url + `/${id}`);
  }
  getImageContent(id) {
    return api.getFile(this.url + `/${id}/Content`);
  }
  getImageTypes() {
    return api.get(this.url + "/types");
  }
  deleteImage(id) {
    return api.delete(this.url + `/${id}`);
  }
  uploadScans(data) {
    return api.post(this.url + "/UploadScan", data, {
      ...this.headers,
      "Content-Type": "application/x-www-form-urlencoded"
    });
  }
  deleteImages(payload) {
    return api.post(this.url + "/MultiDelete", { imageIds: payload });
  }
}
export default new ImagesAPI();
