<template>
  <form
    v-if="!isLoading"
    @submit.prevent="handleSubmit(selectedRows)"
    class="d-flex flex-column bg-white container"
    v-shortkey="saveShortkey"
    @shortkey="handleSubmit(selectedRows)"
  >
    <dx-grid-with-search
      class="grid mb-2"
      :dataSource="hl7Orders"
      title="HL7 Orders"
      :columns="columns"
      v-stream:content-ready="contentReady$"
      :selection="selection"
      :scrolling="scrolling"
      :rowAlternationEnabled="true"
      v-stream:selection-changed="selectionChange$"
      @content-ready="initialize"
      @row-click="clickRow"
      :exportConfig="excelExport"
      :toolbar="toolbar"
      @exporting="handleExporting"
      :searchPanel="searchPanel"
    >
      <template v-slot:actions="{ data: { data: order } }">
        <div class="d-flex justify-content-center">
          <icon-button
            type="button"
            v-tooltip.right="order.isActive ? 'Deactivate order' : 'Activate Oder'"
            :class="order.isActive ? 'text-success' : 'text-danger'"
            class="pointer mx-1 p-0"
            :icon="order.isActive ? 'check' : 'ban'"
            @click.stop="handleActivate(order)"
          />
        </div>
      </template>
    </dx-grid-with-search>
    <div class="d-flex mt-auto justify-content-end align-self-end">
      <button type="button" @click="closeModal" class="btn btn-danger">Cancel</button>
      <button data-testid="loadOrder" type="submit" class="btn btn-primary mx-2">Submit</button>
    </div>
  </form>
  <div class="min-height" v-else>
    <DxLoadPanel :visible="true" message="Applying order..." />
  </div>
</template>

<script>
import { mapState } from "vuex";
import DxGridWithSearch from "./common/DxGridWithSearch.vue";
import { fromEvent, merge } from "rxjs";
import { filter, map, switchMap, tap, take } from "rxjs/operators";
import eventBus, { LOAD_ORDERS, CLOSE_ORDERS } from "../modules/eventBus";
import { AuditLogApi, Hl7OrdersApi, InsuranceApi, ProvidersApi } from "../services";
import DxLoadPanel from "devextreme-vue/load-panel";
import { altKey, createLogItem, filterCellUTC, formatDatetimeCell } from "@/modules/helpers";
import IconButton from "./common/IconButton.vue";
import { format } from "date-fns";
export default {
  name: "Hl7OrderPopup",
  components: { DxGridWithSearch, DxLoadPanel, IconButton },
  props: {
    searchQuery: {
      required: false
    },
    inCase: {
      default: false
    }
  },
  data: () => ({
    isLoading: false,
    searchText: "",
    excelExport: {
      enabled: true
    },
    grid: {},
    scrolling: {
      showScrollbar: "always",
      useNative: false,
      mode: "standard"
    },
    toolbar: {
      items: [
        {
          name: "exportButton"
        }
      ]
    },
    selection: {
      mode: "single",
      showCheckBoxesMode: "always"
    },
    selectedRows: [],
    hl7Orders: Hl7OrdersApi.searchStore,
    contact: ProvidersApi.searchContacts,
    saveShortkey: altKey("s"),
    searchPanel: {
      width: 300,
      visible: true,
      placeholder: "Search by Order Number"
    }
  }),
  mounted() {
    if (this.searchQuery) {
      this.searchText = this.searchQuery;
    }
  },
  domStreams: ["selectionChange$", "contentReady$"],
  subscriptions() {
    return {
      firstContentReady$: this.contentReady$.pipe(
        take(1),
        tap(({ event }) => {
          const { element, component } = event.msg;
          const searchPanel = element.querySelector('input[type="text"]');
          searchPanel.focus();
          setTimeout(() => {
            component.selectRowsByIndexes([0]);
          }, 100);
        })
      ),
      selectRow$: this.contentReady$.pipe(
        tap(({ event }) => {
          const { component } = event.msg;
          setTimeout(() => {
            component.selectRowsByIndexes([0]);
          }, 100);
        })
      ),
      startUp$: this.contentReady$.pipe(
        filter(({ event }) => {
          const { element } = event.msg;
          const searchPanel = element.querySelector('input[type="text"]');
          if (searchPanel) {
            return true;
          }
          return false;
        }),
        map(({ event }) => {
          const { element, component } = event.msg;
          const searchPanel = element.querySelector('input[type="text"]');
          return [component, searchPanel];
        }),
        switchMap(([grid]) => {
          if (this.searchText.length) {
            this.searchText = "";
            grid.searchByText(this.searchQuery);
          }
          function selectNextRow(change) {
            const totalCount = grid.totalCount();
            const currentSelection = grid.getSelectedRowKeys();
            const currentSelectionIndex = grid.getRowIndexByKey(currentSelection[0]);
            if (
              currentSelectionIndex + change > -1 &&
              currentSelectionIndex + change < totalCount
            ) {
              const rowElements = grid.getRowElement(currentSelectionIndex + change);
              if (rowElements?.length) {
                rowElements.forEach(element => {
                  element.scrollIntoView(false);
                });
                const newRowKey = grid.getKeyByRowIndex(currentSelectionIndex + change);
                return grid.selectRows([newRowKey]);
              }
            }
            return;
          }
          function selectNextPage(change) {
            const totalCount = grid.pageCount();
            const currentPage = grid.pageIndex();
            if (currentPage + change > -1 && currentPage + change < totalCount) {
              return grid.pageIndex(currentPage + change);
            }
            return;
          }
          const keyDown$ = fromEvent(document.body, "keydown");
          const arrowUp$ = keyDown$.pipe(
            filter(e => e.keyCode === 38),
            map(() => -1),
            tap(selectNextRow)
          );
          const arrowDown$ = keyDown$.pipe(
            filter(e => e.keyCode === 40),
            map(() => 1),
            tap(selectNextRow)
          );
          const arrowRight$ = keyDown$.pipe(
            filter(e => e.keyCode === 39),
            map(() => 1),
            tap(selectNextPage)
          );
          const arrowLeft$ = keyDown$.pipe(
            filter(e => e.keyCode === 37),
            map(() => -1),
            tap(selectNextPage)
          );
          const enterKey$ = keyDown$.pipe(
            filter(e => e.keyCode === 13),
            tap(() => this.handleSubmit(this.selectedRows))
          );

          const selectionChanged$ = this.selectionChange$.pipe(
            tap(data => {
              const { selectedRowsData } = data.event.msg;
              this.selectedRows = selectedRowsData;
            })
          );
          return merge(arrowDown$, arrowUp$, arrowRight$, arrowLeft$, enterKey$, selectionChanged$);
        })
      )
    };
  },
  computed: {
    ...mapState(["macro", "currentLab"]),
    columns() {
      return [
        {
          dataField: "orderNum",
          caption: "Order #",
          sortOrder: "asc",
          sortIndex: 1,
          dataType: "string"
        },
        {
          dataField: "sexId",
          caption: "Sex",
          dataType: "string",
          columnIndex: 2,
          allowSearch: false
        },
        {
          dataField: "lastName",
          caption: "Patient Name",
          dataType: "string",
          calculateCellValue(data) {
            return `${data.lastName}, ${data.firstName}`;
          },
          calculateFilterExpression(filterValue, selectedOperation) {
            if (selectedOperation) {
              return [
                ["firstName", selectedOperation, filterValue],
                "or",
                ["lastName", selectedOperation, filterValue]
              ];
            }
            return [
              ["firstName", "contains", filterValue],
              "or",
              ["lastName", "contains", filterValue]
            ];
          },
          allowSearch: false
        },
        {
          dataField: "providerName",
          caption: "Provider",
          dataType: "string",
          allowHeaderFiltering: true,
          allowSearch: false
        },
        {
          dataField: "locationName",
          caption: "Location",
          dataType: "string",
          allowHeaderFiltering: true,
          allowSearch: false
        },
        {
          dataField: "bornOn",
          caption: "DOB",
          dataType: "date",
          allowSearch: false
        },
        {
          dataField: "accountNum",
          caption: "Acct #",
          dataType: "string",
          allowSearch: false
        },
        {
          dataField: "specimenQty",
          caption: "Specimens",
          dataType: "number",
          allowFiltering: false,
          allowSearch: false
        },
        {
          dataField: "medicalRecordNum",
          caption: "MRN",
          dataType: "string",
          allowSearch: false
        },
        {
          dataField: "collectedOn",
          dataType: "date",
          caption: "Collected",
          allowSearch: false
        },
        {
          dataField: "createdOn",
          dataType: "datetime",
          caption: "Created",
          calculateCellValue(data) {
            if (data.createdOn) {
              return formatDatetimeCell(data.createdOn);
            }
            return "";
          },

          calculateFilterExpression: filterCellUTC("createdOn"),
          allowSearch: false
        },
        {
          dataField: "isActive",
          dataType: "boolean",
          caption: "Actions",
          cellTemplate: "actions",
          filterValue: true,
          lookup: {
            displayExpr: "text",
            valueExpr: "value",
            dataSource: [
              {
                value: true,
                text: "Active"
              },
              {
                value: false,
                text: "Inactive"
              }
            ]
          },
          allowSearch: false
        }
      ];
    }
  },
  methods: {
    initialize({ component }) {
      this.grid = component;
    },
    handleExporting(data) {
      const logItem = createLogItem({}, 8, "HL7 Orders");
      logItem.comments = "Exported the hl7 orders to excel.";
      AuditLogApi.insertLogMessage(logItem);
      const today = format(new Date(), "MM/dd/yyyy");
      data.fileName = `HL7 Orders ${today}`;
      return data;
    },
    async handleSubmit(selectedRows) {
      if (!selectedRows.length) {
        window.notify("Please select an order to apply.", "warning");
        this.isLoading = false;
        return;
      }
      const target = selectedRows.reduce((acc, curr) => curr);
      if (!target.isActive) {
        window.notify("Cannot load inactive order.", "error");
        this.isLoading = false;
        return;
      }
      try {
        this.isLoading = true;
        const orderDetails = await Hl7OrdersApi.getOrderById(target.id);
        if (target?.contactId) {
          const providerProfile = await ProvidersApi.searchContacts.byKey(target.contactId);
          if (providerProfile) {
            orderDetails.provider = providerProfile;
          }
        }

        orderDetails.insurances = await Promise.all(
          (orderDetails.hL7Insurance || [])
            .map(async (insurance, index) => {
              const {
                address1,
                firstName,
                lastName,
                address2,
                city,
                zipCode,
                stateId,
                sexId,
                bornOn,
                code,
                groupNum,
                policyNum,
                relationshipId
              } = insurance;
              const insuranceProfile = await InsuranceApi.searchStore.load({
                filter: ["code", "=", code]
              });
              if (
                insuranceProfile.length &&
                address1 &&
                sexId != null &&
                zipCode &&
                city &&
                bornOn &&
                firstName
              ) {
                return {
                  dateOfBirth: bornOn,
                  addressLine1: address1,
                  addressLine2: address2,
                  sex: sexId,
                  city,
                  state: stateId,
                  insuranceType: index + 1,
                  groupNumber: groupNum,
                  insuranceRelationship: relationshipId,
                  policyNumber: policyNum,
                  zipCode,
                  insuranceId: insuranceProfile.find(e => e.code === code)?.id,
                  guarantor: `${lastName + ", " ?? ""}${firstName}`
                };
              }
              return null;
            })
            .filter(e => e)
        );
        eventBus.$emit(LOAD_ORDERS, orderDetails);
        if (this.inCase) {
          this.$emit("close");
        }
      } catch (error) {
        console.log("Error occured loading order.", error);
        window.notify("An error occured loading your order.", "error");
      } finally {
        this.isLoading = false;
      }
    },
    clickRow(event) {
      const order = event.data;
      if (order.isActive) {
        this.handleSubmit([order]);
      }
    },
    handleActivate(order) {
      const { id, isActive } = order;
      if (this.selectedRows.length) {
        this.selectedRows.forEach(element => {
          if (parseInt(element.id) === parseInt(id)) {
            element.isActive = !isActive;
          }
        });
      }
      return Hl7OrdersApi.activateOrder(id, !isActive).then(() => {
        this.grid.refresh(true);
      });
    },
    selectRow({ selectedRowsData }) {
      this.selectedRows = selectedRowsData;
    },
    closeModal() {
      if (this.inCase) {
        this.$emit("close");
      }
      return eventBus.$emit(CLOSE_ORDERS);
    }
  },
  directives: {
    focusItem: {
      inserted(el) {
        return el.focus();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.value {
  /* padding: 5px; */
  /* border: 1px solid #e2e2e2; */
  margin: 5px 2px;
}
.summary {
  overflow: auto;
}
.target_title {
  color: $primary-dark;
}
.min-height {
  min-height: 250px;
}
::v-deep
  .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)
  > td:not(.dx-focused) {
  cursor: pointer;
}
</style>
