var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-1"},[_c('DxGridWithSearch',{ref:"taskGrid",attrs:{"title":"Tasks","gridName":"labTasks","dataSource":_vm.dataSource,"noDataText":"No tasks available","columns":_vm.columns,"selection":_vm.selection,"toolbar":_vm.toolbar,"onCellPrepared":_vm.redactLogRocket},on:{"initialized":_vm.initGrid},scopedSlots:_vm._u([{key:"caseTemplate",fn:function(ref){
var data = ref.data.data;
return [(data.caseNumber)?_c('router-link',{attrs:{"to":("/accession/" + (data.caseId))}},[_vm._v(_vm._s(data.caseNumber))]):_vm._e()]}},{key:"extraActions",fn:function(){return [_c('div',{staticClass:"d-flex"},[(_vm.permissions.TaskCreate)?_c('AddBtn',{attrs:{"text":"Add"},on:{"click":_vm.createTask}}):_vm._e()],1)]},proxy:true},{key:"actions",fn:function(ref){
var data = ref.data.data;
return [_c('div',{staticClass:"d-flex"},[(_vm.permissions.TaskChangeStatus && data.assignedTo === _vm.currentUser.id)?_c('icon-button',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:(data.isCompleted ? 'Complete.' : 'Incomplete.'),expression:"data.isCompleted ? 'Complete.' : 'Incomplete.'",modifiers:{"right":true}}],staticClass:"mr-2 p-0",class:[data.isCompleted ? 'text-success' : 'text-danger', 'pointer', ''],attrs:{"icon":data.isCompleted ? 'check' : 'times'},on:{"click":function($event){$event.stopPropagation();return _vm.markComplete(data)}}}):_vm._e(),(
            _vm.permissions.TaskEdit &&
            (data.assignedTo === _vm.currentUser.id ||
              data.createdBy === _vm.currentUser.id ||
              _vm.permissions.TaskEditAllUsers)
          )?_c('icon-button',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:('Edit task.'),expression:"'Edit task.'",modifiers:{"right":true}}],staticClass:"text-primary pointer mr-2 p-0",attrs:{"icon":"pen-alt"},on:{"click":function($event){$event.stopPropagation();return _vm.editTask(data)}}}):_vm._e(),(_vm.permissions.TaskEdit && data.assignedTo !== _vm.currentUser.id)?_c('icon-button',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:('Assign to me'),expression:"'Assign to me'",modifiers:{"right":true}}],staticClass:"text-primary pointer mr-2 p-0",attrs:{"icon":"user"},on:{"click":function($event){$event.stopPropagation();return _vm.assignToCurrentUser(data)}}}):_vm._e(),(
            _vm.permissions.TaskDelete &&
            (data.assignedTo === _vm.currentUser.id || data.createdBy === _vm.currentUser.id)
          )?_c('icon-button',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:('Delete task.'),expression:"'Delete task.'",modifiers:{"right":true}}],staticClass:"text-danger pointer p-0",attrs:{"icon":"trash-alt"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteTask(data.taskId)}}}):_vm._e()],1)]}},{key:"dateCell",fn:function(ref){
          var data = ref.data;
return [_c('div',{staticClass:"align-items-center"},[_c('icon',{staticClass:"filled_circle",class:_vm.dueDateCell(data),attrs:{"icon":"circle"}}),_c('b',[_vm._v(_vm._s(data.text))])],1)]}}])}),_c('modal',{attrs:{"status":_vm.isFormOpen},on:{"close":function($event){_vm.isFormOpen = false}}},[_c('TaskForm',{ref:"taskForm",attrs:{"caseId":_vm.caseId,"taskId":_vm.currentTask},on:{"submit":_vm.handleSubmit,"cancel":_vm.clearTask}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }