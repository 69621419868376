import Quill from "devextreme-quill";
import { fontSizes, fontWhiteList } from "../enums";

const Italics = Quill.import("formats/italic");
const Block = Quill.import("blots/block");
const Inline = Quill.import("blots/inline");
Block.tagName = "div"; //Quill uses <p> by default
Italics.tagName = "I";

const fonts = Quill.import("attributors/style/font");
const fontSize = Quill.import("attributors/style/size");
fontSize.whitelist = fontSizes;
fonts.whitelist = fontWhiteList;
class CustomBold extends Inline {
  static create(value) {
    const node = super.create(value);
    node.style.fontWeight = "bold";
    return node;
  }
  static formats(node) {
    return node.style.fontWeight;
  }
}
CustomBold.tagName = "span"; // Quill uses <strong> by default
CustomBold.blotName = "bold";

Quill.register(fonts, true);
Quill.register(fontSize, true);

Quill.register(
  {
    "formats/bold": CustomBold,
    "formats/italic": Italics,
    "blots/block": Block
  },
  true
);

export function HTMLConverter(htmlString) {
  const tempCont = document.createElement("div");
  const editorInstance = new Quill(tempCont);
  const inputDelta = editorInstance.clipboard.convert({ html: htmlString });
  const lastOp = inputDelta.ops[inputDelta.ops.length - 1];
  if (lastOp?.insert && lastOp?.insert === "\n") {
    inputDelta.ops = [...inputDelta.ops.slice(0, inputDelta.ops.length - 1)];
  }
  editorInstance.setContents(inputDelta);
  const editorContainer = tempCont.getElementsByClassName("ql-editor")[0];

  return editorContainer.innerHTML;
}
export function Delta2HTML(inputDelta) {
  const tempCont = document.createElement("div");
  const editorInstance = new Quill(tempCont);
  editorInstance.setContents(inputDelta);
  const editorContainer = tempCont.getElementsByClassName("ql-editor")[0];
  return editorContainer.innerHTML;
}

export function HTML2Delta(htmlString) {
  // Keeps multiple spaces. These were being reduced to 1 space by Quill
  if (
    htmlString &&
    /(?:(?<!\s|(&nbsp;)))(?<spaces>(\s| |(&nbsp;)){2,})(?:(?!\s|(&nbsp;)))(?:(?<=\s|(&nbsp;)))/.test(
      htmlString
    )
  ) {
    const matches = [
      ...htmlString.matchAll(
        /(?:(?<!\s|(&nbsp;)))(?<spaces>(\s| |(&nbsp;)){2,})(?:(?!\s|(&nbsp;)))(?:(?<=\s|(&nbsp;)))/gi
      )
    ].map(e => e?.groups?.spaces);
    for (const match of matches) {
      const matchLength = match.replaceAll("&nbsp;", " ").length;
      htmlString = htmlString.replace(match, "&nbsp;".repeat(matchLength));
    }
  }
  const tempCont = document.createElement("div");
  const editorInstance = new Quill(tempCont);
  const inputDelta = editorInstance.clipboard.convert({ html: htmlString });
  const lastOp = inputDelta.ops[inputDelta.ops.length - 1];
  if (lastOp?.insert && lastOp?.insert === "\n") {
    inputDelta.ops = [...inputDelta.ops.slice(0, inputDelta.ops.length - 1)];
  }
  return inputDelta;
}
