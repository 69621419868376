export async function openCameraDevice(deviceId) {
  const constrains = {
    audio: false,
    video: { width: { ideal: 1280 }, height: { ideal: 720 } }
  };
  if (deviceId) {
    constrains.video = { deviceId };
  }
  const mediaStream = await navigator.mediaDevices.getUserMedia(constrains);
  const devices = await navigator.mediaDevices.enumerateDevices();
  const track = mediaStream.getVideoTracks().find(e => e.enabled);
  deviceId = devices.find(e => e.label === track.label)?.deviceId;
  return { mediaStream, deviceId };
}
