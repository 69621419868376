<template>
  <FontAwesomeIcon :icon="icon" />
</template>

<script>
import FontAwesomeIcon from "@/icons";

export default {
  name: "Icon",
  components: {
    FontAwesomeIcon
  },
  props: ["icon"]
};
</script>

<style lang="scss" scoped></style>
