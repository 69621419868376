<template>
  <div class="sec-holds d-flex pt-3 flex-column justify-content-start">
    <div class="d-flex col-md-12 text-primary">
      <div class="py-2" v-if="caseHoldCodes.length === 1">
        <icon-button
          @click="removeHold(caseHoldCodes[0])"
          type="button"
          data-testid="removeHoldIcon"
          iconClass="text-danger"
          v-tooltip="'Remove hold'"
          class="btn text-red pointer btn-hold-delete"
          icon="trash-alt"
        >
          {{ firstHoldName }}
        </icon-button>
      </div>
    </div>
    <fieldset class="holds border-top p-2">
      <checkbox
        :key="i"
        v-for="(hold, i) in userHoldCodes"
        ref="tags"
        :id="hold.displayName"
        :value="selectedIds.includes(hold.id)"
        @input="addHolds(hold)"
        :label="holdCodeLabel(hold)"
      />
    </fieldset>
    <div class="d-flex px-2">
      <text-area-input
        class="col px-0"
        name="detail"
        label="Notes to Tag/Notes"
        v-model="holdText"
        :resize="false"
        :generalMacrosEnabled="true"
      />
    </div>
    <div class="d-flex justify-content-end my-1">
      <button type="button" :disabled="$v.$invalid" @click="saveHolds" class="btn btn-primary">
        Send
      </button>
    </div>
  </div>
</template>

<script>
import Checkbox from "../common/Checkbox.vue";
import TextAreaInput from "../TextAreaInput.vue";
import { mapState } from "vuex";
import IconButton from "../common/IconButton.vue";
import { DropdownApi } from "@/services";
import { handleErrors } from "@/modules/handleErrors";

export default {
  components: { Checkbox, TextAreaInput, IconButton },
  data() {
    return {
      selectedHolds: [],
      quickLinkHoldPopup: false,
      holdText: "",
      firstHoldName: ""
    };
  },
  props: ["caseStatus"],
  validations() {
    return {
      selectedHolds: {
        required: () => {
          return this.isDisabled === true ? false : true;
        }
      }
    };
  },
  mounted() {
    if (this.caseHoldCodes?.length === 1 && !this.firstHoldName) {
      const { tagId } = this.caseHoldCodes[0];
      DropdownApi.searchHoldCodes.byKey(tagId).then(holdCode => {
        this.firstHoldName =
          this.SearchByHoldCode && holdCode?.holdCode
            ? `${holdCode.holdCode} - ${holdCode.displayName}`
            : holdCode.displayName;
      });
    }
  },
  watch: {
    caseHoldCodes: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue?.length === 1 && oldValue !== 1) {
          const { tagId } = newValue[0];
          DropdownApi.searchHoldCodes.byKey(tagId).then(holdCode => {
            this.firstHoldName =
              this.SearchByHoldCode && holdCode?.holdCode
                ? `${holdCode.holdCode} - ${holdCode.displayName}`
                : holdCode.displayName;
          });
        }
      }
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser,
      caseHoldCodes: state => state.accessionStore.caseHolds,
      caseDetails: state => state.accessionStore.caseDetails,
      userHoldCodes: state => state.sessionDetails.quickLinks.filter(t => t.linkType === 5),
      confirmRemoveHold: state => state.applicationSettings.confirmRemoveHold,
      SearchByHoldCode: state => state.labSettings.SearchByHoldCode
    }),
    isDisabled() {
      return [7, 11, 12, 13, 14].includes(this.caseDetails.status);
    },
    selectedIds() {
      return this.selectedHolds.map(e => e.id);
    }
  },
  methods: {
    addHolds(hold) {
      if (this.selectedIds.includes(hold.id)) {
        return (this.selectedHolds = this.selectedHolds.filter(e => e.id !== hold.id));
      }
      return (this.selectedHolds = [...this.selectedHolds, hold]);
    },
    handleCloseQLHoldPopup() {
      this.quickLinkHoldPopup = false;
    },
    checkCaseStatus(status) {
      const statusArr = [7, 11, 12, 13, 14];
      this.isDisabled = statusArr.includes(status);
    },
    async removeHold(item) {
      const confirm = this.confirmRemoveHold
        ? await window.confirm(`Are you sure you want to delete this hold?`)
        : true;
      const { caseId } = this.$route.params;
      if (confirm) {
        try {
          await this.$store.dispatch("accessionStore/removeQuickLink", {
            type: "H",
            caseId,
            ids: [item.id]
          });
          await this.$store.dispatch("accessionStore/getCaseHeader", caseId);
          if (this.caseHoldCodes?.length === 1) {
            this.firstHoldName = "";
          }
        } catch (error) {
          handleErrors(error);
        }
      }
    },
    async saveHolds() {
      if (this.selectedHolds !== null && this.selectedHolds.length > 0) {
        const { caseId } = this.$route.params;
        const payload = this.selectedHolds.map(code => ({
          tagId: code.id,
          specimenId: null,
          caseId,
          text: this.holdText,
          type: "H"
        }));
        try {
          await this.$store.dispatch("accessionStore/upsertCaseQuickLink", payload);
          await this.$store.dispatch("accessionStore/getCaseHeader", caseId);
          window.notify("Success.");
          this.selectedHolds = [];
          this.holdText = "";
        } catch (error) {
          handleErrors(error);
        }
      }
    },
    holdCodeLabel(hold) {
      // if (this.SearchByHoldCode) {
      //   return `${hold?.holdCode} - ${hold.displayName}`;
      // }
      return hold.displayName;
    }
  }
};
</script>

<style>
.sec-holds .holds {
  max-height: 20vh;
  overflow-y: auto;
}
.text-red {
  color: #da1a32;
}
.btn-hold-delete {
  margin-top: -10px;
}
</style>
