import api from "./api.js";
class Users {
  constructor() {
    this.url = "/api/Users";
  }
  get searchStore() {
    return api.createSearch(this.url + "/search");
  }
  get labUsersStore() {
    return api.createSearch(this.url);
  }
  createSearch(labId) {
    return api.createSearch(this.url + "/search", "id", { labId });
  }
  getAllUsers() {
    return api.get(this.url);
  }
  getLabUsers(labId) {
    return api.get(this.url + `/lab/${labId}`);
  }
  getUserById(userId) {
    return api.get(this.url + `/${userId}`);
  }
  getUserSearches() {
    return api.get(this.url + "/searches");
  }
  deleteUserSearch(searchId) {
    return api.delete(this.url + `/searches/${searchId}`);
  }
  createPin() {
    return api.post(this.url + "/MfaPin");
  }
  getTaskAssignees() {
    return api.get(this.url + "/TaskAssignees");
  }
  verifyMFAPin(pin) {
    return api.post(this.url + "/VerifyMfaPin", { pin });
  }
  /**
   *
   * @param {Object} data
   * @param {string} data.oldPassword - String of verifying the user's old password.string
   * @param {string} data.newPassword - String for the new user password
   * @returns Api call
   */
  changePassword(data) {
    return api.put(this.url + "/Password", data);
  }
  resetPassword(data) {
    return api.post(this.url + "/password/reset", data, {
      Authorization: data.token
    });
  }
  createUser(data) {
    return api.post(this.url, data);
  }
  saveUser(data) {
    return api.put(this.url, data);
  }
  removeUserLabAssignment(data) {
    return api.delete(this.url + "/Lab", data);
  }
  forgotPassword(data) {
    return api.post(this.url + "/password/forgot", data);
  }
  changeUserSettings(data = {}) {
    return api.put(this.url + "/settings", { settings: JSON.stringify(data) });
  }
  /**
   *
   * @param {Object} data
   * @param {Number} data.id
   * @param {String} data.name
   * @param {String} data.userId
   * @param {String} data.state
   * @returns
   */
  createSavedSearch(data) {
    return api.post(this.url + `/searches`, data);
  }
  updateSavedSearch(data) {
    return api.put(this.url + `/searches`, data);
  }
  sendWelcomeEmails(data) {
    return api.post(this.url + "/WelcomeEmail", { userGuids: data });
  }
  getUserPermissions() {
    return api.get(this.url + "/PermissionsForCurrentUser");
  }
  validateLogin(id) {
    return api.put(`${this.url}/${id}/AdminValidateMfaPin`);
  }
  getMfaCookie(id) {
    return api.get(`${this.url}/${id}/MfaCookie`);
  }
}
export default new Users();
