<template>
  <div>
    <div class="d-flex justify-content-between my-2">
      <h3>Printers</h3>
    </div>
    <div>
      <DxDataGrid
        :columns="columns"
        :data-source="dataSource"
        @initialized="initialize"
        :showColumnLines="true"
        :showRowLines="true"
        :searchPanel="{ visible: true }"
        :cacheEnabled="false"
      >
        <template v-slot:actions="{ data }">
          <icon-button
            class="pointer"
            :icon="data.data.isIgnored ? 'times' : 'check'"
            :class="data.data.isIgnored ? 'text-danger' : 'text-success'"
            v-tooltip.left="
              data.data.isIgnored ? 'Click to enable printer.' : 'Click to ignore printer.'
            "
            @click="ignorePrinter(data)"
          />
        </template>
      </DxDataGrid>
    </div>
    <form v-if="isEditing" @submit.prevent.stop="onSubmit" class="col my-4 aling-items-center">
      <div class="row">
        <div class="col-2 my-auto">
          <Checkbox label="Is Ignored" id="final" v-model="printer.isIgnored" />
        </div>

        <TextInput
          name="printerName"
          class="col"
          label="Name"
          maxLength="255"
          v-model="printer.name"
        />
        <TextInput
          name="hostName"
          class="col"
          maxLength="101"
          label="Host Name"
          v-model="printer.hostName"
        />
      </div>
      <div class="row justify-content-end">
        <button @click="cancelEdit" type="button" class="my-2 mr-1 btn btn-danger">Cancel</button>
        <button type="submit" class="my-2 btn btn-primary">
          {{ isEditing ? "Save" : "Submit" }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { required, maxLength, email } from "vuelidate/lib/validators";
import { DxDataGrid } from "devextreme-vue";
import { PrintersApi } from "../../../services";
import DataSource from "devextreme/data/data_source";
import { mapState } from "vuex";
import IconButton from "@/components/common/IconButton.vue";
import TextInput from "@/components/common/TextInput.vue";
import Checkbox from "@/components/common/Checkbox.vue";

export default {
  name: "Printers",
  props: {
    value: {
      required: true
    },
    printerSource: {
      required: true,
      default: () => []
    }
  },
  components: {
    DxDataGrid,
    IconButton,
    TextInput,
    Checkbox
  },
  computed: {
    ...mapState(["currentLab"])
  },
  mounted() {
    if (this.printerSource) {
      this.dataSource = new DataSource({
        store: this.printerSource,
        paginate: true,
        pageSize: 10,
        sort: true
      });
    }
  },
  data() {
    return {
      grid: {},
      dataSource: [],
      columns: [
        {
          dataField: "name",
          dataType: "string"
        },
        {
          dataField: "hostName",
          dataType: "string"
        },
        {
          dataField: "isIgnored",
          dataType: "boolean",
          cellTemplate: "actions",
          sortOrder: 0,
          allowSearch: false
        }
      ],
      isEditing: false,
      printer: {
        name: "",
        hostName: "",
        isIgnored: false
      }
    };
  },
  validations: {
    email: {
      address: {
        required,
        maxLength: maxLength(254),
        email
      },
      recipient: {
        required,
        maxLength: maxLength(100)
      }
    }
  },
  methods: {
    initialize({ component }) {
      this.grid = component;
    },
    edit({ data }) {
      this.printer = { ...data };
      this.isEditing = true;
    },
    cancelEdit() {
      if (this.isEditing) {
        this.isEditing = false;
      }
    },
    async ignorePrinter({ data }) {
      await PrintersApi.updatePrinter([
        {
          ...data,
          isIgnored: !data.isIgnored
        }
      ]);
      this.grid.refresh(true);
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-cell {
  width: 10%;
}
table td {
  text-align: center;
  padding: 0;
}
.text-input {
  height: 90px;
}
</style>
