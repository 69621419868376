<template>
  <div class="card">
    <div class="card-header p-1" id="headingOne">
      <h5 class="mb-0 d-flex justify-content-between align-items-center">
        <button
          @click="toggle"
          type="button"
          :id="id + 'button'"
          class="btn btn-link text-dark font-weight-bold text-left w-100"
          aria-expanded="false"
          aria-controls="collapseOne"
        >
          <div v-html="title" :id="id + 'title'" class="truncate text-capitalize text-start"></div>
        </button>
        <slot name="after_title"></slot>
      </h5>
    </div>
    <div
      ref="target"
      :id="id"
      class="collapse"
      :class="{ show: isOpen }"
      aria-labelledby="headingOne"
      :data-parent="'#' + accordionId"
    >
      <div class="card-body p-2">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Accordion",
  props: ["id", "title", "accordionId", "isExpanded"],
  data() {
    return { state: false };
  },

  computed: {
    isOpen: {
      get() {
        if (this.isExpanded !== undefined) {
          return this.isExpanded;
        }
        return this.state;
      },
      set(value) {
        if (this.isExpanded !== undefined) {
          return this.isExpanded;
        }
        return (this.state = value);
      }
    }
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        this.$emit("toggle", !this.isOpen);
        if (!this.isOpen) {
          this.$emit("opened", this.isOpen);
        }
      });
      if (this.isExpanded !== undefined) return this.$emit("click");
      this.isOpen = !this.isOpen;
    }
  }
};
</script>
<style>
.card {
  position: static !important;
}
</style>
