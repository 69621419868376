<template>
  <div :key="currentLab" class="wrapper">
    <full-search
      v-if="isClientUser && mode === DashboardModes.search"
      class="header search-header"
    />
    <DashboardHeader class="header" v-else-if="!isClientUser && !isMobileView" />
    <div class="inner-wrapper">
      <template>
        <WebLinkSidebar v-if="isClientUser" />
        <HomeSidebar v-else />
      </template>
      <container>
        <!-- Display for mobile browsers -->
        <template v-if="isMobileView">
          <CasesListClient
            v-if="mode === DashboardModes.Cases && permissions.CaseView && isClientUser"
          />
          <case-free-text-search-list v-else-if="mode === DashboardModes.Search" />
          <reports v-else-if="mode === DashboardModes.Reports" />
          <template v-else-if="mode === DashboardModes.Cases">
            <AllCasesGrid id="acessionGridWrapper" v-if="accessionFilter === CasesModeEnum.All" />
            <OpenCasesGrid id="acessionGridWrapper" v-else />
          </template>
          <orders-grid v-if="mode === DashboardModes.Orders && permissions.OrdersView" />
          <TaskManagement
            id="taskMgmt"
            v-if="mode === DashboardModes.Tasks && permissions.TaskView"
          />
        </template>
        <!-- Display for desktop browsers -->
        <template v-else>
          <div v-if="mode === DashboardModes.Cases && permissions.CaseView">
            <CasesGridClient class="w-100" v-if="isClientUser" />
            <template v-else-if="mode === DashboardModes.Cases">
              <AllCasesGrid id="acessionGridWrapper" v-if="accessionFilter === CasesModeEnum.All" />
              <OpenCasesGrid id="acessionGridWrapper" v-else />
            </template>
          </div>
          <template v-if="mode === DashboardModes.Search">
            <div v-if="!isMobileView">
              <search-screen v-if="!isClientUser" />
              <case-free-text-search-grid v-else />
            </div>
          </template>
          <orders-grid v-if="mode === DashboardModes.Orders && permissions.OrdersView" />
          <TaskManagement
            id="taskMgmt"
            v-if="mode === DashboardModes.Tasks && permissions.TaskView"
          />
          <reports v-else-if="mode === DashboardModes.Reports" />
        </template>
      </container>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import TaskManagement from "@/components/TaskManagement";
import OpenCasesGrid from "@/components/OpenCasesGrid";
import AllCasesGrid from "@/components/AllCasesGrid";
import CasesListClient from "@/components/CasesListClient";
import CasesGridClient from "@/components/CasesGridClient";
import OrdersGrid from "../components/OrdersGrid.vue";
import DashboardHeader from "@/components/Headers/DashboardHeader";
import HomeSidebar from "@/components/Sidebars/Home";
import Container from "@/components/common/Container.vue";
import WebLinkSidebar from "@/components/Sidebars/WebLinkSidebar.vue";
import {
  CasesModeEnum,
  DashboardModes,
  DefaultDashboardModeEnum,
  UserTypesEnum
} from "@/modules/enums";
import Reports from "./Reports.vue";
import FullSearch from "@/components/Headers/FullSearch.vue";
import CaseFreeTextSearchGrid from "@/components/CaseFreeTextSearchGrid.vue";
import CaseFreeTextSearchList from "@/components/CaseFreeTextSearchList.vue";
import SearchScreen from "@/components/SearchScreen.vue";
export default {
  name: "Dashboard",
  components: {
    OpenCasesGrid,
    TaskManagement,
    DashboardHeader,
    HomeSidebar,
    CasesGridClient,
    OrdersGrid,
    Container,
    WebLinkSidebar,
    CasesListClient,
    AllCasesGrid,
    Reports,
    FullSearch,
    CaseFreeTextSearchGrid,
    CaseFreeTextSearchList,
    SearchScreen
  },
  metaInfo: {
    title: "Dashboard",
    titleTemplate: "IntelliPath - %s"
  },
  props: ["keepGrid"],
  data() {
    return {
      DashboardModes,
      CasesModeEnum
    };
  },
  created() {
    switch (this.defaultDashboardMode) {
      case DefaultDashboardModeEnum.AllCases:
        this.$store.commit("applicationSettings/setAccessionFilter", "all");
        this.$store.commit("applicationSettings/setGridMode", this.DashboardModes.Cases);
        break;
      case DefaultDashboardModeEnum.Tasks: {
        this.$store.commit("applicationSettings/setGridMode", this.DashboardModes.Tasks);
        break;
      }
      case DefaultDashboardModeEnum.Orders: {
        this.$store.commit("applicationSettings/setGridMode", this.DashboardModes.Orders);
        break;
      }
      case DefaultDashboardModeEnum.Search: {
        this.$store.commit("applicationSettings/setGridMode", this.DashboardModes.Search);
        break;
      }
      default:
        this.$store.commit("applicationSettings/setAccessionFilter", "open");
        this.$store.commit("applicationSettings/setGridMode", this.DashboardModes.Cases);
    }
  },
  mounted() {
    if (!this.keepGrid && this.currentUser.userTypeId === UserTypesEnum.LabClientUser) {
      this.$store.commit("applicationSettings/setGridMode", this.defaultDashboardMode || 0);
    }
    this.checkPermissions();
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser,
      caseStatuses: state => state.caseStatuses,
      homeGridMode: state => state.applicationSettings.homeGridMode,
      currentLab: state => state.currentLab,
      token: state => state.token,
      isMobileView: state => state.isMobileView,
      accessionFilter: state => state.applicationSettings.accessionFilter,
      defaultDashboardMode: state => state.applicationSettings.defaultDashboardMode
    }),
    ...mapGetters(["permissions", "isClientUser"]),
    mode() {
      return parseInt(this.homeGridMode) || 0;
    }
  },
  watch: {
    token(nv) {
      if (nv === null) {
        this.$destroy();
      }
    }
  },
  methods: {
    handleModeChange(mode) {
      this.mode = mode;
      this.homeGridMode = mode;
    },
    handleAccessionNav() {
      this.$router.push({ name: "Accession" });
    },
    checkPermissions() {
      if (this.currentLab && !this.currentUser?.distinctPermissions?.length) {
        this.$store.dispatch("getUserPermissionsForLab");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
svg {
  cursor: pointer;
}
.header {
  flex-shrink: 0;
  display: none;
}
@media screen and (min-width: 1024px) {
  .header {
    display: block;
  }
}
.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
}
.inner-wrapper {
  display: flex;
  overflow: hidden;
  /* flex-grow: 1; */
  margin: 0.25rem;
  @media screen and (max-width: 1023px) {
    display: block;
    overflow: auto;
  }
}
</style>
