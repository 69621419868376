import axios from "axios";

class ZipCodes {
  constructor() {
    this.url = `https://www.zipcodeapi.com/rest/${process.env.VUE_APP_ZIP_CODE_API_KEY}/info.json/`;
  }
  async getZipInfo(zipCode) {
    const { data } = await axios.get(this.url + `${zipCode}/radians`, { withCredentials: false });
    return data;
  }
}
export default new ZipCodes();
