<template>
  <div>
    <button class="btn btn-success" @click="launchSlideImages">Slides</button>
    <SlideImagesPopup
      title="Title Goes Here"
      :specimenId="slideModalState.specId"
      :popupVisible.sync="isSlideImagesOpen"
      :data="slideModalState.data"
    />
  </div>
</template>

<script>
import launchImage from "@/modules/launchImage";
import { mapState } from "vuex";
import SlideImagesPopup from "./SlideImagesPopup.vue";

export default {
  components: { SlideImagesPopup },
  data() {
    return {
      isSlideImagesOpen: false,
      slideModalState: {
        data: [],
        specId: null
      }
    };
  },
  methods: {
    launchSlideImages() {
      const unviewedSpecimens = this.specimensWithSlideImages.filter(
        e => !this.viewedSlideImages.includes(e.specimenId)
      );
      if (unviewedSpecimens.length) {
        const imageUrls = this.generateImageUrls(unviewedSpecimens);
        this.$store.commit("sessionDetails/setViewedSlideImages", [
          ...this.viewedSlideImages,
          ...unviewedSpecimens.map(e => e.specimenId)
        ]);
        if (imageUrls.length > 1) {
          this.isSlideImagesOpen = true;
          this.slideModalState = {
            data: imageUrls,
            specId: unviewedSpecimens.length > 1 ? null : unviewedSpecimens[0].specimenId
          };
        } else if (imageUrls.length) {
          this.launchImage(imageUrls[0]);
        }
      }
    },
    generateImageUrls(slides) {
      let imageUrls = [];
      for (const slide of slides) {
        imageUrls = [
          ...imageUrls,
          ...(slide.slideImageUrls || []).map(e => ({ type: "Slide", url: e }))
        ];
        if (slide.specimenSlideImageUrl) {
          imageUrls.push({ type: "Specimen", url: slide.specimenSlideImageUrl });
        }
      }
      return imageUrls;
    },
    launchImage(data) {
      launchImage(data.url, this.SlideImagesApplicationHandler);
    }
  },
  computed: {
    ...mapState({
      caseDetails: state => state.accessionStore.caseDetails,
      slideImages: state => state.accessionStore.slideImages,
      viewedSlideImages: state => state.sessionDetails.viewedSlideImages,
      SlideImagesApplicationHandler: state => state.labSettings.SlideImagesApplicationHandler
    }),
    specimensWithSlideImages() {
      return this.slideImages.filter(
        e => e.slideImageUrls?.length || e.specimenSlideImageUrl?.length
      );
    }
  }
};
</script>

<style lang="scss" scoped></style>
