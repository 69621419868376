<template>
  <DxPopup
    :visible="isVisible"
    :drag-enabled="false"
    :show-title="true"
    @shown="handleShow"
    :width="400"
    :height="350"
    :showCloseButton="false"
    container=".dx-viewport"
    :title="title"
  >
    <DxPosition my="center" />
    <template v-slot:content>
      <form @submit.prevent="handleConfirm" class="d-flex flex-column">
        <DxScrollView width="100%" height="100%"> <p v-html="config.displayText" /> </DxScrollView>
        <text-input
          ref="initials"
          label="Initials"
          placeholder="Initials"
          class="mt-2"
          v-model="initials"
          name="initials"
          v-if="config.requireInitials"
        />
        <div class="d-flex ml-auto">
          <button type="submit" ref="confirmBtn" class="btn btn-outline-success">Ok</button>
          <button
            type="button"
            @click="handleCancel"
            ref="cancelBtn"
            v-if="config.hasCancelButton"
            class="btn btn-outline-danger ml-2"
          >
            Cancel
          </button>
        </div>
      </form>
    </template>
  </DxPopup>
</template>

<script>
import { DxPopup, DxPosition } from "devextreme-vue/popup";
import { DxScrollView } from "devextreme-vue/scroll-view";

import TextInput from "./common/TextInput.vue";
import { AuditLogApi, CasesApi } from "@/services";
import { createLogItem } from "@/modules/helpers";
import { mapState } from "vuex";
import eventBus, { POPUP_ACTION, POPUP_HIDE, POPUP_SHOW } from "@/modules/eventBus";
import { PopupTypesEnum } from "@/modules/enums";
import { handleErrors } from "@/modules/handleErrors";

export default {
  components: {
    DxPopup,
    DxPosition,
    DxScrollView,
    TextInput
  },
  name: "PhysicianPopup",
  mounted() {
    eventBus.$on(POPUP_SHOW, config => {
      if (config.typeId === PopupTypesEnum.Image) {
        return this.handleShowImagePopup(config);
      }
      this.config = config;
      this.isVisible = true;
      window.addEventListener("keydown", this.handleKeydown);
    });
    eventBus.$on(POPUP_HIDE, () => {
      this.config = {};
      this.isVisible = false;
      this.initials = "";
      window.removeEventListener("keydown", this.handleKeydown);
    });
  },
  beforeDestroy() {
    eventBus.$off(POPUP_SHOW);
    eventBus.$off(POPUP_HIDE);
  },
  data() {
    return {
      config: {},
      isVisible: false,
      initials: ""
    };
  },
  computed: {
    ...mapState({
      caseDetails: state => state.accessionStore.caseDetails
    }),
    title() {
      return this.config?.name;
    }
  },
  methods: {
    async handleShowImagePopup(popupConfig) {
      try {
        if (this.caseDetails?.caseId) {
          const imageTypeIds = popupConfig?.imageTypeIds ?? [];
          const images = await CasesApi.getCaseImages(this.caseDetails.caseId);
          const targetImages = images.filter(e => imageTypeIds.includes(e.imageTypeId));
          if (targetImages?.length) {
            this.$store.dispatch(
              "sessionDetails/useImageFrame",
              targetImages.map(e => e.id)
            );
          }
        }
      } catch (e) {
        handleErrors(e);
      } finally {
        eventBus.$emit(POPUP_ACTION, true);
      }
    },
    handleShow() {
      if (this.config?.requireInitials) {
        this.$refs.initials.focus();
      } else if (this.config?.hasCancelButton) {
        this.$refs.cancelBtn.focus();
      } else {
        this.$refs.confirmBtn.focus();
      }
    },
    handleConfirm() {
      if (this.config.requireInitials) {
        if (!this.initials) {
          window.notify("Please enter your initials!", "warning");
          return;
        } else {
          /** ENTER CALL TO AUDIT LOG HERE. */
          const logItem = createLogItem(this.caseDetails, 16);
          logItem.comments = `Confirmed popup ${this.config.name}(${
            this.config.id
          }) \n${JSON.stringify({ initials: this.initials }, null, 2)}`;
          AuditLogApi.insertLogMessage(logItem);
        }
      }
      eventBus.$emit(POPUP_ACTION, true);
    },
    handleCancel() {
      if (this.config.requireInitials) {
        /** ENTER CALL TO AUDIT LOG HERE. */
        const logItem = createLogItem(this.caseDetails, 16);
        logItem.comments = `Canceled popup ${this.config.name}(${this.config.id})`;
        AuditLogApi.insertLogMessage(logItem);
      }
      eventBus.$emit(POPUP_ACTION, false);
    },
    handleKeydown(event) {
      const { innerText } = document.activeElement;
      // Adds logic to always use Enter to close popup
      if (event?.keyCode === 13) {
        event.preventDefault();
        if (innerText === "Cancel") {
          this.handleCancel();
        } else {
          this.handleConfirm();
        }
      } else if (
        this.config.hasCancelButton &&
        [37, 39].includes(event?.keyCode) &&
        ["Ok", "Cancel"].includes(innerText)
      ) {
        this.$refs[innerText === "Ok" ? "cancelBtn" : "confirmBtn"].focus();
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
