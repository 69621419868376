var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-1"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('h2',{staticClass:"text-capitalize mr-2"},[_vm._v(_vm._s(_vm.pageTitle))]),_c('div',{staticClass:"d-flex align-items-center"},[_c('button',{staticClass:"ml-2 btn btn-outline-primary",on:{"click":_vm.handleClearGridFilters}},[_vm._v(" Clear Filters ")])])]),_c('DxDataGrid',{key:_vm.currentLab,attrs:{"columnChooser":_vm.columnChooser,"data-source":_vm.accessionDataStore,"noDataText":"Please apply filters to search cases.","show-borders":true,"show-row-lines":true,"column-auto-width":true,"scrolling":_vm.scrolling,"allow-column-reordering":true,"remoteOperations":_vm.remoteOperations,"allow-column-resizing":true,"columnResizingMode":"widget","onCellPrepared":_vm.redactLogRocket,"toolbar":_vm.toolbar,"pager":_vm.pager,"paging":_vm.paging,"hoverStateEnabled":true,"sorting":_vm.sorting,"headerFilter":_vm.headerFilter,"filterRow":_vm.filterRow,"selection":_vm.selection,"stateStoring":_vm.stateStoring,"searchPanel":_vm.searchPanel,"cacheEnabled":false},on:{"cell-click":_vm.handleCaseClick,"content-ready":_vm.onContentReady,"option-changed":_vm.onOptionChanged,"initialized":_vm.onInitialized},scopedSlots:_vm._u([{key:"actionsTemplate",fn:function(ref){
var ref_data = ref.data;
var data = ref_data.data;
var key = ref_data.key;
var rowIndex = ref_data.rowIndex;
return [_c('div',{staticClass:"justify-content-start d-flex align-items-center",attrs:{"data-testid":'actions-col-' + rowIndex}},[(_vm.permissions.CaseView)?_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:('View case details.'),expression:"'View case details.'",modifiers:{"right":true}}],staticClass:"btn-link btn p-0",attrs:{"to":_vm.getModeRoute(key) || '/'}},[_c('icon',{staticClass:"text-primary",attrs:{"icon":"eye"}})],1):_vm._e(),(_vm.permissions.PathReportView)?_c('icon-button',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:('View path report.'),expression:"'View path report.'",modifiers:{"right":true}}],staticClass:"btn text-primary pointer p-0 px-1 mr-1",attrs:{"icon":"window-restore"},on:{"click":function($event){return _vm.handleViewPathReport(key)}}}):_vm._e(),(_vm.isCaseReadyForSignout(data))?_c('icon-button',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:(_vm.signButton(data).message),expression:"signButton(data).message",modifiers:{"right":true}}],staticClass:"btn text-primary pointer p-0 px-1 mr-1",attrs:{"icon":_vm.signButton(data).icon,"disabled":_vm.isLoading},on:{"click":function($event){return _vm.handleSignCase(data)}}}):_vm._e()],1)]}},{key:"booleanValue",fn:function(ref){
var data = ref.data;
return [(data.value)?_c('icon',{staticClass:"text-success",attrs:{"icon":"check"}}):_vm._e()]}}])},_vm._l((_vm.columns),function(column,i){return _c('DxColumn',_vm._b({key:i},'DxColumn',column,false))}),1),_c('modal',{attrs:{"status":_vm.isDistributionOpen},on:{"close":function($event){_vm.isDistributionOpen = false}}},[_c('distribution-popup',{attrs:{"method":_vm.distributionMethod,"caseIds":_vm.selectedCasesForDistribution},on:{"close":function($event){_vm.isDistributionOpen = false},"submitted":_vm.clearSelection}})],1),_c('modal',{attrs:{"status":_vm.isLocationPopupOpen},on:{"close":function($event){_vm.isLocationPopupOpen = false}}},[_c('lab-location-popup',{attrs:{"isLoading":_vm.isLoading,"payloadToSign":_vm.payloadToSign},on:{"cancel":function($event){_vm.isLocationPopupOpen = false},"sign":_vm.handleSelectLabLocation}})],1),_c('modal',{attrs:{"status":_vm.isCollectedPopupOpen},on:{"close":function($event){_vm.isCollectedPopupOpen = false}}},[_c('collected-popup',{attrs:{"caseId":_vm.collectedPopupCaseId},on:{"submit":_vm.handleSetCollected,"cancel":function($event){_vm.isCollectedPopupOpen = false}}})],1),(_vm.isInline && _vm.isActivated)?_c('PathReportViewer',{staticClass:"mt-2",on:{"close":_vm.closeReport}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }