<template>
  <div class="container-fluid bg-white p-1">
    <div class="row justify-content-between align-items-center">
      <FullSearch v-if="isLabClientUser" :gridState="gridState" />
    </div>
    <DxDataGrid
      :data-source="dataSource"
      :show-borders="true"
      @cell-click="handleCaseClick"
      :word-wrap-enabled="true"
      :hoverStateEnabled="true"
      :columns="columns"
      :remoteOperations="remoteOperations"
      @initialized="initializeGrid"
      @content-ready="contentReady"
      :onCellPrepared="redactLogRocket"
      :allowColumnReordering="true"
      :allowColumnResizing="true"
      columnResizingMode="widget"
      :row-alternation-enabled="true"
      :grouping="grouping"
      :toolbar="toolbar"
      no-data-text="No cases available."
      :columnChooser="columnChooser"
      :stateStoring="stateStoring"
      @data-error-occurred="handleDataError"
      :sorting="sorting"
      :cacheEnabled="false"
    >
      <DxSelection v-bind="selection" />
      <template v-slot:gridTitle>
        <div class="d-flex justify-content-start">
          <page-title>Search</page-title
          ><button class="btn btn-primary ml-3" v-if="showBackButton" @click="handleBackButton">
            Back
          </button>
        </div>
      </template>
      <template v-slot:actions="{ data: { data, key } }">
        <div class="text-primary text-center d-flex flex-wrap justify-content-center">
          <icon-button
            v-if="permissions.PathReportView && !isLabClientUser"
            v-tooltip.right="'View path report.'"
            class="btn text-primary pointer p-0 mr-1"
            @click="handleViewPathReport(key)"
            icon="window-restore"
          />
          <icon-button
            v-if="permissions.CaseView"
            v-tooltip.right="'View case details.'"
            @click="handleNavigateToCase(key)"
            class="btn text-primary pointer p-0 mr-1"
            icon="eye"
          />
          <icon-button
            v-if="isCaseReadyForSignout(data)"
            @click="handleSignCase(data)"
            v-tooltip.right="signButton(data).message"
            :icon="signButton(data).icon"
            class="btn text-primary pointer p-0 mr-1"
            :disabled="isLoading"
          />
          <icon-button
            icon="envelope"
            iconClass="text-primary"
            @click.stop="createRequest(key)"
            v-if="isLabClientUser && permissions.TaskCreate"
          />
          <icon-button v-bind="getStatusIcon(data)" v-if="isLabClientUser" />
        </div>
      </template>
      <DxScrolling mode="standard" />
      <DxPaging :enabled="true" :page-size="10" />
      <DxPager :show-page-size-selector="true" :show-info="true" />
    </DxDataGrid>
    <modal :status="isDistributionOpen" @close="isDistributionOpen = false">
      <distribution-popup
        @close="isDistributionOpen = false"
        @submitted="clearSelection"
        :method="distributionMethod"
        :caseIds="selectedCasesForDistribution"
      />
    </modal>
    <PathReportViewer v-if="isInline && !isLabClientUser" class="mt-1" @close="closeReport" />
    <modal :status="isRequestOpen" @close="handleCloseRequest">
      <client-task-form
        :caseId="requestCaseId"
        :caseNumber="requestCaseNumber"
        @submit="handleCloseRequest"
        @cancel="handleCloseRequest"
      />
    </modal>
    <modal :status="isExportModalOpen" @close="isExportModalOpen = !isExportModalOpen">
      <h2>Choose Columns to Export</h2>
      <div v-for="(column, idx) of columnsToExport" v-bind:key="column.name">
        <checkbox
          v-if="column.name !== 'command'"
          v-model="columnsToExport[idx].visible"
          :label="column.displayName"
          :id="column.name"
          :name="column.name"
        />
      </div>
      <div class="ml-auto my-2 my-lg-0 d-flex">
        <button class="btn btn-danger mr-1" @click="isExportModalOpen = false">Cancel</button>
        <button class="btn btn-success" @click="exportToExcel">Export</button>
      </div>
    </modal>
    <modal :status="isLocationPopupOpen" @close="isLocationPopupOpen = false">
      <lab-location-popup
        :isLoading="isLoading"
        @cancel="isLocationPopupOpen = false"
        @sign="handleSelectLabLocation"
      />
    </modal>
    <modal :status="isCollectedPopupOpen" @close="isCollectedPopupOpen = false">
      <collected-popup
        @submit="handleSetCollected"
        @cancel="isCollectedPopupOpen = false"
        :caseId="collectedPopupCaseId"
      />
    </modal>
  </div>
</template>

<script>
import { DxDataGrid, DxPager, DxPaging, DxScrolling, DxSelection } from "devextreme-vue/data-grid";
import {
  AuditLogApi,
  CasesApi,
  MacrosApi,
  PathReportMessagesApi,
  ReportsApi,
  UsersApi
} from "@/services";
import { mapGetters, mapState } from "vuex";
import {
  createLogItem,
  getTextFromHtml,
  getStatusIcon,
  capitalize,
  addCommasToCount
  // batchRedirect
} from "@/modules/helpers";
import Modal from "./common/Modal.vue";
import pathReportMessages from "@/services/pathReportMessages";
import IconButton from "./common/IconButton.vue";
import {
  CLEAR_FREE_TEXT_FILTERS,
  RENDER_FREE_TEXT_SEARCH,
  SET_FREE_TEXT_SEARCH_FILTERS
} from "@/modules/constants";
import { format } from "date-fns";
import PathReportViewer from "./Reports/PathReportViewer.vue";
import FullSearch from "./Headers/FullSearch.vue";
import PageTitle from "./common/PageTitle.vue";
import DistributionPopup from "./DistributionPopup.vue";
import { AuditLogItems, CaseStatusEnum, DistributionMethodsEnum } from "@/modules/enums";
import eventBus, {
  EDIT_SAVED_SEARCH_COLUMNS,
  fromBusEvent,
  LOAD_SAVED_SEARCH,
  SAVE_SEARCH
} from "@/modules/eventBus";
import { exhaustMap, switchMap, take, tap } from "rxjs/operators";
import ClientTaskForm from "./forms/ClientTaskForm.vue";
import Checkbox from "./common/Checkbox.vue";
import LabLocationPopup from "./LabLocationPopup.vue";
import CollectedPopup from "./CollectedPopup.vue";
import { handleErrors } from "@/modules/handleErrors";
import printJS from "print-js";
export default {
  components: {
    DxDataGrid,
    DxPager,
    DxPaging,
    DxScrolling,
    Modal,
    DxSelection,
    IconButton,
    PathReportViewer,
    FullSearch,
    PageTitle,
    DistributionPopup,
    ClientTaskForm,
    Checkbox,
    LabLocationPopup,
    CollectedPopup
  },
  name: "CaseFreeTextSearch",
  props: {
    showBackButton: {
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      isLoading: false,
      grid: {},
      isViewerOpen: false,
      currentPdf: "",
      searchPanel: {
        width: 300,
        visible: true
      },
      fileName: "",
      selectedCasesForDistribution: [],
      distributionMethod: null,
      reportCase: null,
      isDistributionOpen: false,
      selection: {
        mode: "multiple",
        allowSelectAll: true,
        showCheckBoxesMode: "always",
        selectAllMode: "page"
      },
      grouping: {
        autoExpandAll: true
      },
      protocolLookup: {
        dataSource: { store: MacrosApi.searchStore },
        displayExpr: "macroName",
        valueExpr: "macroId"
      },
      dataSource: [],
      remoteOperations: {
        filtering: true,
        sorting: true,
        paging: true,
        grouping: false
      },
      isRequestOpen: false,
      requestCaseId: null,
      requestCaseNumber: null,
      gridState: [],
      columnsToExport: [],
      isExportModalOpen: false,
      loadedSearch: null,
      isLocationPopupOpen: false,
      isCollectedPopupOpen: false,
      collectedPopupCaseId: null,
      sorting: {
        mode: "none"
      },
      caseCount: 0
    };
  },
  domStreams: ["contentReady$"],
  subscriptions() {
    return {
      SET_FREE_TEXT_SEARCH_FILTERS: fromBusEvent(SET_FREE_TEXT_SEARCH_FILTERS).pipe(
        exhaustMap(async payload => {
          if (this.isLabClientUser) {
            return CasesApi.createClientFreeTextStore(payload);
          }
          CasesApi.getFreeTextSearchCaseCount(payload).then(res => {
            this.caseCount = res.qtyCases;
          });
          return CasesApi.createFreeTextStore(payload);
        }),
        switchMap(dataSource => {
          this.dataSource = dataSource;
          return this.contentReady$.pipe(
            take(1),
            tap(() => {
              eventBus.$emit(RENDER_FREE_TEXT_SEARCH);
            })
          );
        })
      ),
      CLEAR_FREE_TEXT_FILTERS: fromBusEvent(CLEAR_FREE_TEXT_FILTERS).pipe(
        tap(() => {
          this.dataSource = [];
          this.caseCount = 0;
        })
      )
    };
  },
  mounted() {
    eventBus.$on(LOAD_SAVED_SEARCH, loadedSearch => {
      this.grid.state(this.searchGridState);
      this.loadedSearch = loadedSearch;
    });
    eventBus.$on(SAVE_SEARCH, res => {
      const state = JSON.parse(res.state);
      try {
        UsersApi.updateSavedSearch({
          ...res,
          state: JSON.stringify({ ...state, columnsToExport: this.columnsToExport })
        });
      } catch (error) {
        window.notify("An error ocurred.", "error");
      }
    });
  },
  beforeDestroy() {
    eventBus.$off(LOAD_SAVED_SEARCH);
    eventBus.$off(SAVE_SEARCH);
  },

  computed: {
    ...mapState({
      caseDetails: state => state.accessionStore.caseDetails,
      caseHistory: state => state.accessionStore.history,
      caseStatuses: state => state.caseStatuses,
      isLabClientUser: state => state.currentUser.isLabClientUser,
      accessionMode: state => state.applicationSettings.accessionMode,
      searchGridState: state => state.grids.freeTextSearch,
      currentLabLocation: state => state.currentLabLocation,
      availableLabLocations: state => state.availableLabLocations,
      labSettings: state => state.labSettings
    }),
    ...mapGetters("report", ["isInline"]),
    ...mapGetters(["permissions"]),
    stateStoring() {
      let customSave = this.customStateSave;
      let customLoad = this.customStateLoad;
      return {
        enabled: true,
        type: "custom",
        customSave,
        customLoad,
        ignoreColumnOptionNames: ["filterValue", "filterValues", "headerFilter", "sortIndex"]
      };
    },
    toolbar() {
      return {
        items: [
          {
            location: "before",
            template: "gridTitle"
          },
          // Removed for IP-1236
          // {
          //   widget: "dxButton",
          //   visible: this.permissions.CaseView,
          //   options: {
          //     icon: "fa fa-file-excel",
          //     onClick: this.openExportModal,
          //     hint: "Click to export selected cases to excel file.",
          //     elementAttr: {
          //       class: "icon-color"
          //     }
          //   }
          // },
          {
            widget: "dxButton",
            visible: this.permissions.CaseView,
            options: {
              icon: "fa fa-download",
              onClick: this.handleDownloadCases,
              hint: "Click to download selected cases.",
              elementAttr: {
                class: "icon-color"
              }
            }
          },
          {
            widget: "dxButton",
            visible: this.permissions.CaseSignout || this.permissions.CaseSignoutByProxy,
            options: {
              icon: "fa fa-pen",
              onClick: this.handleSignMultipleCase,
              hint: "Click to sign selected cases.",
              elementAttr: {
                class: "icon-color",
                disabled: this.isLoading
              }
            }
          },
          {
            widget: "dxButton",
            visible: this.permissions.PathReportSendByPrint,
            options: {
              icon: "fa fa-print",
              onClick: () => this.handleDistributeCases(DistributionMethodsEnum.Print),
              hint: "Click to print selected cases.",
              elementAttr: {
                class: "icon-color"
              }
            }
          },
          {
            widget: "dxButton",
            visible: this.permissions.PathReportSendByFax,
            options: {
              icon: "fa fa-fax",
              onClick: () => this.handleDistributeCases(DistributionMethodsEnum.Fax),
              hint: "Click to fax selected cases.",
              elementAttr: {
                class: "icon-color"
              }
            }
          },
          {
            widget: "dxButton",
            visible: this.permissions.PathReportSendByEmail,
            options: {
              icon: "fa fa-envelope",
              onClick: () => this.handleDistributeCases(DistributionMethodsEnum.Email),
              hint: "Click to email selected cases.",
              elementAttr: {
                class: "icon-color"
              }
            }
          },
          {
            widget: "dxButton",
            visible: this.permissions.PathReportSendByFileDrop,
            options: {
              icon: "fa fa-file-pdf",
              onClick: () => this.handleDistributeCases(DistributionMethodsEnum.FileDrop),
              hint: "Click to use send selected cases by file drop.",
              elementAttr: {
                class: "icon-color"
              }
            }
          },
          {
            widget: "dxButton",
            visible: this.permissions.PathReportSendByHL7,
            options: {
              icon: "fa fa-share-alt",
              onClick: () => this.handleDistributeCases(DistributionMethodsEnum.HL7),
              hint: "Click to use hl7 on selected cases.",
              elementAttr: {
                class: "icon-color"
              }
            }
          },
          {
            name: "columnChooserButton",
            visible: true
          }
        ],
        visible: true
      };
    },
    columns() {
      return [
        {
          type: "command",
          caption: "Actions",
          cellTemplate: "actions",
          allowExporting: false
        },
        {
          dataField: "caseNumber",
          caption: "Case #",
          groupIndex: 1,
          sortOrder: "asc",
          sortIndex: 0,
          calculateSortValue: data => data.caseNumber.replace(/\D/gi, "")
        },
        {
          dataField: "specimenOrder",
          caption: "Specimen",
          sortOrder: "asc",
          sortIndex: 1
        },
        {
          dataField: "caseStatus",
          dataType: "string",
          allowFiltering: true,
          lookup: {
            dataSource: this.caseStatuses.map((e, i) => ({ displayName: e, id: i })),
            displayExpr: "displayName",
            valueExpr: "id"
          },
          caption: "Status",
          allowSearch: false
        },
        { dataField: "receivedOn", caption: "Received", dataType: "date" },
        {
          dataField: "collectedOn",
          caption: "Collected",
          dataType: "date",
          visible: !this.isLabClientUser
        },
        { dataField: "reportedOn", caption: "Reported", dataType: "date" },
        { dataField: "providerName", dataType: "string", caption: "Provider" },
        {
          dataField: "pathologistName",
          dataType: "string",
          caption: "Pathologist",
          visible: !this.isLabClientUser
        },
        { dataField: "patientName", dataType: "string", caption: "Patient" },
        { dataField: "patientDateOfBirth", caption: "DOB", dataType: "date" },
        { dataField: "site", dataType: "string" },
        {
          dataField: "diagnosis",
          dataType: "string",
          calculateCellValue: data => this.calculateHTML(data.diagnosis)
        },
        {
          visible: false,
          dataType: "string",
          dataField: "microscopic",
          calculateCellValue: data => this.calculateHTML(data.microscopic)
        },
        // {
        //   dataType: "string",
        //   visible: false,
        //   dataField: "notes",
        //   calculateCellValue: data => this.calculateHTML(data.notes)
        // },
        {
          visible: false,
          dataType: "string",
          dataField: "gross",
          calculateCellValue: data => this.calculateHTML(data.gross)
        },
        {
          visible: false,
          dataType: "string",
          dataField: "caseNote",
          calculateCellValue: data => this.calculateHTML(data.caseNote)
        },
        {
          visible: false,
          dataType: "string",
          dataField: "clinical",
          calculateCellValue: data => this.calculateHTML(data.clinical)
        }
      ];
    },
    columnChooser() {
      return {
        enabled: true,
        allowSearch: true,
        mode: "select"
      };
    }
  },
  methods: {
    handleViewPathReport(caseId) {
      this.$store.dispatch("report/viewPathReport", { caseId });
    },
    handleCloseRequest() {
      this.requestCaseId = null;
      this.isRequestOpen = false;
    },
    async createRequest(key) {
      const caseDetails = await CasesApi.getCaseById(key);
      this.requestCaseId = key;
      this.requestCaseNumber = caseDetails.caseNumber;
      this.isRequestOpen = true;
    },
    closeReport() {
      this.$store.commit("report/toggleReportViewer", false);
    },
    clearSelection() {
      if (this.grid.clearSelection) {
        this.grid.clearSelection();
      }
    },
    getStatusIcon,
    async handleDistributeCases(method) {
      let cases = this.grid.getSelectedRowsData();
      if (this.isLabClientUser) {
        const numberOfSelectedCases = cases.length;
        cases = cases.filter(e =>
          [
            CaseStatusEnum.ReportedPrelim,
            CaseStatusEnum.Reported,
            CaseStatusEnum.ReReleased
          ].includes(e.caseStatus)
        );
        if (cases.length < numberOfSelectedCases) {
          const methodText =
            method < 4
              ? Object.keys(DistributionMethodsEnum)
                  .find(key => DistributionMethodsEnum[key] === method)
                  .toLowerCase()
              : "distribut";
          await window.alert(
            `One or more caseIds could not be ${methodText}ed because they have not been reported.`
          );
        }
      } else if (method === DistributionMethodsEnum.HL7) {
        let signedCases = cases.filter(e => [7, 8, 10, 11, 13, 14].includes(e.caseStatus));
        if (!signedCases?.length) {
          window.alert("Cases that have not been signed cannot be distributed through HL7.");
          return;
        }
        const confirm = await window.confirm(
          `<div class="text-center">
              One or more of the selected accessions has not been signed out and cannot be distributed through HL7.<br>Do you wish to continue?
          </div>`
        );
        if (!confirm) {
          return;
        } else {
          cases = signedCases;
        }
      }

      const caseIds = cases.map(e => e.caseId);
      if (caseIds.length === 0) {
        return window.notify("No cases selected.", "error");
      }
      for (const e of cases) {
        if (![7, 8, 10, 11, 13, 14].includes(e.caseStatus)) {
          const confirm = await window.confirm(
            `<div class="text-center">
          One or more of the selected cases has not been signed out. <br> Are you sure you want to distribute this report?
          </div>`
          );
          if (!confirm) {
            return;
          }
          break;
        }
      }
      this.selectedCasesForDistribution = caseIds;
      if (this.isLabClientUser && method === DistributionMethodsEnum.Print) {
        this.printPathReportsClient(cases);
        return;
      }
      this.distributionMethod = method;
      this.isDistributionOpen = true;
    },
    canSignOut(data) {
      const { caseStatus } = data;
      if (caseStatus === 6 || caseStatus === 9 || caseStatus === 12 || caseStatus === 10) {
        return true;
      }
      return false;
    },
    async handleSignCase(caseDetails) {
      if (this.isLoading) {
        return;
      }
      this.payloadToSign = null;
      if (
        this.labSettings.ConfirmPrelimSignOuts &&
        caseDetails.caseStatus === CaseStatusEnum.ResultedOnHold
      ) {
        const confirm = await window.confirm(
          "This case has a hold code attached. Are you sure you want to sign it as prelim?"
        );
        if (!confirm) {
          return;
        }
      }
      try {
        this.isLoading = true;
        const { caseStatus, collectedOn, caseId } = caseDetails;
        if (this.canSignOut(caseDetails)) {
          if (this.availableLabLocations.length > 1 && !this.currentLabLocation) {
            this.isLocationPopupOpen = true;
            this.payloadToSign = caseDetails;
            return;
          }
          const isSigned = [7, 8, 10, 11, 13, 14].includes(caseStatus);
          if (!isSigned && !collectedOn) {
            this.isCollectedPopupOpen = true;
            this.collectedPopupCaseId = caseId;
            return;
          } else {
            const caseId = caseDetails.caseId;
            document.body.style.cursor = "wait";
            const SignResponse = await this.$store.dispatch("accessionStore/handleSignCase", {
              caseId,
              labLocationId: this.currentLabLocation,
              caseStatus
            });
            if (SignResponse) {
              window.notify(
                `Successfully ${caseStatus === 10 ? "unsigned" : "signed"} accession ${
                  caseDetails.accessionNumber
                }. `
              );
              // if (SignResponse?.batchRedirect) {
              //   batchRedirect();
              // }
            }
            document.body.style.cursor = "";
            if (this.searchPanel$?.focus) {
              this.focusElement(this.searchPanel$);
            }
            this.refreshGrid();
          }
        } else {
          throw "Case cannot be signed with the current status.";
        }
      } catch (error) {
        handleErrors(error);
      } finally {
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
        document.body.style.cursor = "";
      }
    },
    async handleSignMultipleCase() {
      if (this.isLoading) {
        return;
      }
      this.payloadToSign = null;
      const selectedRows = this.grid.getSelectedRowsData();
      if (selectedRows.length === 0) {
        return window.notify("No case selected.", "error");
      }
      if (this.availableLabLocations.length > 1 && !this.currentLabLocation) {
        this.isLocationPopupOpen = true;
        return;
      }
      const caseIds = selectedRows
        .filter(
          e =>
            [
              CaseStatusEnum.ResultedOnHold,
              CaseStatusEnum.Resulted,
              CaseStatusEnum.ResultedAgain,
              CaseStatusEnum.Signed
            ].includes(e.caseStatus) && e.collectedOn
        )
        .map(v => {
          return v.caseId;
        });
      if (
        this.labSettings.ConfirmPrelimSignOuts &&
        selectedRows.find(e => e.caseStatus === CaseStatusEnum.ResultedOnHold)
      ) {
        const confirm = await window.confirm(
          "One or more cases you are signing out has a hold code attached. Are you sure you want to sign these cases as prelim?"
        );
        if (!confirm) {
          return;
        }
      }
      const unsignableCases = selectedRows.filter(e => !caseIds.includes(e.caseId));
      try {
        this.isLoading = true;
        if (caseIds.length > 0) {
          document.body.style.cursor = "wait";
          const SignResponse = await CasesApi.multiSignCase(caseIds, this.currentLabLocation);
          if (SignResponse && unsignableCases.length) {
            window.alert(
              `${
                caseIds.length
              } cases successfully signed<br><br>The following cases could not be signed:<br>${unsignableCases
                .map(e => e.accessionNumber)
                .join("<br>")}`
            );
            const logItem = createLogItem(
              SignResponse.length === 1 ? SignResponse[0] : {},
              AuditLogItems.ChangeAccession
            );
            logItem.comments = `Signed out case${
              SignResponse.length > 1 ? "s" : ""
            } ${SignResponse.map(e => e.caseNumber).join(", ")}.`;
            AuditLogApi.insertLogMessage(logItem);
          } else if (SignResponse) {
            window.notify(`${caseIds.length} cases successfully signed`);
            const logItem = createLogItem(
              SignResponse.length === 1 ? SignResponse[0] : {},
              AuditLogItems.ChangeAccession
            );
            logItem.comments = `Signed out case${
              SignResponse.length > 1 ? "s" : ""
            } ${SignResponse.map(e => e.caseNumber).join(", ")}.`;
            AuditLogApi.insertLogMessage(logItem);
          }
          document.body.style.cursor = "";
          this.refreshGrid();
        } else {
          throw "Cases cannot be signed because they are not in the right status or do not have Collected On dates.";
        }
      } catch (error) {
        handleErrors(error);
      } finally {
        this.isLoading = false;
        document.body.style.cursor = "";
      }
    },
    async exportToExcel() {
      let promptForNew = true;
      if (this.loadedSearch) {
        if (
          JSON.stringify(this.columnsToExport) ===
          JSON.stringify(this.loadedSearch?.columnsToExport)
        ) {
          promptForNew = false;
        } else {
          const confirm = await window.confirm(
            `The export columns for saved search "${this.loadedSearch.name}" have changed. Would you like to save them?`
          );
          if (confirm) {
            promptForNew = false;
            try {
              const stateToUpdate = {
                fields: this.loadedSearch.fields,
                gridState: this.loadedSearch.gridState,
                columnsToExport: this.columnsToExport
              };
              await UsersApi.updateSavedSearch({
                ...this.loadedSearch,
                state: JSON.stringify(stateToUpdate)
              });
              window.notify("Saved search updated.");
              this.loadedSearch.columnsToExport = this.columnsToExport;
              eventBus.$emit(EDIT_SAVED_SEARCH_COLUMNS, {
                columns: this.columnsToExport,
                id: this.loadedSearch.id
              });
            } catch (error) {
              window.notify("An error ocurred.", "error");
            }
          }
        }
      }
      if (promptForNew) {
        const confirm = await window.confirm(
          "Would you like to create a new saved search with this column arrangement?"
        );
        if (confirm) {
          eventBus.$emit(EDIT_SAVED_SEARCH_COLUMNS, { columns: this.columnsToExport });
        }
      }
      const viewGridState = this.searchGridState;
      const exportGridState = {
        ...this.searchGridState,
        columns: this.searchGridState.columns.map((e, idx) => {
          return { ...e, visible: this.columnsToExport[idx].visible };
        })
      };
      let selectedRows = this.grid.getSelectedRowsData();
      this.grid.beginUpdate();
      for (const column of exportGridState.columns) {
        this.grid.columnOption(column.name, "visible", column.visible);
      }
      if (selectedRows.length) {
        this.grid.exportToExcel(true);
      } else {
        selectedRows = this.grid.getVisibleRows().map(e => e.data);
        this.grid.exportToExcel();
      }
      for (const column of viewGridState.columns) {
        this.grid.columnOption(column.name, "visible", column.visible);
      }
      const logItem = createLogItem({}, 8, "Case Search Grid");
      logItem.comments =
        "Exported the following cases to excel " +
        selectedRows.map(e => e.accessionNumber).join(" \n ");
      AuditLogApi.insertLogMessage(logItem);
      this.isExportModalOpen = false;
      return;
    },
    async handleDownloadCases() {
      let cases = this.grid.getSelectedRowsData();
      if (this.isLabClientUser) {
        const numberOfSelectedCases = cases.length;
        cases = cases.filter(e =>
          [
            CaseStatusEnum.ReportedPrelim,
            CaseStatusEnum.Reported,
            CaseStatusEnum.ReReleased
          ].includes(e.caseStatus)
        );
        if (cases.length < numberOfSelectedCases) {
          await window.alert(
            `One or more cases could not be downloaded because they have not been reported.`
          );
        }
      }
      if (cases.length === 0) {
        return window.notify("No cases selected.", "error");
      }
      this.isLoading = true;
      try {
        const caseIds = cases.map(e => e.caseId);
        const extension = this.useZip && caseIds.length > 1 ? "zip" : "pdf";
        const file = await CasesApi.getMultiCasePdf(caseIds, extension);
        const url = URL.createObjectURL(file);
        const a = document.createElement("a");
        a.href = url;
        if (cases.length === 1) {
          a.download = `${format(new Date(), "MM/dd/yyyy")}_${
            cases[0]?.accessionNumber || ""
          }_PATH_REPORT.pdf`;
        } else {
          a.download = `${format(new Date(), "MM/dd/yyyy")}_PATH_REPORTS.${extension}`;
        }
        a.click();
        pathReportMessages.addPathReportMessage({ caseIds: cases.map(e => e.caseId), method: 6 });
        const logItem = createLogItem({}, 9, "Case Search Grid");
        logItem.comments =
          "Downloaded the following pdfs " + cases.map(e => e.accessionNumber).join(" \n ");
        AuditLogApi.insertLogMessage(logItem);
      } catch (error) {
        window.notify("Error loading file.", "error");
      }
      this.isLoading = false;
    },
    hasItemsExpr(data) {
      return data.specimenCount > 1;
    },
    calculateHTML(data) {
      return getTextFromHtml(data || "");
    },
    contentReady({ component }) {
      addCommasToCount();
      let pagerEl = document.querySelector(".dx-pager .dx-pages .dx-info");
      const originalText = pagerEl?.textContent;
      if (originalText && /items/i.test(originalText)) {
        const newText = originalText.replace(/items/i, `specimens, ${this.caseCount} cases`);
        pagerEl.textContent = newText;
      }
      const sortArrow = document.getElementsByClassName("dx-sort-up");
      if (sortArrow?.length) {
        for (const el of sortArrow) {
          el.style["display"] = "none";
        }
      }
      this.contentReady$.next(component);
    },
    initializeGrid({ component }) {
      this.grid = component;
      this.grid.on("exporting", data => {
        data.fileName = "Cases Search Grid " + format(new Date(), "MM/dd/yyyy");
      });
    },
    async renderPdf(caseId) {
      try {
        const caseInformation = await CasesApi.getCaseHeader(caseId);
        const isReported = [11, 14, 8].includes(caseInformation.status);
        let file;
        if (isReported) {
          file = await CasesApi.getNewestPathReport(caseId);
        } else {
          file = await ReportsApi.getSSRSReport({ caseId, format: "pdf" });
        }
        const blob = new Blob([file], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        this.fileName = `${caseInformation.caseNumber}-PathReport`;
        this.reportCase = caseId;
        this.currentPdf = url;
        this.isViewerOpen = !this.isViewerOpen;
      } catch (e) {
        window.notify("An error occurred", "error");
      }
    },
    async handlePrint() {
      if (this.reportCase) {
        const caseDetails = CasesApi.getCaseById(this.reportCase);
        const logItem = createLogItem(caseDetails, 1);
        logItem.comments = `Printed path report PDF.`;
        AuditLogApi.insertLogMessage(logItem);
      } else {
        const logItem = createLogItem({}, 1);
        logItem.comments = `Printed full patient history PDF.`;
        AuditLogApi.insertLogMessage(logItem);
      }
    },
    async handlePdfDownload() {
      if (this.reportCase) {
        const caseDetails = CasesApi.getCaseById(this.reportCase);
        pathReportMessages.addPathReportMessage({
          caseIds: [this.reportCase ?? this.$route.params.caseId],
          method: 6
        });
        const logItem = createLogItem(caseDetails, 1);
        logItem.comments = `Manually downloaded path report pdf`;
        AuditLogApi.insertLogMessage(logItem);
      } else {
        const logItem = createLogItem({}, 1);
        logItem.comments = `Manually downloaded full history path report pdf`;
        AuditLogApi.insertLogMessage(logItem);
      }
    },
    isCaseReadyForSignout(data) {
      return (
        (this.permissions.CaseSignout || this.permissions.CaseSignoutByProxy) &&
        [6, 9, 12].includes(data.caseStatus) &&
        !this.isLabClientUser
      );
    },
    signButton(data) {
      const { caseStatus } = data;
      if (caseStatus === 6 || caseStatus === 9 || caseStatus === 12) {
        return { icon: "edit", message: "Sign case." };
      }
      if (caseStatus === 10) {
        return { icon: "unlink", message: "Unsign case" };
      }
      return { icon: "edit", message: "Sign case." };
    },
    handleNavigateToCase(caseId) {
      if (this.$route.fullPath.includes("accession")) {
        return;
      }
      if (this.isLabClientUser) {
        return this.$router.push(`/accession/${caseId}/`);
      }
      switch (this.accessionMode) {
        case "specimen":
          this.$router.push({
            name: "Specimen",
            params: { caseId }
          });
          break;
        case "result":
          this.$router.push({
            name: "SpecimenResults",
            params: { caseId }
          });
          break;
        case "signout":
          this.$router.push({
            name: "SpecimenResults",
            params: { caseId }
          });
          break;
        case "transactions":
          this.$router.push({
            name: "accesionTransactions",
            params: { caseId }
          });
          break;
        case "insurance":
          this.$router.push({
            name: "accessionInsurance",
            params: { caseId }
          });
          break;
        case "distribution":
          this.$router.push({
            name: "Distribution",
            params: { caseId }
          });
          break;
        case "history":
          this.$router.push({
            name: "CaseHistory",
            params: { caseId }
          });
          break;
        default:
          this.$router.push({
            name: "CaseView",
            params: { caseId }
          });
          break;
      }
    },
    handleCaseClick(event) {
      //Stops the rerouting if you click inside the action cell which is located at index 0
      if (
        !["command", "expand", "selection", "groupExpand"].includes(event?.column?.type) &&
        event?.data?.caseId
      ) {
        this.handleNavigateToCase(event.data.caseId);
      }
    },
    customStateSave(gridState) {
      const payload = {
        name: "freeTextSearch",
        gridState,
        columns: this.columns
      };
      this.$store.dispatch("grids/setGridState", payload);
    },
    customStateLoad() {
      return this.searchGridState;
    },
    openExportModal() {
      if (this.loadedSearch?.columnsToExport) {
        this.columnsToExport = this.loadedSearch.columnsToExport.map(e => {
          return {
            ...e,
            displayName:
              this.columns.find(col => col?.dataField === e.name)?.caption ||
              (e.name === "caseNote" ? "Case Note" : capitalize(e.name))
          };
        });
      } else {
        this.columnsToExport = this.searchGridState.columns.map(e => {
          return {
            name: e.name,
            visible: [
              "diagnosis",
              "microscopic",
              "notes",
              "gross",
              "caseNote",
              "clinical"
            ].includes(e.name)
              ? true
              : e.visible,
            displayName:
              this.columns.find(col => col?.dataField === e.name)?.caption ||
              (e.name === "caseNote" ? "Case Note" : capitalize(e.name))
          };
        });
      }
      this.isExportModalOpen = true;
    },
    handleSelectLabLocation() {
      this.isLocationPopupOpen = false;
      this.handleSignMultipleCase();
    },
    async handleSetCollected(collectedOnDate) {
      this.isCollectedPopupOpen = false;
      if (!this.collectedPopupCaseId) {
        window.alert("An error ocurred signing out this case. Please try again.");
      }
      const caseDetails = await CasesApi.getCaseById(this.collectedPopupCaseId);
      const updatedCase = await this.$store.dispatch("accessionStore/updateCaseDetails", {
        ...caseDetails,
        collectedOn: collectedOnDate
      });
      this.handleSignCase({
        ...updatedCase,
        caseStatus: updatedCase?.caseStatus || updatedCase?.status
      });
      this.collectedPopupCaseId = null;
    },
    refreshGrid() {
      if (this.grid.refresh) {
        this.grid.refresh(true);
      }
    },
    async printPathReportsClient(cases) {
      this.isLoading = true;
      try {
        const file = await CasesApi.getMultiCasePdf(cases.map(e => e.caseId));
        const url = URL.createObjectURL(file);
        PathReportMessagesApi.addPathReportMessage({
          caseIds: cases.map(e => e.caseId),
          method: 7
        });
        const logItem = createLogItem({}, 9, "Client Search Grid");
        logItem.comments =
          "Printed the following pdfs " + cases.map(e => e.accessionNumber).join(" \n ");
        AuditLogApi.insertLogMessage(logItem);
        printJS({
          printable: url,
          type: "pdf",
          documentTitle: `${format(new Date(), "MM/dd/yyyy")}_PATH_REPORTS.pdf`
        });
      } catch (error) {
        window.notify("Error printing cases.", "error");
      } finally {
        this.isLoading = false;
      }
    },
    handleBackButton() {
      this.$emit("back");
    },
    handleDataError({ error }) {
      handleErrors(error);
    }
  }
};
</script>
<style lang="scss" scoped>
.pdf-modal {
  width: 50vw;
  height: 80vh;
}
@media screen and (max-width: 1000px) {
  .search-header {
    display: flex;
  }
}
.no-scroll {
  overflow-y: hidden;
}
.text-primary {
  color: $primary;
  &:hover {
    color: white !important;
  }
}
.icon {
  margin-right: 0.1rem;
}
::v-deep
  .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)
  > td:not(.dx-focused) {
  cursor: pointer;
}
</style>
