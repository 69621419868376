export default {
  inserted: function (el) {
    const input =
      el.querySelector(".form-control") || el.querySelector("input.dx-texteditor-input");
    if (input) {
      requestAnimationFrame(() => {
        input.scrollTo({ behavior: "smooth" });
        input.focus();
      });
    } else {
      el.focus();
    }
  }
};
