import api from "./api.js";
class MolecularTestRun {
  constructor() {
    this.url = "/api/TestRuns";
  }
  getTestRuns() {
    return api.get(this.url);
  }
  createTestRun(payload) {
    return api.post(this.url, payload);
  }
  updateTestRun(payload) {
    return api.put(this.url, payload);
  }
  deleteTestRun(id) {
    return api.delete(`${this.url}/${id}`);
  }
  queueTestRun(id) {
    return api.put(`${this.url}/${id}/Queued`);
  }
}
export default new MolecularTestRun();
