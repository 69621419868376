const redactedColumnCaptions = [
  "Name",
  "DOB",
  "MRN",
  "SSN",
  "Account Number",
  "INS",
  "Patient Name",
  "Patient",
  "Policy Number",
  "Group Number",
  "Guarantor",
  "Address Line 1",
  "Date Of Birth",
  "Social Security No.",
  "Medical Record No.",
  "Account No."
];

const redactedDataFields = [
  "patient",
  "patientSSN",
  "ssn",
  "dob",
  "patientDOB",
  "patientDob",
  "patientDateOfBirth",
  "dateOfBirth",
  "birth",
  "patientMRN",
  "mrn",
  "patientName",
  "name",
  "patientFirstName",
  "firstName",
  "patientMiddleName",
  "middleName",
  "patientLastName",
  "lastName",
  "patientMaidenName",
  "maidenName",
  "accountNumber",
  "acctNumber",
  "patientAccountNumber",
  "refNumber",
  "alternateId",
  "address1",
  "address2",
  "addressLine1",
  "addressLine2",
  "line1",
  "line2",
  "address",
  "phone",
  "email",
  "groupNumber",
  "guarantor",
  "policyNumber",
  "comments",
  "patientFieldContains",
  "ipAddress",
  "pdf"
];

export default {
  methods: {
    redactLogRocket(cell) {
      if (cell.rowType !== "header" && redactedColumnCaptions.includes(cell.column.caption)) {
        cell.cellElement.setAttribute("data-private", "redact");
      } else if (cell.rowType !== "header" && redactedDataFields.includes(cell.column.dataField)) {
        cell.cellElement.setAttribute("data-private", "redact");
      }
    }
  }
};
