import api from "./api.js";
class Billing {
  constructor() {
    this.url = "/api/BillingTransactionCodes/";
  }
  get searchStore() {
    return api.createSearch(this.url + "search");
  }
  get cptPairsStore() {
    return api.createSearch(this.url + "search", "code");
  }
  createSearch(url, labId) {
    return api.createSearch(this.url + url, undefined, { labId });
  }
  searchTransactionCodes(options, labId) {
    return api.post(this.url + "search?labId=" + labId, options);
  }
  getTransactionCode(id) {
    return api.get(this.url + id);
  }
  updateTransactionCode(transactionCode) {
    return api.put(this.url, transactionCode);
  }
  addTransactionCode(transactionCode) {
    return api.post(this.url, transactionCode);
  }
  getTransactionCodeRates(transactionCodeId) {
    return api.get(this.url + `${transactionCodeId}/BillingRates`);
  }
}
export default new Billing();
