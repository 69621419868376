<template>
  <div class="d-flex justify-content-end mt-1">
    <Loader v-if="isLoading" size="small" class="mr-1" />
    <button class="btn btn-danger mr-1" @click.prevent="handleCancel">{{ cancelText }}</button>
    <button
      type="submit"
      class="btn btn-primary"
      v-shortkey="submitShortKey"
      @shortkey.prevent="handleSubmit"
      :disabled="isDisabled || isLoading"
      @click.prevent="handleSubmit"
    >
      {{ submitText }}
    </button>
  </div>
</template>

<script>
import Loader from "./Loader.vue";

export default {
  props: {
    submitText: {
      default: "Submit"
    },
    cancelText: {
      default: "Cancel"
    },
    isLoading: {
      default: false
    },
    isDisabled: {
      default: false
    },
    submitShortKey: {
      default: ["enter"]
    }
  },
  methods: {
    handleSubmit() {
      this.$emit("submit");
    },
    handleCancel() {
      this.$emit("cancel");
    }
  },
  components: { Loader }
};
</script>

<style lang="scss" scoped></style>
