import eventBus, {
  TOGGLE_PROOF_READ_DIALOG,
  SPELL_CHECK_ACTION,
  START_SPELLCHECK,
  SPELL_CHECK_STATUS
} from "@/modules/eventBus";
import { fromEvent } from "rxjs";
import { exhaustMap, filter, map, repeat, switchMap, take, tap } from "rxjs/operators";
import { mapState } from "vuex";
import elementObserver from "./elementObserver";
import { UserTypesEnum } from "./enums";
const defaultKey = process.env.VUE_APP_DEFAULT_SPELLCHECK_KEY;
const defaultDictionary = process.env.VUE_APP_DEFAULT_DICTIONARY_ID;
const defaultOptions = {
  enableGrammar: false,
  ignoreWordsWithMixedCases: false,
  ignoreWordsWithNumbers: false,
  enableLanguagesInBadgeButton: false,
  proofreadAsYouType: true
};
export default {
  data() {
    return {
      isProofReadDialogOpen: false,
      webSpellCheckInstance: null
    };
  },
  mounted() {
    if (this.webSpellcheckerLoaded) {
      this.initializeSpellcheck();
    }
  },
  beforeDestroy() {
    this.destroySpellcheck();
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser,
      SpellcheckOnSave: state => state.labSettings.SpellcheckOnSave,
      SpellCheckSubscriptionKey: state => state.labSettings.SpellCheckSubscriptionKey || defaultKey,
      SpellCheckDictionaryId: state =>
        state.labSettings.SpellCheckDictionaryId || defaultDictionary,
      SpellCheckOptions: state => state.labSettings.SpellCheckOptions || defaultOptions,
      enableSpellchecker: state => state.applicationSettings.enableSpellchecker,
      ignoreAllCapsWords: state => state.applicationSettings.ignoreAllCapsWords,
      ignoreDomainNames: state => state.applicationSettings.ignoreDomainNames,
      ignoreWordsWithMixedCases: state => state.applicationSettings.ignoreWordsWithMixedCases,
      ignoreWordsWithNumbers: state => state.applicationSettings.ignoreWordsWithNumbers
    }),
    webSpellcheckerLoaded() {
      return typeof window.WEBSPELLCHECKER !== "undefined";
    }
  },
  domStreams: [
    "toggleProofreader$",
    "initWSC$",
    "updateWSCUi$",
    "hideWSCUi$",
    "wordChanged$",
    "addWordToDictionary$",
    "removeWordFromUserDictionary$"
  ],
  subscriptions() {
    const focusState$ = this.$watchAsObservable("isFocused", { immediate: true }).pipe(
      map(({ newValue }) => newValue)
    );
    const dialogVisibility$ = this.updateWSCUi$.pipe(
      map(() => {
        const dialogElement = document.querySelector(
          "div.wsc-dialog.wsc-theme-custom.wsc-element.wsc--border-box"
        );
        return dialogElement;
      }),
      filter(e => e != null),
      switchMap(wscDialogEl => elementObserver(wscDialogEl)),
      map(([mutations]) => {
        if (Array.isArray(mutations)) {
          for (const mutation of mutations) {
            const { target } = mutation;
            if (!target.classList.contains("wsc--hidden")) {
              return true;
            }
          }
        }
        return false;
      }),
      tap(state => {
        console.log("Is web spellcheck dialog open?", state ? "Yes" : "No");
        eventBus.$emit(TOGGLE_PROOF_READ_DIALOG, state);
      })
    );

    const refocus$ = focusState$.pipe(
      filter(isFocused => isFocused),
      switchMap(() => dialogVisibility$.pipe(filter(e => !e))),
      tap(() => {
        this.focus();
      })
    );
    const bindKeys$ = this.initWSC$.pipe(
      switchMap(instance =>
        focusState$.pipe(
          filter(isFocused => isFocused),
          exhaustMap(() => {
            return fromEvent(instance.getContainerNode(), "keydown").pipe(
              filter(
                e =>
                  [e.key, e.keyCode, e.code].includes(113) ||
                  [e.key, e.keyCode, e.code].includes("F2")
              ),
              tap(() => {
                instance.openDialog();
              })
            );
          })
        )
      )
    );

    const initializeWSC$ = this.initWSC$.pipe(
      tap(instance => {
        instance.subscribe("updateUi", e => this.updateWSCUi$.next(e));
        instance.subscribe("changeStateForAddedWordToUserDictionary", e =>
          this.addWordToDictionary$.next(e)
        );
        instance.subscribe("changeStateForDeletedWordFromUserDictionary", e =>
          this.removeWordFromUserDictionary$.next(e)
        );
      })
    );
    const enableSpellchecker$ = this.initWSC$.pipe(
      switchMap(i =>
        this.$watchAsObservable("enableSpellchecker", { immediate: true }).pipe(
          map(({ newValue }) => ({ isEnabled: newValue, instance: i }))
        )
      ),

      tap(({ isEnabled, instance }) => {
        if (isEnabled) {
          instance.enable();
        } else {
          instance.disable();
        }
      })
    );
    const lowerCaseRegex = /^[a-z0-9]+$/;
    const upperCaseRegex = /^[A-Z0-9]+$/;
    const addWords$ = this.initWSC$.pipe(
      switchMap(() =>
        this.addWordToDictionary$.pipe(
          take(1),
          exhaustMap(async ({ word }) => {
            console.log(`Added ${word} to dictionary.`);
            const dictionaryName = await this.webSpellCheckInstance.getUserDictionaryName();
            const addWord = async word => {
              return await fetch("https://svc.webspellchecker.net/spellcheck31/api", {
                method: "POST",
                body: `customerid=${process.env.VUE_APP_DEFAULT_SPELLCHECK_KEY}&format=json&app_type=proofreader_editable_controls&cmd=user_dictionary&action=addword&name=${dictionaryName}&word=${word}`
              });
            };

            const wordList = await this.getUserWordList(dictionaryName);
            const upperCaseExists = wordList.includes(word.toUpperCase());
            const lowerCaseExists = wordList.includes(word.toLowerCase());
            if (!upperCaseExists) {
              await addWord(word.toUpperCase()).then(res => {
                if (res?.status === 200) {
                  console.log(`Added ${word.toUpperCase()} to dictionary.`);
                } else {
                  console.log(`Error adding ${word.toUpperCase()} to dictionary.`);
                }
              });
            }
            if (!lowerCaseExists) {
              await addWord(word.toLowerCase()).then(res => {
                if (res?.status === 200) {
                  console.log(`Added ${word.toLowerCase()} to dictionary.`);
                } else {
                  console.log(`Error adding ${word.toLowerCase()} to dictionary.`);
                }
              });
            }
          }),
          repeat()
        )
      )
    );
    const removeWords$ = this.initWSC$.pipe(
      switchMap(() =>
        this.removeWordFromUserDictionary$.pipe(
          take(1),
          exhaustMap(async ({ word }) => {
            console.log(`Deleted ${word} from the dictionary.`);
            const dictionaryName = await this.webSpellCheckInstance.getUserDictionaryName();
            const deleteWord = async word => {
              return await fetch("https://svc.webspellchecker.net/spellcheck31/api", {
                method: "POST",
                body: `customerid=${process.env.VUE_APP_DEFAULT_SPELLCHECK_KEY}&format=json&app_type=proofreader_editable_controls&cmd=user_dictionary&action=deleteword&name=${dictionaryName}&word=${word}`
              });
            };
            // new Promise((res, rej) => instance.deleteWordFromUserDictionary(word, res, rej));
            const wordList = await this.getUserWordList();
            const upperCaseExists = wordList.includes(word.toUpperCase());
            const lowerCaseExists = wordList.includes(word.toLowerCase());
            if (!upperCaseRegex.test(word) && upperCaseExists) {
              await deleteWord(word.toUpperCase())
                .then(res => {
                  if (res?.status === 200) {
                    console.log(`Deleted ${word.toUpperCase()} from dictionary.`);
                  }
                })
                .catch(() => {
                  console.log(`Error deleting ${word.toUpperCase()} from dictionary.`);
                });
            }
            if (!lowerCaseRegex.test(word) && lowerCaseExists) {
              await deleteWord(word.toLowerCase())
                .then(res => {
                  if (res?.status === 200) {
                    console.log(`Deleted ${word.toLowerCase()} from dictionary.`);
                  }
                })
                .catch(() => {
                  console.log(`Error deleting ${word.toLowerCase()} from dictionary.`);
                });
            }
          }),
          repeat()
        )
      )
    );

    return {
      initializeWSC$,
      refocus$,
      dialogVisibility$,
      bindKeys$,
      addWords$,
      removeWords$,
      enableSpellchecker$
    };
  },
  methods: {
    getSpellcheckState() {
      if (this.webSpellCheckInstance != null) {
        return null;
      }
      const appConfig = this.webSpellCheckInstance.getApplicationConfig();
      return appConfig;
    },
    destroySpellcheck() {
      if (this.webSpellCheckInstance === null) {
        return;
      }
      this.webSpellCheckInstance.destroy();
    },
    initializeSpellcheck() {
      if (this.webSpellCheckInstance != null) {
        return;
      }
      //https://webspellchecker.com/docs/api/wscbundle/Options.html
      const onStatistics = (data, instance) => {
        eventBus.$emit(SPELL_CHECK_ACTION, { data, instance });
      };

      if (typeof window.WEBSPELLCHECKER !== "undefined" && window.WEBSPELLCHECKER?.init) {
        window.WEBSPELLCHECKER.init(
          {
            container: this.$el,
            enableBadgeButton: true,
            actionItems: ["ignoreAll", "ignore", "settings", "proofreadDialog", "addWord"],
            autocorrect: false,
            globalProofreadDialog: false,
            suggestionsCount: 3,
            moreSuggestionsCount: 3,
            customDictionaryIds: this.SpellCheckDictionaryId, //100
            removeBranding: true,
            lang: "en_US",
            spellcheckLang: "en_US",
            serviceId: this.SpellCheckSubscriptionKey,
            disableDictionariesPreferences: this.currentUser.userTypeId !== UserTypesEnum.SuperUser,
            theme: "custom",
            autoSearch: true,
            autoStartup: false,
            disableOptionsStorage: ["all"],
            ignoreAllCapsWords: this.ignoreAllCapsWords,
            ignoreDomainNames: this.ignoreDomainNames,
            ignoreWordsWithMixedCases: this.ignoreWordsWithMixedCases,
            ignoreWordsWithNumbers: this.ignoreWordsWithNumbers,
            enableGrammar: false,
            disableStyleGuide: true,
            grammarSuggestions: false,
            settingsSections: ["dictionaries", "general", "options"],
            requestTokensCount: 100,
            onStatistics,
            onCommitOptions: changedOptions => {
              this.$store.dispatch("applicationSettings/setUserSettings", changedOptions);
            },
            onCheckStatusChange: status => {
              eventBus.$emit(SPELL_CHECK_STATUS, { status: status === "start", name: this.name });
            }
          },
          instance => {
            this.webSpellCheckInstance = instance;
            this.initWSC$.next(instance);
            eventBus.$emit(START_SPELLCHECK, instance);
          },
          error => {
            console.log("Error loading spellchecker ", error);
          }
        );
      }
    },
    openProofReadDialog() {
      try {
        this.webSpellCheckInstance.openDialog();
      } catch (error) {
        console.log("Error opening the spellcheck instace", error);
      }
    },
    async getUserWordList(dictionaryName) {
      return await new Promise((res, rej) => {
        this.webSpellCheckInstance.getUserDictionary(dictionaryName, res, rej);
      }).then(res => {
        return res?.wordlist;
      });
    }
  }
};
